import React, { useRef, useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormControlLabel,
  Checkbox,
  FormLabel,
  Radio,
  RadioGroup,
  Button,
  IconButton,
  Box,
  Divider,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import DesktopWindowsOutlinedIcon from "@mui/icons-material/DesktopWindowsOutlined";
import FolderCopyOutlinedIcon from "@mui/icons-material/FolderCopyOutlined";
import CloudOutlinedIcon from "@mui/icons-material/CloudOutlined";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { MdOutlineEdit } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";

import CustomInputTable from "../../../../components/CustomInputTable";
import CustomEmptyModal from "../../../../components/CustomEmptyModal";
import { useTheme } from "../../../../style/theme";

export default function CandidateFromModal({
  title,
  open,
  onClose,
  onSubmit,
  editFormData,
  viewFormData,
  handleEdit,
}) {
  const isViewMode = Boolean(viewFormData);
  const isEditMode = Boolean(editFormData);
  const { mode } = useTheme();
  const [formData, setFormData] = useState(
    editFormData ||
      viewFormData || {
        candidate: {
          email: "",
          firstName: "",
          lastName: "",
          countryCode: "",
          number: "",
          officalEmail: "",
          photo: null,
        },
        presentAddress: {
          address1: "",
          address2: "",
          city: "",
          country: "",
          state: "",
          postalCode: "",
        },
        permanentAddress: {
          address1: "",
          address2: "",
          city: "",
          country: "",
          state: "",
          postalCode: "",
          sameAsPresentAddress: false,
        },
        professional: {
          experience: "",
          currentSalary: "",
          location: "",
          department: "",
          sourceOfHire: "",
          skillSet: "",
          highestQualification: "",
          offerLetter: null,
          tentativeJoiningDate: null,
          title: "",
        },
        education: [
          {
            instituteName: "",
            degree: "",
            specialization: "",
            additionalNotes: "",
            dateOfCompletion: "",
          },
        ],
        experience: [
          {
            occupation: "",
            company: "",
            duration: "",
            summary: "",
            currentlyWorkHere: "",
          },
        ],
      }
  );

  const photoFileRef = useRef(null);
  const offerLetterRef = useRef(null);

  const educationTableColumns = [
    {
      type: "text",
      label: "School Name",
      name: "instituteName",
      defaultValue: "",
    },
    {
      type: "text",
      label: "Degree/Diploma",
      name: "degree",
      defaultValue: "",
    },
    {
      type: "text",
      label: "Field(s) of Study",
      name: "specialization",
      defaultValue: "",
    },
    {
      type: "text",
      label: "Date of Completion",
      name: "dateOfCompletion",
      defaultValue: "",
    },
    {
      type: "textarea",
      label: "Additional Notes",
      name: "additionalNotes",
      defaultValue: "",
    },
    ...(!isViewMode
      ? [{ name: "actions", label: "Actions", type: "actions" }]
      : []),
  ];
  const experienceTableColumns = [
    {
      type: "text",
      label: "Occupation",
      name: "occupation",
      defaultValue: "",
    },
    {
      type: "text",
      label: "Company",
      name: "company",
      defaultValue: "",
    },
    {
      type: "textarea",
      label: "Summary",
      name: "summary",
      defaultValue: "",
    },
    {
      type: "text",
      label: "Duration",
      name: "duration",
      defaultValue: "",
    },
    {
      type: "select",
      label: "Currently Work Here",
      name: "currentlyWorkHere",
      options: [
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" },
      ],
      defaultValue: "",
    },
    ...(!isViewMode
      ? [{ name: "actions", label: "Actions", type: "actions" }]
      : []),
  ];

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(formData);
  };
  const renderFormFields = () => {
    const departments = [
      { label: "HR", value: "hr" },
      { label: "Finance", value: "finance" },
      { label: "IT", value: "it" },
      { label: "Marketing", value: "marketing" },
      { label: "Sales", value: "sales" },
    ];
    const locations = [
      { label: "New York", value: "newYork" },
      { label: "San Francisco", value: "sanFrancisco" },
      { label: "London", value: "london" },
      { label: "Berlin", value: "berlin" },
      { label: "Tokyo", value: "tokyo" },
    ];
    const sourcesOfHire = [
      { label: "Referral", value: "referral" },
      { label: "Job Portal", value: "jobPortal" },
      { label: "Campus", value: "campus" },
      { label: "Agency", value: "agency" },
    ];
    const countries = [
      { label: "USA", value: "usa" },
      { label: "UK", value: "uk" },
      { label: "Germany", value: "germany" },
      { label: "Japan", value: "japan" },
    ];
    const states = [
      { label: "California", value: "california" },
      { label: "New York", value: "newYork" },
      { label: "Texas", value: "texas" },
      { label: "Florida", value: "florida" },
    ];
    const countryCodes = [
      { label: "+1", value: "1" },
      { label: "+44", value: "44" },
      { label: "+49", value: "49" },
      { label: "+81", value: "81" },
    ];

    return (
      <>
        {/* Candidate */}
        <div className="flex flex-col gap-3 border border-neutral-700 shadow-lg rounded-lg p-4">
          <div className="py-5 ">
            <p className={` text-[18px]  ${mode === "light" ? "#666768" : ""}`}>
              Candidate Details
            </p>
            <Divider sx={{ mt: 1 }} />
          </div>
          <Grid container spacing={3}>
            <Grid size={{ xs: 6, sm: 2 }}>
              <p>Email ID</p>
            </Grid>
            <Grid size={{ xs: 6, sm: 4 }}>
              <TextField
                sx={{ width: "100%" }}
                name="email"
                size="small"
                value={formData.candidate.email || ""}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    candidate: {
                      ...formData.candidate,
                      email: event.target.value,
                    },
                  })
                }
                disabled={isViewMode}
              />
            </Grid>
            <Grid size={{ xs: 6, sm: 2 }}>
              <p>First Name</p>
            </Grid>
            <Grid size={{ xs: 6, sm: 4 }}>
              <TextField
                sx={{ width: "100%" }}
                name="firstName"
                // label="First Name"
                size="small"
                value={formData.candidate.firstName || ""}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    candidate: {
                      ...formData.candidate,
                      firstName: event.target.value,
                    },
                  })
                }
                disabled={isViewMode}
              />
            </Grid>
            <Grid size={{ xs: 6, sm: 2 }}>
              <p>Phone</p>
            </Grid>
            <Grid size={{ xs: 6, sm: 4 }}>
              <Grid container spacing={2}>
                <Grid size={{ xs: 6, sm: 3 }}>
                  <FormControl sx={{ width: "100px" }}>
                    <Select
                      name="countryCode"
                      size="small"
                      value={formData.candidate.countryCode || ""}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          candidate: {
                            ...formData.candidate,
                            countryCode: event.target.value,
                          },
                        })
                      }
                      disabled={isViewMode}
                    >
                      {countryCodes.map((code) => (
                        <MenuItem key={code.value} value={code.value}>
                          {code.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid size={{ xs: 6, sm: 9 }}>
                  <TextField
                    sx={{ width: "100%" }}
                    name="number"
                    // label="Number"
                    size="small"
                    variant="outlined"
                    value={formData.candidate.number || ""}
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        candidate: {
                          ...formData.candidate,
                          number: event.target.value,
                        },
                      })
                    }
                    disabled={isViewMode}
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* </Grid> */}
            <Grid size={{ xs: 6, sm: 2 }}>
              <p>Last Name</p>
            </Grid>
            <Grid size={{ xs: 6, sm: 4 }}>
              <TextField
                sx={{ width: "100%" }}
                name="lastName"
                size="small"
                value={formData.candidate.lastName || ""}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    candidate: {
                      ...formData.candidate,
                      lastName: event.target.value,
                    },
                  })
                }
                disabled={isViewMode}
              />
            </Grid>

            <Grid size={{ xs: 6, sm: 2 }}>
              <p>Photo</p>
            </Grid>
            <Grid size={{ xs: 6, sm: 4 }}>
              {formData.candidate.photo ? (
                <div className="flex p-3 border border-gray-800 rounded-lg items-center justify-between">
                  <span>{formData.candidate.photo.name}</span>
                  <IconButton
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      if (photoFileRef.current) photoFileRef.current.value = "";
                    }}
                  >
                    <MdDeleteOutline />
                  </IconButton>
                </div>
              ) : (
                <>
                <div className="border p-3 rounded-lg text-center ">
                  <p>Upload from</p>
                  <Button sx={{ fontSize: 15, fontWeight: "normal" }}>
                    <span>
                      <DesktopWindowsOutlinedIcon
                        sx={{ fontSize: 15, mr: 1 }}
                      />
                    </span>
                    Desktop
                    <input
                    type="file"
                    hidden
                    ref={photoFileRef}
                    onChange={() => {
                      const file = photoFileRef.current?.files[0];
                      if (file) {
                        setFormData({
                          ...formData,
                          candidate: { ...formData.candidate, photo: file },
                        });
                      }
                    }}
                  />
                  </Button>{" "}
                  /
                  <Button sx={{ fontSize: 15, fontWeight: "normal" }}>
                    <span>
                      <FolderCopyOutlinedIcon
                        sx={{ fontSize: 15, mr: 1 }}
                      />
                    </span>
                    Clikkle WorkDrive
                    <input
                    type="file"
                    hidden
                    ref={photoFileRef}
                    onChange={() => {
                      const file = photoFileRef.current?.files[0];
                      if (file) {
                        setFormData({
                          ...formData,
                          candidate: { ...formData.candidate, photo: file },
                        });
                      }
                    }}
                  />
                  </Button>{" "}
                  /
                  <Button sx={{ fontSize: 15, fontWeight: "normal" }}>
                    <span>
                      <CloudOutlinedIcon sx={{ fontSize: 15, mr: 1 }} />
                    </span>
                    Others
                    <input
                    type="file"
                    hidden
                    ref={photoFileRef}
                    onChange={() => {
                      const file = photoFileRef.current?.files[0];
                      if (file) {
                        setFormData({
                          ...formData,
                          candidate: { ...formData.candidate, photo: file },
                        });
                      }
                    }}
                  />
                  </Button>
                </div>
                <p className=" text-[#8888] text-[14px] mt-1 " >Files supported: JPG, PNG, GIF, JPEG
                Max. size is 5 MB</p>
                </>
                // <Button
                //   sx={{ width: "100%", height: "50px" }}
                //   variant="outlined"
                //   component="label"
                //   className="flex flex-col text-center"
                // >
                //   <h1>Upload Candidate Photo</h1>
                //   <h1>
                //     Files supported: JPG, PNG, GIF, JPEG Max. size is 5 MB
                //   </h1>
                  // <input
                  //   type="file"
                  //   hidden
                  //   ref={photoFileRef}
                  //   onChange={() => {
                  //     const file = photoFileRef.current?.files[0];
                  //     if (file) {
                  //       setFormData({
                  //         ...formData,
                  //         candidate: { ...formData.candidate, photo: file },
                  //       });
                  //     }
                  //   }}
                  // />
                // </Button>
              )}
            </Grid>

            <Grid size={{ xs: 6, sm: 2 }}>
              <p>Official Email</p>
            </Grid>
            <Grid size={{ xs: 6, sm: 4 }}>
              <TextField
                sx={{ width: "100%" }}
                name="officalEmail"
                size="small"
                variant="outlined"
                value={formData.candidate.officalEmail || ""}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    candidate: {
                      ...formData.candidate,
                      officalEmail: event.target.value,
                    },
                  })
                }
                disabled={isViewMode}
              />
            </Grid>
          </Grid>
        </div>

        {/* Address */}
        <div className="flex flex-col border border-neutral-700 shadow-lg mt-2 rounded-lg p-3">
          <div className="flex flex-col gap-3">
            <div className="py-3 ">
              <h1>Address Details</h1>
              <Divider sx={{ mt: 1 }} />
            </div>
            {/* Present Address */}
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid size={{ xs: 6, sm: 2 }}>
                <p>Present address</p>
              </Grid>
              <Grid size={{ xs: 6, sm: 10 }}>
                <Grid container spacing={2}>
                  <Grid size={{ xs: 6, sm: 12 }}>
                    <TextField
                      sx={{ width: "100%" }}
                      name="presentAddress1"
                      placeholder="Address line 1"
                      variant="outlined"
                      size="small"
                      value={formData.presentAddress.address1 || ""}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          presentAddress: {
                            ...formData.presentAddress,
                            address1: event.target.value,
                          },
                        })
                      }
                      disabled={isViewMode}
                    />
                  </Grid>
                  <Grid size={{ xs: 6, sm: 12 }}>
                    <TextField
                      sx={{ width: "100%" }}
                      name="presentAddress2"
                      size="small"
                      placeholder="Address line 2"
                      variant="outlined"
                      value={formData.presentAddress.address2 || ""}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          presentAddress: {
                            ...formData.presentAddress,
                            address2: event.target.value,
                          },
                        })
                      }
                      disabled={isViewMode}
                    />
                  </Grid>
                  <Grid size={{ xs: 6, sm: 12 }}>
                    <TextField
                      sx={{ width: "100%" }}
                      name="presentCity"
                      size="small"
                      placeholder="City"
                      variant="outlined"
                      value={formData.presentAddress.city || ""}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          presentAddress: {
                            ...formData.presentAddress,
                            city: event.target.value,
                          },
                        })
                      }
                      disabled={isViewMode}
                    />
                  </Grid>
                  <Grid size={{ xs: 6, sm: 6 }}>
                    <FormControl fullWidth>
                      <Select
                        name="presentAddressCountry"
                        size="small"
                        label="Select Country"
                        value={formData.presentAddress.country || ""}
                        onChange={(event) =>
                          setFormData({
                            ...formData,
                            presentAddress: {
                              ...formData.presentAddress,
                              country: event.target.value,
                            },
                          })
                        }
                        disabled={isViewMode}
                      >
                        {countries.map((country) => (
                          <MenuItem key={country.value} value={country.value}>
                            {country.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid size={{ xs: 6, sm: 6 }}>
                    <FormControl fullWidth>
                      <Select
                        name="presentAddressState"
                        size="small"
                        label="Select State"
                        variant="outlined"
                        value={formData.presentAddress.state || ""}
                        onChange={(event) =>
                          setFormData({
                            ...formData,
                            presentAddress: {
                              ...formData.presentAddress,
                              state: event.target.value,
                            },
                          })
                        }
                        disabled={isViewMode}
                      >
                        {states.map((state) => (
                          <MenuItem key={state.value} value={state.value}>
                            {state.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid size={{ xs: 6, sm: 12 }}>
                    <TextField
                      sx={{ width: "100%" }}
                      name="presentPostalCode"
                      size="small"
                      placeholder="Postal Code"
                      variant="outlined"
                      value={formData.presentAddress.postalCode || ""}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          presentAddress: {
                            ...formData.presentAddress,
                            postalCode: event.target.value,
                          },
                        })
                      }
                      disabled={isViewMode}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Permanent address */}
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid size={{ xs: 6, sm: 2 }}>
                <p>Permanent address</p>
              </Grid>
              <Grid size={{ xs: 6, sm: 10 }}>
                <Grid container spacing={2}>
                  <Grid size={{ xs: 6, sm: 12 }}>
                    {!isViewMode && (
                      <FormControl fullWidth>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                formData.permanentAddress
                                  .sameAsPresentAddress || false
                              }
                              onChange={(event) => {
                                const isChecked = event.target.checked;
                                setFormData((prev) => {
                                  const updatedFormData = {
                                    ...prev,
                                    permanentAddress: {
                                      ...prev.permanentAddress,
                                      sameAsPresentAddress: isChecked,
                                    },
                                  };

                                  if (isChecked) {
                                    updatedFormData.permanentAddress.address1 =
                                      prev.presentAddress.address1 || "";
                                    updatedFormData.permanentAddress.address2 =
                                      prev.presentAddress.address2 || "";
                                    updatedFormData.permanentAddress.city =
                                      prev.presentAddress.city || "";
                                    updatedFormData.permanentAddress.country =
                                      prev.presentAddress.country || "";
                                    updatedFormData.permanentAddress.state =
                                      prev.presentAddress.state || "";
                                    updatedFormData.permanentAddress.postalCode =
                                      prev.presentAddress.postalCode || "";
                                  } else {
                                    updatedFormData.permanentAddress.address1 =
                                      "";
                                    updatedFormData.permanentAddress.address2 =
                                      "";
                                    updatedFormData.permanentAddress.city = "";
                                    updatedFormData.permanentAddress.country =
                                      "";
                                    updatedFormData.permanentAddress.state = "";
                                    updatedFormData.permanentAddress.postalCode =
                                      "";
                                  }

                                  return updatedFormData;
                                });
                              }}
                            />
                          }
                          label="Same as Present Address"
                          margin="normal"
                        />
                      </FormControl>
                    )}
                  </Grid>
                  <Grid size={{ xs: 6, sm: 12 }}>
                    <TextField
                      sx={{ width: "100%" }}
                      name="presentAddress1"
                      placeholder="Address line 1"
                      variant="outlined"
                      size="small"
                      value={formData.presentAddress.address1 || ""}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          presentAddress: {
                            ...formData.presentAddress,
                            address1: event.target.value,
                          },
                        })
                      }
                      disabled={isViewMode}
                    />
                  </Grid>
                  <Grid size={{ xs: 6, sm: 12 }}>
                    <TextField
                      sx={{ width: "100%" }}
                      name="presentAddress2"
                      size="small"
                      placeholder="Address line 2"
                      variant="outlined"
                      value={formData.presentAddress.address2 || ""}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          presentAddress: {
                            ...formData.presentAddress,
                            address2: event.target.value,
                          },
                        })
                      }
                      disabled={isViewMode}
                    />
                  </Grid>
                  <Grid size={{ xs: 6, sm: 12 }}>
                    <TextField
                      sx={{ width: "100%" }}
                      name="presentCity"
                      size="small"
                      placeholder="City"
                      variant="outlined"
                      value={formData.presentAddress.city || ""}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          presentAddress: {
                            ...formData.presentAddress,
                            city: event.target.value,
                          },
                        })
                      }
                      disabled={isViewMode}
                    />
                  </Grid>
                  <Grid size={{ xs: 6, sm: 6 }}>
                    <FormControl fullWidth>
                      <Select
                        name="presentAddressCountry"
                        size="small"
                        label="Select Country"
                        value={formData.presentAddress.country || ""}
                        onChange={(event) =>
                          setFormData({
                            ...formData,
                            presentAddress: {
                              ...formData.presentAddress,
                              country: event.target.value,
                            },
                          })
                        }
                        disabled={isViewMode}
                      >
                        {countries.map((country) => (
                          <MenuItem key={country.value} value={country.value}>
                            {country.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid size={{ xs: 6, sm: 6 }}>
                    <FormControl fullWidth>
                      <Select
                        name="presentAddressState"
                        size="small"
                        label="Select State"
                        variant="outlined"
                        value={formData.presentAddress.state || ""}
                        onChange={(event) =>
                          setFormData({
                            ...formData,
                            presentAddress: {
                              ...formData.presentAddress,
                              state: event.target.value,
                            },
                          })
                        }
                        disabled={isViewMode}
                      >
                        {states.map((state) => (
                          <MenuItem key={state.value} value={state.value}>
                            {state.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid size={{ xs: 6, sm: 12 }}>
                    <TextField
                      sx={{ width: "100%" }}
                      name="presentPostalCode"
                      size="small"
                      placeholder="Postal Code"
                      variant="outlined"
                      value={formData.presentAddress.postalCode || ""}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          presentAddress: {
                            ...formData.presentAddress,
                            postalCode: event.target.value,
                          },
                        })
                      }
                      disabled={isViewMode}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>

        {/* Professional */}
        <div className="flex flex-col border border-neutral-700 gap-3 shadow-lg mt-2 rounded-lg p-3">
          <div className="py-3  ">
            <h1>Professional Details</h1>
            <Divider sx={{ mt: 2 }} />
          </div>
          {/*  */}
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid size={{ xs: 12, md: 6 }}>
              <Grid container spacing={2}>
                <Grid size={{ xs: 6, sm: 4 }}>
                  <p>Experience</p>
                </Grid>
                <Grid size={{ xs: 6, sm: 8 }}>
                  <TextField
                    sx={{ width: "100%" }}
                    name="experience"
                    // placeholder="Experience"
                    size="small"
                    variant="outlined"
                    value={formData.professional.experience || ""}
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        professional: {
                          ...formData.professional,
                          experience: event.target.value,
                        },
                      })
                    }
                    disabled={isViewMode}
                  />
                </Grid>
                <Grid size={{ xs: 6, sm: 4 }}>
                  <p>Source of Hire</p>
                </Grid>
                <Grid size={{ xs: 6, sm: 8 }}>
                  <FormControl fullWidth>
                    <Select
                      name="sourceOfHire"
                      size="small"
                      // label="Source of Hire"
                      value={formData.professional.sourceOfHire || ""}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          professional: {
                            ...formData.professional,
                            sourceOfHire: event.target.value,
                          },
                        })
                      }
                      disabled={isViewMode}
                    >
                      {sourcesOfHire.map((source) => (
                        <MenuItem key={source.value} value={source.value}>
                          {source.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid size={{ xs: 6, sm: 4 }}>
                  <p>Skill Set</p>
                </Grid>
                <Grid size={{ xs: 6, sm: 8 }}>
                  <TextField
                    sx={{ width: "100%" }}
                    name="skillSet"
                    // label="Skill Set"
                    variant="outlined"
                    minRows={2}
                    value={formData.professional.skillSet || ""}
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        professional: {
                          ...formData.professional,
                          skillSet: event.target.value,
                        },
                      })
                    }
                    multiline
                    disabled={isViewMode}
                  />
                </Grid>
                <Grid size={{ xs: 6, sm: 4 }}>
                  <p>Highest Qualification</p>
                </Grid>
                <Grid size={{ xs: 6, sm: 8 }}>
                  <TextField
                    sx={{ width: "100%" }}
                    name="highestQualification"
                    // label="Highest Qualification"
                    size='small'
                    variant="outlined"
                    value={formData.professional.highestQualification || ""}
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        professional: {
                          ...formData.professional,
                          highestQualification: event.target.value,
                        },
                      })
                    }
                    disabled={isViewMode}
                  />
                </Grid>
                <Grid size={{ xs: 6, sm: 4 }}>
                  <p>Additional information</p>
                </Grid>
                <Grid size={{ xs: 6, sm: 8 }}>
                  <TextField
                    sx={{ width: "100%" }}
                    name="additionalInformation"
                    multiline={true}
                    minRows={2}
                    // label="Current Salary"
                    size='small'
                    variant="outlined"
                    value={formData.professional.currentSalary || ""}
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        professional: {
                          ...formData.professional,
                          currentSalary: event.target.value,
                        },
                      })
                    }
                    disabled={isViewMode}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid size={{ xs: 12, md: 6 }}>
              <Grid container spacing={2}>
                <Grid size={{ xs: 6, sm: 4 }}>
                  <p>Location</p>
                </Grid>
                <Grid size={{ xs: 6, sm: 8 }}>
                  <FormControl fullWidth>
                    {/* <InputLabel>Location</InputLabel> */}
                    <Select
                      name="location"
                      // label="Select"
                      size="small"
                      value={formData.professional.location || ""}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          professional: {
                            ...formData.professional,
                            location: event.target.value,
                          },
                        })
                      }
                      disabled={isViewMode}
                    >
                      {locations.map((loc) => (
                        <MenuItem key={loc.value} value={loc.value}>
                          {loc.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid size={{ xs: 6, sm: 4 }}>
                  <p>Title</p>
                </Grid>
                <Grid size={{ xs: 6, sm: 8 }}>
                  <FormControl disabled={isViewMode}>
                    {/* <FormLabel>Title</FormLabel> */}
                    <RadioGroup
                      value={formData.professional.title || ""}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          professional: {
                            ...formData.professional,
                            title: event.target.value,
                          },
                        })
                      }
                    >
                      <FormControlLabel
                        value="ceo"
                        control={<Radio />}
                        label="CEO"
                      />
                      <FormControlLabel
                        value="fullstackHead"
                        control={<Radio />}
                        label="Administration"
                      />
                      <FormControlLabel
                        value="manager"
                        control={<Radio />}
                        label="Manager"
                      />
                      <FormControlLabel
                        value="amanager"
                        control={<Radio />}
                        label="Assistant Manager"
                      />
                      <FormControlLabel
                        value="teamMember"
                        control={<Radio />}
                        label="Team Member"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid size={{ xs: 6, sm: 4 }}>
                  <p>Current Salary</p>
                </Grid>
                <Grid size={{ xs: 6, sm: 8 }}>
                  <TextField
                    sx={{ width: "100%" }}
                    name="currentSalary"
                    // label="Current Salary"
                    size='small'
                    variant="outlined"
                    value={formData.professional.currentSalary || ""}
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        professional: {
                          ...formData.professional,
                          currentSalary: event.target.value,
                        },
                      })
                    }
                    disabled={isViewMode}
                  />
                </Grid>
                <Grid size={{ xs: 6, sm: 4 }}>
                  <p>Department</p>
                </Grid>
                <Grid size={{ xs: 6, sm: 8 }}>
                  <FormControl fullWidth>
                    <Select
                      name="department"
                      // label="Department"
                      size='small'
                      value={formData.professional.department || ""}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          professional: {
                            ...formData.professional,
                            department: event.target.value,
                          },
                        })
                      }
                      disabled={isViewMode}
                    >
                      {departments.map((dept) => (
                        <MenuItem key={dept.value} value={dept.value}>
                          {dept.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid size={{ xs: 6, sm: 4 }}>
                  <p>Offer Letter</p>
                </Grid>
                <Grid size={{ xs: 6, sm: 8 }}>
                  {formData.professional.offerLetter ? (
                    <div className="flex p-3 border border-gray-800 rounded-lg items-center justify-between">
                      <span>{formData.professional.offerLetter.name}</span>
                      <IconButton
                        variant="outlined"
                        onClick={() => {
                          if (offerLetterRef.current)
                            offerLetterRef.current.value = "";
                        }}
                      >
                        <MdDeleteOutline />
                      </IconButton>
                    </div>
                  ) : (
                    <>
                    <div className="border p-3 rounded-lg text-center ">
                      <p>Upload from</p>
                      <Button sx={{ fontSize: 15, fontWeight: "normal" }}>
                        <span>
                          <DesktopWindowsOutlinedIcon
                            sx={{ fontSize: 15, mr: 1 }}
                          />
                        </span>
                        Desktop
                        <input
                          type="file"
                          hidden
                          ref={offerLetterRef}
                          onChange={() => {
                            const file = offerLetterRef.current?.files[0];
                            if (file) {
                              setFormData({
                                ...formData,
                                professional: {
                                  ...formData.professional,
                                  offerLetter: file,
                                },
                              });
                            }
                          }}
                        />
                      </Button>{" "}
                      /
                      <Button sx={{ fontSize: 15, fontWeight: "normal" }}>
                        <span>
                          <FolderCopyOutlinedIcon
                            sx={{ fontSize: 15, mr: 1 }}
                          />
                        </span>
                        Clikkle WorkDrive
                        <input
                          type="file"
                          hidden
                          ref={offerLetterRef}
                          onChange={() => {
                            const file = offerLetterRef.current?.files[0];
                            if (file) {
                              setFormData({
                                ...formData,
                                professional: {
                                  ...formData.professional,
                                  offerLetter: file,
                                },
                              });
                            }
                          }}
                        />
                      </Button>{" "}
                      /
                      <Button sx={{ fontSize: 15, fontWeight: "normal" }}>
                        <span>
                          <CloudOutlinedIcon sx={{ fontSize: 15, mr: 1 }} />
                        </span>
                        Others
                        <input
                          type="file"
                          hidden
                          ref={offerLetterRef}
                          onChange={() => {
                            const file = offerLetterRef.current?.files[0];
                            if (file) {
                              setFormData({
                                ...formData,
                                professional: {
                                  ...formData.professional,
                                  offerLetter: file,
                                },
                              });
                            }
                          }}
                        />
                      </Button>
                    </div>
                    <p className=" text-[#8888] text-[14px] mt-1 " >Max. size is 5 MB</p>
                    </>
                    // <Button
                    //   sx={{ width: "100%", height: "50px" }}
                    //   variant="outlined"
                    //   component="label"
                    //   className="flex flex-col text-center"
                    // >
                    //   <h1>Upload Offer Letter</h1>
                    //   <h1>Max. size is 5 MB</h1>
                    //   <input
                    //     type="file"
                    //     hidden
                    //     ref={offerLetterRef}
                    //     onChange={() => {
                    //       const file = offerLetterRef.current?.files[0];
                    //       if (file) {
                    //         setFormData({
                    //           ...formData,
                    //           professional: {
                    //             ...formData.professional,
                    //             offerLetter: file,
                    //           },
                    //         });
                    //       }
                    //     }}
                    //   />
                    // </Button>
                  )}
                </Grid>
                <Grid size={{ xs: 6, sm: 4 }}>
                  <p>Tentative Joining Date</p>
                </Grid>
                <Grid size={{ xs: 6, sm: 8 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{ width: "100%" }}
                      label="Tentative Joining Date"                      
                      value={formData.professional.tentativeJoiningDate || null}
                      onChange={(value) =>
                        setFormData({
                          ...formData,
                          professional: {
                            ...formData.professional,
                            tentativeJoiningDate: value,
                          },
                        })
                      }
                      disabled={isViewMode}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>

        {/* Education Section */}
        <div className="flex flex-col gap-3 border border-neutral-700 rounded-lg p-3">
          <div className="w-full flex items-center justify-between">
            <h1>Education</h1>
            {!isViewMode && (
              <Button
                variant="contained"
                onClick={() => {
                  const newRow = {
                    instituteName: "",
                    degree: "",
                    specialization: "",
                    additionalNotes: "",
                    dateOfCompletion: "",
                  };
                  setFormData((prev) => ({
                    ...prev,
                    education: [...prev.education, newRow],
                  }));
                }}
              >
                Add Row
              </Button>
            )}
          </div>
          <CustomInputTable
            columns={educationTableColumns}
            data={formData.education}
            onSubmit={(rowData, index) => {
              const updatedEducation = [...formData.education];
              updatedEducation[index] = rowData;
              setFormData((prev) => ({
                ...prev,
                education: updatedEducation,
              }));
            }}
          />
        </div>

        {/* Experience Section */}
        <div className="flex flex-col gap-3 border border-neutral-700 rounded-lg p-3">
          <div className="w-full flex justify-between">
            <h1>Experience</h1>
            {!isViewMode && (
              <Button
                variant="contained"
                onClick={() => {
                  const newRow = {
                    occupation: "",
                    company: "",
                    duration: "",
                    summary: "",
                    currentlyWorkHere: "",
                  };
                  setFormData((prev) => ({
                    ...prev,
                    experience: [...prev.experience, newRow],
                  }));
                }}
              >
                Add Row
              </Button>
            )}
          </div>
          <CustomInputTable
            columns={experienceTableColumns}
            data={formData.experience}
            onSubmit={(rowData, index) => {
              const updatedExperience = [...formData.experience];
              updatedExperience[index] = rowData;
              setFormData((prev) => ({
                ...prev,
                experience: updatedExperience,
              }));
            }}
          />
        </div>
      </>
    );
  };

  return (
    <CustomEmptyModal isScrollable={true} open={open} onClose={onClose}>
      <form onSubmit={handleSubmit} className="flex flex-col gap-3">
        <Box
          sx={{ backgroundColor: "background.default" }}
          className="w-full min-h-14 flex items-center z-50 sticky top-0 left-0 py-2"
        >
          <div className="w-full flex justify-between items-center">
            <h1 className={`${mode === "light" ? "#666768" : ""} text-xl`}>
              {title}
            </h1>
            <IconButton onClick={onClose}>
                <CloseOutlinedIcon />
              </IconButton>
            {isViewMode && (
              <IconButton onClick={handleEdit}>
                <MdOutlineEdit />
              </IconButton>
            )}
          </div>
        </Box>
        {renderFormFields()}
        {!isViewMode && (
          <Box
            sx={{ backgroundColor: "background.default" }}
            className="w-full min-h-14 z-50 sticky bottom-0 left-0 pt-4"
          >
            <div className="w-full flex flex-row gap-3 justify-between items-center">
              <div className="flex flex-row gap-3">
                <Button type="submit" variant="contained">
                  Submit
                </Button>
                <Button type="submit" variant="contained">
                  Submit And New
                </Button>
                <Button type="submit" variant="outlined">
                  Save Draft
                </Button>                
              <Button variant="outlined" onClick={onClose}>
                Cancel
              </Button>
              </div>
              {/* <Button variant="outlined" onClick={onClose}>
                Cancel
              </Button> */}
            </div>
          </Box>
        )}
      </form>
    </CustomEmptyModal>
  );
}
