import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Drawer,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  ListSubheader,
  Menu,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { Link } from "react-router-dom";
import axios from "axios";
import { useMessage } from "../../../../components/Header";
import LeaveViewPage from "./LeaveViewPage";
import FilterListIcon from "@mui/icons-material/FilterList";
import { TfiViewList } from "react-icons/tfi";
import { useTheme } from "../../../../style/theme";
import { LuCalendarDays, LuLayoutList } from "react-icons/lu";
import { FiGrid } from "react-icons/fi";
import { IoFilter } from "react-icons/io5";
import { HiDotsHorizontal } from "react-icons/hi";
import { CiExport, CiImport } from "react-icons/ci";
import ApplyLeaves from "./ApplyLeaves";
import { SearchIcon, X } from "lucide-react";
import { IoMdClose } from "react-icons/io";
import dayjs from "dayjs";

const LeaveAppPage = () => {
  const [LeaveApplications, seLeaveApplications] = useState([]);
  const [changeView, setChangeView] = useState(false);
  // const errorHandler = useErrorHandler();
  const { mode } = useTheme();
  const [view, setView] = useState("card");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const isMenuopen = Boolean(menuAnchor);

  const [openApply, setOpenApply] = useState(false);
  // const [rowCount, setRowCount] = useState(0);
  // const [rows, setRows] = useState([]);
  // const [loading, setLoading] = useState(false);
  // const [paginationModel, setPaginationModel] = useState({
  //     pageSize: 5,
  //     page: 0,
  // });

  let currentOrg = JSON.parse(localStorage.getItem("org"));
  console.log(currentOrg, "currentOrgcurrentOrg");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (view) => {
    if (view === "table") setChangeView(true);
    else if (view === "card") setChangeView(false);
    setAnchorEl(null);
  };

  const fetchLeaveApplication = useCallback(async function () {
    try {
      const response = await axios.get(
        "/hr/attendance/leaves?searchBy=status&search=Pending"
      );
      seLeaveApplications(response.data.leaves);
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    fetchLeaveApplication();
  }, [fetchLeaveApplication]);

  const { showError, showSuccess } = useMessage();
  const [acceptLoading, setAcceptLoading] = useState(false);
  const acceptLeave = useCallback(
    async function (id) {
      setAcceptLoading(true);
      try {
        const res = await axios.post(`/hr/attendance/leaves/approve/${id}`);
        fetchLeaveApplication();
        const { success, message } = res.data;
        if (success) return showSuccess(message);
        showError(message);
      } catch (e) {
        console.log(e);
      } finally {
        setAcceptLoading(false);
      }
    },
    [fetchLeaveApplication, showSuccess, showError]
  );

  const rejectLeave = useCallback(
    async function (id) {
      try {
        const res = await axios.post(`/hr/attendance/leaves/deny/${id}`);
        fetchLeaveApplication();
        const { success, message } = res.data;
        if (success) return showSuccess(message);
        showError(message);
      } catch (e) {
        showError(e);
      }
    },
    [fetchLeaveApplication, showSuccess, showError]
  );

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  // const fetchLeaves = useCallback(async () => {
  //     setLoading(true);

  //     console.log('fetching....');
  //     setRows([]);

  //     try {
  //         const response = await axios.get(
  //             `/employee/leaves/?page=${paginationModel.page + 1 || 1}&pageSize=${paginationModel.pageSize
  //             }`
  //         );
  //         const { leaves, pageData } = response.data;

  //         console.log(leaves);

  //         const rows = leaves.map((leave, index) => ({
  //             ...leave,
  //             id: index,
  //             days:
  //                 leave.dates.length === 1 ? '1' : calcLeaveDays(leave.dates[0], leave.dates[1]),
  //             from: leave.dates[0],
  //             to: leave.dates.pop(),
  //         }));

  //         setRows(rows);
  //         setRowCount(pageData.totalData || 0);
  //     } catch (e) {
  //         console.warn(e);
  //     } finally {
  //         setLoading(false);
  //     }
  // }, [setRows, paginationModel]);
  const [leaveModal, setleaveModal] = useState(false);
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [filter, setFilter] = useState("");
  const [recordsPerPage, setRecordsPerPage] = useState(20);

  

  const FilterDrawer = () => {
    const Periodoptions = [
      { value: "Yesterday", label: "Yesterday" },
      { value: "Today", label: "Today" },
      { value: "Last Month", label: "Last Month" },
      { value: "This Month", label: "This Month" },
      { value: "Last Year", label: "Last Year" },
      { value: "This Year", label: "This Year" },
      { value: "Custom", label: "Custom" },
    ];

    const typeOptions = [
      { value: "All", label: "All" },
      { value: "Paid", label: "Paid" },
      { value: "unPaid", label: "unPaid" },
    ];
    return (
      <Drawer
        anchor="right"
        open={openFilterDrawer}
        onClose={() => setOpenFilterDrawer(false)}
      >
        <div
          style={{
            backgroundColor: mode === "light" ? "#fff" : "background.view",
          }}
          className="w-72 p-4"
        >
          <div className="flex justify-between items-center">
            <h2 className="text-lg font-semibold mb-4">Filter</h2>
            <button
              className="border-none outline-none"
              onClick={() => setOpenFilterDrawer(false)}
            >
              <X size={20} />
            </button>
          </div>
          <label className="block text-sm font-medium mb-2 text-[#666666] mt-2">
            Period
          </label>
          <Select
            value={filter} // Directly bind `filter`
            onChange={(e) => setFilter(e.target.value)} // MUI ke liye `.target.value` use hota hai
            fullWidth
            sx={{
              backgroundColor: mode === "light" ? "#fff" : "transparent",
              height: "34px",
            }}
          >
            {Periodoptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>

          <div className="mt-4">
            <label className="block text-sm font-medium text-[#666666] mb-2 mt-1">
              From
            </label>
            <div
              className={`w-[250px] h-[30px] flex justify-between border border-[#a1a1a2] ${mode === "light" ? "bg-[#eeeef1]" : ""
                } rounded-md px-2 py-2 items-center `}
            >
              <p className="text-[#a1a1a2] text-[14px]">01-Jan-2025</p>
              <LuCalendarDays color="#a1a1a2" />
            </div>
          </div>
          <div className="mt-4">
            <label className="block text-sm text-[#666666] font-medium mb-2 mt-1">
              To
            </label>
            <div
              className={`w-[250px] h-[30px] flex justify-between border border-[#a1a1a2] ${mode === "light" ? "bg-[#eeeef1]" : ""
                } rounded-md px-2 py-2 items-center `}
            >
              <p className="text-[#a1a1a2] text-[14px]">31-Dec-2025</p>
              <LuCalendarDays color="#a1a1a2" />
            </div>
          </div>
          <div className="mt-4">
            <label className="block text-sm text-[#666666] font-medium mb-2 mt-1">
              Type
            </label>
            <Select
              value={filter} // Directly bind `filter`
              onChange={(e) => setFilter(e.target.value)} // MUI ke liye `.target.value` use hota hai
              fullWidth
              sx={{
                backgroundColor: mode === "light" ? "#fff" : "transparent",
                height: "34px",
              }}
            >
              {typeOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
        <div className="flex items-end h-full mb-5 justify-start px-5 space-x-2">
          <Button variant="contained" sx={{ bgColor: "#0088ff" }}>
            Apply
          </Button>
          <Button variant="outlined" onClick={() => setFilter("")}>
            Reset
          </Button>
        </div>
      </Drawer>
    );
  };

  const LeaveModal = ({ leaveModal, setleaveModal }) => {
    const [leaveType, setLeaveType] = useState("Compensatory Off");
    const [email, setEmail] = useState("");
    const [reason, setReason] = useState("");
    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [search, setSearch] = useState("");
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [teamEmail, setTeamEmail] = useState("");
    const { mode } = useTheme();
    const namesOptions = [
      { value: "1 Tanya gwilsbry", label: "Compensatory Off" },
    ];
    return (
      <Modal open={leaveModal} onClose={() => setleaveModal(false)}>
        <Box className="flex justify-center items-center">
          <Box sx={{
            backgroundColor: mode === "dark" ? "#141414" : "white"
          }} className=" rounded-lg w-screen h-[90vh]">

            {/* Header Section */}
            <div className="flex justify-between items-center p-3 border-b">
              <Typography variant="h6" className="font-semibold">Apply Leave</Typography>
              <IconButton onClick={() => setleaveModal(false)} sx={{ backgroundColor: mode === "light" ? "#dcdcdc" : "", p: "5px", borderRadius: "5px" }}>
                <IoMdClose size={18} />
              </IconButton>
            </div>


            <div style={{
              backgroundColor: mode === "dark" ? "#141414" : "#edf0f4"
            }} className="relative h-full">
              <div className="p-4 h-full relative">
                {/* Form Section */}
                <form
                  style={{
                    backgroundColor: mode === "dark" ? "#141414" : "white",
                  }}
                  className="mt-4 flex flex-col gap-y-5 p-4 w-[70%] rounded-lg"
                >
                  <h2 className="text-[16px] font-semibold mb-2">Leave</h2>
                  <hr />

                  {/* Leave Type */}
                  <div className="flex items-center w-full">

                    <label className="text-gray-600 font-medium text-[15px] w-[20%]">
                      Leave Type <span className="text-red-500">*</span>
                    </label>
                    <div className="w-[80%]">
                      <Select
                        className={`${mode === "light" ? "bg-white" : "bg-[#141414]"}`}
                        fullWidth
                        size="small"
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                        displayEmpty
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 250,
                              backgroundColor: mode === "dark" ? "#141414" : "#ffffff",
                            },
                          },
                        }}
                      >
                        {/* Search Bar in Dropdown */}
                        <ListSubheader
                          style={{ backgroundColor: mode === "ligth" ? "white" : "transparent" }}>
                          <TextField
                            fullWidth
                            size="small"
                            placeholder="Search..."
                            autoFocus
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon fontSize="small" />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </ListSubheader>

                        {/* Filtered Categories */}
                        {categories.filter((cat) => cat.toLowerCase().includes(search.toLowerCase())).length > 0 ? (
                          categories
                            .filter((cat) => cat.toLowerCase().includes(search.toLowerCase()))
                            .map((cat) => (
                              <MenuItem key={cat} value={cat}>
                                {cat}
                              </MenuItem>
                            ))
                        ) : (
                          <ListSubheader
                            style={{ backgroundColor: mode === "ligth" ? "white" : "transparent" }} className="">
                            <MenuItem>Select</MenuItem>
                          </ListSubheader>

                        )}
                      </Select>
                    </div>
                  </div>

                  {/* Date Pickers (Start & End Date Side by Side) */}
                  <div className="flex items-center w-full">
                    <label className="text-gray-600 font-medium w-[20%] text-[15px]">
                      Date <span className="text-red-500">*</span>
                    </label>
                    <div className="flex gap-4 w-[80%]">
                      <TextField
                        size="small"
                        type="date"
                        fullWidth
                        variant="outlined"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                      />
                      <TextField
                        size="small"
                        type="date"
                        fullWidth
                        variant="outlined"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                      />
                    </div>
                  </div>

                  {/* Team Email ID */}
                  <div className="flex items-center w-full">
                    <label className="text-gray-600 text-[15px] font-medium w-[20%]">
                      Team Email ID
                    </label>
                    <div className="w-[80%]">
                      <TextField
                        size="small"
                        fullWidth
                        variant="outlined"
                        value={teamEmail}
                        onChange={(e) => setTeamEmail(e.target.value)}
                      />
                    </div>
                  </div>

                  {/* Reason for Leave */}
                  <div className="flex items-start w-full">
                    <label className="text-gray-600 font-medium w-[20%] text-[15px]">
                      Reason for Leave
                    </label>
                    <div className="w-[80%]">
                      <TextField
                        fullWidth
                        variant="outlined"
                        multiline
                        rows={3}
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                      />
                    </div>
                  </div>
                </form>

              </div>
              <div style={{
                backgroundColor: mode === "dark" ? "#141414" : "#ffffff"
              }} className="absolute bottom-0 gap-2 flex w-full p-3 h-fit">
                <Button variant="outlined" onClick={() => setleaveModal(false)}>
                  Cancel
                </Button>
                <Button variant="contained" color="primary">
                  Submit
                </Button>
              </div>
            </div>
          </Box>
        </Box>
      </Modal>
    );
  };

  return (
    <Box className="p-3 relative">
      {/* <div className='flex flex-col md:mb-1'>
                <div className="flex items-center justify-between md:w-full p-4">
                    <div className="p-2">
                        <h1 className="text-xs md:text-2xl text-neutral-500">Leave Applications</h1>
                    </div>
                    <div className="flex flex-row items-center justify-center gap-4">
                        <InfoOutlinedIcon />
                    </div>
                </div>
            </div> */}
      <Box
        className="w-full ml-2 md:ml-0 rounded-lg mb-4 pb-5 h-[670px] relative"
        sx={{
          // backgroundColor: 'background.bond',
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          "-ms-overflow-style": "none",
          "scrollbar-width": "none",
        }}
      >
        <div className="w-full mb-3 flex flex-col md:flex-row gap-3 justify-between md:items-center ">
          <div className="flex flex-col  sm:flex-row gap-3 sm:items-center">
            <div>
              <FormControl sx={{ width: "200px" }}>
                <Select
                  style={{
                    height: "32px",
                    width: "250px",
                    backgroundColor: mode === "light" ? "#fff" : "",
                  }}

                >
                  <MenuItem value={"Leave"}>Leave</MenuItem>
                  <MenuItem value={"approverView"}>Approver View</MenuItem>
                  <div className="w-full ">
                    <Button
                      sx={{ width: "100%" }}
                      // onClick={() => {
                      //   setCreateTableViewModal(true);
                      // }}
                      variant="outlined"
                    >
                      Create Table View
                    </Button>
                  </div>
                </Select>
              </FormControl>
            </div>
            {/* <div className="flex gap-2">
                            <div>
                                <Button variant="outlined">Edit</Button>
                            </div>
                            <div className="h-9 w-[0.15rem] rounded-lg bg-neutral-500" />
                            <div>
                                <Button variant="outlined" color="error">
                                    Delete
                                </Button>
                            </div>
                        </div> */}
          </div>

          <div className="flex  gap-3 sm:items-center flex-col sm:flex-row mt-3">
            {/* <FormControl sx={{ width: "200px" }}>
              <Select
                style={{
                  height: "32px",
                  width: "200px",
                  backgroundColor: mode === "light" ? "#fff" : "",
                }}
                labelId="data"
                id="data"
                label="Data"
              >
                <MenuItem value={"allRequest"}>All Requests</MenuItem>
                <MenuItem value={"approved"}>Approved</MenuItem>
                <MenuItem value={"reporteesData"}>Reportees Data</MenuItem>
                <MenuItem value={"Rejected"}>Rejected</MenuItem>
                <MenuItem value={"Pending"}>Pending</MenuItem>
                <MenuItem value={"Cancelled"}>Cancelled</MenuItem>
              </Select>
            </FormControl> */}
            <FormControl sx={{ width: "200px" }}>
              <Select
                style={{ height: "34px", width: "200px", padding: '0px', backgroundColor: mode === "light" ? "#fff" : "" }}>
                <MenuItem value={"allData"}>All Data</MenuItem>
                <MenuItem value={"reporteesPlusMyData"}>
                  Reportees + My Data
                </MenuItem>
                <MenuItem value={"reporteesData"}>Reportees Data</MenuItem>
                <MenuItem value={"directReporteesData"}>
                  Direct Reportees Data
                </MenuItem>
                <MenuItem value={"myData"}>My Data</MenuItem>
              </Select>
            </FormControl>
            <div className="flex gap-2 items-center">
              <div
                onClick={() => setOpenFilterDrawer(true)}
                className={`cursor-pointer relative h-fit group p-2 rounded-md ${mode === "light" ? "bg-[#fff]" : ""
                  }`}
              >
                <IoFilter />
              </div>

              {openFilterDrawer && (
                <FilterDrawer
                  openFilterDrawer={openFilterDrawer}
                  setOpenFilterDrawer={setOpenFilterDrawer}
                />
              )}

              <div>
                <div
                  className={`relative h-fit cursor-pointer group p-1 rounded-md ${mode === "light" ? "bg-[#fff]" : ""
                    }`}
                  id="basic-button"
                  aria-controls={isMenuopen ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={isMenuopen ? "true" : undefined}
                  onClick={(event) => {
                    setMenuAnchor(event.currentTarget);
                  }}
                >
                  <HiDotsHorizontal className="text-2xl" />
                </div>
                <Menu
                  id="basic-menu"
                  anchorEl={menuAnchor}
                  open={isMenuopen}
                  onClose={() => {
                    setMenuAnchor(null);
                  }}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem>
                    <div className="flex flex-row gap-3 justify-between items-center">
                      <CiImport className="text-2xl" />
                      <h1>Import</h1>
                    </div>
                  </MenuItem>
                  <MenuItem>
                    <div className="flex flex-row gap-3 justify-between items-center">
                      <CiExport className="text-2xl" />
                      <h1>Export</h1>
                    </div>
                  </MenuItem>
                  <MenuItem>
                    <div className="flex flex-row gap-3 justify-between items-center">
                      <CiExport className="text-2xl" />
                      <h1>History Export</h1>
                    </div>
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex justify-between items-center w- mb-3 pt-3 pe-3">
          <p
            className=" mb-4 border-l-4 border-blue-500 pl-4  text-xl"
            gutterBottom
          >
            Recent Earned Leave Application
          </p>
          {/* <Link to="/leaveapplication/view"> */}
          {/* <button onClick={() => setChangeView(!changeView)} className='flex  items-center text-white font-bold text-[8px] mr-2 md:mr-10 md:text-[12px] py-1 md:py-2 px-2 md:px-4 rounded bg-sky-500 hover:bg-sky-700'>
                        View All
                    </button> */}
          {/* </Link> */}
          {currentOrg.type == "Owner" ? (
            <div
              className={`rounded-md flex items-center gap-3  ${mode === "light" ? "bg-[#fff]" : ""
                }`}
            >
              <div
                className="relative group p-2 rounded-md"
                style={{ border: view === "card" ? "1px solid #0088ff" : "" }}
              // onClick={()=>handleClose("card")}
              >
                <FiGrid
                  size={17}
                  className="cursor-pointer"
                  onClick={() => setView("card")}
                  color={view === "card" ? "#0088ff" : ""}
                />
                <span className="absolute top-10 left-1/2 w-[max-content] -translate-x-1/2 text-xs bg-gray-800 text-white px-2 py-1 rounded opacity-0 group-hover:opacity-100 transition">
                  Grid view
                </span>
              </div>
              <div
                className="relative group p-2 rounded-md"
                style={{
                  border: view === "list" ? "1px solid #0088ff" : "",
                }}
              // onClick={() => handleClose("table")}
              >
                <TfiViewList
                  size={17}
                  className="cursor-pointer"
                  onClick={() => setView("list")}
                  color={view === "list" ? "#0088ff" : ""}
                />
                <span className="absolute top-10 left-1/2 -translate-x-1/2 w-[max-content] text-xs bg-gray-800 text-white px-2 py-1 rounded opacity-0 group-hover:opacity-100 transition">
                  List view
                </span>
              </div>

              {/* <IconButton
                            onClick={handleClick}
                            className="text-white bg-sky-500 hover:bg-sky-700 rounded p-1"
                        >
                            <FilterListIcon />
                        </IconButton>

                        <Menu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={() => setAnchorEl(null)}
                            className="mt-2"
                        >
                            <MenuItem onClick={() => handleClose("card")}>Card View</MenuItem>
                            <MenuItem onClick={() => handleClose("table")}>Table View</MenuItem>
                        </Menu> */}
            </div>
          ) : (
            <>
              <Button
                variant="contained"
                onClick={() => setOpenApply(!openApply)}
              >
                Apply Leaves
              </Button>
            </>
          )}
        </div>
        {currentOrg.type == "Owner" ? (
          view === "card" ? (
            LeaveApplications.length > 0 ? (
              <Grid container spacing={2} className="mx-2 pr-4 pt-2 pl-4">
                {LeaveApplications?.map((section, index) => {
                  const date = new Date(section.updatedAt);
                  let Applied = date.toLocaleTimeString();

                  const from = new Date(
                    `${section.dates[0]?.month}-${section.dates[0]?.day}-${section.dates[0]?.year}`
                  );

                  const to = section.dates[1]
                    ? new Date(
                      `${section.dates[1]?.month}-${section.dates[1]?.day}-${section.dates[1]?.year}`
                    )
                    : new Date(
                      `${section.dates[0]?.month}-${section.dates[0]?.day}-${section.dates[0]?.year}`
                    );
                  const timeDifference = Math.abs(from - to);
                  const daysRemaining = Math.ceil(
                    timeDifference / (1000 * 60 * 60 * 24)
                  );
                  return (
                    <Grid key={index} item xs={12} sm={6} md={4}>
                      <Box
                        className="w-full ml-2 md:ml-0 rounded-lg"
                        sx={{ backgroundColor: "background.view" }}
                      >
                        <div className="flex flex justify-between items-center w-full">
                          <p
                            className="mb-4 border-l-2 border-blue-500 pl-4 md:text-[18px] mt-2"
                            gutterBottom
                          >
                            Earned Leave Request
                          </p>
                          <button className="flex items-center text-white text-[8px] mr-2 md:mr-5 md:text-[10px] py-1 md:py-1 px-2 md:px-2 rounded bg-slate-500 hover:bg-sky-700">
                            {daysRemaining ? daysRemaining + 1 : "1"} days
                          </button>
                        </div>
                        <div className="flex flex-row gap-2 mx-4">
                          <div className="flex justify-center items-center">
                            <AccountCircleOutlinedIcon fontSize="large" />
                          </div>
                          <div className="flex flex-col">
                            <h1 className="text-[16px]">{section.fullName}</h1>
                            <p className="text-[10px] text-zinc-500">
                              {section.department}
                            </p>
                          </div>
                        </div>
                        <div
                          className="flex flex-row gap-4 mx-5 pt-2 "
                          style={{ marginTop: "3px", marginBottom: "3px" }}
                        >
                          <div className="flex justify-center items-center">
                            <CalendarTodayOutlinedIcon fontSize="small" />
                          </div>
                          <p className="text-[16px]">
                            {section.date}
                            {section?.dates[0]?.year}-{section?.dates[0]?.month}
                            -{section?.dates[0]?.day}
                            {section?.dates.length > 1
                              ? ` - ${section?.dates[section.dates.length - 1].year
                              }-${section?.dates[section.dates.length - 1].month
                              }-${section?.dates[section.dates.length - 1].day
                              }`
                              : ""}
                          </p>
                          <button className="flex text-center items-center text-white text-[8px] md:text-[10px]  px-2 md:px-2 rounded bg-slate-500 hover:bg-sky-700">
                            {daysRemaining ? daysRemaining + 1 : "1"} days
                          </button>
                        </div>
                        <div className="flex flex-col w-[90%] md:ml-5 mx-4 gap-2">
                          <p className="text-[10px] text-zinc-500 mt-2">
                            Applied On {formatDate(section.updatedAt)}
                          </p>

                          <LinearProgress
                            variant="determinate"
                            className="mb-2 w-[97%]"
                            value={daysRemaining}
                          />
                        </div>
                        <div className="flex flex justify-between items-center w-[85%] md:ml-5 mx-4">
                          <p
                            className="md:text-[10px] pl-1 pt-1 text-gray-400"
                            gutterBottom
                          >
                            Remaining Leaves
                          </p>
                          <p className="flex items-center text-[8px] md:text-[10px]">
                            {daysRemaining}
                          </p>
                        </div>
                        <Box
                          className="mt-2 w-[90%] ml-3 flex  md:ml-5 rounded-lg"
                          sx={{ backgroundColor: "background.bond" }}
                        >
                          <div>
                            <div className="flex flex-col p-2">
                              <h1 className="text-[10px]">Reason</h1>
                              <p className="text-[8px] text-zinc-500">
                                {section.reason}
                              </p>
                            </div>
                          </div>
                        </Box>
                        <div className="w-full flex justify-center items-center">
                          <div className="w-[90%]  flex flex-row gap-2 pb-2 mt-2 justify-center items-center">
                            <div className="w-1/2 flex justify-center items-center">
                              <button
                                className="flex w-full justify-center items-center text-blue-500 text-[8px] md:text-[10px] py-1 md:py-1 px-2 md:px-2 border border-blue-500 rounded hover:bg-sky-700"
                                onClick={() => acceptLeave(section?._id)}
                                disabled={acceptLoading}
                              >
                                Accept
                              </button>
                            </div>
                            <div className="w-1/2 flex justify-center items-center">
                              <button
                                className="flex w-full justify-center items-center text-red-500 text-[8px]  md:text-[10px] py-1 md:py-1 px-2 md:px-2 border border-red-500 rounded hover:bg-red-700"
                                onClick={() => rejectLeave(section?._id)}
                              >
                                Reject
                              </button>
                            </div>
                          </div>
                        </div>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    padding: "10px",
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    justifyContent: "center",

                    borderRadius: "12px",
                    color: "white",
                    marginBottom: "30px",
                    marginTop: "30px",
                  }}
                >
                  {/* Icon/Image Section */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      component="img"
                      src={"/images/cuate.png"} // Replace with your actual image path
                      alt="No job applications"
                      sx={{ width: "278px", height: "150" }}
                    />
                  </Box>

                  {/* Main Text */}
                  <p
                    style={{
                      marginBottom: 1,
                      fontFamily: "sans-serif",
                      fontSize: "22px",
                      fontWeight: "600",
                    }}
                    className={`${mode === "light" ? "text-[#000]" : "text-[#fff]"
                      } mt-2`}
                  >
                    No Pending Application Yet
                  </p>
                  <p
                    style={{ marginBottom: 4, fontSize: "16px" }}
                    className={`${mode === "light" ? "text-[#000]" : "text-[#fff]"
                      } mt-2 mb-2`}
                  >
                    You do not have any recent leave applications. When you add
                    employees and they apply for leaves, it will show here.
                  </p>

                  <button className='w-[110px] h-[32px] flex justify-center
                   items-center text-[14px] cursor-pointer bg-[#0088ff] mx-auto rounded-md mt-2'
                    onClick={() => setleaveModal(true)}>
                    Add Request
                  </button>

                  {leaveModal && <LeaveModal leaveModal={leaveModal} setleaveModal={setleaveModal} />}
                </Box>
              </Box>
            )
          ) : (
            <LeaveViewPage />
          )
        ) : (
          <LeaveViewPage />
        )}
        {/* ):(<></>)} */}

        <div className={`fixed bottom-[50px] md:bottom-0 md:left-[100px] 
        right-[100px] md:right-[180px] w-full ${mode === "light" ? "bg-[#ffffff]" : "bg-[#141414]"}
          p-3 flex flex-col md:flex-row md:items-center justify-between text-sm h-[60px]`}>
          <div className="text-gray-700">
            Total Record Count : <span className="text-blue-500">0</span>
          </div>

          <div className="flex items-center gap-3 md:me-[150px]">
            <select
              className={` ${mode === "light" ? "bg-white" : "bg-transparent"} border border-gray-300 rounded px-2 py-1`}
              value={recordsPerPage}
              onChange={(e) => setRecordsPerPage(Number(e.target.value))}
            >
              {[20, 50, 75, 100, 200].map((num) => (
                <option className={mode === "light" ? "bg-white text-black" : "bg-black text-white"}
                  key={num} value={num}>
                  {num}
                </option>
              ))}
            </select>
            <span className="text-gray-600">Records per page</span>

            <div className="flex items-center gap-2 ">
              <button className="px-2 py-1 border rounded text-gray-500 cursor-not-allowed">
                &lt;
              </button>
              <span className="text-gray-700">1 - 0</span>
              <button className="px-2 py-1 border rounded text-gray-500 cursor-not-allowed">
                &gt;
              </button>
            </div>
          </div>

        </div>
      </Box>

      <Modal
        open={openApply}
        onClose={() => setOpenApply(false)}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <>
          <ApplyLeaves  fetchLeaveApplication={fetchLeaveApplication} handleClose={() => setOpenApply(false)} />
        </>
      </Modal>
    </Box>
  );
};

export default LeaveAppPage;
