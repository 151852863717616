import React from "react";
import { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Grid from "@mui/material/Grid2";

const Insights = () => {
  const [chartLoaded, setChartLoaded] = useState(false);

  useEffect(() => {
    setChartLoaded(true);
  }, []);

  // Data for the cards
  const cardData = {
    headcount: {
      title: "Headcount & growth rate",
      data: [
        {
          year: "2024",
          month: 17,
          monthGrowth: "0.00%",
          yoy: 17,
          yoyGrowth: "0.00%",
        },
        {
          year: "2025",
          month: 21,
          monthGrowth: "0.00%",
          yoy: 21,
          yoyGrowth: "0.00%",
        },
      ],
    },
    addition: {
      title: "Employee addition & growth rate",
      data: [
        {
          year: "2024",
          month: 0,
          monthGrowth: "0.00%",
          yoy: 0,
          yoyGrowth: "0.00%",
        },
        {
          year: "2025",
          month: 0,
          monthGrowth: "0.00%",
          yoy: 0,
          yoyGrowth: "0.00%",
        },
      ],
    },
    attrition: {
      title: "Employee attrition & growth rate",
      data: [
        {
          year: "2024",
          month: 0,
          monthGrowth: "0.00%",
          yoy: 0,
          yoyGrowth: "0.00%",
        },
        {
          year: "2025",
          month: 0,
          monthGrowth: "0.00%",
          yoy: 0,
          yoyGrowth: "0.00%",
        },
      ],
    },
  };

  // Data for the charts
  const months = [
    "October",
    "November",
    "December",
    "January",
    "February",
    "March",
  ];

  const additionData = {
    counts: [0, 0, 0, 1, 3, 0],
    percentages: [0, 0, 0, 5.88, 16.67, 0],
  };

  const attritionData = {
    counts: [0, 0, 0, 0, 0, 0],
    percentages: [0, 0, 0, 0, 0, 0],
  };

  // Highcharts options for employee addition trend
  const additionChartOptions = {
    chart: {
      type: "column",
      height: 300,
    },
    title: {
      text: "Employee addition trend (Last Six Months)",
      align: "left",
      style: {
        fontWeight: "bold",
        fontSize: "16px",
      },
    },
    xAxis: {
      categories: months,
      crosshair: true,
      lineWidth: 0,
      tickWidth: 0,
    },
    yAxis: [
      {
        title: {
          text: null,
        },
        labels: {
          format: "{value}",
        },
        min: 0,
        max: 3.6,
        tickAmount: 4,
        gridLineWidth: 1,
      },
      {
        title: {
          text: null,
        },
        labels: {
          format: "{value}%",
        },
        min: 0,
        max: 18,
        tickAmount: 4,
        opposite: true,
        gridLineWidth: 0,
      },
    ],
    tooltip: {
      shared: true,
    },
    legend: {
      align: "left",
      verticalAlign: "bottom",
      symbolRadius: 0,
      symbolHeight: 12,
      symbolWidth: 12,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
        color: "rgba(75, 192, 192, 0.6)",
      },
      line: {
        marker: {
          enabled: true,
          radius: 4,
        },
      },
    },
    series: [
      {
        name: "Count",
        type: "column",
        data: additionData.counts,
        color: "rgba(75, 192, 192, 0.6)",
        dataLabels: {
          enabled: true,
          format: "{point.y}",
        },
      },
      {
        name: "Percentage",
        type: "line",
        yAxis: 1,
        data: additionData.percentages,
        color: "#FF6384",
        dataLabels: {
          enabled: true,
          format: "{point.y}%",
          filter: {
            property: "y",
            operator: ">",
            value: 0,
          },
        },
      },
    ],
  };

  // Highcharts options for employee attrition trend
  const attritionChartOptions = {
    chart: {
      type: "column",
      height: 300,
    },
    title: {
      text: "Employee attrition trend (Last Six Months)",
      align: "left",
      style: {
        fontWeight: "bold",
        fontSize: "16px",
      },
    },
    xAxis: {
      categories: months,
      crosshair: true,
      lineWidth: 0,
      tickWidth: 0,
    },
    yAxis: [
      {
        title: {
          text: null,
        },
        labels: {
          format: "{value}",
        },
        min: 0,
        max: 3,
        tickAmount: 4,
        gridLineWidth: 1,
      },
      {
        title: {
          text: null,
        },
        labels: {
          format: "{value}%",
        },
        min: 0,
        max: 3,
        tickAmount: 4,
        opposite: true,
        gridLineWidth: 0,
      },
    ],
    tooltip: {
      shared: true,
    },
    legend: {
      align: "left",
      verticalAlign: "bottom",
      symbolRadius: 0,
      symbolHeight: 12,
      symbolWidth: 12,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
        color: "rgba(75, 192, 192, 0.6)",
      },
      line: {
        marker: {
          enabled: true,
          radius: 4,
        },
      },
    },
    series: [
      {
        name: "Count",
        type: "column",
        data: attritionData.counts,
        color: "rgba(75, 192, 192, 0.6)",
        dataLabels: {
          enabled: true,
          format: "{point.y}",
        },
      },
      {
        name: "Percentage",
        type: "line",
        yAxis: 1,
        data: attritionData.percentages,
        color: "#36A2EB",
        dataLabels: {
          enabled: true,
          format: "{point.y}%",
          filter: {
            property: "y",
            operator: ">",
            value: 0,
          },
        },
      },
    ],
  };

  const designationChartOptions = {
    chart: {
      type: "pie",
      height: 300,
    },
    title: {
      text: "Designations (Top 30)",
      align: "left",
      style: {
        fontWeight: "bold",
        fontSize: "16px",
      },
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.2f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "{point.name}: {point.percentage:.2f}% ({point.y})",
          distance: 20,
          style: {
            fontWeight: "normal",
          },
        },
        showInLegend: false,
      },
    },
    series: [
      {
        name: "Designation",
        colorByPoint: true,
        data: [
          {
            name: "Team Member",
            y: 11,
            percentage: 52.38,
          },
          {
            name: "Administration",
            y: 4,
            percentage: 19.05,
          },
          {
            name: "Assistant Manager",
            y: 3,
            percentage: 14.29,
          },
          {
            name: "Manager",
            y: 2,
            percentage: 9.52,
          },
          {
            name: "CEO",
            y: 1,
            percentage: 4.76,
          },
        ],
      },
    ],
  };

  const departmentChartOptions = {
    chart: {
      type: "pie",
      height: 300,
    },
    title: {
      text: "Department (Top 30)",
      align: "left",
      style: {
        fontWeight: "bold",
        fontSize: "16px",
      },
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.2f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "{point.name}: {point.percentage:.2f}% ({point.y})",
          distance: 20,
          style: {
            fontWeight: "normal",
          },
        },
        showInLegend: false,
      },
    },
    series: [
      {
        name: "Department",
        colorByPoint: true,
        data: [
          {
            name: "IT",
            y: 10,
            percentage: 47.62,
          },
          {
            name: "Management",
            y: 5,
            percentage: 23.81,
          },
          {
            name: "Marketing",
            y: 5,
            percentage: 23.81,
          },
          {
            name: "HR",
            y: 1,
            percentage: 4.76,
          },
        ],
      },
    ],
  };

  const ageChartOptions = {
    chart: {
      type: "pie",
      height: 300,
    },
    title: {
      text: "Age",
      align: "left",
      style: {
        fontWeight: "bold",
        fontSize: "16px",
      },
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.2f}%</b>",
    },
    plotOptions: {
      pie: {
        innerSize: "50%",
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "{point.name}: {point.percentage:.2f}% ({point.y})",
          distance: 20,
          style: {
            fontWeight: "normal",
          },
        },
        showInLegend: false,
      },
    },
    series: [
      {
        name: "Age Group",
        colorByPoint: true,
        data: [
          {
            name: "26-28",
            y: 1,
            percentage: 4.76,
          },
          {
            name: "29-31",
            y: 4,
            percentage: 19.05,
          },
          {
            name: "32-34",
            y: 5,
            percentage: 23.81,
          },
          {
            name: "38-40",
            y: 5,
            percentage: 23.81,
          },
          {
            name: "43-46",
            y: 4,
            percentage: 19.05,
          },
        ],
      },
    ],
  };

  const genderChartOptions = {
    chart: {
      type: "pie",
      height: 300,
    },
    title: {
      text: "Gender",
      align: "left",
      style: {
        fontWeight: "bold",
        fontSize: "16px",
      },
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.2f}%</b>",
    },
    plotOptions: {
      pie: {
        innerSize: "50%",
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "{point.name}: {point.percentage:.2f}% ({point.y})",
          distance: 20,
          style: {
            fontWeight: "normal",
          },
        },
        showInLegend: false,
      },
    },
    series: [
      {
        name: "Gender",
        colorByPoint: true,
        data: [
          {
            name: "Male",
            y: 11,
            percentage: 52.38,
          },
          {
            name: "Female",
            y: 9,
            percentage: 42.86,
          },
        ],
      },
    ],
  };

  const experienceChartOptions = {
    chart: {
      type: "pie",
      height: 300,
    },
    title: {
      text: "Experience",
      align: "left",
      style: {
        fontWeight: "bold",
        fontSize: "16px",
      },
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.2f}%</b>",
    },
    plotOptions: {
      pie: {
        innerSize: "50%",
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "{point.name}: {point.percentage:.2f}% ({point.y})",
          distance: 20,
          style: {
            fontWeight: "normal",
          },
        },
        showInLegend: false,
      },
    },
    series: [
      {
        name: "Experience",
        colorByPoint: true,
        data: [
          {
            name: ">1",
            y: 4,
            percentage: 19.05,
          },
          {
            name: "4",
            y: 3,
            percentage: 14.29,
          },
          {
            name: "5",
            y: 3,
            percentage: 14.29,
          },
          {
            name: "6",
            y: 1,
            percentage: 4.76,
          },
          {
            name: "7-8",
            y: 2,
            percentage: 9.52,
          },
          {
            name: "9-10",
            y: 2,
            percentage: 9.52,
          },
          {
            name: "12-13",
            y: 2,
            percentage: 9.52,
          },
          {
            name: "14-15",
            y: 2,
            percentage: 9.52,
          },
          {
            name: "21-23",
            y: 2,
            percentage: 9.52,
          },
        ],
      },
    ],
  };

  return (
    <div className="w-full">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
        {/* Headcount Card */}
        <MetricCard data={cardData.headcount} />

        {/* Employee Addition Card */}
        <MetricCard data={cardData.addition} />

        {/* Employee Attrition Card */}
        <MetricCard data={cardData.attrition} />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* Employee Addition Chart */}
        <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-100">
          {chartLoaded && (
            <HighchartsReact
              highcharts={Highcharts}
              options={additionChartOptions}
            />
          )}
        </div>

        {/* Employee Attrition Chart */}
        <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-100">
          {chartLoaded && (
            <HighchartsReact
              highcharts={Highcharts}
              options={attritionChartOptions}
            />
          )}
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-6">
        {/* Designations Chart */}
        <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-100">
          {chartLoaded && (
            <HighchartsReact
              highcharts={Highcharts}
              options={designationChartOptions}
            />
          )}
        </div>

        {/* Department Chart */}
        <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-100">
          {chartLoaded && (
            <HighchartsReact
              highcharts={Highcharts}
              options={departmentChartOptions}
            />
          )}
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-6">
        {/* Age Chart */}
        <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-100">
          {chartLoaded && (
            <HighchartsReact
              highcharts={Highcharts}
              options={ageChartOptions}
            />
          )}
        </div>

        {/* Gender Chart */}
        <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-100">
          {chartLoaded && (
            <HighchartsReact
              highcharts={Highcharts}
              options={genderChartOptions}
            />
          )}
        </div>

        <div className="grid grid-cols-1 mt-6">
          {/* Experience Chart */}
          <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-100">
            {chartLoaded && (
              <HighchartsReact
                highcharts={Highcharts}
                options={experienceChartOptions}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

// Metric Card Component
function MetricCard({ data }) {
  return (
    <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-100">
      <h2 className="text-lg font-bold mb-4">{data.title}</h2>

      <div className="grid grid-cols-3 mb-2">
        <div></div>
        <div className="text-center font-medium">Month (Mar)</div>
        <div className="text-center font-medium">YOY</div>
      </div>

      {data.data.map((row, index) => (
        <div key={index} className="grid grid-cols-3 py-2">
          <div className="font-medium">{row.year}</div>
          <div className="text-center">
            {row.month} |{" "}
            <span className="text-gray-600">{row.monthGrowth}</span>
          </div>
          <div className="text-center">
            {row.yoy} | <span className="text-gray-600">{row.yoyGrowth}</span>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Insights;
