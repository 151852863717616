import React from "react";
import { Tab, Tabs } from "@mui/material";
import Candidate from "./Candidate/Candidate";
import TrackOnboarding from "./TrackOnboarding/TrackOnboarding";
import Recruit from "./Recruit/Recruit";
import { useSwitchScreen } from "../../../hooks/SwitchScreenContext";

export default function Onboarding() {
  const { switchScreen } = useSwitchScreen();

  return (
    <div className="w-full h-full flex flex-col gap-3 pb-3 rounded-lg">
      {switchScreen.primary === "trackOnboarding" && <TrackOnboarding />}
      {switchScreen.primary === "candidate" && <Candidate />}
      {switchScreen.primary === "recruit" && <Recruit />}
    </div>
  );
}
