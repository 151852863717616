import React from "react";

export default function NoEmployee() {
  return(
  <>
    <div className="w-full mt-12 flex justify-center items-center">
      <div className="flex justify-center gap-3 flex-col items-center p-2 w-full h-full">
        <img
          src="/images/noemployee.svg"
          className="h-[130px] md:h-[200px]"
          alt=""
        />
        <p className="text-[16px] md:text-[22px]">
          You are not an employee, you cannot access this page.
        </p>
      </div>
    </div>
    <div></div>
  </>
  );
}
