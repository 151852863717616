import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  TextField,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  FormControlLabel,
  Menu,
  FormGroup,
  Checkbox,
  Box,
} from "@mui/material";
import { IoFilter, IoFilterOutline, IoSearch } from "react-icons/io5";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { HiDotsHorizontal } from "react-icons/hi";
import { CiExport, CiImport } from "react-icons/ci";
import { LiaTrashAlt } from "react-icons/lia";
import CustomTable from "../../../components/CustomTable";
import Pagination from "../../../components/Pagination";
import CustomModal from "../../../components/CustomModal";
import axios from "axios";
import { useTheme } from "../../../style/theme";
import { IoIosMore } from "react-icons/io";
import CreateTableView from "../../../components/CreateTableView";
import CreateTableViewDrawer from "./CreateTableViewDrawer";

export default function LeaveRequests() {
  const [filterModal, setFilterModal] = useState(false);
  const { mode } = useTheme();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });
  const [totalRows, setTotalRows] = useState(0);
  const [sorting, setSorting] = useState([{ id: "id", desc: false }]);
  const [rowSelection, setRowSelection] = useState({});

  const fetchData = async (pageIndex, pageSize, sorting) => {
    const sortField = sorting[0]?.id || "id";
    const sortDirection = sorting[0]?.desc ? "desc" : "asc";

    const response = await axios.get(`https://reqres.in/api/users`, {
      params: {
        page: pageIndex + 1,
        per_page: pageSize,
        sort: sortField,
        direction: sortDirection,
      },
    });
    setData(response.data.data);
    setTotalRows(response.data.total);
  };
  useEffect(() => {
    fetchData(pagination.pageIndex, pagination.pageSize, sorting);
  }, [pagination.pageIndex, pagination.pageSize, sorting]);
  const columns = [
    { accessorKey: "id", header: "Employee ID", enableSorting: true },
    { accessorKey: "leaveType", header: "Leave Type", enableSorting: true },
    { accessorKey: "from", header: "From", enableSorting: true },
    { accessorKey: "to", header: "To", enableSorting: true },
    {
      accessorKey: "dateOfRequest",
      header: "Date of Request",
      enableSorting: true,
    },
    {
      accessorKey: "daysHoursTaken",
      header: "Days/Hours Taken",
      enableSorting: true,
    },
    {
      accessorKey: "teamEmailID",
      header: "Team Email ID",
      enableSorting: true,
    },
    {
      accessorKey: "reasonForLeave",
      header: "Reason for Leave",
      enableSorting: true,
    },
    { accessorKey: "addedBy", header: "Added By", enableSorting: true },
    { accessorKey: "addedTime", header: "Added Time", enableSorting: true },
    { accessorKey: "modifiedBy", header: "Modified By", enableSorting: true },
    {
      accessorKey: "modifiedTime",
      header: "Modified Time",
      enableSorting: true,
    },
    { accessorKey: "unit", header: "Unit", enableSorting: true },
  ];
  const renderRowSelection = (table) => {
    return (
      <div>
        {Object.keys(rowSelection).length > 0 && (
          <Button variant="contained" color="error" onClick={() => { }}>
            Delete
          </Button>
        )}
      </div>
    );
  };
  const handleRowClick = (row) => {
    // setViewRecordModal(true);
  };
  const renderActions = (row) => (
    <IconButton>
      <LiaTrashAlt />
    </IconButton>
  );

  const fields = [
    { field: "employeeID", label: "Employee ID" },
    { field: "leaveType", label: "Leave Type" },
    { field: "from", label: "From" },
    { field: "to", label: "To" },
    { field: "dateOfRequest", label: "Date of Request" },
    { field: "daysHoursTaken", label: "Days/Hours Taken" },
    { field: "teamEmailID", label: "Team Email ID" },
    { field: "reasonForLeave", label: "Reason for Leave" },
    { field: "addedBy", label: "Added By" },
    { field: "addedTime", label: "Added Time" },
    { field: "modifiedBy", label: "Modified By" },
    { field: "modifiedTime", label: "Modified Time" },
    { field: "unit", label: "Unit" },
    { field: "isHalfDay", label: "Is Half-Day" },
  ];
  const [filterFormValues, setFilterFormValues] = useState({
    employees: [],
    leaveStatus: "all",
    department: "",
    leaveType: "",
    filterFields: [],
  });
  const handleFilterFormChange = (name, value) => {
    setFilterFormValues((prev) => ({ ...prev, [name]: value }));
  };
  const handleFilterValueChange = (field, condition, value) => {
    setFilterFormValues((prev) => ({
      ...prev,
      filterFields: prev.filterFields.map((item) =>
        item.field === field ? { ...item, condition, value } : item
      ),
    }));
  };
  const handleCheckboxChange = (field) => (event) => {
    const checkedFields = filterFormValues.filterFields;
    if (event.target.checked) {
      setFilterFormValues((prev) => ({
        ...prev,
        filterFields: [...checkedFields, { field, condition: "is", value: "" }],
      }));
    } else {
      setFilterFormValues((prev) => ({
        ...prev,
        filterFields: checkedFields.filter((item) => item.field !== field),
      }));
    }
  };
  const handleFilterFormSubmit = (e) => {
    e.preventDefault();
    console.log("Filter Data :- ", filterFormValues);
    setFilterModal(false);
  };

  const [menuAnchor, setMenuAnchor] = useState(null);
  const isMenuopen = Boolean(menuAnchor);
  const [isProjectOpen, setIsProjectOpen] = useState(false);
  const [searchProjects, setSearchProjects] = useState("");
  const [selectedProject, setSelectedProject] = useState("Leave");
  const dropdownProjectRef = useRef(null);
  const projects = ["Test project F"];
  const [selected, setSelected] = useState("All Requests");
  const [view, setView] = useState("direct");
  const [isOpen, setIsOpen] = useState(false);


  const [createTableViewModal, setCreateTableViewModal] = useState(false);
  const [createTableViewForm, setCreateTableViewForm] = useState({
    view_name: "",
    default: false,
    viewPermission: "onlyMe",
    permission: {
      users: ["8889"],
      // departments: ["1", "2"],
      // roles: ["1", "2"],
      // locations: ["1", "2"],
    },
    select_columns: [
      // {
      //   title: "EmployeeID",
      //   key: "employee.employee_id",
      // },
      // {
      //   title: "First Name",
      //   key: "employee.name",
      // },
    ],
    criterias: [
      {
        relationship: null,
        column: null,
        title: "",
        condition: null,
        value: null,
      },
    ],
  });
  const createTableViewColumns = [
    {
      label: "Employee",
      key: "employee",
      options: [
        { title: "Employee 1", name: "employee1" },
        { title: "Employee 2", name: "employee2" },
      ],
    },
    {
      label: "Department",
      key: "department",
      options: [
        { title: "Department 1", name: "department1" },
        { title: "Department 2", name: "department2" },
      ],
    },
    {
      label: "Location",
      key: "location",
      options: [
        { title: "Location 1", name: "location1" },
        { title: "Location 2", name: "location2" },
      ],
    },
    {
      label: "Designation",
      key: "designation",
      options: [
        { title: "Designation 1", name: "designation1" },
        { title: "Designation 2", name: "designation2" },
      ],
    },
    {
      label: "Reporting Manager",
      key: "reportingManager",
      options: [
        { title: "Reporting Manager 1", name: "reportingManager1" },
        { title: "Reporting Manager 2", name: "reportingManager2" },
      ],
    },
    {
      label: "Added By",
      key: "addedBy",
      options: [
        { title: "Added By 1", name: "addedBy1" },
        { title: "Added By 2", name: "addedBy2" },
      ],
    },
    {
      label: "Modified By",
      key: "modifiedBy",
      options: [
        { title: "Modified By 1", name: "modifiedBy1" },
        { title: "Modified By 2", name: "modifiedBy2" },
      ],
    },
    {
      label: "Present Address",
      key: "presentAddress",
      options: [
        { title: "Present Address 1", name: "presentAddress1" },
        { title: "Present Address 2", name: "presentAddress2" },
      ],
    },
    {
      label: "Permanent Address",
      key: "permanentAddress",
      options: [
        { title: "Permanent Address 1", name: "permanentAddress1" },
        { title: "Permanent Address 2", name: "permanentAddress2" },
      ],
    },
  ];
  const createTableViewSpecifics = [
    {
      label: "User",
      options: [
        { title: "Hello 1", id: "hello1" },
        { title: "Hello 2", id: "hello2" },
      ],
    },
    {
      label: "Department",
      options: [
        { title: "Hello 1", id: "hello1" },
        { title: "Hello 2", id: "hello2" },
      ],
    },
    {
      label: "Role",
      options: [
        { title: "Hello 1", id: "hello1" },
        { title: "Hello 2", id: "hello2" },
      ],
    },
    {
      label: "Locations",
      options: [
        { title: "Hello 1", id: "hello1" },
        { title: "Hello 2", id: "hello2" },
      ],
    },
  ];
  const createTableViewCriterias = [
    {
      label: "Employee",
      key: "employee",
      options: [
        { title: "Employee ID", key: "employee", name: "employeeID" },
        { title: "Full Name", key: "employee", name: "fullName" },
      ],
    },
    {
      label: "Department",
      key: "department",
      options: [
        { title: "Department 1", key: "department", name: "department1" },
        { title: "Department 2", key: "department", name: "department2" },
      ],
    },
    {
      label: "Location",
      key: "location",
      options: [
        { title: "Location 1", key: "location", name: "location1" },
        { title: "Location 2", key: "location", name: "location2" },
      ],
    },
    {
      label: "Designation",
      key: "designation",
      options: [
        { title: "Designation 1", key: "designation", name: "designation1" },
        { title: "Designation 2", key: "designation", name: "designation2" },
      ],
    },
    {
      label: "Reporting Manager",
      key: "reportingManager",
      options: [
        {
          title: "Reporting Manager 1",
          key: "reportingManager",
          name: "reportingManager1",
        },
        {
          title: "Reporting Manager 2",
          key: "reportingManager",
          name: "reportingManager2",
        },
      ],
    },
    {
      label: "Added By",
      key: "addedBy",
      options: [
        { title: "Added By 1", key: "addedBy", name: "addedBy1" },
        { title: "Added By 2", key: "addedBy", name: "addedBy2" },
      ],
    },
    {
      label: "Modified By",
      key: "modifiedBy",
      options: [
        { title: "Modified By 1", key: "modifiedBy", name: "modifiedBy1" },
        { title: "Modified By 2", key: "modifiedBy", name: "modifiedBy2" },
      ],
    },
    {
      label: "Present Address",
      key: "presentAddress",
      options: [
        {
          title: "Present Address 1",
          key: "presentAddress",
          name: "presentAddress1",
        },
        {
          title: "Present Address 2",
          key: "presentAddress",
          name: "presentAddress2",
        },
      ],
    },
    {
      label: "Permanent Address",
      key: "permanentAddress",
      options: [
        {
          title: "Permanent Address 1",
          key: "permanentAddress",
          name: "permanentAddress1",
        },
        {
          title: "Permanent Address 2",
          key: "permanentAddress",
          name: "permanentAddress2",
        },
      ],
    },
  ];
  const createTableViewCriteriasOptions = [
    {
      label: "Employee",
      key: "employee",
      options: [
        { type: "text", title: "Option 1", key: "employee", name: "option1" },
        { type: "text", title: "Option 2", key: "employee", name: "option2" },
      ],
    },
    {
      label: "Department",
      key: "department",
      options: [
        {
          type: "select",
          options: [
            { title: "Option 1", key: "department", name: "option1" },
            { title: "Option 2", key: "department", name: "option2" },
          ],
          title: "Option 1",
          key: "department",
          name: "option1",
        },
        { type: "text", title: "Option 2", key: "department", name: "option2" },
      ],
    },
    {
      label: "Location",
      key: "location",
      options: [
        {
          type: "datetime",
          title: "Option 1",
          key: "location",
          name: "option1",
        },
        {
          type: "datetime",
          title: "Option 2",
          key: "location",
          name: "option2",
        },
      ],
    },
    {
      label: "Designation",
      key: "designation",
      options: [
        {
          type: "none",
          title: "Option 1",
          key: "designation",
          name: "option1",
        },
        {
          type: "text",
          title: "Option 2",
          key: "designation",
          name: "option2",
        },
      ],
    },
    {
      label: "Reporting Manager",
      key: "reportingManager",
      options: [
        {
          type: "text",
          title: "Option 1",
          key: "reportingManager",
          name: "option1",
        },
        {
          type: "text",
          title: "Option 2",
          key: "reportingManager",
          name: "option2",
        },
      ],
    },
    {
      label: "Added By",
      key: "addedBy",
      options: [
        { type: "text", title: "Option 1", key: "addedBy", name: "option1" },
        { type: "text", title: "Option 2", key: "addedBy", name: "option2" },
      ],
    },
    {
      label: "Modified By",
      key: "modifiedBy",
      options: [
        { type: "text", title: "Option 1", key: "modifiedBy", name: "option1" },
        { type: "text", title: "Option 2", key: "modifiedBy", name: "option2" },
      ],
    },
    {
      label: "Present Address",
      key: "presentAddress",
      options: [
        {
          type: "text",
          title: "Option 1",
          key: "presentAddress",
          name: "option1",
        },
        {
          type: "text",
          title: "Option 2",
          key: "presentAddress",
          name: "option2",
        },
      ],
    },
    {
      label: "Permanent Address",
      key: "permanentAddress",
      options: [
        {
          type: "text",
          title: "Option 1",
          key: "permanentAddress",
          name: "option1",
        },
        {
          type: "text",
          title: "Option 2",
          key: "permanentAddress",
          name: "option2",
        },
      ],
    },
  ];
  const createTableViewCriteriasRelationships = [
    { title: "AND", name: "and" },
    { title: "OR", name: "or" },
  ];



  const handleProjectSelect = (project) => {
    setSelectedProject(project);
    setIsProjectOpen(false);
  };

  const toggleprojectsDropdown = () => {
    setIsProjectOpen(!isProjectOpen);
  };

  return (
    <div className="w-full min-h-80 flex flex-col px-3">

      <div className="flex justify-between items-center">
        <div className="relative my-4" onClick={toggleprojectsDropdown} ref={dropdownProjectRef}>
          <select
            className={`w-[250px] px-2 text-[14px] py-1.5 pointer-events-none text-left ${mode === "light" ? "bg-white" : "bg-transparent"} border rounded outline-none`}
          >
            <option value={selectedProject}>{selectedProject} </option>
          </select>
          {isProjectOpen && (
            <div className={`w-[250px] z-30 absolute left-0 mt-2 ${mode === "light" ? "bg-white" : "bg-[#141414]"} border  rounded-md shadow-lg`}>
              <div
                className={`${mode == "light" ? "bg-[#e1e4e7]" : ""}  p-2`}
              >
                <div
                  className={`flex  items-center border border-[#0088ff] rounded-sm h-[30px] px-2 ${mode === "light" ? "bg-[#fff] " : ""
                    }`}
                >
                  <IoSearch color="#c1c1c1" />
                  <input
                    type="text"
                    value={searchProjects}
                    onChange={(e) => setSearchProjects(e.target.value)}
                    className="w-full px-3 py-2 border-none rounded-md outline-none bg-transparent "
                  />
                </div>
              </div>
              <p className="text-[12px] px-4 mt-2">Public views</p>
              <ul className="max-h-48 text-[15px] overflow-y-auto">
                <li className="px-4 py-2">
                  Select Project
                </li>
                {projects
                  .filter((p) =>
                    p.toLowerCase().includes(searchProjects.toLowerCase())
                  )
                  .map((project, index) => (
                    <li
                      key={index}
                      className={`px-4 py-2 hover:bg-gray-100  cursor-pointer`}
                      onClick={() => handleProjectSelect(project)}
                    >
                      {project}
                    </li>
                  ))}
              </ul>
              <div className="flex justify-center items-center hover:bg-gray-100 p-2">
                <button
                  onClick={() => {
                    setCreateTableViewModal(true);
                  }}
                  className="text-[14px] rounded-md px-2 py-1 text-[#0088ff] border border-[#0088ff] "
                >
                  Create View
                </button>
              </div>
            </div>
          )}
          {/* {projectModalOpen && (
          <ProjectModal
            projectModalOpen={projectModalOpen}
            SetProjectModalOpen={SetProjectModalOpen}
          />
        )} */}
        </div>

        <div className="flex gap-2 items-center">

          <div className="relative">
            <select
              style={{ backgroundColor: mode === "light" ? "white" : "#141414" }}
              className="w-[180px] text-[14px] px-2 py-1.5 border border-gray-300 rounded-[4px] shadow-sm"
              value={selected}
              onChange={(e) => setSelected(e.target.value)}
            >
              <option>All Requests</option>
              <option>Approved</option>
              <option>Rejected</option>
              <option>Pending</option>
              <option>Cancelled</option>
            </select>
          </div>

          <div>
            <Box className="flex">
              <button
                onClick={() => setView("direct")}
                className={`px-4 py-1.5 rounded-[4px] text-[14px] ${view === "direct" ? mode === "light" ? "bg-white border border-blue-500 text-black" : "bg-[#3767b1]" : "bg-gray-200 text-black"
                  }`}
              >
                Direct
              </button>
              <button
                onClick={() => setView("all")}
                className={`px-4 py-1.5 rounded-[4px] text-[14px] ${view === "all" ? mode === "light" ? "bg-white border border-blue-500 text-black" : "bg-[#3767b1]" : "bg-gray-200 text-black"}`}
              >
                All
              </button>
            </Box>
          </div>

          <div
            className={`relative group p-2 rounded-md ${mode === "light" ? "bg-[#f7f9fc]" : ""
              }`}
          >
            <IoFilterOutline
              size={17}
              className="cursor-pointer"
            // onClick={() => setOpenFilterDrawer(true)}
            />
            <span className="absolute left-1/2 -translate-x-1/2 text-xs w-[max-content] bg-gray-800 text-white px-2 py-1 rounded opacity-0 group-hover:opacity-100 transition">
              Filter
            </span>
          </div>


          <div className={`relative group p-2 rounded-md ${mode === "light" ? "bg-[#f7f9fc]" : ""
            }`}
            onClick={() => setIsOpen(!isOpen)}
          >
            <IoIosMore size={18} className="cursor-pointer" />
            {isOpen &&
              <Box sx={{ backgroundColor: mode === "light" ? "#fff" : "#363636" }} className="absolute w-[180px] z-40 right-2 p-5 flex flex-col gap-4 rounded-lg top-10">
                <div className="flex gap-2 items-center cursor-pointer">
                  <CiImport size={18} />
                  <p className="text-[14px]">Import</p>
                </div>
                <div className="flex gap-2 items-center cursor-pointer">
                  <CiExport size={18} />
                  <p className="text-[14px]">Export</p>
                </div>
              </Box>
            }
            <span className="absolute left-1/2 -translate-x-1/2 w-[max-content] text-xs bg-gray-800 text-white px-2 py-1 rounded opacity-0 group-hover:opacity-100 transition">
              More
            </span>
          </div>

        </div>

      </div>

      <div className="flex flex-row justify-center items-center">
        <div className="flex flex-row gap-3 justify-center items-center">
          <div>
            <FormControl sx={{ width: "200px" }}>
              <InputLabel id="table-view">Table View</InputLabel>
              <Select labelId="table-view" id="table-view" label="Table View">
                <MenuItem value={"one"}>One</MenuItem>
                <MenuItem value={"two"}>Two</MenuItem>
                <div className="w-full ">
                  <Button
                    sx={{ width: "100%" }}
                    onClick={() => {
                      // setCreateTableViewModal(true);
                    }}
                    variant="outlined"
                  >
                    Create Table View
                  </Button>
                </div>
              </Select>
            </FormControl>
          </div>
          <div>
            <Button variant="outlined">Edit</Button>
          </div>
          <div className="h-9 w-[0.15rem] rounded-lg bg-neutral-500" />
          <div>
            <Button variant="outlined" color="error">
              Delete
            </Button>
          </div>
        </div>
        <div className="w-full flex gap-3 justify-end items-center">
          <FormControl sx={{ width: "200px" }}>
            <InputLabel id="data">Data</InputLabel>
            <Select labelId="data" id="data" label="Data">
              <MenuItem value={"allData"}>All Data</MenuItem>
              <MenuItem value={"reporteesPlusMyData"}>
                Reportees + My Data
              </MenuItem>
              <MenuItem value={"reporteesData"}>Reportees Data</MenuItem>
              <MenuItem value={"directReporteesData"}>
                Direct Reportees Data
              </MenuItem>
              <MenuItem value={"myData"}>My Data</MenuItem>
            </Select>
          </FormControl>

          <IconButton
            onClick={() => {
              setFilterModal(true);
            }}
            title="Filter"
          >
            <IoFilter />
          </IconButton>
          <div>
            <IconButton
              id="basic-button"
              aria-controls={isMenuopen ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={isMenuopen ? "true" : undefined}
              onClick={(event) => {
                setMenuAnchor(event.currentTarget);
              }}
            >
              <HiDotsHorizontal className="text-2xl" />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={menuAnchor}
              open={isMenuopen}
              onClose={() => {
                setMenuAnchor(null);
              }}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem>
                <div className="flex flex-row gap-3 justify-between items-center">
                  <CiImport className="text-2xl" />
                  <h1>Import</h1>
                </div>
              </MenuItem>
            </Menu>
          </div>
        </div>
      </div>
      <div>
        <div className="h-[35.1rem] mt-1 overflow-scroll">
          <CustomTable
            columns={columns}
            onRowClick={handleRowClick}
            renderActions={renderActions}
            renderRowSelection={renderRowSelection}
            data={data}
            loading={loading}
            error={error}
            sorting={sorting}
            setSorting={setSorting}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            pagination={pagination}
            setPagination={setPagination}
            totalRows={totalRows}
            setTotalRows={setTotalRows}
            isBulkSelect={true}
          />
        </div>
      </div>
      <CustomModal
        title="Filter"
        open={filterModal}
        onClose={() => setFilterModal(false)}
        isScrollable={true}
      >
        <form onSubmit={handleFilterFormSubmit} className="flex flex-col gap-3">
          <div>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="systemFilters"
                id="systemFilters"
              >
                <Typography component="span">System Filters</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Autocomplete
                  sx={{ width: "100%" }}
                  multiple
                  options={["Employee 1", "Employee 2"]}
                  getOptionLabel={(option) => option}
                  disableCloseOnSelect
                  onChange={(e, value) =>
                    handleFilterFormChange("employees", value)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Employee"
                      placeholder="Employee"
                    />
                  )}
                />
                <FormControl sx={{ mb: "15px", width: "100%" }}>
                  <InputLabel id="employeeStatus">Employee Status</InputLabel>
                  <Select
                    labelId="employeeStatus"
                    id="employeeStatus"
                    label="Employee Status"
                    onChange={(e) =>
                      handleFilterFormChange("employeeStatus", e.target.value)
                    }
                  >
                    <MenuItem value={"allRequests"}>All Requests</MenuItem>
                    <MenuItem value={"allActiveEmployeeRequests"}>
                      All Active Employee Requests
                    </MenuItem>
                    <MenuItem value={"exEmployeeRequests"}>
                      Ex-Employee Requests
                    </MenuItem>
                  </Select>
                </FormControl>
                <Autocomplete
                  sx={{ width: "100%" }}
                  options={["All", "HR", "IT", "Management", "Marketing"]}
                  renderInput={(params) => (
                    <TextField {...params} label="Department" />
                  )}
                  onChange={(e, value) =>
                    handleFilterFormChange("department", value)
                  }
                />
                <Autocomplete
                  sx={{ width: "100%" }}
                  options={["All", "Location 1", "Location 2"]}
                  renderInput={(params) => (
                    <TextField {...params} label="Location" />
                  )}
                  onChange={(e, value) =>
                    handleFilterFormChange("location", value)
                  }
                />
              </AccordionDetails>
            </Accordion>
          </div>

          <div>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography component="span">Field</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="flex flex-col gap-3">
                  {fields.map((field, index) => (
                    <div key={index}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={handleCheckboxChange(field.field)}
                              checked={filterFormValues.filterFields.some(
                                (item) => item.field === field.field
                              )}
                            />
                          }
                          label={field.label}
                        />
                      </FormGroup>

                      {filterFormValues.filterFields
                        .filter((item) => item.field === field.field)
                        .map((filter, idx) => (
                          <div className="flex flex-col gap-3" key={idx}>
                            <FormControl sx={{ width: "100%" }}>
                              <InputLabel id={`filter-${index}`}>
                                Condition
                              </InputLabel>
                              <Select
                                labelId={`filter-${index}`}
                                id={`filter-${index}`}
                                label="Condition"
                                value={filter.condition}
                                onChange={(e) =>
                                  handleFilterValueChange(
                                    field.field,
                                    e.target.value,
                                    filter.value
                                  )
                                }
                              >
                                <MenuItem value={"is"}>Is</MenuItem>
                                <MenuItem value={"isNot"}>Is Not</MenuItem>
                                <MenuItem value={"startWith"}>
                                  Start With
                                </MenuItem>
                                <MenuItem value={"endWith"}>End With</MenuItem>
                                <MenuItem value={"contains"}>Contains</MenuItem>
                                <MenuItem value={"notContains"}>
                                  Not Contains
                                </MenuItem>
                                <MenuItem value={"Like"}>Like</MenuItem>
                                <MenuItem value={"isEmpty"}>Is Empty</MenuItem>
                                <MenuItem value={"isNotEmpty"}>
                                  Is Not Empty
                                </MenuItem>
                              </Select>
                            </FormControl>
                            <TextField
                              sx={{ width: "100%" }}
                              variant="outlined"
                              label="Value"
                              placeholder="Value"
                              value={filter.value}
                              onChange={(e) =>
                                handleFilterValueChange(
                                  field.field,
                                  filter.condition,
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        ))}
                    </div>
                  ))}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>

          <div className="w-full flex gap-2 flex-row justify-between items-center">
            <Button type="submit" variant="contained">
              Apply
            </Button>
            <Button
              onClick={() => {
                setFilterModal(false);
              }}
              variant="outlined"
            >
              Reset
            </Button>
          </div>
        </form>
      </CustomModal>

      {/* create table view */}

      <CreateTableViewDrawer
        open={createTableViewModal}
        onClose={() => {
          setCreateTableViewModal(false);
        }}
        forWhom={"employee"}
        form={createTableViewForm}
        setForm={setCreateTableViewForm}
        columns={createTableViewColumns}
        specifics={createTableViewSpecifics}
        criterias={createTableViewCriterias}
        criteriasOptions={createTableViewCriteriasOptions}
        criteriasRelationships={createTableViewCriteriasRelationships}
      />

    </div>
  );
}
