import { Popover } from "@mui/material";
import React from "react";

const CustomPopOver = ({ anchorEl, onClose, Content }) => {
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  console.log('asda',Content)
  return (
    <div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        // anchorOrigin={{
        //   vertical: "top",
        //   horizontal: "center",
        // }}
        // transformOrigin={{
        //   vertical: "bottom",
        //   horizontal: "center",
        // }}
        sx={{
          mt: -1,
        }}
      >
        {Content}
      </Popover>
    </div>
  );
};

export default CustomPopOver;
