import { CardContent, Drawer, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { IoIosInformationCircleOutline, IoMdClose } from "react-icons/io";
import { IoCloseCircleSharp } from "react-icons/io5";
import { RiKeyLine } from "react-icons/ri";
import ViewTimelineDrawer from "./ViewTimelineDrawer";

const EmployeeDepartmentDrawer = ({ openDrawer, onClose }) => {
  const { mode } = useTheme();
  const [timeLineOpen, setTimeLineOpen] = useState(false);
  return (
    <div>
      <Drawer
        PaperProps={{
          className: "w-[90%] m-1 md:w-[500px] lg:w-[800px] rounded-lg",
        }}
        anchor="right"
        open={openDrawer}
        onClose={onClose}
      >
        <div
          className={`p-4  h-full overflowY-auto ${
            mode === "dark" ? "#141414" : "#edf0f4"
          }`}
        >
          {/* Header */}
          <div className="sm:flex justify-between items-center border-b pb-3 mb-2">
            <Typography variant="h6" className="font-semibold">
              Department Change
            </Typography>

            {/* Header Right Section (Rejected Icon + View Button + Close Button) */}
            <div className="flex items-center gap-3">
              <div className="flex items-center gap-1">
                <IoCloseCircleSharp color="#ec5158" size={20} />
                <p className="font-[500] text-[15px]">Rejected</p>
              </div>
              <button
                onClick={() => setTimeLineOpen(!timeLineOpen)}
                className="px-2 py-1 text-[13px] rounded-md bg-[#0088ff] text-white"
              >
                View
              </button>
              <IoIosInformationCircleOutline size={20} />
              <button
                className="p-[5px]"
                onClick={onClose}
                style={{
                  backgroundColor: mode === "dark" ? "" : "#dcdcdc",
                  borderRadius: "5px",
                }}
              >
                <IoMdClose size={18} />
              </button>
            </div>
          </div>

          {/* Employee Section */}
          <div
            className={`mb-4 rounded-lg ${
              mode === "dark" ? "#141414" : "#fff"
            }`}
            style={{
              border: "1px solid #d7e2ed",
            }}
          >
            <CardContent>
              <p className="mb-2 text-[15px] font-medium">Employee</p>
              <div
                className={`sm:flex items-center  p-2 sm:p-3 sm:px-4 rounded-md sm:space-x-3
                   ${mode === "dark" ? "#171717" : "#edf0f4"}`}
              >
                <img
                  src="/images/no-user.png"
                  alt="Profile"
                  className="rounded-lg border w-11 h-11"
                />
                <div>
                  <p className={`${mode === "light"? "text-gray-700":"text-white"} text-[14px]`}>
                    1- <span className="font-semibold">tanya Gwilsbry</span>
                  </p>
                  <Typography variant="body2" className={`${mode === "light"? "text-gray-700":"text-white"}`}>
                    tanyagwilsbry@gmail.com
                  </Typography>
                </div>
              </div>
            </CardContent>
            <CardContent>
              <div className="flex items-center mb-2 gap-1">
                <p className="font-medium text-[15px]">Department</p>
                <div className="text-[12px] rounded-[3px] p-[1px] border border-black">
                  <RiKeyLine />
                </div>
              </div>
              <p
                className={`mt-1 text-[15px] p-1 px-2 rounded-sm ${
                  mode === "dark" ? "#171717" : "#edf0f4"
                }`}
              >
                Marketing
              </p>
            </CardContent>
          </div>

          {/* Effective Date */}
          <div
            style={{
              backgroundColor: mode === "dark" ? "#141414" : "##fff",
              border: "1px solid #d7e2ed",
            }}
            className=" rounded-lg"
          >
            <CardContent>
              <p className="mb-2 text-[15px] font-medium">Effective Date</p>
              <p
                className={`${
                  mode === "dark" ? "#171717" : "#edf0f4"
                } mt-1 text-[15px] p-1 px-2 rounded-sm`}
              >
                21-Feb-2025
              </p>
            </CardContent>
            <CardContent>
              <p className="mb-2 text-[15px] font-medium">Reason</p>
              <p
                className={`${
                  mode === "dark" ? "#171717" : "#edf0f4"
                } mt-1 text-[15px] p-1 px-2 rounded-sm`}
              >
                ssa
              </p>
            </CardContent>
          </div>
        </div>
      </Drawer>

      <ViewTimelineDrawer
        timeLineOpen={timeLineOpen}
        setTimeLineOpen={setTimeLineOpen}
      />
    </div>
  );
};

export default EmployeeDepartmentDrawer;
