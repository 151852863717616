// import React, { useState } from "react";
// import { Box, Button, Grid } from "@mui/material";
// import { RiCalendarScheduleLine } from "react-icons/ri";
// import FullCalendar from "@fullcalendar/react";
// import dayGridPlugin from "@fullcalendar/daygrid";
// import timeGridPlugin from "@fullcalendar/timegrid";
// import interactionPlugin from "@fullcalendar/interaction";

// export default function WorkSchedule() {
//   const [events, setEvents] = useState([
//     { title: "Event 1", date: "2024-12-06T10:00:00" },
//     { title: "Event 2", date: "2024-12-07T14:00:00" },
//     { title: "Event 3", date: "2024-12-08T16:00:00" },
//   ]);

//   const handleDateClick = (arg) => {
//     alert("Date clicked: " + arg.dateStr);
//   };

//   return (
//     // <Grid
//     //   className="w-full flex gap-2 px-2 py-4 justify-between items-center rounded-lg shadow-md border border-[#1e2734]"
//     //   sx={{ backgroundColor: "background.default" }}
//     // >
//     //   <div className="w-full flex flex-col gap-3 justify-start items-center ">
//     //     <div className="w-full flex gap-3 justify-start items-center ">
//     //       <div className="bg-blue-400 bg-opacity-10 rounded-lg p-2">
//     //         <RiCalendarScheduleLine className="h-6 w-6 text-blue-400" />
//     //       </div>
//     //       <div>
//     //         <h1>Work Schedule</h1>
//     //       </div>
//     //     </div>
//     //     <div className="w-full max-h-60 overflow-auto">
//     //       {/* <FullCalendar
//     //         plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
//     //         initialView="timeGridWeek" // Week view
//     //         events={events}
//     //         headerToolbar={{
//     //           left: "prev,next today",
//     //           center: "title",
//     //           right: "timeGridWeek,dayGridMonth",
//     //         }}
//     //         dateClick={handleDateClick}
//     //       /> */}
//     //     </div>
//     //   </div>
//     // </Grid>
//     <Box  sx={{ backgroundColor: "background.default" }} className=" px-2 pt-4 pb-10 rounded-lg w-full mw-full border border-[#1e2734]">
//     <div className="flex items-center space-x-2">
//     <div className="bg-blue-400 bg-opacity-10 rounded-lg p-2">
//          <RiCalendarScheduleLine className="h-6 w-6 text-blue-400" />
//     </div>
//       <div>
//       <h2 className="text-lg font-semibold">Work Schedule</h2>
//       <p className="text-gray-400 text-xs">01 December 2024 - 07 December 2024</p>
//       </div>
//     </div>
    
//     <div className="mt-4 relative">
//       <div className="grid grid-cols-7 text-center text-gray-300">
//         {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day, index) => (
//           <div key={index} className="flex flex-col items-center">
//             <span className={`${day === "Mon" ? "text-white font-semibold" : ""}`}>{day}</span>
//             <span className="text-gray-500">{index + 1}</span>
//             <div className="w-1 h-[60px] border-l border-dotted border-gray-500 mt-1"></div>
//           </div>
//         ))}
//       </div>
      
//       <div className="absolute top-[54px] left-[21.5%] w-[57%] bg-blue-500 text-white rounded-lg p-2">
//         <p className="text-sm font-semibold">AM Shift</p>
//         <p className="text-xs text-blue-200">9:00AM - 5:00PM</p>
//       </div>
//     </div>
//   </Box>
//   );
// }

import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { RiCalendarScheduleLine } from "react-icons/ri";
import dayjs from "dayjs"; // A useful library to work with dates easily

export default function WorkSchedule() {
  const [events, setEvents] = useState([
    { title: "Event 1", date: "2024-12-06T10:00:00" },
    { title: "Event 2", date: "2024-12-07T14:00:00" },
    { title: "Event 3", date: "2024-12-08T16:00:00" },
  ]);
  const [shiftStart, setShiftStart] = useState("");
  const [shiftEnd, setShiftEnd] = useState("");
  const [currentWeekDates, setCurrentWeekDates] = useState([]);

  useEffect(() => {
    // Get employee data from localStorage
    const currentEmp = JSON.parse(localStorage.getItem("emp"));
    if (currentEmp) {
      const start = currentEmp.shiftStart;
      const end = currentEmp.shiftEnd;
      setShiftStart(formatTime(start.hour, start.minute));
      setShiftEnd(formatTime(end.hour, end.minute));
    }

    // Get current date and calculate the current week's Sunday-Saturday range
    const currentDate = dayjs();
    const startOfWeek = currentDate.startOf("week"); // Start of the current week (Sunday)
    const endOfWeek = currentDate.endOf("week"); // End of the current week (Saturday)
    
    let weekDates = [];
    for (let i = 0; i < 7; i++) {
      weekDates.push(startOfWeek.add(i, "day").format("YYYY-MM-DD"));
    }
    setCurrentWeekDates(weekDates);
  }, []);

  const formatTime = (hour, minute) => {
    // Convert hour and minute into proper AM/PM format
    const time = dayjs().hour(hour).minute(minute);
    return time.format("h:mm A");
  };

  return (
    <Box sx={{ backgroundColor: "background.default" }} className="px-2 pt-4 pb-10 rounded-lg w-full mw-full border border-[#1e2734]">
      <div className="flex items-center space-x-2">
        <div className="bg-blue-400 bg-opacity-10 rounded-lg p-2">
          <RiCalendarScheduleLine className="h-6 w-6 text-blue-400" />
        </div>
        <div>
          <h2 className="text-lg font-semibold">Work Schedule</h2>
          <p className="text-gray-400 text-xs">{currentWeekDates[0]} - {currentWeekDates[6]}</p>
        </div>
      </div>

      <div className="mt-4 relative">
        {/* Weekly Calendar Headers */}
        <div className="grid grid-cols-7 text-center text-gray-300">
          {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day, index) => (
            <div key={index} className="flex flex-col items-center">
              <span className={`${day === "Mon" ? "text-white font-semibold" : ""}`}>{day}</span>
              <span className="text-gray-500">{dayjs(currentWeekDates[index]).format("D")}</span>
              <div className="w-1 h-[60px] border-l border-dotted border-gray-500 mt-1"></div>
            </div>
          ))}
        </div>

        {/* Shift Info */}
        <div className="absolute top-[54px] left-[21.5%] w-[57%] bg-blue-500 text-white rounded-lg p-2">
          <p className="text-sm font-semibold">AM Shift</p>
          <p className="text-xs text-blue-200">{shiftStart} - {shiftEnd}</p>
        </div>
      </div>
    </Box>
  );
}
