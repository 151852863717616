import React, { useState } from "react";
import { Tab, Tabs } from "@mui/material";

import OrganizationFiles from "./OrganizationFiles";
import EmployeeFiles from "./EmployeeFiles";
import HRFormsAndTemplates from "./HRFormsAndTemplates";
import Folders from "./Folders";
import { useSwitchScreen } from "../../../hooks/SwitchScreenContext";

export default function Files() {
 
   const { switchScreen } = useSwitchScreen();
  return (
    <div className="w-full flex flex-col gap-3 px-3 pb-3 rounded-lg border border-[#1e2734]">
      {switchScreen.primary === "organizationFiles" ? (
        <>
          <OrganizationFiles />
        </>
      ) : (
        <></>
      )}
      {switchScreen.primary === "employeeFiles" ? (
        <>
          <EmployeeFiles />
        </>
      ) : (
        <></>
      )}
      {switchScreen.primary === "hrFormsAndTemplates" ? (
        <>
          <HRFormsAndTemplates />
        </>
      ) : (
        <></>
      )}
      {switchScreen.primary === "folders" ? (
        <>
          <Folders />
        </>
      ) : (
        <></>
      )}
    </div>
  );
}
