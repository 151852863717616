import React, { useState } from "react";
import { Tab, Tabs } from "@mui/material";
import UserSpecificOperations from "./UserSpecificOperations";
import Clients from "./Clients";
import Projects from "./Projects";
import Jobs from "./Jobs";
import JobSchedule from "./JobSchedule/JobSchedule";
import Timesheets from "./Timesheets";
import BillsAndInvoices from "./BillsAndInvoices";
import EmployeeWageRate from "./EmployeeWageRate";
import { useSwitchScreen } from "../../../hooks/SwitchScreenContext";

export default function TimeTracker() {

  const { switchScreen } = useSwitchScreen(); 

  return (
    <div className="w-full flex flex-col gap-3 px-3 pb-3 rounded-lg border border-[#1e2734]">
      {switchScreen.primary === "userSpecificOperations" ? (
        <>
          <UserSpecificOperations />
        </>
      ) : (
        <></>
      )}
      {switchScreen.primary === "jobs" ? (
        <>
          <Jobs />
        </>
      ) : (
        <></>
      )}
      {switchScreen.primary === "timesheets" ? (
        <>
          <Timesheets />
        </>
      ) : (
        <></>
      )}
      {switchScreen.primary === "projects" ? (
        <>
          <Projects />
        </>
      ) : (
        <></>
      )}
      {switchScreen.primary === "clients" ? (
        <>
          <Clients />
        </>
      ) : (
        <></>
      )}
      {switchScreen.primary === "jobSchedule" ? (
        <>
          <JobSchedule />
        </>
      ) : (
        <></>
      )}
      {switchScreen.primary === "billsAndInvoices" ? (
        <>
          <BillsAndInvoices />
        </>
      ) : (
        <></>
      )}
      {switchScreen.primary === "employeeWageRate" ? (
        <>
          <EmployeeWageRate />
        </>
      ) : (
        <></>
      )}
    </div>
  );
}
