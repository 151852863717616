import React, { useCallback, useState, useEffect, useRef } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import axios from "axios";
import { useMessage } from "../../components/Header";
import useModal from "../../hooks/useModal";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Pagination,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import AddQuestion from "../../components/AddQuestuion";
import QuestionCard from "../../components/QuestionCard";
import { Search } from "@mui/icons-material";
import DepartmentImg from "../../assets/initalScreen/intrviewQuestion.png";
import { IoSearch } from "react-icons/io5";
import { IoMdAdd } from "react-icons/io";

const getOrders = (jobs) =>
  jobs.map((job, i) => ({
    id: job._id,
    index: i,
  }));

const Interview = () => {
  const [currentScreen, setCurrentScreen] = useState(1);
  const { modalState, closeModal, openModal } = useModal();
  const [selectedQuestion, setSelectedQuestion] = useState({});
  const [questions, setQuestions] = useState(null);
  const [originalOrder, setOriginalOrders] = useState(null);
  const [isOrderChanged, setIsOrderChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const { showError, showSuccess } = useMessage();
  const [offset, setOffset] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [pageLimit, setPageLimit] = useState(0);
  const handlePrevScreen = () => {
    if (currentScreen > 1) {
      setCurrentScreen(currentScreen - 1);
    }
  };

  const handleNextScreen = () => {
    if (currentScreen < 2) {
      setCurrentScreen(currentScreen + 1);
    }
  };
  const fetchInterviewQuestions = useCallback(
    async (search = "") => {
      setQuestions(null);
      try {
        const response = await axios.get(
          `/hr/question?searchBy=title&search=${search}&sortBy=order&direction=-1&page=${pageNo}`
        );
        const body = response.data;
        const { questions, pageData } = body;
        const { currentPage, pageSize } = pageData;
        setQuestions(questions);
        setOffset((currentPage - 1) * pageSize);
        setPageLimit(response.data.pageData.totalPages);
        setOriginalOrders(getOrders(questions));
      } catch (e) {
        console.warn(e);
      }
    },
    [setQuestions, pageNo]
  );

  useEffect(() => {
    fetchInterviewQuestions();
  }, [fetchInterviewQuestions]);

  const editQuestion = (id) => {
    openModal();
    setSelectedQuestion({ id, action: "edit" });
  };

  const copyQuestion = (id) => {
    openModal();
    setSelectedQuestion({ id, action: "" });
  };

  const saveOrder = async () => {
    setLoading(true);
    const newOrder = getOrders(questions);
    const effOrder = newOrder
      .filter((order, i) => order.id !== originalOrder[i].id)
      .map((order) => ({
        ...order,
        index: order.index + offset,
      }));

    const res = await axios.patch("/hr/job-listing/order", {
      newOrders: effOrder,
    });

    const { success } = res.data;

    if (success) {
      showSuccess("Order saved successfully");
    } else {
      showError("Cannot save order");
    }

    setIsOrderChanged(false);
    setLoading(false);
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    )
      return;

    setIsOrderChanged(true);

    const draggingJob = questions[source.index];
    questions.splice(source.index, 1);

    questions.splice(destination.index, 0, draggingJob);

    setQuestions([...questions]);
  };
  console.log(questions);

   const [placeholder, setPlaceholder] = useState('Search');
    const inputRef = useRef(null);


    const updatePlaceholder = () => {
      // Ensure the ref is not null before trying to access offsetWidth
      if (inputRef.current) {
        if (inputRef.current.offsetWidth > 200) { // Adjust width threshold as needed
          setPlaceholder('Search your job category question');
        } else {
          setPlaceholder('Search');
        }
      }
    };
  
    useEffect(() => {
      // Check on mount
      updatePlaceholder();
  
      // Add event listener to handle resizing
      window.addEventListener('resize', updatePlaceholder);
  
    }, [updatePlaceholder]);
  


  return (
    <div className="flex flex-col h-screen gap-4 sm:px-4 py-6 px-2 mb-[60px]">

      <Modal
        sx={{ overflowY: "scroll" }}
        open={modalState}
        onClose={closeModal}
      >
        <AddQuestion
          open={modalState} // Yeh ensure karega ke Dialog bhi open ho
          selectedQuestion={selectedQuestion}
          setSelectedQuestion={setSelectedQuestion}
          refresh={fetchInterviewQuestions}
          handleClose={closeModal}
          questions={questions}
        />
      </Modal>

      {questions && questions.length > 0 ? (
        <>
          <div className="flex gap-2 items-center justify-end ">
            <Box className="flex  w-[134px] sm:w-[470px] h-[49px] px-[10px] items-center justify-between border border-[#1F1F1F] rounded-md">
                <input
                    type="text"
                    className="w-full bg-transparent outline-none text-[14px]"
                    ref={inputRef}
                    placeholder={placeholder}
                  />
            
              <div>
                <IoSearch />
              </div>
            </Box>

            <Box>
              <button
                style={{
                  height: "49px",
                  backgroundColor: "#3767B1",
                  color: "#fff"
                  ,width:"142px"
                }}
                onClick={openModal}
                className="w-max rounded-[5px] hidden sm:block p-2"
              >
                Add Question
              </button>

              <div className="bg-[#3767B1] sm:hidden flex justify-center w-[41px] h-[49px] items-center rounded-[5px]"   onClick={openModal}>
                <IoMdAdd color="#fff" />
              </div>
            </Box>
          </div>

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="list">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {questions
                    ? questions?.map((question, i) => (
                        <Draggable
                          key={question._id}
                          draggableId={question._id}
                          index={i}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <QuestionCard
                                ref={provided.innerRef}
                                draggableProps={provided.draggableProps}
                                dragHandleProps={provided.dragHandleProps}
                                title={question.title}
                                questions={question.questions}
                                refresh={fetchInterviewQuestions}
                                id={question.jobId}
                                editQuestion={editQuestion}
                                copyQuestion={copyQuestion}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))
                    : Array(5)
                        ?.fill(0)
                        .map((el, i) => (
                          <Skeleton
                            variant="rounded"
                            key={i}
                            width="100%"
                            height="136px"
                            animation="wave"
                            sx={{
                              borderRadius: "20px",
                              my: 2,
                            }}
                          />
                        ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <Pagination
          page={pageNo}
          onChange={(_, newPage) => setPageNo(newPage)}
          color="primary"
          count={pageLimit}
          sx={{ float: "right" }}
        />
        </>
      ) : (
        <div className="flex flex-col items-center justify-center  text-center h-[100%]">
          <div>
            <img
              src={DepartmentImg}
              alt="No Record"
              className="mb-1"
              style={{ maxWidth: "290px", margin: "auto" }}
            />
          </div>

          <div>
            <h1 className="text-[20px] sm:text-[23px] font-bold mb-2 mt-[20px]">
              No interview question available!
            </h1>
          </div>
          <div>
            <p className="mb-[50px] text-[#868686] text-[16px]">
              You have not set any interview questions. Click on “add question”
              to start now, and keep offers rolling in.
            </p>
          </div>

          <Button
            onClick={openModal}
            variant="contained"
            sx={{ backgroundColor: "#3767B1" }}
          >
            Add Question
          </Button>
        </div>
      )}
      <Stack direction="row" justifyContent="flex-end" my={4}>
        {/* <Search
            placeholder='Search Your Job Category questions'
            onChange={e => {
                const { value } = e.target;
                !(value.trim() === ' ') && fetchInterviewQuestions(value);
            }}
        /> */}
        
        {isOrderChanged && (
          <Button
            variant="contained"
            onClick={saveOrder}
            endIcon={
              loading && (
                <CircularProgress size={20} color="secondary" thickness={7} />
              )
            }
          >
            Save Order
          </Button>
        )}
      </Stack>
    </div>
  );
};

export default Interview;
