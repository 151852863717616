import { CardContent, Drawer, Typography, useTheme } from "@mui/material";
import React from "react";
import { IoIosInformationCircleOutline, IoMdClose } from "react-icons/io";
import { IoCloseCircleSharp } from "react-icons/io5";
import { RiKeyLine } from "react-icons/ri";

const ViewTimelineDrawer = ({ timeLineOpen, setTimeLineOpen }) => {
  const { mode } = useTheme();
  return (
    <div>
      {/* view timeline */}
      <Drawer
        PaperProps={{
          className: "w-[90%] m-1 md:w-[600px] lg:w-[800px] rounded-lg",
        }}
        anchor="right"
        open={timeLineOpen}
        onClose={() => setTimeLineOpen(false)}
      >
        <div
          
          className={`w-full h-full  rounded-lg overflow-hidden p-2 sm:p-4
             ${mode === "dark" ? "#141414" : "#edf0f4"}`}
        >
          {/* Header Section */}
          <div className="flex justify-between items-center">
            <h2 className="text-lg font-semibold">Approval Timeline</h2>
            {/* <Button onClick={() => setTimeLineOpen(false)} className="text-gray-500">X</Button> */}
            <button
              className="p-[5px]"
              onClick={() => setTimeLineOpen(false)}
              style={{
                backgroundColor: mode === "dark" ? "" : "#dcdcdc",
                borderRadius: "5px",
              }}
            >
              <IoMdClose size={18} />
            </button>
          </div>

          {/* Timeline Section */}
          <div
            style={{
              border: "1px solid #d7e2ed",
             
            }}
            className={`${mode === "dark" ? "#171717" : "white"} p-2 sm:p-4 mt-4 rounded-lg w-full`}
          >
            <div className="relative border-gray-300 ">
              {/* Request Sent */}
              <div className="mb-6 flex items-start gap-2">
                {/* Avatar */}
                <div className="sm:w-10 sm:h-10 h-9 w-9">
                  <img
                    src="/images/no-user.png"
                    className="w-full h-full rounded-lg border shadow-lg"
                    alt="User"
                  />
                </div>
                <div>
                  <p className={`${mode === "light"?"text-gray-600":'text-white'} text-[11.5px] sm:text-[15px]`}>
                    Your request has been sent for approval
                  </p>
                  <span className="text-[11.5px] sm:text-[15px] text-gray-400">
                    21-Feb-2025 09:12 AM
                  </span>
                </div>
              </div>

              <div className="sm:flex items-center justify-between">
                {/* Rejected */}
                <div
                  className="mb-6 pt-11 mt-[-30px] ml-[18px] sm:ml-[20px] items-start"
                  style={{ borderLeft: "2px solid #eeeeee" }}
                >
                  {/* Red Dot */}
                  <div className="flex items-center gap-2 sm:gap-5">
                    <div
                      style={{ border: "4px solid #f5f5f5" }}
                      className="w-5 h-5 bg-[#e95b6d]  rounded-full ml-[-11px]"
                    ></div>
                    <div>
                      <p className="text-[#eb6c7c] text-[12px] sm:text-[15px]">
                        Rejected
                      </p>
                    </div>
                  </div>
                  <div className="sm:ms-5 ms-3">
                    {/* <p className="text-red-500 font-medium">Rejected</p> */}
                    <p className="text-[12px] sm:text-[14px] text-gray-400 block mt-2">
                      22-Feb-2025 01:34 PM
                    </p>
                    <div className="flex w-fit items-center gap-3 mt-2">
                      <img
                        alt="User"
                        src="/images/no-user.png"
                        className="w-10 h-10 border rounded-lg shadow-lg"
                      />
                      <div>
                        <p className="text-[12px] sm:text-[15px]">
                          1- <span className="font-medium">Tanya Gwilsbry</span>
                        </p>
                        <p className="text-[12px] sm:text-[15px] text-gray-500">
                          tanyagwilsbry@gmail.com
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex justify-center  sm:flex-col flex-row-reverse gap-5">
                  <p className="bg-[#d6e7ff] h-fit py-[3px] w-fit rounded-full px-3 text-[13px]">
                    On behalf of
                  </p>

                  <div className="flex items-center gap-2">
                    <img
                      className="h-8 w-8 rounded-lg"
                      style={{ border: "2px solid #dcdcdc" }}
                      src="/images/person.png"
                      alt=""
                    />
                    <div className="text-[14px]">
                      <p>Admin</p>
                      <p className="text-[#8d8d8d]">Role</p>
                    </div>
                  </div>
                </div>

                <div className="sm:flex hidden" />
              </div>
            </div>

            {/* Comment Section */}
            <div

              className={`${ mode === "dark" ? "#141414" : "#edf0f4"} border-t-2 rounded-md mt-5`}
            >
              <div className="p-4 flex items-start gap-3">
                <img
                  src="/images/no-user.png"
                  alt="Profile"
                  className="rounded-lg border w-8 h-8"
                />
                <div className="flex-1">
                  <p className={`${mode === "light"?"text-gray-800":"text-white"} text-sm`}>Tanya Gwilsbry</p>
                  <p className="text-red-500 text-sm">Rejected</p>
                  <span className="text-xs text-gray-400">
                    Yesterday 11:34 PM
                  </span>
                </div>
              </div>

              <hr className="h-[2px] mt-2 bg-[#dcdcdc]" />
              <div className="mt-4 flex items-center gap-2 p-4">
                <img
                  src="/images/no-user.png"
                  alt="Profile"
                  className="rounded-lg border w-8 h-8"
                />
                <input
                  type="text"
                  placeholder="Write a comment..."
                  className={`w-full p-2 border rounded-lg focus:outline-none focus:ring-2
                   focus:ring-blue-500 ${mode === "light" ? "bg-[#ffff]" : "bg-transparent"}`}
                />
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default ViewTimelineDrawer;
