import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { IoFilter } from "react-icons/io5";
import { LiaTrashAlt } from "react-icons/lia";
import dayjs from "dayjs";
import CustomTable from "../../../components/CustomTable";
import Pagination from "../../../components/Pagination";
import CustomModal from "../../../components/CustomModal";
import axios from "axios";
import CreateTableView from "../../../components/CreateTableView";

export default function FormView() {
  const [filterModal, setFilterModal] = useState(false);
  const [addTaskModal, setAddTaskModal] = useState(false);
  const [viewTaskModal, setViewTaskModal] = useState(false);
  const [editTaskModal, setEditTaskModal] = useState(false);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });
  const [totalRows, setTotalRows] = useState(0);
  const [sorting, setSorting] = useState([{ id: "id", desc: false }]);
  const [rowSelection, setRowSelection] = useState({});

  const fetchData = async (pageIndex, pageSize, sorting) => {
    const sortField = sorting[0]?.id || "id";
    const sortDirection = sorting[0]?.desc ? "desc" : "asc";

    const response = await axios.get(`https://reqres.in/api/users`, {
      params: {
        page: pageIndex + 1,
        pageSize,
        sort: sortField,
        direction: sortDirection,
      },
    });
    setData(response.data.data);
    setTotalRows(response.data.total);
  };
  useEffect(() => {
    fetchData(pagination.pageIndex, pagination.pageSize, sorting);
  }, [pagination.pageIndex, pagination.pageSize, sorting]);
  const columns = [
    { accessorKey: "id", header: "Employee ID", enableSorting: true },
    { accessorKey: "firstName", header: "First Name", enableSorting: true },
    { accessorKey: "lastName", header: "Last Name", enableSorting: true },
    { accessorKey: "nickname", header: "Nick Name", enableSorting: true },
    { accessorKey: "email", header: "Email Address", enableSorting: true },
    { accessorKey: "department", header: "Department", enableSorting: true },
    { accessorKey: "designation", header: "Designation", enableSorting: true },
    { accessorKey: "clikkleHR", header: "Clikkle Role", enableSorting: true },
    {
      accessorKey: "employmentType",
      header: "Employment Type",
      enableSorting: true,
    },
    {
      accessorKey: "employeeStatus",
      header: "Employee Status",
      enableSorting: true,
    },
    {
      accessorKey: "sourceOfHire",
      header: "Source of Hire",
      enableSorting: true,
    },
    {
      accessorKey: "dateOfJoining",
      header: "Date of Joining",
      enableSorting: true,
    },
    {
      accessorKey: "currentExperience",
      header: "Current Experience",
      enableSorting: true,
    },
    {
      accessorKey: "totalExperience",
      header: "Total Experience",
      enableSorting: true,
    },
    {
      accessorKey: "reportingManager",
      header: "Reporting Manager",
      enableSorting: true,
    },
    {
      accessorKey: "dateOfBirth",
      header: "Date of Birth",
      enableSorting: true,
    },
    { accessorKey: "age", header: "Age", enableSorting: true },
    { accessorKey: "gender", header: "Gender", enableSorting: true },
    {
      accessorKey: "maritalStatus",
      header: "Marital Status",
      enableSorting: true,
    },
    { accessorKey: "aboutMe", header: "About Me", enableSorting: true },
    {
      accessorKey: "expertise",
      header: "Ask me about/Expertise",
      enableSorting: true,
    },
    {
      accessorKey: "workPhoneNumber",
      header: "Work Phone Number",
      enableSorting: true,
    },
    { accessorKey: "extension", header: "Extension", enableSorting: true },
    {
      accessorKey: "seatingLocation",
      header: "Seating Location",
      enableSorting: true,
    },
    { accessorKey: "tags", header: "Tags", enableSorting: true },
    {
      accessorKey: "personalMobileNumber",
      header: "Personal Mobile Number",
      enableSorting: true,
    },
    {
      accessorKey: "personalEmailAddress",
      header: "Personal Email Address",
      enableSorting: true,
    },
    { accessorKey: "dateOfExit", header: "Date of Exit", enableSorting: true },
    { accessorKey: "addedBy", header: "Added By", enableSorting: true },
    { accessorKey: "modifiedBy", header: "Modified By", enableSorting: true },
    { accessorKey: "addedTime", header: "Added Time", enableSorting: true },
    {
      accessorKey: "modifiedTime",
      header: "Modified Time",
      enableSorting: true,
    },
    {
      accessorKey: "onboardingStatus",
      header: "Onboarding Status",
      enableSorting: true,
    },
    {
      accessorKey: "presentAddress",
      header: "Present Address",
      enableSorting: true,
    },
    {
      accessorKey: "permanentAddress",
      header: "Permanent Address",
      enableSorting: true,
    },
    {
      accessorKey: "actions",
      header: "Actions",
    },
  ];
  const renderRowSelection = (table) => {
    return (
      <div>
        {Object.keys(rowSelection).length > 0 && (
          <Button variant="contained" color="error" onClick={() => {}}>
            Delete
          </Button>
        )}
      </div>
    );
  };
  const handleRowClick = (row) => {
    // setViewRecordModal(true);
  };
  const renderActions = (row) => (
    <IconButton
      onClick={(e) => {
        e.stopPropagation();
        console.log("Delete Button Clicked");
      }}
    >
      <LiaTrashAlt />
    </IconButton>
  );

  const addTaskModalFields = [
    {
      type: "autocomplete",
      name: "taskOwner",
      label: "Task Owner",
      options: ["Task Owner 1", "Task Owner 2"],
      defaultValue: "",
    },
    {
      type: "text",
      label: "Task Name",
      name: "taskName",
      defaultValue: "",
    },
    {
      type: "textarea",
      label: "Description",
      name: "description",
      defaultValue: "",
    },
    {
      type: "datePicker",
      label: "Start Date",
      name: "startDate",
      defaultValue: dayjs("2023-10-01"),
    },
    {
      type: "datePicker",
      label: "Due Date",
      name: "dueDate",
      defaultValue: dayjs("2023-10-01"),
    },
    {
      type: "dateTimePicker",
      label: "Reminder",
      name: "reminder",
      defaultValue: dayjs("2023-10-01"),
    },
    {
      type: "select",
      name: "priority",
      label: "Priority",
      options: [
        { label: "Select", value: "select" },
        { label: "Low", value: "low" },
        { label: "Moderate", value: "moderate" },
        { label: "High", value: "high" },
      ],
      defaultValue: "select",
    },
    {
      type: "select",
      name: "status",
      label: "Status",
      options: [
        { label: "Select", value: "select" },
        { label: "Open", value: "open" },
        { label: "Completed", value: "completed" },
      ],
      defaultValue: "select",
    },
  ];
  const handleAddTaskFormSubmit = (data) => {
    console.log("Add Form :- ", data);
  };

  const viewTaskModalFields = [
    {
      type: "autocomplete",
      name: "taskOwner",
      label: "Task Owner",
      options: ["Task Owner 1", "Task Owner 2"],
      defaultValue: "Task Owner 1",
    },
    {
      type: "text",
      label: "Task Name",
      name: "taskName",
      defaultValue: "Name",
    },
    {
      type: "textarea",
      label: "Description",
      name: "description",
      defaultValue: "Description",
    },
    {
      type: "datePicker",
      label: "Start Date",
      name: "startDate",
      defaultValue: dayjs("2023-10-01"),
    },
    {
      type: "datePicker",
      label: "Due Date",
      name: "dueDate",
      defaultValue: dayjs("2023-10-01"),
    },
    {
      type: "dateTimePicker",
      label: "Reminder",
      name: "reminder",
      defaultValue: dayjs("2023-10-01"),
    },
    {
      type: "text",
      label: "Assigned To",
      name: "assignedTo",
      defaultValue: "Assigned To",
    },
    {
      type: "select",
      name: "priority",
      label: "Priority",
      options: [
        { label: "Select", value: "select" },
        { label: "Low", value: "low" },
        { label: "Moderate", value: "moderate" },
        { label: "High", value: "high" },
      ],
      defaultValue: "select",
    },
    {
      type: "select",
      name: "status",
      label: "Status",
      options: [
        { label: "Select", value: "select" },
        { label: "Open", value: "open" },
        { label: "Completed", value: "completed" },
      ],
      defaultValue: "select",
    },
    {
      type: "text",
      label: "Completed On",
      name: "completedOn",
      defaultValue: "",
    },
    {
      type: "text",
      label: "Completed By",
      name: "completedBy",
      defaultValue: "",
    },
  ];

  const editTaskModalFields = [
    {
      type: "autocomplete",
      name: "taskOwner",
      label: "Task Owner",
      options: ["Task Owner 1", "Task Owner 2"],
      defaultValue: "Task Owner 1",
    },
    {
      type: "text",
      label: "Task Name",
      name: "taskName",
      defaultValue: "Task Name",
    },
    {
      type: "textarea",
      label: "Description",
      name: "description",
      defaultValue: "Description",
    },
    {
      type: "datePicker",
      label: "Start Date",
      name: "startDate",
      defaultValue: dayjs("2023-10-01"),
    },
    {
      type: "datePicker",
      label: "Due Date",
      name: "dueDate",
      defaultValue: dayjs("2023-10-01"),
    },
    {
      type: "dateTimePicker",
      label: "Reminder",
      name: "reminder",
      defaultValue: dayjs("2023-10-01"),
    },
    {
      type: "text",
      label: "Assigned To",
      name: "assignedTo",
      defaultValue: "Assigned To",
      disabled: true,
    },
    {
      type: "select",
      name: "priority",
      label: "Priority",
      options: [
        { label: "Select", value: "select" },
        { label: "Low", value: "low" },
        { label: "Moderate", value: "moderate" },
        { label: "High", value: "high" },
      ],
      defaultValue: "select",
    },
    {
      type: "select",
      name: "status",
      label: "Status",
      options: [
        { label: "Select", value: "select" },
        { label: "Open", value: "open" },
        { label: "Completed", value: "completed" },
      ],
      defaultValue: "select",
    },
    {
      type: "text",
      label: "Completed On",
      name: "completedOn",
      defaultValue: "Completed On",
      disabled: true,
    },
    {
      type: "text",
      label: "Completed By",
      name: "completedBy",
      defaultValue: "Completed On",
      disabled: true,
    },
  ];
  const handleEditTaskFormSubmit = (data) => {
    console.log("Edit Form :- ", data);
  };

  const filterModalFields = [
    {
      type: "autocomplete",
      name: "priority",
      label: "Priority",
      options: ["Priority 1", "Priority 2"],
      defaultValue: "",
    },
    {
      type: "autocomplete",
      name: "relatedForms",
      label: "Related Forms",
      options: ["Related Forms 1", "Related Forms 2"],
      defaultValue: "",
    },
  ];
  const handleFilterFormSubmit = (data) => {
    console.log("Filter Form :- ", data);
  };

  const [createTableViewModal, setCreateTableViewModal] = useState(false);
  const [createTableViewForm, setCreateTableViewForm] = useState({
    view_name: "",
    default: false,
    viewPermission: "onlyMe",
    permission: {
      users: ["8889"],
      // departments: ["1", "2"],
      // roles: ["1", "2"],
      // locations: ["1", "2"],
    },
    select_columns: [
      // {
      //   title: "EmployeeID",
      //   key: "employee.employee_id",
      // },
      // {
      //   title: "First Name",
      //   key: "employee.name",
      // },
    ],
    criterias: [
      {
        relationship: null,
        column: null,
        title: "",
        condition: null,
        value: null,
      },
    ],
  });
  const createTableViewColumns = [
    {
      label: "Task",
      key: "task",
      options: [
        { title: "Task Owner", name: "task_owner" },
        { title: "Task Name", name: "task_name" },
        { title: "Description", name: "description" },
        { title: "Start Date", name: "start_date" },
        { title: "Due Date", name: "due_date" },
        { title: "Reminder", name: "reminder" },
        { title: "Completed On", name: "completed_on" },
        { title: "Completed By", name: "completed_by" },
        { title: "Assigned To", name: "assigned_to" },
        { title: "Priority", name: "priority" },
        { title: "Status", name: "status" },
      ],
    },
    {
      label: "Task Owner",
      key: "task_owner",
      options: [
        { title: "Employee ID", name: "employee_id" },
        { title: "First Name", name: "first_name" },
        { title: "Last Name", name: "last_name" },
        { title: "Email Address", name: "email_address" },
        { title: "Nick Name", name: "nick_name" },
        { title: "Department", name: "department" },
        { title: "Location", name: "location" },
        { title: "Designation", name: "designation" },
        { title: "Clikkle Role", name: "clikkle_role" },
        { title: "Reporting Manager", name: "reporting_manager" },
        { title: "Date of Joining", name: "date_of_joining" },
        { title: "Seating Location", name: "seating_location" },
        { title: "Work Phone Number", name: "work_phone_number" },
        { title: "Extension", name: "extension" },
        { title: "Personal Email Address", name: "personal_email_address" },
        { title: "Date of Birth", name: "date_of_birth" },
        { title: "Personal Mobile Number", name: "personal_mobile_number" },
        { title: "Tags", name: "tags" },
        { title: "Ask Me About/Expertise", name: "expertise" },
        { title: "About Me", name: "about_me" },
        { title: "Date of Exit", name: "date_of_exit" },
        { title: "Gender", name: "gender" },
        { title: "Employment Type", name: "employment_type" },
        { title: "Employee Status", name: "employee_status" },
        { title: "Source of Hire", name: "source_of_hire" },
        { title: "Marital Status", name: "marital_status" },
        { title: "Age", name: "age" },
        { title: "Current Experience", name: "current_experience" },
        { title: "Onboarding Status", name: "onboarding_status" },
        { title: "Added Time", name: "added_time" },
        { title: "Modified Time", name: "modified_time" },
        { title: "Total Experience", name: "total_experience" },
        { title: "Present Address", name: "present_address" },
        { title: "Permanent Address", name: "permanent_address" },
      ],
    },
    {
      label: "Completed By",
      key: "completed_by",
      options: [
        { title: "Employee ID", name: "employee_id" },
        { title: "First Name", name: "first_name" },
        { title: "Last Name", name: "last_name" },
        { title: "Email Address", name: "email_address" },
        { title: "Nick Name", name: "nick_name" },
        { title: "Department", name: "department" },
        { title: "Location", name: "location" },
        { title: "Designation", name: "designation" },
        { title: "Clikkle Role", name: "clikkle_role" },
        { title: "Reporting Manager", name: "reporting_manager" },
        { title: "Date of Joining", name: "date_of_joining" },
        { title: "Seating Location", name: "seating_location" },
        { title: "Work Phone Number", name: "work_phone_number" },
        { title: "Extension", name: "extension" },
        { title: "Personal Email Address", name: "personal_email_address" },
        { title: "Date of Birth", name: "date_of_birth" },
        { title: "Personal Mobile Number", name: "personal_mobile_number" },
        { title: "Tags", name: "tags" },
        { title: "Ask Me About/Expertise", name: "expertise" },
        { title: "About Me", name: "about_me" },
        { title: "Date of Exit", name: "date_of_exit" },
        { title: "Gender", name: "gender" },
        { title: "Employment Type", name: "employment_type" },
        { title: "Employee Status", name: "employee_status" },
        { title: "Source of Hire", name: "source_of_hire" },
        { title: "Marital Status", name: "marital_status" },
        { title: "Age", name: "age" },
        { title: "Current Experience", name: "current_experience" },
        { title: "Onboarding Status", name: "onboarding_status" },
        { title: "Added Time", name: "added_time" },
        { title: "Modified Time", name: "modified_time" },
        { title: "Total Experience", name: "total_experience" },
        { title: "Present Address", name: "present_address" },
        { title: "Permanent Address", name: "permanent_address" },
      ],
    },
  ];
  const createTableViewSpecifics = [
    {
      label: "User",
      options: [
        { title: "Hello 1", id: "hello1" },
        { title: "Hello 2", id: "hello2" },
      ],
    },
    {
      label: "Department",
      options: [
        { title: "Hello 1", id: "hello1" },
        { title: "Hello 2", id: "hello2" },
      ],
    },
    {
      label: "Role",
      options: [
        { title: "Hello 1", id: "hello1" },
        { title: "Hello 2", id: "hello2" },
      ],
    },
    {
      label: "Locations",
      options: [
        { title: "Hello 1", id: "hello1" },
        { title: "Hello 2", id: "hello2" },
      ],
    },
  ];
  const createTableViewCriterias = [
    {
      label: "Task",
      key: "task",
      options: [
        { key: "task", title: "Task Owner", name: "task_owner" },
        { key: "task", title: "Task Name", name: "task_name" },
        { key: "task", title: "Description", name: "description" },
        { key: "task", title: "Start Date", name: "start_date" },
        { key: "task", title: "Due Date", name: "due_date" },
        { key: "task", title: "Reminder", name: "reminder" },
        { key: "task", title: "Completed On", name: "completed_on" },
        { key: "task", title: "Completed By", name: "completed_by" },
        { key: "task", title: "Assigned To", name: "assigned_to" },
        { key: "task", title: "Priority", name: "priority" },
        { key: "task", title: "Status", name: "status" },
      ],
    },
    {
      label: "Task Owner",
      key: "task_owner",
      options: [
        { key: "task_owner", title: "Employee ID", name: "employee_id" },
        { key: "task_owner", title: "First Name", name: "first_name" },
        { key: "task_owner", title: "Last Name", name: "last_name" },
        { key: "task_owner", title: "Email Address", name: "email_address" },
        { key: "task_owner", title: "Nick Name", name: "nick_name" },
        { key: "task_owner", title: "Department", name: "department" },
        { key: "task_owner", title: "Location", name: "location" },
        { key: "task_owner", title: "Designation", name: "designation" },
        { key: "task_owner", title: "Clikkle Role", name: "clikkle_role" },
        {
          key: "task_owner",
          title: "Reporting Manager",
          name: "reporting_manager",
        },
        {
          key: "task_owner",
          title: "Date of Joining",
          name: "date_of_joining",
        },
        {
          key: "task_owner",
          title: "Seating Location",
          name: "seating_location",
        },
        {
          key: "task_owner",
          title: "Work Phone Number",
          name: "work_phone_number",
        },
        { key: "task_owner", title: "Extension", name: "extension" },
        {
          key: "task_owner",
          title: "Personal Email Address",
          name: "personal_email_address",
        },
        { key: "task_owner", title: "Date of Birth", name: "date_of_birth" },
        {
          key: "task_owner",
          title: "Personal Mobile Number",
          name: "personal_mobile_number",
        },
        { key: "task_owner", title: "Tags", name: "tags" },
        {
          key: "task_owner",
          title: "Ask Me About/Expertise",
          name: "expertise",
        },
        { key: "task_owner", title: "About Me", name: "about_me" },
        { key: "task_owner", title: "Date of Exit", name: "date_of_exit" },
        { key: "task_owner", title: "Gender", name: "gender" },
        {
          key: "task_owner",
          title: "Employment Type",
          name: "employment_type",
        },
        {
          key: "task_owner",
          title: "Employee Status",
          name: "employee_status",
        },
        { key: "task_owner", title: "Source of Hire", name: "source_of_hire" },
        { key: "task_owner", title: "Marital Status", name: "marital_status" },
        { key: "task_owner", title: "Age", name: "age" },
        {
          key: "task_owner",
          title: "Current Experience",
          name: "current_experience",
        },
        {
          key: "task_owner",
          title: "Onboarding Status",
          name: "onboarding_status",
        },
        { key: "task_owner", title: "Added Time", name: "added_time" },
        { key: "task_owner", title: "Modified Time", name: "modified_time" },
        {
          key: "task_owner",
          title: "Total Experience",
          name: "total_experience",
        },
        {
          key: "task_owner",
          title: "Present Address",
          name: "present_address",
        },
        {
          key: "task_owner",
          title: "Permanent Address",
          name: "permanent_address",
        },
      ],
    },
    {
      label: "Completed By",
      key: "completed_by",
      options: [
        { key: "completed_by", title: "Employee ID", name: "employee_id" },
        { key: "completed_by", title: "First Name", name: "first_name" },
        { key: "completed_by", title: "Last Name", name: "last_name" },
        { key: "completed_by", title: "Email Address", name: "email_address" },
        { key: "completed_by", title: "Nick Name", name: "nick_name" },
        { key: "completed_by", title: "Department", name: "department" },
        { key: "completed_by", title: "Location", name: "location" },
        { key: "completed_by", title: "Designation", name: "designation" },
        { key: "completed_by", title: "Clikkle Role", name: "clikkle_role" },
        {
          key: "completed_by",
          title: "Reporting Manager",
          name: "reporting_manager",
        },
        {
          key: "completed_by",
          title: "Date of Joining",
          name: "date_of_joining",
        },
        {
          key: "completed_by",
          title: "Seating Location",
          name: "seating_location",
        },
        {
          key: "completed_by",
          title: "Work Phone Number",
          name: "work_phone_number",
        },
        { key: "completed_by", title: "Extension", name: "extension" },
        {
          key: "completed_by",
          title: "Personal Email Address",
          name: "personal_email_address",
        },
        { key: "completed_by", title: "Date of Birth", name: "date_of_birth" },
        {
          key: "completed_by",
          title: "Personal Mobile Number",
          name: "personal_mobile_number",
        },
        { key: "completed_by", title: "Tags", name: "tags" },
        {
          key: "completed_by",
          title: "Ask Me About/Expertise",
          name: "expertise",
        },
        { key: "completed_by", title: "About Me", name: "about_me" },
        { key: "completed_by", title: "Date of Exit", name: "date_of_exit" },
        { key: "completed_by", title: "Gender", name: "gender" },
        {
          key: "completed_by",
          title: "Employment Type",
          name: "employment_type",
        },
        {
          key: "completed_by",
          title: "Employee Status",
          name: "employee_status",
        },
        {
          key: "completed_by",
          title: "Source of Hire",
          name: "source_of_hire",
        },
        {
          key: "completed_by",
          title: "Marital Status",
          name: "marital_status",
        },
        { key: "completed_by", title: "Age", name: "age" },
        {
          key: "completed_by",
          title: "Current Experience",
          name: "current_experience",
        },
        {
          key: "completed_by",
          title: "Onboarding Status",
          name: "onboarding_status",
        },
        { key: "completed_by", title: "Added Time", name: "added_time" },
        { key: "completed_by", title: "Modified Time", name: "modified_time" },
        {
          key: "completed_by",
          title: "Total Experience",
          name: "total_experience",
        },
        {
          key: "completed_by",
          title: "Present Address",
          name: "present_address",
        },
        {
          key: "completed_by",
          title: "Permanent Address",
          name: "permanent_address",
        },
      ],
    },
  ];
  const createTableViewCriteriasOptions = [
    {
      label: "Task",
      key: "task",
      options: [
        {
          type: "none",
          title: "Logged In User",
          key: "task",
          name: "logged_in_user",
          show: ["task.task_owner", "task.completed_by"],
        },

        {
          type: "select",
          options: [
            { title: "User 1", key: "task", name: "user_1" },
            { title: "User 2", key: "task", name: "user_2" },
          ],
          title: "Is",
          key: "task",
          name: "is",
          show: ["task.task_owner", "task.completed_by"],
          multiple: true,
        },
        {
          type: "select",
          options: [
            { title: "User 1", key: "task", name: "user_1" },
            { title: "User 2", key: "task", name: "user_2" },
          ],
          title: "Is Not",
          key: "task",
          name: "is_not",
          multiple: true,
          show: ["task.task_owner", "task.completed_by"],
        },

        {
          type: "datetime",
          key: "task",
          title: "Is",
          name: "is",
          show: [
            "task.start_date",
            "task.due_date",
            "task.reminder",
            "task.completed_on",
          ],
        },
        {
          type: "datetime",
          key: "task",
          title: "Is Not",
          name: "is_not",
          show: [
            "task.start_date",
            "task.due_date",
            "task.reminder",
            "task.completed_on",
          ],
        },

        {
          type: "select",
          options: [
            { title: "Low", key: "task", name: "low" },
            { title: "Moderate", key: "task", name: "moderate" },
            { title: "High", key: "task", name: "high" },
          ],
          title: "Is",
          key: "task",
          name: "is",
          show: ["task.priority"],
          multiple: true,
        },
        {
          type: "select",
          options: [
            { title: "Low", key: "task", name: "low" },
            { title: "Moderate", key: "task", name: "moderate" },
            { title: "High", key: "task", name: "high" },
          ],
          title: "Is Not",
          key: "task",
          name: "is_not",
          show: ["task.priority"],
          multiple: true,
        },

        {
          type: "select",
          options: [
            { title: "Open", key: "task", name: "open" },
            { title: "completed", key: "task", name: "completed" },
            { title: "Other 1", key: "task", name: "other_1" },
          ],
          title: "Is",
          key: "task",
          name: "is",
          show: ["task.status"],
          multiple: true,
        },
        {
          type: "select",
          options: [
            { title: "Open", key: "task", name: "open" },
            { title: "completed", key: "task", name: "completed" },
            { title: "Other 1", key: "task", name: "other_1" },
          ],
          title: "Is Not",
          key: "task",
          name: "is_not",
          show: ["task.status"],
          multiple: true,
        },

        {
          type: "text",
          key: "task",
          title: "Is",
          name: "is",
          show: ["task.task_name", "task.description", "task.assigned_to"],
        },
        {
          type: "text",
          key: "task",
          title: "Is Not",
          name: "is_not",
          show: ["task.task_name", "task.description", "task.assigned_to"],
        },
        {
          type: "none",
          title: "Is Empty",
          key: "task",
          name: "is_empty",
          show: [
            "task.task_owner",
            "task.task_name",
            "task.description",
            "task.start_date",
            "task.due_date",
            "task.reminder",
            "task.completed_on",
            "task.completed_by",
            "task.assigned_to",
            "task.priority",
            "task.status",
          ],
        },
        {
          type: "none",
          title: "Is Empty Not",
          key: "task",
          name: "is_empty_not",
          show: [
            "task.task_owner",
            "task.task_name",
            "task.description",
            "task.start_date",
            "task.due_date",
            "task.reminder",
            "task.completed_on",
            "task.completed_by",
            "task.assigned_to",
            "task.priority",
            "task.status",
          ],
        },
        {
          type: "text",
          title: "Start With",
          key: "task",
          name: "start_with",
          show: [
            "task.task_owner",
            "task.task_name",
            "task.description",
            "task.completed_by",
            "task.assigned_to",
          ],
        },
        {
          type: "text",
          title: "End With",
          key: "task",
          name: "end_with",
          show: [
            "task.task_owner",
            "task.task_name",
            "task.description",
            "task.completed_by",
            "task.assigned_to",
          ],
        },
        {
          type: "text",
          title: "Contains",
          key: "task",
          name: "contains",
          show: [
            "task.task_owner",
            "task.task_name",
            "task.description",
            "task.completed_by",
            "task.assigned_to",
            "task.priority",
            "task.status",
          ],
        },
        {
          type: "text",
          title: "Not Contains",
          key: "task",
          name: "not_contains",
          show: [
            "task.task_owner",
            "task.task_name",
            "task.description",
            "task.completed_by",
            "task.assigned_to",
            "task.priority",
            "task.status",
          ],
        },
        {
          type: "datetime",
          key: "task",
          title: "Before",
          name: "before",
          show: [
            "task.start_date",
            "task.due_date",
            "task.reminder",
            "task.completed_on",
          ],
        },
        {
          type: "datetime",
          key: "task",
          title: "After",
          name: "After",
          show: [
            "task.start_date",
            "task.due_date",
            "task.reminder",
            "task.completed_on",
          ],
        },
        {
          type: "none",
          key: "task",
          title: "Yesterday",
          name: "yesterday",
          show: [
            "task.start_date",
            "task.due_date",
            "task.reminder",
            "task.completed_on",
          ],
        },
        {
          type: "none",
          key: "task",
          title: "Today",
          name: "today",
          show: [
            "task.start_date",
            "task.due_date",
            "task.reminder",
            "task.completed_on",
          ],
        },
        {
          type: "none",
          key: "task",
          title: "Tomorrow",
          name: "tomorrow",
          show: [
            "task.start_date",
            "task.due_date",
            "task.reminder",
            "task.completed_on",
          ],
        },
        {
          type: "none",
          key: "task",
          title: "Last Week",
          name: "last_week",
          show: [
            "task.start_date",
            "task.due_date",
            "task.reminder",
            "task.completed_on",
          ],
        },
        {
          type: "none",
          key: "task",
          title: "This Week",
          name: "this_week",
          show: [
            "task.start_date",
            "task.due_date",
            "task.reminder",
            "task.completed_on",
          ],
        },
        {
          type: "none",
          key: "task",
          title: "Next Week",
          name: "next_week",
          show: [
            "task.start_date",
            "task.due_date",
            "task.reminder",
            "task.completed_on",
          ],
        },
        {
          type: "none",
          key: "task",
          title: "Last Month",
          name: "last_month",
          show: [
            "task.start_date",
            "task.due_date",
            "task.reminder",
            "task.completed_on",
          ],
        },
        {
          type: "none",
          key: "task",
          title: "This Month",
          name: "this_month",
          show: [
            "task.start_date",
            "task.due_date",
            "task.reminder",
            "task.completed_on",
          ],
        },
        {
          type: "none",
          key: "task",
          title: "Next Month",
          name: "next_month",
          show: [
            "task.start_date",
            "task.due_date",
            "task.reminder",
            "task.completed_on",
          ],
        },
        {
          type: "none",
          key: "task",
          title: "Last Year",
          name: "last_year",
          show: [
            "task.start_date",
            "task.due_date",
            "task.reminder",
            "task.completed_on",
          ],
        },
        {
          type: "none",
          key: "task",
          title: "This Year",
          name: "this_year",
          show: [
            "task.start_date",
            "task.due_date",
            "task.reminder",
            "task.completed_on",
          ],
        },
        {
          type: "none",
          key: "task",
          title: "Next Year",
          name: "next_year",
          show: [
            "task.start_date",
            "task.due_date",
            "task.reminder",
            "task.completed_on",
          ],
        },
      ],
    },
    {
      label: "Task Owner",
      key: "task_owner",
      options: [
        {
          type: "none",
          title: "Logged In User",
          key: "task",
          name: "logged_in_user",
          show: ["task_owner.reporting_manager"],
        },

        {
          type: "select",
          options: [
            { title: "User 1", key: "task_owner", name: "user_1" },
            { title: "User 2", key: "task_owner", name: "user_2" },
          ],
          title: "Is",
          key: "task_owner",
          name: "is",
          show: ["task_owner.reporting_manager"],
          multiple: true,
        },
        {
          type: "select",
          options: [
            { title: "User 1", key: "task_owner", name: "user_1" },
            { title: "User 2", key: "task_owner", name: "user_2" },
          ],
          title: "Is Not",
          key: "task_owner",
          name: "is_not",
          multiple: true,
          show: ["task_owner.reporting_manager"],
        },

        {
          type: "select",
          options: [
            { title: "Low", key: "task_owner", name: "low" },
            { title: "Moderate", key: "task_owner", name: "moderate" },
            { title: "High", key: "task_owner", name: "high" },
          ],
          title: "Is",
          key: "task_owner",
          name: "is",
          show: ["task_owner.priority"],
          multiple: true,
        },
        {
          type: "select",
          options: [
            { title: "Low", key: "task_owner", name: "low" },
            { title: "Moderate", key: "task_owner", name: "moderate" },
            { title: "High", key: "task_owner", name: "high" },
          ],
          title: "Is Not",
          key: "task_owner",
          name: "is_not",
          show: ["task_owner.priority"],
          multiple: true,
        },

        {
          type: "select",
          options: [
            { title: "Open", key: "task_owner", name: "open" },
            { title: "completed", key: "task_owner", name: "completed" },
            { title: "Other 1", key: "task_owner", name: "other_1" },
          ],
          title: "Is",
          key: "task_owner",
          name: "is",
          show: ["task_owner.status"],
          multiple: true,
        },
        {
          type: "select",
          options: [
            { title: "Open", key: "task_owner", name: "open" },
            { title: "completed", key: "task_owner", name: "completed" },
            { title: "Other 1", key: "task_owner", name: "other_1" },
          ],
          title: "Is Not",
          key: "task_owner",
          name: "is_not",
          show: ["task_owner.status"],
          multiple: true,
        },

        {
          type: "select",
          options: [
            { title: "Department 1", key: "task_owner", name: "department_1" },
            { title: "Department 2", key: "task_owner", name: "department_2" },
          ],
          title: "Is",
          key: "task_owner",
          name: "is",
          show: ["task_owner.department"],
          multiple: true,
        },
        {
          type: "select",
          options: [
            { title: "Department 1", key: "task_owner", name: "department_1" },
            { title: "Department 2", key: "task_owner", name: "department_2" },
          ],
          title: "Is Not",
          key: "task_owner",
          name: "is_not",
          show: ["task_owner.department"],
          multiple: true,
        },

        {
          type: "select",
          options: [
            { title: "Location 1", key: "task_owner", name: "location_1" },
            { title: "Location 2", key: "task_owner", name: "location_2" },
          ],
          title: "Is",
          key: "task_owner",
          name: "is",
          show: ["task_owner.location"],
          multiple: true,
        },
        {
          type: "select",
          options: [
            { title: "Location 1", key: "task_owner", name: "location_1" },
            { title: "Location 2", key: "task_owner", name: "location_2" },
          ],
          title: "Is Not",
          key: "task_owner",
          name: "is_not",
          show: ["task_owner.location"],
          multiple: true,
        },

        {
          type: "select",
          options: [
            {
              title: "Designation 1",
              key: "task_owner",
              name: "designation_1",
            },
            {
              title: "Designation 2",
              key: "task_owner",
              name: "designation_2",
            },
          ],
          title: "Is",
          key: "task_owner",
          name: "is",
          show: ["task_owner.designation"],
          multiple: true,
        },
        {
          type: "select",
          options: [
            {
              title: "Designation 1",
              key: "task_owner",
              name: "designation_1",
            },
            {
              title: "Designation 2",
              key: "task_owner",
              name: "designation_2",
            },
          ],
          title: "Is Not",
          key: "task_owner",
          name: "is_not",
          show: ["task_owner.designation"],
          multiple: true,
        },

        {
          type: "select",
          options: [
            {
              title: "Clikkle Role 1",
              key: "task_owner",
              name: "clikkle_hr_1",
            },
            {
              title: "Clikkle Role 2",
              key: "task_owner",
              name: "clikkle_hr_2",
            },
          ],
          title: "Is",
          key: "task_owner",
          name: "is",
          show: ["task_owner.clikkle_role"],
          multiple: true,
        },
        {
          type: "select",
          options: [
            {
              title: "Clikkle Role 1",
              key: "task_owner",
              name: "clikkle_hr_1",
            },
            {
              title: "Clikkle Role 2",
              key: "task_owner",
              name: "clikkle_hr_2",
            },
          ],
          title: "Is Not",
          key: "task_owner",
          name: "is_not",
          show: ["task_owner.clikkle_role"],
          multiple: true,
        },

        {
          type: "select",
          options: [
            {
              title: "Employment Type 1",
              key: "task_owner",
              name: "employment_type_1",
            },
            {
              title: "Employment Type 2",
              key: "task_owner",
              name: "employment_type_2",
            },
          ],
          title: "Is",
          key: "task_owner",
          name: "is",
          show: ["task_owner.employment_type"],
          multiple: true,
        },
        {
          type: "select",
          options: [
            {
              title: "Employment Type 1",
              key: "task_owner",
              name: "employment_type_1",
            },
            {
              title: "Employment Type 2",
              key: "task_owner",
              name: "employment_type_2",
            },
          ],
          title: "Is Not",
          key: "task_owner",
          name: "is_not",
          show: ["task_owner.employment_type"],
          multiple: true,
        },

        {
          type: "select",
          options: [
            {
              title: "Employee Status 1",
              key: "task_owner",
              name: "employee_status_1",
            },
            {
              title: "Employee Status 2",
              key: "task_owner",
              name: "employee_status_2",
            },
          ],
          title: "Is",
          key: "task_owner",
          name: "is",
          show: ["task_owner.employee_status"],
          multiple: true,
        },
        {
          type: "select",
          options: [
            {
              title: "Employee Status 1",
              key: "task_owner",
              name: "employee_status_1",
            },
            {
              title: "Employee Status 2",
              key: "task_owner",
              name: "employee_status_2",
            },
          ],
          title: "Is Not",
          key: "task_owner",
          name: "is_not",
          show: ["task_owner.employee_status"],
          multiple: true,
        },

        {
          type: "select",
          options: [
            {
              title: "Source Of Hire 1",
              key: "task_owner",
              name: "source_of_hire_1",
            },
            {
              title: "Source Of Hire 2",
              key: "task_owner",
              name: "source_of_hire_2",
            },
          ],
          title: "Is",
          key: "task_owner",
          name: "is",
          show: ["task_owner.source_of_hire"],
          multiple: true,
        },
        {
          type: "select",
          options: [
            {
              title: "Source Of Hire 1",
              key: "task_owner",
              name: "source_of_hire_1",
            },
            {
              title: "Source Of Hire 2",
              key: "task_owner",
              name: "source_of_hire_2",
            },
          ],
          title: "Is Not",
          key: "task_owner",
          name: "is_not",
          show: ["task_owner.source_of_hire"],
          multiple: true,
        },

        {
          type: "select",
          options: [
            {
              title: "Onboarding Status 1",
              key: "task_owner",
              name: "onboarding_status_1",
            },
            {
              title: "Onboarding Status 2",
              key: "task_owner",
              name: "onboarding_status_2",
            },
          ],
          title: "Is",
          key: "task_owner",
          name: "is",
          show: ["task_owner.onboarding_status"],
          multiple: true,
        },
        {
          type: "select",
          options: [
            {
              title: "Onboarding Status 1",
              key: "task_owner",
              name: "onboarding_status_1",
            },
            {
              title: "Onboarding Status 2",
              key: "task_owner",
              name: "onboarding_status_2",
            },
          ],
          title: "Is Not",
          key: "task_owner",
          name: "is_not",
          show: ["task_owner.onboarding_status"],
          multiple: true,
        },

        {
          type: "select",
          options: [
            {
              title: "Single",
              key: "task_owner",
              name: "single",
            },
            {
              title: "Married",
              key: "task_owner",
              name: "married",
            },
          ],
          title: "Is",
          key: "task_owner",
          name: "is",
          show: ["task_owner.marital_status"],
          multiple: true,
        },
        {
          type: "select",
          options: [
            {
              title: "Single",
              key: "task_owner",
              name: "single",
            },
            {
              title: "Married",
              key: "task_owner",
              name: "married",
            },
          ],
          title: "Is Not",
          key: "task_owner",
          name: "is_not",
          show: ["task_owner.marital_status"],
          multiple: true,
        },

        {
          type: "datetime",
          key: "task_owner",
          title: "Is",
          name: "is",
          show: [
            "task_owner.date_of_joining",
            "task_owner.date_of_birth",
            "task_owner.date_of_exit",
            "task_owner.added_time",
            "task_owner.modified_time",
          ],
        },
        {
          type: "datetime",
          key: "task_owner",
          title: "Is Not",
          name: "is_not",
          show: [
            "task_owner.date_of_joining",
            "task_owner.date_of_birth",
            "task_owner.date_of_exit",
            "task_owner.added_time",
            "task_owner.modified_time",
          ],
        },

        {
          type: "text",
          key: "task_owner",
          title: "Is",
          name: "is",
          show: [
            "task_owner.employee_id",
            "task_owner.first_name",
            "task_owner.last_name",
            "task_owner.email_address",
            "task_owner.nick_name",
            "task_owner.seating_location",
            "task_owner.work_phone_number",
            "task_owner.extension",
            "task_owner.personal_email_address",
            "task_owner.personal_mobile_number",
            "task_owner.tags",
            "task_owner.expertise",
            "task_owner.about_me",
            "task_owner.gender",
            "task_owner.age",
            "task_owner.current_experience",
            "task_owner.total_experience",
            "task_owner.present_address",
            "task_owner.permanent_address",
          ],
        },
        {
          type: "text",
          key: "task_owner",
          title: "Is Not",
          name: "is_not",
          show: [
            "task_owner.employee_id",
            "task_owner.first_name",
            "task_owner.last_name",
            "task_owner.email_address",
            "task_owner.nick_name",
            "task_owner.seating_location",
            "task_owner.work_phone_number",
            "task_owner.extension",
            "task_owner.personal_email_address",
            "task_owner.personal_mobile_number",
            "task_owner.tags",
            "task_owner.expertise",
            "task_owner.about_me",
            "task_owner.gender",
            "task_owner.age",
            "task_owner.current_experience",
            "task_owner.total_experience",
            "task_owner.present_address",
            "task_owner.permanent_address",
          ],
        },
        {
          type: "none",
          title: "Is Empty",
          key: "task_owner",
          name: "is_empty",
          show: [
            "task_owner.employee_id",
            "task_owner.modified_time",
            "task_owner.first_name",
            "task_owner.last_name",
            "task_owner.email_address",
            "task_owner.nick_name",
            "task_owner.department",
            "task_owner.location",
            "task_owner.designation",
            "task_owner.clikkle_role",
            "task_owner.reporting_manager",
            "task_owner.seating_location",
            "task_owner.work_phone_number",
            "task_owner.extension",
            "task_owner.personal_email_address",
            "task_owner.date_of_birth",
            "task_owner.personal_mobile_number",
            "task_owner.tags",
            "task_owner.expertise",
            "task_owner.about_me",
            "task_owner.date_of_exit",
            "task_owner.gender",
            "task_owner.employment_type",
            "task_owner.employee_status",
            "task_owner.source_of_hire",
            "task_owner.marital_status",
            "task_owner.age",
            "task_owner.current_experience",
            "task_owner.onboarding_status",
            "task_owner.total_experience",
            "task_owner.present_address",
            "task_owner.permanent_address",
          ],
        },
        {
          type: "none",
          title: "Is Empty Not",
          key: "task_owner",
          name: "is_empty_not",
          show: [
            "task_owner.employee_id",
            "task_owner.modified_time",
            "task_owner.first_name",
            "task_owner.last_name",
            "task_owner.email_address",
            "task_owner.nick_name",
            "task_owner.department",
            "task_owner.location",
            "task_owner.designation",
            "task_owner.clikkle_role",
            "task_owner.reporting_manager",
            "task_owner.seating_location",
            "task_owner.work_phone_number",
            "task_owner.extension",
            "task_owner.personal_email_address",
            "task_owner.date_of_birth",
            "task_owner.personal_mobile_number",
            "task_owner.tags",
            "task_owner.expertise",
            "task_owner.about_me",
            "task_owner.date_of_exit",
            "task_owner.gender",
            "task_owner.employment_type",
            "task_owner.employee_status",
            "task_owner.source_of_hire",
            "task_owner.marital_status",
            "task_owner.age",
            "task_owner.current_experience",
            "task_owner.onboarding_status",
            "task_owner.total_experience",
            "task_owner.present_address",
            "task_owner.permanent_address",
          ],
        },
        {
          type: "text",
          title: "Start With",
          key: "task_owner",
          name: "start_with",
          show: [
            "task_owner.employee_id",
            "task_owner.first_name",
            "task_owner.last_name",
            "task_owner.email_address",
            "task_owner.nick_name",
            "task_owner.department",
            "task_owner.location",
            "task_owner.designation",
            "task_owner.clikkle_role",
            "task_owner.reporting_manager",
            "task_owner.seating_location",
            "task_owner.work_phone_number",
            "task_owner.extension",
            "task_owner.personal_email_address",
            "task_owner.personal_mobile_number",
            "task_owner.tags",
            "task_owner.expertise",
            "task_owner.about_me",
            "task_owner.gender",
            "task_owner.present_address",
            "task_owner.permanent_address",
          ],
        },
        {
          type: "text",
          title: "End With",
          key: "task_owner",
          name: "end_with",
          show: [
            "task_owner.employee_id",
            "task_owner.first_name",
            "task_owner.last_name",
            "task_owner.email_address",
            "task_owner.nick_name",
            "task_owner.department",
            "task_owner.location",
            "task_owner.designation",
            "task_owner.clikkle_role",
            "task_owner.reporting_manager",
            "task_owner.seating_location",
            "task_owner.work_phone_number",
            "task_owner.extension",
            "task_owner.personal_email_address",
            "task_owner.personal_mobile_number",
            "task_owner.tags",
            "task_owner.expertise",
            "task_owner.about_me",
            "task_owner.gender",
            "task_owner.present_address",
            "task_owner.permanent_address",
          ],
        },
        {
          type: "text",
          title: "Contains",
          key: "task_owner",
          name: "contains",
          show: [
            "task_owner.employee_id",
            "task_owner.first_name",
            "task_owner.last_name",
            "task_owner.email_address",
            "task_owner.nick_name",
            "task_owner.department",
            "task_owner.location",
            "task_owner.designation",
            "task_owner.clikkle_role",
            "task_owner.reporting_manager",
            "task_owner.seating_location",
            "task_owner.work_phone_number",
            "task_owner.extension",
            "task_owner.personal_email_address",
            "task_owner.personal_mobile_number",
            "task_owner.tags",
            "task_owner.expertise",
            "task_owner.about_me",
            "task_owner.gender",
            "task_owner.employment_type",
            "task_owner.employee_status",
            "task_owner.source_of_hire",
            "task_owner.marital_status",
            "task_owner.onboarding_status",
            "task_owner.present_address",
            "task_owner.permanent_address",
          ],
        },
        {
          type: "text",
          title: "Not Contains",
          key: "task_owner",
          name: "not_contains",
          show: [
            "task_owner.employee_id",
            "task_owner.first_name",
            "task_owner.last_name",
            "task_owner.email_address",
            "task_owner.nick_name",
            "task_owner.department",
            "task_owner.location",
            "task_owner.designation",
            "task_owner.clikkle_role",
            "task_owner.reporting_manager",
            "task_owner.seating_location",
            "task_owner.work_phone_number",
            "task_owner.extension",
            "task_owner.personal_email_address",
            "task_owner.personal_mobile_number",
            "task_owner.tags",
            "task_owner.expertise",
            "task_owner.about_me",
            "task_owner.gender",
            "task_owner.employment_type",
            "task_owner.employee_status",
            "task_owner.source_of_hire",
            "task_owner.marital_status",
            "task_owner.onboarding_status",
            "task_owner.present_address",
            "task_owner.permanent_address",
          ],
        },
        {
          type: "text",
          title: "Lesser Than",
          key: "task_owner",
          name: "lesser_than",
          show: ["task_owner.age", "task_owner.current_experience", "task_owner.total_experience" ],
        },
        {
          type: "text",
          title: "Greater Than",
          key: "task_owner",
          name: "greater_than",
          show: ["task_owner.age", "task_owner.current_experience", "task_owner.total_experience" ],
        },
        {
          type: "text",
          title: "Lesser Or Equal",
          key: "task_owner",
          name: "lesser_or_equal",
          show: ["task_owner.age", "task_owner.current_experience", "task_owner.total_experience" ],
        },
        {
          type: "text",
          title: "Greater Or Equal",
          key: "task_owner",
          name: "greater_or_equal",
          show: ["task_owner.age", "task_owner.current_experience", "task_owner.total_experience" ],
        },

        {
          type: "datetime",
          key: "task_owner",
          title: "Before",
          name: "before",
          show: [
            "task_owner.modified_time",
            "task_owner.date_of_joining",
            "task_owner.date_of_birth",
            "task_owner.date_of_exit",
            "task_owner.added_time",
            "task_owner.modified_time",
          ],
        },
        {
          type: "datetime",
          key: "task_owner",
          title: "After",
          name: "After",
          show: [
            "task_owner.modified_time",
            "task_owner.date_of_joining",
            "task_owner.date_of_birth",
            "task_owner.date_of_exit",
            "task_owner.added_time",
            "task_owner.modified_time",
          ],
        },
        {
          type: "none",
          key: "task_owner",
          title: "Yesterday",
          name: "yesterday",
          show: [
            "task_owner.modified_time",
            "task_owner.date_of_joining",
            "task_owner.date_of_birth",
            "task_owner.date_of_exit",
            "task_owner.added_time",
            "task_owner.modified_time",
          ],
        },
        {
          type: "none",
          key: "task_owner",
          title: "Today",
          name: "today",
          show: [
            "task_owner.modified_time",
            "task_owner.date_of_joining",
            "task_owner.date_of_birth",
            "task_owner.date_of_exit",
            "task_owner.added_time",
            "task_owner.modified_time",
          ],
        },
        {
          type: "none",
          key: "task_owner",
          title: "Tomorrow",
          name: "tomorrow",
          show: [
            "task_owner.modified_time",
            "task_owner.date_of_joining",
            "task_owner.date_of_birth",
            "task_owner.date_of_exit",
            "task_owner.added_time",
            "task_owner.modified_time",
          ],
        },
        {
          type: "none",
          key: "task_owner",
          title: "Last Week",
          name: "last_week",
          show: [
            "task_owner.modified_time",
            "task_owner.date_of_joining",
            "task_owner.date_of_birth",
            "task_owner.date_of_exit",
            "task_owner.added_time",
            "task_owner.modified_time",
          ],
        },
        {
          type: "none",
          key: "task_owner",
          title: "This Week",
          name: "this_week",
          show: [
            "task_owner.modified_time",
            "task_owner.date_of_joining",
            "task_owner.date_of_birth",
            "task_owner.date_of_exit",
            "task_owner.added_time",
            "task_owner.modified_time",
          ],
        },
        {
          type: "none",
          key: "task_owner",
          title: "Next Week",
          name: "next_week",
          show: [
            "task_owner.modified_time",
            "task_owner.date_of_joining",
            "task_owner.date_of_birth",
            "task_owner.date_of_exit",
            "task_owner.added_time",
            "task_owner.modified_time",
          ],
        },
        {
          type: "none",
          key: "task_owner",
          title: "Last Month",
          name: "last_month",
          show: [
            "task_owner.modified_time",
            "task_owner.date_of_joining",
            "task_owner.date_of_birth",
            "task_owner.date_of_exit",
            "task_owner.added_time",
            "task_owner.modified_time",
          ],
        },
        {
          type: "none",
          key: "task_owner",
          title: "This Month",
          name: "this_month",
          show: [
            "task_owner.modified_time",
            "task_owner.date_of_joining",
            "task_owner.date_of_birth",
            "task_owner.date_of_exit",
            "task_owner.added_time",
            "task_owner.modified_time",
          ],
        },
        {
          type: "none",
          key: "task_owner",
          title: "Next Month",
          name: "next_month",
          show: [
            "task_owner.modified_time",
            "task_owner.date_of_joining",
            "task_owner.date_of_birth",
            "task_owner.date_of_exit",
            "task_owner.added_time",
            "task_owner.modified_time",
          ],
        },
        {
          type: "none",
          key: "task_owner",
          title: "Last Year",
          name: "last_year",
          show: [
            "task_owner.modified_time",
            "task_owner.date_of_joining",
            "task_owner.date_of_birth",
            "task_owner.date_of_exit",
            "task_owner.added_time",
            "task_owner.modified_time",
          ],
        },
        {
          type: "none",
          key: "task_owner",
          title: "This Year",
          name: "this_year",
          show: [
            "task_owner.modified_time",
            "task_owner.date_of_joining",
            "task_owner.date_of_birth",
            "task_owner.date_of_exit",
            "task_owner.added_time",
            "task_owner.modified_time",
          ],
        },
        {
          type: "none",
          key: "task_owner",
          title: "Next Year",
          name: "next_year",
          show: [
            "task_owner.modified_time",
            "task_owner.date_of_joining",
            "task_owner.date_of_birth",
            "task_owner.date_of_exit",
            "task_owner.added_time",
            "task_owner.modified_time",
          ],
        },
      ],
    },
    {
      label: "Completed By",
      key: "completed_by",
      options: [
        {
          type: "none",
          title: "Logged In User",
          key: "task",
          name: "logged_in_user",
          show: ["completed_by.reporting_manager"],
        },

        {
          type: "select",
          options: [
            { title: "User 1", key: "completed_by", name: "user_1" },
            { title: "User 2", key: "completed_by", name: "user_2" },
          ],
          title: "Is",
          key: "completed_by",
          name: "is",
          show: ["completed_by.reporting_manager"],
          multiple: true,
        },
        {
          type: "select",
          options: [
            { title: "User 1", key: "completed_by", name: "user_1" },
            { title: "User 2", key: "completed_by", name: "user_2" },
          ],
          title: "Is Not",
          key: "completed_by",
          name: "is_not",
          multiple: true,
          show: ["completed_by.reporting_manager"],
        },

        {
          type: "select",
          options: [
            { title: "Low", key: "completed_by", name: "low" },
            { title: "Moderate", key: "completed_by", name: "moderate" },
            { title: "High", key: "completed_by", name: "high" },
          ],
          title: "Is",
          key: "completed_by",
          name: "is",
          show: ["completed_by.priority"],
          multiple: true,
        },
        {
          type: "select",
          options: [
            { title: "Low", key: "completed_by", name: "low" },
            { title: "Moderate", key: "completed_by", name: "moderate" },
            { title: "High", key: "completed_by", name: "high" },
          ],
          title: "Is Not",
          key: "completed_by",
          name: "is_not",
          show: ["completed_by.priority"],
          multiple: true,
        },

        {
          type: "select",
          options: [
            { title: "Open", key: "completed_by", name: "open" },
            { title: "completed", key: "completed_by", name: "completed" },
            { title: "Other 1", key: "completed_by", name: "other_1" },
          ],
          title: "Is",
          key: "completed_by",
          name: "is",
          show: ["completed_by.status"],
          multiple: true,
        },
        {
          type: "select",
          options: [
            { title: "Open", key: "completed_by", name: "open" },
            { title: "completed", key: "completed_by", name: "completed" },
            { title: "Other 1", key: "completed_by", name: "other_1" },
          ],
          title: "Is Not",
          key: "completed_by",
          name: "is_not",
          show: ["completed_by.status"],
          multiple: true,
        },

        {
          type: "select",
          options: [
            { title: "Department 1", key: "completed_by", name: "department_1" },
            { title: "Department 2", key: "completed_by", name: "department_2" },
          ],
          title: "Is",
          key: "completed_by",
          name: "is",
          show: ["completed_by.department"],
          multiple: true,
        },
        {
          type: "select",
          options: [
            { title: "Department 1", key: "completed_by", name: "department_1" },
            { title: "Department 2", key: "completed_by", name: "department_2" },
          ],
          title: "Is Not",
          key: "completed_by",
          name: "is_not",
          show: ["completed_by.department"],
          multiple: true,
        },

        {
          type: "select",
          options: [
            { title: "Location 1", key: "completed_by", name: "location_1" },
            { title: "Location 2", key: "completed_by", name: "location_2" },
          ],
          title: "Is",
          key: "completed_by",
          name: "is",
          show: ["completed_by.location"],
          multiple: true,
        },
        {
          type: "select",
          options: [
            { title: "Location 1", key: "completed_by", name: "location_1" },
            { title: "Location 2", key: "completed_by", name: "location_2" },
          ],
          title: "Is Not",
          key: "completed_by",
          name: "is_not",
          show: ["completed_by.location"],
          multiple: true,
        },

        {
          type: "select",
          options: [
            {
              title: "Designation 1",
              key: "completed_by",
              name: "designation_1",
            },
            {
              title: "Designation 2",
              key: "completed_by",
              name: "designation_2",
            },
          ],
          title: "Is",
          key: "completed_by",
          name: "is",
          show: ["completed_by.designation"],
          multiple: true,
        },
        {
          type: "select",
          options: [
            {
              title: "Designation 1",
              key: "completed_by",
              name: "designation_1",
            },
            {
              title: "Designation 2",
              key: "completed_by",
              name: "designation_2",
            },
          ],
          title: "Is Not",
          key: "completed_by",
          name: "is_not",
          show: ["completed_by.designation"],
          multiple: true,
        },

        {
          type: "select",
          options: [
            {
              title: "Clikkle Role 1",
              key: "completed_by",
              name: "clikkle_hr_1",
            },
            {
              title: "Clikkle Role 2",
              key: "completed_by",
              name: "clikkle_hr_2",
            },
          ],
          title: "Is",
          key: "completed_by",
          name: "is",
          show: ["completed_by.clikkle_role"],
          multiple: true,
        },
        {
          type: "select",
          options: [
            {
              title: "Clikkle Role 1",
              key: "completed_by",
              name: "clikkle_hr_1",
            },
            {
              title: "Clikkle Role 2",
              key: "completed_by",
              name: "clikkle_hr_2",
            },
          ],
          title: "Is Not",
          key: "completed_by",
          name: "is_not",
          show: ["completed_by.clikkle_role"],
          multiple: true,
        },

        {
          type: "select",
          options: [
            {
              title: "Employment Type 1",
              key: "completed_by",
              name: "employment_type_1",
            },
            {
              title: "Employment Type 2",
              key: "completed_by",
              name: "employment_type_2",
            },
          ],
          title: "Is",
          key: "completed_by",
          name: "is",
          show: ["completed_by.employment_type"],
          multiple: true,
        },
        {
          type: "select",
          options: [
            {
              title: "Employment Type 1",
              key: "completed_by",
              name: "employment_type_1",
            },
            {
              title: "Employment Type 2",
              key: "completed_by",
              name: "employment_type_2",
            },
          ],
          title: "Is Not",
          key: "completed_by",
          name: "is_not",
          show: ["completed_by.employment_type"],
          multiple: true,
        },

        {
          type: "select",
          options: [
            {
              title: "Employee Status 1",
              key: "completed_by",
              name: "employee_status_1",
            },
            {
              title: "Employee Status 2",
              key: "completed_by",
              name: "employee_status_2",
            },
          ],
          title: "Is",
          key: "completed_by",
          name: "is",
          show: ["completed_by.employee_status"],
          multiple: true,
        },
        {
          type: "select",
          options: [
            {
              title: "Employee Status 1",
              key: "completed_by",
              name: "employee_status_1",
            },
            {
              title: "Employee Status 2",
              key: "completed_by",
              name: "employee_status_2",
            },
          ],
          title: "Is Not",
          key: "completed_by",
          name: "is_not",
          show: ["completed_by.employee_status"],
          multiple: true,
        },

        {
          type: "select",
          options: [
            {
              title: "Source Of Hire 1",
              key: "completed_by",
              name: "source_of_hire_1",
            },
            {
              title: "Source Of Hire 2",
              key: "completed_by",
              name: "source_of_hire_2",
            },
          ],
          title: "Is",
          key: "completed_by",
          name: "is",
          show: ["completed_by.source_of_hire"],
          multiple: true,
        },
        {
          type: "select",
          options: [
            {
              title: "Source Of Hire 1",
              key: "completed_by",
              name: "source_of_hire_1",
            },
            {
              title: "Source Of Hire 2",
              key: "completed_by",
              name: "source_of_hire_2",
            },
          ],
          title: "Is Not",
          key: "completed_by",
          name: "is_not",
          show: ["completed_by.source_of_hire"],
          multiple: true,
        },

        {
          type: "select",
          options: [
            {
              title: "Onboarding Status 1",
              key: "completed_by",
              name: "onboarding_status_1",
            },
            {
              title: "Onboarding Status 2",
              key: "completed_by",
              name: "onboarding_status_2",
            },
          ],
          title: "Is",
          key: "completed_by",
          name: "is",
          show: ["completed_by.onboarding_status"],
          multiple: true,
        },
        {
          type: "select",
          options: [
            {
              title: "Onboarding Status 1",
              key: "completed_by",
              name: "onboarding_status_1",
            },
            {
              title: "Onboarding Status 2",
              key: "completed_by",
              name: "onboarding_status_2",
            },
          ],
          title: "Is Not",
          key: "completed_by",
          name: "is_not",
          show: ["completed_by.onboarding_status"],
          multiple: true,
        },

        {
          type: "select",
          options: [
            {
              title: "Single",
              key: "completed_by",
              name: "single",
            },
            {
              title: "Married",
              key: "completed_by",
              name: "married",
            },
          ],
          title: "Is",
          key: "completed_by",
          name: "is",
          show: ["completed_by.marital_status"],
          multiple: true,
        },
        {
          type: "select",
          options: [
            {
              title: "Single",
              key: "completed_by",
              name: "single",
            },
            {
              title: "Married",
              key: "completed_by",
              name: "married",
            },
          ],
          title: "Is Not",
          key: "completed_by",
          name: "is_not",
          show: ["completed_by.marital_status"],
          multiple: true,
        },

        {
          type: "datetime",
          key: "completed_by",
          title: "Is",
          name: "is",
          show: [
            "completed_by.date_of_joining",
            "completed_by.date_of_birth",
            "completed_by.date_of_exit",
            "completed_by.added_time",
            "completed_by.modified_time",
          ],
        },
        {
          type: "datetime",
          key: "completed_by",
          title: "Is Not",
          name: "is_not",
          show: [
            "completed_by.date_of_joining",
            "completed_by.date_of_birth",
            "completed_by.date_of_exit",
            "completed_by.added_time",
            "completed_by.modified_time",
          ],
        },

        {
          type: "text",
          key: "completed_by",
          title: "Is",
          name: "is",
          show: [
            "completed_by.employee_id",
            "completed_by.first_name",
            "completed_by.last_name",
            "completed_by.email_address",
            "completed_by.nick_name",
            "completed_by.seating_location",
            "completed_by.work_phone_number",
            "completed_by.extension",
            "completed_by.personal_email_address",
            "completed_by.personal_mobile_number",
            "completed_by.tags",
            "completed_by.expertise",
            "completed_by.about_me",
            "completed_by.gender",
            "completed_by.age",
            "completed_by.current_experience",
            "completed_by.total_experience",
            "completed_by.present_address",
            "completed_by.permanent_address",
          ],
        },
        {
          type: "text",
          key: "completed_by",
          title: "Is Not",
          name: "is_not",
          show: [
            "completed_by.employee_id",
            "completed_by.first_name",
            "completed_by.last_name",
            "completed_by.email_address",
            "completed_by.nick_name",
            "completed_by.seating_location",
            "completed_by.work_phone_number",
            "completed_by.extension",
            "completed_by.personal_email_address",
            "completed_by.personal_mobile_number",
            "completed_by.tags",
            "completed_by.expertise",
            "completed_by.about_me",
            "completed_by.gender",
            "completed_by.age",
            "completed_by.current_experience",
            "completed_by.total_experience",
            "completed_by.present_address",
            "completed_by.permanent_address",
          ],
        },
        {
          type: "none",
          title: "Is Empty",
          key: "completed_by",
          name: "is_empty",
          show: [
            "completed_by.employee_id",
            "completed_by.modified_time",
            "completed_by.first_name",
            "completed_by.last_name",
            "completed_by.email_address",
            "completed_by.nick_name",
            "completed_by.department",
            "completed_by.location",
            "completed_by.designation",
            "completed_by.clikkle_role",
            "completed_by.reporting_manager",
            "completed_by.seating_location",
            "completed_by.work_phone_number",
            "completed_by.extension",
            "completed_by.personal_email_address",
            "completed_by.date_of_birth",
            "completed_by.personal_mobile_number",
            "completed_by.tags",
            "completed_by.expertise",
            "completed_by.about_me",
            "completed_by.date_of_exit",
            "completed_by.gender",
            "completed_by.employment_type",
            "completed_by.employee_status",
            "completed_by.source_of_hire",
            "completed_by.marital_status",
            "completed_by.age",
            "completed_by.current_experience",
            "completed_by.onboarding_status",
            "completed_by.total_experience",
            "completed_by.present_address",
            "completed_by.permanent_address",
          ],
        },
        {
          type: "none",
          title: "Is Empty Not",
          key: "completed_by",
          name: "is_empty_not",
          show: [
            "completed_by.employee_id",
            "completed_by.modified_time",
            "completed_by.first_name",
            "completed_by.last_name",
            "completed_by.email_address",
            "completed_by.nick_name",
            "completed_by.department",
            "completed_by.location",
            "completed_by.designation",
            "completed_by.clikkle_role",
            "completed_by.reporting_manager",
            "completed_by.seating_location",
            "completed_by.work_phone_number",
            "completed_by.extension",
            "completed_by.personal_email_address",
            "completed_by.date_of_birth",
            "completed_by.personal_mobile_number",
            "completed_by.tags",
            "completed_by.expertise",
            "completed_by.about_me",
            "completed_by.date_of_exit",
            "completed_by.gender",
            "completed_by.employment_type",
            "completed_by.employee_status",
            "completed_by.source_of_hire",
            "completed_by.marital_status",
            "completed_by.age",
            "completed_by.current_experience",
            "completed_by.onboarding_status",
            "completed_by.total_experience",
            "completed_by.present_address",
            "completed_by.permanent_address",
          ],
        },
        {
          type: "text",
          title: "Start With",
          key: "completed_by",
          name: "start_with",
          show: [
            "completed_by.employee_id",
            "completed_by.first_name",
            "completed_by.last_name",
            "completed_by.email_address",
            "completed_by.nick_name",
            "completed_by.department",
            "completed_by.location",
            "completed_by.designation",
            "completed_by.clikkle_role",
            "completed_by.reporting_manager",
            "completed_by.seating_location",
            "completed_by.work_phone_number",
            "completed_by.extension",
            "completed_by.personal_email_address",
            "completed_by.personal_mobile_number",
            "completed_by.tags",
            "completed_by.expertise",
            "completed_by.about_me",
            "completed_by.gender",
            "completed_by.present_address",
            "completed_by.permanent_address",
          ],
        },
        {
          type: "text",
          title: "End With",
          key: "completed_by",
          name: "end_with",
          show: [
            "completed_by.employee_id",
            "completed_by.first_name",
            "completed_by.last_name",
            "completed_by.email_address",
            "completed_by.nick_name",
            "completed_by.department",
            "completed_by.location",
            "completed_by.designation",
            "completed_by.clikkle_role",
            "completed_by.reporting_manager",
            "completed_by.seating_location",
            "completed_by.work_phone_number",
            "completed_by.extension",
            "completed_by.personal_email_address",
            "completed_by.personal_mobile_number",
            "completed_by.tags",
            "completed_by.expertise",
            "completed_by.about_me",
            "completed_by.gender",
            "completed_by.present_address",
            "completed_by.permanent_address",
          ],
        },
        {
          type: "text",
          title: "Contains",
          key: "completed_by",
          name: "contains",
          show: [
            "completed_by.employee_id",
            "completed_by.first_name",
            "completed_by.last_name",
            "completed_by.email_address",
            "completed_by.nick_name",
            "completed_by.department",
            "completed_by.location",
            "completed_by.designation",
            "completed_by.clikkle_role",
            "completed_by.reporting_manager",
            "completed_by.seating_location",
            "completed_by.work_phone_number",
            "completed_by.extension",
            "completed_by.personal_email_address",
            "completed_by.personal_mobile_number",
            "completed_by.tags",
            "completed_by.expertise",
            "completed_by.about_me",
            "completed_by.gender",
            "completed_by.employment_type",
            "completed_by.employee_status",
            "completed_by.source_of_hire",
            "completed_by.marital_status",
            "completed_by.onboarding_status",
            "completed_by.present_address",
            "completed_by.permanent_address",
          ],
        },
        {
          type: "text",
          title: "Not Contains",
          key: "completed_by",
          name: "not_contains",
          show: [
            "completed_by.employee_id",
            "completed_by.first_name",
            "completed_by.last_name",
            "completed_by.email_address",
            "completed_by.nick_name",
            "completed_by.department",
            "completed_by.location",
            "completed_by.designation",
            "completed_by.clikkle_role",
            "completed_by.reporting_manager",
            "completed_by.seating_location",
            "completed_by.work_phone_number",
            "completed_by.extension",
            "completed_by.personal_email_address",
            "completed_by.personal_mobile_number",
            "completed_by.tags",
            "completed_by.expertise",
            "completed_by.about_me",
            "completed_by.gender",
            "completed_by.employment_type",
            "completed_by.employee_status",
            "completed_by.source_of_hire",
            "completed_by.marital_status",
            "completed_by.onboarding_status",
            "completed_by.present_address",
            "completed_by.permanent_address",
          ],
        },
        {
          type: "text",
          title: "Lesser Than",
          key: "completed_by",
          name: "lesser_than",
          show: ["completed_by.age", "completed_by.current_experience", "completed_by.total_experience" ],
        },
        {
          type: "text",
          title: "Greater Than",
          key: "completed_by",
          name: "greater_than",
          show: ["completed_by.age", "completed_by.current_experience", "completed_by.total_experience" ],
        },
        {
          type: "text",
          title: "Lesser Or Equal",
          key: "completed_by",
          name: "lesser_or_equal",
          show: ["completed_by.age", "completed_by.current_experience", "completed_by.total_experience" ],
        },
        {
          type: "text",
          title: "Greater Or Equal",
          key: "completed_by",
          name: "greater_or_equal",
          show: ["completed_by.age", "completed_by.current_experience", "completed_by.total_experience" ],
        },

        {
          type: "datetime",
          key: "completed_by",
          title: "Before",
          name: "before",
          show: [
            "completed_by.modified_time",
            "completed_by.date_of_joining",
            "completed_by.date_of_birth",
            "completed_by.date_of_exit",
            "completed_by.added_time",
            "completed_by.modified_time",
          ],
        },
        {
          type: "datetime",
          key: "completed_by",
          title: "After",
          name: "After",
          show: [
            "completed_by.modified_time",
            "completed_by.date_of_joining",
            "completed_by.date_of_birth",
            "completed_by.date_of_exit",
            "completed_by.added_time",
            "completed_by.modified_time",
          ],
        },
        {
          type: "none",
          key: "completed_by",
          title: "Yesterday",
          name: "yesterday",
          show: [
            "completed_by.modified_time",
            "completed_by.date_of_joining",
            "completed_by.date_of_birth",
            "completed_by.date_of_exit",
            "completed_by.added_time",
            "completed_by.modified_time",
          ],
        },
        {
          type: "none",
          key: "completed_by",
          title: "Today",
          name: "today",
          show: [
            "completed_by.modified_time",
            "completed_by.date_of_joining",
            "completed_by.date_of_birth",
            "completed_by.date_of_exit",
            "completed_by.added_time",
            "completed_by.modified_time",
          ],
        },
        {
          type: "none",
          key: "completed_by",
          title: "Tomorrow",
          name: "tomorrow",
          show: [
            "completed_by.modified_time",
            "completed_by.date_of_joining",
            "completed_by.date_of_birth",
            "completed_by.date_of_exit",
            "completed_by.added_time",
            "completed_by.modified_time",
          ],
        },
        {
          type: "none",
          key: "completed_by",
          title: "Last Week",
          name: "last_week",
          show: [
            "completed_by.modified_time",
            "completed_by.date_of_joining",
            "completed_by.date_of_birth",
            "completed_by.date_of_exit",
            "completed_by.added_time",
            "completed_by.modified_time",
          ],
        },
        {
          type: "none",
          key: "completed_by",
          title: "This Week",
          name: "this_week",
          show: [
            "completed_by.modified_time",
            "completed_by.date_of_joining",
            "completed_by.date_of_birth",
            "completed_by.date_of_exit",
            "completed_by.added_time",
            "completed_by.modified_time",
          ],
        },
        {
          type: "none",
          key: "completed_by",
          title: "Next Week",
          name: "next_week",
          show: [
            "completed_by.modified_time",
            "completed_by.date_of_joining",
            "completed_by.date_of_birth",
            "completed_by.date_of_exit",
            "completed_by.added_time",
            "completed_by.modified_time",
          ],
        },
        {
          type: "none",
          key: "completed_by",
          title: "Last Month",
          name: "last_month",
          show: [
            "completed_by.modified_time",
            "completed_by.date_of_joining",
            "completed_by.date_of_birth",
            "completed_by.date_of_exit",
            "completed_by.added_time",
            "completed_by.modified_time",
          ],
        },
        {
          type: "none",
          key: "completed_by",
          title: "This Month",
          name: "this_month",
          show: [
            "completed_by.modified_time",
            "completed_by.date_of_joining",
            "completed_by.date_of_birth",
            "completed_by.date_of_exit",
            "completed_by.added_time",
            "completed_by.modified_time",
          ],
        },
        {
          type: "none",
          key: "completed_by",
          title: "Next Month",
          name: "next_month",
          show: [
            "completed_by.modified_time",
            "completed_by.date_of_joining",
            "completed_by.date_of_birth",
            "completed_by.date_of_exit",
            "completed_by.added_time",
            "completed_by.modified_time",
          ],
        },
        {
          type: "none",
          key: "completed_by",
          title: "Last Year",
          name: "last_year",
          show: [
            "completed_by.modified_time",
            "completed_by.date_of_joining",
            "completed_by.date_of_birth",
            "completed_by.date_of_exit",
            "completed_by.added_time",
            "completed_by.modified_time",
          ],
        },
        {
          type: "none",
          key: "completed_by",
          title: "This Year",
          name: "this_year",
          show: [
            "completed_by.modified_time",
            "completed_by.date_of_joining",
            "completed_by.date_of_birth",
            "completed_by.date_of_exit",
            "completed_by.added_time",
            "completed_by.modified_time",
          ],
        },
        {
          type: "none",
          key: "completed_by",
          title: "Next Year",
          name: "next_year",
          show: [
            "completed_by.modified_time",
            "completed_by.date_of_joining",
            "completed_by.date_of_birth",
            "completed_by.date_of_exit",
            "completed_by.added_time",
            "completed_by.modified_time",
          ],
        },
      ],
    },
  ];
  const createTableViewCriteriasRelationships = [
    { title: "AND", name: "and" },
    { title: "OR", name: "or" },
  ];
  return (
    <div className="w-full min-h-80 flex flex-col gap-3">
      <div className="flex gap-3 justify-between items-center">
        <div className="flex flex-row gap-3 justify-center items-center">
          <div>
            <FormControl sx={{ width: "200px" }}>
              <InputLabel id="table-view">Table View</InputLabel>
              <Select labelId="table-view" id="table-view" label="Table View">
                <MenuItem value={"one"}>One</MenuItem>
                <MenuItem value={"two"}>Two</MenuItem>
                <div className="w-full ">
                  <Button
                    sx={{ width: "100%" }}
                    onClick={() => {
                      setCreateTableViewModal(true);
                    }}
                    variant="outlined"
                  >
                    Create Table View
                  </Button>
                </div>
              </Select>
            </FormControl>
          </div>
          <div>
            <Button variant="outlined">Edit</Button>
          </div>
          <div className="h-9 w-[0.15rem] rounded-lg bg-neutral-500" />
          <div>
            <Button variant="outlined" color="error">
              Delete
            </Button>
          </div>
        </div>
        <div className="flex gap-3 justify-center items-center">
          {/* <Button
            variant="contained"
            onClick={() => {
              setAddTaskModal(true);
            }}
          >
            Add Task
          </Button> */}
          <IconButton
            onClick={() => {
              setFilterModal(true);
            }}
            title="Filter"
          >
            <IoFilter />
          </IconButton>
        </div>
      </div>

      <div className="h-[30rem]">
        <CustomTable
          columns={columns}
          onRowClick={handleRowClick}
          renderActions={renderActions}
          renderRowSelection={renderRowSelection}
          data={data}
          loading={loading}
          error={error}
          sorting={sorting}
          setSorting={setSorting}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          pagination={pagination}
          setPagination={setPagination}
          totalRows={totalRows}
          setTotalRows={setTotalRows}
          isBulkSelect={false}
        />
      </div>

      <CustomModal
        title="Add Task"
        fields={addTaskModalFields}
        open={addTaskModal}
        onClose={() => {
          setAddTaskModal(false);
        }}
        onSubmit={handleAddTaskFormSubmit}
        isScrollable={true}
        isCustomSubmitButtom={true}
        customSubmitButton={
          <div className="w-full flex flex-row justify-between items-center">
            <Button variant="contained" type="submit">
              Submit
            </Button>
            <Button variant="contained" type="submit">
              Submit And New
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                setAddTaskModal(false);
              }}
            >
              Cancle
            </Button>
          </div>
        }
      />
      <CustomModal
        title="View Task"
        fields={viewTaskModalFields}
        open={viewTaskModal}
        onClose={() => {
          setViewTaskModal(false);
        }}
        isView={true}
        isEditButton={true}
        onEditButtonClick={() => {
          setViewTaskModal(false);
          setEditTaskModal(true);
        }}
        isScrollable={true}
      />
      <CustomModal
        title="Edit Task"
        fields={editTaskModalFields}
        open={editTaskModal}
        onClose={() => {
          setEditTaskModal(false);
        }}
        onSubmit={handleEditTaskFormSubmit}
        isScrollable={true}
      />
      <CustomModal
        title="Filter"
        submitLabel="Apply"
        fields={filterModalFields}
        open={filterModal}
        onClose={() => {
          setFilterModal(false);
        }}
        onSubmit={handleFilterFormSubmit}
        // isScrollable={true}
      />

      <CreateTableView
        open={createTableViewModal}
        onClose={() => {
          setCreateTableViewModal(false);
        }}
        forWhom={"task"}
        form={createTableViewForm}
        setForm={setCreateTableViewForm}
        columns={createTableViewColumns}
        specifics={createTableViewSpecifics}
        criterias={createTableViewCriterias}
        criteriasOptions={createTableViewCriteriasOptions}
        criteriasRelationships={createTableViewCriteriasRelationships}
      />
    </div>
  );
}
