import React from "react";

export default function importLog() {
  return (
    <div className="w-full min-h-80 flex flex-col">
      <div className="w-full min-h-80 flex flex-col justify-center items-center ">
        <h1>No Data</h1>
        <h1>No Data Imported</h1>
      </div>
    </div>
  );
}
