import React from 'react'

export default function NoFeeds() {
    return (
        <div className='h-[full] mt-12 w-full gap-2 flex flex-col justify-center items-center'>
            <img className='h-[200px] w-[200px]' src="/images/no_feeds.png" alt="" />
            <p className='mt-2'>No Feeds Yet</p>
        </div>
    )
}
