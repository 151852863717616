import React, { useState } from "react";
import CustomEmptyModal from "./CustomEmptyModal";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import { ArrowBack, Close } from "@mui/icons-material";

const CustomBulkUpload = ({ open, onClose }) => {
  const { mode } = useTheme();
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && ["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "text/csv"].includes(file.type)) {
      setSelectedFile(file);
    } else {
      alert("Invalid file type! Only xls, xlsx, and csv formats are supported.");
    }
  };

  return (
    <div>
      <CustomEmptyModal isScrollable={true} open={open} onClose={onClose}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: 2,
            borderBottom: "1px solid #ddd",
            position: "sticky",
            backgroundColor: "background.default",
            top: 0,
            zIndex: 10, // Keeps it above scrollable content
          }}
        >
          <div className="flex items-center">
            <IconButton
              sx={{
                backgroundColor: mode == "light" ? "#edf0f4" : "#3333",
                mr: 2,
                fontSize: 14,
                borderRadius: "6px",
              }}
              onClick={onClose}
            >
              <ArrowBack  fontSize="16px" />
            </IconButton>
            <Typography variant="h6">
            Bulk File Upload</Typography>
          </div>
          <IconButton
            sx={{
              backgroundColor: mode == "light" ? "#edf0f4" : "#3333",
              fontSize: 16,
              borderRadius: "6px",
            }}
            onClick={onClose}
          >
            <Close />
          </IconButton>
        </Box>

        <Box sx={{ padding: 2 }}>
          <div className="grid grid-cols-4 gap-2" >
            <div>
              <p>Upload data for:</p>
              <FormControl
                // key={index}
                sx={{
                  minWidth: "200px",
                  width: "100%",
                  padding: 0,
                  margin: 0,
                  mt:1
                }}
              >
                {/* <InputLabel>{column.label}</InputLabel> */}
                <Select
                  size="small"
                  // label={column.label}
                  name={"importFor"}
                  value={""}
                  //   onChange={}
                //   disabled={isView}
                >
                  <MenuItem value={"Candidate"}>{"Candidate"}</MenuItem>
                  <MenuItem value={"Education"}>{"Education"}</MenuItem>
                  <MenuItem value={"Experience"}>{"Experience"}</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              <p>Upload based on:</p>
              <FormControl
                // key={index}
                sx={{
                  minWidth: "200px",
                  width: "100%",
                  padding: 0,
                  margin: 0,
                  mt:1
                }}
              >
                {/* <InputLabel>{column.label}</InputLabel> */}
                <Select
                  size="small"
                  // label={column.label}
                  name={"importBasedOn"}
                  value={""}
                  //   onChange={}
                //   disabled={isView}
                >
                  <MenuItem value={"Candidate ID"}>{"Candidate ID"}</MenuItem>
                  <MenuItem value={"Email ID"}>{"Email ID"}</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="border p-2 rounded-md my-5 flex justify-center items-center flex-col " >
            <img src="/images/bulkUploadImg.png" 
            style={{ width: "25%", height: "25%" }} />
      <div className="text-center mt-2">
      {selectedFile ? (
        <>
          <p className="text-[18px]">{selectedFile.name}</p>
          <Button variant="contained" component="label" sx={{my:2}} >
            Remove and Upload New
            <input type="file" hidden onChange={handleFileChange} />
          </Button>
        </>
      ) : (
        <>
          <p className="text-[18px]">Drag and drop attachment here</p>
          <p className="text-[14px] my-1">[only zip format is supported]</p>
          <Button variant="contained" component="label" sx={{my:1}} >
            Upload File
            <input type="file" hidden onChange={handleFileChange} />
          </Button>
          <p>Maximum upload file size is 50 MB.</p>
        </>
      )}
    </div>
          </div>

          <div className="p-3 w-full bg-[#fff7e5] rounded-md " >
            <p  className="text-[#ff832b] "  >Note</p>
            <p className="text-black text-[14px] " >
            Filenames should be same as the selected 'upload based on' values.
            </p>
          </div>
        </Box>

       <Box
              sx={{ backgroundColor: "background.default" }}
              className="w-full min-h-14 items-center z-50 sticky bottom-0 left-0 py-2"
            >
              <div className="flex flex-row gap-3 justify-between items-center">
                <Button variant="outlined" onClick={onClose}>
                  Cancel
                </Button>
                <Button variant="contained" type="submit">
                  Submit
                </Button>
              </div>
            </Box>

      </CustomEmptyModal>
    </div>
  );
};

export default CustomBulkUpload;
