import React, { useState } from "react";
import { Tabs, Tab } from "@mui/material";

import Metrics from "./Metrics/Metrics";
import QuestionPool from "./QuestionPool/QuestionPool";
import Templates from "./Templates/Templates";
import { useSwitchScreen } from "../../../hooks/SwitchScreenContext";

export default function EmployeeEngagement({ back }) {
   const { switchScreen } = useSwitchScreen(); 



  return (
    <div className="w-full min-h-full h-fit flex flex-col gap-3 px-3 rounded-lg border border-[#1e2734]">
      {switchScreen.primary === "metrics" ? (
        <>
          <Metrics />
        </>
      ) : (
        <></>
      )}
      {switchScreen.primary === "questionPool" ? (
        <>
          <QuestionPool />
        </>
      ) : (
        <></>
      )}
      {switchScreen.primary === "templates" ? (
        <>
          <Templates />
        </>
      ) : (
        <></>
      )}
    </div>
  );
}
