import React, { useState } from "react";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Checkbox,
  IconButton,
  Menu,
  MenuItem,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { HiDotsHorizontal } from "react-icons/hi";
import { IoFilter } from "react-icons/io5";
import dayjs from "dayjs";

import CustomModal from "../../../../components/CustomModal";
import { ChevronDown, ChevronUp } from "lucide-react";
import { FaEnvelope, FaPhoneAlt } from "react-icons/fa";
import { VscEye } from "react-icons/vsc";
import CustomFilter from "../../../../components/CustomFilter";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Grid from "@mui/material/Grid2";

export default function TrackOnboarding() {
  const [switchScreen, setSwitchScreen] = useState({
    primary: "all",
  });
  const attendanceData = [
    {
      id: "#193845039283",
      name: "Richard Webber",
      role: "UI/UX Designer",
      date: "22/10/2024",
      status: "Present",
      clockIn: "09:00:17 AM",
      clockOut: "Not yet clocked out",
      shift: "AM",
      avatar: "https://i.pravatar.cc/40?img=3",
    },
    {
      id: "#995830128543",
      name: "Desmond Jakes",
      role: "Frontend Developer",
      date: "22/10/2024",
      status: "Late",
      clockIn: "09:40:17 AM",
      clockOut: "Not yet clocked out",
      shift: "AM",
      avatar: "https://i.pravatar.cc/40?img=8",
    },
    {
      id: "#995839202395",
      name: "Jaxson Schleifer",
      role: "Frontend Developer",
      date: "22/10/2024",
      status: "Present",
      clockIn: "09:00:03 AM",
      clockOut: "Not yet clocked out",
      shift: "AM",
      avatar: "https://i.pravatar.cc/40?img=10",
    },
    {
      id: "#294857104856",
      name: "Cynthia Eze",
      role: "Software Engineer",
      date: "22/10/2024",
      status: "Present",
      clockIn: "09:00:05 AM",
      clockOut: "Not yet clocked out",
      shift: "AM",
      avatar: "https://i.pravatar.cc/40?img=4",
    },
    {
      id: "#775839203848",
      name: "Erin Herwitz",
      role: "Digital Marketer",
      date: "22/10/2024",
      status: "Present",
      clockIn: "09:00:07 AM",
      clockOut: "Not yet clocked out",
      shift: "AM",
      avatar: "https://i.pravatar.cc/40?img=5",
    },
    {
      id: "#775839205548",
      name: "Erin Herwitz",
      role: "Digital Marketer",
      date: "22/10/2024",
      status: "Present",
      clockIn: "09:00:07 AM",
      clockOut: "Not yet clocked out",
      shift: "AM",
      avatar: "https://i.pravatar.cc/40?img=5",
    },
  ];
  const [attendance, setAttendance] = useState(attendanceData);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [anchorEl, setAnchorEl] = useState(null);

  const openFilterModal = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeFilterModal = () => {
    setAnchorEl(null);
  };

  const handleFilterFormSubmit = () => {
    alert("form submit");
  };
  const primaryTabs = [
    {
      label: "All",
      value: "all",
    },
    {
      label: "Onboarding",
      value: "onboarding",
    },
    {
      label: "Onboarding In Progress",
      value: "onboardingInProgress",
    },
  ];

  const [menuAnchor, setMenuAnchor] = useState(null);
  const isMenuopen = Boolean(menuAnchor);

  const [filterModal, setFilterModal] = useState(false);
  const filterFields = [
    {
      type: "text",
      label: "Employee Name",
      name: "employeeName",
      defaultValue: "",
    },
    {
      type: "autocomplete",
      label: "Onboarding Status",
      name: "onboardingStatus",
      options: [
        {
          label: "Onboarding Status 1",
          value: "onboarding_status_1",
        },
        {
          label: "Onboarding Status 2",
          value: "onboarding_status_2",
        },
      ],
      defaultValue: "",
    },
    {
      type: "datePicker",
      label: "Date Of Joining",
      name: "dateOfJoining",
      defaultValue: dayjs(),
    },
    {
      type: "autocomplete",
      label: "Designation",
      name: "designation",
      options: [
        {
          label: "Designation 1",
          value: "designation_1",
        },
        {
          label: "Designation 2",
          value: "designation_2",
        },
      ],
      defaultValue: "",
    },
    {
      type: "autocomplete",
      label: "Clikkle HR",
      name: "clikkleHR",
      options: [
        {
          label: "Clikkle HR 1",
          value: "clikkle_hr_1",
        },
        {
          label: "Clikkle HR 2",
          value: "clikkle_hr_2",
        },
      ],
      defaultValue: "",
    },
    {
      type: "autocomplete",
      label: "Department",
      name: "department",
      options: [
        {
          label: "Department 1",
          value: "department_1",
        },
        {
          label: "Department 2",
          value: "department_2",
        },
      ],
      defaultValue: "",
    },
  ];

  return (
    <div className="w-full flex flex-col gap-3">
      <div className="w-full flex flex-col sm:flex-row justify-between items-center'">
        <Tabs
          value={switchScreen.primary}
          onChange={(event, newValue) => {
            setSwitchScreen({
              primary: newValue,
            });
          }}
          variant="scrollable"
          scrollButtons="auto"
        >
          {primaryTabs.map((tab, index) => (
            <Tab key={index} label={tab.label} value={tab.value} />
          ))}
        </Tabs>

        <div className="flex flex-row gap-3 mt-3">
        <Button
          variant="contained"
          onClick={(event) => {
            setMenuAnchor(event.currentTarget);
          }}
        >
          Export Report
          <span>
            <ChevronDown size={16} className="ml-2" />
          </span>
        </Button>
          <IconButton onClick={openFilterModal}>
            <IoFilter />
          </IconButton>
          <div>
            {/* <IconButton
              id="basic-button"
              aria-controls={isMenuopen ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={isMenuopen ? "true" : undefined}
              onClick={(event) => {
                setMenuAnchor(event.currentTarget);
              }}
            >
              <HiDotsHorizontal className="text-2xl" />
            </IconButton> */}
            <Menu
              id="basic-menu"
              anchorEl={menuAnchor}
              open={isMenuopen}
              onClose={() => {
                setMenuAnchor(null);
              }}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={() => {}}>CSV</MenuItem>
              <MenuItem onClick={() => {}}>XLS</MenuItem>
              <MenuItem onClick={() => {}}>XLSX</MenuItem>
            </Menu>
          </div>
        </div>
      </div>
      {switchScreen.primary === "all" && (
        <Box
          sx={{
            borderRadius: "12px",
            width: "auto",
            height: { xs: "auto" },
          }}
        >
          <TableContainer
            className="collapsible-div"
            sx={{ overflowX: "auto", border: "1px solid #262626" }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow sx={{ border: "1px solid #262626" }}>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      fontSize: isMobile ? "10px" : "14px",
                      borderBottom: "1px solid #262626",
                      borderRight: "1px solid #262626",
                    }}
                  >
                    <Checkbox />
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      fontSize: isMobile ? "10px" : "14px",
                      borderBottom: "1px solid #262626",
                      borderRight: "1px solid #262626",
                    }}
                  >
                    Employee Name
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      fontSize: isMobile ? "10px" : "14px",
                      borderBottom: "1px solid #262626",
                      borderRight: "1px solid #262626",
                    }}
                  >
                    Date of Joining
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      fontSize: isMobile ? "10px" : "14px",
                      borderBottom: "1px solid #262626",
                      borderRight: "1px solid #262626",
                    }}
                  >
                    Designation
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      fontSize: isMobile ? "10px" : "14px",
                      borderBottom: "1px solid #262626",
                      borderRight: "1px solid #262626",
                    }}
                  >
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {attendance?.map((entry, index) => ( */}
                <TableRow
                  sx={{ border: "1px solid #262626" }}
                  // key={entry._id}
                >
                  <TableCell sx={{ borderRight: "1px solid #262626" }}>
                    <Checkbox />
                  </TableCell>
                  <TableCell
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      fontSize: isMobile ? "10px" : "14px",
                      borderRight: "1px solid #262626",
                    }}
                  >
                    <Avatar
                      src={`https://ui-avatars.com/api/?name=${"S"} ${"sk"}`}
                      alt={"em"}
                      sx={{
                        width: isMobile ? 24 : 40,
                        height: isMobile ? 24 : 40,
                      }}
                    />
                    <div>
                      <p
                        style={{ fontSize: isMobile ? "10px" : "14px" }}
                      >{`Saad khan`}</p>
                      <p
                        style={{
                          fontSize: isMobile ? "8px" : "12px",
                          color: "gray",
                        }}
                      >
                        {"sk@gmail.com"}
                      </p>
                    </div>
                  </TableCell>
                  <TableCell sx={{ borderRight: "1px solid #262626" }}>
                    {"24-Feb-2025"}
                  </TableCell>
                  <TableCell sx={{ borderRight: "1px solid #262626" }}>
                    {"Team member"}
                  </TableCell>
                  <TableCell sx={{ borderRight: "1px solid #262626" }}>
                    {"0/13 In progress"}
                  </TableCell>
                </TableRow>
                {/* ))} */}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
      {switchScreen.primary === "onboarding" && (
        <Box
          sx={{
            borderRadius: "12px",
            width: "auto",
            height: { xs: "auto" },
          }}
        >
          <TableContainer
            className="collapsible-div"
            sx={{ overflowX: "auto", border: "1px solid #262626" }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow sx={{ border: "1px solid #262626" }}>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      fontSize: isMobile ? "10px" : "14px",
                      borderBottom: "1px solid #262626",
                      borderRight: "1px solid #262626",
                    }}
                  >
                    <Checkbox />
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      fontSize: isMobile ? "10px" : "14px",
                      borderBottom: "1px solid #262626",
                      borderRight: "1px solid #262626",
                    }}
                  >
                    Employee Name
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      fontSize: isMobile ? "10px" : "14px",
                      borderBottom: "1px solid #262626",
                      borderRight: "1px solid #262626",
                    }}
                  >
                    Date of Joining
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      fontSize: isMobile ? "10px" : "14px",
                      borderBottom: "1px solid #262626",
                      borderRight: "1px solid #262626",
                    }}
                  >
                    Designation
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      fontSize: isMobile ? "10px" : "14px",
                      borderBottom: "1px solid #262626",
                      borderRight: "1px solid #262626",
                    }}
                  >
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {attendance?.map((entry, index) => ( */}
                <TableRow
                  sx={{ border: "1px solid #262626" }}
                  // key={entry._id}
                >
                  <TableCell sx={{ borderRight: "1px solid #262626" }}>
                    <Checkbox />
                  </TableCell>
                  <TableCell
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      fontSize: isMobile ? "10px" : "14px",
                      borderRight: "1px solid #262626",
                    }}
                  >
                    <Avatar
                      src={`https://ui-avatars.com/api/?name=${"S"} ${"sk"}`}
                      alt={"em"}
                      sx={{
                        width: isMobile ? 24 : 40,
                        height: isMobile ? 24 : 40,
                      }}
                    />
                    <div>
                      <p
                        style={{ fontSize: isMobile ? "10px" : "14px" }}
                      >{`Saad khan`}</p>
                      <p
                        style={{
                          fontSize: isMobile ? "8px" : "12px",
                          color: "gray",
                        }}
                      >
                        {"sk@gmail.com"}
                      </p>
                    </div>
                  </TableCell>
                  <TableCell sx={{ borderRight: "1px solid #262626" }}>
                    {"24-Feb-2025"}
                  </TableCell>
                  <TableCell sx={{ borderRight: "1px solid #262626" }}>
                    {"Team member"}
                  </TableCell>
                  <TableCell sx={{ borderRight: "1px solid #262626" }}>
                    {"0/13 In progress"}
                  </TableCell>
                </TableRow>
                {/* ))} */}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
      {switchScreen.primary === "onboardingInProgress" && (
        <Grid container spacing={2} sx={{ width: "100%" }}>
          <Grid
            size={{ xs: 12, md: 102 }}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              // border: "1px solid",
              // borderRadius: "8px",
              p: 3,
              textAlign: "center",
              height: "100%",
              minHeight: "200px",
            }}
          >
            <img src="/images/no_timeLogs.png" />
            <h1 className="mt-4">No Record Found</h1>
          </Grid>
        </Grid>
      )}

      <CustomFilter
        anchorEl={anchorEl}
        onClose={closeFilterModal}
        content={
          <>
            <form
              onSubmit={handleFilterFormSubmit}
              className="flex flex-col gap-3"
            >
              <div>
                <TextField
                  sx={{ width: "100%" }}
                  name="employee"
                  // size="small"
                  label="Employee"
                  variant="outlined"
                  value={""}
                  // onChange={(event) => {
                  //   setFormData((prev) => ({
                  //     ...prev,
                  //     currentExperience: event.target.value,
                  //   }));
                  // }}
                />
                <Autocomplete
                  sx={{ width: "100%" }}
                  options={["Onboarding in progress", "Triggered"]}
                  renderInput={(params) => (
                    <TextField {...params} label="Onboarding Status" />
                  )}
                  // onChange={(e, value) =>
                  //   handleFilterFormChange("location", value)
                  // }
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    // label="Select Date and Time"
                    sx={{
                      minWidth: "200px",
                      width: "100%",
                      padding: 0,
                      margin: 0,
                    }}
                    value={dayjs("")}
                    name="dateOfJoining"
                    label="Date Of Joining"
                    // onChange={(newValue) => {
                    //   const updatedCriterias = [
                    //     ...form.criterias,
                    //   ];
                    //   updatedCriterias[index].value = newValue;
                    //   setForm((prevForm) => ({
                    //     ...prevForm,
                    //     criterias: updatedCriterias,
                    //   }));
                    //   resetCriteriaError(index);
                    // }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        // error={!!criteriaErrors[index]}
                        // helperText={criteriaErrors[index]}
                      />
                    )}
                  />
                </LocalizationProvider>
                <Autocomplete
                  sx={{ width: "100%" }}
                  options={["CEO", "Manager"]}
                  renderInput={(params) => (
                    <TextField {...params} label="Designation" />
                  )}
                  // onChange={(e, value) =>
                  //   handleFilterFormChange("location", value)
                  // }
                />
                <Autocomplete
                  sx={{ width: "100%" }}
                  options={["Admin", "Manager"]}
                  renderInput={(params) => (
                    <TextField {...params} label="Clikkle Hr Role" />
                  )}
                  // onChange={(e, value) =>
                  //   handleFilterFormChange("location", value)
                  // }
                />
                <Autocomplete
                  sx={{ width: "100%" }}
                  options={["Management", "HR", "Marketing", "It"]}
                  renderInput={(params) => (
                    <TextField {...params} label="Department" />
                  )}
                  // onChange={(e, value) =>
                  //   handleFilterFormChange("location", value)
                  // }
                />
              </div>
            </form>
          </>
        }
      />
      {/* <CustomFilter
        anchorEl={filterModal}
        onClose={() => setFilterModal(false)}
        content={
          <>
            <Autocomplete
              sx={{ width: "100%" }}
              options={["Onboarding in progress", "Triggered"]}
              renderInput={(params) => (
                <TextField {...params} label="Onboarding Status" />
              )}
              // onChange={(e, value) =>
              //   handleFilterFormChange("location", value)
              // }
            />
          </>
        }
      /> */}
    </div>
  );
}
