import React, { useEffect, useState } from "react";
import morning from "../../../../assets/Greeting/Background/Morning.jpg";
import afternoon from "../../../../assets/Greeting/Background/Afternoon.jpg";
import evening from "../../../../assets/Greeting/Background/Evening.jpg";
import night from "../../../../assets/Greeting/Background/Night.jpg";
import { Button, Typography, IconButton, Box } from "@mui/material";
import { useUser } from "../../../../hooks/Authorize";
import camera from "../../../../assets/Interductionimages/cameraaicon2.png";
import { GoKebabHorizontal } from "react-icons/go";
import { FaRegEdit } from "react-icons/fa";
import { IoBrush } from "react-icons/io5";
import EditProfileModal from "../../../EditProfileModal";

import PersonalizePreferencesModal from "../../../PersonalizePreferencesModal";

export default function GreetingBackground({menu,setMenu}) {
    const [editModalOpen, setEditModalOpen] = useState(false);
    
  const [personalizeModalOpen, setPersonalizeModalOpen] = useState(false); // State for modal 
  const [time, setTime] = useState(new Date().getHours());
  const [greetImage, setGreetImage] = useState(morning);
  const platformUser = useUser();
  const [image, setImage] = useState(platformUser?.image || "");
  useEffect(() => {
    if (time >= 1 && time <= 4) {
      setGreetImage(morning);
    } else if (time >= 5 && time <= 11) {
      setGreetImage(morning);
    } else if (time >= 12 && time <= 16) {
      setGreetImage(afternoon);
    } else if (time >= 17 && time <= 21) {
      setGreetImage(evening);
    } else {
      setGreetImage(night);
    }
  }, [time]);

  const getInitials = () => {
    if (platformUser?.firstName && platformUser?.lastName) {
      return `${platformUser.firstName
        .charAt(0)
        .toUpperCase()}${platformUser.lastName.charAt(0).toUpperCase()}`;
    }
    return "?";
  };
  // Function to handle image selection and upload
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setImage(imageUrl);
    }
  };

  return (
    <div className="flex gap-2">
      <div className="flex flex-col justify-center items-center lg:hidden">
        <Box
          sx={{
            position: "relative",
            width: image ? "50px" : "50px",
            height: image ? "50px" : "50px",
            borderRadius: image ? "10px" : "10px",
            overflow: "hidden",
            border: image ? "" : "2px solid #3B84D9",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: image ? "transparent" : "background.view",
            backgroundImage: image ? `url(${image})` : "none", // Show image as background if available
            backgroundSize: "cover", // Ensure image covers the area
            backgroundPosition: "center", // Center the background image
            transition: "all 0.3s ease", // Smooth transition for hover effect
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.6)", // Darkens the background on hover
            },
            "&:hover::after": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: "inherit", // Inherit background properties from the box
              filter: "blur(5px)", // Apply blur only to the background
              zIndex: 1,
            },
            "&:hover .camera-icon": {
              opacity: 1,
            },
          }}
        >
          {/* Initials Fallback */}
          {!image && (
            <Typography
              variant="subtitle1"
              fontWeight={600}
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontSize: 80,
              }}
            >
              {getInitials()}
            </Typography>
          )}

          {/* Camera Icon */}
          <IconButton
            className="camera-icon"
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)", // Center the icon
              backgroundColor: "transparent",
              boxShadow: "none",
              opacity: 0, // Hidden by default
              transition: "opacity 0.3s ease", // Smooth transition for icon appearance
              zIndex: 2, // Ensure the icon is above the blurred background
            }}
            size="small"
            component="label"
          >
            <img
              src={camera}
              alt="Camera"
              style={{
                width: "20px",
                height: "20px",
              }}
            />
            <input
              type="file"
              accept="image/*"
              hidden
              onChange={handleImageUpload}
            />
          </IconButton>
        </Box>
      </div>

      <div
        style={{
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          backgroundImage: `url(${greetImage})`,
        }}
        className="w-full h-[50px] lg:min-h-24 flex flex-col justify-center items-end border rounded-lg bg-no-repeat bg-six border-[#1e2734] ute pe-2"
      >
        <div className="relative lg:hidden block">
          <GoKebabHorizontal size={22} onClick={() => setMenu(!menu)} />
          {menu &&
            <div className="bg-black right-2 p-2 py-4 w-[175px] rounded-lg z-10 absolute flex flex-col gap-3">
              <div className="flex items-center gap-2"   onClick={() => setEditModalOpen(true)}>
                <FaRegEdit color="white" size={14} />
                <p className="text-white text-[12px]">Edit Profile</p>
              </div>
              <div className="flex items-center gap-2" onClick={() => setPersonalizeModalOpen(true)}>
                <IoBrush color="white" size={14} />
                <p className="text-white text-[12px]">Personalize Preferences</p>
              </div>
            </div>
          }
        </div>
      </div>
      <EditProfileModal editModalOpen={editModalOpen} closeEditModal={() => setEditModalOpen(false)} />
        
      <PersonalizePreferencesModal personalizeModalOpen={personalizeModalOpen} ClosePersonalizeModal={() => setPersonalizeModalOpen(false)} />


    </div>
  );
}
