import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Modal,
  Toolbar,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useState,useEffect,useRef } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import axios from "axios";
import Pending from "@mui/icons-material/Pending";
import { useMessage } from "./Header";
import Details from "./Details";
import { IoIosMore } from "react-icons/io";
import { useTheme } from "../style/theme";
import { MdExpandLess } from 'react-icons/md'; 
import { TfiMoreAlt } from "react-icons/tfi";

const QuestionCard = (props) => {
  const { title, questions, id, refresh, editQuestion, copyQuestion } = props;
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [deleting, setDeleting] = useState(false);
  const { showError, showSuccess } = useMessage();

  const style = {
    width: "100%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
  };

  const handleExpand = () => {
    setAccordionOpen((show) => !show);
  };

  async function deleteCategory(id) {
    setDeleting(true);
    try {
      const res = await axios.delete(`/hr/question/${id}`);
      const { success, message } = res.data;
      if (success) {
        showSuccess("Question deleted successfully");
      } else {
        showError(message);
      }
    } catch (e) {}
    setDeleting(false);
    refresh();
  }

  const handleClose = () => setOpen(false);

  const [openModal, setOpenModal] = useState(false);

  const [deletingModal, setDeletingModal] = useState(false);
  const toggleModal = () => {
    setOpenModal((prev) => !prev);
  };

  const OpenDeleteModal = () => setDeletingModal(true);
  const CloseDeleteModal = () => setDeletingModal(false);
  const { mode } = useTheme();


  const modalRef = useRef(null);  // Reference for the modal content
  const buttonRef = useRef(null);  // Reference for the button that opens the modal
  useEffect(() => {
    // Function to handle clicks outside the modal
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
        setOpenModal(false); // Close the modal if clicked outside
      }
    };

    // Add event listener for clicks outside
    document.addEventListener('mousedown', handleClickOutside);

    
  }, []);

  return (
    <div >
      <div className="flex justify-between gap-3 items-center hidden sm:flex">
        <div className="flex w-full justify-between mt-[20px] relative border px-4 py-2  border-[#2B2B2B] rounded-[8px] items-center ">
          <div className="flex justify-between items-center w-full">
            <div>
              <p className="text-[16px] font-[700]">{title}</p>

              <p className="text-[#A5A5A5] font-[400] text-[14px]">
                {questions?.length} Questions
              </p>
            </div>

            <div>
              <Grid item xs={12} md="auto" mt={{ xs: 1, md: 0 }}>

                <Button
                  sx={{
                    textTransform: "capitalize",
                    fontSize: "16px",
                    color: "#3767B1",
                    mr: 2,
                  }}
                  onClick={handleExpand}
                  variant="text"
                  endIcon={
                    accordionOpen ? (
                      <MdExpandLess color="#3767B1" />
                    ) : (
                      <ExpandMoreIcon color="#3767B1" />
                    )
                  }
                >
                  Show details
                </Button>
              </Grid>
            </div>
          </div>
        </div>

        <div
          className={`${
            mode === "dark" ? "bg-[#171717]" : ""
          } hidden sm:flex h-[63px] rounded-md flex justify-center mt-[20px] items-center p-4`}
          onClick={toggleModal} 
          ref={buttonRef}
        >
          <TfiMoreAlt   className="relative font-extrabold"
          size="20px"
        />

         
        </div>

        <Modal
          sx={{ overflowY: "scroll" }}
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Toolbar>
              <IconButton
                edge="start"
                color="primary"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </Box>
        </Modal>
      </div>

      {/* mobile screen */}
      <div
        className={`${
          mode === "dark" ? "bg-[#1F1F1F]" : ""
        } px-3 py-4 rounded-[8px] sm:hidden relative mb-3`}
        ref={buttonRef}
      >
        <div className="flex justify-between items-center ">
          <div className="flex justify-between items-center ">
            <div>
              <p className="text-[16px] font-[700]">{title}</p>

              <p className="text-[#A5A5A5] font-[400] text-[14px]">
                {questions?.length} Questions
              </p>
            </div>
          </div>

          <div className="relative">
            <IoIosMore className="relative" onClick={toggleModal} />
          </div>

          
        </div>

        <div>
          <Grid item xs={12} md="auto" mt={{ xs: 1, md: 0 }}>
            <Button
                  sx={{
                    textTransform: "capitalize",
                    fontSize: "12px",
                    color: "#3767B1",
                    mr: 2,
                  }}
                  onClick={handleExpand}
                  variant="text"
                  endIcon={
                    accordionOpen ? (
                      <MdExpandLess color="#3767B1" />
                    ) : (
                      <ExpandMoreIcon color="#3767B1" />
                    )
                  }
                >
                  Show details
                </Button>
          </Grid>
        </div>
      </div>
      {accordionOpen && <Details questions={questions} />}

      {openModal && (
            <div ref={modalRef}  className="absolute right-[40px] sm:right-[70px] mt-[-80px] sm:mt-[-10px] text-white shadow-lg flex-col flex gap-3 rounded-md w-[149px] h-[90px] px-4 py-3 bg-[#1B1B1B] z-10">
              {/* <div
                className="flex items-center gap-3"
                onClick={() => copyQuestion(id)} 
              >
                <ContentCopyIcon fontSize='small'   /> 
                <p>Copy</p>
              </div> */}

              <div
                className="flex items-center gap-3"
                onClick={() => editQuestion(id)}
              >
                <EditIcon fontSize="small" />
                <p>Edit</p>
              </div>

              {/* onClick={() => OpenDeleteModal(id)} */}
              <div
                className="flex items-center gap-3"
                onClick={OpenDeleteModal}
              >
                <DeleteIcon fontSize="small" />

                <p>Delete</p>
              </div>
            </div>
          )}
          {deletingModal && (
            <Dialog
              maxWidth="sm"
              fullWidth
              open={OpenDeleteModal}
              onClose={CloseDeleteModal}
            >
              <div
                className="p-[10px] sm:p-[20px] "
                style={{ backgroundColor: mode === "dark" ? "#171717" : "" }}
              >
                <DialogTitle>Delete Question</DialogTitle>
                <DialogContent
                  sx={{ backgroundColor: mode === "dark" ? "#171717" : "" }}
                >
                  <p className="text-[#A5A5A5] text-[10px] sm:text-[14px]">
                    Are you sure you want to delete <b>{title}</b>?
                  </p>
                  <p className="text-[#FF3D3D] mt-[30px] text-[10px] sm:text-[14px]">
                    This action cannot be undone.
                  </p>
                </DialogContent>
                <DialogActions
                  sx={{ backgroundColor: mode === "dark" ? "#171717" : "" }}
                >
                  <Button
                    onClick={CloseDeleteModal}
                    color="primary"
                    variant="contained"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      deleteCategory(id);
                      CloseDeleteModal();
                    }}
                    color="error"
                    variant="outlined"
                  >
                    Delete
                  </Button>
                </DialogActions>
              </div>
            </Dialog>
          )}  
    </div>
  );
};

export default QuestionCard;
