import React, { useMemo } from 'react';
import { Box, Button, Divider, TextField, Typography } from '@mui/material';
import { Form, Submit, useForm } from '../hooks/useForm';
import { handleAxiosError } from '../utilities/function';
import { useMessage } from './Header';
import { Input } from '../hooks/useForm/inputs';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { sm: 600, xs: 300 },
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 2,
};

const ClockOutModal = ({ handleClose, AttendanceDataFetch ,fetchData }) => {
   
    
    let currentOrg = JSON.parse(localStorage.getItem("org"));
    let currentEmp = currentOrg?.type == "Member" ? JSON.parse(localStorage.getItem("emp")): null;
   
    
    const { showError, showSuccess } = useMessage();

    const handlers = useForm(
        useMemo(
            () => ({
                note: '',
            }),
            []
        ),
        { Input: TextField }
    );

    function onSubmit(res) {
        const { success, message } = res.data;
        if (success) {
            showSuccess(message);
            AttendanceDataFetch();
            fetchData();
            handleClose();
            return;
        }

        showError(message || 'Something went wrong');
    }

    function onError(e) {
        handleAxiosError(e, showError);
    }



    return (
        <>
            <Box sx={style}>
                <Typography id='modal-modal-title' variant='h6'>
                    Clock Out
                </Typography>
                <Divider sx={{ my: 2 }} />
                <Typography id='modal-modal-description' sx={{ my: 4 }} variant='body1'>
                    Please provide detail of what you have completed today:
                </Typography>
                <Form
                    handlers={handlers}
                    onSubmit={onSubmit}
                    onError={onError}
                    action={`/employee/shift/clock-out?empId=${currentEmp?._id}`}
                    method='POST'>
                    <Input
                        fullWidth
                        name='note'
                        multiline
                        rows={5}
                        placeholder='Note .................'
                    />

                    <Box mt={5} textAlign='right'>
                        <Button
                            onClick={handleClose}
                            variant='contained'
                            sx={{
                                mx: 2,
                            }}>
                            Close
                        </Button>

                        <Submit>
                            {loader => (
                                <Button
                                    type='submit'
                                    variant='contained'
                                    disabled={Boolean(loader)}
                                    endIcon={loader}>
                                    Clock Out
                                </Button>
                            )}
                        </Submit>
                    </Box>
                </Form>
            </Box>
        </>
    );
};

export default ClockOutModal;
