import { Box, Button, Drawer, MenuItem, Select } from "@mui/material";
import { X } from "lucide-react";
import React, { useState } from "react";
import { useTheme } from "../../../style/theme";
import { IoFilterOutline } from "react-icons/io5";
import { CiExport, CiImport } from "react-icons/ci";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { IoIosMore, IoMdInformationCircleOutline } from "react-icons/io";
import { LuCalendarDays } from "react-icons/lu";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { CgClose } from "react-icons/cg";

export default function CompensatoryRequest() {
  const { mode } = useTheme();
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [filter, setFilter] = useState("");

  const [isOpen, setIsOpen] = useState(false);
  const [recordsPerPage, setRecordsPerPage] = useState(20);
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const FilterDrawer = () => {
    return (
      <Drawer
        anchor="right"
        open={openFilterDrawer}
        onClose={() => setOpenFilterDrawer(false)}
      >
        <div
          style={{
            backgroundColor: mode === "light" ? "#fff" : "background.view",
          }}
          className="w-72 p-4"
        >
          <div className="flex justify-between items-center">
            <h2 className="text-lg font-semibold mb-4">Filter</h2>
            <button
              className="border-none outline-none"
              onClick={() => setOpenFilterDrawer(false)}
            >
              <X size={20} />
            </button>
          </div>

          <label className="block text-sm font-medium mb-2 text-[#666666] mt-2">
            Period
          </label>
          <Select
            value={filter}
            defaultValue="This Year"
            onChange={(e) => setFilter(e.target.value)}
            fullWidth
            sx={{
              backgroundColor: mode === "light" ? "#fff" : "",
            }}
            // className="bg-white"
            size="small"
          >
            <MenuItem value="Yesterday">Yesterday</MenuItem>
            <MenuItem value="Today">Today</MenuItem>
            <MenuItem value="Last Month">Last Month</MenuItem>
            <MenuItem value="This Month">This Month</MenuItem>
            <MenuItem value="Last Year">Last Year</MenuItem>
            <MenuItem value="This Year">This Year</MenuItem>
            <MenuItem value="Custom">Custom</MenuItem>
          </Select>

          <div className="mt-4">
            <label className="block text-sm font-medium text-[#666666] mb-2 mt-1">
              From
            </label>
            <div
              className={`w-[250px] h-[30px] flex justify-between border border-[#a1a1a2] ${
                mode === "light" ? "bg-[#eeeef1]" : ""
              } rounded-md px-2 py-2 items-center `}
            >
              <p className="text-[#a1a1a2] text-[14px]">01-Jan-2025</p>
              <LuCalendarDays color="#a1a1a2" />
            </div>
          </div>

          <div className="mt-4">
            <label className="block text-sm text-[#666666] font-medium mb-2 mt-1">
              To
            </label>
            <div
              className={`w-[250px] h-[30px] flex justify-between border border-[#a1a1a2] ${
                mode === "light" ? "bg-[#eeeef1]" : ""
              } rounded-md px-2 py-2 items-center `}
            >
              <p className="text-[#a1a1a2] text-[14px]">31-Dec-2025</p>
              <LuCalendarDays color="#a1a1a2" />
            </div>
          </div>
        </div>
        <div className="flex items-end h-full mb-5 justify-start px-5 space-x-2">
          <Button variant="contained" sx={{ bgColor: "#0088ff" }}>
            Apply
          </Button>
          <Button variant="outlined" onClick={() => setFilter("")}>
            Reset
          </Button>
        </div>
      </Drawer>
    );
  };

  const AddRequestDrawer = () => {
    return (
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => setDrawerOpen(false)}
        PaperProps={{
          className: "w-[99%] m-1 md:w-[650px] lg:w-[800px] rounded-lg",
        }}
      >
        <div className="h-full bg-gray-200 flex flex-col relative">
          {/* Sticky Header */}
          <div
            className={`${
              mode === "light" ? "bg-[#edf0f4]" : "bg-[#141414]"
            }  px-4 py-2 flex justify-between items-center sticky top-0 z-10`}
          >
            <h2 className="text-[18px] font-semibold">Add Request</h2>
            <div
              className={`${
                mode === "light" ? "bg-[#dcdcdc]" : ""
              } p-2 rounded-md cursor-pointer`}
              onClick={() => setDrawerOpen(false)}
            >
              <CgClose />
            </div>
          </div>

          <div
            className={`${
              mode === "light" ? "bg-[#edf0f4]" : "bg-[#141414]"
            } flex-1 overflow-y-auto p-4`}
          >
            <div
              style={{ backgroundColor: mode === "light" ? "#fff" : "#171717" }}
              className="px-5 py-4 rounded-md flex flex-col sm:flex-row  justify-between gap-5 "
            >
              <div className="w-full sm:w-[50%] lg:w-[400px]">
                <div className="mb-4 w-full mt-2">
                  <div className="flex gap-2 items-center">
                    <label className={`block text-[14px] font-[500] mb-2 mt-4`}>
                      Worked date <span className="text-red-500">*</span>
                    </label>
                    <IoMdInformationCircleOutline />
                  </div>

                  <TextField
                    type="date"
                    fullWidth
                    size="small"
                    className="w-full"
                  />
                </div>

                <div className="mb-4 w-full">
                  <label className={`block text-[14px] font-[500] mb-2 mt-4`}>
                    Unit <span className="text-red-500">*</span>
                  </label>
                  <RadioGroup row className="w-full">
                    <FormControlLabel
                      value="days"
                      control={<Radio />}
                      label="Days"
                    />
                    <FormControlLabel
                      value="hours"
                      control={<Radio />}
                      label="Hours"
                    />
                  </RadioGroup>
                </div>

                <div className="mb-4 w-full">
                  <label Duration={`block text-[14px] font-[500] mb-2 mt-4`}>
                    Unit <span className="text-red-500">*</span>
                  </label>
                  <Select fullWidth size="small" defaultValue="Full Day">
                    <MenuItem value="Full Day">Full Day</MenuItem>
                    <MenuItem value="Half Day">Half Day</MenuItem>
                  </Select>
                </div>

                <div className="mb-4 w-full">
                  <label className="block text-[14px] font-[500] mb-2 mt-4">
                    Worked time
                  </label>
                  <div className="flex gap-2">
                    <TextField type="time" fullWidth size="small" />
                    <TextField type="time" fullWidth size="small" />
                  </div>
                </div>

                <div className="mb-4 w-full">
                  <label Duration={`block text-[14px] font-[500] mb-2 mt-4`}>
                    Expiry date <span className="text-red-500">*</span>
                  </label>
                  <TextField type="date" fullWidth size="small" />
                </div>

                <div className="mb-4 w-full">
                  <label className="block text-[14px] font-[500] mb-2 mt-4">
                    Reason
                  </label>
                  <TextField fullWidth multiline rows={1} />
                </div>
              </div>

              {/* Fixed Attendance Details Box */}
              <div
                style={{
                  backgroundColor: mode === "light" ? "#FFF7E5" : "#191919",
                }}
                className="sm:fixed sm:right-[40px] sm:top-[70px] p-4 rounded md:w-[170px]"
              >
                <h3 className="font-semibold text-[14px] text-[#ff832b] mb-2">
                  Attendance details
                </h3>
                <p className="flex gap-8 text-[13px] mt-4">
                  First in <span>-</span>
                </p>
                <p className="flex gap-8 text-[13px] mt-4">
                  Last out <span>-</span>
                </p>
                <p className="flex gap-8 text-[13px] mt-4">
                  Overtime <span>-</span>
                </p>
                <p className="flex gap-8 text-[13px] mt-4">
                  Total hours <span>-</span>
                </p>
              </div>
            </div>
          </div>

          {/* Sticky Footer */}
          <div
            className={`sticky flex gap-3 items-center top-0 ${
              mode === "light" ? "bg-[#edf0f4]" : "bg-[#141414]"
            }  z-10 py-4 px-4`}
          >
            <Button variant="contained" sx={{ backgroundColor: "#0088ff" }}>
              Submit
            </Button>
            <Button
              variant="outlined"
              sx={{ backgroundColor: mode === "light" ? "#fff" : "" }}
              onClick={() => setDrawerOpen(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Drawer>
    );
  };
  return (
    <div className="relative h-[100vh]">
      <div className="flex justify-end items-center gap-2 m-[20px]">
        <div
          className={`cursor-pointer relative h-fit group p-2 rounded-md ${
            mode === "light" ? "bg-[#fff]" : ""
          }`}
        >
          <IoFilterOutline
            size={17}
            className="cursor-pointer"
            onClick={() => setOpenFilterDrawer(true)}
          />
          <span className="absolute left-1/2 -translate-x-1/2 text-xs w-[max-content] bg-gray-800 text-white px-2 py-1 rounded opacity-0 group-hover:opacity-100 transition">
            Filter
          </span>
        </div>

        <div
          onClick={() => setIsOpen(!isOpen)}
          className={`cursor-pointer relative h-fit group p-2 rounded-md ${
            mode === "light" ? "bg-[#fff]" : ""
          }`}
        >
          <IoIosMore size={17} className="cursor-pointer" />
          {isOpen && (
            <Box
              sx={{ backgroundColor: mode === "light" ? "#fff" : "#363636" }}
              className="absolute w-[150px] z-40 right-3 shadow-md p-5 flex flex-col gap-2 rounded-lg top-10"
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <CiImport size={18} />
                <p className="text-[14px]">Import</p>
              </div>
              <div className="flex gap-2 items-center cursor-pointer">
                <CiExport size={18} />
                <p className="text-[14px]">Export</p>
              </div>
              <div className="flex gap-2 items-center cursor-pointer">
                <MdOutlineRemoveRedEye size={18} />
                <p className="text-[14px]">Audit History</p>
              </div>
            </Box>
          )}
          <span className="absolute left-1/2 -translate-x-1/2 w-[max-content] text-xs bg-gray-800 text-white px-2 py-1 rounded opacity-0 group-hover:opacity-100 transition">
            More
          </span>
        </div>
      </div>

      <div
        className={`flex flex-col justify-center items-center rounded-md mt-[30px] mx-[20px]  ${
          mode === "light" ? "bg-[#ffffff] border border-[#d7e2ed]" : ""
        }`}
      >
        <div className="mt-[50px] mb-[50px] flex flex-col justify-center items-center">
          <img
            style={{ width: "180px", marginBottom: "10px" }}
            src="/images/no_timeLogs.png"
            alt=""
          />
          <h4 className="font-[500] text-[16px]">
            No data to display currently
          </h4>

          <button
            className="w-[120px] h-[32px] bg-[#0088FF] rounded-md text-white mt-2"
            onClick={() => setDrawerOpen(true)}
          >
            Add Request
          </button>
        </div>
      </div>

      <div
        className={`fixed bottom-[50px] md:bottom-0 md:left-[100px] right-0 md:right-[180px] w-full ${
          mode === "light" ? "bg-[#f7f9fc]" : "bg-[#141414]"
        }  p-3 flex flex-col md:flex-row md:items-center justify-between text-sm`}
      >
        <div className="text-gray-700">
          Total Record Count : <span className="text-blue-500">0</span>
        </div>

        <div className="flex items-center gap-3 md:me-[150px]">
          <select
            className={` ${
              mode === "light" ? "bg-white" : "bg-transparent"
            } border border-gray-300 rounded px-2 py-1`}
            value={recordsPerPage}
            onChange={(e) => setRecordsPerPage(Number(e.target.value))}
          >
            {[20, 50, 75, 100, 200].map((num) => (
              <option 
              className={mode === "light" ? "bg-white text-black" : "bg-black text-white"}
              key={num} value={num}>
                {num}
              </option>
            ))}
          </select>
          <span className="text-gray-600">Records per page</span>

          <div className="flex items-center gap-2">
            <button className="px-2 py-1 border rounded text-gray-500 cursor-not-allowed">
              &lt;
            </button>
            <span className="text-gray-700">1 - 0</span>
            <button className="px-2 py-1 border rounded text-gray-500 cursor-not-allowed">
              &gt;
            </button>
          </div>
        </div>
      </div>

      <FilterDrawer />
      <AddRequestDrawer />
    </div>
  );
}
