import React, { useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { createTheme, ThemeProvider } from "@mui/material";
import { useTheme } from "../style/theme";

export default function CustomTable({
  columns,
  data,
  renderActions,
  loading,
  error,
  sorting,
  setSorting,
  rowSelection,
  setRowSelection,
  pagination,
  setPagination,
  totalRows,
  enableColumnActions=true,
  enableFullScreenToggle=true,
  enableHiding=true,
  onRowClick = (row) => { },
  renderRowSelection = (table) => { },
  isBulkSelect = false,
  isPagination = true,
}) {
  const updatedColumns = useMemo(() => {
    return columns.map((col) => {
      if (col.accessorKey === "actions") {
        return {
          ...col,
          Cell: ({ row }) => renderActions(row),
        };
      }
      return col;
    });
  }, [columns, renderActions]);

  const { mode } = useTheme(); //(optional) if you already have a theme defined in your app root, you can import here

  const tableTheme = useMemo(() => {
    return createTheme({
      palette: {
        mode, // Sync with global theme's light/dark mode
        primary: {
          main: mode === "light" ? "#0000" : "#ffff", // Custom primary color
        },
        background: {
          default: mode === "light" ? "#ffffff" : "#00000000", // Custom background colors
        },
        info: {
          main: "#ff7a00", // Custom color for alerts
        },
      },
      typography: {
        button: {
          textTransform: "none", // Buttons in the table will have no uppercase transformation
          fontSize: "1rem",
        },
      },
      components: {
        MuiTooltip: {
          styleOverrides: {
            tooltip: {
              fontSize: "1rem",
            },
          },
        },
        MuiSwitch: {
          styleOverrides: {
            thumb: {
              color: "#3B84D9", // Custom color for switch thumb
            },
          },
        },
      },
    });
  }, [mode]);


  return (
    <div className="w-full h-full">
      {isPagination ? (
        <ThemeProvider theme={tableTheme}>
          <MaterialReactTable
            columns={updatedColumns}
            data={Array.isArray(data) ? data : []}
            enableRowSelection={isBulkSelect}
            onRowSelectionChange={isBulkSelect ? setRowSelection : undefined}
            manualPagination
            manualSorting
            rowCount={totalRows}
            onPaginationChange={setPagination}
            onSortingChange={setSorting}
            state={{
              pagination,
              sorting,
              rowSelection: isBulkSelect ? rowSelection : {},
            }}
            enableSortingRemoval={false}
            enableColumnFilters
            enableColumnFilterModes={false}
            enableGlobalFilter={false}
            enableColumnActions={enableColumnActions}
            enableDensityToggle={false}
            enableFullScreenToggle={enableFullScreenToggle}
            enableHiding={enableHiding}
            enableStickyHeader
            // enableColumnResizing
            muiTablePaperProps={{
              elevation: 3,
              sx: {
                height: "100%",
                display: "flex",
                flexDirection: "column"
              },
            }}
            muiTableContainerProps={{
              sx: {
                flex: 1,
                overflow: "auto",
              },
            }}
            muiTableHeadProps={{
              sx: {
                backgroundColor: "#1E1E1E",
                border: "none",
              },
            }}
            muiTableBodyCellProps={({ column, row }) => ({
              onClick: (e) => {
                if (column.id !== "actions") {
                  onRowClick(row);
                }
              },
              sx: {
                cursor: column.id === "actions" ? "default" : "pointer",
                border: "none"
              },
            })}
            renderTopToolbarCustomActions={({ table }) =>
              renderRowSelection(table)
            }
          />
        </ThemeProvider>
      ) : (
        <MaterialReactTable
          columns={updatedColumns}
          data={data}
          enablePagination={false}
          enableSortingRemoval={false}
          enableColumnFilterModes={false}
          enableGlobalFilter={false}
          enableColumnFilters={false}
          enableColumnActions={enableColumnActions}
          enableDensityToggle={false}
          enableFullScreenToggle={enableFullScreenToggle}
          enableHiding={enableHiding}
          enableStickyHeader
          // enableColumnResizing
          muiTablePaperProps={{
            elevation: 3,
            sx: {
              height: "100%",
              display: "flex",
              flexDirection: "column",
            },
          }}
          muiTableContainerProps={{
            sx: {
              flex: 1,
              overflow: "auto",
            },
          }}
          muiTableBodyCellProps={({ column, row }) => ({
            onClick: (e) => {
              if (column.id !== "actions") {
                onRowClick(row);
              }
            },
            sx: {
              cursor: column.id === "actions" ? "default" : "pointer",
            },
          })}
          renderTopToolbarCustomActions={({ table }) =>
            renderRowSelection(table)
          }
        />
      )}
    </div>
  );
}