import React, { useCallback, useEffect, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box } from "@mui/material";
import axios from "axios";
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useTheme } from "../../style/theme";
import MessageIcon from "@mui/icons-material/Message";
import CallIcon from "@mui/icons-material/Call";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import ReportIcon from "@mui/icons-material/Report";
import BlockIcon from "@mui/icons-material/Block";
import { TbMessageDots } from "react-icons/tb";
import { IoCallOutline } from "react-icons/io5";
import { CiVideoOn } from "react-icons/ci";
import { MdBlockFlipped, MdOutlineReport } from "react-icons/md";

const ContactList = ({ setSharedData, contacts }) => {
  let page = 1;
  let img =
    "https://images.unsplash.com/photo-1605993439219-9d09d2020fa5?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjB8fHVzZXIlMjBwcm9maWxlfGVufDB8fDB8fHww";
  const userList = [
    {
      id: 1,
      name: "Randy",
      email: "randy@clikkle.com",
      img: "https://images.unsplash.com/photo-1605993439219-9d09d2020fa5?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjB8fHVzZXIlMjBwcm9maWxlfGVufDB8fDB8fHww",
    },
    {
      id: 2,
      name: "John",
      email: "john@clikkle.com",
      img: "https://images.unsplash.com/photo-1605993439219-9d09d2020fa5?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjB8fHVzZXIlMjBwcm9maWxlfGVufDB8fDB8fHww",
    },
    {
      id: 3,
      name: "Michael",
      email: "michael@clikkle.com",
      img: "https://images.unsplash.com/photo-1605993439219-9d09d2020fa5?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjB8fHVzZXIlMjBwcm9maWxlfGVufDB8fDB8fHww",
    },
    {
      id: 4,
      name: "Steve",
      email: "steve@clikkle.com",
      img: "https://images.unsplash.com/photo-1605993439219-9d09d2020fa5?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjB8fHVzZXIlMjBwcm9maWxlfGVufDB8fDB8fHww",
    },
    {
      id: 5,
      name: "Brock",
      email: "brock@clikkle.com",
      img: "https://images.unsplash.com/photo-1605993439219-9d09d2020fa5?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjB8fHVzZXIlMjBwcm9maWxlfGVufDB8fDB8fHww",
    },
    {
      id: 6,
      name: "Kane",
      email: "kane@clikkle.com",
      img: "https://images.unsplash.com/photo-1605993439219-9d09d2020fa5?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjB8fHVzZXIlMjBwcm9maWxlfGVufDB8fDB8fHww",
    },
    {
      id: 7,
      name: "Jason",
      email: "jason@clikkle.com",
      img: "https://images.unsplash.com/photo-1605993439219-9d09d2020fa5?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjB8fHVzZXIlMjBwcm9maWxlfGVufDB8fDB8fHww",
    },
    {
      id: 8,
      name: "Armond",
      email: "armond@clikkle.com",
      img: "https://images.unsplash.com/photo-1605993439219-9d09d2020fa5?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjB8fHVzZXIlMjBwcm9maWxlfGVufDB8fDB8fHww",
    },
    {
      id: 9,
      name: "Henery",
      email: "henery@clikkle.com",
      img: "https://images.unsplash.com/photo-1605993439219-9d09d2020fa5?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjB8fHVzZXIlMjBwcm9maWxlfGVufDB8fDB8fHww",
    },

    {
      id: 10,
      name: "Shakira",
      email: "shakiradwain@clikkle.com",
      img: "https://images.unsplash.com/photo-1605993439219-9d09d2020fa5?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjB8fHVzZXIlMjBwcm9maWxlfGVufDB8fDB8fHww",
    },
  ];

  const handleClick = (item) => {
    console.log("first", item);
    setSharedData(item);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const menuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const { mode } = useTheme();
  return (
    <>
      {contacts?.map((item, index) => {
         const nameToDisplay = item.firstName + " " + item.lastName;
         let imgDisplay = "";
         if (item && item?.userImage) {
           imgDisplay = item?.userImage;
         } else if (item && !item?.userImage) {
           imgDisplay = `https://ui-avatars.com/api/?name=${item.firstName} ${item.lastName}`;
         } else {
           imgDisplay = undefined;
         }
         return (
        <div key={index}>
          <div className="flex gap-4 items-center p-1 my-3">
            <div className="h-[31px] w-[31px] md:h-[40px]  md:w-[50px] relative">
              {/* <img
                className="w-full h-full rounded-full object-cover object-top"
                src={img}
                alt=""
              /> */}
                <img
                                    loading="lazy"
                                    width="40"
                                    src={`https://ui-avatars.com/api/?name=${nameToDisplay}`}
                                    alt={nameToDisplay}
                                  />
            </div>
            <div className="flex w-full flex-row justify-between items-center">
              <div
                onClick={() => handleClick(item)}
                className="w-full font-bold"
              >
                <div className="text-base md:text-xs ">
                  {item.firstName} {item.lastName}
                </div>

                <div className="mt-1 line-clamp-1 text-xs text-[#434343] md:text-[9px]">
                  {item.email}
                </div>
              </div>

              <MoreVertIcon
                sx={{ width: "18px", height: "18px" }}
                onClick={menuOpen}
                className="text-[35px] md:text-[20px]"
              />
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                slotProps={{
                  paper: {
                    sx: {
                      backgroundColor: mode === "dark" ? "#1E1E1E" : "#fff",
                      color: mode === "dark" ? "#fff" : "#000",
                    },
                  },
                }}
              >
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <TbMessageDots  size={"20px"}/>
                  </ListItemIcon>
                  <ListItemText primary="Message" />
                </MenuItem>

                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <IoCallOutline size={"20px"}/>
                  </ListItemIcon>
                  <ListItemText primary="Audio Call" />
                </MenuItem>

                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <CiVideoOn size={"20px"}/>
                  </ListItemIcon>
                  <ListItemText primary="Video Call" />
                </MenuItem>

                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <MdOutlineReport size={"20px"}/>
                  </ListItemIcon>
                  <ListItemText primary="Report" />
                </MenuItem>

                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <MdBlockFlipped size={"20px"}/>
                  </ListItemIcon>
                  <ListItemText primary="Block" />
                </MenuItem>
              </Menu>
            </div>
          </div>
          <p className="h-[1px] md:hidden bg-[#111111] w-full"></p>
        </div>
)})}
    </>
  );
};

export default ContactList;
