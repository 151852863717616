import { Grid, Typography } from '@mui/material';
import React from 'react';
import { Images } from '../../components/Images';

const CompleteInterview = () => {
    return (
        <Grid
            container
            spacing={0}
            display='flex'
            alignItems='center'
            // style={{ height: '40vh' }}
            my={3}>
            <Grid
                item
                lg={4}
                xs={12}
                sx={{ textAlign: { lg: 'right', xs: 'center' } , display : { sm: 'block', xs: 'none' } }}
                justifyContent='center'>
                <Images src='interview.svg' style={{ height: '200px' }} />
            </Grid>
            <Grid
                item
                lg={4}
                xs={12}
                sx={{ textAlign: { lg: 'right', xs: 'center' } , display : { sm: 'none', xs: 'block' } }}
                justifyContent='center'>
                <Images src='interview.svg' style={{ height: '165px' }} />
            </Grid>
            
            <Grid item lg={6} xs={12}>
                {' '}
                <Typography sx={{ maxWidth: '700px', ml: 4 , fontSize: { sm: '1.25rem', xs: '1rem' }  }} variant='h6'>
                    "Congratulations on completing your interview! You've taken a first step towards
                    your goals. Now, take a deep breath, relax, and await the next chapter in your
                    journey. Your hard work will pay off."
                </Typography>
            </Grid>
        </Grid>
    );
};

export default CompleteInterview;
