import { Autocomplete, Box, Button, IconButton, InputAdornment, ListSubheader, MenuItem, Modal, Select, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useState } from "react";
import { CiExport, CiImport } from "react-icons/ci";
import { IoIosArrowBack, IoIosArrowForward, IoIosMore, IoMdClose } from "react-icons/io";
import { IoCalendarOutline } from "react-icons/io5";
import { TfiDownload, TfiReload, TfiViewList } from "react-icons/tfi";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useTheme } from "../../../style/theme";
import { FiGrid, FiInfo } from "react-icons/fi";
import { HiOutlineCalendarDays } from "react-icons/hi2";
import SearchIcon from "@mui/icons-material/Search";


export default function LeaveSummary() {
    const [view, setView] = useState("grid");
    const [mode, setMode] = useState("edit");
    const [openCalendar, setOpenCalendar] = useState(false);
    const [selectedDate, setSelectedDate] = useState(dayjs());
    const startOfWeek = selectedDate.startOf("isoWeek"); // Monday
    const endOfWeek = startOfWeek.add(6, "day"); // Sunday
    const formattedStart = startOfWeek.format("DD/MM/YYYY");
    const formattedEnd = endOfWeek.format("DD/MM/YYYY");
    const { mode: themeMode } = useTheme();
    const [isOpen, setIsOpen] = useState(false);
    const [openApply, setOpenApply] = useState(false);
    const [endDate, setEndDate] = useState("");
    const [teamEmail, setTeamEmail] = useState("");
    const [reason, setReason] = useState("");
    const [startDate, setStartDate] = useState("");
    const [selected, setSelected] = useState(null);
    const [search, setSearch] = useState("");
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState("");

    const leaveOptions = [
        { value: "", label: "Select" },
        { value: "compensatory", label: "Compensatory Off" }
    ];

    const filteredOptions = leaveOptions.filter((option) =>
        option.label.toLowerCase().includes(search.toLowerCase())
    );

    const handleOpen = (selectedMode) => {
        setMode(selectedMode);
        setOpenCalendar(true);
    };

    const handlePrevWeek = () => {
        setSelectedDate(selectedDate.subtract(7, "day"));
    };

    const handleNextWeek = () => {
        setSelectedDate(selectedDate.add(7, "day"));
    };

    const [currentMonth, setCurrentMonth] = useState(dayjs());

    const handlePrevMonth = () => {
        setCurrentMonth(currentMonth.subtract(1, "month"));
    };

    const handleNextMonth = () => {
        setCurrentMonth(currentMonth.add(1, "month"));
    };

    const daysInMonth = currentMonth.daysInMonth();
    const firstDay = currentMonth.startOf("month").day();

    const calendarDays = [];
    for (let i = 0; i < firstDay; i++) {
        calendarDays.push(null);
    }
    for (let i = 1; i <= daysInMonth; i++) {
        calendarDays.push(i);
    }

    const [upcoming, setUpcoming] = useState("Upcoming Leave & Holidays");
    const [past, setPast] = useState("Past Leave & Holidays");

    const upcomingOption = [
        "Upcoming Leave & Holidays",
        "Upcoming Leave",
        "Upcoming Holidays",
    ];

    const pastOption = [
        "Past Leave & Holidays",
        "Past Leave",
        "Past Holidays",
    ];

    const LeaveCard = ({ title, abbreviation, color, bgColor }) => {
        const [showTooltip, setShowTooltip] = useState(false);
        const { mode: themeMode } = useTheme();

        return (
            <div style={{
                backgroundColor: themeMode === "dark" ? "#141414" : "white",
                border: themeMode === "dark" ? "" : "1px solid #d7e2ed"
            }}
                className="border flex flex-col gap-4 rounded-xl p-4 sm:w-48 text-center">
                <h2 className="text-gray-700 font-semibold mb-2">{title}</h2>
                <div
                    className={`w-12 h-12 mx-auto flex items-center justify-center rounded-lg text-[22px] font-bold`}
                    style={{ backgroundColor: bgColor, color: color }}
                >
                    {abbreviation}
                </div>
                <hr className="my-2 border-gray-200" />
                <div className="text-sm text-gray-600">
                    <div className="flex justify-between">
                        <span>Available</span>
                        <span className="me-[22px]">0</span>
                    </div>
                    <div className="flex justify-between relative">
                        <span>Booked</span>
                        <span
                            className="flex items-center space-x-1"
                            onMouseEnter={() => setShowTooltip(true)}
                            onMouseLeave={() => setShowTooltip(false)}
                        >
                            0
                            <FiInfo onClick={() => setOpenApply(!openApply)} className="ms-2 text-gray-500 cursor-pointer hover:text-[#ebcc34]" />
                        </span>
                        {showTooltip && (
                            <div className="absolute bg-white text-gray-700 text-xs border shadow-md p-3 rounded-lg bottom-6 right-0 w-24">
                                <div className="flex justify-between mb-2">
                                    <p>Used:</p>
                                    <p>0</p>
                                </div>
                                <div className="flex justify-between">
                                    <p>Planned:</p>
                                    <p>0</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    };


    return (<>
        <Box className="p-4" sx={{ background: themeMode === "dark" ? "#141414" : "#f7f9fc", marginBottom: { md: "0px", xs: '60px' } }}>

            {/* header */}
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: { md: "center", xs: "start" }, flexDirection: { xs: "column", md: "row" }, marginBottom: "20px" }} className="gap-2">
                {view === "grid" ? (
                    <div className="flex text-[14px] gap-3">
                        <p>Leave booked this year : <span className="border-r-2 pe-3">0</span></p>
                        <p>Absent : <span>0</span></p>
                    </div>
                ) : (<div />)}

                <Box sx={{ marginLeft: view === "grid" ? "0px" : { md: "165px", xs: "0px" } }} className="flex relative items-center gap-2">
                    {/* Previous Week Button */}
                    <div style={{ backgroundColor: themeMode === "dark" ? "#171717" : "white" }} className="flex items-center gap-2 p-2 rounded-md">
                        <IoIosArrowBack
                            fontSize="small"
                            className="cursor-pointer hover:text-gray-600"
                            onClick={() => { view === "calendar" ? handlePrevMonth() : handlePrevWeek() }}
                            color="#398aff"
                        />

                        {/* Calendar Icon and Date Range Text */}
                            {/* className={`relative flex items-center rounded-md cursor-pointer ${themeMode === "dark" ? "hover:bg-[#191919]" : "hover:bg-gray-200"}`} */}
                        <Box
                        >
                            <IoCalendarOutline onClick={() => setOpenCalendar(!openCalendar)} size="18px" />
                        </Box>

                        {/* Next Week Button */}
                        <IoIosArrowForward
                            fontSize="small"
                            className="cursor-pointer hover:text-gray-600"
                            onClick={() => { view === "calendar" ? handleNextMonth() : handleNextWeek() }}
                            color="#398aff"
                        />
                    </div>

                    {view === "calendar" ? (
                        <span className="text-sm font-semibold">
                            {currentMonth.format("MMMM YYYY")}
                        </span>
                    ) : (
                        <span className="text-[14px] font-medium">
                            {formattedStart} - {formattedEnd}
                        </span>
                    )}

                    {/* Calendar Popup */}
                    {openCalendar && (
                        <Box sx={{ backgroundColor: themeMode === "dark" ? "#171717" : "white" }} className="absolute top-8 z-10 shadow-md border rounded-md">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateCalendar
                                    value={selectedDate}
                                    onChange={(date) => {
                                        setSelectedDate(date);
                                        setOpenCalendar(false);
                                    }}
                                />
                            </LocalizationProvider>
                        </Box>
                    )}
                </Box>

                <div className="flex gap-1 md:gap-3 md:items-center" style={{ display: "flex" }}>
                    <div className={`rounded-md flex items-center gap-1  ${themeMode === "light" ? "bg-[#fff]" : ""
                        }`}>
                        <div
                            className="relative group p-1 rounded-md"
                            style={{ border: view === "grid" ? "1px solid #0088ff" : "" }}
                        >
                            <FiGrid
                                size={20}
                                className="cursor-pointer"
                                onClick={() => setView("grid")}
                                color={view === "grid" ? "#0088ff" : ""}
                            />
                            <span className="absolute top-10 left-1/2 w-[max-content] -translate-x-1/2 text-xs bg-gray-800 text-white px-2 py-1 rounded opacity-0 group-hover:opacity-100 transition">
                                Grid view
                            </span>
                        </div>
                        <div
                            className="relative group p-1 rounded-md"
                            style={{
                                border: view === "calendar" ? "1px solid #0088ff" : "",
                            }}
                        >
                            <HiOutlineCalendarDays
                                size={20}
                                className="cursor-pointer"
                                onClick={() => setView("calendar")}
                                color={view === "calendar" ? "#0088ff" : ""}
                            />
                            <span className="absolute top-10 left-1/2 -translate-x-1/2 w-[max-content] text-xs bg-gray-800 text-white px-2 py-1 rounded opacity-0 group-hover:opacity-100 transition">
                                Calendar view
                            </span>
                        </div>
                    </div>
                    <div>
                        <button
                            className="bg-[#0088ff] py-1 text-white px-3 rounded-[3px]"
                            onClick={() => setOpenApply(!openApply)}
                        >
                            Apply Leave
                        </button>
                    </div>

                    <div className={`relative h-fit group p-2 rounded-md ${themeMode === "light" ? "bg-[#fff]" : ""}`}
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        <IoIosMore size={17} className="cursor-pointer" />
                        {isOpen &&
                            <Box sx={{ backgroundColor: themeMode === "light" ? "#fff" : "#363636" }} className="absolute w-[180px] z-40 right-2 p-5 flex flex-col gap-4 rounded-lg top-10">
                                <div className="flex gap-2 items-center cursor-pointer">
                                    <TfiReload size={18} />
                                    <p className="text-[14px]">Calendar Sync</p>
                                </div>
                                <div className="flex gap-2 items-center cursor-pointer">
                                    <CiImport size={18} />
                                    <p className="text-[14px]">Import</p>
                                </div>
                                <div className="flex gap-2 items-center cursor-pointer">
                                    <CiExport size={18} />
                                    <p className="text-[14px]">Export</p>
                                </div>
                            </Box>
                        }
                        <span className="absolute left-1/2 -translate-x-1/2 w-[max-content] text-xs bg-gray-800 text-white px-2 py-1 rounded opacity-0 group-hover:opacity-100 transition">
                            More
                        </span>
                    </div>
                </div>
            </Box>

            {view === "grid" ? (
                <div>
                    <div className="flex flex-col sm:flex-row gap-3 my-4">
                        <LeaveCard title="Absent" abbreviation="A" bgColor="#f9dddd" color="#e05654" />
                        <LeaveCard title="Compensatory Off" abbreviation="CO" bgColor="#eaf2d9" color="#a1bc42" />
                    </div>

                    <div>
                        <div style={{
                            backgroundColor: themeMode === "dark" ? "#141414" : "white",
                            border: themeMode === "dark" ? "" : "1px solid #d7e2ed"
                        }}
                            className="p-4 h-[400px] flex flex-col rounded-lg">
                            <div className="mb-6">
                                <select
                                    className={`border rounded-lg px-4 py-2 ${themeMode === "light" ? "text-gray-700 bg-[#ffffff]" : "text-[#fff] bg-[#141414]"}   shadow-sm focus:ring-blue-500 focus:border-blue-500`}
                                    value={upcoming}
                                    onChange={(e) => setUpcoming(e.target.value)}
                                >
                                    {upcomingOption.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            {/* No Data UI */}
                            <div style={{
                                backgroundColor: themeMode === "dark" ? "#141414" : "white",
                                border: themeMode === "dark" ? "" : "1px solid #d7e2ed"
                            }}
                                className="rounded-lg p-8 flex flex-col items-center">
                                <img
                                    src="/images/no_timeLogs.png"
                                    alt="No Data Found"
                                    className="w-48 h-48 mb-4"
                                />
                                <p className="text-gray-500 text-lg">No Data Found</p>
                            </div>
                        </div>
                    </div>

                    <div className="mt-4">
                        <div style={{
                            backgroundColor: themeMode === "dark" ? "#141414" : "white",
                            border: themeMode === "dark" ? "" : "1px solid #d7e2ed"
                        }}
                            className="p-4 h-[400px] flex flex-col rounded-lg">
                            <div className="mb-6">
                                <select
                                    className={`border rounded-lg px-4 py-2 ${themeMode === "dark" ? "text-[#fff] bg-[#141414]" : " text-gray-700 bg-white"}   shadow-sm focus:ring-blue-500 focus:border-blue-500`}
                                    value={past}
                                    onChange={(e) => setPast(e.target.value)}
                                >
                                    {pastOption.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            {/* No Data UI */}
                            <div style={{
                                backgroundColor: themeMode === "dark" ? "#141414" : "white",
                                border: themeMode === "dark" ? "" : "1px solid #d7e2ed"
                            }}
                                className="rounded-lg p-8 flex flex-col items-center">
                                <img
                                    src="/images/no_timeLogs.png"
                                    alt="No Data Found"
                                    className="w-48 h-48 mb-4"
                                />
                                <p className="text-gray-500 text-lg">No Data Found</p>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (<>
                {/* Calendar Grid */}
                <div style={{
                    backgroundColor: themeMode === "dark" ? "#141414" : "white",
                    border: themeMode === "dark" ? "1px solid" : "1px solid #d7e2ed"
                }} className="grid grid-cols-7 gap-0 sm:p-2 text-center text-gray-700 font-medium">
                    {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
                        <div key={day} className="sm:p-2 py-2">{day}</div>
                    ))}
                </div>
                <div className="grid grid-cols-7 gap-0">
                    {calendarDays.map((day, index) => (
                        <div style={{
                            border: themeMode === "dark" ? "1px solid #374151" : "1px solid #d7e2ed"
                        }}
                            key={index}
                            className={`sm:h-32 p-3 ${themeMode === "dark" ? "bg-[#141414]" : "bg-white"}`}
                            onClick={() => setSelectedDate(currentMonth.date(day))}
                        >
                            {day && <span className={`text-[14px] sm:text-lg ${day === selectedDate.date() &&
                                currentMonth.isSame(selectedDate, "month")
                                ? "bg-blue-500 p-1 px-2 rounded-lg text-white font-bold"
                                : ""
                                }`}>{day}</span>}
                        </div>
                    ))}
                </div>
            </>)}

            <Modal open={openApply} onClose={() => setOpenApply(false)}>
                <Box className="flex justify-center items-center">
                    <Box sx={{
                        backgroundColor: themeMode === "dark" ? "#141414" : "white"
                    }} className=" rounded-lg w-screen h-[87vh] sm:h-[90vh]">

                        {/* Header Section */}
                        <div className="flex justify-between items-center p-2 sm:p-3 border-b">
                            <Typography variant="h6" className="font-semibold">Apply Leave</Typography>
                            <IconButton onClick={() => setOpenApply(false)} sx={{ backgroundColor: themeMode === "light" ? "#dcdcdc" : "", p: "5px", borderRadius: "5px" }}>
                                <IoMdClose size={18} />
                            </IconButton>
                        </div>


                        <div style={{
                            backgroundColor: themeMode === "dark" ? "#141414" : "#edf0f4"
                        }} className="relative h-full">
                            <div className="p-2 sm:p-4 h-full relative">
                                {/* Form Section */}
                                <form
                                    style={{
                                        backgroundColor: themeMode === "dark" ? "#141414" : "white",
                                    }}
                                    className="mt-4 flex flex-col gap-y-5 p-2 sm:p-4 w-full sm:w-[70%] rounded-lg"
                                >
                                     <h2 className="text-[16px] font-semibold mb-2">Leave</h2>
                                     <hr />
                                    {/* Leave Type */}
                                    <div className="flex items-center w-full">
                                        <label className={`${mode === "light" ? "text-gray-600":"text-white"} font-medium text-[14px] w-[20%]`}>
                                            Leave Type <span className="text-red-500">*</span>
                                        </label>
                                        <div className="w-[80%]">
                                            <Select
                                                className={`${themeMode === "light" ? "bg-white" : "bg-[#141414]"}`}
                                                fullWidth
                                                size="small"
                                                value={category}
                                                onChange={(e) => setCategory(e.target.value)}
                                                displayEmpty
                                                MenuProps={{
                                                    PaperProps: {
                                                        style: {
                                                            maxHeight: 250,
                                                            backgroundColor: themeMode === "dark" ? "#141414" : "#ffffff",
                                                        },
                                                    },
                                                }}
                                            >
                                                {/* Search Bar in Dropdown */}
                                                <ListSubheader
                                                    style={{ backgroundColor: themeMode === "ligth" ? "white" : "transparent" }}>
                                                    <TextField
                                                        fullWidth
                                                        size="small"
                                                        placeholder="Search..."
                                                        autoFocus
                                                        value={search}
                                                        onChange={(e) => setSearch(e.target.value)}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <SearchIcon fontSize="small" />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </ListSubheader>

                                                {/* Filtered Categories */}
                                                {categories.filter((cat) => cat.toLowerCase().includes(search.toLowerCase())).length > 0 ? (
                                                    categories
                                                        .filter((cat) => cat.toLowerCase().includes(search.toLowerCase()))
                                                        .map((cat) => (
                                                            <MenuItem key={cat} value={cat}>
                                                                {cat}
                                                            </MenuItem>
                                                        ))
                                                ) : (
                                                    <ListSubheader
                                                        style={{ backgroundColor: themeMode === "ligth" ? "white" : "transparent" }} className="">
                                                        <MenuItem>Select</MenuItem>
                                                    </ListSubheader>

                                                )}
                                            </Select>
                                        </div>
                                    </div>

                                    {/* Date Pickers (Start & End Date Side by Side) */}
                                    <div className="flex items-center w-full">
                                        <label className={`${mode === "light" ? "text-gray-600":"text-white"} font-medium w-[20%] text-[14px]`}>
                                            Date <span className="text-red-500">*</span>
                                        </label>
                                        <div className="flex gap-4 w-[80%]">
                                            <TextField
                                                size="small"
                                                type="date"
                                                fullWidth
                                                variant="outlined"
                                                value={startDate}
                                                onChange={(e) => setStartDate(e.target.value)}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                            <TextField
                                                size="small"
                                                type="date"
                                                fullWidth
                                                variant="outlined"
                                                value={endDate}
                                                onChange={(e) => setEndDate(e.target.value)}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </div>
                                    </div>

                                    {/* Team Email ID */}
                                    <div className="flex items-center w-full">
                                        <label className={`${mode === "light" ? "text-gray-600":"text-white"} text-[14px] font-medium w-[20%]`}>
                                            Team Email ID
                                        </label>
                                        <div className="w-[80%]">
                                            <TextField
                                                size="small"
                                                fullWidth
                                                variant="outlined"
                                                value={teamEmail}
                                                onChange={(e) => setTeamEmail(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    {/* Reason for Leave */}
                                    <div className="flex items-start w-full">
                                        <label className={`${mode === "light" ? "text-gray-600":"text-white"} font-medium w-[20%] text-[14px]`}>
                                            Reason for Leave
                                        </label>
                                        <div className="w-[80%]">
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                multiline
                                                rows={3}
                                                value={reason}
                                                onChange={(e) => setReason(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </form>

                            </div>
                            <div style={{
                                backgroundColor: themeMode === "dark" ? "#141414" : "#ffffff"
                            }} className="absolute bottom-0 gap-2 flex w-full p-3 h-fit">
                                <Button variant="outlined" onClick={() => setOpenApply(false)}>
                                    Cancel
                                </Button>
                                <Button variant="contained" color="primary">
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </Box>
                </Box>
            </Modal>

        </Box>
    </>)
}