import React, { useState } from "react";
import { Tab, Tabs } from "@mui/material";

import TravelRequest from "./TravelRequest";
import TravelExpense from "./TravelExpense";
import CustomInputTable from "../../../components/CustomInputTable";
import { useSwitchScreen } from "../../../hooks/SwitchScreenContext";

export default function Travel() {
   const { switchScreen } = useSwitchScreen(); 
 

  return (
    <div className="w-full flex flex-col gap-3 px-3 pb-3 rounded-lg border border-[#1e2734]">
    
      {switchScreen.primary === "travelrequest" ? (
        <>
          <TravelRequest />
        </>
      ) : (
        <></>
      )}
      {switchScreen.primary === "travelexpense" ? (
        <>
          <TravelExpense />
        </>
      ) : (
        <></>
      )}
    </div>
  );
}
