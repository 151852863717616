import React, { useState } from "react";
import { Tab, Tabs } from "@mui/material";

import ImportLog from "./ImportLog";
import ExportLog from "./ExportLog";
import ActivityLog from "./ActivityLog";
import RecycleBin from "./RecycleBin";
import { useSwitchScreen } from "../../../hooks/SwitchScreenContext";

export default function DataAdministration() {
   const { switchScreen } = useSwitchScreen(); 

  return (
    <div className="w-full flex flex-col gap-3 px-3 pb-3 rounded-lg border border-[#1e2734]">
      {switchScreen.primary === "importLog" ? (
        <>
          <ImportLog />
        </>
      ) : (
        <></>
      )}
      {switchScreen.primary === "exportLog" ? (
        <>
          <ExportLog />
        </>
      ) : (
        <></>
      )}
      {switchScreen.primary === "activityLog" ? (
        <>
          <ActivityLog />
        </>
      ) : (
        <></>
      )}
      {switchScreen.primary === "recycleBin" ? (
        <>
          <RecycleBin />
        </>
      ) : (
        <></>
      )}
    </div>
  );
}
