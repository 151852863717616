import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Grid from "@mui/material/Grid2";

export default function EmployeeAuditHistory() {
  return (
    <Grid container spacing={2} sx={{ width: "100%" }}>
      <Grid
        size={{ xs: 12, md: 10 }}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          border: "1px solid",
          borderRadius: "8px",
          p: 3,
          textAlign: "center",
          height: "100%",
          minHeight: "200px",
        }}
      >
        <img src="/images/no_timeLogs.png" />
        <h1 className="mt-4">History details not available for this Record</h1>
      </Grid>
      <Grid size={{ xs: 12, md: 2 }}>
        <div className="shadow-md rounded-md p-4 min-height-[200px] h-full ">
        <div className="relative pl-8">
        {/* Dot */}
        <div className="absolute left-0 w-4 h-4 rounded-full bg-gray-300 border-4 border-white"></div>

        {/* Vertical line */}
        <div className="absolute left-2 top-4 w-px h-full bg-gray-200"></div>

        {/* Content */}
        <div className="font-medium text-gray-800">History</div>

        {/* This div provides height to show the line extending down */}
        <div className="h-40"></div>
      </div>
        </div>
      </Grid>
    </Grid>
  );
}
