import React, { useState } from "react";
import { Tab, Tabs } from "@mui/material";
import UserSpecificOperations from "./UserSpecificOperations";
import ManageShifts from "./ManageShifts";
import EmployeeShiftMapping from "./EmployeeShiftMapping";
import { useSwitchScreen } from "../../../hooks/SwitchScreenContext";
import OverTime from "../../Projects/OverTimeCalender";

export default function Shift() {
   const { switchScreen } = useSwitchScreen();

  return (
    <div className="w-full flex flex-col gap-3 px-3 pb-3 rounded-lg border border-[#1e2734]">
      {switchScreen.primary === "userSpecificOperations" ? (
        <>
          <UserSpecificOperations />
        </>
      ) : (
        <></>
      )}
      {switchScreen.primary === "manageShifts" ? (
        <>
          <ManageShifts />
        </>
      ) : (
        <></>
      )}
      {switchScreen.primary === "employeeShiftMapping" ? (
        <>
          <EmployeeShiftMapping />
        </>
      ) : (
        <></>
      )}
      {switchScreen.primary === "overviewCalendar" ? (
        <>
          <OverTime />
        </>
      ) : (
        <></>
      )}
    </div>
  );
}
