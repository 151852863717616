import React, { useState } from "react";
import { Tab, Tabs } from "@mui/material";
import UserSpecificOperations from "./UserSpecificOperations";
import BiometricIDMapping from "./BiometricIDMapping";
import CheckInAndOutImportAndExport from "./CheckInAndOutImportAndExport";
import { useSwitchScreen } from "../../../hooks/SwitchScreenContext";
import RecentAttendance from "../../DashComponents/attend";
import AttendViewHome from "../../Attendance/AttendView/AttendViewHome";

export default function Attendance() {
    const { switchScreen } = useSwitchScreen(); 
 
  return (
    <div className="w-full h-full flex flex-col gap-3 px-3 rounded-lg border border-[#1e2734] overflow-hidden">
     
      {switchScreen.primary === "userSpecificOperations" ? (
        <>
          <UserSpecificOperations />
        </>
      ) : (
        <></>
      )}
      {switchScreen.primary === "biometricIDmapping" ? (
        <>
          <BiometricIDMapping />
        </>
      ) : (
        <></>
      )}
      {switchScreen.primary === "checkInAndOutImportAndExport" ? (
        <>
          <CheckInAndOutImportAndExport />
        </>
      ) : (
        <></>
      )}
      {switchScreen.primary === "recentAttendence" ? (
        <>
          <RecentAttendance />
        </>
      ) : (
        <></>
      )}

      {switchScreen.primary === "attendanceview" ? (
        <>
          <AttendViewHome />
        </>
      ) : (
        <></>
      )}
      
    </div>
  );
}
