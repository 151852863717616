import React, { useState } from "react";
import CustomFilter from "../../../components/CustomFilter";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { CheckCircle, Cancel, Search } from "@mui/icons-material";
import { IoFilter } from "react-icons/io5";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EmployeeDepartmentDrawer from "../../../components/EmployeeDepartmentDrawer";
import HrProcessDrawer from "../../../components/HrProcessDrawer";

const EmployeeHrProcess = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isStatusDrawer, setIsStatusDrawer] = useState(false);
  const [openHrDrawer, setOpenHrDrawer] = useState(false);

  const openFilterModal = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeFilterModal = () => {
    setAnchorEl(null);
  };

  const handleFilterFormSubmit = () => {
    alert("form submit");
  };

  const columns = [
    "Status",
    "Employee",
    "Key Field",
    "Value",
    "Effective Date",
    "Process",
    "Reason",
  ];

  const data = [
    {
      status: false,
      employee: "John",
      keyfield: "Department",
      Value: "Marketing",
      effectiveDate: "21-Feb-2015",
      Process: "Department Change",
      reason: "ssa",
    },
    {
      status: true,
      employee: "Alice",
      keyfield: "Role",
      Value: "Manager",
      effectiveDate: "10-Jan-2020",
      Process: "Location Change",
      reason: "Performance",
    },
    {
      status: false,
      employee: "Annie",
      keyfield: "Role",
      Value: "Manager",
      effectiveDate: "10-Jan-2020",
      Process: "Designation Change",
      reason: "Performance",
    },
  ];

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <div className="w-full flex gap-3 flex-col justify-center items-center">
      <div className="p-2 w-full flex justify-between items-center">
        <div className="flex">
          <p>Process</p>
          <FormControl sx={{ width: "250px", ml: 2 }}>
            <Select labelId="table-view" id="table-view" size="small">
              <MenuItem value={"one"}>One</MenuItem>
              <MenuItem value={"two"}>Two</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="flex flex-row gap-3 mt-3 ">
          <button
            className={`px-3 py-1.5 bg-[#0088FF] text-sm rounded-md text-white `}
            onClick={() => setOpenHrDrawer(true)}
          >
            Initiate Process
          </button>
          <IconButton onClick={openFilterModal}>
            <IoFilter />
          </IconButton>
        </div>
      </div>

      {/* Table */}
      <div className="w-full">
        <TableContainer
          style={{ overflowX: "auto", width: "100%" }}
          className="text-nowrap"
          component={Paper}
        >
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell key={index}>{column}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <StyledTableRow key={index}>
                  {/* Status with Icon */}
                  <StyledTableCell
                    sx={{ cursor: "pointer" }}
                    onClick={() => setIsStatusDrawer(true)}
                  >
                    {row.status ? (
                      <CheckCircle sx={{ color: "lightgreen" }} />
                    ) : (
                      <Cancel sx={{ color: "#f75d59" }} />
                    )}
                  </StyledTableCell>

                  <StyledTableCell>{row.employee}</StyledTableCell>
                  <StyledTableCell>{row.keyfield}</StyledTableCell>
                  <StyledTableCell>{row.Value}</StyledTableCell>
                  <StyledTableCell>{row.effectiveDate}</StyledTableCell>

                  {/* Process as Button */}
                  <StyledTableCell>
                    <button
                      className={`px-3 py-1.5 text-sm rounded-md text-white `}
                      onClick={() => setIsStatusDrawer(true)}
                      style={{ backgroundColor: "#64d5fd33", color: "#64d5fd" }}
                    >
                      {row.Process}
                    </button>
                  </StyledTableCell>

                  <StyledTableCell>{row.reason}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <EmployeeDepartmentDrawer
        openDrawer={isStatusDrawer}
        onClose={() => setIsStatusDrawer(false)}
      />

      <HrProcessDrawer
        openDrawer={openHrDrawer}
        onClose={() => setOpenHrDrawer(false)}
      />
      <CustomFilter
        anchorEl={anchorEl}
        onClose={closeFilterModal}
        content={
          <>
            <div>
              <TextField
                sx={{ width: "100%" }}
                name="employee"
                size="small"
                placeholder="Field Search"
                variant="outlined"
                value={""}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search fontSize="small" />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div>
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography component="span">System Filters</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    <p>Approval Status</p>
                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        labelId="employeeStatus"
                        id="employeeStatus"
                        size="small"
                      >
                        <MenuItem value={"allEmployees"}>All request</MenuItem>
                        <MenuItem value={"allActiveEmployee"}>
                          All Active Employees
                        </MenuItem>
                        <MenuItem value={"exEmployee"}>Ex-Employees</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          </>
        }
      />
    </div>
  );
};

export default EmployeeHrProcess;
