import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  styled,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  tableCellClasses,
  Paper,
  InputAdornment,
  TextField,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@mui/material";
import { IoFilter } from "react-icons/io5";
import {
  ArrowBackIos,
  ArrowForwardIos,
  Cancel,
  CheckCircle,
  Search,
} from "@mui/icons-material";
import CustomFilter from "../../../components/CustomFilter";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EmployeeDepartmentDrawer from "../../../components/EmployeeDepartmentDrawer";
import HrProcessDrawer from "../../../components/HrProcessDrawer";

export default function HRProcess() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isStatusDrawer, setIsStatusDrawer] = useState(false);
  const [openHrDrawer, setOpenHrDrawer] = useState(false);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 5 });
  const [totalRows, setTotalRows] = useState(0);
  const [sorting, setSorting] = useState([{ id: "id", desc: false }]);

  const openFilterModal = (event) => setAnchorEl(event.currentTarget);
  const closeFilterModal = () => setAnchorEl(null);

  const fetchData = async (pageIndex, pageSize, sorting) => {
    const sortField = sorting[0]?.id || "id";
    const sortDirection = sorting[0]?.desc ? "desc" : "asc";

    const response = await axios.get(`https://reqres.in/api/users`, {
      params: {
        page: pageIndex + 1,
        per_page: pageSize,
        sort: sortField,
        direction: sortDirection,
      },
    });

    const fetchedData = response.data.data.map((user) => ({
      status: user.id % 2 === 0, // Example logic for status
      employee: user.first_name + " " + user.last_name,
      keyField: "Sample Key", // Placeholder
      value: "Sample Value", // Placeholder
      effectiveDate: new Date().toISOString().split("T")[0],
      process: "View", // Placeholder
      reason: "Example reason", // Placeholder
    }));

    setData(response.data.data);
    setTotalRows(response.data.total);
  };

  useEffect(() => {
    fetchData(pagination.pageIndex, pagination.pageSize, sorting);
  }, [pagination.pageIndex, pagination.pageSize, sorting]);

  const totalPages = Math.ceil(totalRows / pagination.pageSize);

  return (
    <div className="w-full flex gap-3 flex-col justify-center items-center">
      <div className="p-2 w-full flex justify-between items-center">
        <div className="flex">
          <p>Process</p>
          <FormControl sx={{ width: "250px", ml: 2 }}>
            <Select labelId="table-view" id="table-view" size="small">
              <MenuItem value={"one"}>One</MenuItem>
              <MenuItem value={"two"}>Two</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="flex flex-row gap-3 mt-3 ">
          <button
            className="px-3 py-1.5 bg-[#0088FF] text-sm rounded-md text-white "
            onClick={() => setOpenHrDrawer(true)}
          >
            Initiate Process
          </button>
          <IconButton onClick={openFilterModal}>
            <IoFilter />
          </IconButton>
        </div>
      </div>

      {/* Table */}
      <div className="w-full">
        <TableContainer
          style={{ overflowX: "auto", width: "100%" }}
          className="text-nowrap"
          component={Paper}
        >
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#E5E5F0" }}>
                <TableCell>Status</TableCell>
                <TableCell>Employee</TableCell>
                <TableCell>Key Field</TableCell>
                <TableCell>Value</TableCell>
                <TableCell>Effective Date</TableCell>
                <TableCell>Process</TableCell>
                <TableCell>Reason</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell
                    sx={{ cursor: "pointer" }}
                    onClick={() => setIsStatusDrawer(true)}
                  >
                    {row.status ? (
                      <CheckCircle sx={{ color: "lightgreen" }} />
                    ) : (
                      <Cancel sx={{ color: "#f75d59" }} />
                    )}
                  </TableCell>
                  <TableCell>{row.first_name + " " + row.last_name}</TableCell>
                  <TableCell>{row.keyField || "N/A"}</TableCell>
                  <TableCell>{row.value || "N/A"}</TableCell>
                  <TableCell>{row.effectiveDate || "N/A"}</TableCell>
                  <TableCell>
                    <button
                      onClick={() => setIsStatusDrawer(true)}
                      className="px-3 py-1.5 text-sm rounded-md text-white "
                      style={{ backgroundColor: "#64d5fd33", color: "#64d5fd" }}
                    >
                      {row.process || "N/A"}
                    </button>
                  </TableCell>
                  <TableCell>{row.reason || "N/A"}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Pagination Component */}
        <div className="flex items-center justify-between p-4 border-t">
          <Typography variant="body1">
            Total Record Count:{" "}
            <span className="text-blue-500">{totalRows}</span>
          </Typography>

          <div className="flex items-center gap-2">
            <Select
              value={pagination.pageSize}
              onChange={(e) =>
                setPagination((prev) => ({ ...prev, pageSize: e.target.value }))
              }
              size="small"
            >
              {[5, 10, 25, 50].map((size) => (
                <MenuItem key={size} value={size}>
                  {size}
                </MenuItem>
              ))}
            </Select>

            <IconButton
              onClick={() =>
                setPagination((prev) => ({
                  ...prev,
                  pageIndex: prev.pageIndex - 1,
                }))
              }
              disabled={pagination.pageIndex === 0}
            >
              <ArrowBackIos fontSize="small" />
            </IconButton>

            <Typography variant="body1">
              {pagination.pageIndex + 1}-{totalPages}
            </Typography>

            <IconButton
              onClick={() =>
                setPagination((prev) => ({
                  ...prev,
                  pageIndex: prev.pageIndex + 1,
                }))
              }
              disabled={pagination.pageIndex >= totalPages - 1}
            >
              <ArrowForwardIos fontSize="small" />
            </IconButton>
          </div>
        </div>
      </div>

      <EmployeeDepartmentDrawer
        openDrawer={isStatusDrawer}
        onClose={() => setIsStatusDrawer(false)}
      />

      <HrProcessDrawer
        openDrawer={openHrDrawer}
        onClose={() => setOpenHrDrawer(false)}
      />

      <CustomFilter
        anchorEl={anchorEl}
        onClose={closeFilterModal}
        content={
          <>
            <TextField
              sx={{ width: "100%" }}
              name="employee"
              size="small"
              placeholder="Field Search"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search fontSize="small" />
                  </InputAdornment>
                ),
              }}
            />
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography component="span">System Filters</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div>                  
                <p>Approval Status</p>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    labelId="employeeStatus"
                    id="employeeStatus"
                    size="small"
                  >
                    <MenuItem value={"allEmployees"}>All Requests</MenuItem>
                    <MenuItem value={"Approved"}>
                     Approved
                    </MenuItem>
                    <MenuItem value={"Pending"}>Pending</MenuItem>
                    <MenuItem value={"Rejected"}>Rejected</MenuItem>
                  </Select>
                </FormControl>
                </div>
                <div>                  
                <p>Employee</p>
                <TextField
              sx={{ width: "100%" }}
              name="employee"
              size="small"
              placeholder="Employee"
              variant="outlined"
            />
                </div>
                <div>                  
                <p>Employee Status</p>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    labelId="employeeStatus"
                    id="employeeStatus"
                    size="small"
                  >
                    <MenuItem value={"allEmployees"}>All Requests</MenuItem>
                    <MenuItem value={"allActiveEmployee"}>
                      All Active Employees
                    </MenuItem>
                    <MenuItem value={"exEmployee"}>Ex-Employees</MenuItem>
                  </Select>
                </FormControl>
                </div>
              </AccordionDetails>
            </Accordion>
          </>
        }
      />
    </div>
  );
}
