import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "@mui/material";
import Employees from "./Employees";
import Departments from "./Departments";
import Designations from "./Designations";
import HRProcess from "./HRProcess";
import Groups from "./Groups";
import Delegation from "./Delegation";
import UserSpecificOperations from "./UserSpecificOperations";
import { useSwitchScreen } from "../../../hooks/SwitchScreenContext";
import { useLocation } from "react-router-dom";
import Insights from "./Insights";

export default function EmployeeInformation() {
  const { switchScreen, setSwitchScreen } = useSwitchScreen();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get("tab");

    if (tab && switchScreen.primary !== tab) {
      setSwitchScreen((prev) => ({ ...prev, primary: tab }));
      console.log("Updated switchScreen.primary to:", switchScreen);
    }
  }, [
    location.search,
    setSwitchScreen,
  ]);
  
  return (
    <div className="w-full flex flex-col gap-3 pb-3 px-3 rounded-lg h-full mt-5">
      {switchScreen.primary === "userSpecificOperations" ? (
        <>
          <UserSpecificOperations />
        </>
      ) : (
        <></>
      )}
      {switchScreen.primary === "insights" ? (
        <>
          <Insights />
          {/* <div className="w-full h-[42.025rem] py-2 flex justify-center items-center ">
            No Insights Founded
          </div> */}
        </>
      ) : (
        <></>
      )}
      {switchScreen.primary === "employees" ? (
        <>
          <Employees />
        </>
      ) : (
        <></>
      )}
      {switchScreen.primary === "departments" ? (
        <>
          <Departments />
        </>
      ) : (
        <></>
      )}
      {switchScreen.primary === "designations" ? (
        <>
          <Designations />
        </>
      ) : (
        <></>
      )}
      {switchScreen.primary === "hrProcess" ? (
        <>
          <HRProcess />
        </>
      ) : (
        <></>
      )}
      {switchScreen.primary === "groups" ? (
        <>
          <Groups />
        </>
      ) : (
        <></>
      )}
      {switchScreen.primary === "delegation" ? (
        <>
          <Delegation />
        </>
      ) : (
        <></>
      )}
    </div>
  );
}
