import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { useTheme } from "../../../../style/theme";
import { FiGrid } from "react-icons/fi";
import { TfiViewList } from "react-icons/tfi";
import AllReporteesGrid from "./AllReporteesGrid";
import DirectReporteesGrid from "./DirectReporteesGrid";
import DirectReporteesTable from "./DirectReporteesTable";
import AllReporteesTable from "./AllReporteeTable";
import { CiSearch } from "react-icons/ci";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";

export default function LeaveTrackerReportees() {
  const [view, setView] = useState({ first: "direct", second: "" });
  const [screenView, setScreenView] = useState("grid");
  const { mode } = useTheme();
  const [isExpanded, setIsExpanded] = useState(false);
  const searchRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsExpanded(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleToggle = (newView) => {
    setView({ ...view, first: newView });
  };

  return (
    <div className="h-[100vh]">
      <div className="h-full flex gap-3 flex-col p-2 md:p-3 ">
        <div className="w-full flex flex-row gap-3 justify-center sm:justify-between items-center">
          {isExpanded && (
            <div
              ref={searchRef}
              className="relative transition-all w-full duration-300"
            >
              <TextField
              sx={{ backgroundColor: mode === "light" ? "#fff" :"" }}
                fullWidth
                placeholder="Enter 3 or more characters to search"
                variant="outlined"
                autoFocus
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="transition-all duration-300"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton sx={{ backgroundColor: "transparent" }}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        sx={{ backgroundColor: "transparent" }}
                        onClick={() => setIsExpanded(false)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          )}

          {!isExpanded && (
            <div className="flex gap-3 flex-col md:flex-row justify-between w-full ">
              <div className="flex items-center gap-2">
                <img
                  className="w-10 h-10 rounded-md"
                  src="/images/no-user.png"
                  alt=""
                />
                <p className="text-[14px]">1 - tanya gwilsbry</p>
              </div>
              <div className="flex gap-2 items-center">
                <div>
                  <Box className="flex">
                    <button
                      onClick={() => handleToggle("direct")}
                      variant={
                        view.first === "direct" ? "contained" : "outlined"
                      }
                      className={`px-4 py-1 rounded-sm text-[14px] ${
                        view.first === "direct"
                          ? mode === "light"
                            ? "bg-white border border-blue-500 text-black"
                            : "bg-[#3767b1]"
                          : "bg-gray-200 text-black"
                      }`}
                    >
                      Direct 5
                    </button>
                    <button
                      onClick={() => handleToggle("all")}
                      // variant={view.first === "all" ? "contained" : "outlined"}
                      className={`px-4 py-1 rounded-sm text-[14px] ${
                        view.first === "all"
                          ? mode === "light"
                            ? "bg-white border border-blue-500 text-black"
                            : "bg-[#3767b1]"
                          : "bg-gray-200 text-black"
                      }`}
                    >
                      All 20
                    </button>
                  </Box>
                </div>
                <div
                  className={`rounded-md flex items-center gap-1  ${
                    mode === "light" ? "bg-[#fff]" : "#171717"
                  }`}
                >
                  <div
                    className="relative group p-1 rounded-md"
                    style={{
                      border: screenView === "grid" ? "1px solid #0088ff" : "",
                    }}
                  >
                    <FiGrid
                      size={20}
                      className="cursor-pointer"
                      onClick={() => setScreenView("grid")}
                      color={screenView === "grid" ? "#0088ff" : ""}
                    />
                    <span className="absolute top-10 left-1/2 w-[max-content] -translate-x-1/2 text-xs bg-gray-800 text-white px-2 py-1 z-40 rounded opacity-0 group-hover:opacity-100 transition">
                      Grid view
                    </span>
                  </div>
                  <div
                    className="relative group p-1 rounded-md"
                    style={{
                      border: screenView === "list" ? "1px solid #0088ff" : "",
                    }}
                  >
                    <TfiViewList
                      size={20}
                      className="cursor-pointer"
                      onClick={() => setScreenView("list")}
                      color={screenView === "list" ? "#0088ff" : ""}
                    />
                    <span className="absolute top-10 left-1/2 -translate-x-1/2 w-[max-content] text-xs bg-gray-800 text-white px-2 py-1 rounded z-40 opacity-0 group-hover:opacity-100 transition">
                      Table view
                    </span>
                  </div>
                  <button
                    style={{
                      backgroundColor: mode === "light" ? "white" : "#181818",
                    }}
                    className="hover:outline hover:outline-blue-500 hover:outline-[1px]  p-1 hover:text-blue-500 rounded-sm"
                    onClick={() => setIsExpanded(true)}
                  >
                    <CiSearch size={20} />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        {view.first === "all" && screenView === "grid" ? (
          <div className="w-full">
            <AllReporteesGrid searchTerm={searchTerm} />
          </div>
        ) : (
          <></>
        )}
        {view.first === "all" && screenView === "list" ? (
          <>
            <AllReporteesTable searchTerm={searchTerm} />
          </>
        ) : (
          <></>
        )}

        {view.first === "direct" && screenView === "grid" ? (
          <>
            <DirectReporteesGrid searchTerm={searchTerm} />
          </>
        ) : (
          <></>
        )}
        {view.first === "direct" && screenView === "list" ? (
          <>
            <DirectReporteesTable searchTerm={searchTerm} />
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
