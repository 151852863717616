import React, { useRef, useState } from "react";
import { Button, IconButton, Menu, MenuItem } from "@mui/material";
import { IoFilter } from "react-icons/io5";
import { MdFormatListBulleted } from "react-icons/md";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import CustomModal from "../../../../components/CustomModal";
import DayView from "./DayView";
import WeekView from "./WeekView";
import dayjs from "dayjs";
import { CiExport, CiFolderOn, CiImport } from "react-icons/ci";
import { PiPrinterThin } from "react-icons/pi";
import { PiFilePdfThin } from "react-icons/pi";
import { HiDotsHorizontal } from "react-icons/hi";
import DateButton from "../../../../components/DateButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { IoClose } from "react-icons/io5";
import { MdCheckCircle } from "react-icons/md";
import { useTheme } from "../../../../style/theme";

const ConfirmModal = ({ open, setOpen }) => {
  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
      {/* Close Button */}
      <IconButton
        onClick={() => setOpen(false)}
        sx={{ position: "absolute", top: 8, right: 8, color: "gray" }}
      >
        <IoClose size={20} />
      </IconButton>

      {/* Modal Content */}
      <DialogTitle className="flex flex-col items-center gap-1">
        <MdCheckCircle size={50} color="#45cb8c" />
        <span className="font-[500] text-[20px]">Confirm</span>
      </DialogTitle>

      <DialogContent className="text-center">
        Are you sure, you want to clone all the filtered job schedules of
        previous day?
      </DialogContent>

      <div className="flex justify-center gap-4 pb-4">
        <Button variant="contained" sx={{ backgroundColor: "#45cb8c" }}>
          Confirm
        </Button>
        <Button
          variant="outlined"
          sx={{ border: "1px solid #ebebeb", color: "#222222" }}
          onClick={() => setOpen(false)}
        >
          Cancel
        </Button>
      </div>
    </Dialog>
  );
};

export default function JobSchedule() {
  const [open, setOpen] = useState(false);

  const [switchScreen, setSwitchScreen] = useState({
    primary: "day",
  });
  const [dateRange, setDateRange] = useState({ start: "", end: "" });

  const handleDateChange = ({ start, end }) => {
    setDateRange({ start, end });
  };
  const addProjectModalFields = [
    {
      type: "text",
      name: "projectName",
      label: "Project Name",
      defaultValue: "",
    },
    {
      type: "autocomplete",
      name: "clientName",
      label: "Client Name",
      options: ["Client 1", "Client 2"],
      defaultValue: "",
    },
    {
      type: "text",
      name: "projectCost",
      label: "Project Cost",
      defaultValue: "",
    },
    {
      type: "autocomplete",
      name: "projectHead",
      label: "Project Head",
      options: ["Project Head 1", "Project Head 2"],
      defaultValue: "",
    },
    {
      type: "text",
      name: "ratePerHour",
      label: "Rate Per Hour",
      defaultValue: "",
    },
    {
      type: "autocomplete",
      name: "projectManager",
      label: "Project Manager",
      options: ["Project Manager 1", "Project Manager 2"],
      defaultValue: "",
    },
    {
      type: "text",
      name: "description",
      label: "Description",
      defaultValue: "",
    },
  ];
  const handleAddProjectFormSubmit = (data) => {
    console.log("Add Form :- ", data);
  };
  const [filterModal, setFilterModal] = useState(false);
  const [addProjectModal, setAddProjectModal] = useState(false);

  const [menuAnchor, setMenuAnchor] = useState(null);
  const isMenuopen = Boolean(menuAnchor);

  const [filterFormValues, setFilterFormValues] = useState({
    fileName: "",
    dateFrom: null,
    dateTo: null,
  });

  const handleFilterFormChange = (name, value) => {
    setFilterFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleFilterFormSubmit = (e) => {
    e.preventDefault();
    console.log("Form Values: ", filterFormValues);
    setFilterModal(false);
  };

  const filterModalFields = [
    {
      type: "multipleSelect",
      name: "employee",
      label: "Employee",
      options: ["1", "2", "3"],
      defaultValue: "",
    },
    {
      type: "multipleSelect",
      name: "clients",
      label: "Clients",
      options: ["1", "2", "3"],
      defaultValue: "",
    },
    {
      type: "multipleSelect",
      name: "projects",
      label: "Projects",
      options: ["1", "2", "3"],
      defaultValue: "",
    },
    {
      type: "multipleSelect",
      name: "jobs",
      label: "Jobs",
      options: ["1", "2", "3"],
      defaultValue: "",
    },
    {
      type: "multipleSelect",
      name: "publishStatus",
      label: "Publish Status",
      options: ["1", "2", "3"],
      defaultValue: "",
    },
    {
      type: "multipleSelect",
      name: "Department",
      label: "Department",
      options: ["Department 1", "Department 2", "Department 3"],
      defaultValue: "",
    },
    {
      type: "multipleSelect",
      name: "Designation",
      label: "Designation",
      options: ["Designation 1", "Designation 2", "Designation 3"],
      defaultValue: "",
    },
  ];

  const {mode} = useTheme()
  const pendingChanges = 0;
  return (
    <div className="w-full h-fit flex flex-col gap-3 ">
      <div className="flex flex-col sm:flex-row gap-3 justify-between sm:items-center mt-[40px]">
       

        <div className="w-full flex justify-center items-center">
          {switchScreen.primary === "day" ? (
            <>
              <div className="w-full flex gap-3 justify-center items-center">
                <DateButton mode="day" onDateChange={handleDateChange} />
              </div>
            </>
          ) : (
            <></>
          )}{" "}
          {switchScreen.primary === "week" ? (
            <>
              <div className="w-full flex gap-3 justify-center items-center">
                <DateButton mode="week" onDateChange={handleDateChange} />
              </div>
            </>
          ) : (
            <></>
          )}
        </div>

        <div className="flex flex-col ps-3 sm:ps-0 sm:flex-row gap-2 sm:justify-center sm:items-center text-nowrap">
          <div className="flex">
            <Button
              title="Day"
              variant={
                switchScreen.primary === "day" ? "contained" : "outlined"
              }
              onClick={() => {
                setSwitchScreen({ primary: "day" });
              }}
              sx={{ fontSize: { sm: "14px", xs: "12px" } }}
            >
              <div className="text-1xl">Day</div>
            </Button>
            <Button
              title="Week"
              variant={
                switchScreen.primary === "week" ? "contained" : "outlined"
              }
              onClick={() => {
                setSwitchScreen({ primary: "week" });
              }}
              sx={{ fontSize: { sm: "14px", xs: "12px" } }}
            >
              <div className="text-1xl">Week</div>
            </Button>
          </div>

          <Button
            title="Week"
            variant="outlined"
            sx={{
              fontSize: { sm: "14px", xs: "12px" },
              border: "1px solid #0088ff",
            }}
            onClick={() => setOpen(true)}
          >
            <div className="text-1xl">Clone</div>
          </Button>

          <div className="flex items-center gap-2">
            {/* Pending Changes */}
            <span className={`text-[14px] rounded ${mode === "light"? "bg-[#e5e5f0]":"transparent border border-blue-500"}  px-4 py-2`}>
              <span
                className={`font-[500] text-[14px] ${
                  pendingChanges > 0 ? "text-gray-500" : "text-red-500"
                }`}
              >
                {pendingChanges}
              </span>{" "}
              pending changes
            </span>

            {/* Custom Published Button */}
            <button className="bg-[#76bcfa] font-[500] text-[14px] text-[#e1edfa] px-4 py-2 rounded">
              Published
            </button>
          </div>

          <div className="flex gap-2">
            <Button
              variant="contained"
              onClick={() => {
                setAddProjectModal(true);
              }}
              sx={{ fontSize: { sm: "14px", xs: "12px" } ,height:"38px" }}
            >
              Add Project
            </Button>

            <IconButton
              onClick={() => {
                setFilterModal(true);
              }}
            >
              <IoFilter />
            </IconButton>
            <div>
              <IconButton
                id="basic-button"
                aria-controls={isMenuopen ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={isMenuopen ? "true" : undefined}
                onClick={(event) => {
                  setMenuAnchor(event.currentTarget);
                }}
              >
                <HiDotsHorizontal className="text-2xl" />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={menuAnchor}
                open={isMenuopen}
                onClose={() => {
                  setMenuAnchor(null);
                }}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem>
                  <div className="flex flex-row gap-3 justify-between items-center">
                    <CiImport className="text-2xl" />
                    <h1>Import</h1>
                  </div>
                </MenuItem>
                <MenuItem>
                  <div className="flex flex-row gap-3 justify-between items-center">
                    <CiExport className="text-2xl" />
                    <h1>Export</h1>
                  </div>
                </MenuItem>
                <MenuItem>
                  <div className="flex flex-row gap-3 justify-between items-center">
                    <PiFilePdfThin className="text-2xl" />
                    <h1>Download as PDF</h1>
                  </div>
                </MenuItem>
                <MenuItem>
                  <div className="flex flex-row gap-3 justify-between items-center">
                    <PiPrinterThin className="text-2xl" />
                    <h1>Print</h1>
                  </div>
                </MenuItem>
              </Menu>
            </div>
          </div>
        </div>
      </div>

      {switchScreen.primary === "day" ? (
        <>
          <DayView />
        </>
      ) : (
        <></>
      )}
      {switchScreen.primary === "week" ? (
        <>
          <WeekView />
        </>
      ) : (
        <></>
      )}
      <div>
        <CustomModal
          title="Filter"
          submitLabel="Apply"
          fields={filterModalFields}
          open={filterModal}
          onClose={() => {
            setFilterModal(false);
          }}
          isScrollable={true}
          onSubmit={handleFilterFormSubmit}
        />
      </div>
      <div>
        <CustomModal
          title="Add Project"
          fields={addProjectModalFields}
          open={addProjectModal}
          onClose={() => {
            setAddProjectModal(false);
          }}
          onSubmit={handleAddProjectFormSubmit}
          isScrollable={true}
          isCustomSubmitButtom={true}
          customSubmitButton={
            <div className="w-full flex flex-row justify-between items-center">
              <Button variant="contained" type="submit">
                Submit
              </Button>
              <Button variant="contained" type="submit">
                Save Draft
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  setAddProjectModal(false);
                }}
              >
                Cancel
              </Button>
            </div>
          }
        />
      </div>

      <ConfirmModal open={open} setOpen={setOpen} />
    </div>
  );
}
