import React, { useState } from "react";
import { Tab, Tabs } from "@mui/material";

import Task from "./Task";
import Checklist from "./Checklist";
import { useSwitchScreen } from "../../../hooks/SwitchScreenContext";

export default function Tasks() {
    const { switchScreen } = useSwitchScreen(); 

  return (
    <div className="w-full flex flex-col gap-3 px-3 pb-3 rounded-lg border border-[#1e2734]">
      {switchScreen.primary === "task" ? (
        <>
          <Task />
        </>
      ) : (
        <></>
      )}
      {switchScreen.primary === "checklist" ? (
        <>
          <Checklist />
        </>
      ) : (
        <></>
      )}
    </div>
  );
}
