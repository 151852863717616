import { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  MenuItem,
  IconButton,
} from "@mui/material";
import { IoClose } from "react-icons/io5";
import { RiDeleteBinLine } from "react-icons/ri";
import { FiPlusSquare } from "react-icons/fi";
import { useTheme } from "../style/theme";

const EditProfileModal = ({ editModalOpen, closeEditModal }) => {
  const [rows, setRows] = useState([
    {
      id: 1,
      institute: "Caleb University",
      degree: "Bachelors of Science",
      startDate: "2022-06-12",
      endDate: "2024-03-19",
      specialization: "Computer Science",
    },
  ]);

   const [workExperienceRows, setWorkExperienceRows] = useState([
    {
      company: "TechExports",
      jobTitle: "Software Engineer",
      startDate: "2022-06-12",
      endDate: "2024-03-19",
      jobDescription: "Enter Job Description",
    },
  ]);


   // ✅ **Add New Work Experience Row**
   const addWorkExperienceRow = () => {
    setWorkExperienceRows([
      ...workExperienceRows,
      {
        company: "",
        jobTitle: "",
        startDate: "",
        endDate: "",
        jobDescription: "",
      },
    ]);
  };

   // ✅ **Delete Work Experience Row**
   const removeWorkExperienceRow = (index) => {
    const updatedRows = [...workExperienceRows];
    updatedRows.splice(index, 1);
    setWorkExperienceRows(updatedRows);
  };


  // ✅ **Add New Row Function**
  const addRow = () => {
    setRows([
      ...rows,
      {
        id: rows.length + 1,
        institute: "",
        degree: "",
        startDate: "",
        endDate: "",
        specialization: "",
      },
    ]);
  };

  // ✅ **Delete Row Function**
  const deleteRow = (id) => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const{mode} = useTheme()

  return (
    <Dialog
      open={editModalOpen}
      onClose={closeEditModal}
      fullWidth
      maxWidth="lg"
    >
      {/* Modal Background with Theme */}
      <div className={`${mode === " light" ? "bg-[#141414]":""}  p-6`} >
        {/* Header */}
        <div className="flex justify-between items-center pb-4">
          <DialogTitle className="text-xl font-semibold">
            Edit Profile
          </DialogTitle>
          <IconButton onClick={closeEditModal} className="">
            <IoClose size={20} />
          </IconButton>
        </div>

        {/* Modal Content */}
        <DialogContent>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {/* Left Column */}
            <div className="flex flex-col gap-4">
              <div>
                <p className="font-medium">Staff ID</p>
                <TextField
                  variant="outlined"
                  placeholder="Enter staff ID"
                  fullWidth
                />
              </div>

              <div>
                <p className="font-medium">Username</p>
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder="Enter Username"
                />
              </div>

              <div>
                <p className="font-medium">Emergency Contact</p>
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder="Enter Emergency Contact"
                />
              </div>

              <div>
                <p className="font-medium">Marital Status</p>
                <TextField
                  variant="outlined"
                  fullWidth
                  select
                  placeholder="Enter Marital Status"
                >
                  <MenuItem value="Single">Single</MenuItem>
                  <MenuItem value="Married">Married</MenuItem>
                </TextField>
              </div>
            </div>

            {/* Middle Column */}
            <div className="flex flex-col gap-4">
              <div>
                <p className="font-medium">First Name</p>
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder="Enter First Name"
                />
              </div>

              <div>
                <p className="font-medium">Date of Birth</p>
                <TextField type="date" variant="outlined" fullWidth />
              </div>

              <div>
                <p className="font-medium">Blood Group</p>
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder="Enter Blood Group"
                />
              </div>

              <div>
                <p className="font-medium">Gender</p>
                <TextField
                  variant="outlined"
                  fullWidth
                  select
                  placeholder="Enter Gender"
                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                </TextField>
              </div>
            </div>

            {/* Right Column */}
            <div className="flex flex-col gap-4">
              <div>
                <p className="font-medium">Last Name</p>
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder="Enter Last Name"
                />
              </div>

              <div>
                <p className="font-medium">Email Address</p>
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder="Enter Email Address"
                />
              </div>

              <div>
                <p className="font-medium">Address</p>
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder="Enter Address"
                />
              </div>

              <div>
                <p className="font-medium">About Me</p>
                <TextField
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={2}
                  placeholder="Enter description"
                />
              </div>
            </div>
          </div>

          {/* Work Information */}
          <div className="mt-6">
            <h3 className="text-lg font-semibold mb-4">Work Information</h3>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div>
                <p className="font-medium">Designation</p>
                <TextField
                  variant="outlined"
                  fullWidth
                  select
                  placeholder="Select"
                >
                  <MenuItem value="Manager">Manager</MenuItem>
                  <MenuItem value="Developer">Developer</MenuItem>
                </TextField>
              </div>

              <div>
                <p className="font-medium">Department</p>
                <TextField
                  variant="outlined"
                  fullWidth
                  select
                  placeholder="Select"
                >
                  <MenuItem value="IT">IT</MenuItem>
                  <MenuItem value="HR">HR</MenuItem>
                </TextField>
              </div>

              <div>
                <p className="font-medium">Source of Hire</p>
                <TextField
                  variant="outlined"
                  fullWidth
                  select
                  placeholder="Select"
                >
                  <MenuItem value="Full-time">Full-time</MenuItem>
                  <MenuItem value="Part-time">Part-time</MenuItem>
                </TextField>
              </div>

              <div>
                <p className="font-medium">Date of Joining</p>
                <TextField type="date" variant="outlined" fullWidth />
              </div>

              <div>
                <p className="font-medium">Role</p>
                <TextField
                  variant="outlined"
                  fullWidth
                  select
                  placeholder="Select"
                >
                  <MenuItem value="Full-time">Full-time</MenuItem>
                  <MenuItem value="Part-time">Part-time</MenuItem>
                </TextField>
              </div>

              <div>
                <p className="font-medium">Years of Experience</p>
                <TextField
                  variant="outlined"
                  fullWidth
                  select
                  placeholder="Select"
                >
                  <MenuItem value="Full-time">Full-time</MenuItem>
                  <MenuItem value="Part-time">Part-time</MenuItem>
                </TextField>
              </div>

              <div>
                <p className="font-medium">Employment Type</p>
                <TextField
                  variant="outlined"
                  fullWidth
                  select
                  placeholder="Select"
                >
                  <MenuItem value="Full-time">Full-time</MenuItem>
                  <MenuItem value="Part-time">Part-time</MenuItem>
                </TextField>
              </div>

              <div>
                <p className="font-medium">Employment Status</p>
                <TextField
                  variant="outlined"
                  fullWidth
                  select
                  placeholder="Select"
                >
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="Inactive">Inactive</MenuItem>
                </TextField>
              </div>

              <div>
                <p className="font-medium">Reporting Manager</p>
                <TextField
                  variant="outlined"
                  fullWidth
                  select
                  placeholder="Select"
                >
                  <MenuItem value="John Doe">John Doe</MenuItem>
                  <MenuItem value="Jane Smith">Jane Smith</MenuItem>
                </TextField>
              </div>
            </div>
          </div>

          <div className="mt-6">
            <h3 className="text-lg font-semibold mb-4">Contact Address</h3>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div>
                <p className="font-medium">Personal Email Address</p>
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder="Enter email address"
                />
              </div>

              <div>
                <p className="font-medium">Work Email Address</p>
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder="Enter email address"
                />
              </div>

              <div>
                <p className="font-medium">Tags</p>
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder="Enter tags"
                />
              </div>

              <div>
                <p className="font-medium">Personal Phone Number</p>
                <div className="flex gap-2">
                  <TextField
                    select
                    variant="outlined"
                    className="w-1/4"
                    defaultValue="+234"
                  >
                    <MenuItem value="+1">+1</MenuItem>
                    <MenuItem value="+44">+44</MenuItem>
                    <MenuItem value="+234">+234</MenuItem>
                  </TextField>
                  <TextField
                    variant="outlined"
                    fullWidth
                    placeholder="000 000 0000"
                  />
                </div>
              </div>

              <div>
                <p className="font-medium">Work Phone Number</p>
                <div className="flex gap-2">
                  <TextField
                    select
                    variant="outlined"
                    className="w-1/4"
                    defaultValue="+234"
                  >
                    <MenuItem value="+1">+1</MenuItem>
                    <MenuItem value="+44">+44</MenuItem>
                    <MenuItem value="+234">+234</MenuItem>
                  </TextField>
                  <TextField
                    variant="outlined"
                    fullWidth
                    placeholder="000 000 0000"
                  />
                </div>
              </div>

              <div>
                <p className="font-medium">Current Address</p>
                <TextField
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  placeholder="Enter description"
                />
              </div>
            </div>

            {/* Permanent Address */}
            <div className="mt-6">
              <h3 className="text-lg font-semibold mb-4">Permanent Address</h3>
              <div className="flex items-center gap-3">
                <input type="checkbox" id="sameAddress" />
                <label htmlFor="sameAddress" className="font-medium">
                  Same as current address
                </label>
              </div>
              <TextField
                variant="outlined"
                fullWidth
                placeholder="Enter description"
                multiline
                rows={2}
              />
            </div>

            {/* Termination Information */}
            <div className="mt-6">
              <h3 className="text-lg font-semibold mb-4">
                Termination Information
              </h3>
              <div>
                <p className="font-medium">Date of Exit</p>
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder="dd - mmmm - yyyy"
                  type="date"
                />
              </div>
            </div>

            {/* System Field */}
            <div className="mt-6">
              <h3 className="text-lg font-semibold mb-4">System Field</h3>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div>
                  <p className="font-medium">Information Was Added By</p>
                  <TextField
                    variant="outlined"
                    fullWidth
                    defaultValue="Jonathan"
                    disabled
                  />
                </div>
                <div>
                  <p className="font-medium">Information Was Added At</p>
                  <TextField
                    variant="outlined"
                    fullWidth
                    defaultValue="27-12-2024"
                    type="date"
                    disabled
                  />
                </div>
                <div>
                  <p className="font-medium">Information Was Modified By</p>
                  <TextField
                    variant="outlined"
                    fullWidth
                    defaultValue="Jonathan"
                    disabled
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <p className="font-medium">Onboarding Status</p>
                  <TextField
                    variant="outlined"
                    fullWidth
                    select
                    placeholder="Select"
                  >
                    <MenuItem value="John Doe">John Doe</MenuItem>
                    <MenuItem value="Jane Smith">Jane Smith</MenuItem>
                  </TextField>
                </div>
                <div>
                  <p className="font-medium">Information Was Modified At</p>
                  <TextField
                    variant="outlined"
                    fullWidth
                    defaultValue="27-12-2024"
                    type="date"
                    disabled
                  />
                </div>
              </div>

                {/* Work Experience Section */}
                <div className="mt-6">
      <h3 className="text-lg font-semibold mb-4">Work Experience</h3>
      <div className="overflow-x-auto">
      <table className="w-full border-collapse overflow-x-auto">
        <thead>
          <tr className={`bg-[#191919] rounded-[5px] text-[#fff]`}>
            <th className="p-3">Company Name</th>
            <th className="p-3">Job Title</th>
            <th className="p-3">Start Date</th>
            <th className="p-3">End Date</th>
            <th className="p-3">Job Description</th>
            <th className="p-3">Action</th>
          </tr>
        </thead>
        <tbody>
          {workExperienceRows.map((row, index) => (
            <tr key={index}>
              <td className="p-3">
                <TextField fullWidth defaultValue={row.company} />
              </td>
              <td className="p-3">
                <TextField fullWidth defaultValue={row.jobTitle} />
              </td>
              <td className="p-3">
                <TextField type="date" fullWidth defaultValue={row.startDate} />
              </td>
              <td className="p-3">
                <TextField type="date" fullWidth defaultValue={row.endDate} />
              </td>
              <td className="p-3">
                <TextField fullWidth defaultValue={row.jobDescription} />
              </td>
              <td className="p-3 text-center">
                <button onClick={() => removeWorkExperienceRow(index)}>
                  <RiDeleteBinLine size={20} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>

      {/* Add New Row Button */}
      <div className="bg-[#3767B1] bg-opacity-5 rounded-[5px] dark:bg-[#141414] p-2">
        <button onClick={addWorkExperienceRow} className="flex items-center gap-3 text-blue-500">
          <FiPlusSquare size={20} />
          <span className="text-sm">Add New Row</span>
        </button>
      </div>
    </div>

              {/* Work Experience Section */}
              <div className="mt-6">
                <h3 className="text-lg font-semibold mb-4">
                  Educational History
                </h3>
                <div className="overflow-x-auto">
                <table className="w-full border-collapse">
                  <thead>
                    <tr className="bg-[#191919] rounded-[5px] text-[#fff]">
                      <th className="p-3 ">Name of Institute</th>
                      <th className="p-3 ">Degree</th>
                      <th className="p-3 ">Start Date</th>
                      <th className="p-3">End Date</th>
                      <th className="p-3">Specialization</th>
                      <th className="p-3 ">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map((row) => (
                      <tr key={row.id}>
                        <td className="p-3">
                          <TextField fullWidth defaultValue={row.institute} />
                        </td>
                        <td className="p-3">
                          <TextField fullWidth defaultValue={row.degree} />
                        </td>
                        <td className="p-3">
                          <TextField
                            type="date"
                            fullWidth
                            defaultValue={row.startDate}
                          />
                        </td>
                        <td className="p-3">
                          <TextField
                            type="date"
                            fullWidth
                            defaultValue={row.endDate}
                          />
                        </td>
                        <td className="p-3">
                          <TextField
                            fullWidth
                            defaultValue={row.specialization}
                          />
                        </td>
                        <td className="p-3 text-center">
                          <button onClick={() => deleteRow(row.id)}>
                            <RiDeleteBinLine size={20} />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                </div>

                {/* Add New Row Button */}
                <div className="bg-[#3767B1] bg-opacity-5 rounded-[5px] p-2 dark:bg-[#141414]">
                  <button
                    onClick={addRow}
                    className="flex items-center gap-3 text-blue-500"
                  >
                    <FiPlusSquare size={20} />
                    <span className="text-sm">Add New Row</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </div>
    </Dialog>
  );
};

export default EditProfileModal;
