import React, { useState } from "react";
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  tableCellClasses,
  TableBody,
  IconButton,
  Button,
  Autocomplete,
  Grid,
  Modal,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from "@mui/material";
import { CgMenuGridO } from "react-icons/cg";
import { LuLayoutList } from "react-icons/lu";
import { styled } from "@mui/material/styles";
import { MdOutlineModeEdit } from "react-icons/md";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { IoCalendarOutline, IoFilter } from "react-icons/io5";
import { useTheme } from "../../../style/theme";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import dayjs from "dayjs";

export default function EmployeeShiftMapping() {
  const [activeTab, setActiveTab] = useState("list");

  const { mode } = useTheme();
  const DailyBasedEmployeeShiftMappingTableView = () => {
    const columns = [
      "Employee",
      "08 AM",
      "09 AM",
      "10 AM",
      "11 AM",
      "12 PM",
      "01 PM",
      "02 PM",
      "03 PM",
      "04 PM",
      "05 PM",
      "06 PM",
    ];
    const employees = ["1 - Steward Graham", "2 - Lilly Williams"];

    const data = [{}];

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
      },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
      "&:last-child td, &:last-child th": {
        border: 0,
      },
    }));

    return (
      <TableContainer
        style={{ overflowX: "auto", marginTop: "8px" }}
        className="text-nowrap"
        component={Paper}
      >
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell key={index} style={{ whiteSpace: "nowrap" }}>
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {employees.map((employee, index) => {
              return data.map((row, index) => (
                <>
                  <StyledTableRow>
                    <StyledTableCell>{employee}</StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell colSpan={9}>
                      <div className="flex flex-row justify-between items-center bg-blue-300 rounded-lg bg-opacity-10 p-4">
                        <div>
                          <h1>General</h1>
                          <h1>09:00 AM - 06:00 PM</h1>
                        </div>
                        <div>
                          <IconButton>
                            <MdOutlineModeEdit />
                          </IconButton>
                        </div>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                  </StyledTableRow>
                </>
              ));
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };
  const WeeklyBasedEmployeeShiftMappingTableView = () => {
    const days = [
      "Sun 16",
      "Mon 17",
      "Tue 18",
      "Wed 19",
      "Thu 20",
      "Fri 21",
      "Sat 22",
    ];
    const activeDay = "Wed 19";
    const employees = [
      "Steward Graham",
      "Lilly Williams",
      "Lindon Smith",
      "Olivia Smith",
      "Lilly Williams",
      "Lindon Smith",
      "Lilly Williams",
      "Lindon Smith",
    ];

    const data = [{}];

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
      },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
      "&:last-child td, &:last-child th": {
        border: 0,
      },
    }));

    return (
      <div className="overflow-x-auto rounded-md mt-4">
        <table className="min-w-full border-collapse">
          <thead className="sticky top-0 z-10 bg-white ">
            <tr
              className={`text-[#666666] ${mode === "light" ? "bg-[#E5E5F0]" : "bg-[#141414]"
                }`}
            >
              <th
                className={` p-4 sticky left-0 ${mode === "light" ? "bg-[#E5E5F0] border-r border-[#f5f5f5]" : "bg-[#141414] border-r border-[#1e2734]"
                  } z-10`}
              >
                <input type="checkbox" />
              </th>
              <th
                className={`w-[350px] text-[#666666] font-[400] p-4 text-left sticky left-[40px] ${mode === "light" ? "bg-[#E5E5F0] border-r  border-[#f5f5f5]" : "bg-[#141414] border-r border-[#1e2734]"
                  } z-10`}
              >
                Employee
              </th>
              {days.map((day) => (
                <th
                  key={day}
                  className={`${mode === "light" ? "border-r  border-[#f5f5f5]" : "border-r border-[#1e2734]"} sticky font-[400] p-4 text-left`}
                >
                  {day}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {employees.map((employee, index) => (
              <tr key={index} className={`${mode === "light" ? "border-t" : "border-t border-[#1e2734]"} sticky`}>
                <td className={`${mode === "light" ? "bg-white border-r border-[#f5f5f5]" : "bg-[#141414] border-r border-[#1e2734]"
                  } h-[90px] text-center sticky left-0 z-10`}>
                  <input type="checkbox" />
                </td>
                <td style={{ backgroundColor: mode === "light" ? "white" : "#141414" }} className="h-[90px] w-[300px] flex items-center text-left gap-3 px-2 sticky left-[40px] z-10">
                  <div className="w-8 h-8 flex items-center justify-center rounded-md">
                    <img
                      className="rounded-md"
                      src="/images/user-profile.png"
                      alt=""
                      style={{ width: "38px", height: "38px" }}
                    />
                  </div>
                  <span
                    className={`${mode === "light" ? "text-[#666666] " : ""}`}
                  >
                    {index + 1}{" "}
                  </span>
                  <span
                    className={`${mode === "light" ? "text-[#222222] " : ""}`}
                  >
                    - {employee}
                  </span>
                </td>
                {days.map((day) => (
                  <td key={day} className="text-center">
                    <div
                      className={`flex flex-col items-center w-[150px] justify-center px-3 h-[90px] ${mode === "light" ? "bg-[#f6f6f6]" : ""
                        }`}
                    >
                      <h1
                        className={`text-[14px] font-[500] ${mode === "light" ? "text-[#222222] " : ""
                          }`}
                      >
                        General
                      </h1>
                      <h1 className="text-[14px] text-[#666666]">
                        09:00 AM - 06:00 PM
                      </h1>
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const [switchScreen, setSwitchScreen] = useState({
    primary: "weeklyView",
  });

  const [assignShiftModal, setAssignShiftModal] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [open, setOpen] = useState(false);

  const [selectedDate, setSelectedDate] = useState(dayjs());
  const shifts = ["General - 09:00 AM - 06:00 PM"];
  const employees = ["S1 - Steward Graham", "S2 - Lilly Williams"];
  const departments = ["HR", "IT", "Marketing", "Management"];
  const designations = [
    "CEO",
    "Team Member",
    "Manager",
    "Administration",
    "Assistant Manager",
  ];

  const handlePrevWeek = () => {
    setSelectedDate(selectedDate.subtract(7, "day"));
  };

  const handleNextWeek = () => {
    setSelectedDate(selectedDate.add(7, "day"));
  };
  const startOfWeek = selectedDate.startOf("isoWeek"); // Monday
  const endOfWeek = startOfWeek.add(6, "day"); // Sunday
  const formattedStart = startOfWeek.format("DD/MM/YYYY");
  const formattedEnd = endOfWeek.format("DD/MM/YYYY");

  return (
    <div className="p-3">
      <div className="flex justify-between items-center">
        <div></div>

        <div className="flex relative  items-center gap-2">
          {/* Previous Week Button */}
          <div className="flex items-center gap-2 bg-white p-2 rounded-md">
            <IoIosArrowBack
              fontSize="small"
              className="cursor-pointer hover:text-gray-600"
              onClick={handlePrevWeek}
              color="#398aff"
            />

            {/* Calendar Icon and Date Range Text */}
            <Box
              className="relative flex items-center rounded-md cursor-pointer hover:bg-gray-200"
              onClick={() => setOpen((prev) => !prev)}
            >
              <IoCalendarOutline size="18px" className="text-gray-600" />
            </Box>

            {/* Next Week Button */}
            <IoIosArrowForward
              fontSize="small"
              className="cursor-pointer hover:text-gray-600"
              onClick={handleNextWeek}
              color="#398aff"
            />
          </div>

          <div className="text-sm flex gap-2 items-center font-medium">
            <span>{formattedStart}</span> - <span>{formattedEnd}</span>
          </div>

          {/* Calendar Popup */}
          {open && (
            <Box className="absolute top-8 z-10 shadow-md border rounded-md bg-white">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar
                  value={selectedDate}
                  onChange={(date) => {
                    setSelectedDate(date);
                    setOpen(false);
                  }}
                />
              </LocalizationProvider>
            </Box>
          )}
        </div>

        <div className="flex gap-2 items-center">
          <div className="w-full flex sticky items-center ">
            <button
              onClick={() => setSwitchScreen({ primary: "weeklyView" })}
              style={{
                backgroundColor:
                  switchScreen.primary === "weeklyView" ? "#fff" : "#e5e5f0",
                border:
                  switchScreen.primary === "weeklyView"
                    ? "1px solid #0088ff"
                    : "none",
                color:
                  switchScreen.primary === "weeklyView" ? "#000" : "#666866",
                borderRadius: "3px",
                height: "32px",
                fontSize: "14px",
                width: "70px",
                fontWeight: "500",
              }}
            >
              Weekly
            </button>
            <button
              onClick={() => setSwitchScreen({ primary: "dailyView" })}
              style={{
                backgroundColor:
                  switchScreen.primary === "dailyView" ? "#fff" : "#e5e5f0",
                border:
                  switchScreen.primary === "dailyView"
                    ? "1px solid #0088ff"
                    : "none",
                color:
                  switchScreen.primary === "dailyView" ? "#000" : "#666866",
                borderRadius: "3px",
                height: "32px",
                fontSize: "14px",
                width: "70px",
                fontWeight: "500",
              }}
            >
              Daily
            </button>
          </div>

          <div className="flex items-center">
            <div
              onClick={() => setActiveTab("list")}
              className={`p-2 cursor-pointer rounded-[4px] ${activeTab === "list" ? "border-1 border-[#098cff]" : ""}`}
            >
              <LuLayoutList />
            </div>

            <div
              onClick={() => setActiveTab("grid")}
              className={`p-2 cursor-pointer ${activeTab === "grid" ? "border-1 border-[#098cff]" : ""}`}
            >
              <CgMenuGridO />
            </div>
          </div>


          <div className="flex gap-3 items-center">
            <button
              className={`bg-[#0088ff] w-[100px] h-[32px] rounded-md text-[14px] text-[#fff]`}
              onClick={() => {
                setAssignShiftModal(true);
              }}
            >
              Assign Shift
            </button>
            <div
              onClick={() => {
                setFilterModal(true);
              }}
              className={`${mode === "light" ? "bg-[#fff]" : ""} rounded-md`}
            >
              <IoFilter size={'16px'} />
            </div>

          </div>
        </div>
      </div>

      <div className="w-full">
        {switchScreen.primary === "weeklyView" ? (
          <>
            <WeeklyBasedEmployeeShiftMappingTableView />
          </>
        ) : (
          <></>
        )}
        {switchScreen.primary === "dailyView" ? (
          <>
            <DailyBasedEmployeeShiftMappingTableView />
          </>
        ) : (
          <></>
        )}
      </div>
      <div>
        <Modal
          open={assignShiftModal}
          onClose={() => {
            setAssignShiftModal(false);
          }}
          aria-labelledby="checkInAndCheckOutEntryModal"
          aria-describedby="checkInAndCheckOutEntryModal"
        >
          <Grid
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "background.default",
              flexDirection: "column",
            }}
            className="w-1/2 p-4 flex flex-col gap-4 rounded-lg border border-gray-800"
          >
            <div>
              <h1>Assign Shift</h1>
            </div>
            <div className="w-full">
              <Autocomplete
                sx={{ width: "100%" }}
                disablePortal
                options={shifts}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Shifts"
                    placeholder="Shifts"
                  />
                )}
                onChange={() => {
                  setAssignShiftModal(true);
                }}
              />
            </div>
            <div className="flex flex-row gap-4">
              <div className="w-full">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker sx={{ minWidth: "100%" }} label="From" />
                </LocalizationProvider>
              </div>
              <div className="w-full">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className="w-full"
                    sx={{ minWidth: "100%" }}
                    label="To"
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div>
              <TextField
                sx={{ width: "100%" }}
                variant="outlined"
                label="Reason"
                placeholder="Reason"
              />
            </div>
            <div className="w-full flex flex-row justify-between items-center">
              <div>
                <Button variant="contained">Submit</Button>
              </div>
              <div>
                <Button
                  onClick={() => {
                    setAssignShiftModal(false);
                  }}
                  variant="outlined"
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Grid>
        </Modal>
      </div>
      <div>
        <Modal
          open={filterModal}
          onClose={() => {
            setFilterModal(false);
          }}
          aria-labelledby="filterModal"
          aria-describedby="filterModal"
        >
          <Grid
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "background.default",
              flexDirection: "column",
            }}
            className="w-1/2 p-4 flex flex-col gap-4 rounded-lg border border-gray-800"
          >
            <div>
              <h1>Filter</h1>
            </div>
            <div>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="period">Period</InputLabel>
                <Select
                  labelId="period"
                  id="period"
                  value={"today"}
                  label="Period"
                >
                  <MenuItem value={"today"}>Today</MenuItem>
                  <MenuItem value={"yesterday"}>Yesterday</MenuItem>
                  <MenuItem value={"thisWeek"}>This Week</MenuItem>
                  <MenuItem value={"lastWeek"}>Last Week</MenuItem>
                  <MenuItem value={"thisMonth"}>This Month</MenuItem>
                  <MenuItem value={"lastMonth"}>Last Month</MenuItem>
                  <MenuItem value={"custom"}>Custom</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="flex flex-row gap-4">
              <div className="w-full">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker sx={{ minWidth: "100%" }} label="From" />
                </LocalizationProvider>
              </div>
              <div className="w-full">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className="w-full"
                    sx={{ minWidth: "100%" }}
                    label="To"
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div>
              <Autocomplete
                sx={{ width: "100%" }}
                multiple
                options={employees}
                getOptionLabel={(option) => option}
                disableCloseOnSelect
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Employees"
                    placeholder="Employees"
                  />
                )}
              />
            </div>
            <div>
              <Autocomplete
                sx={{ width: "100%" }}
                multiple
                options={departments}
                getOptionLabel={(option) => option}
                disableCloseOnSelect
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Departments"
                    placeholder="Departments"
                  />
                )}
              />
            </div>
            <div>
              <Autocomplete
                sx={{ width: "100%" }}
                multiple
                options={designations}
                getOptionLabel={(option) => option}
                disableCloseOnSelect
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Designations"
                    placeholder="Designations"
                  />
                )}
              />
            </div>
            <div>
              <Autocomplete
                sx={{ width: "100%" }}
                multiple
                options={shifts}
                getOptionLabel={(option) => option}
                disableCloseOnSelect
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Shifts"
                    placeholder="Shifts"
                  />
                )}
              />
            </div>
            <div className="w-full flex flex-row justify-between items-center">
              <div>
                <Button variant="contained">Submit</Button>
              </div>
              <div>
                <Button
                  onClick={() => {
                    setFilterModal(false);
                  }}
                  variant="outlined"
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Grid>
        </Modal>
      </div>
    </div>
  );
}
