import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Modal,
  Toolbar,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useEffect, useRef, useState } from "react";
import PlaceIcon from "@mui/icons-material/Place";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import axios from "axios";
import { CategoryOutlined, Lock, Pending } from "@mui/icons-material";
import Details from "./Details";
import { useMessage } from "../../components/Header";
import { IoIosMore } from "react-icons/io";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useTheme } from "../../style/theme";
import { MdExpandLess } from 'react-icons/md'; 

import { TfiMoreAlt } from "react-icons/tfi";
const JobListingCard = (props) => {
  const {
    title,
    location,
    experience,
    details,
    jobType,
    salary,
    id,
    departmentId,
    refresh,
    editJob,
    copyJob,
    departments,
    remote,
  } = props;
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [deletingModal, setDeleting] = useState(false);
  const { showError, showSuccess } = useMessage();
  const OpenDeleteModal = () => setDeleting(true);
  const CloseDeleteModal = () => setDeleting(false);
  const { mode } = useTheme();
  const style = {
    width: "100%",

    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
  };

  const handleExpand = () => {
    setAccordionOpen((show) => !show);
  };

  async function deletejob(id) {
    try {
      const res = await axios.delete(`/hr/job-listing/${id}`);
      const { success, message } = res.data;
      if (success) {
        showSuccess("Job deleted successfully");
      } else {
        showError(message);
      }
    } catch (e) {}

    refresh();
  }

  const handleClose = () => setOpen(false);
  const [openModal, setOpenModal] = useState(false);

  const toggleModal = () => {
    setOpenModal((prev) => !prev);
  };

  const modalRef = useRef(null);  // Reference for the modal content
  const buttonRef = useRef(null);  // Reference for the button that opens the modal
  useEffect(() => {
    // Function to handle clicks outside the modal
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
        setOpenModal(false); // Close the modal if clicked outside
      }
    };

    // Add event listener for clicks outside
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup the event listener on unmount
    // return () => {
    //   document.removeEventListener('mousedown', handleClickOutside);
    // };
  }, []);

  return (
    <Box my={2} sx={{ backgroundColor: "background.default" }}>
      <div className="flex  justify-between gap-7  items-center">
        <div className="w-full hidden sm:grid grid-cols-6 gap-10 mt-[20px] relative border px-4 py-2 border-[#2B2B2B] rounded-[8px] items-center">
          {/* Row 1 */}
          <Typography
            className={`text-[16px] font-[700] ${
              mode === "dark" ? "text-[#ffffff]" : "text-[#A5A5A5]"
            }`}
          >
            {title}
          </Typography>
          <div className="flex items-center gap-2">
            <img src="/images/salaryicon.png" alt="" style={{width:"13px" ,height:"13px"}}/>
            <Typography className="text-[16px] font-[400] text-[#A5A5A5]">
              {salary.amount} CAD
            </Typography>
          </div>
          <div className="flex items-center gap-2">
            <img src="/images/yearIcon.png" alt="" style={{width:"14px" ,height:"14px"}}/>
            <Typography className="text-[16px] font-[400] text-[#A5A5A5]">
              {experience} Year
            </Typography>
          </div>
          <div className="flex items-center gap-2">
            <img src="/images/remoteicon.png" alt="" style={{width:"12px" ,height:"14px"}}/>
            <p className="text-[16px] font-[400] text-[#A5A5A5]">{remote ? "Remote" : " On Premise"}</p>
          </div>
          <div className="flex items-center gap-2">
            <img src="/images/developicon.png" alt="" style={{width:"13px" ,height:"13px"}}/>
            <Typography className="text-[16px] font-[400] text-[#A5A5A5]">
              {departments[departmentId]}
            </Typography>
          </div>
          {/* <Typography className="text-[16px] font-[400] text-[#A5A5A5]">
            {jobType}
          </Typography>
          <Typography className="text-[16px] font-[400] text-[#A5A5A5]">
            {location}
          </Typography> */}
          <Button
            sx={{
              textTransform: "capitalize",
              fontSize: "16px",
              color: "#3767B1",
              mr: 2,
            }}
            onClick={handleExpand}
            variant="text"
            endIcon={
              accordionOpen ? (
                <MdExpandLess color="#3767B1" />
              ) : (
                <ExpandMoreIcon color="#3767B1" />
              )
            }
          >
            Show details
          </Button>
        </div>

        <div
          className={` ${
            mode === "dark" ? "bg-[#171717]" : ""
          } hidden sm:flex h-[58px] rounded-md flex justify-center mt-[20px] items-center p-4`}
          onClick={toggleModal}
          ref={buttonRef} 
        >
          <TfiMoreAlt   className="relative font-extrabold"
          size="20px"
        />

        
        </div>
      </div>

      {/* {mobile screen} */}
      <div
        className={`${
          mode === "dark" ? "bg-[#1F1F1F]" : ""
        } px-3 py-4 rounded-[8px] sm:hidden relative`}
        ref={buttonRef} 
      >
        <div className="flex justify-between items-center ">
          <p
            className={`text-[12px] mb-2 font-[700]  ${
              mode === "dark" ? "text-[#ffffff]" : ""
            } `}
          >
            {title}
          </p>

          <div className="relative">
            <IoIosMore className="relative" onClick={toggleModal} />
          </div>

          
        </div>

        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center gap-2">
            <div>
              <img src="/images/salaryicon.png" alt="" />
            </div>
            <p className="text-[12px] font-[400] text-[#A5A5A5]">
              {salary.amount} CAD
            </p>
          </div>

          <div className="flex items-center gap-2">
            <div>
              <img src="/images/yearIcon.png" alt="" style={{width:"13px" ,height:"13px"}}/>
            </div>
            <p className="text-[12px] font-[400] text-[#A5A5A5]">
              {experience} Year
            </p>
          </div>

          <div className="flex items-center gap-2">
            <div>
              <img src="/images/remoteicon.png" alt="" />
            </div>
            <p className="text-[12px] font-[400] text-[#A5A5A5]">{remote ? "Remote" :"On Premise"}</p>
          </div>

          <div className="flex items-center gap-2">
            <div>
              <img src="/images/developicon.png" alt="" />
            </div>
            <p className="text-[12px] font-[400] text-[#A5A5A5]">
              {departments[departmentId]}
            </p>
          </div>
        </div>

        <div className="flex justify-between items-center">
        <Button
                  sx={{
                    textTransform: "capitalize",
                    fontSize: "12px",
                    color: "#3767B1",
                    mr: 2,
                  }}
                  onClick={handleExpand}
                  variant="text"
                  endIcon={
                    accordionOpen ? (
                      <MdExpandLess color="#3767B1" />
                    ) : (
                      <ExpandMoreIcon color="#3767B1" />
                    )
                  }
                >
                  Show details
                </Button>

          <button
            style={{ height: "25px", backgroundColor: "#3767B1" }}
            className="w-max rounded-[5px] p-2 flex justify-center items-center text-[12px] text-[#fff]"
          >
            Apply Now
          </button>
        </div>
      </div>

      {accordionOpen && (
        <Details details={details} jobType={jobType} salary={salary} />
      )}

      <Modal
        sx={{ overflowY: "scroll" }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Toolbar>
            <IconButton
              edge="start"
              color="primary"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </Box>
      </Modal>


      {openModal && (
            <div ref={modalRef} className="absolute right-[40px] sm:right-[70px] mt-[-80px] sm:mt-[-10px] shadow-lg flex-col flex gap-3 rounded-md w-[149px] h-[121px] text-white px-4 py-3 bg-[#1B1B1B] z-10">
              <div
                className="flex items-center gap-3"
                onClick={() => copyJob(id)}
              >
                <ContentCopyIcon fontSize="small" />
                <p>Copy</p>
              </div>

              <div
                className="flex items-center gap-3"
                onClick={() => editJob(id)}
              >
                <EditIcon fontSize="small" />
                <p>Edit</p>
              </div>

              {/* onClick={() => OpenDeleteModal(id)} */}
              <div
                className="flex items-center gap-3"
                onClick={OpenDeleteModal}
              >
                <DeleteIcon fontSize="small" />

                <p>Delete</p>
              </div>
            </div>
          )}
          {deletingModal && (
            <Dialog
              maxWidth="sm"
              fullWidth
              open={OpenDeleteModal}
              onClose={CloseDeleteModal}
            >
              <div
                className="p-[10px] sm:p-[20px] "
                style={{ backgroundColor: mode === "dark" ? "#171717" : "" }}
              >
                <DialogTitle>Delete Job Field</DialogTitle>
                <DialogContent
                  sx={{ backgroundColor: mode === "dark" ? "#171717" : "" }}
                >
                  <p className="text-[#A5A5A5] text-[10px] sm:text-[14px]">
                    Are you sure you want to delete <b>Full Stack Engineer</b>?
                  </p>
                  <p className="text-[#FF3D3D] mt-[30px] text-[10px] sm:text-[14px]">
                    This action cannot be undone.
                  </p>
                </DialogContent>
                <DialogActions
                  sx={{ backgroundColor: mode === "dark" ? "#171717" : "" }}
                >
                  <Button
                    onClick={CloseDeleteModal}
                    color="primary"
                    variant="contained"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      deletejob(id);
                      CloseDeleteModal();
                    }}
                    color="error"
                    variant="outlined"
                  >
                    Delete
                  </Button>
                </DialogActions>
              </div>
            </Dialog>
          )}
    </Box>
  );
};

export default JobListingCard;
