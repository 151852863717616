import React, { useState } from "react";
import {
    Avatar,
    Box,
    Button,
    Grid,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { TbPhone, TbPhoneCalling } from "react-icons/tb";
import { BsThreeDots } from "react-icons/bs";
import { useTheme } from "../../../../style/theme";
import ChatIcon from "@mui/icons-material/Chat";
import CallIcon from "@mui/icons-material/Call";
import VideoCallIcon from "@mui/icons-material/VideoCall";
const arr = [
    {
        img_url: "/images/profileImg1.png",
        name: "Amanda Throne",
        attendence: "Present",
        time: "9:00 AM-6:00 PM"
    },
    {
        img_url: "/images/profileImg2.png",
        name: "Amina Kumar",
        attendence: "Absent",
        time: "9:00 AM-6:00 PM"
    },
    {
        img_url: "/images/profileImg3.png",
        name: "Daniel Thompson",
        attendence: "Leave",
        time: "9:00 AM-6:00 PM"
    },
    {
        img_url: "/images/profileImg4.png",
        name: "Dave Maxwell",
        attendence: "Present",
        time: "9:00 AM-6:00 PM"
    },
    {
        img_url: "/images/profileImg5.png",
        name: "Dwayne Graham",
        attendence: "Present",
        time: "9:00 AM-6:00 PM"
    },
]

export default function LeaveReportees({ searchTerm }) {
    const navigate = useNavigate();
    const [hovered, setHovered] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const options = [
        { label: "Chat", icon: <ChatIcon /> },
        { label: "Audio Call", icon: <CallIcon /> },
        { label: "Video Call", icon: <VideoCallIcon /> },
      ];   
       const ITEM_HEIGHT = 48;
    const { mode } = useTheme();
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const filteredData = arr?.filter((item) =>
        item?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
    );
    return (
        filteredData.map((items, i) => (
            <Box
                sx={{
                    backgroundColor: mode === "dark" ? "#141414" : "white",
                    border: mode === "dark" ? "1px solid #1e2734" : "1px solid #d7e2ed"
                }}
                key={i}
                className="w-full h-fit sm:p-3  rounded-lg cursor-pointer relative"
            // onMouseEnter={() => setHovered(true)}
            // onMouseLeave={() => setHovered(false)}
            >
                <div
                    onClick={() => {
                        navigate("/leaveTracker/myData");
                    }}
                    className="w-full flex gap-3 flex-row justify-start items-center text-start"
                >
                    <div>
                        <img
                            alt="Steward Graham"
                            style={{ height: "50px", width: "50px" }}
                            src={items.img_url}
                            className="rounded-lg"
                        />
                    </div>
                    <div className="text-sm">
                        <div className="flex gap-2 items-center">
                            <h1>{items.name}</h1>
                        </div>
                    </div>

                </div>

                <div className="my-3 bg-[#f5f5f5] w-full h-[1px]" />

                <div className='flex gap-2 items-center'>
                    <h1
                        className="text-[15px]"
                    >
                        General - {items.time}
                    </h1>
                </div>

                <div className="min-w-10 absolute right-2 top-1">

                    <>
                        <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? "long-menu" : undefined}
                            aria-expanded={open ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={handleClick}
                        >
                            <TbPhone />
                        </IconButton>
                        <Menu
                            id="long-menu"
                            MenuListProps={{
                                "aria-labelledby": "long-button",
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            slotProps={{
                                paper: {
                                    style: {
                                        maxHeight: ITEM_HEIGHT * 4.5,
                                        width: "20ch",
                                    },
                                },
                            }}
                        >
                            {options.map((option) => (
                                 <MenuItem key={option.label} onClick={handleClose}>
                                 <ListItemIcon>{option.icon}</ListItemIcon>
                                 <ListItemText>{option.label}</ListItemText>
                               </MenuItem>
                            ))}
                        </Menu>
                    </>

                </div>
            </Box>

        ))
    );
}
