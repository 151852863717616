import * as React from "react";
import ReporteesTable from "./ReporteesTable";

function createData(employee, status, designation, email, dateOfJoining) {
  return { employee, status, designation, email, dateOfJoining };
}
const rows = [
    {
        img_url: "/images/profileImg1.png",
        name: "Amanda Throne",
        attendence: "Present",
        time: "9:00 AM-6:00 PM"
    },
    {
        img_url: "/images/profileImg2.png",
        name: "Amina Kumar",
        attendence: "Absent",
        time: "9:00 AM-6:00 PM"
    },
    {
        img_url: "/images/profileImg3.png",
        name: "Daniel Thompson",
        attendence: "Leave",
        time: "9:00 AM-6:00 PM"
    },
    {
        img_url: "/images/profileImg4.png",
        name: "Dave Maxwell",
        attendence: "Present",
        time: "9:00 AM-6:00 PM"
    },
    {
        img_url: "/images/profileImg5.png",
        name: "Dwayne Graham",
        attendence: "Present",
        time: "9:00 AM-6:00 PM"
    },
]

export default function DirectReporteesTable({searchTerm}) {
  return <ReporteesTable rows={rows} searchTerm={searchTerm}/>;
}
