import React from "react";
import Card from "./Card";

export default function AllDesignation() {
  return (
    <div
      className="w-full md:grid md:grid-cols-3 justify-center items-center  place-content-center
     place-items-center place-self-center sm:flex gap-2"
    >
      <Card title={"Team Member"} className={"w-full md:w-85 lg:w-96"} />
      <Card title={"Administration"} className={"w-full md:my-0 my-3 md:w-85 lg:w-96"} />
      <Card title={"Assistant Manager"} className={"w-full md:w-85 lg:w-96"}  />
    </div>
  );
}
