import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, CircularProgress, Grid, Modal } from "@mui/material";
import { IoCalendarOutline } from "react-icons/io5";
import axios from "axios";
import { useMessage } from "../../../Header";
import { handleAxiosError } from "../../../../utilities/function";
import ClockOutModal from "../../../ClockOutModal";
import dayjs from "dayjs";

export default function ClockInReminder({isClockedIn,setIsClockedIn}) {
  let currentOrg = JSON.parse(localStorage.getItem("org"));
  let currentEmp = currentOrg?.type === "Member" ? JSON.parse(localStorage.getItem("emp")) : null;

  const { showError, showSuccess } = useMessage();
  // const [isClockedIn, setIsClockedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openClockOut, setOpenClockOut] = useState(false);
  const [attendanceData, setAttendanceData] = useState(null);
  const [shiftStart, setShiftStart] = useState("");
  const [shiftEnd, setShiftEnd] = useState("");
  const [timeRemaining, setTimeRemaining] = useState(""); // Countdown time

  const [query, setQuery] = useState({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  });

  // Fetch shift data (Clock-in status)
  const fetchData = useCallback(async () => {
    try {
      const res = await axios.get(`/employee/shift?empId=${currentEmp?._id}`);
      setIsClockedIn(res?.data?.isClockedIn || false);
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    if (currentOrg?.type == "Member") {
      fetchData();
    }
  }, [fetchData]);

  // Clock-in and Clock-out handler
  const handleClock = async () => {
    setLoading(true);
    try {
      if (!isClockedIn) {
        const res = await axios.post(`/employee/shift/clock-in?empId=${currentEmp?._id}`);
        showSuccess(res.data.message);
        setIsClockedIn(true);
      } else {
        setOpenClockOut(true);
      }
    } catch (e) {
      handleAxiosError(e, showError);
    } finally {
      setLoading(false);
    }
  };

  const AttendanceDataFetch = useCallback(
    async function () {
      try {
        const res = await axios.get(
          `/employee/shift/attendance?empId=${currentEmp?._id}&month=${query.month}&year=${query.year}&sortBy=clockInTime&direction=-1`
        );
        setAttendanceData(res.data.attendance);
      } catch (e) {
        console.log(e);
      }
    },
    [setAttendanceData, query.month, query.year]
  );

  useEffect(() => {
    if (currentOrg?.type == "Member") {
      AttendanceDataFetch();
    }
  }, [AttendanceDataFetch]);

  const closeModal = () => {
    setOpenClockOut(false);
    fetchData();
  };

  useEffect(() => {
    if (currentEmp) {
      const start = currentEmp?.shiftStart;
      const end = currentEmp?.shiftEnd;
      setShiftStart(formatTime(start?.hour, start?.minute));
      setShiftEnd(formatTime(end?.hour, end?.minute));
      calculateNextShiftTime(currentEmp?.shiftStart); // Call to calculate next shift on initial load
    }
  }, []);

  const formatTime = (hour, minute) => {
    // Convert hour and minute into proper AM/PM format
    const time = dayjs().hour(hour).minute(minute);
    return time.format("h:mm A");
  };


  const calculateNextShiftTime = (shiftStart) => {
    if (!shiftStart) return;

    const shiftStartTime = dayjs()
      .hour(shiftStart?.hour)
      .minute(shiftStart?.minute)
      .second(0); // Ensure seconds start at 0

    const now = dayjs();
    let diff = shiftStartTime.diff(now, "second"); // Get difference in seconds

    if (diff < 0) {
      diff += 24 * 60 * 60; // Next day's shift
    }

    const hoursLeft = Math.floor(diff / 3600);
    const minutesLeft = Math.floor((diff % 3600) / 60);
    const secondsLeft = diff % 60;

    setTimeRemaining(
      `${formatTimeUnit(hoursLeft)}:${formatTimeUnit(minutesLeft)}:${formatTimeUnit(secondsLeft)}`
    );
  };

  const formatTimeUnit = (unit) => String(unit).padStart(2, "0");



  useEffect(() => {
    const interval = setInterval(() => {
      if (currentEmp) {
        calculateNextShiftTime(currentEmp?.shiftStart); // Recalculate every second
      }
    }, 1000); // Updates every second

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [currentEmp]);

  return (
    <Grid
      className="w-full flex gap-2 px-2 py-4 justify-between items-center rounded-lg shadow-md border border-[#1e2734]"
      sx={{ backgroundColor: "background.default" }}
    >
      <div className="w-full flex justify-start items-center">
        <div className="bg-blue-400 bg-opacity-10 rounded-lg p-2 me-3">
          <IoCalendarOutline className="h-6 w-6 text-blue-400" />
        </div>
        <div className="w-full flex sm:flex-row flex-col sm:items-center">
          <div className="w-full flex justify-start items-center">
            <div>
              <h1 className="text-[16px] sm:text-[1rem]">Clock-in reminder</h1>
              <p className="text-[10px] sm:text-xs text-zinc-400">
                {isClockedIn === true ? `Your next shift starts in ${timeRemaining}` :
                  "Your shift has already started"
                }
              </p>
            </div>
          </div>
          <div className="w-full flex me-7 sm:items-end flex-col">
            <div className="flex sm:flex-col items-center sm:items-start gap-2 sm:gap-0">
              <h1 className="text-start text-[12px] sm:text-[1rem]">General</h1>
              <h1 className="text-[10px] sm:text-xs text-zinc-400">{shiftStart} - {shiftEnd}</h1>
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          {currentOrg?.type === "Member" && (
            <Box className="w-[100px]" sx={{ textAlign: "center" }}>
              <Button
                variant="contained"
                sx={{ fontSize: "12px" }}
                onClick={handleClock}
                disabled={loading}
                endIcon={loading && <CircularProgress size="15px" sx={{ color: "inherit" }} />}
              >
                {isClockedIn ? "Clock Out" : "Clock In"}
              </Button>
            </Box>
          )}
        </div>
      </div>

      <Modal open={openClockOut} onClose={closeModal}>
        <ClockOutModal AttendanceDataFetch={AttendanceDataFetch} handleClose={closeModal} fetchData={fetchData} />
      </Modal>
    </Grid>
  );
}
