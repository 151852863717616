import { Avatar, Button, TextField, useTheme } from "@mui/material";
import React, { useRef, useState } from "react";
import Grid from "@mui/material/Grid2";
import {
  FormatBold,
  FormatItalic,
  AttachFile,
  InsertEmoticon,
  FormatUnderlined,
  FormatAlignLeft,
  FormatAlignCenter,
  FormatAlignRight,
  Link,
  FormatListBulleted,
  FormatListNumbered,
  Cancel,
  Send,
  TextFields,
  CheckBox,
  ArrowDropDown,
  EmojiEmotions,
  InsertPhoto,
  FormatSize,
  FormatColorText,
  InsertLink,
  FormatStrikethrough,
  Superscript,
  Subscript,
} from "@mui/icons-material";

export default function CreaatePost() {
  const [isEditorVisible, setEditorVisible] = useState(false);
  const [content, setContent] = useState("");
  const [isPlaceholderVisible, setIsPlaceholderVisible] = useState(true);
  const [activeStyles, setActiveStyles] = useState([]);
  const { mode } = useTheme();
  const editorRef = useRef(null);

  // Function to toggle styles & apply to text
  const handleClick = (style) => {
    document.execCommand(style);
    setActiveStyles((prev) =>
      prev.includes(style) ? prev.filter((s) => s !== style) : [...prev, style]
    );
  };

  // Function to check if the style is active
  const isActive = (style) => activeStyles.includes(style);

  // Handle input
  const handleInput = () => {
    setIsPlaceholderVisible(editorRef.current.innerText.trim() === "");
  };

  // Style for active icons
  const getIconStyle = (style) =>
    `p-2 rounded-md cursor-pointer transition ${
      isActive(style)
        ? "border border-blue-500 bg-blue-100 text-blue-600"
        : "hover:border hover:border-gray-300"
    }`;

  const handleCancelClick = () => {
    setEditorVisible(false);
    setContent("");
  };
  const handleEditorClick = () => {
    setEditorVisible(true);
  };
  return (
    <Grid
      sx={{ backgroundColor: "background.default" }}
      className="w-full h-fit flex p-3 gap-3 justify-start items-center rounded-lg border border-gray-800"
    >
 
      <div className="">
        {!isEditorVisible ? (
          <div
            style={{
              backgroundColor: mode === "light" ? "white" : "transparent",
              // border:
              //   mode === "dark" ? "1px solid #1e2734" : "1px solid #d7e2ed",
            }}
            className="w-full p-3 flex items-center gap-2 rounded cursor-text"
            onClick={handleEditorClick}
          >
            <div className="w-10 h-10 rounded-md flex items-center justify-center mr-2">
              <img
                src="/images/no-user.png"
                className="rounded-md"
                alt="User"
              />
            </div>
            <span className="text-gray-500">Type @ to mention someone</span>
          </div>
        ) : (
          <div
            style={{
              backgroundColor: mode === "light" ? "white" : "transparent",
              // border:
              //   mode === "dark" ? "1px solid #1e2734" : "1px solid #d7e2ed",
            }}
            className="w-full flex items-start p-3  rounded-lg shadow-sm "
          >
            {/* User Profile + Input Box in the same line */}
            <div className="w-10 h-10 rounded-md flex items-center justify-center">
              <img
                src="/images/no-user.png"
                className="rounded-md"
                alt="User"
              />
            </div>
            <div className="flex flex-col px-3 w-[95%] gap-3">
              {/* Editable Text Area with Placeholder */}
              <div className="w-[99%] relative">
                <div
                  style={{
                    border:
                      mode === "dark"
                        ? "1px solid #1e2734"
                        : "1px solid #d7e2ed",
                    wordWrap: "break-word",
                    whiteSpace: "pre-wrap",
                  }}
                  ref={editorRef}
                  contentEditable
                  className="h-24 overflow-auto px-3 py-2 outline-none rounded-md"
                  onInput={handleInput}
                  onFocus={() => setIsPlaceholderVisible(false)}
                  onBlur={handleInput}
                >
                  {isPlaceholderVisible && (
                    <span className="text-gray-400">Write a comment...</span>
                  )}
                </div>

                <div
                  style={{
                    backgroundColor: mode === "dark" ? "#171717" : "#edf0f4",
                    borderTop:
                      mode === "light" ? "1px solid #fff" : "1px solid #1e2734",
                  }}
                  className="w-full"
                >
                  {/* Toolbar */}

                  <div className="flex items-center p-2 flex-wrap gap-1">
                    {/* Text Formatting */}
                    <div
                      className={getIconStyle("bold")}
                      onClick={() => handleClick("bold")}
                    >
                      <FormatBold fontSize="small" />
                    </div>
                    <div
                      className={getIconStyle("italic")}
                      onClick={() => handleClick("italic")}
                    >
                      <FormatItalic fontSize="small" />
                    </div>
                    <div
                      className={getIconStyle("underline")}
                      onClick={() => handleClick("underline")}
                    >
                      <FormatUnderlined fontSize="small" />
                    </div>
                    <div
                      className={getIconStyle("strikethrough")}
                      onClick={() => handleClick("strikeThrough")}
                    >
                      <FormatStrikethrough fontSize="small" />
                    </div>

                    {/* Font Color */}
                    <div
                      className={getIconStyle("foreColor")}
                      onClick={() => handleClick("foreColor")}
                    >
                      <FormatColorText fontSize="small" />
                    </div>

                    {/* Separator */}
                    <span className="border-l h-5 mx-2"></span>

                    {/* Lists & Alignment */}
                    <div
                      className={getIconStyle("insertUnorderedList")}
                      onClick={() => handleClick("insertUnorderedList")}
                    >
                      <FormatListBulleted fontSize="small" />
                    </div>
                    <div
                      className={getIconStyle("insertOrderedList")}
                      onClick={() => handleClick("insertOrderedList")}
                    >
                      <FormatListNumbered fontSize="small" />
                    </div>
                    <div
                      className={getIconStyle("justifyLeft")}
                      onClick={() => handleClick("justifyLeft")}
                    >
                      <FormatAlignLeft fontSize="small" />
                    </div>
                    <div
                      className={getIconStyle("justifyCenter")}
                      onClick={() => handleClick("justifyCenter")}
                    >
                      <FormatAlignCenter fontSize="small" />
                    </div>
                    <div
                      className={getIconStyle("justifyRight")}
                      onClick={() => handleClick("justifyRight")}
                    >
                      <FormatAlignRight fontSize="small" />
                    </div>

                    {/* Separator */}
                    <span className="border-l h-5 mx-2"></span>

                    {/* Attachments & Emojis */}
                    <div
                      className={getIconStyle("insertImage")}
                      onClick={() => handleClick("insertImage")}
                    >
                      <InsertPhoto fontSize="small" />
                    </div>
                    <div
                      className={getIconStyle("createLink")}
                      onClick={() => handleClick("createLink")}
                    >
                      <InsertLink fontSize="small" />
                    </div>
                    <div
                      className={getIconStyle("attachment")}
                      onClick={() => handleClick("attachment")}
                    >
                      <AttachFile fontSize="small" />
                    </div>
                    <div
                      className={getIconStyle("emoji")}
                      onClick={() => handleClick("emoji")}
                    >
                      <EmojiEmotions fontSize="small" />
                    </div>

                    {/* Superscript & Subscript */}
                    <div
                      className={getIconStyle("superscript")}
                      onClick={() => handleClick("superscript")}
                    >
                      <Superscript fontSize="small" />
                    </div>
                    <div
                      className={getIconStyle("subscript")}
                      onClick={() => handleClick("subscript")}
                    >
                      <Subscript fontSize="small" />
                    </div>
                  </div>

                  {/* Action Buttons */}
                  <div
                    style={{
                      backgroundColor: mode === "dark" ? "#171717" : "#edf0f4",
                      borderTop:
                        mode === "light"
                          ? "1px solid #fff"
                          : "1px solid #1e2734",
                    }}
                    className="p-3  flex justify-between w-full space-x-2"
                  >
                    {/* Attachment Icon at Bottom Left */}
                    <div className="flex items-center">
                      <AttachFile
                        fontSize="small"
                        className="ml-1 text-gray-600"
                      />
                    </div>

                    <div className="flex gap-3 items-center">
                      <Button
                        onClick={handleCancelClick}
                        variant="outlined"
                        size="small"
                        className="hover:bg-transparent"
                      >
                        Cancel
                      </Button>
                      <Button variant="contained" size="small" color="primary">
                        Post
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Grid>
  );
}
