import React from "react";
import { Drawer, Typography } from "@mui/material";
import { IoMdClose } from "react-icons/io";

const CustomDrawer = ({
  open,
  onClose,
  children,
  paperProps = "w-[90%] m-1 md:w-[500px] lg:w-[800px] rounded-lg",
  mode = "light",
  headerTitle
}) => {
  return (
    <Drawer
      PaperProps={{
        className: paperProps,
      }}
      anchor="right"
      open={open}
      onClose={onClose}
    >
      <div
        style={{ backgroundColor: mode === "dark" ? "#141414" : "#edf0f4" }}
        className="p-4 h-full overflow-y-auto"
      >
        {/* Header */}
        <div className="sm:flex justify-between items-center border-b pb-3">
          <Typography variant="h6">{headerTitle}</Typography>

          {/* Header Right Section (Close Button) */}
          <div className="flex items-center gap-3">
            <button
              className="p-[5px]"
              onClick={onClose}
              style={{
                backgroundColor: mode === "dark" ? "" : "#dcdcdc",
                borderRadius: "5px",
              }}
            >
              <IoMdClose size={18} />
            </button>
          </div>
        </div>

        {/* Children Content */}
        <div className="mt-3">{children}</div>
      </div>
    </Drawer>
  );
};

export default CustomDrawer;
