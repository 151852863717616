import React from 'react'
import LeaveReportees from './LeaveReportees'

export default function AllReporteesGrid({searchTerm}) {
  return (
    <>
      <div className="w-full h-full sm:grid sm:gap-3 sm:grid-cols-3 justify-center items-center place-content-center place-items-center place-self-center">
        <LeaveReportees searchTerm={searchTerm} />
        <LeaveReportees searchTerm={searchTerm} />
        <LeaveReportees searchTerm={searchTerm} />
        <LeaveReportees searchTerm={searchTerm} />
        <LeaveReportees searchTerm={searchTerm} />
      </div>
    </>
  )
}
