import { Drawer, Button, Select, MenuItem, TextField, FormControl, InputLabel, Checkbox, FormControlLabel, Box, IconButton, Autocomplete, InputAdornment, Menu } from "@mui/material";
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import React, { useState } from 'react'
import { HiOutlineCalendarDays } from 'react-icons/hi2';
import { IoIosArrowBack, IoIosArrowForward, IoMdClose } from 'react-icons/io';
import { IoCalendarOutline, IoFilter } from 'react-icons/io5';
import { TfiViewList } from 'react-icons/tfi';
import { useTheme } from '../../../style/theme';
import { FaRegCalendarAlt } from 'react-icons/fa';
import SearchIcon from "@mui/icons-material/Search";

export default function OnLeave() {
    const [view, setView] = useState("grid");
    const [openCalendar, setOpenCalendar] = useState(false);
    const [selectedDate, setSelectedDate] = useState(dayjs());
    const startOfWeek = selectedDate.startOf("isoWeek"); // Monday
    const endOfWeek = startOfWeek.add(6, "day"); // Sunday
    const formattedStart = startOfWeek.format("DD/MM/YYYY");
    const formattedEnd = endOfWeek.format("DD/MM/YYYY");
    const { mode: themeMode } = useTheme();
    const [isOpen, setIsOpen] = useState(false);
    const periodOptions = ["Last Week", "This Week", "Custom"];
    const [selectedPeriod, setSelectedPeriod] = useState("This Week");
    const [searchTerm, setSearchTerm] = useState("");
    const [department, setDepartment] = useState(["Management"]);
    const [showReportees, setShowReportees] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setSearchTerm(""); // Clear search when closing
    };

    const handlePrevWeek = () => {
        setSelectedDate(selectedDate.subtract(7, "day"));
    };

    const handleNextWeek = () => {
        setSelectedDate(selectedDate.add(7, "day"));
    };

    const [currentMonth, setCurrentMonth] = useState(dayjs());

    const handlePrevMonth = () => {
        setCurrentMonth(currentMonth.subtract(1, "month"));
    };

    const handleNextMonth = () => {
        setCurrentMonth(currentMonth.add(1, "month"));
    };

    const daysInMonth = currentMonth.daysInMonth();
    const firstDay = currentMonth.startOf("month").day();

    const calendarDays = [];
    for (let i = 0; i < firstDay; i++) {
        calendarDays.push(null);
    }
    for (let i = 1; i <= daysInMonth; i++) {
        calendarDays.push(i);
    }

    return (
        <div className="sm:p-4 p-2 h-full lg:mb-0 mb-[70px]" style={{ background: themeMode === "dark" ? "#141414" : "#f7f9fc", marginBottom: { md: "0px", xs: '70px' } }}>
            {/* header */}
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: { md: "center", xs: "start" }, flexDirection: { xs: "column", md: "row" }, marginBottom: "20px" }} className="gap-3">
                <div></div>

                <div className="flex relative items-center gap-2 md:ms-[150px]">
                    {/* Previous Week Button */}
                    <div style={{ backgroundColor: themeMode === "dark" ? "#171717" : "white" }} className="flex items-center gap-2 p-2 rounded-md">
                        <IoIosArrowBack
                            fontSize="small"
                            className="cursor-pointer hover:text-gray-600"
                            onClick={() => { view === "calendar2" ? handlePrevMonth() : handlePrevWeek() }}
                            color="#398aff"
                        />

                        {/* Calendar Icon and Date Range Text */}
                        <Box
                            className={`relative flex items-center rounded-md cursor-pointer ${themeMode === "dark" ? "hover:bg-[#191919]" : "hover:bg-gray-200"}`}
                        >
                            <IoCalendarOutline onClick={() => setOpenCalendar(!openCalendar)} size="18px" />
                        </Box>

                        {/* Next Week Button */}
                        <IoIosArrowForward
                            fontSize="small"
                            className="cursor-pointer hover:text-gray-600"
                            onClick={() => { view === "calendar2" ? handleNextMonth() : handleNextWeek() }}
                            color="#398aff"
                        />
                    </div>

                    {view === "calendar2" ? (
                        <span className="text-sm font-semibold">
                            {currentMonth.format("MMMM YYYY")}
                        </span>
                    ) : (
                        <span className="text-sm font-medium">
                            {formattedStart} - {formattedEnd}
                        </span>
                    )}

                    {/* Calendar Popup */}
                    {openCalendar && (
                        <Box sx={{ backgroundColor: themeMode === "dark" ? "#171717" : "white" }} className="absolute top-8 z-10 shadow-md border rounded-md">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateCalendar
                                    value={selectedDate}
                                    onChange={(date) => {
                                        setSelectedDate(date);
                                        setOpenCalendar(false);
                                    }}
                                />
                            </LocalizationProvider>
                        </Box>
                    )}
                </div>

                <div className="flex gap-1 md:gap-3 md:items-center" style={{ display: "flex" }}>
                    <div className={`rounded-md flex items-center gap-1  ${themeMode === "light" ? "bg-[#fff]" : ""
                        }`}>
                        <div
                            className="relative group p-1 rounded-md"
                            style={{ border: view === "grid" ? "1px solid #0088ff" : "" }}
                        >
                            <TfiViewList
                                size={18}
                                className="cursor-pointer"
                                onClick={() => setView("grid")}
                                color={view === "grid" ? "#0088ff" : ""}
                            />
                        </div>
                        <div
                            className="relative group p-1 rounded-md"
                            style={{
                                border: view === "calendar" ? "1px solid #0088ff" : "",
                            }}
                        >
                            <HiOutlineCalendarDays
                                size={20}
                                className="cursor-pointer"
                                onClick={() => setView("calendar")}
                                color={view === "calendar" ? "#0088ff" : ""}
                            />
                        </div>
                        <div
                            className="relative group p-1 rounded-md"
                            style={{
                                border: view === "calendar2" ? "1px solid #0088ff" : "",
                            }}
                        >
                            <FaRegCalendarAlt
                                size={20}
                                className="cursor-pointer"
                                onClick={() => setView("calendar2")}
                                color={view === "calendar2" ? "#0088ff" : ""}
                            />
                        </div>
                    </div>

                    <div className={`relative h-fit group p-1 rounded-md ${themeMode === "light" ? "bg-[#fff]" : ""}`}
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        <IoFilter size={20} className="cursor-pointer" />
                    </div>
                </div>
            </Box>


            {view === "grid" ? (
                <div className="mt-4">
                    <div style={{
                        backgroundColor: themeMode === "dark" ? "#141414" : "white",
                        border: themeMode === "dark" ? "" : "1px solid #d7e2ed"
                    }}
                        className="p-4 h-[350px] flex flex-col rounded-lg">

                        {/* No Data UI */}
                        <div
                            className="rounded-lg p-4 sm:p-8 flex flex-col items-center">
                            <img
                                src="/images/no_timeLogs.png"
                                alt="No Data Found"
                                className="w-48 h-48 mb-4"
                            />
                            <p className="text-gray-500 text-lg text-center">No team members on leave this week</p>
                        </div>
                    </div>
                </div>
            ) : view === "calendar2" ? (
                <>
                    {/* Calendar Grid */}
                    <div style={{
                        backgroundColor: themeMode === "dark" ? "#141414" : "white",
                        border: themeMode === "dark" ? "1px solid" : "1px solid #d7e2ed"
                    }} className="grid grid-cols-7 gap-0 sm:p-2 text-center text-gray-700 font-medium">
                        {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
                            <div key={day} className="sm:p-2 py-2">{day}</div>
                        ))}
                    </div>
                    <div className="grid grid-cols-7 gap-0">
                        {calendarDays.map((day, index) => (
                            <div style={{
                                border: themeMode === "dark" ? "1px solid #374151" : "1px solid #d7e2ed"
                            }}
                                key={index}
                                className={`sm:h-32 p-3 ${themeMode === "dark" ? "bg-[#141414]" : "bg-white"}`}
                                onClick={() => setSelectedDate(currentMonth.date(day))}
                            >
                                {day && <span className={`text-[14px] sm:text-lg ${day === selectedDate.date() &&
                                    currentMonth.isSame(selectedDate, "month")
                                    ? "bg-blue-500 p-1 px-2 rounded-lg text-white font-bold"
                                    : ""
                                    }`}>{day}</span>}
                            </div>
                        ))}
                    </div>
                </>
            ) : (
                <div className="mt-4">
                    <div style={{
                        backgroundColor: themeMode === "dark" ? "#141414" : "white",
                        border: themeMode === "dark" ? "" : "1px solid #d7e2ed"
                    }}
                        className="p-4 h-[350px] flex flex-col rounded-lg">

                        {/* No Data UI */}
                        <div
                            className="rounded-lg p-8 flex flex-col items-center">
                            <img
                                src="/images/no_timeLogs.png"
                                alt="No Data Found"
                                className="w-48 h-48 mb-4"
                            />
                            <p className="text-gray-500 text-lg">No team members on leave this week</p>
                        </div>
                    </div>
                </div>
            )
            }



            <Drawer anchor="right" open={isOpen} onClose={() => setIsOpen(false)}>
                <div style={{ backgroundColor: themeMode === "dark" ? "#191919" : "#fff" }} className="w-[350px] p-5 h-full shadow-lg relative">
                    <div className="flex justify-between items-center">
                        <h2 className="text-lg font-semibold">Filter</h2>
                        <IconButton onClick={() => setIsOpen(false)} sx={{ backgroundColor: themeMode === "light" ? "#dcdcdc" : "transparent", p: "5px", borderRadius: "5px" }}>
                            <IoMdClose size={18} />
                        </IconButton>
                    </div>

                    <div className="mt-5 space-y-2">
                        {/* Period Dropdown */}
                        <FormControl fullWidth>
                            <label className="block text-sm font-medium mb-1">Period</label>
                            <Select
                                size="small"
                                value={selectedPeriod}
                                onOpen={handleOpen}
                                onClose={handleClose}
                                onChange={(e) => setSelectedPeriod(e.target.value)}
                                open={Boolean(anchorEl)}
                                MenuProps={{ disableScrollLock: true }} // Prevents background from scrolling
                            >
                                {/* Search Bar Inside Dropdown */}
                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                    PaperProps={{ style: { width: anchorEl?.clientWidth } }} // Matches select width
                                >
                                    <div style={{ padding: "8px" }}>
                                        <TextField
                                            placeholder="Search..."
                                            fullWidth
                                            variant="outlined"
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                            InputProps={{
                                                startAdornment: (
                                                    <IconButton size="small" edge="start">
                                                        <SearchIcon />
                                                    </IconButton>
                                                ),
                                            }}
                                        />
                                    </div>

                                    {/* Filtered Menu Items */}
                                    {periodOptions
                                        .filter((option) => option.toLowerCase().includes(searchTerm.toLowerCase()))
                                        .map((option) => (
                                            <MenuItem key={option} onClick={() => { setSelectedPeriod(option); handleClose(); }}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                </Menu>
                            </Select>
                        </FormControl>
                        {/* Date Fields */}
                        <div>
                            <label className="block text-sm font-medium mb-1">From</label>
                            <TextField
                                type="date"
                                defaultValue="2025-03-02"
                                fullWidth
                                size="small"
                                disabled
                                className={`${themeMode === "light" ? "bg-gray-100" : "bg-[#171717]"}`}
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium mb-1">To</label>
                            <TextField
                                type="date"
                                defaultValue="2025-03-08"
                                fullWidth
                                disabled
                                size="small"
                                className={`${themeMode === "light" ? "bg-gray-100" : "bg-[#171717]"}`}
                            />
                        </div>

                        {/* Department Multi-Select */}
                        <div>
                            <label className="block text-sm font-medium mb-1">Department</label>
                            <FormControl fullWidth>
                                <TextField
                                    type="text"
                                    fullWidth
                                    size="small"
                                    placeholder="All Departments"
                                />
                            </FormControl>

                            {/* Show Only Reportees Checkbox */}
                            <FormControlLabel
                                control={<Checkbox checked={showReportees} onChange={(e) => setShowReportees(e.target.checked)} />}
                                label="Show only reportees"
                            />
                        </div>
                    </div>

                    {/* Buttons Section */}
                    <div className="flex py-3 gap-2 absolute bottom-0">
                        <Button variant="contained" className="!bg-blue-600 text-white px-6">
                            Apply
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => { setDepartment(["Management"]); setShowReportees(false); }}
                            className="border-gray-400 text-gray-700 px-5"
                        >
                            Reset
                        </Button>
                    </div>
                </div>
            </Drawer>

        </div>
    )
}
