import {
  Popover,
  Box,
  Typography,
  IconButton,
  Button,
} from "@mui/material";
import { Close } from "@mui/icons-material";

const CustomFilter = ({ anchorEl, onClose, content, title = "Filter Options" }) => {
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      transformOrigin={{ vertical: "bottom", horizontal: "center" }}
      // sx={{ height: "100vh" }}
    >
      <Box
        p={2}
        sx={{
          minWidth: 350,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6">{title}</Typography>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Box>
      <Box sx={{ padding: 2 }}>{content}</Box>
      <Box sx={{ padding: 2, display: "flex", gap: 2 }}>
        <Button variant="contained" onClick={onClose}>
          Apply
        </Button>
        <Button variant="outlined" onClick={onClose}>
          Reset
        </Button>
      </Box>
    </Popover>
  );
};

export default CustomFilter;
