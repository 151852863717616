import { useEffect, useState } from "react";
import { useSwitchScreen } from "../../hooks/SwitchScreenContext";
import { Tabs, Tab, useMediaQuery, useTheme } from "@mui/material";
import LeaveSummary from "../LeaveTracker/MyData/LeaveSummary";
import LeaveBalance from "../LeaveTracker/MyData/LeaveBalance";
import LeaveRequests from "../Operations/LeaveTracker/LeaveRequests";
import CompensatoryRequest from "../LeaveTracker/MyData/CompensatoryRequest";
import Shift from "../Operations/Shift/Shift";
import Reportees from "../Dashboard/Team/Reportees";
import OnLeave from "../LeaveTracker/Team/OnLeave";
import Timesheets from "../Operations/TimeTracker/Timesheets";
import Jobs from "../Operations/TimeTracker/Jobs";
import Projects from "../Operations/TimeTracker/Projects";
import JobSchedule from "../Operations/TimeTracker/JobSchedule/JobSchedule";
import Clients from "../Operations/TimeTracker/Clients";
import ProjectMembers from "./Team/ProjectMembers";
import TimeLogs from "../TimeTracker/myData/TimeLogs";
import NoEmployee from "../../components/NoEmployee";

export default function TimeTrackerHome() {
  const [available, setAvailable] = useState(false)
  const pathname = window.location.pathname;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  let currentOrg = JSON.parse(localStorage.getItem("org"));

  useEffect(() => {
    if (pathname === "/timeTracker/myData") {
      setSwitchScreen({ first: "myData", second: "timeLogs" });
    } else if (pathname === "/timeTracker/team") {
      setSwitchScreen({ first: "team", second: "reportees" });
    }

  }, [pathname]);

  const [switchScreen, setSwitchScreen] = useState({
    first: "myData",
    second: "timeLogs",
  });

  const handleSwitchScreen = (screen) => {
    setSwitchScreen(screen);
  };

  // Fix: Ensure all tabs have a `value` property instead of `second`
  const tabAdminMappings = {
    myData: [
      { label: "Time Logs", value: "timeLogs" },
      { label: "Timesheets", value: "timesheets" },
      { label: "Jobs", value: "jobs" },
      { label: "Projects", value: "projects" },
      { label: "Job Schedule", value: "jobSchedule" },
    ],
    team: [
      { label: "Reportees", value: "reportees" },
      { label: "Project Members", value: "projectMembers" },
      { label: "Timesheets", value: "timesheets" },
      { label: "Jobs", value: "jobs" },
      { label: "Projects", value: "projects" },
      { label: "Clients", value: "clients" },
      { label: "Job Schedule", value: "jobSchedule" },
    ]
  };


  const tabEmployeeMappings = {
    myData: [
      { label: "Time Logs", value: "timeLogs" },
      { label: "Timesheets", value: "timesheets" },
      { label: "Jobs", value: "jobs" },
      { label: "Projects", value: "projects" },
      { label: "Job Schedule", value: "jobSchedule" },
    ]
  };
  let tabMappings = currentOrg?.type == "Owner" ? tabAdminMappings : tabEmployeeMappings
  const renderTabs = () => {
    const currentTabs = tabMappings[switchScreen.first] || [];

    return (
      <Tabs
        value={currentTabs.findIndex((tab) => tab.value === switchScreen.second)} // Fix: `value` should be `tab.value`
        onChange={(event, newValue) => {
          const selectedTab = currentTabs[newValue];
          handleSwitchScreen({
            first: switchScreen.first,
            second: selectedTab.value, // Fix: Use `value` instead of `second`
          });
        }}
        textColor="primary"
        indicatorColor="primary"
        aria-label="Navigation Tabs"
        variant={isMobile ? "scrollable" : "standard"}
        TabIndicatorProps={{
          sx: { bottom: "8px" },
        }}
      >
        {currentTabs.map((tab, index) => (
          <Tab
            key={index}
            label={tab.label}
            sx={{
              fontSize: { xs: "12px", md: "14px" },
              minWidth: { xs: "20px", md: "auto" },
              paddingLeft: { md: "15px", xs: 1 },
              paddingRight: { md: "15px", xs: 1 },
            }}
          />
        ))}
      </Tabs>
    );
  };

  return (
    <>
      {currentOrg?.type !== "Pending Onboarding" ? (<>
        <div className="flex flex-row gap-3 rounded-lg">{renderTabs()}</div>

        {/* Fix: Remove redundant conditional checks and simplify */}
        {/* {switchScreen.first === "myData" && switchScreen.second === "timeLogs" && <TimeLogs show = {true}/>} */}
        {switchScreen.first === "myData" && switchScreen.second === "timeLogs" &&
          <div className='px-5 mt-[30px]'>
            <TimeLogs />
          </div>
        }
        {switchScreen.first === "myData" && switchScreen.second === "timesheets" && <Timesheets />}
        {switchScreen.first === "myData" && switchScreen.second === "jobs" && <Jobs />}
        {switchScreen.first === "myData" && switchScreen.second === "projects" && <Projects />}
        {switchScreen.first === "myData" && switchScreen.second === "jobSchedule" && <JobSchedule />}

        {switchScreen.first === "team" && switchScreen.second === "reportees" && <div className="mt-7"><Reportees /></div>}
        {switchScreen.first === "team" && switchScreen.second === "projectMembers" && <ProjectMembers />}
        {switchScreen.first === "team" && switchScreen.second === "timesheets" && <Timesheets />}
        {switchScreen.first === "team" && switchScreen.second === "jobs" && <Jobs />}
        {switchScreen.first === "team" && switchScreen.second === "projects" && <Projects />}
        {switchScreen.first === "team" && switchScreen.second === "clients" && <Clients />}
        {switchScreen.first === "team" && switchScreen.second === "jobSchedule" && <JobSchedule />}
      </>) : (<NoEmployee />)}
    </>
  );
}
