import { Button, Drawer, FormControl, MenuItem, Select, useTheme } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useState } from "react";
import { CgClose } from "react-icons/cg";
import Grid from "@mui/material/Grid2";

const departmentoptions = [
  { value: "HR", label: "HR" },
  { value: "Management", label: "management" },
  { value: "Marketing", label: "marketing" },
];
const reportingoptions = [
  { value: "1 Tanya gwilsbry", label: "1 Tanya gwilsbry" },
  { value: "S2 Lilly Williams", label: "S2 Lilly Williams" },
  { value: "S3 Clarkson Walter", label: "S3 Clarkson Walter" },
];
const designationoptions = [
  { value: "CEO", label: "CEO" },
  { value: "Administration", label: "Administration" },
  { value: "Manager", label: "Manager" },
  { value: "Assistant Manager", label: "Assistant Manager" },
  { value: "Team Member", label: "Team Member" },
];
const locationoptions = [];

const DesignationChangeDrawer = ({ openDesignation, setOpenDesignation }) => {
  const { mode } = useTheme();
  const [selectedDate, setSelectedDate] = useState("");
  return (
    <div>
      {/* DesignationDrawer */}
      <Drawer
        anchor="right"
        open={openDesignation}
        onClose={() => setOpenDesignation(false)}
      >
        <div
          style={{ backgroundColor: mode === "dark" ? "#141414" : "#fff" }}
          className={`relative md:w-[800px] w-full h-screen md:h-[200vh] p-[16px] flex flex-col`}
        >
          <div
            style={{ backgroundColor: mode === "dark" ? "#141414" : "#edf0f4" }}
            className={`flex sticky justify-between top-0 left-0 z-10   p-[16px] mb-5`}
          >
            <h2 className="text-xl font-semibold ">Designation Change</h2>

            <div
              style={{
                backgroundColor: mode === "dark" ? "#141414" : "#edf0f4",
              }}
              className={` p-2 rounded-md cursor-pointer`}
              onClick={() => setOpenDesignation(false)}
            >
              <CgClose />
            </div>
          </div>

          <div
            style={{ backgroundColor: mode === "dark" ? "#141414" : "#edf0f4" }}
            className={` rounded-lg shadow-md pb-3`}
          >
            <p className="p-[16px] font-medium">HR Process request details</p>
            <hr />
            <div className="px-[16px]">
              <div className="text-[#9b9b9b] mt-2 ">Employee:</div>

              <div
                style={{
                  backgroundColor: mode === "dark" ? "#141414" : "#edf0f4",
                }}
                className={`mt-4 flex gap-2 items-center rounded-md p-3`}
              >
                <div>
                  <img
                    src="/images/no-user.png"
                    alt="profile"
                    style={{ width: "30px", height: "30px" }}
                  />
                </div>
                <div>
                  <p className="font-semibold text-[14px]">
                    <span className="text-[#666666]">1</span> - Tanya Gwilsbry
                  </p>
                  <p className="text-[14px] text-gray-600">
                    tanyagwilsbry@gmail.com
                  </p>
                </div>
              </div>

              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid size={{ xs: 6, md: 12 }} sx={{ mt: 2 }}>
                  <p className="text-[15px] font-[500] text-[#666666] pb-3 block">
                    {" "}
                    Select New Designation:
                  </p>
                  <FormControl fullWidth>
                    <Select
                      name="designation"
                      size="small"
                      variant="outlined"
                      value={""}
                    >
                      {designationoptions.map((dept, index) => (
                        <MenuItem key={index} value={dept.value}>
                          {dept.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </div>
          </div>

          <div
            style={{ backgroundColor: mode === "dark" ? "#141414" : "#edf0f4" }}
            className={` rounded-lg shadow-md pb-3 mt-4`}
          >
            <p className="p-[16px] font-medium">Process date and reason</p>
            <hr />

            <div className="px-[16px]">
              <div className="mt-4">
                <label className="text-[15px] font-[500] text-[#666666] pb-3 block">
                  Select effective date:
                </label>
                <div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      //   label="Select Date"
                      //   value={selectedDate}
                      //   onChange={(newValue) => setSelectedDate(newValue)}
                      format="DD-MMM-YYYY"
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          sx: {
                            height: 32,
                            "& .MuiInputBase-root": { height: 32 },
                          },
                        },
                      }}
                    />
                  </LocalizationProvider>
                </div>
              </div>

              <div className="mt-4">
                <label
                  className="text-[15px] font-[500] text-[#666666] pb-3 block"
                  htmlFor=""
                >
                  Enter the reason for change:
                </label>
                <textarea
                  style={{
                    backgroundColor: mode === "dark" ? "transparent" : "#ffff",
                  }}
                  className={`border border-[#cccccc]  h-[75px] outline-none block  w-full rounded-md p-2`}
                />
              </div>
            </div>
          </div>

          <div
            style={{ backgroundColor: mode === "dark" ? "#141414" : "#edf0f4" }}
            className={` pb-3 mt-4 sticky bottom-0 left-0 w-full p-3 gap-2 flex z-10`}
          >
            <Button
              variant="contained"
              sx={{ bgColor: "#0088ff", width: "74px" }}
            >
              Submit
            </Button>
            <button
              style={{
                backgroundColor:
                  mode === "light" ? "#ffffff" : "bg-transparent",
                width: "74px",
              }}
              onClick={() => setOpenDesignation(false)}
              className="mr-2 rounded-md border border-[##dcdcdc]"
            >
              Cancel
            </button>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default DesignationChangeDrawer;
