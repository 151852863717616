import { useEffect, useState, useCallback } from 'react';
import io from 'socket.io-client';
import { env } from '../utilities/function';
import { useUser } from './Authorize';
import axios from 'axios';

const useEmployeeSocket = () => {
    const [socket, setSocket] = useState(null);
    const [messages, setMessages] = useState({ });
    const [chatList, setChatList ] =  useState([])
    const [contacts, setContacts] = useState([]);
    const [visibleTab, setVisibleTab] = useState('chat');
    
    const [totalNotification, setTotalNotification] = useState([]);
    const platformUser = useUser();

    let currentOrg = localStorage.getItem("org");
    if (currentOrg) {
        currentOrg = JSON.parse(currentOrg);
    }
    let currentEmp = localStorage.getItem("emp");
    if (currentEmp) {
        currentEmp = JSON.parse(currentEmp);
    }

    const fetchContactList = useCallback(async () => {
        // setJobs(null);
        try {
          const response = await axios.get(
            `/employee/message/contact?empId=${currentEmp?._id}&page=1&limit=200`
          );
          const data = response.data;
          setContacts(data.contact);
        } catch (e) {
          console.warn(e);
        }
      }, []);

    const fetchChatList  = useCallback(
        async () => {
            // setJobs(null);
            try {
                const response = await axios.get(
                    `/employee/message?empId=${currentEmp?._id}&page=1&limit=200`
                );
                const data = response.data;
                if(data.success){
                    setChatList(data.messages)
                }
            } catch (e) {
                console.warn(e);
            }
        },
        []
    );

    const getUnreadNotification = useCallback(async () => {
            try {
                const response = await axios.get(
                    `/employee/message/unreadnotification`
                );
                setTotalNotification(response?.data?.totalUnread);
    
                console.log('response?.data?.totalUnread',response?.data?.totalUnread)
            } catch (e) {
                console.log(e);
            }
        }, [setTotalNotification]);

    const getReceiverName  = ( sender,receiver, message ,createdAt,allcontacts) =>{
        let receiverName  = allcontacts.find((contact) => contact._id === receiver._id);
        let newChatList  =  chatList.filter( chat => chat?.receiver._id !== sender._id);

        const updatedMessages = newChatList.filter(msg => msg.receiver._id !== receiver._id);

        setChatList([
             {
            "_id": newChatList.length ,
            "sender": sender,
            "receiver": (receiver && receiver.firstName) ? receiver : receiverName,
            "content": message,
            "isViewed": false,
            createdAt
        },...updatedMessages])
    }


    useEffect(() => {
        // Initialize the socket connection
       if(currentOrg?.type == "Member")
       {
        const newSocket = io(env('SERVER')); // Replace with your server URL
        setSocket(newSocket);

        // Register the user once on connection
        if (platformUser._id) {
            newSocket.emit('registerUser', platformUser._id);
            fetchContactList();
            fetchChatList();
            getUnreadNotification();
        }

        // Handle incoming messages
        newSocket.on('receiveChat', ({ _id="",sender, receiver, content }) => {
            let createdAt =   new Date()
            
            setMessages((prevMessages) => {
                const previousChat = prevMessages[sender] || [];
                // return { ...prevMessages, [sender]: [...previousChat, { sender, receiver, content  , createdAt}] };
                return [...previousChat,{ sender, receiver, content  , createdAt}];
            });
            
            const newNotificationData = 
            {
                "_id": _id,
                "sender": sender._id,
                "receiver": receiver._id,
                "content": content,
                "isViewed": false,
                createdAt
            }

            setTotalNotification((prevNotifications) => {
                const updatedNotifications = [newNotificationData, ...prevNotifications];
                console.log("Updated Notifications (Member):", updatedNotifications);
                return updatedNotifications;
              });               
            
            getReceiverName(sender, receiver ,content ,createdAt,contacts )

        });

        // Cleanup on unmount
        return () => {
            newSocket.disconnect();
        };
       }
        
    }, []);

    // Function to send a private message
    const sendMessage = useCallback((receiver, content) => {
        if (socket) {
           let createdAt =   new Date()
           
            socket.emit('sendChat', {
                sender: platformUser._id,
                receiver,
                employeeId:currentEmp?._id,
                content: content,
            });
           
            setMessages((prevMessages) => {
                const previousChat = prevMessages[receiver] || [];
                return { ...prevMessages, [receiver]: [...previousChat, { sender:  platformUser._id, receiver, content ,createdAt }] };
            });
            getReceiverName(platformUser,{_id:receiver},content ,createdAt, contacts )

        }
    }, [socket,contacts]);

    const setMessage = useCallback((oldMessage , receiverId) => {
             setMessages({...messages , receiverId : [ ...oldMessage ,...messages[receiverId] ]})
    }, [socket]);
    
    return { messages, sendMessage ,  setMessage ,totalNotification,setTotalNotification,  contacts  ,chatList ,
        visibleTab, setVisibleTab,getUnreadNotification};
};

export default useEmployeeSocket;
