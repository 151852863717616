import { Box, IconButton } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import CallIcon from "@mui/icons-material/Call";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import SendIcon from "@mui/icons-material/Send";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import axios from "axios";
import io from 'socket.io-client';
import { env, formatTimestamp, formatDateTimestamp } from "../../utilities/function";
import { useTheme } from "../../style/theme";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import KeyboardVoiceOutlinedIcon from '@mui/icons-material/KeyboardVoiceOutlined';
import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import { CiSearch, CiVideoOn } from "react-icons/ci";
import { IoCallOutline } from "react-icons/io5";
import { AiOutlineSend } from "react-icons/ai";
import useEmployeeSocket from "../../hooks/useEmployeeSocket";

// Connect to the server
// const socket = io(env('SERVER'));

const ChatSection = ({ currentChatUser, closeModal, messages, sendMessage }) => {
  const { mode } = useTheme();
  const [chats, setChats] = useState([])
  const lastMessageRef = useRef(null);


  const { getUnreadNotification } = useEmployeeSocket();
  const [userId, setUserId] = useState('1234564656545');
  const [receiverId, setReceiverId] = useState('');
  const [message, setMessage] = useState('');
  const textareaRef = useRef(null);
  const [rows, setRows] = useState(1);

  let currentEmp = localStorage.getItem("emp");
    if (currentEmp) {
        currentEmp = JSON.parse(currentEmp);
    }
  // useEffect(() => {
  //     // Listen for incoming private messages
  //     registerUser();
  //     socket.on('privateMessage', ({ senderId, message }) => {
  //       setChats((prevChat) => [...prevChat, `From ${senderId}: ${message}`]);
  //     });

  //     // Cleanup on component unmount
  //     return () => socket.off('privateMessage');
  // }, []);

  // const registerUser = () => {
  //     // Register the user ID with the server
  //     console.log("register User")
  //     socket.emit('registerUser', userId);
  // };

  // const sendMessage = (e) => {
  //     e.preventDefault();

  //     // Send the message to the server
  //     socket.emit('privateMessage', { senderId: userId, receiverId, message });

  //     // Add the message locally for the sender's view
  //     setChats((prevChat) => [...prevChat, `To ${receiverId}: ${message}`]);

  //     // Clear the input field
  //     setMessage('');
  // };
  const handelSendMessage = (e) => {
    if (message === "") {
      toast.error('Type a message to send');
      return;
    } else {
      sendMessage(currentChatUser._id, message);
      setMessage('');
    }
  };

  const scrollToBottom = () => {
    lastMessageRef.current?.scrollIntoView({ behavior: 'smooth', block: "end", inline: "nearest" });
  };
  let page = 1;

  const triggerRightArrowKey = () => {
    const rightArrowEvent = new KeyboardEvent("keydown", {
      key: "ArrowRight",
      code: "ArrowRight",
      keyCode: 39, // Deprecated but still required for some browsers
      which: 39,   // Deprecated but still required for some browsers
      bubbles: true, // Ensure the event bubbles up
      cancelable: true, // Allow event to be cancelable
    });

    // Dispatch the event on the target element (document in this case)
    document.dispatchEvent(rightArrowEvent);
  };

  const handleKeyDown = (event) => {
    // If Shift + Enter is pressed, add a new line
    if (event.key === 'Enter' && event.shiftKey) {
      event.preventDefault(); // Prevent default behavior (submit or new line)
      const newMessage = message + '\n'; // Append a new line
      setMessage(newMessage); // Update the state with the new message

      // Focus the textarea and move the cursor to the new line
      setTimeout(() => {
        const textarea = textareaRef.current;
        textarea.focus();
        // Move cursor to the end of the new line
        textarea.selectionStart = textarea.selectionEnd = newMessage.length; // Move cursor to the end
      }, 0); // Use timeout to ensure cursor is placed after re-render
    }
    else if (event.key === 'Enter') {
      event.preventDefault(); // Prevent the default behavior of Enter (like submitting a form)
      if (message.trim()) {
        handelSendMessage();
      }
    }
  };

  // Function to adjust the height of the textarea dynamically
  const adjustHeight = () => {
    const lines = message.split('\n').length; // Count the number of lines
    if (lines > 1) {
      setRows(2); // Set rows to 2 if there are more than 1 line
    } else {
      setRows(1); // Set rows to 1 if there's only 1 line
    }
  };

  const renderTextWithLinks = (text) => {
    if (!text) return text; // Return as is if empty or undefined

    const urlRegex = /(https?:\/\/[^\s]+|www\.[^\s]+|[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/[^\s]*)?)/g;

    return text.split(urlRegex).map((part, index) => {
      if (!part) return ""; // Handle undefined cases from split

      // Check if the part is a URL
      const isURL = urlRegex.test(part);
      if (isURL) {
        const hasProtocol = part.startsWith("http://") || part.startsWith("https://");
        const href = hasProtocol ? part : `https://${part}`; // Add https if missing

        return (
          <a key={index} href={href} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
            {part}
          </a>
        );
      }

      return part; // Return non-link text as is
    });
  };
  // console.log("currentChatUser from newschatsection", messages);

  const fetchChatDetails = useCallback(
    async () => {
      // setJobs(null);
      try {
        const response = await axios.get(
          `/employee/message/details?receiver=${currentChatUser._id}&empId=${currentEmp?._id}&page=${page}&limit=0`
        );
        const data = response.data;
        setChats(data.messages);
        getUnreadNotification();
      } catch (e) {
        console.warn(e);
      }
    },
    [currentChatUser]
  );

  useEffect(() => {
    fetchChatDetails();
  }, [fetchChatDetails]);

  useEffect(() => {
    adjustHeight();
  }, [message]);

  useEffect(() => {
    scrollToBottom();
    console.log('messagesmessages=>', messages);
  }, [messages]);


  useEffect(() => {
    console.log('chatschats=>', chats);
  }, [chats]);


  let img = "https://images.unsplash.com/photo-1605993439219-9d09d2020fa5?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjB8fHVzZXIlMjBwcm9maWxlfGVufDB8fDB8fHww";


  let chatsOld = [{
    type: "receiver",
    message: `Lorem ipsum dolor sit, amet consectetur adipisicing elit. Et
    temporibus ipsam corrupti? Consequuntur eveniet excepturi, iste,
    voluptate inventore autem dolores quos veritatis ex cum nemo
    cumque obcaecati odio aperiam natus.`
  },

  {
    type: "sender",
    message: `Lorem ipsum dolor sit, amet consectetur adipisicing elit. Et
    temporibus ipsam corrupti? Consequuntur eveniet excepturi, iste,
    voluptate inventore autem dolores quos veritatis ex cum nemo
    cumque obcaecati odio aperiam natus.`
  },
  {
    type: "receiver",
    message: `Lorem ipsum dolor sit, amet consectetur adipisicing elit. Et
    temporibus ipsam corrupti? Consequuntur eveniet excepturi, iste,
    voluptate inventore autem dolores quos veritatis ex cum nemo
    cumque obcaecati odio aperiam natus.`
  },

  {
    type: "sender",
    message: `Lorem ipsum dolor sit, amet consectetur adipisicing elit. Et
    temporibus ipsam corrupti? Consequuntur eveniet excepturi, iste,
    voluptate inventore autem dolores quos veritatis ex cum nemo
    cumque obcaecati odio aperiam natus.`
  },
  {
    type: "receiver",
    message: `Lorem ipsum dolor sit, amet consectetur adipisicing elit. Et
    temporibus ipsam corrupti? Consequuntur eveniet excepturi, iste,
    voluptate inventore autem dolores quos veritatis ex cum nemo
    cumque obcaecati odio aperiam natus.`
  },

  {
    type: "sender",
    message: `Lorem ipsum dolor sit, amet consectetur adipisicing elit. Et
    temporibus ipsam corrupti? Consequuntur eveniet excepturi, iste,
    voluptate inventore autem dolores quos veritatis ex cum nemo
    cumque obcaecati odio aperiam natus.`
  }


  ]

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success("Copied to clipboard!");
    }).catch(() => {
      toast.error("Failed to copy");
    });
  };

  return (
    <>
      <div className={`overflow-hidden relative flex flex-col mx-2`}>
        <div className="flex md:border-b md:border-b-[#3F3F3F] my-4 md:my-0 py-2 justify-between items-center">
          <div className="flex md:mx-2 items-center gap-2.5 ">
            <div className="flex gap-4 items-center ">
              <div
                className="md:hidden"
                onClick={() => {
                  closeModal();
                }}
              >
                <ArrowBackIosIcon sx={{ width: "22px", height: "22px" }} />
              </div>
              <div className="h-[32px] w-[32px] md:h-[40px]  md:w-[40px] relative">
                <img
                  className="w-full h-full rounded-full object-cover object-top"
                  src={img}
                  alt=""
                />
                <p className="h-[7px] w-[7px]  bg-blue-500 border p-[2px]  rounded-full absolute bottom-[4px] md:bottom-[3px] md:right-[1px] right-[-1px]"></p>
              </div>
            </div>
            <div className="font-bold">
              <div className="text-[12px] hidden md:flex">
                {currentChatUser?.firstName ? currentChatUser?.firstName : "N/a"}{" "} {currentChatUser?.lastName && currentChatUser?.lastName}
              </div>
              <div className="text-[12px] md:hidden">
                {currentChatUser?.firstName && currentChatUser?.lastName &&
                  (currentChatUser.firstName.length + currentChatUser.lastName.length > 8)
                  ? `${currentChatUser.firstName.substring(0, 8)}...`
                  : `${currentChatUser?.firstName || "N/A"} ${currentChatUser?.lastName || ""}`}
              </div>

              <p className="text-[#3767B1] text-xs md:text-[11px]">Active</p>
            </div>
          </div>
          <ul className="flex items-center gap-2 md:gap-2 ">
            {" "}
            <li>
              <IconButton
                type="button"
                sx={{
                  color: 'white',
                  p: "5px",
                  background: '#1F1F1F',
                  borderRadius: '4px',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.05)',
                  },
                }}
                aria-label="search"
              >
                <IoCallOutline size={18} />
              </IconButton>
            </li>
            <li>
              <IconButton
                type="button"
                sx={{
                  p: '5px',
                  color: 'white',
                  borderRadius: '4px',
                  background: '#1F1F1F',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.05)',
                  },
                }}
                aria-label="search"
              >
                <CiVideoOn size={18} />
              </IconButton>
            </li>
            {/* <li>
                       <IconButton
                         type="button"
                         sx={{
                           // p: '10px',
                           color: 'white', 
                           borderRadius: '4px',
                           background: '#1e1e1e',
                           '&:hover': {
                             backgroundColor: 'rgba(0, 0, 0, 0.05)', 
                           },
                         }}
                         aria-label="search"
                       >
                         <DeleteOutlineIcon sx={{ fontSize: "17px", cursor: "pointer" }} onClick={(e) => { clearSelectedChat(); setDeleteActivated(!deleteActivated); }} />
                       </IconButton>
                     </li> */}
            <li>
              {" "}
              <IconButton
                type="button"
                sx={{
                  p: '5px',
                  color: 'white',
                  borderRadius: '4px',
                  background: '#1F1F1F',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.05)',
                  },
                }}
                aria-label="search"
              >
                <CiSearch size={18} />
              </IconButton>
            </li>
            <li>

              <MoreHorizIcon sx={{ fontSize: "20px" }} />

            </li>
          </ul>
        </div>
        <p className="h-[1px] md:hidden bg-[#111111] w-full"></p>

        <Box style={{ height: 'calc(100vh - 226px)' }} className="overflow-y-auto mt-[14px] px-2 mb-2 md:mt-0 no-scrollbar"
        >
          {chats?.map((chat, index) => (
            <div key={index} className="w-full md:text-xs
            text-sm font-bold">
              {(chat?.sender == currentChatUser?._id) ?
                <div className="flex flex-row w-full mt-[25px] justify-start">
                  {/* <div>
                    <div className="h-[32px] w-[32px] md:h-[40px]  md:w-[50px]">
                      <img
                        className="w-full h-full rounded-full object-cover object-top"
                        src={img}
                        alt=""
                      />
                    </div>
                  </div> */}
                  <div className="relative px-3.5 w-max-fit ">
                    <div className='absolute top-0 left-[1px]'>
                      <img src="/images/chatLeft.png" alt="" />
                    </div>
                    <div
                      className={`${mode === "dark" ? "bg-[#1E1E1E]" : "border-2 bg-[#EEEEEE]"
                        }  px-[12px] py-[8px] rounded-[7px] w-max-fit w-[90%] break-words  relative z-20 md:leading-[17px] chat-message`}
                      onDoubleClick={() => copyToClipboard(chat?.content)}
                    >
                      {renderTextWithLinks(chat?.content)}
                      <p className="text-[8px] text-[#C8C8C8] leading-[12px] w-[100px]">{formatDateTimestamp(chat.createdAt)}</p>
                    </div>
                  </div>
                </div>
                :
                <div className="flex flex-row w-full mt-[25px] justify-end">
                  <div className="text-right w-max-fit relative px-3.5 flex justify-end">
                    <div className='absolute top-0 right-[1px]'>
                      <img src="/images/chatRight.png" alt="" />
                    </div>
                    <div
                      className={`${mode === "dark" ? "bg-[#3767b1]" : "bg-[#51A0D5]"
                        } px-[12px] py-[8px] rounded-[7px] w-max-fit w-[90%] break-words relative z-20  md:leading-[17px]  chat-message flex flex-col justify-end`}
                      onDoubleClick={() => copyToClipboard(chat?.content)}
                    >
                      {renderTextWithLinks(chat?.content)}
                      <p className="text-[8px] text-[#C8C8C8] text-end pe-[12px] ml-auto leading-[12px] w-[100px]">{formatDateTimestamp(chat.createdAt)}</p>
                    </div>
                  </div>
                  {/* <div>
                    <div className="h-[32px] w-[32px] md:h-[40px]  md:w-[50px] ">
                      <img
                        className="w-full h-full rounded-full object-cover object-top"
                        src={img}
                        alt=""
                      />
                    </div>
                  </div> */}
                </div>}
            </div>
          ))}
          {messages[currentChatUser?._id]?.map((chat, index) => (
            <div key={index} className="md:text-xs text-sm font-bold">
              {(chat?.sender == currentChatUser?._id) ?
                <div className="flex flex-row w-full mt-[25px] justify-start">
                  {/* <div>
                    <div className="h-[32px] w-[32px] md:h-[40px]  md:w-[50px]">
                      <img
                        className="w-full h-full rounded-full object-cover object-top"
                        src={img}
                        alt=""
                      />
                    </div>
                  </div> */}
                  <div className="relative px-3.5 w-max-fit ">
                    <div className='absolute top-0 left-[1px]'>
                      <img src="/images/chatLeft.png" alt="" />
                    </div>
                    <div
                      className={`${mode === "dark" ? "bg-[#1E1E1E]" : "border-2 bg-[#EEEEEE]"
                        }   px-[12px] py-[8px] rounded-[7px] w-max-fit w-[90%] break-words  relative z-20 md:leading-[17px] chat-message`}
                      onDoubleClick={() => copyToClipboard(chat?.content)}
                    >
                      {renderTextWithLinks(chat?.content)}
                      <p className="text-[8px] text-[#C8C8C8] leading-[12px] w-[100px]">{formatDateTimestamp(chat.createdAt)}</p>
                    </div>
                  </div>
                </div>
                :
                <div className="flex flex-row w-full mt-[25px] justify-end">
                  <div className="text-right w-max-fit relative px-3.5 flex justify-end">
                    <div className='absolute top-0 right-[1px]'>
                      <img src="/images/chatRight.png" alt="" />
                    </div>
                    <div
                      className={`${mode === "dark" ? "bg-[#3767b1]" : "bg-[#51A0D5]"
                        }  px-[12px] py-[8px] rounded-[7px] w-max-fit w-[90%] break-words relative z-20  md:leading-[17px]  chat-message flex flex-col justify-end`}
                      onDoubleClick={() => copyToClipboard(chat?.content)}
                    >
                      {renderTextWithLinks(chat?.content)}
                      <p className="text-[8px] text-[#C8C8C8] text-end pe-[12px] ml-auto leading-[12px] w-[100px]">{formatDateTimestamp(chat.createdAt)}</p>
                    </div>
                  </div>
                  {/* <div>
                    <div className="h-[32px] w-[32px] md:h-[40px]  md:w-[50px] ">
                      <img
                        className="w-full h-full rounded-full object-cover object-top"
                        src={img}
                        alt=""
                      />
                    </div>
                  </div> */}
                </div>}
            </div>
          ))}
          <div ref={lastMessageRef}> </div>
        </Box>
        <Box sx={{ backgroundColor: "background.input" }} className="flex sticky bottom-0 items-center justify-between px-4 rounded-[8px] mb-3">
          <div className="text-[12px] py-4  md:py-4 flex w-full mr-4">
            <EmojiEmotionsOutlinedIcon color={"#626262"} sx={{ fontSize: "22px", marginRight: "16px", color: "white" }} />
            <AttachFileOutlinedIcon color={"#626262"} sx={{ fontSize: "22px", marginRight: "16px", transform: "rotate(40deg)", color: "white", }} />{" "}
            <KeyboardVoiceOutlinedIcon color={"#626262"} sx={{ fontSize: "22px", marginRight: "16px", color: "white" }} />            <textarea
              ref={textareaRef}
              className="w-full outline-none bg-transparent placeholder:text-[#494949] placeholder:text-base md:placeholder:text-sm"
              placeholder="Type message here"
              value={message}
              rows={rows}
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={handleKeyDown} // Listen for keydown events
              style={{
                width: '100%',
                resize: 'none', // Disable manual resize
                overflowY: 'hidden', // Hide vertical scrollbar
                lineHeight: '1.5', // Line height to match the content
              }}
            />
          </div>
          <div>
            <AiOutlineSend
              sx={{
                fontSize: "26px",
                color: "white",
                transform: "rotate(-35deg)",
                marginBottom: "6px"

              }}
              onClick={handelSendMessage}

            />
          </div>
        </Box>
      </div>
      <ToastContainer />
      {/* <p className="h-[1px] absolute top-[68px] w-full bg-gray-500"></p> */}
    </>
  );
};

export default ChatSection;
