import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  styled,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  tableCellClasses,
  Paper,
  InputAdornment,
  TextField,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  useTheme,
  Box,
  Textarea,
  Checkbox,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import axios from "axios";
import {
  ArrowBackIos,
  ArrowForwardIos,
  Cancel,
  CheckCircle,
  Delete,
  Search,
} from "@mui/icons-material";
import CustomDrawer from "../../../components/CustomDrawer";

export default function Groups() {
  const mode = useTheme();
  const [addGroup, setAddGroup] = useState(false);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 5 });
  const [totalRows, setTotalRows] = useState(0);
  const [sorting, setSorting] = useState([{ id: "id", desc: false }]);

  const fetchData = async (pageIndex, pageSize, sorting) => {
    const sortField = sorting[0]?.id || "id";
    const sortDirection = sorting[0]?.desc ? "desc" : "asc";

    const response = await axios.get(`https://reqres.in/api/users`, {
      params: {
        page: pageIndex + 1,
        per_page: pageSize,
        sort: sortField,
        direction: sortDirection,
      },
    });
    console.log("response.data.data", response.data.data);
    const fetchedData = response.data.data.map((user) => ({
      status: user.id % 2 === 0, // Example logic for status
      employee: user.first_name + " " + user.last_name,
      keyField: "Sample Key", // Placeholder
      value: "Sample Value", // Placeholder
      effectiveDate: new Date().toISOString().split("T")[0],
      process: "View", // Placeholder
      reason: "Example reason", // Placeholder
    }));

    setData(response.data.data);
    setTotalRows(response.data.total);
  };

  useEffect(() => {
    fetchData(pagination.pageIndex, pagination.pageSize, sorting);
  }, [pagination.pageIndex, pagination.pageSize, sorting]);

  const totalPages = Math.ceil(totalRows / pagination.pageSize);

  return (
    <div className="w-full flex gap-3 flex-col justify-center items-center">
      <div className="p-2 w-full flex justify-end  gap-3 items-center">
        {/* <div className="flex flex-row gap-3 mt-3 "> */}

        <div className="flex">
          <FormControl sx={{ width: "250px", ml: 2 }}>
            <Select labelId="table-view" id="table-view" size="small">
              <MenuItem value={"All groups"}>All groups</MenuItem>
              <MenuItem value={"My groups"}>My groups</MenuItem>
            </Select>
          </FormControl>
        </div>
        <button
          className="px-3 py-1.5 bg-[#0088FF] text-sm rounded-md text-white "
          onClick={() => setAddGroup(true)}
        >
          Add Group
        </button>
        {/* </div> */}
      </div>

      {/* Table */}
      <div className="w-full">
        <TableContainer
          style={{ overflowX: "auto", width: "100%" }}
          className="text-nowrap"
          component={Paper}
        >
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#E5E5F0" }}>
                <TableCell>Group Name</TableCell>
                <TableCell>Group email address</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.groupName || "N/A"}</TableCell>
                  <TableCell>{row.email || "N/A"}</TableCell>
                  <TableCell>{row.description || "N/A"}</TableCell>
                  <TableCell>
                    <Delete sx={{ color: "#f75d59", cursor: "pointer" }} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Pagination Component */}
        <div className="flex items-center justify-between shadow-md rounded-md mt-2 p-4 ">
          <Typography variant="body1">
            Total Record Count:{" "}
            <span className="text-blue-500">{totalRows}</span>
          </Typography>

          <div className="flex items-center gap-2">
            <Select
              value={pagination.pageSize}
              onChange={(e) =>
                setPagination((prev) => ({ ...prev, pageSize: e.target.value }))
              }
              size="small"
            >
              {[5, 10, 25, 50].map((size) => (
                <MenuItem key={size} value={size}>
                  {size}
                </MenuItem>
              ))}
            </Select>

            <IconButton
              onClick={() =>
                setPagination((prev) => ({
                  ...prev,
                  pageIndex: prev.pageIndex - 1,
                }))
              }
              disabled={pagination.pageIndex === 0}
            >
              <ArrowBackIos fontSize="small" />
            </IconButton>

            <Typography variant="body1">
              {pagination.pageIndex + 1}-{totalPages}
            </Typography>

            <IconButton
              onClick={() =>
                setPagination((prev) => ({
                  ...prev,
                  pageIndex: prev.pageIndex + 1,
                }))
              }
              disabled={pagination.pageIndex >= totalPages - 1}
            >
              <ArrowForwardIos fontSize="small" />
            </IconButton>
          </div>
        </div>
      </div>

      <CustomDrawer
        isScrollable={true}
        open={addGroup}
        onClose={() => setAddGroup(false)}
        paperProps="w-[80%] m-1 md:w-[500px] lg:w-[800px] rounded-lg"
        mode={mode}
        headerTitle={"Add Group"}
      >
        <Box sx={{ background: "#fff", padding: 2, borderRadius: 2 }}>
          <Grid container spacing={2}>
            <Grid size={{ xs: 12 }}>
              <p>Group name</p>
              <TextField
                sx={{ width: "100%" }}
                name="employee"
                size="small"
                // placeholder="Field Search"
                variant="outlined"
                value={""}
              />
            </Grid>

            <Grid size={{ xs: 12 }}>
              <p>Description</p>
              <TextField
                sx={{ width: "100%" }}
                name="employee"
                variant="outlined"
                multiline={true}
                minRows={3}
                value={""}
              />
            </Grid>
            <Grid size={{ xs: 12 }}>
              <p>Group email address</p>
              <TextField
                sx={{ width: "100%" }}
                name="employee"
                size="small"
                // placeholder="Field Search"
                variant="outlined"
                value={""}
              />
            </Grid>
            <Grid size={{ xs: 12 }}>
              <p>Administrator</p>
              <TextField
                sx={{ width: "100%" }}
                name="employee"
                size="small"
                // placeholder="Field Search"
                variant="outlined"
                value={""}
              />
            </Grid>
            <Grid size={{ xs: 12 }}>
              <p>Members</p>
              <TextField
                sx={{ width: "100%" }}
                name="employee"
                size="small"
                // placeholder="Field Search"
                variant="outlined"
                value={""}
              />
            </Grid>
            <Grid
              size={{ xs: 12 }}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Checkbox />
              <p>Notify newly added employees.</p>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{ backgroundColor: mode === "dark" ? "#141414" : "#edf0f4" }}
          className="w-full min-h-14 items-center z-50 sticky mt-2 bottom-0 left-0 py-2"
        >
          <div className="flex flex-row gap-3 items-center">
            <Button variant="contained">Save</Button>
            <Button variant="outlined" onClick={() => setAddGroup(false)}>
              Cancel
            </Button>
          </div>
        </Box>
      </CustomDrawer>
    </div>
  );
}
