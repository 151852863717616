import React, { useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";

import Newreceivedapplication from "../../../ReceivedApp/Newreceivedapplication";
import JobListingHome from "../../../JobListing/JobListingHome";
import InterviewHome from "../../../Interview/interviewHome";
import Docs from "../../../Docs/Docs";
import { useTheme } from "../../../../style/theme";
import Departments from "../../EmployeeInformation/Departments";

export default function Recruit() {
  const {mode} = useTheme()
  const [switchScreen, setSwitchScreen] = useState({
    primary: "receivedapplications",
  });
  const primaryTabs = [
    {
      label: "Received Applications",
      value: "receivedapplications",
    },
    {
      label: "Job Listing",
      value: "jobListing",
    },
    {
      label: "Interview Questions",
      value: "interviewQuestions",
    },
    {
      label: "Docs ",
      value: "docs",
    },
    {
      label: "Departments ",
      value: "departments",
    },
  ];

  return (
    <Box sx={{backgroundColor:mode === "light" ?  "#f7f9fc" : ""}} className="w-full flex flex-col gap-3">
      <div>
        {/* <Tabs
          value={switchScreen.primary}
          onChange={(event, newValue) => {
            setSwitchScreen({
              primary: newValue,
            });
          }}
          variant="scrollable"
          scrollButtons="auto"
        >
          {primaryTabs.map((tab, index) => (
            <Tab key={index} label={tab.label} value={tab.value}
            />
          ))}
        </Tabs> */}

        <Tabs
          value={switchScreen.primary}
          onChange={(event, newValue) => {
            setSwitchScreen({
              primary: newValue,
            });
          }}
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          indicatorColor="primary"
          aria-label="Navigation Tabs"
          sx={{
            minHeight: "32px", // Reduce overall height
          }}
          className="w-full sm:w-auto"
          TabIndicatorProps={{
            sx: {
              bottom:{xs:"10px"},
            },
          }}
        >
          {primaryTabs.map((tab, index) => (
            <Tab
              key={index} label={tab.label} value={tab.value}
              className="h-8 text-xs sm:text-sm px-2 sm:px-4"
              sx={{
                minWidth: "60px", // Reduce tab width on small screens
                padding: "4px 8px", // Adjust padding for better fit
                fontSize: { xs: "10px", sm: "14px" },
              }}
            />
          ))}
        </Tabs>
      </div>
      {switchScreen.primary === "receivedapplications" && (
        <>
          <Newreceivedapplication />
        </>
      )}
      {switchScreen.primary === "jobListing" && (
        <>
          <JobListingHome />
        </>
      )}
      {switchScreen.primary === "interviewQuestions" && (
        <>
          <InterviewHome />
        </>
      )}
      {switchScreen.primary === "docs" && (
        <>
          <Docs />
        </>
      )}
      {switchScreen.primary === "departments" && (
        <>
          <Departments />
        </>
      )}
    </Box>
  );
}
