import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import view from '../../ReceivedApp/viewicon.png';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import { useMenu } from '../../../hooks/useMenu';
import axios from 'axios';
import attendanceImg from '../../../assets/initalScreen/attendanceView.svg'
import { IoIosSearch } from 'react-icons/io';
import { VscEye } from 'react-icons/vsc';



const AttendViewPage = ({ month, year }) => {

    const [currentScreen, setCurrentScreen] = useState(1);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [attendance, setAttendance] = useState([]);
    const [date, setDate] = useState();
    const [overview, setOverView] = useState({
        working: 0,
        leaves: 0,
        absent: 0,
        halfDays: 0,
        lateDays: 0,
        holidays: 0,
    })
    const Months = {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
        8: 'August',
        9: 'September',
        10: 'October',
        11: 'November',
        12: 'December',
    };

    const fetchOverView = useCallback(async () => {
        try {
            const response = await axios.get(`/hr/attendance/overview?year=${year}&month=${month}`);

            const result = response.data;

            const getCount = (name, arrayData = []) => {
                let res = arrayData.find((type) => type._id == name)
                return res?.count || 0;
            }

            setOverView({
                working: result.working,
                leaves: result?.Leave ?? 0,
                absent: 0,
                halfDays: result?.["Half-Day"] ?? 0,
                lateDays: result?.Late ?? 0,
                holidays: result.holidays,
            })

        } catch (e) {
            console.log(e);
        }
    }, []);



    const handlePrevScreen = () => {
        if (currentScreen > 1) {
            setCurrentScreen(currentScreen - 1);
        }
    };

    const handleNextScreen = () => {

        if (currentScreen < 2) {
            setCurrentScreen(currentScreen + 1);
        }
    };


    const handleChangeQuery = e => {
        const name = e.target.name;
        const value = e.target.value;
        setDate({ ...date, [name]: value });
    };


    const { anchorEl: detailAnchorEl, openMenu: openDetail, closeMenu: closeDetail } = useMenu();



    const fetchAttendance = useCallback(
        async function () {
            try {
                if (year && month) {
                    const response = await axios.get(
                        '/hr/attendance?year=' + year + '&month=' + month
                    );
                    let attendanceData = response.data.attendance;
                    setAttendance(attendanceData)
                }
            } catch (e) {
                console.log(e);
            }
        },
        [year, month]
    );

    useEffect(() => {
        fetchAttendance();
        fetchOverView();
    }, [fetchAttendance, fetchOverView]);

    const getColor = (lastAbsent) => {
        switch (lastAbsent) {
            case 'Never':
                return { bgColor: 'bg-blue-950', textColor: 'text-blue-500' };
            default:
                return { bgColor: 'bg-red-950', textColor: 'text-red-500' };
        }
    };


    const attendancee = [
        {
            employeeData: {
                _id: "E001",
                firstName: "John",
                lastName: "Doe",
            },
            presentCount: 20,
            lateCount: 2,
            halfDayCount: 1,
            leaveCount: 1,
        },
        {
            employeeData: {
                _id: "E002",
                firstName: "Jane",
                lastName: "Smith",
            },
            presentCount: 18,
            lateCount: 1,
            halfDayCount: 2,
            leaveCount: 3,
        },
        {
            employeeData: {
                _id: "E003",
                firstName: "Michael",
                lastName: "Johnson",
            },
            presentCount: 22,
            lateCount: 0,
            halfDayCount: 0,
            leaveCount: 2,
        },
        {
            employeeData: {
                _id: "E004",
                firstName: "Emily",
                lastName: "Davis",
            },
            presentCount: 19,
            lateCount: 3,
            halfDayCount: 1,
            leaveCount: 2,
        },
        {
            employeeData: {
                _id: "E005",
                firstName: "David",
                lastName: "Wilson",
            },
            presentCount: 21,
            lateCount: 2,
            halfDayCount: 0,
            leaveCount: 1,
        },
        {
            employeeData: {
                _id: "E006",
                firstName: "Sophia",
                lastName: "Brown",
            },
            presentCount: 17,
            lateCount: 4,
            halfDayCount: 2,
            leaveCount: 3,
        },
        {
            employeeData: {
                _id: "E007",
                firstName: "James",
                lastName: "Taylor",
            },
            presentCount: 23,
            lateCount: 0,
            halfDayCount: 0,
            leaveCount: 1,
        },
    ];

    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const years = ["2024", "2025", "2026", "2027", "2028"];

    const employeeNames = [
        "Employee Name", "John Doe", "Jane Smith", "Alice Johnson", "Bob Brown"
    ];


    const fakeData = [
        {
            id: "#2345",
            name: "Paige Campbell",
            days: "4 Days",
            value1: 0,
            value2: 0,
            value3: 0,
            value4: 0,
            value5: 0,
            value6: 3,
            value7: 1,
            value8: 21,
            status: "Active",
        },
        {
            id: "#2346",
            name: "John Doe",
            days: "2 Days",
            value1: 0,
            value2: 1,
            value3: 0,
            value4: 0,
            value5: 1,
            value6: 5,
            value7: 2,
            value8: 18,
            status: "Inactive",
        },
        {
            id: "#2347",
            name: "Alice Johnson",
            days: "1 Day",
            value1: 0,
            value2: 0,
            value3: 0,
            value4: 1,
            value5: 0,
            value6: 4,
            value7: 0,
            value8: 12,
            status: "Active",
        },
    ];

    return (
        <Box sx={{ backgroundColor: 'background.main', overflowY: "auto" }}>

            {attendance && attendance.length > 0 ? <>
                <div className='flex flex-col'>
                    <div className="flex items-center justify-between md:w-full p-4">
                        <div className="p-2">
                            <h1 className="text-xs md:text-2xl text-neutral-500">Attendance Summary</h1>
                        </div>
                        <div className="flex flex-row items-center justify-center gap-4">
                            <h1 className="text-xs md:text-2xl text-neutral-500"> {Months[month]} - {year}</h1>
                            {/* <button className='flex  items-center text-white font-bold text-[8px] md:text-[12px] py-1 md:py-1 px-2 md:px-3 rounded bg-sky-500 hover:bg-sky-700'>
                           
                            </button> */}
                            <InfoOutlinedIcon />
                        </div>
                    </div>
                </div>

                <Box className="w-full md:ml-0 pt-4 rounded-lg mb-4" sx={{ backgroundColor: 'background.view', }}>
                    {/* <p className=" mb-4 border-l-4 border-blue-500 pl-4 text-xl" gutterBottom>
                    Days Overview This Month
                </p> */}

                    <div className='flex flex-col md:flex-row  justify-around gap-3  md:pt-4 md:w-full pb-10'>
                        <div className='flex flex-row md:flex-col justify-around md:justify-center md:items-center gap-2'>
                            <div className='w-[40px] h-[40px] flex justify-center items-center rounded-lg bg-neutral-800 text-[16px] text-blue-700'>{overview.working}</div>
                            <p className='text-[16px] text-gray-400'> Total Working Days</p>
                        </div>
                        <div className='flex flex-row md:flex-col justify-around md:justify-center md:items-center gap-2'>
                            <div className='w-[40px] h-[40px] flex justify-center items-center rounded-lg bg-green-950 text-[16px] text-green-500'>{overview.leaves}</div>
                            <p className='text-[16px] text-gray-400'> leaves </p>
                        </div>
                        <div className='flex flex-row md:flex-col justify-around md:justify-center md:items-center gap-2'>
                            <div className='w-[40px] h-[40px] flex justify-center items-center rounded-lg bg-amber-950 text-[16px] text-amber-500'>{overview.absent}</div>
                            <p className='text-[16px] text-gray-400'> Absent </p>
                        </div>
                        <div className='flex flex-row md:flex-col justify-around md:justify-center md:items-center gap-2'>
                            <div className='w-[40px] h-[40px] flex justify-center items-center rounded-lg bg-teal-950 text-[16px] text-teal-500'>{overview.halfDays}</div>
                            <p className='text-[16px] text-gray-400'> Half Days </p>
                        </div>
                        <div className='flex flex-row md:flex-col justify-around md:justify-center md:items-center gap-2'>
                            <div className='w-[40px] h-[40px] flex justify-center items-center rounded-lg bg-red-950 text-[16px] text-red-500'>{overview.lateDays}</div>
                            <p className='text-[16px] text-gray-400'> Late Days</p>
                        </div>
                        <div className='flex flex-row md:flex-col justify-around md:justify-center items-center gap-2 '>
                            <div className='w-[40px] h-[40px] flex justify-center items-center rounded-lg bg-orange-950 text-[16px] text-orange-500'>{overview.holidays}</div>
                            <p className='text-[16px] text-gray-400'>Holidays</p>
                        </div>
                    </div>

                </Box>
                <Box className="w-full md:ml-0 pt-4 rounded-lg mb-4" sx={{ backgroundColor: 'background.view' }}>
                    <Box>
                        {/* <Box className="flex flex-col md:flex-row justify-center gap-4 mt-4 w-[97%] md:ml-4 ">
                   <div className='w-full md:w-[21%] flex justify-start items-center'>
                    <p className='text-[12px] ml-3'>Show</p>
                        <select className="appearance-none bg-transparent pl-1 rounded leading-tight focus:outline-none focus:border-gray-500 text-[12px]">
                            <option>10</option>
                            <option>20</option>
                            <option>30</option>
                            <option>40</option>
                        </select>
                        <ArrowDropDownIcon fontSize='medium' className="text-zinc-500"/>
                        <p className='text-[12px]'>entries</p>
                   </div>
                   <div className='w-full md:w-[22%] border border-gray-500 rounded-lg flex flex-row items-center'>
                     <select className="appearance-none bg-transparent w-[95%] text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none text-[12px] focus:border-gray-500">
                            <option>Employee Name</option>
                            <option>John Doe</option>
                            <option>Jane Smith</option>
                            <option>Michael Johnson</option>
                        </select>
                        <ArrowDropDownIcon style={{fontSize:'28px'}} className="text-zinc-500"/>
                   </div>
                   <div className='w-full md:w-[22%] border border-gray-500 rounded-lg flex flex-row items-center'>
                     <select className="appearance-none bg-transparent w-[90%] text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none text-[12px] focus:border-gray-500">
                            <option>Select Date</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                        </select>
                        <CalendarTodayOutlinedIcon style={{fontSize:'24px'}} className="text-zinc-500 pr-2"/>
                   </div>
                   <div className='w-full md:w-[22%] border border-gray-500 rounded-lg flex flex-row items-center'>
                     <select className="appearance-none bg-transparent w-[90%] text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none text-[12px] focus:border-gray-500">
                            <option>Month</option>
                            <option>January</option>
                            <option>February</option>
                            <option>March</option>
                        </select>
                        <ArrowDropDownIcon style={{fontSize:'28px'}} className="text-zinc-500"/>
                   </div>
                   <div className='w-full md:w-[22%] border border-gray-500 rounded-lg flex flex-row items-center'>
                     <select className="appearance-none bg-transparent w-[90%] text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none text-[12px] focus:border-gray-500">
                            <option>Year</option>
                            <option>2022</option>
                            <option>2023</option>
                            <option>2024</option>
                        </select>
                        <ArrowDropDownIcon style={{fontSize:'28px'}} className="text-zinc-500"/>
                   </div>

                   <div className='w-full md:w-[11%] flex justify-end md:justify-center items-center '>
                        <button className="bg-sky-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Search</button>
                   </div>
                </Box> */}
                        <Box
                            sx={{ width: { xs: 'calc(100vw - 35px)', sm: '97%' } }}
                            className=' md:ml-4 border border-zinc-500 overflow-x-auto md:overflow-x-hidden rounded-sm mt-10 h-[310px]'
                        >
                            <Grid className='flex flex-row border-b border-zinc-500  min-w-[68rem]' >
                                <div className='w-[50%] md:w-[18%] p-2 flex items-center border-r border-zinc-500 text-left text-sm md:text-[12px] font-bold'>
                                    Emp ID
                                </div>
                                <div className='w-[50%] md:w-[20%] p-2 flex items-center border-r border-zinc-500 text-sm md:text-[12px] font-bold'>
                                    Emp Name
                                </div>
                                <div className='w-[25%] md:w-[6.2%] flex items-center p-2 border-r border-zinc-500 text-sm md:text-[12px] font-bold'>
                                    Present
                                </div>
                                <div className='w-[25%] md:w-[6.2%] flex items-center p-2 border-r border-zinc-500 text-sm md:text-[12px] font-bold'>
                                    Late
                                </div>
                                <div className='w-[25%] md:w-[6.2%] flex items-center p-2 border-r border-zinc-500 text-sm md:text-[12px] font-bold'>
                                    Half-Day
                                </div>
                                <div className='w-[25%] md:w-[6.2%] flex items-center p-2 border-r border-zinc-500 text-sm md:text-[12px] font-bold'>
                                    Leave
                                </div>
                                {/* 
                        <div className='w-[25%] md:w-[6.2%] p-2 flex items-center border-r border-zinc-500 text-sm md:text-[12px] font-bold'>
                            Status
                        </div> */}
                                <div className='w-[25%] md:w-[6.2%] p-2 flex items-center text-left text-sm md:text-[12px] font-bold'>
                                    Action
                                </div>
                            </Grid>
                            {attendance?.map((item, index) => (
                                <Grid key={index} className='flex flex-row border-b border-zinc-500  min-w-[68rem]' >
                                    <div className='w-[50%] md:w-[18%] p-2 border-r border-zinc-500 truncate flex items-center text-sm md:text-[12px] '>
                                        #{item.employeeData._id}
                                    </div>
                                    <div className='w-[50%] md:w-[20%] p-2 border-r border-zinc-500 flex flex-row gap-4 truncate items-center text-sm md:text-[8px] '>
                                        <AccountCircleOutlinedIcon style={{ fontSize: '14px' }} />
                                        <p className='text-[12px]'>{item.employeeData.firstName} {item.employeeData.lastName}</p>
                                    </div>
                                    {/* <div className='w-[25%] md:w-[6.2%] p-2 border-r border-zinc-500 flex items-center text-sm md:text-[8px] font-bold'>
                                <div className={`px-1 py-0 w-[90%] rounded-lg  flex justify-center items-center text-[8px] ${
                                        getColor(item.lastAbsent).bgColor
                                    } ${getColor(item.lastAbsent).textColor}`}>
                                    {item.lastAbsent}
                                </div>
                            </div> */}
                                    <div className='w-[25%] md:w-[6.2%] p-2 flex items-center justify-center  border-r border-zinc-500 text-left text-sm md:text-[12px]'>
                                        {item.presentCount}
                                    </div>
                                    <div className='w-[25%] md:w-[6.2%] p-2  flex items-center justify-center border-r border-zinc-500 text-sm md:text-[12px]'>
                                        {item.lateCount}
                                    </div>
                                    <div className='w-[25%] md:w-[6.2%] p-2 flex items-center justify-center border-r border-zinc-500 text-sm md:text-[12px]'>
                                        {item.halfDayCount}
                                    </div>
                                    <div className='w-[25%] md:w-[6.2%] p-2 flex items-center justify-center border-r border-zinc-500  text-left text-sm md:text-[12px]'>
                                        {item.leaveCount}
                                    </div>

                                    {/* <div className='w-[25%] md:w-[6.2%] p-2 flex items-center justify-center border-r border-zinc-500 text-sm md:text-[12px]'>
                                <button className='flex  items-center text-white text-[8px] md:text-[8px] py-1 md:py-0 px-2 md:px-2 rounded bg-sky-500 hover:bg-sky-900'>
                                    {item.status}
                                </button>
                            </div> */}
                                    <div className='w-[25%] md:w-[6.2%] p-2  flex items-center justify-center text-left text-sm md:text-[10px]'>
                                        <IconButton><img src={view} alt="view" className="w-4 h-4" /></IconButton>
                                    </div>

                                </Grid>
                            ))}
                        </Box>
                        {/* <div className='flex items-center justify-between w-[80%] md:w-[92%] md:mx-4 pl-5 md:pl-0 pt-4 md:pt-5'>
                <div className="p-2 rounded-lg ">
                    <div className="flex items-center gap-0 md:gap-6">
                        <p className='text-[12px] text-gray-400'>Show Rows: 1-10 of 20</p>
                    </div>
                </div>
                <div className="flex flex-row gap-4">
                    <KeyboardArrowLeftOutlinedIcon
                        className="text-zinc-400 cursor-pointer"
                        onClick={handlePrevScreen}
                    />
                    <p className="text-zinc-400">1</p>
                    {currentScreen === 1 ? (
                        <KeyboardArrowRightOutlinedIcon
                            className="text-zinc-300 cursor-pointer"
                            onClick={handleNextScreen}
                        />
                    ) : (
                        <div className="bg-blue-500 w-[20px] h-[20px] flex items-center justify-center p-1 rounded-full">
                            2
                        </div>
                    )}
                </div>
                </div> */}
                    </Box>
                </Box>


                {/* figma table */}
                {/* <div className="p-4">
                    <div className="flex flex-col items-end w-full gap-9 mb-6">
                        <div className='flex gap-2 flex-wrap items-center'>
                            <FormControl className="w-48">
                                <Select
                                    variant="outlined"
                                    defaultValue="Employee Name"
                                >
                                    {employeeNames.map((name, index) => (
                                        <MenuItem key={index} value={name}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <TextField
                                sx={{ margin: 0 }}
                                className='rounded-xl'
                                type="date"
                                variant="outlined"
                                InputLabelProps={{ className: "text-white" }}
                            />

                            <FormControl className="w-48">
                                <InputLabel className="text-white">Month</InputLabel>
                                <Select
                                    variant="outlined"
                                    defaultValue=""
                                >
                                    {months.map((month, index) => (
                                        <MenuItem key={index} value={month}>
                                            {month}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl className="w-48">
                                <InputLabel className="text-white">Year</InputLabel>
                                <Select
                                    variant="outlined"
                                    defaultValue=""
                                >
                                    {years.map((year, index) => (
                                        <MenuItem key={index} value={year}>
                                            {year}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <Button
                                variant="contained"
                                className="bg-blue-600 h-fit text-white hover:bg-blue-500"
                            >
                                Search
                            </Button>
                        </div>
                        <div>
                            <TextField
                                size="small"
                                variant="outlined"
                                placeholder="Search"
                                sx={{ margin: 0 }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <IoIosSearch className="text-gray-400" />
                                        </InputAdornment>
                                    ),
                                    sx: {
                                        backgroundColor: "background.main",
                                        borderRadius: "8px",
                                    },
                                }}
                            />
                        </div>
                    </div>
                    <TableContainer sx={{ border: "1px solid #676767" }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ borderBottom: "1px solid #676767", borderRight: "1px solid #676767" }} rowSpan={2}>
                                        Emp ID
                                    </TableCell>
                                    <TableCell sx={{ borderBottom: "1px solid #676767", borderRight: "1px solid #676767" }} rowSpan={2}>
                                        Emp Name
                                    </TableCell>
                                    <TableCell sx={{ borderBottom: "1px solid #676767", borderRight: "1px solid #676767" }} rowSpan={2}>
                                        Last Absent
                                    </TableCell>
                                    <TableCell sx={{ borderBottom: "1px solid #676767", borderRight: "1px solid #676767" }} colSpan={7} align="center">
                                        Leaves
                                    </TableCell>
                                    <TableCell sx={{ borderBottom: "1px solid #676767", borderRight: "1px solid #676767" }} rowSpan={2}>
                                        Credit Leaves
                                    </TableCell>
                                    <TableCell sx={{ borderBottom: "1px solid #676767", borderRight: "1px solid #676767" }} rowSpan={2}>
                                        Status
                                    </TableCell>
                                    <TableCell sx={{ borderBottom: "1px solid #676767" }} rowSpan={2}>
                                        Action
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ borderBottom: "1px solid #676767", borderRight: "1px solid #676767" }}>Half Day</TableCell>
                                    <TableCell sx={{ borderBottom: "1px solid #676767", borderRight: "1px solid #676767" }}>Sick</TableCell>
                                    <TableCell sx={{ borderBottom: "1px solid #676767", borderRight: "1px solid #676767" }}>Casual</TableCell>
                                    <TableCell sx={{ borderBottom: "1px solid #676767", borderRight: "1px solid #676767" }}>Maternity</TableCell>
                                    <TableCell sx={{ borderBottom: "1px solid #676767", borderRight: "1px solid #676767" }}>Paternity</TableCell>
                                    <TableCell sx={{ borderBottom: "1px solid #676767", borderRight: "1px solid #676767" }}>Annual</TableCell>
                                    <TableCell sx={{ borderBottom: "1px solid #676767", borderRight: "1px solid #676767" }}>Unpaid</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {fakeData.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell sx={{ borderBottom: "1px solid #676767", borderRight: "1px solid #676767" }}>{row.id}</TableCell>
                                        <TableCell sx={{ borderBottom: "1px solid #676767", borderRight: "1px solid #676767" }}>{row.name}</TableCell>
                                        <TableCell sx={{ borderBottom: "1px solid #676767", borderRight: "1px solid #676767" }}>
                                            <span className="px-2 py-1 bg-[#B8242433] text-[#FF0000] rounded">
                                                {row.days}
                                            </span>
                                        </TableCell>
                                        <TableCell sx={{ borderBottom: "1px solid #676767", borderRight: "1px solid #676767" }}>{row.value1}</TableCell>
                                        <TableCell sx={{ borderBottom: "1px solid #676767", borderRight: "1px solid #676767" }}>{row.value2}</TableCell>
                                        <TableCell sx={{ borderBottom: "1px solid #676767", borderRight: "1px solid #676767" }}>{row.value3}</TableCell>
                                        <TableCell sx={{ borderBottom: "1px solid #676767", borderRight: "1px solid #676767" }}>{row.value4}</TableCell>
                                        <TableCell sx={{ borderBottom: "1px solid #676767", borderRight: "1px solid #676767" }}>{row.value5}</TableCell>
                                        <TableCell sx={{ borderBottom: "1px solid #676767", borderRight: "1px solid #676767" }}>{row.value6}</TableCell>
                                        <TableCell sx={{ borderBottom: "1px solid #676767", borderRight: "1px solid #676767" }}>{row.value7}</TableCell>
                                        <TableCell sx={{ borderBottom: "1px solid #676767", borderRight: "1px solid #676767" }}>{row.value8}</TableCell>
                                        <TableCell sx={{ borderBottom: "1px solid #676767", borderRight: "1px solid #676767" }}>
                                            <Button
                                                variant="contained"
                                                color={row.status === "Active" ? "primary" : "error"}
                                            >
                                                {row.status}
                                            </Button>
                                        </TableCell>
                                        <TableCell sx={{ borderBottom: "1px solid #676767" }}>
                                            <button className="border-none outline-none p-2 bg-[#7474740F] rounded-lg"
                                            >
                                                <VscEye size={18} color="#A5A5A5" />
                                            </button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div> */}
            </>
                :
                <div className="flex flex-col h-[80vh] items-center justify-center  text-center">
                    <div><img src={attendanceImg} alt="No Record" className="mb-1"
                        style={{ maxWidth: '70%', margin: 'auto' }}
                    /></div>
                    <div><h1 className="text-2xl font-bold mb-2" style={{ fontSize: '36px' }}> No Current attendance</h1></div>
                    <div><p className='mb-[20px] text-center text-[#868686]'>Your company attendance record will be seen and edited here. Add employees now <br /> to start monitoring their attendance record.</p></div>
                    <Button variant='contained' color="primary">Add employee</Button>
                </div>
            }
        </Box >
    );
};

export default AttendViewPage;
