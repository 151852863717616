import React, { useState } from "react";
import { Tab, Tabs } from "@mui/material";
import LeaveRequests from "./LeaveRequests";
import Holidays from "./Holidays";
import CustomizeBalance from "./CustomizeBalance";
import CustomizePolicy from "./CustomizePolicy";
import ExceptionalWorkingDays from "./ExceptionalWorkingDays";
import UserSpecificOperations from "./UserSpecificOperations";
import { useSwitchScreen } from "../../../hooks/SwitchScreenContext";
import LeaveSettingsHome from "../../Attendance/AttendView/LeaveSetting/LeaveSettingHome";
import LeaveAppHome from "../../Attendance/AttendView/LeaveAppPage.jsx/LeaveAppHome";

export default function LeaveTracker() {

  const { switchScreen } = useSwitchScreen();
console.log(switchScreen.primary,"switchScreenswitchScreenswitchScreen")
  return (
    <div className="w-full flex flex-col gap-3 px-3 rounded-lg border border-[#1e2734]">
      {switchScreen.primary === "userSpecificOperations" ? (
        <>
          <UserSpecificOperations />
        </>
      ) : (
        <></>
      )}
      {switchScreen.primary === "leaveRequests" ? (
        <>
          <LeaveRequests />
        </>
      ) : (
        <></>
      )}
      {switchScreen.primary === "holidays" ? (
        <>
          <Holidays />
        </>
      ) : (
        <></>
      )}
      {switchScreen.primary === "customizeBalance" ? (
        <>
          <CustomizeBalance />
        </>
      ) : (
        <></>
      )}
      {switchScreen.primary === "customizePolicy" ? (
        <>
          <CustomizePolicy />
        </>
      ) : (
        <></>
      )}
      {switchScreen.primary === "exceptionalWorkingDays" ? (
        <>
          <ExceptionalWorkingDays />
        </>
      ) : (
        <></>
      )}
      {switchScreen.primary === "leavesettings" ? (
        <>
          <LeaveSettingsHome />
        </>
      ) : (
        <></>
      )}

      {switchScreen.primary === "leaveapplication" ? (
        <>
          <LeaveAppHome />
        </>
      ) : (
        <></>
      )}
    </div>
  );
}
