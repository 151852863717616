import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  tableCellClasses,
  TableBody,
  IconButton,
  Modal,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  TextField,
  Avatar,
  TextareaAutosize,
  Box,
  Drawer,
  Typography,
  DialogTitle,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { MdDeleteOutline } from "react-icons/md";
import { IoCalendarOutline, IoFilter } from "react-icons/io5";
import {
  DateCalendar,
  DatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { RiHistoryFill } from "react-icons/ri";
import CheckIcon from "@mui/icons-material/Check";
import CustomEmptyModal from "../../../components/CustomEmptyModal";
import CustomModal from "../../../components/CustomModal";
import {
  getToday,
  getYesterday,
  getThisWeek,
  getThisMonth,
  getLastWeek,
  getLastMonth,
} from "../../../utilities/date";
import { LiaTrashAlt } from "react-icons/lia";
import CustomTable from "../../../components/CustomTable";
import Pagination from "../../../components/Pagination";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import dayjs from "dayjs";
import { useTheme } from "../../../style/theme";
import { X } from "lucide-react";
import { CgClose } from "react-icons/cg";

const FilterDrawer = ({ filterModal, setFilterModal }) => {
  const [filter, setFilter] = useState("Today");
  const { mode } = useTheme();
  return (
    <Drawer
      anchor="right"
      open={filterModal}
      onClose={() => setFilterModal(false)}
    >
      <div
        style={{
          backgroundColor: mode === "light" ? "#fff" : "background.view",
        }}
        className="w-[320px] p-4"
      >
        <div className="flex justify-between items-center">
          <h2 className="text-lg font-semibold mb-4">Filter</h2>
          <button
            className="border-none outline-none"
            onClick={() => setFilterModal(false)}
          >
            <X size={20} />
          </button>
        </div>

        <label className="block text-sm font-medium mb-2 text-[#666666] mt-2">
          Period
        </label>
        <Select
          value={filter}
          defaultValue="This Year"
          onChange={(e) => setFilter(e.target.value)}
          fullWidth
          sx={{
            backgroundColor: mode === "light" ? "#fff" : "",
          }}
          // className="bg-white"
          size="small"
        >
          <MenuItem value="Today">Today</MenuItem>
          <MenuItem value="Last Month">This Week</MenuItem>
          <MenuItem value="This Month">This Month</MenuItem>
          <MenuItem value="This Month">Yesterday</MenuItem>
          <MenuItem value="Last Year">Last Week</MenuItem>
          <MenuItem value="This Year">Last Month</MenuItem>
          <MenuItem value="Custom">Custom</MenuItem>
        </Select>
      </div>

      <div className="flex items-end h-full mb-5 justify-start px-5 space-x-2">
        <Button variant="contained" sx={{ bgColor: "#0088ff" }}>
          Apply
        </Button>
        <Button variant="outlined" onClick={() => setFilter("")}>
          Reset
        </Button>
      </div>
    </Drawer>
  );
};

export default function Timesheets() {
  const [filterModal, setFilterModal] = useState(false);
  const [timeSheetModal, setTimeSheetModal] = useState(false);
  const [createTimesheetModal, setCreateTimesheetModal] = useState(false);
  const [editTimesheetModal, setEditTimesheetModal] = useState(false);
  const [auditHistoryModal, setAuditHistoryModal] = useState(false);

  const [sorting, setSorting] = useState([{ id: "id", desc: false }]);
  const [totalRows, setTotalRows] = useState(0);
  const [createTimesheetPeriod, setCeateTimesheetPeriod] = useState("pending");

  const filterFields = [
    {
      type: "select",
      name: "period",
      label: "Period",
      options: [
        { label: "Today", value: getToday() },
        { label: "Yesterday", value: getYesterday() },
        { label: "This Week", value: getThisWeek() },
        { label: "This Month", value: getThisMonth() },
        { label: "Last Week", value: getLastWeek() },
        { label: "Last Month", value: getLastMonth() },
      ],
      defaultValue: getToday(),
    },
    {
      type: "multipleSelect",
      name: "employees",
      label: "Employees",
      options: [
        { label: "Employee 1", value: "employee_1" },
        { label: "Employee 2", value: "employee_2" },
      ],
      defaultValue: "",
    },
    {
      type: "multipleSelect",
      name: "departments",
      label: "Departments",
      options: [
        { label: "Department 1", value: "department_1" },
        { label: "Department 2", value: "department_2" },
      ],
      defaultValue: "",
    },
    {
      type: "multipleSelect",
      name: "employeeStatus",
      label: "Employee Status",
      options: [
        { label: "Employee Status 1", value: "employee_status_1" },
        { label: "Employee Status 2", value: "employee_status_2" },
      ],
      defaultValue: "",
    },
  ];

  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [rowSelection, setRowSelection] = useState({});
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });
  const [selectedRecord, setSelectedRecord] = useState(null);

  const renderRowSelection = (table) => {
    const selectedRows = Object.keys(rowSelection)?.map(
      (rowId) => table?.getRow(rowId)?.original
    );

    return (
      <div>
        {selectedRows?.length > 0 && (
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              setSelectedRecord(selectedRows); // Set multiple selected rows
              // openDelete();
            }}
          >
            Delete
          </Button>
        )}
      </div>
    );
  };

  const handleRowClick = (row) => {
    // setViewJobModal(true);
  };
  const renderActions = (row) => (
    <IconButton>
      <LiaTrashAlt />
    </IconButton>
  );
  const fetchData = async (sortBy = "", sortOrder = "") => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(
        `https://reqres.in/api/users?page=${currentPage}&per_page=${limit}&${sortBy}=${sortOrder}`
      );
      const result = await response.json();
      if (result && result.data && result.total) {
        setData(result.data);
        setTotalItems(result.total);
      }
    } catch (error) {
      setError("Error Fetching Data.");
    } finally {
      setLoading(false);
    }
  };
  const handleSort = (column, direction) => {
    fetchData(column, direction);
  };
  useEffect(() => {
    fetchData();
  }, [currentPage, limit]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
    setCurrentPage(1);
  };
  const totalPages = Math.ceil(totalItems / limit);

  useEffect(() => {
    console.log(getToday());
    console.log(getYesterday());
    console.log(getThisWeek());
    console.log(getThisMonth());
    console.log(getLastWeek());
    console.log(getLastMonth());
  });

  const columns = [
    {
      accessorKey: "id",
      header: "ID",
      enableSorting: true,
    },
    {
      accessorKey: "timesheetName",
      header: "Timesheet Name",
      enableSorting: false,
    },
    {
      accessorKey: "employee",
      header: "Employee",
      enableSorting: false,
    },
    {
      accessorKey: "submittedHours",
      header: "Submitted Hours",
      enableSorting: false,
    },
    {
      accessorKey: "approvedHours",
      header: "Approved Hours",
      enableSorting: false,
    },
    {
      accessorKey: "status",
      header: "Status",
      enableSorting: false,
    },
    {
      accessorKey: "actions",
      header: "Actions",
    },
  ];

  const fakeData = [
    {
      timesheetName: "January Report",
      employee: "John Doe",
      submittedHours: 40,
      approvedHours: 38,
      status: "Approved",
      actions: "View",
    },
    {
      timesheetName: "February Report",
      employee: "Jane Smith",
      submittedHours: 42,
      approvedHours: 40,
      status: "Pending",
      actions: "Edit",
    },
    {
      timesheetName: "March Report",
      employee: "Alice Johnson",
      submittedHours: 36,
      approvedHours: 36,
      status: "Approved",
      actions: "View",
    },
    {
      timesheetName: "April Report",
      employee: "Bob Brown",
      submittedHours: 45,
      approvedHours: 43,
      status: "Rejected",
      actions: "Resubmit",
    },
    {
      timesheetName: "May Report",
      employee: "Charlie Wilson",
      submittedHours: 38,
      approvedHours: 38,
      status: "Approved",
      actions: "View",
    },
  ];
  const [selectedValue, setSelectedValue] = useState("all");
  const [openCalendar, setOpenCalendar] = useState(false);

  const [open, setOpen] = useState(false);
  // const [selectedDate, setSelectedDate] = useState(dayjs());
  // const startOfWeek = selectedDate.startOf("isoWeek"); // Monday
  // const endOfWeek = startOfWeek.add(6, "day"); // Sunday
  // const formattedStart = startOfWeek.format("DD/MM/YYYY");
  // const formattedEnd = endOfWeek.format("DD/MM/YYYY");
  const [selectedDate, setSelectedDate] = useState(dayjs());

  const handlePrevMonth = () => {
    setSelectedDate(selectedDate.subtract(1, "month"));
  };

  const handleNextMonth = () => {
    setSelectedDate(selectedDate.add(1, "month"));
  };

  //   const handlePrevWeek = () => {
  //     setSelectedDate(selectedDate.subtract(7, "day"));
  // };

  // const handleNextWeek = () => {
  //     setSelectedDate(selectedDate.add(7, "day"));
  // };
  const { mode } = useTheme();

  const TimeSheetDrawer = ({ timeSheetModal, setTimeSheetModal }) => {
    const { mode } = useTheme();

    return (
      <Drawer
        anchor="right"
        open={timeSheetModal}
        onClose={() => setTimeSheetModal(false)}
      >
        <div className={`w-[800px] p-4  ${mode === "light" ? "bg-[#edf0f4]" : "bg-[#141414]"} h-full`}>
          <div className="flex justify-between items-center pb-2">
            <DialogTitle sx={{ padding: "0px" }}>Create Timesheet</DialogTitle>
            <div
              className={`${
                mode === "light" ? "bg-[#dcdcdc]" : ""
              } p-2 rounded-md cursor-pointer`}
              onClick={() => setTimeSheetModal(false)} // Close correctly
            >
              <CgClose />
            </div>
          </div>

          <div className={ ` ${mode === "light" ? " bg-[#ffffff]" : "bg-[#141414]"} px-[20px] py-[10px]`}>
            <FormControl fullWidth margin="normal">
              <div className="mb-3">Period</div>
              <Select defaultValue="thisWeek" sx={{ height: "34px" }}>
                <MenuItem value="today">Today</MenuItem>
                <MenuItem value="thisWeek">This Week</MenuItem>
                <MenuItem value="thisMonth">This Month</MenuItem>
                <MenuItem value="yesterday">Yesterday</MenuItem>
                <MenuItem value="lastWeek">Last Week</MenuItem>
                <MenuItem value="lastMonth">Last Month</MenuItem>
                <MenuItem value="custom">Custom</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth margin="normal">
              <div className="mb-3">Clients</div>
              <Select defaultValue="All Clients" sx={{ height: "34px" }}>
                <MenuItem value="All Clients">All Clients</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth margin="normal">
              <div className="mb-3">Projects</div>
              <Select defaultValue="All Projects" sx={{ height: "34px" }}>
                <MenuItem value="All Projects">All Projects</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth margin="normal">
              <div className="mb-3">Jobs</div>
              <Select defaultValue="All Jobs" sx={{ height: "34px" }}>
                <MenuItem value="All Jobs">All Jobs</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth margin="normal">
              <div className="mb-3">Billable Status</div>
              <Select defaultValue="All" sx={{ height: "34px" }}>
                <MenuItem value="All">All</MenuItem>
              </Select>
            </FormControl>
          </div>

          <Box display="flex" alignItems="center" gap="4px" mt={2}>
            <Button
              style={{ backgroundColor: "#0088ff" }}
              variant="contained"
              color="primary"
            >
              Next
            </Button>
            <Button
              style={{
                backgroundColor: "white",
                border: "1px solid #DCDCDC",
                color: "#666666",
              }}
              variant="outlined"
              onClick={() => setTimeSheetModal(false)}
            >
              Cancel
            </Button>
          </Box>
        </div>
      </Drawer>
    );
  };

  return (
    <div className="w-full h-[42rem] flex flex-col">
      <div className="flex w-full justify-between items-center">
        <div className="flex-1"></div>

        <div className="flex flex-1 relative justify-center items-center gap-2">
          {/* Navigation Controls */}
          <div className={`flex items-center gap-2 ${mode === "light" ? "bg-white" :""} p-2 rounded-md`}>
            <IoIosArrowBack
              fontSize="small"
              className="cursor-pointer hover:text-gray-600"
              onClick={handlePrevMonth}
            />

            <Box
              onClick={() => setOpen((prev) => !prev)}
              className="relative flex items-center rounded-md cursor-pointer hover:bg-gray-200"
            >
              <IoCalendarOutline size="18px" className="text-gray-600" />
            </Box>

            <IoIosArrowForward
              fontSize="small"
              className="cursor-pointer hover:text-gray-600"
              onClick={handleNextMonth}
            />
          </div>

          {/* Display Current Month and Year */}
          <span className="text-sm flex font-medium">
            {selectedDate.format("MMMM YYYY")}
          </span>

          {/* Calendar Popup */}
          {open && (
            <Box className={`absolute top-8 z-10 shadow-md border rounded-md ${mode === "light" ? "bg-white" :"bg-[#141414]"}`}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar
                  value={selectedDate}
                  onChange={(date) => {
                    setSelectedDate(date);
                    setOpen(false);
                  }}
                />
              </LocalizationProvider>
            </Box>
          )}
        </div>

        <div className="flex flex-1 gap-3 justify-end items-center">
          <FormControl sx={{ width: "200px" }}>
            <Select
              value={selectedValue}
              onChange={(e) => setSelectedValue(e.target.value)}
              displayEmpty
              sx={{
                height: "40px",
                backgroundColor: mode === "light" ? "white":"", 
              }}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="draft">Draft</MenuItem>
              <MenuItem value="pending">Pending</MenuItem>
              <MenuItem value="approved">Approved</MenuItem>
              <MenuItem value="rejected">Rejected</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="contained"
            onClick={() => setTimeSheetModal(true)}
            sx={{
              fontSize: { xs: "10px", md: "14px" },
              backgroundColor: "#0088FF",
              width: "160px",
            }}
          >
            Create Timesheet
          </Button>

          {timeSheetModal && (
            <TimeSheetDrawer
              timeSheetModal={timeSheetModal}
              setTimeSheetModal={setTimeSheetModal}
            />
          )}

          <IconButton
            onClick={() => {
              setFilterModal(true);
            }}
            title="Filter"
          >
            <IoFilter />
          </IconButton>
        </div>
      </div>

      {/* <div className="w-full h-full flex justify-center items-center">
        <h1>
          No timesheets found for the applied filters.To add new timesheets,
          click Create Timesheet
        </h1>
      </div> */}
      {data?.length > 100 ? (
        <div>
          <div className="h-[35.1rem] mt-1 overflow-scroll">
            <CustomTable
              columns={columns}
              onRowClick={handleRowClick}
              renderActions={renderActions}
              renderRowSelection={renderRowSelection}
              data={data}
              loading={loading}
              error={error}
              sorting={sorting}
              setSorting={setSorting}
              rowSelection={rowSelection}
              setRowSelection={setRowSelection}
              pagination={pagination}
              setPagination={setPagination}
              totalRows={totalRows}
              setTotalRows={setTotalRows}
              isBulkSelect={false}
            />
          </div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            limit={limit}
            onLimitChange={handleLimitChange}
          />
        </div>
      ) : (
        <div className="px-4">
          <div
            className={`flex flex-col justify-center py-7 items-center rounded-md mt-[30px] border 
          border-[#d7e2ed] ${mode === "light" ? "bg-[#f7f9fc]" : ""}`}
          >
            <img
              style={{ width: "240px", marginBottom: "20px" }}
              src="/images/no_timeLogs.png"
              alt=""
            />

            <p className=" text-[16px] text-center mt-2">
              {" "}
              No timesheets found for the applied filters.To add new timesheets,
              click Create Timesheet
            </p>
          </div>
        </div>
      )}

      <CustomEmptyModal
        onClose={() => {
          setCreateTimesheetModal(false);
        }}
        open={createTimesheetModal}
      >
        <div className="flex flex-col gap-3">
          <div>Create Timesheet</div>
          <div className="flex flex-col gap-3">
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="period">Period</InputLabel>
              <Select
                labelId="period"
                id="period"
                value={createTimesheetPeriod}
                label="Period"
                onChange={(event) => {
                  setCeateTimesheetPeriod(event.target.value);
                }}
              >
                <MenuItem value={"today"}>Today</MenuItem>
                <MenuItem value={"thisWeek"}>This Week</MenuItem>
                <MenuItem value={"thisMonth"}>This Month</MenuItem>
                <MenuItem value={"yesterday"}>Yesterday</MenuItem>
                <MenuItem value={"lastWeek"}>Last Week</MenuItem>
                <MenuItem value={"lastMonth"}>Last Month</MenuItem>
                <MenuItem value={"custom"}>Custom</MenuItem>
              </Select>
            </FormControl>
            <div>
              {createTimesheetPeriod === "custom" ? (
                <div className="w-full flex flex-col gap-1">
                  <div>
                    <h1>Date Range</h1>
                  </div>
                  <div className="w-full flex flex-row gap-3">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker sx={{ width: "100%" }} label="From" />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker sx={{ width: "100%" }} label="To" />
                    </LocalizationProvider>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div>
            <Autocomplete
              sx={{ width: "100%" }}
              multiple
              options={["Client 1", "Client 2"]}
              getOptionLabel={(option) => option}
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Clients"
                  placeholder="Clients"
                />
              )}
              renderOption={(props, option, { selected }) => (
                <MenuItem
                  {...props}
                  key={option}
                  value={option}
                  sx={{ justifyContent: "space-between" }}
                >
                  {option}
                  {selected ? <CheckIcon color="info" /> : null}
                </MenuItem>
              )}
            />
          </div>
          <div>
            <Autocomplete
              sx={{ width: "100%" }}
              multiple
              options={["Project 1", "Project 2"]}
              getOptionLabel={(option) => option}
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Projects"
                  placeholder="Projects"
                />
              )}
              renderOption={(props, option, { selected }) => (
                <MenuItem
                  {...props}
                  key={option}
                  value={option}
                  sx={{ justifyContent: "space-between" }}
                >
                  {option}
                  {selected ? <CheckIcon color="info" /> : null}
                </MenuItem>
              )}
            />
          </div>
          <div>
            <Autocomplete
              sx={{ width: "100%" }}
              multiple
              options={["Job 1", "Job 2"]}
              getOptionLabel={(option) => option}
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Jobs"
                  placeholder="Jobs"
                />
              )}
              renderOption={(props, option, { selected }) => (
                <MenuItem
                  {...props}
                  key={option}
                  value={option}
                  sx={{ justifyContent: "space-between" }}
                >
                  {option}
                  {selected ? <CheckIcon color="info" /> : null}
                </MenuItem>
              )}
            />
          </div>
          <div>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="billableStatus">Billable Status</InputLabel>
              <Select
                labelId="billableStatus"
                id="billableStatus"
                label="Billable Status"
              >
                <MenuItem value={"all"}>All</MenuItem>
                <MenuItem value={"billable"}>Billable</MenuItem>
                <MenuItem value={"nonBillable"}>Non Billable</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="w-full flex gap-2 flex-row justify-between items-center">
            <Button
              onClick={() => {
                setCreateTimesheetModal(false);
              }}
              variant="contained"
            >
              Next
            </Button>
            <Button
              onClick={() => {
                setCreateTimesheetModal(false);
              }}
              variant="outlined"
            >
              Cancel
            </Button>
          </div>
        </div>
      </CustomEmptyModal>
      {/* <CustomModal
        title={"Filter"}
        onClose={() => {
          setFilterModal(false);
        }}
        fields={filterFields}
        open={filterModal}
        onSubmit={(data) => {
          console.log("Filter Data :- ", data);
        }}
      /> */}
      <FilterDrawer setFilterModal={setFilterModal} filterModal={filterModal} />
    </div>
  );
}
