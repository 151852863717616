import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { TbPhoneCalling } from "react-icons/tb";
import { BsThreeDots } from "react-icons/bs";

const arr = [
  {
    img_url:"/images/profileImg1.png",
    name:"Amanda Throne",
    attendence:"Present",
    role:"Software Developer",
    time:"Clocked-In"
  },
  {
    img_url:"/images/profileImg2.png",
    name:"Amina Kumar",
    attendence:"Absent",
    role:"QA Tester",
    time:"Not Yet Clocked-In"
  },
  {
    img_url:"/images/profileImg3.png",
    name:"Daniel Thompson",
    attendence:"Leave",
    role:"Frontend Developer",
    time:"Not Yet Clocked-In"
  },
  {
    img_url:"/images/profileImg4.png",
    name:"Dave Maxwell",
    attendence:"Present",
    role:"Frontend Developer",
    time:"Clocked-In"
  },
  {
    img_url:"/images/profileImg5.png",
    name:"Dwayne Graham",
    attendence:"Present",
    role:"Backend Developer",
    time:"Clocked-In"
  },
]

export default function Reportee() {
  const navigate = useNavigate();
  const [hovered, setHovered] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const options = ["Chat", "Audio Call", "Video Call"];
  const ITEM_HEIGHT = 48;
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    
      arr.map((items,i)=>(

     

        <Box
        sx={{
          backgroundColor: "background.default",
        }}
        key={i}
        className="w-full h-full flex gap-3 sm:p-3 flex-row justify-center items-center rounded-lg cursor-pointer "
        // onMouseEnter={() => setHovered(true)}
        // onMouseLeave={() => setHovered(false)}
      >
        <div
          onClick={() => {
            navigate("/performance/view");
          }}
          className="w-full flex gap-3 flex-row justify-start items-center text-start"
        >
          <div>
            <img
              alt="Steward Graham"
              sx={{ height: "35px", width: "35px" }}
              src={items.img_url}
            />
          </div>
          <div className="text-sm">
           <div className="flex gap-2 items-center">
           <h1>{items.name}</h1>

           <div className='flex gap-1 items-center'>
           <div
  className={`w-[8px] h-[8px] rounded-full ${
    items.attendence === "Present"
      ? "bg-[#3767B1]"
      : items.attendence === "Absent"
      ? "bg-[#FF9B05]"
      : "bg-[#44B14F]"
  }`}
/>
           <h1 className="text-[#FFFFFF] text-[12px] font-[400]">{items.attendence}</h1>
           </div>
          
           </div>
           <div className='flex gap-2 items-center'>
            <h1 className="text-[#A5A5A5] text-[14px] font-[400]">{items.role}</h1>
            <h1
              className={`text-[12px] font-[400] ${
                items.time === "Clocked-In" ? "text-[#3767B1]" : "text-[#F13B3B]"
              }`}
            >
              {items.time}
            </h1>

           </div>
          </div>
        </div>
  
  
        <div className="min-w-10">
         
            <>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
              >
                <BsThreeDots />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                slotProps={{
                  paper: {
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                      width: "20ch",
                      backgroundColor:"#1B1B1B"
                    },
                  },
                }}
              >
                {options.map((option) => (
                  <MenuItem
                    key={option}
                    selected={option === "Pyxis"}
                    onClick={handleClose}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </>
         
        </div>
  
      </Box>
      
    ))
  );
}
