import React from 'react'
import LeaveReportees from './LeaveReportees'

export default function DirectReporteesGrid({searchTerm}) {
    return (
        <div className="w-full sm:grid sm:gap-3 sm:grid-cols-3">
            <LeaveReportees searchTerm={searchTerm} />
        </div>
    )
}
