import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Close } from "@mui/icons-material";
import React, { useState } from "react";
import { HiDotsHorizontal } from "react-icons/hi";
import { CiImport } from "react-icons/ci";
import { IoFilter } from "react-icons/io5";
import { LiaTrashAlt } from "react-icons/lia";
import { MdOutlineOpenInFull } from "react-icons/md";
import { CiExport } from "react-icons/ci";
import CustomFilter from "./CustomFilter";
import CustomCreateView from "./CustomCreateView";
import CustomImport from "./CustomImport";
import CustomExport from "./CustomExport";
import CustomBulkUpload from "./CustomBulkUpload";

const fields = [
  { field: "employeeID", label: "Employee ID" },
  { field: "firstName", label: "First Name" },
  { field: "lastName", label: "Last Name" },
  { field: "nickname", label: "Nick Name" },
  { field: "email", label: "Email Address" },
  { field: "department", label: "Department" },
  { field: "designation", label: "Designation" },
  { field: "clikkleHR", label: "Clikkle HR" },
  { field: "employmentType", label: "Employment Type" },
  { field: "employeeStatus", label: "Employee Status" },
  { field: "sourceOfHire", label: "Source of Hire" },
  { field: "dateOfJoining", label: "Date of Joining" },
  { field: "currentExperience", label: "Current Experience" },
  { field: "totalExperience", label: "Total Experience" },
  { field: "reportingManager", label: "Reporting Manager" },
  { field: "dateOfBirth", label: "Date of Birth" },
  { field: "age", label: "Age" },
  { field: "gender", label: "Gender" },
  { field: "maritalStatus", label: "Marital Status" },
  { field: "aboutMe", label: "About Me" },
  { field: "expertise", label: "Expertise" },
  { field: "workPhoneNumber", label: "Work Phone Number" },
  { field: "extension", label: "Extension" },
  { field: "seatingLocation", label: "Seating Location" },
  { field: "tags", label: "Tags" },
  { field: "personalMobileNumber", label: "Personal Mobile Number" },
  { field: "personalEmailAddress", label: "Personal Email Address" },
  { field: "dateOfExit", label: "Date of Exit" },
  { field: "addedBy", label: "Added By" },
  { field: "modifiedBy", label: "Modified By" },
  { field: "addedTime", label: "Added Time" },
  { field: "modifiedTime", label: "Modified Time" },
  { field: "onboardingStatus", label: "Onboarding Status" },
  { field: "presentAddress", label: "Present Address" },
  { field: "permanentAddress", label: "Permanent Address" },
];

const CustomRow = ({
  openAddModal,
  ButtonLabel = "Add Candidate",
  toggleFullScreen,
  viewOptions = [
    { value: "one", label: "One" },
    { value: "two", label: "Two" },
  ],
  fileOptions = [
    { icon: <CiImport />, label: "Import" },
    { icon: <CiExport />, label: "Export" },
    { icon: <CiImport />, label: "Bulk File Upload" },
  ],
  historyExport,
  setHistoryExport,
  checkBoxes = false,
}) => {
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [toggleDrawer, setToggleDrawer] = useState(null);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [openExportModal, setOpenExportModal] = useState(false);
  const [openBulkModal, setOpenBulkModal] = useState(false);
  const isMenuopen = Boolean(menuAnchor);
  const [anchorEl, setAnchorEl] = useState(null);

  const [filterFormValues, setFilterFormValues] = useState({
    employees: [],
    employeeStatus: "allCandidates",
    department: "",
    location: "",
    filterFields: [],
  });

  const handleCheckboxChange = (field) => (event) => {
    const checkedFields = filterFormValues.filterFields;
    if (event.target.checked) {
      setFilterFormValues((prev) => ({
        ...prev,
        filterFields: [...checkedFields, { field, condition: "is", value: "" }],
      }));
    } else {
      setFilterFormValues((prev) => ({
        ...prev,
        filterFields: checkedFields.filter((item) => item.field !== field),
      }));
    }
  };

  const handleFilterFormChange = (name, value) => {
    setFilterFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleFilterValueChange = (field, condition, value) => {
    setFilterFormValues((prev) => ({
      ...prev,
      filterFields: prev.filterFields.map((item) =>
        item.field === field ? { ...item, condition, value } : item
      ),
    }));
  };
  const handleFilterFormSubmit = (e) => {
    e.preventDefault();
    console.log("Filter Data :- ", filterFormValues);
    // onClose();
  };

  const handleMenuClick = (label) => {
    if (label === "Import") setOpenImportModal(true);
    if (label === "Export") setOpenExportModal(true);
    if (label === "Bulk File Upload") setOpenBulkModal(true);
    if (label === "History Export") {
      setOpenExportModal(true);
      setHistoryExport(true);
    }
  };

  const openFilterModal = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeFilterModal = () => {
    setAnchorEl(null);
  };
  const { mode } = useTheme();
  //

  const [createTableViewModal, setCreateTableViewModal] = useState(false);
  const [createTableViewForm, setCreateTableViewForm] = useState({
    view_name: "",
    default: false,
    viewPermission: "onlyMe",
    permission: {
      users: ["8889"],
      // departments: ["1", "2"],
      // roles: ["1", "2"],
      // locations: ["1", "2"],
    },
    select_columns: [
      // {
      //   title: "EmployeeID",
      //   key: "employee.employee_id",
      // },
      // {
      //   title: "First Name",
      //   key: "employee.name",
      // },
    ],
    criterias: [
      {
        relationship: null,
        column: null,
        title: "",
        condition: null,
        value: null,
      },
    ],
  });
  const createTableViewColumns = [
    {
      label: "Employee",
      key: "employee",
      options: [
        { title: "Employee 1", name: "employee1" },
        { title: "Employee 2", name: "employee2" },
      ],
    },
    {
      label: "Department",
      key: "department",
      options: [
        { title: "Department 1", name: "department1" },
        { title: "Department 2", name: "department2" },
      ],
    },
    {
      label: "Location",
      key: "location",
      options: [
        { title: "Location 1", name: "location1" },
        { title: "Location 2", name: "location2" },
      ],
    },
    {
      label: "Designation",
      key: "designation",
      options: [
        { title: "Designation 1", name: "designation1" },
        { title: "Designation 2", name: "designation2" },
      ],
    },
    {
      label: "Reporting Manager",
      key: "reportingManager",
      options: [
        { title: "Reporting Manager 1", name: "reportingManager1" },
        { title: "Reporting Manager 2", name: "reportingManager2" },
      ],
    },
    {
      label: "Added By",
      key: "addedBy",
      options: [
        { title: "Added By 1", name: "addedBy1" },
        { title: "Added By 2", name: "addedBy2" },
      ],
    },
    {
      label: "Modified By",
      key: "modifiedBy",
      options: [
        { title: "Modified By 1", name: "modifiedBy1" },
        { title: "Modified By 2", name: "modifiedBy2" },
      ],
    },
    {
      label: "Present Address",
      key: "presentAddress",
      options: [
        { title: "Present Address 1", name: "presentAddress1" },
        { title: "Present Address 2", name: "presentAddress2" },
      ],
    },
    {
      label: "Permanent Address",
      key: "permanentAddress",
      options: [
        { title: "Permanent Address 1", name: "permanentAddress1" },
        { title: "Permanent Address 2", name: "permanentAddress2" },
      ],
    },
  ];
  const createTableViewSpecifics = [
    {
      label: "User",
      options: [
        { title: "Hello 1", id: "hello1" },
        { title: "Hello 2", id: "hello2" },
      ],
    },
    {
      label: "Department",
      options: [
        { title: "Hello 1", id: "hello1" },
        { title: "Hello 2", id: "hello2" },
      ],
    },
    {
      label: "Role",
      options: [
        { title: "Hello 1", id: "hello1" },
        { title: "Hello 2", id: "hello2" },
      ],
    },
    {
      label: "Locations",
      options: [
        { title: "Hello 1", id: "hello1" },
        { title: "Hello 2", id: "hello2" },
      ],
    },
  ];
  const createTableViewCriterias = [
    {
      label: "Employee",
      key: "employee",
      options: [
        { title: "Employee ID", key: "employee", name: "employeeID" },
        { title: "Full Name", key: "employee", name: "fullName" },
      ],
    },
    {
      label: "Department",
      key: "department",
      options: [
        { title: "Department 1", key: "department", name: "department1" },
        { title: "Department 2", key: "department", name: "department2" },
      ],
    },
    {
      label: "Location",
      key: "location",
      options: [
        { title: "Location 1", key: "location", name: "location1" },
        { title: "Location 2", key: "location", name: "location2" },
      ],
    },
    {
      label: "Designation",
      key: "designation",
      options: [
        { title: "Designation 1", key: "designation", name: "designation1" },
        { title: "Designation 2", key: "designation", name: "designation2" },
      ],
    },
    {
      label: "Reporting Manager",
      key: "reportingManager",
      options: [
        {
          title: "Reporting Manager 1",
          key: "reportingManager",
          name: "reportingManager1",
        },
        {
          title: "Reporting Manager 2",
          key: "reportingManager",
          name: "reportingManager2",
        },
      ],
    },
    {
      label: "Added By",
      key: "addedBy",
      options: [
        { title: "Added By 1", key: "addedBy", name: "addedBy1" },
        { title: "Added By 2", key: "addedBy", name: "addedBy2" },
      ],
    },
    {
      label: "Modified By",
      key: "modifiedBy",
      options: [
        { title: "Modified By 1", key: "modifiedBy", name: "modifiedBy1" },
        { title: "Modified By 2", key: "modifiedBy", name: "modifiedBy2" },
      ],
    },
    {
      label: "Present Address",
      key: "presentAddress",
      options: [
        {
          title: "Present Address 1",
          key: "presentAddress",
          name: "presentAddress1",
        },
        {
          title: "Present Address 2",
          key: "presentAddress",
          name: "presentAddress2",
        },
      ],
    },
    {
      label: "Permanent Address",
      key: "permanentAddress",
      options: [
        {
          title: "Permanent Address 1",
          key: "permanentAddress",
          name: "permanentAddress1",
        },
        {
          title: "Permanent Address 2",
          key: "permanentAddress",
          name: "permanentAddress2",
        },
      ],
    },
  ];
  const createTableViewCriteriasOptions = [
    {
      label: "Employee",
      key: "employee",
      options: [
        { type: "text", title: "Option 1", key: "employee", name: "option1" },
        { type: "text", title: "Option 2", key: "employee", name: "option2" },
      ],
    },
    {
      label: "Department",
      key: "department",
      options: [
        {
          type: "select",
          options: [
            { title: "Option 1", key: "department", name: "option1" },
            { title: "Option 2", key: "department", name: "option2" },
          ],
          title: "Option 1",
          key: "department",
          name: "option1",
        },
        { type: "text", title: "Option 2", key: "department", name: "option2" },
      ],
    },
    {
      label: "Location",
      key: "location",
      options: [
        {
          type: "datetime",
          title: "Option 1",
          key: "location",
          name: "option1",
        },
        {
          type: "datetime",
          title: "Option 2",
          key: "location",
          name: "option2",
        },
      ],
    },
    {
      label: "Designation",
      key: "designation",
      options: [
        {
          type: "none",
          title: "Option 1",
          key: "designation",
          name: "option1",
        },
        {
          type: "text",
          title: "Option 2",
          key: "designation",
          name: "option2",
        },
      ],
    },
    {
      label: "Reporting Manager",
      key: "reportingManager",
      options: [
        {
          type: "text",
          title: "Option 1",
          key: "reportingManager",
          name: "option1",
        },
        {
          type: "text",
          title: "Option 2",
          key: "reportingManager",
          name: "option2",
        },
      ],
    },
    {
      label: "Added By",
      key: "addedBy",
      options: [
        { type: "text", title: "Option 1", key: "addedBy", name: "option1" },
        { type: "text", title: "Option 2", key: "addedBy", name: "option2" },
      ],
    },
    {
      label: "Modified By",
      key: "modifiedBy",
      options: [
        { type: "text", title: "Option 1", key: "modifiedBy", name: "option1" },
        { type: "text", title: "Option 2", key: "modifiedBy", name: "option2" },
      ],
    },
    {
      label: "Present Address",
      key: "presentAddress",
      options: [
        {
          type: "text",
          title: "Option 1",
          key: "presentAddress",
          name: "option1",
        },
        {
          type: "text",
          title: "Option 2",
          key: "presentAddress",
          name: "option2",
        },
      ],
    },
    {
      label: "Permanent Address",
      key: "permanentAddress",
      options: [
        {
          type: "text",
          title: "Option 1",
          key: "permanentAddress",
          name: "option1",
        },
        {
          type: "text",
          title: "Option 2",
          key: "permanentAddress",
          name: "option2",
        },
      ],
    },
  ];
  const createTableViewCriteriasRelationships = [
    { title: "AND", name: "and" },
    { title: "OR", name: "or" },
  ];
  //

  const closeExportModal = () => {
    setOpenExportModal(false);
    setHistoryExport(false);
  };

  return (
    <div>
      <CustomCreateView
        open={toggleDrawer}
        onClose={() => setToggleDrawer(false)}
        forWhom={"Candidate"}
        form={createTableViewForm}
        setForm={setCreateTableViewForm}
        columns={createTableViewColumns}
        specifics={createTableViewSpecifics}
        criterias={createTableViewCriterias}
        criteriasOptions={createTableViewCriteriasOptions}
        criteriasRelationships={createTableViewCriteriasRelationships}
      />
      <CustomFilter
        anchorEl={anchorEl}
        onClose={closeFilterModal}
        content={
          <>
            <form
              onSubmit={handleFilterFormSubmit}
              className="flex flex-col gap-3"
            >
              <div>
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="systemFilters"
                    id="systemFilters"
                  >
                    <Typography component="span">System Filters</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Autocomplete
                      sx={{ width: "100%" }}
                      multiple
                      options={["Employee 1", "Employee 2"]}
                      getOptionLabel={(option) => option}
                      disableCloseOnSelect
                      onChange={(e, value) =>
                        handleFilterFormChange("employees", value)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Employee"
                          placeholder="Employee"
                        />
                      )}
                    />
                    <FormControl sx={{ mb: "15px", width: "100%" }}>
                      <InputLabel id="employeeStatus">
                        Employee Status
                      </InputLabel>
                      <Select
                        labelId="employeeStatus"
                        id="employeeStatus"
                        label="Employee Status"
                        onChange={(e) =>
                          handleFilterFormChange(
                            "employeeStatus",
                            e.target.value
                          )
                        }
                      >
                        <MenuItem value={"allCandidates"}>
                          All Candidates
                        </MenuItem>
                        <MenuItem value={"allActiveEmployeeCandidates"}>
                          All Active Employee Candidates
                        </MenuItem>
                        <MenuItem value={"exEmployeeCandidates"}>
                          Ex-Employee Candidates
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <Autocomplete
                      sx={{ width: "100%" }}
                      options={["All", "HR", "IT", "Management", "Marketing"]}
                      renderInput={(params) => (
                        <TextField {...params} label="Department" />
                      )}
                      onChange={(e, value) =>
                        handleFilterFormChange("department", value)
                      }
                    />
                    <Autocomplete
                      sx={{ width: "100%" }}
                      options={["Location 1", "Location 2"]}
                      renderInput={(params) => (
                        <TextField {...params} label="Location" />
                      )}
                      onChange={(e, value) =>
                        handleFilterFormChange("location", value)
                      }
                    />
                  </AccordionDetails>
                </Accordion>
              </div>

              <div>
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography component="span">Field</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="flex flex-col gap-3">
                      {fields.map((field, index) => (
                        <div key={index}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={handleCheckboxChange(field.field)}
                                  checked={filterFormValues.filterFields.some(
                                    (item) => item.field === field.field
                                  )}
                                />
                              }
                              label={field.label}
                            />
                          </FormGroup>

                          {filterFormValues.filterFields
                            .filter((item) => item.field === field.field)
                            .map((filter, idx) => (
                              <div className="flex flex-col gap-3" key={idx}>
                                <FormControl sx={{ width: "100%" }}>
                                  <InputLabel id={`filter-${index}`}>
                                    Condition
                                  </InputLabel>
                                  <Select
                                    labelId={`filter-${index}`}
                                    id={`filter-${index}`}
                                    label="Condition"
                                    value={filter.condition}
                                    onChange={(e) =>
                                      handleFilterValueChange(
                                        field.field,
                                        e.target.value,
                                        filter.value
                                      )
                                    }
                                  >
                                    <MenuItem value={"is"}>Is</MenuItem>
                                    <MenuItem value={"isNot"}>Is Not</MenuItem>
                                    <MenuItem value={"startWith"}>
                                      Start With
                                    </MenuItem>
                                    <MenuItem value={"endWith"}>
                                      End With
                                    </MenuItem>
                                    <MenuItem value={"contains"}>
                                      Contains
                                    </MenuItem>
                                    <MenuItem value={"notContains"}>
                                      Not Contains
                                    </MenuItem>
                                    <MenuItem value={"Like"}>Like</MenuItem>
                                    <MenuItem value={"isEmpty"}>
                                      Is Empty
                                    </MenuItem>
                                    <MenuItem value={"isNotEmpty"}>
                                      Is Not Empty
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                                <TextField
                                  sx={{ width: "100%" }}
                                  variant="outlined"
                                  label="Value"
                                  placeholder="Value"
                                  value={filter.value}
                                  onChange={(e) =>
                                    handleFilterValueChange(
                                      field.field,
                                      filter.condition,
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                            ))}
                        </div>
                      ))}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            </form>
          </>
        }
      />
      <CustomImport
        onClose={() => setOpenImportModal(false)}
        open={openImportModal}
      />
      <CustomExport
        onClose={closeExportModal}
        open={openExportModal}
        checkBoxes={checkBoxes}
        historyExport={historyExport}
        setHistoryExport={setHistoryExport}
      />
      <CustomBulkUpload
        onClose={() => setOpenBulkModal(false)}
        open={openBulkModal}
      />
      <div className="w-full flex flex-col gap-3 justify-between  md:flex-row">
        <div className="flex flex-col  sm:flex-row sm:items-center justify-start gap-1 sm:gap-3">
          <div>
            <FormControl sx={{ width: "250px" }}>
              {/* <InputLabel id="table-view">Table View</InputLabel> */}
              <Select labelId="table-view" id="table-view" size="small">
                {viewOptions.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
                <div className="w-full ">
                  <Button
                    sx={{ width: "100%" }}
                    // onClick={viewCreateTableModal}
                    onClick={() => setToggleDrawer(true)}
                    variant="outlined"
                  >
                    Create Table View
                  </Button>
                </div>
              </Select>
            </FormControl>
          </div>

          <div className="flex ">
            <div>
              {/* <p>Edit</p> */}
              <Button
                onClick={() => setToggleDrawer(true)}
                size="small"
                variant="text"
              >
                Edit
              </Button>
            </div>
            {/* <div className="h-9 w-[0.15rem] rounded-lg bg-neutral-500 hidden sm:block" /> */}
            <div>
              <Button variant="text" size="small" color="error">
                Delete
              </Button>
            </div>
          </div>
        </div>
        <div className="flex flex-col  sm:flex-row gap-1 sm:gap-3 items-start sm:items-center justify-start">
          <FormControl>
            {/* <InputLabel id="data">Data</InputLabel> */}
            <Select
              sx={{
                width: "250px",
              }}
              labelId="data"
              size="small"
              id="data"
              //   label="Data"
            >
              <MenuItem value={"allData"}>All Data</MenuItem>
              <MenuItem value={"reporteesPlusMyData"}>
                Reportees + My Data
              </MenuItem>
              <MenuItem value={"reporteesData"}>Reportees Data</MenuItem>
              <MenuItem value={"directReporteesData"}>
                Direct Reportees Data
              </MenuItem>
              <MenuItem value={"myData"}>My Data</MenuItem>
            </Select>
          </FormControl>
          <button
            className={`px-3 py-1.5 bg-[#0088FF] text-sm rounded-md text-white `}
            onClick={openAddModal}
          >
            {ButtonLabel}
          </button>
          <div className="flex gap-1">
            <IconButton
              onClick={toggleFullScreen}
              sx={{
                backgroundColor: mode == "light" ? "#edf0f4" : "#3333",
                fontSize: 16,
                borderRadius: "6px",
              }}
              title="open in full screen"
            >
              <MdOutlineOpenInFull />
            </IconButton>
            <IconButton
              onClick={openFilterModal}
              sx={{
                backgroundColor: mode == "light" ? "#edf0f4" : "#3333",
                fontSize: 16,
                borderRadius: "6px",
              }}
              title="Filter"
            >
              <IoFilter />
            </IconButton>
            <div>
              <IconButton
                id="basic-button"
                sx={{
                  backgroundColor: mode == "light" ? "#edf0f4" : "#3333",
                  fontSize: 16,
                  borderRadius: "6px",
                }}
                aria-controls={isMenuopen ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={isMenuopen ? "true" : undefined}
                onClick={(event) => {
                  setMenuAnchor(event.currentTarget);
                }}
              >
                <HiDotsHorizontal />
              </IconButton>

              <Menu
                id="basic-menu"
                anchorEl={menuAnchor}
                open={isMenuopen}
                onClose={() => setMenuAnchor(null)}
                MenuListProps={{ "aria-labelledby": "basic-button" }}
              >
                {fileOptions.map((option, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => handleMenuClick(option.label)}
                  >
                    <div className="flex flex-row gap-3 justify-between items-center">
                      <span className="text-2xl">{option.icon}</span>
                      <h1>{option.label}</h1>
                    </div>
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomRow;
