import { useEffect, useState } from "react";
import { useSwitchScreen } from "../../hooks/SwitchScreenContext";
import { Tabs, Tab, useMediaQuery, useTheme, Box } from "@mui/material";
import TeamFeeds from "../DashComponents/TeamProject/TeamFeeds";
import LeaveBalance from "./MyData/LeaveBalance";
import LeaveRequests from "../Operations/LeaveTracker/LeaveRequests";
import CompensatoryRequest from "./MyData/CompensatoryRequest";
import Shift from "../DashComponents/Shift";
import LeaveSummary from "./MyData/LeaveSummary";
import Reportees from "../Dashboard/Team/Reportees";
import OnLeave from "./Team/OnLeave";
import Holidays from "../Operations/LeaveTracker/Holidays";
import LeaveAppPage from "../Attendance/AttendView/LeaveAppPage.jsx/LeaveAppPage";
import LeaveSettingPage from "../Attendance/AttendView/LeaveSetting/LeaveSettingPage";
import LeaveTrackerReportees from "./Team/LeaveTrackerReportees/LeaveTrackerReportees";
import TeamCompensatoryRequest from "./Team/TeamCompensatoryRequest";
import NoEmployee from "../../components/NoEmployee";
import { useTheme as useCustomTheme } from '../../style/theme';
import { useLocation } from "react-router-dom";

export default function LeaveTrackerHome() {
  const pathname = window.location.pathname;
  let currentOrg = JSON.parse(localStorage.getItem("org"));
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tabParam = searchParams.get("tab");


  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (pathname === "/leaveTracker/myData") {
      if (tabParam === "leaveRequests") {
        setSwitchScreen({ first: "myData", second: "leaveRequests" });
      } else {
        setSwitchScreen({ first: "myData", second: "leaveSummary" });
      }
    } else if (pathname === "/leaveTracker/team") {
      setSwitchScreen({ first: "team", second: currentOrg?.type == "Owner" ? "reportees" : "onLeave" });
    }
    else if (pathname === "/leaveTracker/holidays") {
      setSwitchScreen({ first: "holidays", second: "holidays" });
    }
  }, [pathname, tabParam]);

  const [switchScreen, setSwitchScreen] = useState({
    first: "myData",
    second: "leaveSummary",
  });

  const handleSwitchScreen = (screen) => {
    setSwitchScreen(screen);
  };

  // Fix: Ensure all tabs have a `value` property instead of `second`
  const tabMappings = {
    myData: currentOrg?.type == "Owner" ? [
      { label: "Leave Summary", value: "leaveSummary" },
      { label: "Leave Balance", value: "leaveBalance" },
      { label: "Leave Requests", value: "leaveRequests" },
      { label: "Leave Setting", value: "leaveSetting" },
      { label: "Compensatory Request", value: "compensatoryRequest" },
      { label: "Shift", value: "shift" },
    ] :
      [
        { label: "Leave Summary", value: "leaveSummary" },
        { label: "Leave Balance", value: "leaveBalance" },
        { label: "Leave Requests", value: "leaveRequests" },
        { label: "Compensatory Request", value: "compensatoryRequest" },
      ],
    team:
      currentOrg?.type == "Owner" ?
        [
          { label: "Reportees", value: "reportees" },
          { label: "On Leave", value: "onLeave" },
          { label: "Leave Requests", value: "leaveRequests" },
          { label: "Compensatory Request", value: "compensatoryRequest" },
        ] :
        [
          { label: "On Leave", value: "onLeave" },
        ]
    ,
    holidays: [],
  };

  const renderTabs = () => {
    const currentTabs = tabMappings[switchScreen.first] || [];

    return (
      <Tabs
        value={currentTabs.findIndex((tab) => tab.value === switchScreen.second)} // Fix: `value` should be `tab.value`
        onChange={(event, newValue) => {
          const selectedTab = currentTabs[newValue];
          handleSwitchScreen({
            first: switchScreen.first,
            second: selectedTab.value, // Fix: Use `value` instead of `second`
          });
        }}
        textColor="primary"
        indicatorColor="primary"
        aria-label="Navigation Tabs"
        variant={isMobile ? "scrollable" : "standard"}
        TabIndicatorProps={{
          sx: { bottom: "8px" },
        }}
      >
        {currentTabs.map((tab, index) => (
          <Tab
            key={index}
            label={tab.label}
            sx={{
              fontSize: { xs: "12px", md: "14px" },
              minWidth: { xs: "20px", md: "auto" },
              paddingLeft: { md: "15px", xs: 1 },
              paddingRight: { md: "15px", xs: 1 },
            }}
          />
        ))}
      </Tabs>
    );
  };

  const { mode: themeMode } = useCustomTheme();

  return (
    <Box sx={{ background: themeMode === "dark" ? "#141414" : "#f7f9fc" }}>

      {currentOrg?.type !== "Pending Onboarding" ? (<>
        <div className="flex flex-row gap-3 rounded-lg">{renderTabs()}</div>
        {/* Fix: Remove redundant conditional checks and simplify */}
        {switchScreen.first === "myData" && switchScreen.second === "leaveSummary" && <LeaveSummary />}
        {switchScreen.first === "myData" && switchScreen.second === "leaveBalance" && <LeaveBalance />}
        {switchScreen.first === "myData" && switchScreen.second === "leaveRequests" && <LeaveAppPage />}
        {switchScreen.first === "myData" && switchScreen.second === "compensatoryRequest" && <CompensatoryRequest />}
        {switchScreen.first === "myData" && switchScreen.second === "shift" && <Shift />}
        {switchScreen.first === "myData" && switchScreen.second === "leaveSetting" && <LeaveSettingPage />}
        {switchScreen.first === "team" && switchScreen.second === "reportees" && <div><LeaveTrackerReportees /></div>}
        {switchScreen.first === "team" && switchScreen.second === "onLeave" && <OnLeave />}
        {switchScreen.first === "team" && switchScreen.second === "leaveRequests" && <LeaveRequests />}
        {switchScreen.first === "team" && switchScreen.second === "compensatoryRequest" && <TeamCompensatoryRequest />}
        {switchScreen.first === "holidays" && switchScreen.second === "holidays" && <Holidays />}
      </>) : (<NoEmployee />)}
    </Box>
  );
}
