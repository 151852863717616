import React, { useEffect, useRef, useState } from 'react'
import { useTheme } from '../../style/theme';
import { IoSearch } from 'react-icons/io5';

const SelectProject = () => {
      const [isProjectOpen, setIsProjectOpen] = useState(false);
      const [searchProjects, setSearchProjects] = useState("");
      const [selectedProject, setSelectedProject] = useState("Select Project");
      const [projectModalOpen, SetProjectModalOpen] = useState(false);
      const dropdownProjectRef = useRef(null);
        const projects = ["Test project F"];

        
  const toggleprojectsDropdown = () => {
    setIsProjectOpen(!isProjectOpen);
  };

  const closeProjectsDropdown = (e) => {
    if (
      dropdownProjectRef.current &&
      !dropdownProjectRef.current.contains(e.target)
    ) {
      setIsProjectOpen(false);
    }
  };

  const handleProjectSelect = (project) => {
    setSelectedProject(project);
    setIsProjectOpen(false);
  };

    useEffect(() => {
      document.addEventListener("mousedown", closeProjectsDropdown);
      return () =>
        document.removeEventListener(
          "mousedown",
          closeProjectsDropdown,
        );
    }, []);

    const {mode} = useTheme()

  return (
    <div
                  className="relative w-full "
                  onClick={toggleprojectsDropdown}
                  ref={dropdownProjectRef}
                >
                  <select
                    className={`w-full h-[34px] px-2 text-[14px] py-2 pointer-events-none text-left ${
                      mode === "light" ? "bg-white" : "bg-transparent"
                    } border rounded outline-none`}
                  >
                    <option value={selectedProject}>{selectedProject} </option>
                  </select>
                  {isProjectOpen && (
                    <div
                      className={`w-full z-40  absolute left-0 mt-2 ${
                        mode === "light" ? "bg-white" : "bg-[#141414]"
                      } border  rounded-md shadow-lg`}
                    >
                      <div
                        className={`${mode == "light" ? "bg-[#e1e4e7]" : ""}  p-2`}
                      >
                        <div
                          className={`flex  items-center border border-[#0088ff] rounded-sm h-[30px] px-2 ${
                            mode === "light" ? "bg-[#fff] " : ""
                          }`}
                        >
                          <IoSearch color="#c1c1c1" />
                          <input
                            type="text"
                            // placeholder="Search"
                            value={searchProjects}
                            onChange={(e) => setSearchProjects(e.target.value)}
                            className="w-full px-3 py-2 border-none rounded-md outline-none bg-transparent "
                          />
                        </div>
                      </div>
                      <ul className="max-h-48 overflow-y-auto">
                        <li className="px-4 py-2 bg-gray-100 text-gray-600">
                          Select Project
                        </li>
                        {projects
                          .filter((p) =>
                            p.toLowerCase().includes(searchProjects.toLowerCase())
                          )
                          .map((project, index) => (
                            <li
                              key={index}
                              className={`px-4 py-2 hover:bg-gray-100  cursor-pointer`}
                              onClick={() => handleProjectSelect(project)}
                            >
                              {project}
                            </li>
                          ))}
                      </ul>
                    </div>
                  )}
                </div>
  )
}

export default SelectProject
