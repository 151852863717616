import React, { useState } from "react";
import { Tab, Tabs } from "@mui/material";

import UserSpecificOperations from "./UserSpecificOperations/UserSpecificOperations";
import Methods from "./Methods/Methods";
import Appraisal from "./Appraisal/Appraisal";
import Import from "./Import/Import";
import Export from "./Export/Export";
import KRA from "./KRA/KRA";
import Competency from "./Competency/Competency";
import SkillSet from "./SkillSet/SkillSet";
import { useSwitchScreen } from "../../../hooks/SwitchScreenContext";

export default function Performance() {
   const { switchScreen } = useSwitchScreen(); 

  return (
    <div className="w-full min-h-full h-fit flex flex-col gap-3 px-3 rounded-lg border border-[#1e2734]">
     
      {switchScreen.primary === "userSpecificOperations" ? (
        <>
          <UserSpecificOperations />
        </>
      ) : (
        <></>
      )}
      {switchScreen.primary === "methods" ? (
        <>
          <Methods />
        </>
      ) : (
        <></>
      )}
      {switchScreen.primary === "appraisal" ? (
        <>
          <Appraisal />
        </>
      ) : (
        <></>
      )}
      {switchScreen.primary === "import" ? (
        <>
          <Import />
        </>
      ) : (
        <></>
      )}
      {switchScreen.primary === "export" ? (
        <>
          <Export />
        </>
      ) : (
        <></>
      )}
      {switchScreen.primary === "kra" ? (
        <>
          <KRA />
        </>
      ) : (
        <></>
      )}
      {switchScreen.primary === "competency" ? (
        <>
          <Competency />
        </>
      ) : (
        <></>
      )}
      {switchScreen.primary === "skillSet" ? (
        <>
          <SkillSet />
        </>
      ) : (
        <></>
      )}
    </div>
  );
}
