import React, { useEffect, useState } from "react";
import { useUser } from "../../../../hooks/Authorize";
import morning from "../../../../assets/Greeting/PNG/Morning.png";
import afternoon from "../../../../assets/Greeting/PNG/Afternoon.png";
import evening from "../../../../assets/Greeting/PNG/Evening.png";
import night from "../../../../assets/Greeting/PNG/Night.png";

export default function Greeting() {
  const platformUser = useUser();
  const [time, setTime] = useState(new Date().getHours());
  const [greet, setGreet] = useState({
    greetMessage: "",
    greetImage: morning,
  });

  useEffect(() => {
    if (time >= 1 && time <= 4) {
      setGreet({
        greetMessage: "Early Morning",
        greetImage: morning,
      });
    } else if (time >= 5 && time <= 11) {
      setGreet({
        greetMessage: "Good Morning",
        greetImage: morning,
      });
    } else if (time >= 12 && time <= 16) {
      setGreet({
        greetMessage: "Good Afternoon",
        greetImage: afternoon,
      });
    } else if (time >= 17 && time <= 21) {
      setGreet({
        greetMessage: "Good Evening",
        greetImage: evening,
      });
    } else {
      setGreet({
        greetMessage: "Good Night",
        greetImage: night,
      });
    }
  }, [time]);

  return (
    <div className="w-full py-4 sm:py-0 sm:min-h-24 flex flex-row gap-3 px-2 sm:px-5 justify-start items-center border rounded-lg border-[#1e2734]">
      <div>
        <img src={greet.greetImage} alt="" className="h-10 w-12 sm:h-14 sm:w-14 bg-blend-color-burn" />
      </div>
      <div className="w-full">
        <div className="flex flex-row gap-1 text-[14px] w-full sm:text-lg font-medium">
          <h1>{greet.greetMessage},</h1>
          <h1 className="text-blue-400">
            {platformUser?.firstName && platformUser?.lastName ? (
              <>{platformUser?.firstName + " " + platformUser?.lastName}</>
            ) : (
              <></>
            )}
          </h1>
        </div>
        <div>
          <h1 className="text-[11px] sm:text-xs">
            Wishing you a successful and productive day ahead!
          </h1>
        </div>
      </div>
    </div>
  );
}
