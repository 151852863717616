import { createContext, useState, useContext } from "react";

const SwitchScreenContext = createContext();

export const SwitchScreenProvider = ({ children }) => {
  const [switchScreen, setSwitchScreen] = useState({ primary: "trackOnboarding" });

  return (
    <SwitchScreenContext.Provider value={{ switchScreen, setSwitchScreen }}>
      {children}
    </SwitchScreenContext.Provider>
  );
};

export const useSwitchScreen = () => useContext(SwitchScreenContext);
