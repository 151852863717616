import React from 'react'
import FeedsHeader from '../Feeds/FeedsHeader'
import NoFeeds from '../Feeds/NoFeeds'

export default function TeamFeeds() {
    return (<>
        <FeedsHeader />
        <NoFeeds />
    </>)
}
