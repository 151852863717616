import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  IconButton,
  Modal,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  TextField,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  FormControlLabel,
  Menu,
  FormGroup,
  Checkbox,
  Card,
  CardContent,
  CardActions,
  Divider,
  Box,
  CardHeader,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { IoFilter } from "react-icons/io5";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { HiDotsHorizontal } from "react-icons/hi";
import { CiImport } from "react-icons/ci";
import { CiExport } from "react-icons/ci";
import { LiaTrashAlt } from "react-icons/lia";
import CustomTable from "../../../components/CustomTable";
import Pagination from "../../../components/Pagination";
import dayjs from "dayjs";
import CustomModal from "../../../components/CustomModal";
import axios from "axios";
import { useMessage } from "../../../components/Header";
import DeparmentModal from "../../../components/DeparmentModal";
import useModal from "../../../hooks/useModal";
import { useLocation } from "react-router-dom";
import CustomRow from "../../../components/CustomRow";
import CustomEmptyModal from "../../../components/CustomEmptyModal";
import { Add, Close } from "@mui/icons-material";

export default function Departments() {
  const [filterModal, setFilterModal] = useState(false);
  const [addRecordModal, setAddRecordModal] = useState(false);
  const [editRecordModal, setEditRecordModal] = useState(false);
  const [viewRecordModal, setViewRecordModal] = useState(false);
  const [openaddDepartmentModal, setOpenAddDepartmentModal] = useState(false);
  const [parentDepartModal, setParentDepartModal] = useState(false);
  const [departments, setDepartments] = useState([]);
  const { showSuccess, showError } = useMessage();
  const [search, setSearch] = useState("");
  const { mode } = useTheme();

  const [historyExport, setHistoryExport] = useState(false);
  const {
    modalState: deleteState,
    openModal: openDelete,
    closeModal: closeDelete,
  } = useModal();
  const location = useLocation();
  const [isFullScreen, setIsFullScreen] = useState(false);

  const toggleFullScreen = () => {
    setIsFullScreen((prevState) => !prevState); // Toggle full-screen mode
  };

  // useEffect(() => {
  //   const params = new URLSearchParams(location.search);
  //   const modal = params.get("modal");

  //   if (modal === "open") {
  //     setAddRecordModal(true);
  //   }
  // }, [location.search]);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });
  const [totalRows, setTotalRows] = useState(0);
  const [sorting, setSorting] = useState([{ id: "id", desc: false }]);
  const [rowSelection, setRowSelection] = useState({});
  const [selectedDepartment, setSelectedDepartment] = useState(null);

  console.log(rowSelection, "rowSelectionrowSelection");

  const [open, setOpen] = useState();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const fetchDepartment = useCallback(async () => {
    try {
      const response = await axios.get(
        `/hr/department?searchBy=name&search=${search}&sortBy=order`
      );
      setDepartments(response.data.departments || []);
      setData(response.data.departments || []);
    } catch (error) {
      showError("Failed to fetch departments");
    }
  }, [search, showError]);

  const deleteDepartment = async (id) => {
    try {
      const res = await axios.delete(`/hr/department/${id}`);
      const { success, message } = res.data;
      if (success) {
        showSuccess("Department deleted");
        fetchDepartment();
      } else {
        showError(message);
      }
    } catch (error) {
      showError("Failed to delete department");
    } finally {
      // closeDelete();
    }
  };

  useEffect(() => {
    fetchDepartment();
  }, [fetchDepartment]);

  const departmentFields = [
    { name: "id", label: "Department ID", type: "text" },
    { name: "name", label: "Department Name", type: "text" },
    { name: "manager", label: "Manager", type: "text" },
    { name: "location", label: "Location", type: "text" },
    { name: "employeeCount", label: "Employee Count", type: "text" },
    { name: "addedBy", label: "Added By", type: "text" },
    { name: "addedTime", label: "Added Time", type: "text" },
    { name: "modifyBy", label: "Modified By", type: "text" },
    { name: "modifyTime", label: "Modified Time", type: "text" },
  ];

  // const fetchData = async (pageIndex, pageSize, sorting) => {
  //   const sortField = sorting[0]?.id || "id";
  //   const sortDirection = sorting[0]?.desc ? "desc" : "asc";
  //   const response = await axios.get(`https://reqres.in/api/users`, {
  //     params: {
  //       page: pageIndex + 1,
  //       per_page: pageSize,
  //       sort: sortField,
  //       direction: sortDirection,
  //     },
  //   });
  //   setData(response.data.data);
  //   setTotalRows(response.data.total);
  // };

  // useEffect(() => {
  //   fetchData(pagination.pageIndex, pagination.pageSize, sorting);
  // }, [pagination.pageIndex, pagination.pageSize, sorting]);

  const columns = [
    {
      accessorKey: "id",
      header: "Department ID",
      enableSorting: true,
    },
    {
      accessorKey: "name",
      header: "Department Name",
      enableSorting: true,
    },
    { accessorKey: "manager", header: "Manager", enableSorting: true },
    { accessorKey: "location", header: "Location", enableSorting: true },
    {
      accessorKey: "employeeCount",
      header: "Employee Count",
      enableSorting: true,
    },
    { accessorKey: "addedBy", header: "Added By", enableSorting: true },
    { accessorKey: "addedTime", header: "Added Time", enableSorting: true },
    { accessorKey: "modifiedBy", header: "Modified By", enableSorting: true },
    {
      accessorKey: "modifiedTime",
      header: "Modified Time",
      enableSorting: true,
    },
  ];

  const renderRowSelection = (table) => {
    const selectedRows = Object.keys(rowSelection)?.map(
      (rowId) => table?.getRow(rowId)?.original
    );

    return (
      <div>
        {selectedRows.length > 0 && (
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              setSelectedDepartment(selectedRows); // Set multiple selected rows
              openDelete();
            }}
          >
            Delete
          </Button>
        )}
      </div>
    );
  };

  const handleRowClick = (row) => {
    setViewRecordModal(true);
    // setRowSelection(row);
  };
  const renderActions = (row) => (
    <IconButton>
      <LiaTrashAlt />
    </IconButton>
  );

  const [menuAnchor, setMenuAnchor] = useState(null);
  const isMenuopen = Boolean(menuAnchor);

  const fields = [
    { field: "departmentID", label: "Department ID" },
    { field: "departmentName", label: "Department Name" },
    { field: "manager", label: "Manager" },
    { field: "location", label: "Location" },
    { field: "employeeCount", label: "Employee Count" },
    { field: "addedBy", label: "Added By" },
    { field: "addedTime", label: "Added Time" },
    { field: "modifiedBy", label: "Modified By" },
    { field: "modifiedTime", label: "Modified Time" },
  ];

  const [filterFormValues, setFilterFormValues] = useState({
    departments: [],
    departmentStatus: "allDepartments",
    location: "",
    filterFields: [],
  });

  const handleFilterFormChange = (name, value) => {
    setFilterFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleFilterValueChange = (field, condition, value) => {
    setFilterFormValues((prev) => ({
      ...prev,
      filterFields: prev.filterFields.map((item) =>
        item.field === field ? { ...item, condition, value } : item
      ),
    }));
  };

  const handleCheckboxChange = (field) => (event) => {
    const checkedFields = filterFormValues.filterFields;
    if (event.target.checked) {
      setFilterFormValues((prev) => ({
        ...prev,
        filterFields: [...checkedFields, { field, condition: "is", value: "" }],
      }));
    } else {
      setFilterFormValues((prev) => ({
        ...prev,
        filterFields: checkedFields.filter((item) => item.field !== field),
      }));
    }
  };

  const handleFilterFormSubmit = (e) => {
    e.preventDefault();
    console.log("Filter Data :- ", filterFormValues);
    setFilterModal(false);
  };

  const addRecordModalFields = [
    {
      type: "autocomplete",
      name: "departmentID",
      label: "Department ID",
      options: ["DepartmentID 1", "DepartmentID 2"],
      defaultValue: "",
    },
    {
      type: "text",
      name: "name",
      label: "Department Name",
      defaultValue: "",
      required: true,
    },
    {
      type: "text",
      name: "manager",
      label: "Manager",
      defaultValue: "",
    },
    {
      type: "text",
      name: "location",
      label: "Location",
      defaultValue: "",
    },
    {
      type: "text",
      name: "employeeCount",
      label: "Employee Count",
      defaultValue: "",
    },
    {
      type: "text",
      name: "addedBy",
      label: "Added By",
      defaultValue: "",
    },
    {
      type: "text",
      name: "addedTime",
      label: "Added Time",
      defaultValue: "",
    },
    {
      type: "text",
      name: "modifiedBy",
      label: "Modified By",
      defaultValue: "",
    },
    {
      type: "text",
      name: "modifiedTime",
      label: "Modified Time",
      defaultValue: "",
    },
  ];

  const handleAddRecordFormSubmit = async (data) => {
    console.log("Add Form :- ", data);
    try {
      const response = await axios.post(`/hr/department`, data);
      showSuccess("Department added successfully");
      fetchDepartment();
      setAddRecordModal(false);
    } catch (e) {
      console.error(e);
    }
  };

  const editRecordModalFields = [
    {
      type: "autocomplete",
      name: "departmentID",
      label: "Department ID",
      options: ["DepartmentID 1", "DepartmentID 2"],
      defaultValue: "DepartmentID 1",
      disabled: true,
    },
    {
      type: "text",
      name: "name",
      label: "Department Name",
      defaultValue: rowSelection?.original?.name ?? "",
      required: true,
    },
    {
      type: "text",
      name: "manager",
      label: "Manager",
      defaultValue: "",
    },
    {
      type: "text",
      name: "location",
      label: "Location",
      defaultValue: "",
    },
    {
      type: "text",
      name: "employeeCount",
      label: "Employee Count",
      defaultValue: "",
    },
    {
      type: "text",
      name: "addedBy",
      label: "Added By",
      defaultValue: "",
    },
    {
      type: "text",
      name: "addedTime",
      label: "Added Time",
      defaultValue: "",
    },
    {
      type: "text",
      name: "modifiedBy",
      label: "Modified By",
      defaultValue: "",
    },
    {
      type: "text",
      name: "modifiedTime",
      label: "Modified Time",
      defaultValue: "",
    },
  ];

  const handleEditRecordFormSubmit = async (data) => {
    console.log("Edit Form :- ", data);
    try {
      const response = await axios.patch(
        `/hr/department/${rowSelection?.original?._id}`,
        data
      );
      showSuccess("Department updated successfully");
      fetchDepartment();
      setEditRecordModal(false);
    } catch (e) {
      console.error(e);
    }
  };

  const viewRecordModalFields = [
    {
      type: "autocomplete",
      name: "departmentID",
      label: "Department ID",
      options: ["DepartmentID 1", "DepartmentID 2"],
      defaultValue: "DepartmentID 1",
      disabled: true,
    },
    {
      type: "text",
      name: "name",
      label: "Department Name",
      defaultValue: rowSelection?.original?.name,
      disabled: true,
    },
    {
      type: "text",
      name: "manager",
      label: "Manager",
      defaultValue: "",
      disabled: true,
    },
    {
      type: "text",
      name: "location",
      label: "Location",
      defaultValue: "",
      disabled: true,
    },
    {
      type: "text",
      name: "employeeCount",
      label: "Employee Count",
      defaultValue: "",
      disabled: true,
    },
    {
      type: "text",
      name: "addedBy",
      label: "Added By",
      defaultValue: "",
      disabled: true,
    },
    {
      type: "text",
      name: "addedTime",
      label: "Added Time",
      defaultValue: "",
      disabled: true,
    },
    {
      type: "text",
      name: "modifiedBy",
      label: "Modified By",
      defaultValue: "",
      disabled: true,
    },
    {
      type: "text",
      name: "modifiedTime",
      label: "Modified Time",
      defaultValue: "",
      disabled: true,
    },
  ];

  return (
    <div className="w-full min-h-80 flex flex-col">
      <div>
        <CustomRow
          openAddModal={() => setAddRecordModal(true)}
          ButtonLabel={"Add Departments"}
          toggleFullScreen={toggleFullScreen}
          viewOptions={[{ value: "1", label: "Department View" }]}
          fileOptions={[
            { icon: <CiImport />, label: "Import" },
            { icon: <CiExport />, label: "Export" },
            { icon: <CiImport />, label: "History Export" },
          ]}
          historyExport={historyExport}
          setHistoryExport={setHistoryExport}
        />
      </div>

      <div>
        <div className="h-[35.1rem] mt-3 overflow-scroll">
          <CustomTable
            columns={columns}
            onRowClick={handleRowClick}
            renderActions={renderActions}
            renderRowSelection={renderRowSelection}
            data={data}
            loading={loading}
            error={error}
            sorting={sorting}
            setSorting={setSorting}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            pagination={pagination}
            setPagination={setPagination}
            totalRows={totalRows}
            setTotalRows={setTotalRows}
            isBulkSelect={true}
          />
        </div>
      </div>
      <CustomModal
        title="Add Department"
        fields={addRecordModalFields}
        open={addRecordModal}
        onClose={() => {
          setAddRecordModal(false);
        }}
        onSubmit={handleAddRecordFormSubmit}
        isScrollable={true}
      />
      <CustomModal
        title="Edit Department"
        fields={editRecordModalFields}
        open={editRecordModal}
        data={rowSelection?.original}
        onClose={() => setEditRecordModal(false)}
        onSubmit={handleEditRecordFormSubmit}
        isScrollable={true}
      />

      {/* <DeparmentModal
        title="Add Department"
        open={addRecordModal}
        handleClose={() => setAddRecordModal(false)}
        fields={departmentFields}
        onSubmit={handleAddRecordFormSubmit}
        fetchDepartment={fetchDepartment}
      />

      <DeparmentModal
        title="Edit Department"
        open={editRecordModal}
        handleClose={() => setEditRecordModal(false)}
        fields={departmentFields}
        // data={editRecordData} // Populate with existing data for edit
        onSubmit={handleEditRecordFormSubmit}
        fetchDepartment={fetchDepartment}
      /> */}

      <CustomModal
        title="View Department"
        fields={viewRecordModalFields}
        open={viewRecordModal}
        onClose={() => setViewRecordModal(false)}
        isView={true}
        data={data}
        isScrollable={true}
        isEditButton={true}
        onEditButtonClick={() => {
          setViewRecordModal(false);
          setEditRecordModal(true);
        }}
      />
      {/* 
      <Modal open={addRecordModal} onClose={() => {
          setAddRecordModal(false);
        }}>
        <DeparmentModal fetchDepartment={fetchDepartment} handleClose={() => {
          setAddRecordModal(false);
        }}
        />
      </Modal> */}
      <CustomEmptyModal
        isScrollable={true}
        // isSmall={true}
        open={openaddDepartmentModal}
        onClose={() => setOpenAddDepartmentModal(false)}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: 2,
            borderBottom: "1px solid #ddd",
            position: "sticky",
            backgroundColor: "background.default",
            top: 0,
            zIndex: 10, // Keeps it above scrollable content
          }}
        >
          <Typography variant="h6">Add Department</Typography>
          <IconButton
            sx={{
              backgroundColor: mode == "light" ? "#edf0f4" : "#3333",
              mr: 2,
              fontSize: 14,
              borderRadius: "6px",
            }}
            onClick={() => setOpenAddDepartmentModal(false)}
          >
            <Close fontSize="16px" />
          </IconButton>
        </Box>
        <Box sx={{ padding: 2, display: "flex", flexDirection: "column" }}>
          <Card sx={{ borderRadius: "12px" }}>
            <CardHeader
              title="Department Details"
              titleTypographyProps={{ variant: "subtitle2" }}
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid size={{ xs: 4, md: 2 }}>
                  <p className="text-gray-500">Department Name</p>
                </Grid>
                <Grid size={{ xs: 8, md: 10 }}>
                  <TextField
                    size="small"
                    sx={{ width: "100%" }}
                    variant="outlined"
                    name=""
                  />
                </Grid>
                <Grid size={{ xs: 4, md: 2 }}>
                  <p className="text-gray-500">Mail Alias</p>
                </Grid>
                <Grid size={{ xs: 8, md: 10 }}>
                  <TextField
                    size="small"
                    sx={{ width: "100%" }}
                    variant="outlined"
                    name=""
                  />
                </Grid>
                <Grid size={{ xs: 4, md: 2 }}>
                  <p className="text-gray-500">Department Lead</p>
                </Grid>
                <Grid size={{ xs: 8, md: 10 }}>
                  <FormControl fullWidth>
                    <Select
                      size="small"
                      name="taskOwner"
                      variant="outlined"
                      value={""}
                    >
                      <MenuItem value={"Bisham"}>{"Bisham"}</MenuItem>
                      <MenuItem value={"Saad"}>{"Saad"}</MenuItem>
                      <MenuItem value={"Fahad"}>{"Fahad"}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid size={{ xs: 4, md: 2 }}>
                  <p className="text-gray-500">Parent Department</p>
                </Grid>
                <Grid
                  size={{ xs: 8, md: 10 }}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <FormControl fullWidth>
                    <Select
                      size="small"
                      name="taskOwner"
                      variant="outlined"
                      value={""}
                    >
                      <MenuItem value={"Hr"}>{"Hr"}</MenuItem>
                      <MenuItem value={"Marketing"}>{"Marketing"}</MenuItem>
                      <MenuItem value={"IT"}>{"IT"}</MenuItem>
                      <MenuItem value={"Management"}>{"Management"}</MenuItem>
                    </Select>
                  </FormControl>
                  <IconButton
                    onClick={() => setParentDepartModal(true)}
                    sx={{
                      backgroundColor: mode == "light" ? "#edf0f4" : "#3333",
                      fontSize: 14,
                      borderRadius: "6px",
                      ml: 2,
                    }}
                    title="Add Priority"
                  >
                    <Add fontSize="18px" />
                  </IconButton>
                </Grid>
                {/* <Grid size={{ xs: 2, md: 2 }}>
                  <IconButton
                    onClick={() => setParentDepartModal(true)}
                    sx={{
                      backgroundColor: mode == "light" ? "#edf0f4" : "#3333",
                      fontSize: 14,
                      borderRadius: "6px",
                      ml: 2,
                    }}
                    title="Add Priority"
                  >
                    <Add fontSize="18px" />
                  </IconButton>
                </Grid> */}
              </Grid>
            </CardContent>
          </Card>
        </Box>
        <div
          style={{ backgroundColor: "background.default" }}
          className="w-full min-h-14 flex items-center z-50 sticky bottom-0 left-0 p-4"
        >
          <div className="flex flex-row gap-3 justify-between items-center">
            <Button variant="contained" type="submit">
              Submit
            </Button>
            <Button variant="contained" type="submit">
              Submit and New
            </Button>
            <Button
              variant="outlined"
              onClick={() => setOpenAddDepartmentModal(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </CustomEmptyModal>

      <CustomEmptyModal
        isScrollable={true}
        // isSmall={true}
        open={parentDepartModal}
        onClose={() => setParentDepartModal(false)}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: 2,
            borderBottom: "1px solid #ddd",
            position: "sticky",
            backgroundColor: "background.default",
            top: 0,
            zIndex: 10, // Keeps it above scrollable content
          }}
        >
          <Typography variant="h6">Add Department</Typography>
          <IconButton
            sx={{
              backgroundColor: mode == "light" ? "#edf0f4" : "#3333",
              mr: 2,
              fontSize: 14,
              borderRadius: "6px",
            }}
            onClick={() => setParentDepartModal(false)}
          >
            <Close fontSize="16px" />
          </IconButton>
        </Box>
        <Box sx={{ padding: 2, display: "flex", flexDirection: "column" }}>
          <Card>
            <CardHeader
              title="Department Details"
              titleTypographyProps={{ variant: "subtitle2" }}
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid size={{ xs: 4, md: 2 }}>
                  <p className="text-gray-500">Department Name</p>
                </Grid>
                <Grid size={{ xs: 8, md: 10 }}>
                  <TextField
                    size="small"
                    sx={{ width: "100%" }}
                    variant="outlined"
                    name=""
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
        <Box
          sx={{ backgroundColor: "background.default" }}
          className="w-full min-h-14 flex items-center z-50 sticky bottom-0 left-0 p-4"
        >
          <div className="flex flex-row gap-3 justify-between items-center">
            <Button variant="contained" type="submit">
              Submit
            </Button>
            <Button
              variant="outlined"
              onClick={() => setParentDepartModal(false)}
            >
              Cancel
            </Button>
          </div>
        </Box>
      </CustomEmptyModal>

      <CustomModal
        title="Filter"
        open={filterModal}
        onClose={() => setFilterModal(false)}
        isScrollable={true}
      >
        <form onSubmit={handleFilterFormSubmit} className="flex flex-col gap-3">
          <div>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="systemFilters"
                id="systemFilters"
              >
                <Typography component="span">System Filters</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Autocomplete
                  sx={{ width: "100%" }}
                  multiple
                  options={["Department 1", "Department 2"]}
                  getOptionLabel={(option) => option}
                  disableCloseOnSelect
                  onChange={(e, value) =>
                    handleFilterFormChange("departments", value)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Department"
                      placeholder="Department"
                    />
                  )}
                />
                <FormControl sx={{ mb: "15px", width: "100%" }}>
                  <InputLabel id="departmentStatus">
                    Department Status
                  </InputLabel>
                  <Select
                    labelId="departmentStatus"
                    id="departmentStatus"
                    label="Department Status"
                    onChange={(e) =>
                      handleFilterFormChange("departmentStatus", e.target.value)
                    }
                  >
                    <MenuItem value={"allDepartments"}>
                      All Departments
                    </MenuItem>
                    <MenuItem value={"allActiveDepartments"}>
                      All Active Departments
                    </MenuItem>
                    <MenuItem value={"inactiveDepartments"}>
                      Inactive Departments
                    </MenuItem>
                  </Select>
                </FormControl>
                <Autocomplete
                  sx={{ width: "100%" }}
                  options={["Location 1", "Location 2"]}
                  renderInput={(params) => (
                    <TextField {...params} label="Location" />
                  )}
                  onChange={(e, value) =>
                    handleFilterFormChange("location", value)
                  }
                />
              </AccordionDetails>
            </Accordion>
          </div>

          <div>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography component="span">Field</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="flex flex-col gap-3">
                  {fields.map((field, index) => (
                    <div key={index}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={handleCheckboxChange(field.field)}
                              checked={filterFormValues.filterFields.some(
                                (item) => item.field === field.field
                              )}
                            />
                          }
                          label={field.label}
                        />
                      </FormGroup>

                      {filterFormValues.filterFields
                        .filter((item) => item.field === field.field)
                        .map((filter, idx) => (
                          <div className="flex flex-col gap-3" key={idx}>
                            <FormControl sx={{ width: "100%" }}>
                              <InputLabel id={`filter-${index}`}>
                                Condition
                              </InputLabel>
                              <Select
                                labelId={`filter-${index}`}
                                id={`filter-${index}`}
                                label="Condition"
                                value={filter.condition}
                                onChange={(e) =>
                                  handleFilterValueChange(
                                    field.field,
                                    e.target.value,
                                    filter.value
                                  )
                                }
                              >
                                <MenuItem value={"is"}>Is</MenuItem>
                                <MenuItem value={"isNot"}>Is Not</MenuItem>
                                <MenuItem value={"startWith"}>
                                  Start With
                                </MenuItem>
                                <MenuItem value={"endWith"}>End With</MenuItem>
                                <MenuItem value={"contains"}>Contains</MenuItem>
                                <MenuItem value={"notContains"}>
                                  Not Contains
                                </MenuItem>
                                <MenuItem value={"Like"}>Like</MenuItem>
                                <MenuItem value={"isEmpty"}>Is Empty</MenuItem>
                                <MenuItem value={"isNotEmpty"}>
                                  Is Not Empty
                                </MenuItem>
                              </Select>
                            </FormControl>
                            <TextField
                              sx={{ width: "100%" }}
                              variant="outlined"
                              label="Value"
                              placeholder="Value"
                              value={filter.value}
                              onChange={(e) =>
                                handleFilterValueChange(
                                  field.field,
                                  filter.condition,
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        ))}
                    </div>
                  ))}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>

          <div className="w-full flex gap-2 flex-row justify-between items-center">
            <Button type="submit" variant="contained">
              Apply
            </Button>
            <Button
              onClick={() => {
                setFilterModal(false);
              }}
              variant="outlined"
            >
              Reset
            </Button>
          </div>
        </form>
      </CustomModal>

      {/* <Modal
        open={deleteState}
        onClose={closeDelete}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Card sx={{ maxWidth: '548px', width: '100%' }}>
          <CardContent>
            <Typography variant="h5" fontWeight={500}>
              Delete {selectedDepartment?.name}
            </Typography>
            <Divider sx={{ my: 1.5 }} />
            <Typography variant="subtitle1">
              Are you sure want to delete {selectedDepartment?.name}?
            </Typography>
          </CardContent>
          <CardActions sx={{ mt: 3, justifyContent: 'flex-end', p: 2 }}>
            <Button variant="outlined" onClick={closeDelete}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => deleteDepartment(selectedDepartment?._id)}
            >
              Delete
            </Button>
          </CardActions>
        </Card>
      </Modal> */}

      <Modal
        open={deleteState}
        onClose={closeDelete}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Card sx={{ maxWidth: "548px", width: "100%" }}>
          <CardContent>
            <Typography variant="h5" fontWeight={500}>
              Delete {selectedDepartment?.length} Department(s)
            </Typography>
            <Divider sx={{ my: 1.5 }} />
            <Typography variant="subtitle1">
              Are you sure you want to delete:
              <ul>
                {selectedDepartment?.map((dept) => (
                  <li key={dept._id}>{dept.name}</li>
                ))}
              </ul>
            </Typography>
          </CardContent>
          <CardActions sx={{ mt: 3, justifyContent: "flex-end", p: 2 }}>
            <Button variant="outlined" onClick={closeDelete}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                selectedDepartment.forEach((dept) =>
                  deleteDepartment(dept._id)
                ); // Delete all selected
                closeDelete(); // Close modal after deleting
              }}
            >
              Delete
            </Button>
          </CardActions>
        </Card>
      </Modal>
    </div>
  );
}
