import { CardContent, Drawer, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { IoIosInformationCircleOutline, IoMdClose } from "react-icons/io";
import { IoCloseCircleSharp } from "react-icons/io5";
import { RiKeyLine } from "react-icons/ri";
import DepartmentChangeDrawer from "./DepartmentChangeDrawer";
import LocationChangeDrawer from "./LocationChangeDrawer";
import DesignationChangeDrawer from "./DesignationChangeDrawer";

const HrProcessDrawer = ({ openDrawer, onClose }) => {
  const [openDepartment, setOpenDepartment] = useState(false);
  const [openLocation, setOpenLocation] = useState(false);
  const [openDesignation, setOpenDesignation] = useState(false);
  const { mode } = useTheme();
  return (
    <div>
      <Drawer
        PaperProps={{
          className: "w-[90%] m-1 md:w-[500px] lg:w-[800px] rounded-lg",
        }}
        anchor="right"
        open={openDrawer}
        onClose={onClose}
      >
        <div
          style={{ backgroundColor: mode === "dark" ? "#141414" : "#edf0f4" }}
          className="p-4  h-full overflowY-auto"
        >
          {/* Header */}
          <div className="sm:flex justify-between items-center border-b pb-3">
            <Typography variant="h6" className="font-semibold">
              Hr Process
            </Typography>

            {/* Header Right Section (Rejected Icon + View Button + Close Button) */}
            <div className="flex items-center gap-3">
              <button
                className="p-[5px]"
                onClick={onClose}
                style={{
                  backgroundColor: mode === "dark" ? "" : "#dcdcdc",
                  borderRadius: "5px",
                }}
              >
                <IoMdClose size={18} />
              </button>
            </div>
          </div>

          {/* Employee Section */}
          <div
            className="mb-4 rounded-lg"
            style={{
              border: "1px solid #d7e2ed",
              backgroundColor: mode === "dark" ? "#141414" : "#fff",
            }}
          >
            <CardContent>
              <div className="w-full mx-auto p-4 space-y-4">
                {/* Department Change Card */}
                <button
                  onClick={() => setOpenDepartment(true)}
                  className="w-full text-left transition-all duration-200 hover:shadow-md rounded-lg border border-gray-200 overflow-hidden"
                >
                  <div className="p-4 flex items-start gap-4">
                    <div className="flex-shrink-0 w-12 h-12 rounded-md bg-cyan-100 flex items-center justify-center">
                      <span className="text-cyan-500 font-medium">DC</span>
                    </div>
                    <div>
                      <h3 className="font-medium text-lg text-gray-900">
                        Department Change
                      </h3>
                      <p className="text-gray-600 mt-1">
                        This process is used by reporting managers to initiate
                        the team change process.
                      </p>
                    </div>
                  </div>
                </button>

                {/* Location Change Card */}
                <button
                  onClick={() => setOpenLocation(true)}
                  className="w-full text-left transition-all duration-200 hover:shadow-md  rounded-lg border border-gray-200 overflow-hidden"
                >
                  <div className="p-4 flex items-start gap-4">
                    <div className="flex-shrink-0 w-12 h-12 rounded-md bg-blue-100 flex items-center justify-center">
                      <span className="text-blue-500 font-medium">LC</span>
                    </div>
                    <div>
                      <h3 className="font-medium text-lg text-gray-900">
                        Location Change
                      </h3>
                      <p className="text-gray-600 mt-1">
                        This process is used by reporting managers to initiate
                        the location change process.
                      </p>
                    </div>
                  </div>
                </button>

                {/* Designation Change Card */}
                <button
                  onClick={() => setOpenDesignation(true)}
                  className="w-full text-left transition-all duration-200 hover:shadow-md rounded-lg border border-gray-200 overflow-hidden"
                >
                  <div className="p-4 flex items-start gap-4">
                    <div className="flex-shrink-0 w-12 h-12 rounded-md bg-purple-100 flex items-center justify-center">
                      <span className="text-purple-500 font-medium">PP</span>
                    </div>
                    <div>
                      <h3 className="font-medium text-lg text-gray-900">
                        Designation Change
                      </h3>
                      <p className="text-gray-600 mt-1">
                        This process is used by reporting managers to raise a
                        designation change for their reportees
                      </p>
                    </div>
                  </div>
                </button>
              </div>
            </CardContent>
          </div>
        </div>
      </Drawer>

      <DepartmentChangeDrawer
        openDepartment={openDepartment}
        setOpenDepartment={setOpenDepartment}
      />

      <LocationChangeDrawer
        openLocation={openLocation}
        setOpenLocation={setOpenLocation}
      />

      <DesignationChangeDrawer
        openDesignation={openDesignation}
        setOpenDesignation={setOpenDesignation}
      />
    </div>
  );
};

export default HrProcessDrawer;
