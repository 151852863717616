import { Box } from "@mui/material";
import axios from "axios";
import React, { useCallback, useState, useEffect } from "react";
import { formatTimestamp } from "../../utilities/function";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";

import Menu from "@mui/material/Menu";
import Fade from "@mui/material/Fade";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { MdOutlineMoreVert } from "react-icons/md";
import useEmployeeSocket from "../../hooks/useEmployeeSocket";

const ChatList = ({ setSharedData, chatList = [] }) => {
  let page = 1;
  const [chats, setChats] = useState([]);

  const { totalNotification,getUnreadNotification } = useEmployeeSocket();
  const updatedChatList = chatList?.map(chat => {
    const notificationCount = totalNotification?.filter(notification =>
      notification?.sender === chat?.sender?._id || notification?.sender === chat?.receiver?._id
    )?.length;
    return { ...chat, notificationCount };
  });


  let imgUrl =
    "https://images.unsplash.com/photo-1605993439219-9d09d2020fa5?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjB8fHVzZXIlMjBwcm9maWxlfGVufDB8fDB8fHww";

  //   const fetchChatList  = useCallback(
  //     async () => {
  //         // setJobs(null);
  //         try {
  //             const response = await axios.get(
  //                 `/employee/message?page=${page}&limit=50`
  //             );
  //             const data = response.data;
  //             if(data.success){
  //               setChats(data.messages)
  //             }

  //         } catch (e) {
  //             console.warn(e);
  //         }
  //     },
  //     []
  // );

  // useEffect(() => {
  //   fetchChatList();
  // }, [fetchChatList])
  const handleClick = (item) => {
    console.log("shared data from chat", item);
    setSharedData(item);
    getUnreadNotification()
  };

  const currentUserId = JSON.parse(localStorage.user)._id;

  const [hoveredIndex, setHoveredIndex] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openDelete = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      {updatedChatList?.map((item, index) =>  {
         const nametodisplay = item?.receiver?._id === currentUserId
         ? `${item?.sender?.firstName} ${item?.sender?.lastName}`
         : `${item?.receiver?.firstName} ${item?.receiver?.lastName}`;
         return (
         <div
          key={index}
          className="relative"
          onMouseEnter={() => setHoveredIndex(index)}
          onMouseLeave={() => setHoveredIndex(null)}
        >
          <div className="flex gap-4 items-center p-1 my-3">
            <div className="h-[31px] w-[31px] md:h-[40px]  md:w-[50px] relative">
              {/* <img
                className="w-full h-full rounded-full object-cover object-top"
                src={imgUrl}
                alt=""
              /> */}
               <img
                  className="w-full h-full rounded-full object-cover object-top"
                  src={`https://ui-avatars.com/api/?name=${nametodisplay}`}
                  alt=""
                />
              <p className="h-[7px]  w-[7px]  bg-blue-500 border p-[2px] md:right-[1px] md:bottom-[3px] rounded-full absolute bottom-[4px] right-[-1px]"></p>
            </div>

            <div
              onClick={() =>
                handleClick(
                  item?.receiver?._id === currentUserId
                    ? item.sender
                    : item.receiver
                )
              }
              className="w-full font-bold"
            >
              <div className="flex  justify-between items-center">
                <div className="text-sm  md:text-xs ">
                  {item?.receiver?._id === currentUserId
                    ? `${item?.sender?.firstName} ${item?.sender?.lastName}`
                    : `${item?.receiver?.firstName} ${item?.receiver?.lastName}`}
                </div>
                <div className="flex gap-3 ">
                  {hoveredIndex === index && (
                    <div onClick={(e) => e.stopPropagation()}>
                      <span
                        style={{
                          opacity: hoveredIndex === index ? 1 : 0,
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setAnchorEl(e.currentTarget);
                        }}
                      >
                        <MdOutlineMoreVert />
                      </span>
                      <Menu
                        id="fade-menu"
                        MenuListProps={{
                          "aria-labelledby": "fade-button",
                        }}
                        anchorEl={anchorEl}
                        open={openDelete}
                        onClose={handleClose}
                        TransitionComponent={Fade}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                      >
                        <MenuItem>
                          <ListItemIcon>
                            <DeleteForeverIcon fontSize="small" />
                          </ListItemIcon>
                          <Typography
                            variant="inherit"
                            style={{ fontSize: "14px" }}
                          >
                            Delete Chat
                          </Typography>
                        </MenuItem>
                      </Menu>
                    </div>
                  )}

                  <div style={{ color: item?.notificationCount > 0 ? `#3A7EC1` : "#434343", flexDirection: "column" }} className=" text-xs md:text-[8px]">
                    {formatTimestamp(item.createdAt)}
                  </div>
                </div>
              </div>

              <div className="flex mt-1 justify-between items-center ">
                {/* <div className="line-clamp-1 text-xs text-[#434343] md:text-[9px]">
                  {item.content}
                </div>
                {item.isViewed && (
                  <div className="bg-[#3A7EC1] text-[10px] md:px-[3.5px] md rounded-full md:text-[7px] px-[5px] ">
                    1
                  </div>
                )} */}
                <div className="line-clamp-1 text-xs text-[#434343] md:text-[9px]">
                  {item.content}
                </div>
                {item?.notificationCount > 0 && (
                  <div className="bg-[#3A7EC1] text-white text-[10px] md rounded-full md:text-[7px] px-[6px] py-[3px] ">
                    {item?.notificationCount}
                  </div>)}
              </div>
            </div>
          </div>
          <p className="h-[1px] md:hidden bg-[#111111] w-full"></p>
        </div>
)})}
    </>
  );
};

export default ChatList;
