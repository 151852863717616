import React, { useEffect, useState, useRef, useCallback } from "react";
import { Button, Typography, IconButton, Box, Skeleton, Card, CardContent, Grid, CircularProgress, Modal } from "@mui/material";
import { useUser } from "../../../../hooks/Authorize";
import camera from "../../../../assets/Interductionimages/cameraaicon2.png";
import { IoIosMore } from "react-icons/io";
import { MdEdit } from "react-icons/md";
import axios from "axios";
import { FaPaintBrush } from "react-icons/fa";
import { TbEdit } from "react-icons/tb";
import EditProfileModal from "../../../EditProfileModal";
import PersonalizePreferencesModal from "../../../PersonalizePreferencesModal";
import { useMessage } from "../../../Header";
import { handleAxiosError, env } from "../../../../utilities/function";
import useModal from "../../../../hooks/useModal";
import ClockOutModal from "../../../ClockOutModal";

export default function ProfilePreview({clockInValidation,setClockInValidation}) {
  const platformUser = useUser();
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState(platformUser?.userImage || "");
  const [editModalOpen, setEditModalOpen] = useState(false); // State for modal
  const [personalizeModalOpen, setPersonalizeModalOpen] = useState(false); // State for modal
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  // console.log(emp, 'platformUser')

  // console.log(platformUser,'platformUser')
  // Function to get the initials or "?"
  const getInitials = () => {
    if (platformUser?.firstName && platformUser?.lastName) {
      return `${platformUser.firstName
        .charAt(0)
        .toUpperCase()}${platformUser.lastName.charAt(0).toUpperCase()}`;
    }
    return "?";
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setImage(imageUrl);
      if (!file) {
        return;
      }

      const formData = new FormData();
      formData.append("image", file);

      try {
        const response = await axios.post(`https://accounts.clikkle.com:5000/upload/${platformUser._id}`, formData, {
          headers: { "Content-Type": "multipart/form-data" }
        });

      } catch (error) {

        console.error(error);
      }
    }
  };

  const updatePic = async () => {
    try {
      const res = await axios.put('profile/updatePic')
    } catch (error) {
      console.log(error)
    }
  }

  // const [time, setTime] = useState("00 : 00 : 00");




  const [metrics, setMetrics] = useState(null);
  const { showError, showSuccess } = useMessage();
  const [attendanceData, setAttendanceData] = useState(null);
  const [clockInLoading, setClockInLoading] = useState(false);
  // const [clockInValidation, setClockInValidation] = useState(null);
  const [clockOutValidation, setClockOutValidation] = useState(null);
  // const { state, closeModal, openModal } = useModal();
  const [openClockOut, setOpenClockOut] = useState(false);
  const [query, setQuery] = useState({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  });
  let time = new Date().toLocaleTimeString();
  const [currentTime, setCurrentTime] = useState(time);
  let currentOrg = JSON.parse(localStorage.getItem("org"));
  let currentEmp = currentOrg?.type == "Member" ? JSON.parse(localStorage.getItem("emp")) : null;


  const closeModal = () => {
    setOpenClockOut(false)
  }

  const setTime = () => {
    let time = new Date().toLocaleTimeString();
    setCurrentTime(time);
  };

  setInterval(() => {
    setTime();
  }, 1000);

  const clockIn = async function () {
    setClockInLoading(true);
    try {
      const res = await axios.post(`/employee/shift/clock-in?empId=${currentEmp?._id}`);
      const { success, message } = res.data;
      if (success) {
        showSuccess(message);
        AttendanceDataFetch();
        fetchData();
      }
    } catch (e) {
      handleAxiosError(e, showError);
    } finally {
      setClockInLoading(false);
    }
  };


  const fetchData = useCallback(async function () {
    setMetrics(null);

    try {
      const res = await axios.get(`/employee/shift?empId=${currentEmp?._id}`);

      const monthData = res.data.monthData;
      monthData.total = Object.values(monthData).reduce((acc, val) => acc + val);

      setMetrics(monthData);
      setClockInValidation(res?.data?.isClockedIn);
      setClockOutValidation(res?.data?.isClockedOutYesterday);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const AttendanceDataFetch = useCallback(
    async function () {
      try {
        const res = await axios.get(
          `/employee/shift/attendance?empId=${currentEmp?._id}&month=${query.month}&year=${query.year}&sortBy=clockInTime&direction=-1`
        );
        setAttendanceData(res.data.attendance);
      } catch (e) {
        console.log(e);
      }
    },
    [setAttendanceData, query.month, query.year]
  );

  useEffect(() => {
    if(currentOrg?.type == "Member")
    {
      AttendanceDataFetch();
    }
  }, [AttendanceDataFetch]);

  useEffect(() => {
    if(currentOrg?.type == "Member")
      {
        fetchData();
      }
  }, [fetchData]);








  useEffect(() => {
    setInterval(() => {
      const dateObject = new Date();

      const hour = dateObject.getHours();
      const minute = dateObject.getMinutes();
      const tempSecond = dateObject.getSeconds();
      const second = tempSecond < 10 ? "0" + tempSecond : tempSecond;

      const currentTime = hour + " : " + minute + " : " + second;

      setTime(currentTime);
    }, 1000);
  }, []);

  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="flex gap-3 p-3 flex-col justify-center items-center relative">


      {/* More Icon Button */}
      <div
        ref={buttonRef}
        className="absolute right-[15px] top-[15px] cursor-pointer"
        onClick={() => setOpen(!open)}
      >
        <IoIosMore size={25} />
      </div>

      {/* Modal Dropdown */}
      {open && (
        <Box
          ref={dropdownRef}
          sx={{ bgcolor: "background.paper" }} // Dynamic Theme Background
          className="absolute right-[-50px] top-10 w-[250px] shadow-[4px_4px_15px_rgba(0,0,0,0.25)] py-2 px-4 rounded-lg z-50"
        >
          <div
            className="flex items-center gap-3 py-2 cursor-pointer rounded-md "
            onClick={() => setEditModalOpen(true)}
          >
            <TbEdit size={18} />
            <span className="font-[400] text-[16px]">Edit Profile</span>
          </div>
          <div
            className="flex items-center gap-3 py-2 cursor-pointer  rounded-md "
            onClick={() => setPersonalizeModalOpen(true)}
          >
            <FaPaintBrush size={18} />
            <span className="font-[400] text-[16px]">Personalize Preferences</span>
          </div>
        </Box>
      )}

      {/* Profile Picture Section */}
      <div className="flex  flex-col justify-center items-center">
        <Box
          sx={{
            position: "relative",
            width: image ? "121px" : "121px",
            height: image ? "127px" : "127px",
            borderRadius: image ? "8px" : "8px",
            overflow: "hidden",
            border: "1px solid #141414",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: image ? "transparent" : "background.view",
            backgroundImage: image ? `url(${image})` : `url(${process.env.REACT_APP_PRODUCTION_SERVER + '/static/' + currentOrg?.logo})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            transition: "all 0.3s ease",
            // "&:hover": {
            //   backgroundColor: "rgba(0, 0, 0, 0.6)",
            // },
            // "&:hover::after": {
            //   content: '""',
            //   position: "absolute",
            //   top: 0,
            //   left: 0,
            //   right: 0,
            //   bottom: 0,
            //   background: "inherit",
            //   filter: "blur(5px)",
            //   zIndex: 1,
            // },
            // "&:hover .camera-icon": {
            //   opacity: 1,
            // },
          }}
        >
          {!currentOrg?.logo && (
            <Typography
              variant="subtitle1"
              fontWeight={600}
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontSize: 30,
              }}
            >
              {getInitials()}
            </Typography>
          )}

          {/* Camera Icon */}
          {/* <IconButton
            className="camera-icon"
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)", // Center the icon
              backgroundColor: "transparent",
              boxShadow: "none",
              opacity: 0, // Hidden by default
              transition: "opacity 0.3s ease", // Smooth transition for icon appearance
              zIndex: 2, // Ensure the icon is above the blurred background
            }}
            size="small"
            component="label"
          >
            <img
              src={camera}
              alt="Camera"
              style={{
                width: "30px",
                height: "30px",
              }}
            />
            <input
              type="file"
              accept="image/*"
              hidden
              onChange={handleImageUpload}
            />
          </IconButton> */}
        </Box>

        {/* User Info */}
        <Typography variant="h6" sx={{ mt: 2 }}>
          {platformUser?.firstName + " " + platformUser?.lastName || "N/A"}
          {/* {currentOrg?.name} */}
        </Typography>
        <Typography variant="subtitle2" sx={{ color: "#A5A5A5", fontWeight: "400", fontSize: "14px" }}>
          {/* HR Manager */}
          {currentEmp?.role ?? "CEO"}
        </Typography>
      </div>
      {/* <div className="flex flex-col justify-center items-center">
        <Typography variant="body2" sx={{ fontSize: "14px", fontWeight: "400", color: "#F13B3B" }}>
          Not yet Clocked-in
        </Typography>
        <Typography variant="h6">{time}</Typography>
      </div>

      <div className="flex gap-3 flex-row justify-center items-center">
        <Button sx={{ px: "1.6rem", py: "0.2rem" }} variant="contained">
          Clock-in
        </Button>
        <Button
          sx={{ px: "1.6rem", py: "0.2rem" }}
          variant="contained"
          disabled
        >
          Clock-out
        </Button>
      </div> */}

      {currentOrg.type == "Member" &&
        <Grid item lg={3} xs={12}>
          <div
            elevation={0}
            style={{
              textAlign: 'center',
              // minHeight: 200,
            }}>
            <CardContent>
              <Typography variant='h5' component='div'>
                {currentTime}
              </Typography>
              {/* <Typography sx={{ my: 3 }}>Current Time</Typography> */}
            </CardContent>

            {metrics ? (
              <Box sx={{ textAlign: 'center' }}>
                <Button
                  variant='contained'
                  onClick={clockIn}
                  disabled={clockInLoading || clockInValidation}
                  endIcon={
                    clockInLoading && (
                      <CircularProgress
                        size='20px'
                        sx={{ color: 'inherit' }}
                      />
                    )
                  }>
                  Clock In{' '}
                </Button>

                <Button
                  variant='contained'
                  onClick={() => setOpenClockOut(!openClockOut)}
                  disabled={!clockInValidation}
                  sx={{
                    mx: 2,
                  }}>
                  Clock Out{' '}
                </Button>
              </Box>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}>
                <Skeleton animation='wave' height={70} width={80} />
                <Skeleton
                  sx={{ mx: 2 }}
                  animation='wave'
                  height={70}
                  width={80}
                />
              </Box>
            )}
          </div>
        </Grid>
      }

      <EditProfileModal editModalOpen={editModalOpen} closeEditModal={() => setEditModalOpen(false)} />
      <PersonalizePreferencesModal personalizeModalOpen={personalizeModalOpen} ClosePersonalizeModal={() => setPersonalizeModalOpen(false)} />
      <Modal open={openClockOut} onClose={closeModal}>
        <ClockOutModal handleClose={closeModal}
          fetchData={fetchData} AttendanceDataFetch={AttendanceDataFetch} />
      </Modal>
    </div>
  );
}
