import React, { useState, useEffect, useCallback } from 'react';

import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseIcon from '@mui/icons-material/Close';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PersonIcon from '@mui/icons-material/Person';
import axios from 'axios';
import { useMessage } from '../../../../components/Header';
import { GoKebabHorizontal } from 'react-icons/go';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { useTheme } from '../../../../style/theme';
import { FaCheck } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import { IoEyeOutline } from 'react-icons/io5';
import { RiDeleteBinLine } from 'react-icons/ri';
import { FiEdit } from 'react-icons/fi';
import Noleaveapplication from '../../../DashComponents/Noleaveapplication';
import { calcLeaveDays } from "../../../../utilities/function"


const LeaveViewPage = ({ leaveAppData = [], isDashboardCall }) => {
    const { mode } = useTheme();
    const [leavedata, setLeaveData] = useState(leaveAppData);
    const [leavetype, setLeavetype] = useState([]);
    const { showError, showSuccess } = useMessage();
    const [acceptLoading, setAcceptLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [openMenuId, setOpenMenuId] = useState(null);
    let currentOrg = JSON.parse(localStorage.getItem("org"));


    let currentEmp = currentOrg?.type == "Member" ? JSON.parse(localStorage.getItem("emp")) : null;


    const [paginationModel, setPaginationModel] = useState({
        pageSize: 5,
        page: 0,
    });
    const [rowCount, setRowCount] = useState(0);


    const toggleMenu = (id) => {
        setOpenMenuId(openMenuId == id ? null : id);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    const getColor = (profile) => {
        console.log("profile", profile);
        let colorConfig = {};
        switch (profile) {
            case 'Approved':
                colorConfig = { bgColor: 'bg-green-950', textColor: 'text-green-500' };
                break;
            case 'New':
                colorConfig = { bgColor: 'bg-sky-950', textColor: 'text-sky-500' };
                break;
            case 'Rejected':
                colorConfig = { bgColor: 'bg-red-950', textColor: 'text-red-500' };
                break;
            case 'Pending':
                colorConfig = { bgColor: 'bg-orange-950', textColor: 'text-orange-400' };
                break;
            default:
                colorConfig = { bgColor: 'bg-gray-900', textColor: 'text-gray-500' };
                break;
        }
        return colorConfig;
    };

    const LeaveDetails = useCallback(async () => {
        try {
            if (currentOrg?.type == "Owner") {
                const response = await axios.get(`/hr/attendance/leaves`);
                setLeaveData(response.data.leaves);
            } else {
                const response = await axios.get(
                    `/employee/leaves/${currentEmp?._id}?page=${paginationModel.page + 1 || 1}&pageSize=${paginationModel.pageSize
                    }`
                );
                const { leaves, pageData } = response.data;

                console.log(leaves, "leaveleaveleave");

                // const rows = leaves.map((leave, index) => ({
                //     ...leave,
                //     id: index,
                //     days:
                //         leave.dates.length === 1 ? '1' : calcLeaveDays(leave.dates[0], leave.dates[1]),
                //     from: leave.dates[0],
                //     to: leave.dates.pop(),
                // }));

                // setRows(rows);
                setLeaveData(leaves);
                setRowCount(pageData.totalData || 0);
            }
        } catch (e) {
            console.log(e);
        }
    }, []);

    const LeaveType = useCallback(async () => {
        try {
            const response = await axios.get(`/hr/attendance/leaves-types?orgId=${currentOrg?._id}`);
            setLeavetype(response.data.leaveTypes);
        } catch (e) {
            console.log(e);
        }
    }, []);

    useEffect(() => {
        if (!isDashboardCall) {
            LeaveDetails();
        } else {
            setLeaveData(leaveAppData)
        }
        LeaveType();
    }, [LeaveDetails, LeaveType]);

    const getLeaveTypeName = (leaveTypeId) => {
        const leaveType = leavetype.find(type => type._id === leaveTypeId);
        return leaveType ? leaveType.name : 'Unknown';
    };

    const calculateLeaveDays = (dates) => {
        if (dates.length < 2) {
            return 1;
        }
        const startDate = new Date(dates[0].year, dates[0].month - 1, dates[0].day);
        const endDate = new Date(dates[dates.length - 1].year, dates[dates.length - 1].month - 1, dates[dates.length - 1].day);
        const diffTime = Math.abs(endDate - startDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1; // Add 1 to include both start and end days
        return diffDays;
    };


    const acceptLeave = useCallback(
        async function (id) {
            setAcceptLoading(true);
            try {
                const res = await axios.post(`/hr/attendance/leaves/approve/${id}`);
                LeaveDetails();
                const { success, message } = res.data;
                if (success) return showSuccess(message);
                showError(message);
            } catch (e) {
                console.log(e);
            } finally {
                setAcceptLoading(false);
            }
        },
        [LeaveDetails, showSuccess, showError]
    );

    const rejectLeave = useCallback(
        async function (id) {
            try {
                const res = await axios.post(`/hr/attendance/leaves/deny/${id}`);
                LeaveDetails();
                const { success, message } = res.data;
                if (success) return showSuccess(message);
                showError(message);
            } catch (e) {
                showError(e);
            }
        },
        [LeaveDetails, showSuccess, showError]
    );




    return (
        <Box>
            <div className='flex flex-col'>
                {/* <div className="flex items-center justify-between md:w-full p-4">
                    <div className="p-2">
                        <h1 className="text-2xl text-neutral-500">Leave Application</h1>
                    </div>
                    <div className="flex flex-row items-center justify-center gap-4">
                        <InfoOutlinedIcon />
                    </div>
                </div> */}

                {/* <Box className="w-full  min-w-[48rem] ml-2 md:ml-0 pt-4 rounded-lg mb-4" sx={{ backgroundColor: 'background.view', }}> */}
                {/* <div className='flex items-center mb-5 md:w-full'>
                        <p className='text-[8px] md:text-[12px]  pl-2 md:pl-5'>Show 10 <FontAwesomeIcon icon={faCaretDown} className='text-zinc-500 text-sm md:text-[12px] text-center ml-2 mr-2 text-center' /> entries</p>
                    </div>
                    <div className='w-[97%] ml-2 md:ml-4 border border-zinc-500 rounded-sm '>
                        <div className='flex flex-row border-b border-zinc-500'>
                            <div className='w-[25%] md:w-[10%] p-3 border-r border-zinc-500 text-left text-sm md:text-xs font-bold'>
                                Emp ID
                            </div>
                            <div className='w-[50%] md:w-[10%] p-3 border-r border-zinc-500 text-sm md:text-xs font-bold'>
                                Emp Name
                            </div>
                            <div className='w-[25%] md:w-[10%] p-3 border-r border-zinc-500 text-sm md:text-xs font-bold'>
                                Leave Type
                            </div>
                            <div className='w-[25%] md:w-[10%] p-3 border-r border-zinc-500 text-left text-sm md:text-xs font-bold'>
                                From
                            </div>
                            <div className='w-[50%] md:w-[10%] p-3 border-r border-zinc-500 text-sm md:text-xs font-bold'>
                                To
                            </div>
                            <div className='w-[25%] md:w-[10%] p-3 border-r border-zinc-500 text-sm md:text-xs font-bold'>
                                Days
                            </div>
                            <div className='w-[25%] md:w-[10%] p-3 border-r border-zinc-500 text-left text-sm md:text-xs font-bold'>
                                Reason
                            </div>
                            <div className='w-[50%] md:w-[10%] p-3 border-r border-zinc-500 text-sm md:text-xs font-bold'>
                                Applied On
                            </div>
                            <div className='w-[25%] md:w-[10%] p-3 border-r border-zinc-500 text-sm md:text-xs font-bold'>
                                Status
                            </div>
                            <div className='w-[25%] md:w-[10%] p-3  text-left text-sm md:text-xs font-bold'>
                                Action
                            </div>

                        </div>
                        {leavedata && leavedata.map((leave) => (
                            <div key={leave.id} className='flex flex-row border-b border-zinc-500'>
                                <div className='w-[25%] md:w-[10%] p-3 border-r border-zinc-500 text-left text-sm md:text-[10px]'>
                                    #{leave?.employeeId?.slice(0, 10)}
                                </div>
                                <div className='w-[50%] md:w-[10%] p-1 border-r border-zinc-500 text-sm md:text-[10px] flex flex-row gap-2 flex items-center'>
                                    <div className='flex justify-center items-center pl-2'>
                                        <PersonIcon style={{ fontSize: '16px' }} className="text-zinc-300" />
                                    </div>
                                    <div className=''>
                                        {leave?.fullName}
                                    </div>
                                </div>
                                <div className='w-[25%] md:w-[10%] p-3 border-r border-zinc-500 text-sm md:text-[10px]'>
                                    {getLeaveTypeName(leave?.leaveType)}
                                </div>
                                <div className='w-[25%] md:w-[10%] p-3 border-r border-zinc-500 text-left text-sm md:text-[10px]'>
                                    {leave?.dates[0]?.year}-{leave?.dates[0]?.month}-{leave?.dates[0]?.day}
                                </div>
                                <div className='w-[50%] md:w-[10%] p-3 border-r border-zinc-500 text-sm md:text-[10px]'>
                                    {leave?.dates.length > 1 ? `${leave?.dates[leave.dates.length - 1].year}-${leave?.dates[leave.dates.length - 1].month}-${leave?.dates[leave.dates.length - 1].day}` : '-'}
                                </div>
                                <div className='w-[25%] md:w-[10%] p-3 border-r border-zinc-500 text-sm md:text-[10px]'>
                                    {calculateLeaveDays(leave?.dates)}
                                </div>
                                <div className='w-[25%] md:w-[10%] p-3 border-r border-zinc-500 text-left text-sm md:text-[10px]'>
                                    {leave?.reason}
                                </div>
                                <div className='w-[50%] md:w-[10%] p-3 border-r border-zinc-500 text-sm md:text-[10px]'>
                                    {new Date(leave?.createdAt).toLocaleDateString()}
                                </div>
                                <div className='w-[25%] md:w-[10%] p-3 border-r border-zinc-500'>
                                    <div className={`px-0 py-0 rounded-lg text-sm md:text-[8px] flex justify-center items-center ${getColor(leave?.status).bgColor} ${getColor(leave?.status).textColor}`}>
                                        {leave?.status}
                                    </div>
                                </div>
                                <div className='w-[25%] md:w-[10%] flex flex-row  justify-center items-center'>
                                    {leave?.status !== "Approved" && <IconButton color="success" style={{ borderRadius: "5px", padding: "4px", margin: "2px" }} onClick={() => acceptLeave(leave?._id)}><CheckOutlinedIcon style={{ fontSize: '15px' }} /></IconButton>}
                                    {leave?.status === "Pending" && <IconButton color="error" style={{ borderRadius: "5px", padding: "4px", margin: "2px" }} onClick={() => rejectLeave(leave?._id)}><CloseOutlinedIcon style={{ fontSize: '15px' }} /></IconButton>}
                                    <IconButton style={{ borderRadius: "5px", padding: "4px", margin: "2px" }} ><EditOutlinedIcon style={{ fontSize: '15px' }} /></IconButton>
                                    <IconButton color="primary" style={{ borderRadius: "5px", padding: "4px", margin: "2px" }} ><DeleteOutlineOutlinedIcon style={{ fontSize: '15px' }} /></IconButton>

                                </div>
                            </div>
                        ))}
                    </div>

                    <div className='w-[95%] ml-2  md:ml-5 mt-5 flex justify-between items-center pb-2 mb-20 md:mb-0'>
                        <p className='text-sm md:text-[12px]  '>Showing Rows: 1-10 of 20</p>
                        <div className='flex flex-row gap-4'>
                            <KeyboardArrowLeftOutlinedIcon className='text-zinc-400' />
                            <p className='text-zinc-400'>1</p>
                            <p className='text-zinc-400 bg-blue-500 w-[20px] h-[20px] flex items-center justify-center p-1 rounded-full'>2</p>
                        </div>
                    </div> */}

                {/* </Box> */}


                {leavedata?.length > 0 ? (
                    <Box className="rounded-lg h-full" >
                        <TableContainer className='rounded-lg' sx={{ maxHeight: "400px", overflowY: "auto" }}>
                            <Table>
                                <TableHead sx={{
                                    backgroundColor: mode === "light" ? "#f7f9fc" : "#171717",
                                }}>
                                    <TableRow>
                                        <TableCell >Emp ID</TableCell>
                                        {currentOrg?.type == "Owner" &&
                                            <TableCell >Emp Name</TableCell>
                                        }
                                        <TableCell >Leave Type</TableCell>
                                        <TableCell >From</TableCell>
                                        <TableCell >To</TableCell>
                                        <TableCell >Days</TableCell>
                                        <TableCell>Reason</TableCell>
                                        <TableCell>Applied On</TableCell>
                                        <TableCell>Status</TableCell>
                                        {currentOrg?.type == "Owner" &&
                                            <TableCell>Action</TableCell>
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {leavedata?.map((row, index) => (
                                        <TableRow key={row.id}>
                                            <TableCell>{index + 1}</TableCell>
                                            {currentOrg?.type == "Owner" &&
                                                <TableCell className='flex items-center'>
                                                    {/* <div className='flex justify-center items-center'>
                                                <PersonIcon style={{ fontSize: '16px' }} className="text-zinc-300" />
                                            </div> */}
                                                    <div className=''>
                                                        {row?.fullName}
                                                    </div>
                                                </TableCell>
                                            }
                                            <TableCell>{getLeaveTypeName(row?.leaveType)}</TableCell>
                                            <TableCell>
                                                {row?.dates?.[0]?.year}-{row?.dates?.[0]?.month}-{row?.dates?.[0]?.day}
                                            </TableCell>
                                            <TableCell>{row?.dates.length > 1 ? `${row?.dates[row.dates.length - 1].year}-${row?.dates[row.dates.length - 1].month}-${row?.dates[row.dates.length - 1].day}` : '-'}</TableCell>
                                            <TableCell>{calculateLeaveDays(row?.dates)}</TableCell>
                                            <TableCell>{row.reason}</TableCell>
                                            <TableCell> {new Date(row?.createdAt).toLocaleDateString()}</TableCell>
                                            <TableCell sx={{ paddingX: 0 }}>
                                                <Box
                                                    sx={{
                                                        px: 1,
                                                        py: 1,
                                                        textAlign: "center",
                                                        borderRadius: "6px",
                                                        fontSize: "0.875rem",
                                                        fontWeight: "bold",
                                                        backgroundColor:
                                                            mode === "dark"
                                                                ? row.status === "Approved"
                                                                    ? "#182115"
                                                                    : row.status === "Rejected"
                                                                        ? "#261717"
                                                                        : row.status === "Pending"
                                                                            ? "#271f13"
                                                                            : "transparent"
                                                                : "background.view",
                                                        color:
                                                            mode === "dark" || mode === "light"
                                                                ? row.status === "Approved"
                                                                    ? "#42b824"
                                                                    : row.status === "Rejected"
                                                                        ? "#f13b3b"
                                                                        : row.status === "Pending"
                                                                            ? "#ff9b05"
                                                                            : "transparent"
                                                                : "",
                                                    }}
                                                >{row.status}</Box>
                                            </TableCell>
                                            {currentOrg?.type == "Owner" &&
                                                <TableCell>
                                                    <div className="relative">
                                                        <div className='cursor-pointer' onClick={() => toggleMenu(row._id)} >
                                                            <GoKebabHorizontal size={20} className='m-auto' />
                                                        </div>
                                                        {openMenuId == row._id && (
                                                            <Box sx={{ bgcolor: "background.paper" }} className="absolute right-0 mt-2 w-32 shadow-lg rounded-lg z-40">
                                                                <ul className="text-sm">
                                                                    <li className="cursor-pointer"
                                                                        onClick={() => acceptLeave(row?._id)}
                                                                    >
                                                                        {row?.status !== "Approved" &&
                                                                            <div
                                                                                className="flex items-center gap-2"
                                                                            >
                                                                                <IconButton>
                                                                                    <FaCheck style={{ fontSize: "14px" }} />
                                                                                </IconButton>
                                                                                <button className="block w-full text-left py-2">
                                                                                    Accept
                                                                                </button>
                                                                            </div>
                                                                        }
                                                                    </li>
                                                                    <li className="cursor-pointer"
                                                                        onClick={() => rejectLeave(row?._id)}
                                                                    >
                                                                        {row?.status === "Pending" &&
                                                                            <div
                                                                                className="flex items-center gap-2"
                                                                            >
                                                                                <IconButton>
                                                                                    <IoMdClose style={{ fontSize: "16px" }} />
                                                                                </IconButton>
                                                                                <button className="block w-full text-left py-2">
                                                                                    Reject
                                                                                </button>
                                                                            </div>
                                                                        }
                                                                    </li>
                                                                    <li className="cursor-pointer "
                                                                    // onClick={() => editNotice(row._id)}
                                                                    // onClick={() => handleOpenModal("edit")}
                                                                    >
                                                                        <div className="flex items-center gap-2">
                                                                            <IconButton>
                                                                                <FiEdit style={{ fontSize: "16px" }} />
                                                                            </IconButton>
                                                                            <button className="block w-full text-left py-2 ">
                                                                                Edit
                                                                            </button>
                                                                        </div>
                                                                    </li>
                                                                    <li className="cursor-pointer "
                                                                    // onClick={() => deleteNotice(row._id)}
                                                                    >
                                                                        <div
                                                                            className="flex items-center gap-2"
                                                                        // onClick={() => setOpenDelete(true)}
                                                                        >
                                                                            <IconButton>
                                                                                <RiDeleteBinLine style={{ fontSize: "16px" }} />
                                                                            </IconButton>
                                                                            <button className="block w-full text-left py-2">
                                                                                Delete
                                                                            </button>
                                                                        </div>
                                                                    </li>

                                                                </ul>
                                                            </Box>
                                                        )}
                                                    </div>
                                                </TableCell>
                                            }
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <div className="flex justify-end items-center">
                            <span className="text-sm">
                                Showing Rows: {page * rowsPerPage + 1} - {Math.min((page + 1) * rowsPerPage, rows.length)} of {rows.length}
                            </span>

                            {/* Pagination Controls */}
                            <div className="flex items-center gap-2">
                                <IconButton
                                    sx={{ bgcolor: "background.view" }}
                                    onClick={() => handleChangePage(null, page - 1)}
                                    disabled={page === 0}
                                    className="text-white hover:bg-[#3A3B3C] rounded-lg"
                                >
                                    <MdKeyboardArrowLeft />
                                </IconButton>

                                {/* Page Indicator */}
                                <span className="p-1 text-white px-2 bg-[#3767b1] rounded-full text-xs font-bold">{page + 1}</span>

                                <IconButton
                                    onClick={() => handleChangePage(null, page + 1)}
                                    disabled={(page + 1) * rowsPerPage >= rows.length}
                                    className="text-white hover:bg-[#3A3B3C] rounded-lg"
                                >
                                    <MdKeyboardArrowRight />
                                </IconButton>
                            </div>
                        </div>
                    </Box>
                ) : (<Noleaveapplication />
                )}
            </div>
        </Box>
    );
};

export default LeaveViewPage;
