import { IconButton, InputAdornment, ListSubheader, MenuItem, Select, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useTheme } from "../../../style/theme";
import { useEffect, useRef, useState } from "react";
import { SlHandbag } from "react-icons/sl";
import { CiSearch } from "react-icons/ci";
import CloseIcon from "@mui/icons-material/Close";

export default function ProjectMembers() {
    const { mode } = useTheme();
    const [searchTerm, setSearchTerm] = useState("");
    const [search, setSearch] = useState("");
    const categories = ["Test Project F"];
    const [category, setCategory] = useState("Test Project F");

    const [isExpanded, setIsExpanded] = useState(false);
    const searchRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                setIsExpanded(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    const cardData = [
        { id: 1, name: "Tanya Gwilsbry", role: "Project Manager", weeklyLogged: "00:00 hrs" }
    ];

    // Filtered data based on search term
    const filteredCards = cardData.filter((card) =>
        card.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="py-4 p-2 h-full" style={{ background: mode === "dark" ? "#141414" : "#f7f9fc" }}>
            {/* header */}
            <div>
                {/* Header Content */}
                {!isExpanded && (
                    <div className="flex flex-col sm:flex-row gap-3 sm:items-center justify-between">
                        <div className="flex items-center gap-2">
                            <p className="text-[15px]">Project</p>
                            <div className="w-[200px]">
                                <Select
                                    className={`${mode === "light" ? "bg-white" : "#141414"} w-32`}
                                    fullWidth
                                    size="small"
                                    value={category}
                                    onChange={(e) => setCategory(e.target.value)}
                                    MenuProps={{ PaperProps: { style: { maxHeight: 250 } } }}
                                >
                                    {/* Search Bar in Dropdown */}
                                    <ListSubheader style={{ backgroundColor: mode === "light" ? "white" : "transparent" }}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            placeholder="Search..."
                                            autoFocus
                                            value={search}
                                            onChange={(e) => setSearch(e.target.value)}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon fontSize="small" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </ListSubheader>

                                    {/* Filtered Categories */}
                                    {categories.filter((cat) => cat.toLowerCase().includes(search.toLowerCase())).length > 0 ? (
                                        categories
                                            .filter((cat) => cat.toLowerCase().includes(search.toLowerCase()))
                                            .map((cat) => (
                                                <MenuItem key={cat} value={cat}>
                                                    {cat}
                                                </MenuItem>
                                            ))
                                    ) : (
                                        <ListSubheader style={{ backgroundColor: mode === "light" ? "white" : "transparent" }}>
                                            <MenuItem value="Test Project F">Test Project F</MenuItem>
                                        </ListSubheader>
                                    )}
                                </Select>
                            </div>
                        </div>

                        <div className="flex items-center gap-2">
                            <div style={{ backgroundColor: mode === "light" ? "#e5e5f0" : "#181818" }} className="px-4 py-1 rounded-sm text-[14px]">Member 1</div>
                            <button style={{ backgroundColor: mode === "light" ? "white" : "#181818" }} className="hover:outline hover:outline-blue-500 hover:outline-[1px]  p-1 hover:text-blue-500 rounded-sm shadow" onClick={() => setIsExpanded(true)}>
                                <CiSearch size={20} />
                            </button>
                        </div>
                    </div>
                )}

                {/* Search Bar - Full Width */}
                {isExpanded && (
                    <div ref={searchRef} className="relative transition-all w-full duration-300">
                        <TextField
                            fullWidth
                            placeholder="Enter 3 or more characters to search"
                            variant="outlined"
                            autoFocus
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="transition-all duration-300 bg-white"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton sx={{ backgroundColor: "transparent" }}>
                                            <SearchIcon sx={{ color: "black" }} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton sx={{ backgroundColor: "transparent" }} onClick={() => setIsExpanded(false)}>
                                            <CloseIcon sx={{ color: "black" }} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>

                )}
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-5">
                {filteredCards.length > 0 ? (
                    filteredCards.map((card) => (
                        <div
                            key={card.id}
                            style={{
                                backgroundColor: mode === "dark" ? "#141414" : "white",
                                border: mode === "dark" ? "1px solid #1e2734" : "1px solid #d7e2ed",
                            }}
                            className="p-3 rounded-md cursor-pointer"
                        >
                            <div className="flex items-center gap-2 text-[15px]">
                                <img src="/images/no-user.png" className="w-[60px] h-[60px] rounded-md" alt="" />
                                <div>
                                    <p>{card.id} - {card.name}</p>
                                    <p className="text-[#666d7f]">{card.role}</p>
                                </div>
                            </div>

                            <hr className="my-3" />

                            <div>
                                <div className="flex items-center gap-2">
                                    <SlHandbag /> -
                                </div>
                                <p className="text-[#666d7f] text-[14px]">
                                    Weekly logged - <span className={`${mode === "dark" ? "white" : "black"} text-black`}>
                                        {card.weeklyLogged}
                                    </span>
                                </p>
                            </div>
                        </div>
                    ))
                ) : (
                    <p className="text-center text-gray-500 col-span-3">No matching results</p>
                )}
            </div>

        </div>
    )
}