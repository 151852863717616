import React, { useState } from "react";

import { useTheme } from "../../../style/theme";
import { FaRegStar } from "react-icons/fa";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  IconButton,
  Box,
  ListSubheader,
  InputAdornment,
  Modal,
  Select
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { CgClose } from "react-icons/cg";

import Textarea from "../../DashComponents/TextArea";
import { IoMdClose } from "react-icons/io";
import { SearchIcon } from "lucide-react";

const LeaveModal = ({ setOpen, open }) => {
  const [leaveType, setLeaveType] = useState("Compensatory Off");
  const [email, setEmail] = useState("");
  const [reason, setReason] = useState("");
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [search, setSearch] = useState("");
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [teamEmail, setTeamEmail] = useState("");
  const { mode } = useTheme();
  const namesOptions = [
    { value: "1 Tanya gwilsbry", label: "Compensatory Off" },
  ];
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box className="flex justify-center items-center">
        <Box
          sx={{
            backgroundColor: mode === "dark" ? "#141414" : "white",
          }}
          className=" rounded-lg w-screen h-[90vh]"
        >
          {/* Header Section */}
          <div className="flex justify-between items-center p-3 border-b">
            <Typography variant="h6" className="font-semibold">
              Apply Leave
            </Typography>
            <IconButton
              onClick={() => setOpen(false)}
              sx={{
                backgroundColor: mode === "light" ? "#dcdcdc" : "",
                p: "5px",
                borderRadius: "5px",
              }}
            >
              <IoMdClose size={18} />
            </IconButton>
          </div>

          <div
            style={{
              backgroundColor: mode === "dark" ? "#141414" : "#edf0f4",
            }}
            className="relative h-full"
          >
            <div className="p-4 h-full relative">
              {/* Form Section */}
              <form
                style={{
                  backgroundColor: mode === "dark" ? "#141414" : "white",
                }}
                className="mt-4 flex flex-col gap-y-5 p-4 w-[70%] rounded-lg"
              >
                <h2 className="text-[16px] font-semibold mb-2">Leave</h2>
                <hr />

                {/* Leave Type */}
                <div className="flex items-center w-full">
                  <label
                    className={`${
                      mode === "light" ? "text-gray-600" : "text-white"
                    } font-medium text-[14px] w-[20%]`}
                  >
                    Leave Type <span className="text-red-500">*</span>
                  </label>
                  <div className="w-[80%]">
                    <Select
                      className={`${
                        mode === "light" ? "bg-white" : "bg-[#141414]"
                      }`}
                      fullWidth
                      size="small"
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                      displayEmpty
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 250,
                            backgroundColor:
                              mode === "dark" ? "#141414" : "#ffffff",
                          },
                        },
                      }}
                    >
                      {/* Search Bar in Dropdown */}
                      <ListSubheader
                        style={{
                          backgroundColor:
                            mode === "ligth" ? "white" : "transparent",
                        }}
                      >
                        <TextField
                          fullWidth
                          size="small"
                          placeholder="Search..."
                          autoFocus
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon fontSize="small" />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </ListSubheader>

                      {/* Filtered Categories */}
                      {categories.filter((cat) =>
                        cat.toLowerCase().includes(search.toLowerCase())
                      ).length > 0 ? (
                        categories
                          .filter((cat) =>
                            cat.toLowerCase().includes(search.toLowerCase())
                          )
                          .map((cat) => (
                            <MenuItem key={cat} value={cat}>
                              {cat}
                            </MenuItem>
                          ))
                      ) : (
                        <ListSubheader
                          style={{
                            backgroundColor:
                              mode === "ligth" ? "white" : "transparent",
                          }}
                          className=""
                        >
                          <MenuItem>Select</MenuItem>
                        </ListSubheader>
                      )}
                    </Select>
                  </div>
                </div>

                {/* Date Pickers (Start & End Date Side by Side) */}
                <div className="flex items-center w-full">
                  <label
                    className={`${
                      mode === "light" ? "text-gray-600" : "text-white"
                    } font-medium w-[20%] text-[15px]`}
                  >
                    Date <span className="text-red-500">*</span>
                  </label>
                  <div className="flex gap-4 w-[80%]">
                    <TextField
                      size="small"
                      type="date"
                      fullWidth
                      variant="outlined"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                      size="small"
                      type="date"
                      fullWidth
                      variant="outlined"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                </div>

                {/* Team Email ID */}
                <div className="flex items-center w-full">
                  <label
                    className={`${
                      mode === "light" ? "text-gray-600" : "text-white"
                    } text-[15px] font-medium w-[20%]`}
                  >
                    Team Email ID
                  </label>
                  <div className="w-[80%]">
                    <TextField
                      size="small"
                      fullWidth
                      variant="outlined"
                      value={teamEmail}
                      onChange={(e) => setTeamEmail(e.target.value)}
                    />
                  </div>
                </div>

                {/* Reason for Leave */}
                <div className="flex items-start w-full">
                  <label
                    className={`${
                      mode === "light" ? "text-gray-600" : "text-white"
                    } font-medium w-[20%] text-[15px]`}
                  >
                    Reason for Leave
                  </label>
                  <div className="w-[80%]">
                    <TextField
                      fullWidth
                      variant="outlined"
                      multiline
                      rows={3}
                      value={reason}
                      onChange={(e) => setReason(e.target.value)}
                    />
                  </div>
                </div>
              </form>
            </div>
            <div
              style={{
                backgroundColor: mode === "dark" ? "#141414" : "#ffffff",
              }}
              className="absolute bottom-0 gap-2 flex w-full p-3 h-fit"
            >
              <Button variant="outlined" onClick={() => setOpen(false)}>
                Cancel
              </Button>
              <Button variant="contained" color="primary">
                Submit
              </Button>
            </div>
          </div>
        </Box>
      </Box>
    </Modal>
  );
};

export default function LeaveBalance() {
  const [isHovered, setIsHovered] = useState(false);
  const [open, setOpen] = useState(false);
  const { mode } = useTheme();
  return (
    <div className="h-[100vh] mt-[20px]">
      <div
        className={`flex items-center justify-between border h-[89px]  border-gray-300 rounded-lg p-4 w-full
            ${mode === "light" ? "bg-[#ffffff]" : "bg-[#141414]"}`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {/* Left Icon */}
        <div className="flex items-center gap-3">
          <div className="bg-[#eaf2d9] text-[#adc451] flex justify-center items-center w-[48px] h-[48px] rounded-lg">
            CO
          </div>
          {/* Text Content */}
          <div>
            <h3 className="text-[15px] font-[500]">Compensatory Off</h3>
          </div>
        </div>

        <div className="flex gap-4 items-center">
          <div>
            <p className="text-[#666666] text-[14px]">Available</p>
            <p className="text-[14px]">0 day</p>
          </div>

          <div>
            <p className="text-[#666666] text-[14px]">Booked</p>
            <p className="text-[14px]">0 day</p>
          </div>
        </div>

        <div className="w-[170px]">
          {/* Right Button - Show only on hover */}
          {isHovered && (
            <button
              onClick={() => setOpen(true)}
              className="text-blue-600 border border-[#0088ff] text-[14px] w-[150px] h-[30px] rounded-md transition-all duration-300 hover:bg-blue-100"
            >
              Apply Leave
            </button>
          )}
        </div>
      </div>

      {open && <LeaveModal setOpen={setOpen} open={open} />}
    </div>
  );
}
