import { useState, useEffect, useRef } from "react";
import { IoIosClose } from "react-icons/io";
import { IoTimer } from "react-icons/io5";
import { useTheme } from "../../style/theme";
import { PiCalendarBlankThin } from "react-icons/pi";
import { IoSearch } from "react-icons/io5";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
} from "@mui/material";

import { LuCalendarFold } from "react-icons/lu";
import { CgClose } from "react-icons/cg";

export default function TimeLogs() {
  const [isRunning, setIsRunning] = useState(false);
  const [time, setTime] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isProjectOpen, setIsProjectOpen] = useState(false);
  const [searchProjects, setSearchProjects] = useState("");
  const [selectedProject, setSelectedProject] = useState("Select Proj...");
  const dropdownProjectRef = useRef(null);

  const [isJobOpen, setIsJobOpen] = useState(false);
  const [searchJob, setSearchJob] = useState("");
  const [selectedJob, setSelectedJob] = useState("Select Job");
  const dropdownJobRef = useRef(null);
  const [projectModalOpen, SetProjectModalOpen] = useState(false);
  const [jobModalOpen, SetJobModalOpen] = useState(false);

  useEffect(() => {
    let timer;
    if (isRunning) {
      timer = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
    } else {
      clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [isRunning]);

  const formatTime = (seconds) => {
    const hrs = String(Math.floor(seconds / 3600)).padStart(2, "0");
    const mins = String(Math.floor((seconds % 3600) / 60)).padStart(2, "0");
    const secs = String(seconds % 60).padStart(2, "0");
    return `${hrs}:${mins}:${secs}`;
  };

  const projects = ["Test project F"];
  const job = ["Find"];

  const toggleprojectsDropdown = () => {
    setIsProjectOpen(!isProjectOpen);
  };

  const toggleJobDropdown = () => {
    setIsJobOpen(!isJobOpen);
  };

  const closeProjectsDropdown = (e) => {
    if (
      dropdownProjectRef.current &&
      !dropdownProjectRef.current.contains(e.target)
    ) {
      setIsProjectOpen(false);
    }
  };

  const closeJobDropdown = (e) => {
    if (dropdownJobRef.current && !dropdownJobRef.current.contains(e.target)) {
      setIsJobOpen(false);
    }
  };

  const handleProjectSelect = (project) => {
    setSelectedProject(project);
    setIsProjectOpen(false);
  };

  const handleJobSelect = (job) => {
    setSelectedJob(job);
    setIsProjectOpen(false);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    document.addEventListener("mousedown", closeProjectsDropdown);
    document.addEventListener("mousedown", closeJobDropdown);
    return () =>
      document.removeEventListener(
        "mousedown",
        closeProjectsDropdown,
        closeJobDropdown
      );
  }, []);

  const { mode } = useTheme();

  const ProjectModal = ({ projectModalOpen, SetProjectModalOpen }) => {
    const [projectName, setProjectName] = useState("");
    const { mode } = useTheme();
    return (
      <Dialog
        open={projectModalOpen}
        onClose={() => SetProjectModalOpen(false)}
        fullScreen
        sx={{ margin: "10px", borderRadius: "10px" }}
      >
        {/* Sticky Header */}
        <div
          className={`sticky flex justify-between items-center shadow-md top-0 ${
            mode === "light" ? "bg-white" : "bg-[#141414]"
          }  z-10 py-2 px-4 `}
        >
          <DialogTitle sx={{ padding: "0px" }}>Add Project</DialogTitle>
          <div
            className={`${
              mode === "light" ? "bg-[#dcdcdc]" : ""
            } p-2 rounded-md cursor-pointer`}
            onClick={() => SetProjectModalOpen(false)}
          >
            <CgClose />
          </div>
        </div>

        {/* Grey Background Content */}
        <DialogContent
          sx={{
            bgcolor: mode === "light" ? "#edf0f4" : "#141414",
            p: 2,
          }}
        >
          <Box
            sx={{
              bgcolor: mode === "light" ? "#ffffff" : "",
              borderRadius: "12px",
              marginTop: "10px",
            }}
          >
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              sx={{ fontWeight: "700px", fontSize: "16px", padding: "20px" }}
            >
              Project Configuration Details
            </Typography>

            <hr />

            <div className="flex gap-4 items-center p-5">
              <label
                className={`block text-[15px] font-[400] w-[170px] ${
                  mode === "light" ? "text-[#666666]" : ""
                } `}
              >
                Project Name
                <span className="text-red-500">*</span>
              </label>
              <TextField
                fullWidth
                // label="Project Name"
                required
                variant="outlined"
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    height: "34px",
                  },
                  mt: "15px",
                }}
              />
            </div>
          </Box>
        </DialogContent>

        {/* Sticky Bottom Section */}
        <div
          className={`sticky bottom-0 flex gap-2 items-center shadow-md ${
            mode === "light" ? "bg-[#ffffff]" : "bg-[#141414]"
          } z-10 p-4 flex justify-start`}
        >
          <button className="text-[14px] px-4 py-1 bg-[#0088ff] text-white rounded">
            Submit
          </button>
          <button
            className="px-4 py-1 border border-gray-300 text-[14px] rounded"
            onClick={() => SetProjectModalOpen(false)}
          >
            Cancel
          </button>
        </div>
      </Dialog>
    );
  };

  const JobModal = ({ jobModalOpen, SetJobModalOpen }) => {
    const [jobName, setJobName] = useState("");
    const { mode } = useTheme();
    return (
      <Dialog
        open={jobModalOpen}
        onClose={() => SetJobModalOpen(false)}
        fullScreen
        sx={{ margin: "10px", borderRadius: "10px" }}
      >
        {/* Sticky Header */}
        <div
          className={`sticky flex justify-between items-center shadow-md top-0 ${
            mode === "light" ? "bg-white" : "bg-[#141414]"
          }  z-10 py-2 px-4 `}
        >
          <DialogTitle sx={{ padding: "0px" }}>Add Job</DialogTitle>
          <div
            className={`${
              mode === "light" ? "bg-[#dcdcdc]" : ""
            } p-2 rounded-md cursor-pointer`}
            onClick={() => SetJobModalOpen(false)}
          >
            <CgClose />
          </div>
        </div>

        {/* Grey Background Content */}
        <DialogContent
          sx={{
            bgcolor: mode === "light" ? "#edf0f4" : "#141414",
            p: 2,
          }}
        >
          <Box
            sx={{
              bgcolor: mode === "light" ? "#ffffff" : "",
              borderRadius: "12px",
              marginTop: "10px",
            }}
          >
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              sx={{ fontWeight: "700px", fontSize: "16px", padding: "20px" }}
            >
              Job Configuration Details
            </Typography>

            <hr />

            <div className="flex gap-4 items-center p-5">
              <label
                className={`block text-[15px] font-[400] w-[170px] ${
                  mode === "light" ? "text-[#666666]" : ""
                } `}
              >
                Job Name
                <span className="text-red-500">*</span>
              </label>
              <TextField
                fullWidth
                // label="Project Name"
                required
                variant="outlined"
                value={jobName}
                onChange={(e) => setJobName(e.target.value)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    height: "34px",
                  },
                  mt: "15px",
                }}
              />
            </div>
          </Box>
        </DialogContent>

        {/* Sticky Bottom Section */}
        <div
          className={`sticky bottom-0 flex gap-2 items-center shadow-md ${
            mode === "light" ? "bg-[#ffffff]" : "bg-[#141414]"
          } z-10 p-4 flex justify-start`}
        >
          <button className="text-[14px] px-4 py-1 bg-[#0088ff] text-white rounded">
            Submit
          </button>
          <button
            className="px-4 py-1 border border-gray-300 text-[14px] rounded"
            onClick={() => SetJobModalOpen(false)}
          >
            Cancel
          </button>
        </div>
      </Dialog>
    );
  };

  return (
    <div>
      <div
        className={`w-full  flex flex-col sm:flex-row justify-between sm:items-center rounded-lg p-3 gap-2 ${
          mode === "light" ? "bg-white " : ""
        }  shadow`}
      >
        <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-2">
          {/* <select className={`p-2 rounded ${mode === "light" ? "[bg-#fff] border" :"bg-[transparent]"}`}>
        <option value={"Select Proj..."}>Select Proj...</option>
      </select> */}

          {/* Projects */}
          <div
            className="relative "
            onClick={toggleprojectsDropdown}
            ref={dropdownProjectRef}
          >
            <select
              className={`w-full px-2 text-[14px] py-2 pointer-events-none text-left ${
                mode === "light" ? "bg-white" : "bg-transparent"
              } border rounded outline-none`}
            >
              <option value={selectedProject}>{selectedProject} </option>
            </select>
            {isProjectOpen && (
              <div
                className={`w-[320px] absolute left-0 mt-2 ${
                  mode === "light" ? "bg-white" : "bg-[#141414]"
                } border  rounded-md shadow-lg`}
              >
                <div
                  className={`${mode == "light" ? "bg-[#e1e4e7]" : ""}  p-2`}
                >
                  <div
                    className={`flex  items-center border border-[#0088ff] rounded-sm h-[30px] px-2 ${
                      mode === "light" ? "bg-[#fff] " : ""
                    }`}
                  >
                    <IoSearch color="#c1c1c1" />
                    <input
                      type="text"
                      // placeholder="Search"
                      value={searchProjects}
                      onChange={(e) => setSearchProjects(e.target.value)}
                      className="w-full px-3 py-2 border-none rounded-md outline-none bg-transparent "
                    />
                  </div>
                </div>
                <ul className="max-h-48 overflow-y-auto">
                  <li
                    className={`px-4 py-2 ${
                      mode === "light" ? "bg-gray-100" : ""
                    } text-gray-600`}
                  >
                    Select Project
                  </li>
                  {projects
                    .filter((p) =>
                      p.toLowerCase().includes(searchProjects.toLowerCase())
                    )
                    .map((project, index) => (
                      <li
                        key={index}
                        className={`px-4 py-2 ${
                          mode === "light" ? "hover:bg-gray-100" : ""
                        }  cursor-pointer`}
                        onClick={() => handleProjectSelect(project)}
                      >
                        {project}
                      </li>
                    ))}
                </ul>
                <div
                  className={`flex justify-center items-center ${
                    mode === " light" ? "hover:bg-gray-100" : ""
                  } p-2`}
                >
                  <button
                    onClick={() => SetProjectModalOpen(true)}
                    className="text-[14px] rounded-md px-2 py-1 text-[#0088ff] border border-[#0088ff] "
                  >
                    Add Project
                  </button>
                </div>
              </div>
            )}
            {projectModalOpen && (
              <ProjectModal
                projectModalOpen={projectModalOpen}
                SetProjectModalOpen={SetProjectModalOpen}
              />
            )}
          </div>

          {/* Jobs */}
          <div
            className="relative "
            ref={dropdownJobRef}
            onClick={toggleJobDropdown}
          >
            <select
              className={`w-full px-2 text-[14px] py-2 text-left pointer-events-none ${
                mode === "light" ? "bg-white" : "bg-transparent"
              } border rounded outline-none`}
            >
              <option>{selectedJob} </option>
            </select>
            {isJobOpen && (
              <div
                className={`w-[320px] absolute left-0 mt-2  ${
                  mode === "light" ? "bg-white" : "bg-[#141414]"
                } border  rounded-md shadow-lg`}
              >
                <div
                  className={`${mode == "light" ? "bg-[#e1e4e7]" : ""}  p-2`}
                >
                  <div
                    className={`flex  items-center border border-[#0088ff] rounded-sm h-[30px] px-2 ${
                      mode === "light" ? "bg-[#fff] " : ""
                    }`}
                  >
                    <IoSearch color="#c1c1c1" />
                    <input
                      type="text"
                      // placeholder="Search"
                      value={searchJob}
                      onChange={(e) => setSearchJob(e.target.value)}
                      className="w-full px-3 py-2 border-none rounded-md outline-none bg-transparent "
                    />
                  </div>
                </div>
                <ul className="max-h-48 overflow-y-auto">
                  <li
                    className={`px-4 py-2 ${
                      mode === "light" ? "bg-gray-100" : ""
                    } text-gray-600`}
                  >
                    Select Job
                  </li>
                  {job
                    .filter((p) =>
                      p.toLowerCase().includes(searchJob.toLowerCase())
                    )
                    .map((job, index) => (
                      <li
                        key={index}
                        className={`px-4 py-2 ${
                          mode === "light" ? "hover:bg-gray-100" : ""
                        }  cursor-pointer`}
                        onClick={() => handleJobSelect(job)}
                      >
                        {job}
                      </li>
                    ))}
                </ul>
                <div className="flex justify-center items-center p-2">
                  <button
                    onClick={() => SetJobModalOpen(true)}
                    className="text-[14px] rounded-md px-2 py-1 text-[#0088ff] border border-[#0088ff] "
                  >
                    Add Job
                  </button>
                </div>
              </div>
            )}

            {jobModalOpen && (
              <JobModal
                jobModalOpen={jobModalOpen}
                SetJobModalOpen={SetJobModalOpen}
              />
            )}
          </div>

          {/* <select
            className={`p-2 rounded ${
              mode === "light" ? "[bg-#fff] border" : "bg-[transparent]"
            }`}
          >
            <option value={"Select Job"}>Select Job</option>
          </select> */}
          <input
            type="text"
            placeholder="What are you working on?"
            className={`${
              mode === "light" ? "bg-[#fff] " : "bg-[transparent]"
            } border p-2 rounded sm:w-64 outline-none w-full`}
          />
          <select
            className={`${
              mode === "light" ? "bg-[#fff] " : "bg-transparent"
            } p-2 rounded outline-none border`}
          >
            <option
              className={`${
                mode === "light"
                  ? "bg-white text-black"
                  : "bg-[#141414] text-white"
              }`}
              value={"Billable"}
            >
              Billable
            </option>
            <option
              className={`${
                mode === "light"
                  ? "bg-white text-black"
                  : "bg-[#141414] text-white"
              }`}
              value={"Billable"}
            >
              Non-Billable
            </option>
          </select>

          <div className="relative">
            <div
              className={`p-2  border rounded ${
                mode === "light" ? "bg-[#edf0f4]" : ""
              }  cursor-pointer`}
              onClick={() => setIsModalOpen(!isModalOpen)}
            >
              {/* <LuCalendarFold color="#737373" /> */}
              <PiCalendarBlankThin color="#737373" />
            </div>

            {isModalOpen && (
              <div
                className={`absolute top-10 right-[20px] w-[300px] p-4   ${
                  mode === "light" ? "bg-white" : "bg-[#141414]"
                } border shadow-lg rounded-md `}
              >
                <div className="absolute top-1 right-2 mb-2">
                  <IoIosClose
                    onClick={() => setIsModalOpen(false)}
                    size={"25px"}
                    color={"#ed5158"}
                  />
                </div>
                <textarea className="w-[270px]  h-[96px] border rounded p-2 text-[#000] mt-3 outline-none"></textarea>
                <button className="mt-2 p-1 text-[14px] bg-blue-500 text-white rounded">
                  OK
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="flex items-center">
          <button
            className={`p-2 w-[130px] rounded text-white  ${
              isRunning ? "bg-red-500" : "bg-green-500"
            }`}
          >
            {formatTime(time)}
          </button>
          <div
            className={`bg-[#fff] rounded-full ms-[-20px] p-1`}
            onClick={() => setIsRunning(!isRunning)}
          >
            <IoTimer color="#0ebc6b" size={"20px"} />
          </div>
        </div>
      </div>

      <div
        className={`flex flex-col justify-center py-7 items-center rounded-md mt-[30px] ${
          mode === "light" ? "bg-[#ffffff]" : ""
        }`}
      >
        <img
          style={{ width: "240px", marginBottom: "20px" }}
          src="/images/no_timeLogs.png"
          alt=""
        />
        <h4 className="font-[600] text-[22px]">No time logs available </h4>
        <p className="text-[#868686] text-[16px] text-center mt-2">
          {" "}
          You currently do not have any available time logs. When you add
          employees to your organization, you will be able to track their
          activities.
        </p>
      </div>
    </div>
  );
}
