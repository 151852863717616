import React, { useState } from "react";
import { Tab, Tabs } from "@mui/material";

import AddressProof from "./AddressProof";
import BonafideLetter from "./BonafideLetter";
import ExperienceLetter from "./ExperienceLetter";
import { useSwitchScreen } from "../../../hooks/SwitchScreenContext";

export default function HRLetters() {
   const { switchScreen } = useSwitchScreen(); 

  return (
    <div className="w-full flex flex-col gap-3 px-3 pb-3 rounded-lg border border-[#1e2734]">
     
      {switchScreen.primary === "addressProof" ? (
        <>
          <AddressProof />
        </>
      ) : (
        <></>
      )}
      {switchScreen.primary === "bonafideLetter" ? (
        <>
          <BonafideLetter />
        </>
      ) : (
        <></>
      )}
      {switchScreen.primary === "experienceLetter" ? (
        <>
          <ExperienceLetter />
        </>
      ) : (
        <></>
      )}
    </div>
  );
}
