import { Add, Close, PlusOne } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Drawer,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import Grid from "@mui/material/Grid2";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import CustomEmptyModal from "./CustomEmptyModal";
import { closestCenter, DndContext } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const CustomAddTask = ({ open, onClose }) => {
  const [openPriorityModal, setOpenPriorityModal] = useState(false);
  const { mode } = useTheme();
  const [priorities, setPriorities] = useState([
    { id: 1, title: "High" },
    { id: 2, title: "Moderate" },
    { id: 3, title: "Low" },
  ]);

  const getPriorityPosition = (id) => priorities.findIndex((item) => item.id === id);
  
  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id === over.id) return;
    setPriorities((items) => {
      const originalPosition = getPriorityPosition(active.id);
      const newPosition = getPriorityPosition(over.id);

      return arrayMove(items, originalPosition, newPosition);
    });
    // console.log("tasks", tasks);
  };

  const Task = ({ id, title }) => {
    const { attributes, listeners, setNodeRef, transform, transition } =
      useSortable({ id });
    console.log("title", title);
    return (
      <div
        key={id}
        ref={setNodeRef}
        {...attributes}
        {...listeners}
        style={{
          transition,
          transform: CSS.Transform.toString(transform),
        }}
        className="border bg-white p-3 rounded-lg "
      >
        <p>{title}bc</p>
      </div>
    );
  };
  return (
    <div>
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            width: 1150,
            m: 1,
            maxHeight: "98vh", // Limits height to 90% of viewport
            overflowY: "auto",
            borderRadius: 4,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: 2,
            borderBottom: "1px solid #ddd",
            position: "sticky",
            backgroundColor: "background.default",
            top: 0,
            zIndex: 10, // Keeps it above scrollable content
          }}
        >
          <Typography variant="h6">Add Task</Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
        <Box
          sx={{ padding: 2, display: "flex", flexDirection: "column", gap: 2 }}
        >
          <div className="border rounded-md p-4 ">
            <div>
              <p>Task Details</p>
              <Divider sx={{ mt: 2 }} />
            </div>

            <Grid container spacing={2} sx={{ padding: 2 }}>
              <Grid size={{ xs: 6, md: 2 }}>
                <p>Task Owner</p>
              </Grid>
              <Grid size={{ xs: 6, md: 10 }}>
                <FormControl fullWidth>
                  <Select
                    size="small"
                    name="taskOwner"
                    variant="outlined"
                    value={""}
                  >
                    <MenuItem value={"Bisham"}>{"Bisham"}</MenuItem>
                    <MenuItem value={"Saad"}>{"Saad"}</MenuItem>
                    <MenuItem value={"Fahad"}>{"Fahad"}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid size={{ xs: 6, md: 2 }}>
                <p>Task Name</p>
              </Grid>
              <Grid size={{ xs: 6, md: 10 }}>
                <TextField
                  sx={{ width: "100%" }}
                  name="taskName"
                  size="small"
                  variant="outlined"
                  value={""}
                  // onChange={(event) => {
                  //   setFormData((prev) => ({
                  //     ...prev,
                  //     currentExperience: event.target.value,
                  //   }));
                  // }}
                  disabled
                />
              </Grid>
              <Grid size={{ xs: 6, md: 2 }}>
                <p>Description</p>
              </Grid>
              <Grid size={{ xs: 6, md: 10 }}>
                <TextField
                  sx={{ width: "100%" }}
                  multiline={true}
                  minRows={3}
                  size="small"
                  name="taskName"
                  variant="outlined"
                  value={""}
                  // onChange={(event) => {
                  //   setFormData((prev) => ({
                  //     ...prev,
                  //     currentExperience: event.target.value,
                  //   }));
                  // }}
                  disabled
                />
              </Grid>
              <Grid size={{ xs: 6, md: 2 }}>
                <p>Start Date</p>
              </Grid>
              <Grid size={{ xs: 6, md: 10 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{
                      minWidth: "200px",
                      width: "100%",
                      padding: 0,
                      margin: 0,
                    }}
                    size="small"
                    // label={column.label}
                    name={"startDate"}
                    value={dayjs("")}
                    // onChange={(date) =>
                    //   handleDateChange(date, column.name, rowIndex)
                    // }
                    // disabled={
                    //   isView ||
                    //   column.disabled ||
                    //   editableRow !== rowIndex
                    // }
                  />
                </LocalizationProvider>
              </Grid>
              <Grid size={{ xs: 6, md: 2 }}>
                <p>Due Date</p>
              </Grid>
              <Grid size={{ xs: 6, md: 10 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{
                      minWidth: "200px",
                      width: "100%",
                      padding: 0,
                      margin: 0,
                    }}
                    size="small"
                    // label={column.label}
                    name={"dueDate"}
                    value={dayjs("")}
                    // onChange={(date) =>
                    //   handleDateChange(date, column.name, rowIndex)
                    // }
                    // disabled={
                    //   isView ||
                    //   column.disabled ||
                    //   editableRow !== rowIndex
                    // }
                  />
                </LocalizationProvider>
              </Grid>
              <Grid size={{ xs: 6, md: 2 }}>
                <p>Reminder</p>
              </Grid>
              <Grid size={{ xs: 6, md: 10 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    // label="Select Date and Time"
                    sx={{
                      minWidth: "200px",
                      width: "100%",
                      padding: 0,
                      margin: 0,
                    }}
                    value={dayjs("")}
                    name="reminder"
                    // onChange={(newValue) => {
                    //   const updatedCriterias = [
                    //     ...form.criterias,
                    //   ];
                    //   updatedCriterias[index].value = newValue;
                    //   setForm((prevForm) => ({
                    //     ...prevForm,
                    //     criterias: updatedCriterias,
                    //   }));
                    //   resetCriteriaError(index);
                    // }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        // error={!!criteriaErrors[index]}
                        // helperText={criteriaErrors[index]}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid size={{ xs: 6, md: 2 }}>
                <p>Priority</p>
              </Grid>

              <Grid
                size={{ xs: 6, md: 10 }}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <FormControl fullWidth>
                  <Select
                    size="small"
                    name="priority"
                    variant="outlined"
                    value={""}
                  >
                    <MenuItem value={"Bisham"}>{"Bisham"}</MenuItem>
                    <MenuItem value={"Saad"}>{"Saad"}</MenuItem>
                    <MenuItem value={"Fahad"}>{"Fahad"}</MenuItem>
                  </Select>
                </FormControl>
                <IconButton
                  onClick={() => setOpenPriorityModal(true)}
                  sx={{
                    backgroundColor: mode == "light" ? "#edf0f4" : "#3333",
                    fontSize: 14,
                    borderRadius: "6px",
                    ml: 2,
                  }}
                  title="Add Priority"
                >
                  <Add fontSize="18px" />
                </IconButton>
              </Grid>
              <Grid size={{ xs: 6, md: 2 }}>
                <p>Status</p>
              </Grid>
              <Grid
                size={{ xs: 6, md: 10 }}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <FormControl fullWidth>
                  <Select
                    size="small"
                    name="priority"
                    variant="outlined"
                    value={""}
                  >
                    <MenuItem value={"Bisham"}>{"Bisham"}</MenuItem>
                    <MenuItem value={"Saad"}>{"Saad"}</MenuItem>
                    <MenuItem value={"Fahad"}>{"Fahad"}</MenuItem>
                  </Select>
                </FormControl>
                <IconButton
                  onClick={() => setOpenPriorityModal(true)}
                  sx={{
                    backgroundColor: mode == "light" ? "#edf0f4" : "#3333",
                    fontSize: 14,
                    borderRadius: "6px",
                    ml: 2,
                  }}
                  title="Add Priority"
                >
                  <Add fontSize="18px" />
                </IconButton>
              </Grid>
            </Grid>
          </div>
        </Box>
        <Box
          sx={{ backgroundColor: "background.default" }}
          className="w-full min-h-14 flex items-center z-50 sticky bottom-0 left-0 p-4"
        >
          <div className="flex flex-row gap-3 justify-between items-center">
            <Button variant="contained" type="submit">
              Submit
            </Button>
            <Button variant="contained" type="submit">
              Submit and New
            </Button>
            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>
          </div>
        </Box>
      </Drawer>

      <CustomEmptyModal
        isScrollable={true}
        isSmall={true}
        open={openPriorityModal}
        onClose={() => setOpenPriorityModal(false)}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: 2,
            borderBottom: "1px solid #ddd",
            position: "sticky",
            backgroundColor: "background.default",
            top: 0,
            zIndex: 10, // Keeps it above scrollable content
          }}
        >
          <Typography variant="h6">Edit Quick Add</Typography>
          <IconButton onClick={() => setOpenPriorityModal(false)}>
            <Close />
          </IconButton>
        </Box>
        <Box sx={{ padding: 2, display: "flex", flexDirection: "column" }}>
          <Grid container spacing={1}>
            <Grid size={{ xs: 6, md: 10 }}>
              <p>Add Value</p>
            </Grid>
            <Grid size={{ xs: 6, md: 10 }}>
              <TextField
                sx={{ width: "100%" }}
                name="addValue"
                size="small"
                variant="outlined"
                value={""}
                // onChange={(event) => {
                //   setFormData((prev) => ({
                //     ...prev,
                //     currentExperience: event.target.value,
                //   }));
                // }}
                disabled
              />
            </Grid>
          </Grid>

          <div className="p-4 mt-3 rounded-lg h-[60vh] bg-[#f2f2f2] ">
            <DndContext
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={priorities}
                strategy={verticalListSortingStrategy}
              >
                {priorities.map((item, index) => (
                  <Task id={item.id} title={item.title} />
                ))}
              </SortableContext>
            </DndContext>
          </div>
        </Box>

        <Box
          sx={{ backgroundColor: "background.default" }}
          className="w-full min-h-14 flex items-center z-50 sticky bottom-0 left-0 p-4"
        >
          <div className="flex flex-row gap-3 justify-between items-center">
            <Button variant="contained" type="submit">
              Done
            </Button>
            <Button variant="outlined" onClick={() => setOpenPriorityModal(false)}>
              Cancel
            </Button>
          </div>
        </Box>
      </CustomEmptyModal>
    </div>
  );
};

export default CustomAddTask;
