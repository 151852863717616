import React, { useState } from "react";
import { Tab, Tabs } from "@mui/material";
import AllApprovals from "./AllApprovals";
import { useSwitchScreen } from "../../../hooks/SwitchScreenContext";

export default function Approvals() {
   const { switchScreen } = useSwitchScreen(); 


  return (
    <div className="w-full flex flex-col gap-3 px-3 pb-3 rounded-lg border border-[#1e2734]">
      {switchScreen.primary === "allapprovals" ? (
        <>
          <AllApprovals />
        </>
      ) : (
        <></>
      )}
    </div>
  );
}
