import React, { useEffect, useRef, useState } from 'react'
import { useTheme } from '../../style/theme';
import { IoSearch } from 'react-icons/io5';

const SelectJob = () => {
    


  const [isJobOpen, setIsJobOpen] = useState(false);
  const [searchJob, setSearchJob] = useState("");
  const [selectedJob, setSelectedJob] = useState("Select Job");
  const [jobModalOpen, SetJobModalOpen] = useState(false);
  const dropdownJobRef = useRef(null);

  const { mode } = useTheme();
  const job = ["Find"];

  const toggleJobDropdown = () => {
    setIsJobOpen(!isJobOpen);
  };


  const closeJobDropdown = (e) => {
    if (dropdownJobRef.current && !dropdownJobRef.current.contains(e.target)) {
      setIsJobOpen(false);
    }
  };


  const handleJobSelect = (job) => {
    setSelectedJob(job);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    document.addEventListener("mousedown", closeJobDropdown);
    return () =>
      document.removeEventListener(
        "mousedown",
        closeJobDropdown
      );
  }, []);

  

  return (
      <div
                  className="relative w-full"
                  ref={dropdownJobRef}
                  onClick={toggleJobDropdown}
                >
                  <select
                    className={`w-full h-[34px] px-2 text-[14px] py-2 pointer-events-none text-left ${
                      mode === "light" ? "bg-white" : "bg-transparent"
                    } border rounded outline-none`}
                  >
                    <option>{selectedJob} </option>
                  </select>
                  {isJobOpen && (
                    <div
                    className={`w-full z-40  absolute left-0 mt-2 ${
                      mode === "light" ? "bg-white" : "bg-[#141414]"
                    } border  rounded-md shadow-lg`}
                  >
                      <div
                        className={`${mode == "light" ? "bg-[#e1e4e7]" : ""}  p-2`}
                      >
                        <div
                          className={`flex  items-center border border-[#0088ff] rounded-sm h-[30px] px-2 ${
                            mode === "light" ? "bg-[#fff] " : ""
                          }`}
                        >
                          <IoSearch color="#c1c1c1" />
                          <input
                            type="text"
                            // placeholder="Search"
                            value={searchJob}
                            onChange={(e) => setSearchJob(e.target.value)}
                            className="w-full px-3 py-2 border-none rounded-md outline-none bg-transparent "
                          />
                        </div>
                      </div>
                      <ul className="max-h-48 overflow-y-auto">
                        <li className="px-4 py-2 bg-gray-100 text-gray-600">
                          Select Job
                        </li>
                        {job
                          .filter((p) =>
                            p.toLowerCase().includes(searchJob.toLowerCase())
                          )
                          .map((job, index) => (
                            <li
                              key={index}
                              className="px-4 py-2  hover:bg-gray-100 cursor-pointer"
                              onClick={() => handleJobSelect(job)}
                            >
                              {job}
                            </li>
                          ))}
                      </ul>
                    </div>
                  )}
                </div>
  )
}

export default SelectJob
