import React, { useEffect, useRef, useState } from "react";
import ChatList from "./ChatList";
import MapsUgcRoundedIcon from "@mui/icons-material/MapsUgcRounded";
import CallRoundedIcon from "@mui/icons-material/CallRounded";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import ContactList from "./ContactList";
import {
  Box,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  Modal,
  SvgIcon,
  Tooltip,
  Typography,
} from "@mui/material";
import ChatSection from "./Newchatsection";
import chatIcon from "../../services/icons/chatIcon/chat.svg";
import callIcon from "../../services/icons/chatIcon/call.svg";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import useModal from "../../hooks/useModal";
import useSocket from "../../hooks/useEmployeeSocket";
import { useSwitchScreen } from "../../hooks/SwitchScreenContext";
import NoEmployee from "../../components/NoEmployee";
import noChatContact from "../../assets/noChatContact.png";
import { TbEdit } from "react-icons/tb";
import { useTheme } from "../../style/theme";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: theme.palette.custom.search.main,
  width: "100%",
  borderRadius: "10px",
  padding: "0px 13px",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  fontSize: "14px",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(3.5)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const ChatPage = () => {
  const { switchScreen } = useSwitchScreen();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [currentChatUser, setCurrentChatUser] = useState([]);
  const { modalState, closeModal, openModal } = useModal();
  const dropdownRef = useRef(null);
  let currentOrg = JSON.parse(localStorage.getItem("org"));

  console.log(currentOrg, "currentOrgcurrentOrgcurrentOrg");
  const { messages, sendMessage, contacts, chatList } = useSocket();

  const handleInputChange = (event) => {
    const value = event.target.value;

    setSearchTerm(value);
    if (value) {
      const filtered = contacts.filter(
        (contact) =>
          `${contact?.firstName} ${contact?.lastName}`
            .toLowerCase()
            .includes(value?.toLowerCase()) ||
          contact?.email?.toLowerCase().includes(value?.toLowerCase())
      );
      console.log("filtered", filtered);
      setFilteredContacts(filtered);
    } else {
      setFilteredContacts([]);
    }
  };


  const handleClick = (contact) => {
    // alert(`Selected Contact: ${contact.firstName} ${contact.lastName}`);
    setCurrentChatUser(contact);
    clearSearch();
  };

  const clearSearch = () => {
    setSearchTerm("");
    setFilteredContacts([]);
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      clearSearch();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);


  const handelMobileChatOpen = (data) => {
    openModal();
    handelChatOpen(data);
  };
  const handelChatOpen = (data) => {
    setCurrentChatUser(data);
  };
  const { mode } = useTheme();
  return (
    <>
      {currentOrg?.type == "Member" ? (
        <>
          <Box
            className="h-full flex flex-col overflow-hidden px-2 sm:px-8 pb-4 md:py-4"
            sx={{ backgroundColor: mode === "dark" ? "#141414" : "", borderRadius: "14px" }}
          >
            {/* web Chat screen  */}
            {/* <div className="hidden md:flex justify-between items-center pb-3 ">
        <h1 className="text-2xl text-neutral-500">Chat</h1>
        <Tooltip title="info" placement="top">
          <InfoOutlinedIcon />
        </Tooltip>
      </div> */}

            <div className=" hidden md:flex md:pb-4 ml-[-25px] ">
              <Box
                sx={{
                  backgroundColor: mode === "dark" ? "#141414" : "",
                  borderRadius: "10px",
                  height: "85.5vh",
                }}
                className={`block w-[24vw] relative mb-4 mr-2 rounded-md border border-[#3F3F3F]`}
              >
                <div className={`overflow-hidden `}>
                  <div className="block " ref={dropdownRef}>
                    {/* <div className="flex gap-4 my-4 px-2.5 justify-center ">
                <Button
                  onClick={() => setSwitchScreen("chat")}
                  sx={{
                    padding: "3px 38px",
                    backgroundColor: switchScreen.primary == "chat" ? "" : "background.main",
                    border: switchScreen.primary == "chat" ? "" : "0.8px solid",
                    color: "text.two",
                    borderRadius: "5px",
                  }}
                  variant="contained"
                >
                  Chat
                </Button>
                <Button
                  onClick={() => setSwitchScreen("contacts")}
                  sx={{
                    borderRadius: "5px",
                    padding: "3px 25px",
                    color: "text.two",
                    backgroundColor:
                      switchScreen.primary == "contacts" ? "" : "background.main",
                    border: switchScreen.primary == "contacts" ? "" : "0.8px solid ",
                  }}
                  variant="contained"
                >
                  Contacts
                </Button>
              </div> */}

                    <div className="flex gap-4 my-2 px-2.5 justify-center items-center " >
                      <div
                        component="form"
                        style={{
                          p: "2px 2px",
                          display: "flex",
                          alignItems: "center",
                          width: 450,
                          height: 40,
                          borderRadius: "5px",
                          border: "1px solid #3F3F3F",
                          backgroundColor:
                            mode === "dark" ? "#141414" : "transparent",
                          boxShadow: "none",
                          marginTop: "6px",
                        }}
                      >
                        <InputBase
                          sx={{
                            ml: 1,
                            flex: 1,
                            fontSize: "12px",
                            backgroundColor: mode === "dark" ? "#141414" : "",
                            color: "gray",
                            "&::placeholder": {
                              color: "gray",
                            },
                          }}
                          placeholder="Search or start a new chat"
                          inputProps={{
                            "aria-label": "search or start a new chat",
                          }}
                          value={searchTerm}
                          onChange={handleInputChange}
                        />
                        <IconButton
                          type="button"
                          sx={{
                            p: "10px",
                            color: "#666",
                            background: "none",
                            "&:hover": {
                              backgroundColor: "rgba(0, 0, 0, 0.05)",
                            },
                          }}
                          aria-label="search"
                        >
                          <SearchIcon />
                        </IconButton>
                      </div>

                      <IconButton
                        type="button"
                        sx={{
                          // p: '10px',
                          color: "white",
                          background: "none",
                          "&:hover": {
                            backgroundColor: "rgba(0, 0, 0, 0.05)",
                          },
                        }}
                        aria-label="search"
                      >
                        <TbEdit
                          size={"20px"}
                          className={`${mode === "light" ? "text-[#000]" : "text-[#fff]"
                            }`}
                        />
                      </IconButton>
                    </div>

                    {filteredContacts.length > 0 && (
                      <List
                        sx={{
                          position: "absolute",
                          // top: '100%',
                          width: "100%",
                          maxHeight: "200px",
                          overflowY: "auto",
                          backgroundColor: mode == "dark" ? "#171717" : "white",
                          zIndex: 2,
                          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        {filteredContacts.map((option) => {
                          const nameToDisplay = `${option.firstName} ${option.lastName}`;

                          return (
                            <ListItem
                              key={option._id}
                              button
                              onClick={() => handleClick(option)}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Grid container sx={{ alignItems: "center" }}>
                                <Grid item sx={{ display: "flex", width: 44 }}>
                                  <img
                                    loading="lazy"
                                    width="40"
                                    src={`https://ui-avatars.com/api/?name=${nameToDisplay}`}
                                    alt={nameToDisplay}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  sx={{
                                    width: "calc(100% - 44px)",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  <Box component="span" sx={{ fontWeight: "bold" }}>
                                    {nameToDisplay}
                                  </Box>
                                  <Typography
                                    variant="body2"
                                    sx={{ color: "text.secondary" }}
                                  >
                                    {option.email}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </ListItem>
                          );
                        })}
                      </List>
                    )}
                  </div>
                  <Box>
                    <div
                      style={{ height: "calc(100vh - 220px)" }}
                      className=" md:py-2 px-2 overflow-y-scroll  no-scrollbar"
                    >
                      <p className="h-[1px] md:hidden bg-[#111111] w-full"></p>
                      {switchScreen.primary === "contacts" ? (
                        <ContactList
                          setSharedData={handelChatOpen}
                          contacts={contacts}
                        />
                      ) : chatList.length >= 1 ? (
                        <ChatList
                          setSharedData={handelChatOpen}
                          chatList={chatList}
                        />
                      ) : (
                        <div className="flex flex-col justify-center text-center items-center w-auto h-auto mx-auto">
                          <img
                            className="mt-24 w-[150px]"
                            src={noChatContact}
                            alt=""
                          />
                          <h4 className="text-[25px] font-[500] mt-4">
                            No chat information available!
                          </h4>
                          <p className="text-[#868686] text-[15px] mt-2 ">
                            Click on a chat to start a conversation
                          </p>
                        </div>
                      )}
                    </div>
                  </Box>
                </div>
                {/* <p className="h-[1px] hidden md:block absolute top-[68px] w-full bg-gray-500"></p> */}
              </Box>
              <Box
                sx={{
                  backgroundColor: mode === "dark" ? "#141414" : "",
                  borderRadius: "12px",
                  // borderBottomRightRadius: "10px"
                }}
                className={`w-[76vw] relative mb-4 border border-[#3F3F3F] mr-[-20px]`}
              >
                {currentChatUser.firstName ? (
                  <ChatSection
                    currentChatUser={currentChatUser}
                    closeModal={closeModal}
                    messages={messages}
                    sendMessage={sendMessage}
                  />
                ) : (
                  <div className="flex flex-col mt-[80px] justify-center text-center items-center w-auto h-auto mx-auto">
                    <img
                      className="mt-24 w-[200px]"
                      src={noChatContact}
                      alt=""
                    />
                    <h4 className="text-[40px] font-[500] mt-4">
                      No chat information available!
                    </h4>
                    <p className="text-[#868686] text-[20px] mt-2 ">
                      Click on a chat to start a conversation
                    </p>
                  </div>
                )}
              </Box>
            </div>

            {/* mobile chat screen */}
            <div className=" md:hidden flex">
              <Box
                sx={{ backgroundColor: { sm: "background.paper" } }}
                className={`md:block  w-full  relative  border-r-[#3F3F3F]`}
              >
                <div className={`overflow-hidden`}>
                  {/* <div className="flex justify-between items-center mb-2 "> */}
                  {/* <h1 className="text-2xl text-neutral-500">Chat</h1> */}
                  {/* <div className="flex flex-row gap-6">
                <div onClick={() => setSwitchScreen("chat")}>
                  <img
                    src={chatIcon}
                    alt="Interview Icon"
                    style={{ width: "22px", height: "22px" }}
                  />
                </div>
                <div onClick={() => setSwitchScreen("contacts")}>
                  <img
                    src={callIcon}
                    alt="Interview Icon"
                    style={{ width: "21px", height: "21px" }}
                  />
                </div>
              </div> */}
                  {/* </div> */}
                  <div
                    style={{ height: "calc(100vh - 198px)" }}
                    className="px-2 overflow-y-scroll  no-scrollbar"
                    ref={dropdownRef}
                  >
                    <div className="mb-3 flex items-center">
                      <Search>
                        <SearchIconWrapper>
                          <SearchIcon sx={{ width: "18px", height: "18px" }} />
                        </SearchIconWrapper>
                        <StyledInputBase
                          sx={{
                            "& ::placeholder": {
                              fontSize: "small",
                              color: "text.secondary",
                            },
                          }}
                          placeholder={
                            switchScreen.primary == "chat"
                              ? "Search chat"
                              : "Search contact"
                          }
                          inputProps={{ "aria-label": "search" }}

                          value={searchTerm}
                          onChange={handleInputChange}
                        />
                      </Search>

                      <IconButton
                        type="button"
                        sx={{
                          // p: '10px',
                          color: "white",
                          background: "none",
                          "&:hover": {
                            backgroundColor: "rgba(0, 0, 0, 0.05)",
                          },
                        }}
                        aria-label="search"
                      >
                        <TbEdit
                          size={"18px"}
                          className={`${mode === "light" ? "text-[#000]" : "text-[#fff]"
                            }`}
                        />
                      </IconButton>
                    </div>
                    {filteredContacts.length > 0 && (
                      <List
                        sx={{
                          position: "absolute",
                          // top: '100%',
                          width: "100%",
                          maxHeight: "200px",
                          overflowY: "auto",
                          backgroundColor: mode == "dark" ? "#171717" : "white",
                          zIndex: 2,
                          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                          left: 0,

                        }}
                      >
                        {filteredContacts.map((option) => {
                          const nameToDisplay = `${option.firstName} ${option.lastName}`;

                          return (
                            <ListItem
                              key={option._id}
                              button
                              onClick={() => handleClick(option)}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Grid container sx={{ alignItems: "center" }}>
                                <Grid item sx={{ display: "flex", width: 44 }}>
                                  <img
                                    loading="lazy"
                                    width="30"
                                    src={`https://ui-avatars.com/api/?name=${nameToDisplay}`}
                                    alt={nameToDisplay}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  sx={{
                                    width: "calc(100% - 44px)",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  <Box component="span" sx={{ fontWeight: "bold", fontSize: 14 }}>
                                    {nameToDisplay}
                                  </Box>
                                  <p
                                    // variant="body2"
                                    style={{ color: "text.secondary", fontSize: "12px" }}
                                  >
                                    {option.email}
                                  </p>
                                </Grid>
                              </Grid>
                            </ListItem>
                          );
                        })}
                      </List>
                    )}
                    <p className="h-[1px] md:hidden bg-[#111111] w-full"></p>
                    {switchScreen.primary === "contacts" ? (
                      <ContactList
                        setSharedData={handelMobileChatOpen}
                        contacts={contacts}
                      />
                    ) : (
                      <ChatList
                        setSharedData={handelMobileChatOpen}
                        chatList={chatList}
                      />
                    )}
                  </div>
                </div>
                {/* <p className="h-[1px] hidden md:block absolute top-[68px] w-full bg-gray-500"></p> */}
              </Box>
              <Modal
                sx={{
                  overflowY: "scroll",
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "flex-start",
                }}
                open={modalState}
                onClose={closeModal}
              >
                <Box
                  sx={{
                    width: "100%",
                    bgcolor: "background.main",
                    boxShadow: 24,
                  }}
                >
                  <ChatSection
                    currentChatUser={currentChatUser}
                    closeModal={closeModal}
                    messages={messages}
                    sendMessage={sendMessage}
                  />
                </Box>
              </Modal>
              {/* <Box
          sx={{ backgroundColor: { sm: "background.view" } }}
          className={` ${
            sharedData.firstName ? "block" : "hidden"
          } w-full relative `}
        >
         
        </Box> */}
            </div>
          </Box>
        </>
      ) : (
        <NoEmployee />
      )}
    </>
  );
};

export default ChatPage;
