import { Box, IconButton, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { PiLessThanBold } from "react-icons/pi";
import { PiGreaterThanBold } from "react-icons/pi";
import { IoCalendarOutline } from "react-icons/io5";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

export default function DateButton({ mode, onDateChange }) {
  const [currentDate, setCurrentDate] = useState(new Date());

  // Calculate the date range based on the mode
  const getDateRange = () => {
    const startDate = new Date(currentDate);
    const endDate = new Date(currentDate);

    if (mode === "year") {
      startDate.setMonth(0, 1); // First day of the year
      endDate.setMonth(11, 31); // Last day of the year
    } else if (mode === "month") {
      startDate.setDate(1); // First day of the month
      endDate.setMonth(endDate.getMonth() + 1);
      endDate.setDate(0); // Last day of the month
    } else if (mode === "week") {
      const dayOfWeek = startDate.getDay();
      startDate.setDate(startDate.getDate() - dayOfWeek); // Start of the week (Sunday)
      endDate.setDate(startDate.getDate() + 6); // End of the week (Saturday)
    } else if (mode === "day") {
      // For day mode, start and end dates are the same
      endDate.setDate(startDate.getDate());
    }

    return {
      start: startDate.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      }),
      end: endDate.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      }),
    };
  };

  // Notify the parent component of the initial date range on mount
  useEffect(() => {
    if (onDateChange) {
      const { start, end } = getDateRange();
      onDateChange({ start, end });
    }
  }, []); // Empty dependency array ensures this runs only once on mount

  // Notify the parent component whenever the date changes
  useEffect(() => {
    if (onDateChange) {
      const { start, end } = getDateRange();
      onDateChange({ start, end });
    }
  }, [currentDate, mode]);

  const handlePrevious = () => {
    const newDate = new Date(currentDate);
    if (mode === "year") {
      newDate.setFullYear(newDate.getFullYear() - 1);
    } else if (mode === "month") {
      newDate.setMonth(newDate.getMonth() - 1);
    } else if (mode === "week") {
      newDate.setDate(newDate.getDate() - 7);
    } else if (mode === "day") {
      newDate.setDate(newDate.getDate() - 1);
    }
    setCurrentDate(newDate);
  };

  const handleNext = () => {
    const newDate = new Date(currentDate);
    if (mode === "year") {
      newDate.setFullYear(newDate.getFullYear() + 1);
    } else if (mode === "month") {
      newDate.setMonth(newDate.getMonth() + 1);
    } else if (mode === "week") {
      newDate.setDate(newDate.getDate() + 7);
    } else if (mode === "day") {
      newDate.setDate(newDate.getDate() + 1);
    }
    setCurrentDate(newDate);
  };

  const { start, end } = getDateRange();
  const [openCalendar, setOpenCalendar] = useState(false);

  return (
    <div className="flex flex-row gap-3 relative justify-center items-center">
      <div className="flex flex-row justify-center items-center gap-2 px-2 ">
        <IoIosArrowBack
          className="cursor-pointer hover:text-gray-600"
          onClick={handlePrevious}
        />

        <Box
          onClick={() => setOpenCalendar(true)}
          className="relative flex items-center rounded-md cursor-pointer hover:bg-gray-200"
        >
          <IoCalendarOutline size="18px" className="text-gray-600" />
        </Box>

        <IoIosArrowForward
          className="cursor-pointer hover:text-gray-600"
          onClick={handleNext}
        />
      </div>
      <div>
        <p className="text-[12px] sm:text-[15px]">
          {start} - {end}
        </p>
      </div>

      {/* Calendar Popup */}
      {openCalendar && (
        <Box className="absolute top-8 z-10 shadow-md border rounded-md "
        sx={{ backgroundColor: mode === "dark" ? "#171717" : "white" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              value={currentDate}
              onChange={(date) => {
                setCurrentDate(date);
                setOpenCalendar(false);
              }}
            />
          </LocalizationProvider>
        </Box>
      )}
    </div>
  );
}
