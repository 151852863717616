import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "../../../../style/theme";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#E8E8F1",
        color: "#5A5A5A",
        fontWeight: "bold",
        fontSize: 14,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: "#F9FAFB",
    },
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

export default function ReporteesTable({ rows, searchTerm }) {
    const {mode} = useTheme();

    const filteredData = rows?.filter((item) =>
        item?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
    );

    return (
        <TableContainer className="rounded-lg" sx={{ border: "1px solid #e0e0e0" }}>
  <Table sx={{ minWidth: 700 }} aria-label="customized table">
    <TableHead
      sx={{
        backgroundColor: mode === "light" ? "#f5f5f5" : "#171717",
        "& .MuiTableCell-root": {
          fontWeight: "bold",
          color: "#6b7280",
          borderBottom: "1px solid #e0e0e0",
        },
      }}
    >
      <TableRow>
        <TableCell>Employee</TableCell>
        <TableCell>Status</TableCell>
        <TableCell>Leave booked this year</TableCell>
        <TableCell>Shift</TableCell>
      </TableRow>
    </TableHead>
    <TableBody
    sx={{
        backgroundColor: mode === "light" ? "#ffffff" : "",
      }}>
      {filteredData?.map((row) => (
        <TableRow
          key={row.name}
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
            borderBottom: "1px solid #e0e0e0",
          }}
        >
          <TableCell>
            <Box display="flex" alignItems="center">
              <Avatar
                src={row.img_url}
                alt={row.name}
                sx={{ width: 36, height: 36, marginRight: 1 }}
              />
              <Typography fontWeight="bold" color="#000">
                {row.id} -{" "}
                <Typography
                  component="span"
                  sx={{ color: "#007bff", cursor: "pointer" }}
                >
                  {row.name}
                </Typography>
              </Typography>
            </Box>
          </TableCell>
          <TableCell>{row.attendance || "-"}</TableCell>
          <TableCell>-</TableCell>
          <TableCell>
            <Typography >{row.time}</Typography>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
</TableContainer>
    );
}

