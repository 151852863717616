import React from "react";
import Card from "./Card";

export default function AllSeniority() {
  return (
    <div
      className="w-full md:grid md:grid-cols-3 justify-center items-center  place-content-center
     place-items-center place-self-center sm:flex gap-2"
    >
      <Card title={"0 - 5 Years"} className={"w-full md:w-85 lg:w-96"} />
      <Card title={"5 - 10 Years"} className={"w-full md:my-0 my-3 md:w-85 lg:w-96"} />
      <Card title={"Above 10 Years"} className={"w-full md:w-85 lg:w-96"} />
    </div>
  );
}
