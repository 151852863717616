import { useTheme } from "@mui/material";
import { FaBirthdayCake } from "react-icons/fa";
import { FaRegStar } from "react-icons/fa6";

export default function EmployeeProfileInformation() {
  const mode = useTheme();
  return (
    <div className="w-full flex gap-3 p-2 flex-col justify-center items-center ">
      <div
        className={`w-full flex flex-col sm:flex-row mt-2 justify-between py-3 shadow-lg px-3 sm:items-center rounded-md ${
          mode === "light" ? "bg-[#ffffff]" : ""
        }`}
      >
        <div>
          <p className="text-[14px]  text-[#666666] font-[400]">
            Current Experience{" "}
          </p>
          <span className="text-[14px]">21 Years</span>
        </div>

        <div>
          <p className="text-[14px]  text-[#666666] font-[400]">
            Total Experience{" "}
          </p>
          <span className="text-[14px]">26 Years and 8 Months </span>
        </div>

        <div>
          <p className="text-[14px]  text-[#666666] font-[400]">
            Date of Joining{" "}
          </p>
          <span className="text-[14px]">02-Feb-2004</span>
        </div>
      </div>

      <div className="w-full ">
        <p className="text-[18px] mt-2 font-[500]  ">2004</p>
      </div>
      <div className="w-full flex  justify-between items-center gap-4 mt-2">
        {/* Left Date Section */}
        <div className="text-right mt-[-40px]">
          <p className="text-sm">02</p>
          <p className="text-xs text-gray-400">February</p>
        </div>

        {/* Content Section */}
        <div className="w-full">
          <div className="flex flex-col w-full">
            {/* Joined Organization Box */}
            <div
              className={`flex w-full gap-6 ${
                mode === "light" ? "bg-[#ffeee0]" : ""
              } border border-[#fed8b9] p-5 h-[78px] rounded`}
            >
              <div
                className={`${
                  mode === "light" ? "bg-[#fff]" : ""
                } rounded-md p-2 flex justify-center items-center`}
              >
                <FaBirthdayCake className="text-orange-500 text-[20px] " />
              </div>
              <p className="text-[14px] mb-[-30px]">Joined the organization</p>
            </div>

            {/* Previous Experience */}
            <p className="font-[500] text-[18px] mt-4 ml-[50px] mb-[20px]">
              <span className="">Previous Experience</span>
              <span className="text-[#666666]"> - 5 Years 8 Months</span>
            </p>
          </div>
        </div>
      </div>

      <div className="w-full flex items-center sm:gap-[70px] ">
        <div className=""></div>
        <div
          className={`flex w-full mt-5 gap-4 p-[20px] shadow-lg items-center border border-[#dcdcdc] rounded-md ${
            mode === "light" ? "bg-[#ffffff]" : ""
          }`}
        >
          <div>
            <FaRegStar />
          </div>

          <div>
            <div className="flex gap-2">
              <p className="text-[#666666] text-[14px]">Company name:</p>
              <p className="font-[500] text-[14px]">Infomax</p>
            </div>

            <div className="flex gap-2">
              <p className="text-[#666666] text-[14px]">Job Title:</p>
              <p className="text-[#666666] text-[14px]">Assistant Manager</p>
            </div>

            <div className="flex gap-2">
              <p className="text-[#666666] text-[14px]">Experience:</p>
              <p className="text-[#666666] text-[14px]">5 Years 8 Months</p>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="w-full flex gap-3 flex-col justify-center items-center ">
      <div className=" w-full h-[20rem] flex flex-col gap-3 justify-center items-center">
        <h1>No career history data</h1>
        <h1>
          Ensure that the Date of Joining is updated for this employee to view
          their career history information.
        </h1>
      </div>
    </div> */}
    </div>
  );
}
