import React, { useEffect, useState } from "react";
import {
  Button,
  IconButton,
  Modal,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  TextField,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  FormControlLabel,
  Menu,
  FormGroup,
  Checkbox,
} from "@mui/material";
import { IoFilter } from "react-icons/io5";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { HiDotsHorizontal } from "react-icons/hi";
import { CiExport, CiImport } from "react-icons/ci";
import { LiaTrashAlt } from "react-icons/lia";
import CustomTable from "../../../components/CustomTable";
import Pagination from "../../../components/Pagination";
import dayjs from "dayjs";
import CustomModal from "../../../components/CustomModal";
import { useTheme } from "../../../style/theme";
import { IoMdContract, IoMdExpand } from "react-icons/io";

export default function Projects() {
  const [filterModal, setFilterModal] = useState(false);
  const [addProjectModal, setAddProjectModal] = useState(false);
  const [editProjectModal, setEditProjectModal] = useState(false);
  const [viewProjectModal, setViewProjectModal] = useState(false);

  const fields = [
    { label: "Project Name", field: "projectName", sortable: true },
    { label: "Client Name", field: "clientName", sortable: true },
    { label: "Project Cost", field: "projectCost", sortable: true },
    { label: "Status", field: "status", sortable: true },
    { label: "Project Owner", field: "projectOwner", sortable: true },
    { label: "Project Manager", field: "projectManager", sortable: true },
    { label: "Project Users", field: "projectUsers", sortable: true },
    { label: "Description", field: "description", sortable: true },
    { label: "Added By", field: "addedBy", sortable: true },
    { label: "Added Time", field: "addedTime", sortable: true },
    { label: "Modified By", field: "modifiedBy", sortable: true },
    { label: "Modified Time", field: "modifiedTime", sortable: true },
    { label: "Project Head", field: "projectHead", sortable: true },
  ];

  const [filterFormValues, setFilterFormValues] = useState({
    search: "",
    filterFields: [],
  });
  const handleFilterFormChange = (name, value) => {
    setFilterFormValues((prev) => ({ ...prev, [name]: value }));
  };
  const handleFilterValueChange = (field, condition, value) => {
    setFilterFormValues((prev) => ({
      ...prev,
      filterFields: prev.filterFields.map((item) =>
        item.field === field ? { ...item, condition, value } : item
      ),
    }));
  };
  const handleCheckboxChange = (field) => (event) => {
    const checkedFields = filterFormValues.filterFields;
    if (event.target.checked) {
      setFilterFormValues((prev) => ({
        ...prev,
        filterFields: [...checkedFields, { field, condition: "is", value: "" }],
      }));
    } else {
      setFilterFormValues((prev) => ({
        ...prev,
        filterFields: checkedFields.filter((item) => item.field !== field),
      }));
    }
  };
  const handleFilterFormSubmit = (e) => {
    e.preventDefault();
    console.log("Filter Data :- ", filterFormValues);
    setFilterModal(false);
  };

  const [menuAnchor, setMenuAnchor] = useState(null);
  const isMenuopen = Boolean(menuAnchor);

  const columns = [
    { header: "Project Name", accessorKey: "projectName", enableSorting: true },
    { header: "Client Name", accessorKey: "clientName", enableSorting: true },
    { header: "Project Cost", accessorKey: "projectCost", enableSorting: true },
    { header: "Status", accessorKey: "status", enableSorting: true },
    {
      header: "Project Owner",
      accessorKey: "projectOwner",
      enableSorting: true,
    },
    {
      header: "Project Manager",
      accessorKey: "projectManager",
      enableSorting: true,
    },
    {
      header: "Project Users",
      accessorKey: "projectUsers",
      enableSorting: true,
    },
    { header: "Description", accessorKey: "description", enableSorting: true },
    { header: "Added By", accessorKey: "addedBy", enableSorting: true },
    { header: "Added Time", accessorKey: "addedTime", enableSorting: true },
    { header: "Modified By", accessorKey: "modifiedBy", enableSorting: true },
    {
      header: "Modified Time",
      accessorKey: "modifiedTime",
      enableSorting: true,
    },
    { header: "Project Head", accessorKey: "projectHead", enableSorting: true },
    { header: "Actions", accessorKey: "actions" },
  ];

  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [rowSelection, setRowSelection] = useState({});
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [totalRows, setTotalRows] = useState(0);

  const handleRowClick = (row) => {
    setViewProjectModal(true);
  };
  const [sorting, setSorting] = useState([{ id: "id", desc: false }]);

  const renderRowSelection = (table) => {
    const selectedRows = Object.keys(rowSelection)?.map(
      (rowId) => table?.getRow(rowId)?.original
    );

    return (
      <div>
        {selectedRows?.length > 0 && (
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              setSelectedRecord(selectedRows); // Set multiple selected rows
              // openDelete();
            }}
          >
            Delete
          </Button>
        )}
      </div>
    );
  };

  const renderActions = (row) => (
    <IconButton>
      <LiaTrashAlt />
    </IconButton>
  );
  const handleSort = (column, direction) => {
    fetchData(column, direction);
  };
  const fetchData = async (sortBy = "", sortOrder = "") => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(
        `https://reqres.in/api/users?page=${currentPage}&per_page=${limit}&${sortBy}=${sortOrder}`
      );
      const result = await response.json();
      if (result && result.data && result.total) {
        setData(result.data);
        setTotalItems(result.total);
      }
    } catch (error) {
      setError("Error Fetching Data.");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [currentPage, limit]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
    setCurrentPage(1);
  };
  const totalPages = Math.ceil(totalItems / limit);

  const addProjectModalFields = [
    {
      type: "text",
      name: "projectName",
      label: "Project Name",
      defaultValue: "",
    },
    {
      type: "autocomplete",
      name: "clientName",
      label: "Client Name",
      options: ["Client 1", "Client 2"],
      defaultValue: "",
    },
    {
      type: "text",
      name: "projectCost",
      label: "Project Cost",
      defaultValue: "",
    },
    {
      type: "autocomplete",
      name: "projectHead",
      label: "Project Head",
      options: ["Project Head 1", "Project Head 2"],
      defaultValue: "",
    },
    {
      type: "text",
      name: "ratePerHour",
      label: "Rate Per Hour",
      defaultValue: "",
    },
    {
      type: "autocomplete",
      name: "projectManager",
      label: "Project Manager",
      options: ["Project Manager 1", "Project Manager 2"],
      defaultValue: "",
    },
    {
      type: "text",
      name: "description",
      label: "Description",
      defaultValue: "",
    },
  ];
  const handleAddProjectFormSubmit = (data) => {
    console.log("Add Form :- ", data);
  };

  const editProjectModalFields = [
    {
      type: "text",
      name: "projectName",
      label: "Project Name",
      defaultValue: "Iml",
    },
    {
      type: "autocomplete",
      name: "clientName",
      label: "Client Name",
      options: ["Client 1", "Client 2"],
      defaultValue: "Client 1",
    },
    {
      type: "text",
      name: "projectCost",
      label: "Project Cost",
      defaultValue: "22",
    },
    {
      type: "autocomplete",
      name: "projectHead",
      label: "Project Head",
      options: ["Project Head 1", "Project Head 2"],
      defaultValue: "Project Head 1",
    },
    {
      type: "text",
      name: "ratePerHour",
      label: "Rate Per Hour",
      defaultValue: "1.5",
    },
    {
      type: "autocomplete",
      name: "projectManager",
      label: "Project Manager",
      options: ["Project Manager 1", "Project Manager 2"],
      defaultValue: "Project Manager 1",
    },
    {
      type: "text",
      name: "description",
      label: "Description",
      defaultValue: "Description Data",
    },
  ];
  const handleEditProjectFormSubmit = (data) => {
    console.log("Edit Form :- ", data);
  };

  const viewProjectModalFields = [
    {
      type: "text",
      name: "projectName",
      label: "Project Name",
      defaultValue: "Iml",
      disabled: true,
    },
    {
      type: "autocomplete",
      name: "clientName",
      label: "Client Name",
      options: ["Client 1", "Client 2"],
      defaultValue: "Client 1",
      disabled: true,
    },
    {
      type: "text",
      name: "projectCost",
      label: "Project Cost",
      defaultValue: "22.00",
      disabled: true,
    },
    {
      type: "autocomplete",
      name: "projectHead",
      label: "Project Head",
      options: ["Project Head 1", "Project Head 2"],
      defaultValue: "Project Head 1",
      disabled: true,
    },
    {
      type: "autocomplete",
      name: "projectManager",
      label: "Project Manager",
      options: ["Project Manager 1", "Project Manager 2"],
      defaultValue: "Project Manager 1",
      disabled: true,
    },
    {
      type: "text",
      name: "ratePerHour",
      label: "Rate Per Hour",
      defaultValue: "1.5",
      disabled: true,
    },
    {
      type: "text",
      name: "projectUsers",
      label: "Project Users",
      defaultValue: "Users 1",
      disabled: true,
    },
    {
      type: "text",
      name: "description",
      label: "Description",
      defaultValue: "Description Data",
      disabled: true,
    },

    {
      type: "text",
      name: "jobName",
      label: "Job Name",
      defaultValue: "Iml",
      disabled: true,
    },
    {
      type: "autocomplete",
      name: "project",
      label: "Project",
      options: ["Project 1", "Project 2"],
      defaultValue: "Project 1",
      disabled: true,
    },

    {
      type: "datePicker",
      label: "Start Date",
      name: "startDate",
      defaultValue: dayjs("2025-01-01"),
      disabled: true,
      // disabled,
    },
    {
      type: "datePicker",
      label: "End Date",
      name: "endDate",
      defaultValue: dayjs("2025-05-01"),
      disabled: true,
      // disabled,
    },
    {
      type: "text",
      name: "hours",
      label: "Hours",
      defaultValue: "00:00",
      disabled: true,
    },
    {
      type: "text",
      name: "ratePerHour",
      label: "Rate Per Hour",
      defaultValue: "0.00",
      disabled: true,
    },
    {
      type: "text",
      name: "description",
      label: "Description",
      defaultValue: "Description Data",
      disabled: true,
    },
    {
      type: "text",
      name: "reminder",
      label: "Reminder",
      defaultValue: "No",
      disabled: true,
    },
    {
      type: "text",
      name: "reminderTime",
      label: "Reminder Time",
      defaultValue: "-",
      disabled: true,
    },
    {
      type: "select",
      name: "billableStatus",
      label: "Billable Status",
      options: [
        { label: "Billable", value: "billable" },
        { label: "Non-billable", value: "non-billable" },
        { label: "Select", value: "select" },
      ],
      defaultValue: "Billable",
      disabled: true,
    },
  ];

  const [isFullscreen, setIsFullscreen] = useState(false);

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setIsFullscreen(true);
    } else {
      document.exitFullscreen();
      setIsFullscreen(false);
    }
  };

  const [selectedValue, setSelectedValue] = useState("One"); // Default value set
  const [activeTab, setActiveTab] = useState("employee");
  const { mode } = useTheme();
  return (
    <div className="w-full min-h-80 flex flex-col mt-[40px]">
      <div className="flex flex-col sm:flex-row gap-3 justify-between items-center mx-4">
        <div className="flex flex-row gap-3 justify-center items-center">
          <div>
            <FormControl sx={{ width: { md: "250px", xs: "150px" } }}>
              <Select
                labelId="table-view"
                id="table-view"
                value={selectedValue} // Selected value ko control kar raha hai
                onChange={(e) => setSelectedValue(e.target.value)}
                sx={{
                  height: "40px",
                  backgroundColor: mode === "light" ? "white" : "#141414",
                }}
              >
                <MenuItem value="One">One</MenuItem>
                <MenuItem value="two">Two</MenuItem>
                <div className="w-full">
                  <Button
                    sx={{ width: "100%" }}
                    onClick={() => {
                      // setCreateTableViewModal(true);
                    }}
                    variant="outlined"
                  >
                    Create Table View
                  </Button>
                </div>
              </Select>
            </FormControl>
          </div>
        </div>

        <div className="flex flex-row gap-2 sm:gap-3 justify-center items-center">
          {/* <FormControl sx={{ width: {md:"200px",xs:"100px"} }}>
            <InputLabel id="data">Data</InputLabel>
            <Select labelId="data" id="data" label="Data">
              <MenuItem value={"allData"}>All Data</MenuItem>
              <MenuItem value={"reporteesPlusMyData"}>
                Reportees + My Data
              </MenuItem>
              <MenuItem value={"reporteesData"}>Reportees Data</MenuItem>
              <MenuItem value={"directReporteesData"}>
                Direct Reportees Data
              </MenuItem>
              <MenuItem value={"myData"}>My Data</MenuItem>
            </Select>
          </FormControl> */}

          <div className="flex items-center">
            {/* Employee Tab */}
            <button
              className={`px-4 py-1 text-[14px] border rounded-[2px] h-[32px] ${
                activeTab === "employee"
                  ?`${mode === "light" ? "bg-white text-black" :"transparent text-white"} border-blue-500 `
                  : `${mode === "light" ? "bg-gray-200 text-gray-500" :"transparent text-white"} `
              }`}
              onClick={() => setActiveTab("employee")}
            >
              Employee
            </button>

            {/* Department Tab */}
            <button
              className={`px-4 text-[14px] py-1 h-[32px] border rounded-[2px] ${
                activeTab === "department"
                  ? `border-blue-500 ${mode === "light" ? "text-black" :"text-white"} `
                  : `${mode === "light" ? "bg-gray-200  text-gray-500" :"transparent  text-white"} `
              }`}
              onClick={() => setActiveTab("department")}
            >
              Department
            </button>
          </div>

          <Button
            variant="contained"
            onClick={() => {
              setAddProjectModal(true);
            }}
            sx={{ fontSize: { md: "15px", xs: "12px" } ,height:"32px",backgroundColor:"#0088ff"}}
          >
            Add Project
          </Button>

           {/* Maximize/Minimize Button */}
      <IconButton onClick={toggleFullscreen} >
        {isFullscreen ? <IoMdContract size={24} /> : <IoMdExpand size={24} />}
      </IconButton>

          <IconButton
            onClick={() => {
              setFilterModal(true);
            }}
            title="Filter"
          >
            <IoFilter />
          </IconButton>
          <div>
            <IconButton
              id="basic-button"
              aria-controls={isMenuopen ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={isMenuopen ? "true" : undefined}
              onClick={(event) => {
                setMenuAnchor(event.currentTarget);
              }}
            >
              <HiDotsHorizontal className="text-2xl" />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={menuAnchor}
              open={isMenuopen}
              onClose={() => {
                setMenuAnchor(null);
              }}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem>
                <div className="flex flex-row gap-3 justify-between items-center">
                  <CiImport className="text-2xl" />
                  <h1>Import</h1>
                </div>
              </MenuItem>
              <MenuItem>
                <div className="flex flex-row gap-3 justify-between items-center">
                  <CiExport className="text-2xl" />
                  <h1>Export</h1>
                </div>
              </MenuItem>
            </Menu>
          </div>
        </div>
      </div>

      {activeTab === "employee" ? (
        <div>
          <div className="h-[35.1rem] mt-1 overflow-scroll">
            {/* <CustomTable
            columns={columns}
            onRowClick={handleRowClick}
            renderActions={renderActions}
            data={data}
            loading={loading}
            error={error}
            onSort={handleSort}
          /> */}

            <CustomTable
              columns={columns}
              onRowClick={handleRowClick}
              renderActions={renderActions}
              renderRowSelection={renderRowSelection}
              data={data}
              loading={loading}
              error={error}
              sorting={sorting}
              setSorting={setSorting}
              rowSelection={rowSelection}
              setRowSelection={setRowSelection}
              pagination={pagination}
              setPagination={setPagination}
              totalRows={totalRows}
              setTotalRows={setTotalRows}
              isBulkSelect={false}
            />
          </div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            limit={limit}
            onLimitChange={handleLimitChange}
          />
        </div>
      ) : (
        <div
          className={`flex flex-col mx-4 justify-center py-7 items-center rounded-md mt-[30px] border 
          border-[#d7e2ed] ${mode === "light" ? "bg-[#f7f9fc]" : ""}`}
        >
          <img
            style={{ width: "240px", marginBottom: "20px" }}
            src="/images/no_timeLogs.png"
            alt=""
          />

          <p className=" text-[16px] font-[500] text-center mt-2">
          No Projects added currently. To add new Projects, click Add Project
          </p>
        </div>
      )}

      <CustomModal
        title="Add Project"
        fields={addProjectModalFields}
        open={addProjectModal}
        onClose={() => {
          setAddProjectModal(false);
        }}
        onSubmit={handleAddProjectFormSubmit}
        isScrollable={true}
        isCustomSubmitButtom={true}
        customSubmitButton={
          <div className="w-full flex flex-row justify-between items-center">
            <Button variant="contained" type="submit">
              Submit
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                setAddProjectModal(false);
              }}
            >
              Cancel
            </Button>
          </div>
        }
      />
      <CustomModal
        title="Edit Project"
        fields={editProjectModalFields}
        open={editProjectModal}
        onClose={() => setEditProjectModal(false)}
        onSubmit={handleEditProjectFormSubmit}
        isScrollable={true}
      />
      <CustomModal
        title="View Project"
        fields={viewProjectModalFields}
        open={viewProjectModal}
        onClose={() => setViewProjectModal(false)}
        isView={true}
        isScrollable={true}
        isEditButton={true}
        onEditButtonClick={() => {
          setViewProjectModal(false);
          setEditProjectModal(true);
        }}
      />

      <CustomModal
        title="Filter"
        open={filterModal}
        onClose={() => setFilterModal(false)}
        // onSubmit={handleFilterFormSubmit}
        isScrollable={true}
      >
        <form onSubmit={handleFilterFormSubmit} className="flex flex-col gap-3">
          <div>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="systemFilters"
                id="systemFilters"
              >
                <Typography component="span">Search</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TextField
                  sx={{ width: "100%" }}
                  variant="outlined"
                  label="Search"
                  placeholder="Search"
                  value={filterFormValues.search}
                  onChange={(e) =>
                    handleFilterFormChange("search", e.target.value)
                  }
                />
              </AccordionDetails>
            </Accordion>
          </div>

          <div>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography component="span">Field</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="flex flex-col gap-3">
                  {fields.map((field, index) => (
                    <div key={index}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={handleCheckboxChange(field.field)}
                              checked={filterFormValues.filterFields.some(
                                (item) => item.field === field.field
                              )}
                            />
                          }
                          label={field.label}
                        />
                      </FormGroup>

                      {filterFormValues.filterFields
                        .filter((item) => item.field === field.field)
                        .map((filter, idx) => (
                          <div className="flex flex-col gap-3" key={idx}>
                            <FormControl sx={{ width: "100%" }}>
                              <InputLabel id={`filter-${index}`}>
                                Condition
                              </InputLabel>
                              <Select
                                labelId={`filter-${index}`}
                                id={`filter-${index}`}
                                label="Condition"
                                value={filter.condition}
                                onChange={(e) =>
                                  handleFilterValueChange(
                                    field.field,
                                    e.target.value,
                                    filter.value
                                  )
                                }
                              >
                                <MenuItem value={"is"}>Is</MenuItem>
                                <MenuItem value={"isNot"}>Is Not</MenuItem>
                                <MenuItem value={"startWith"}>
                                  Start With
                                </MenuItem>
                                <MenuItem value={"endWith"}>End With</MenuItem>
                                <MenuItem value={"contains"}>Contains</MenuItem>
                                <MenuItem value={"notContains"}>
                                  Not Contains
                                </MenuItem>
                                <MenuItem value={"Like"}>Like</MenuItem>
                                <MenuItem value={"isEmpty"}>Is Empty</MenuItem>
                                <MenuItem value={"isNotEmpty"}>
                                  Is Not Empty
                                </MenuItem>
                              </Select>
                            </FormControl>
                            <TextField
                              sx={{ width: "100%" }}
                              variant="outlined"
                              label="Value"
                              placeholder="Value"
                              value={filter.value}
                              onChange={(e) =>
                                handleFilterValueChange(
                                  field.field,
                                  filter.condition,
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        ))}
                    </div>
                  ))}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>

          <div className="w-full flex gap-2 flex-row justify-between items-center">
            <Button type="submit" variant="contained">
              Apply
            </Button>
            <Button
              onClick={() => {
                setFilterModal(false);
              }}
              variant="outlined"
            >
              Reset
            </Button>
          </div>
        </form>
      </CustomModal>
    </div>
  );
}
