import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
  Tabs,
  Tab,
  Avatar,
  Typography,
  Switch,
  TextField,
  MenuItem,
  Select,
  Button,
  DialogActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useTheme } from "../style/theme";
const timeZones = [
  { value: "UTC-12:00", label: "UTC-12:00 - Baker Island Time" },
  { value: "UTC-11:00", label: "UTC-11:00 - Samoa Time" },
  { value: "UTC-10:00", label: "UTC-10:00 - Hawaii-Aleutian Time" },
  { value: "UTC-09:00", label: "UTC-09:00 - Alaska Time" },
  { value: "UTC-08:00", label: "UTC-08:00 - Pacific Time (US & Canada)" },
  { value: "UTC-07:00", label: "UTC-07:00 - Mountain Time (US & Canada)" },
  { value: "UTC-06:00", label: "UTC-06:00 - Central Time (US & Canada)" },
  { value: "UTC-05:00", label: "UTC-05:00 - Eastern Time (US & Canada)" },
  { value: "UTC-04:00", label: "UTC-04:00 - Atlantic Time (Canada)" },
  { value: "UTC-03:30", label: "UTC-03:30 - Newfoundland Time" },
  { value: "UTC-03:00", label: "UTC-03:00 - Buenos Aires, Brazil" },
  { value: "UTC-02:00", label: "UTC-02:00 - Mid-Atlantic" },
  { value: "UTC-01:00", label: "UTC-01:00 - Azores" },
  { value: "UTC+00:00", label: "UTC+00:00 - Greenwich Mean Time" },
  { value: "UTC+01:00", label: "UTC+01:00 - Central European Time" },
  { value: "UTC+02:00", label: "UTC+02:00 - Eastern European Time" },
  { value: "UTC+03:00", label: "UTC+03:00 - Moscow Time" },
  { value: "UTC+03:30", label: "UTC+03:30 - Iran Time" },
  { value: "UTC+04:00", label: "UTC+04:00 - Gulf Standard Time" },
  { value: "UTC+04:30", label: "UTC+04:30 - Afghanistan Time" },
  { value: "UTC+05:00", label: "UTC+05:00 - Pakistan Standard Time" },
  { value: "UTC+05:30", label: "UTC+05:30 - India Standard Time" },
  { value: "UTC+06:00", label: "UTC+06:00 - Bangladesh Time" },
  { value: "UTC+07:00", label: "UTC+07:00 - Indochina Time" },
  { value: "UTC+08:00", label: "UTC+08:00 - China Standard Time" },
  { value: "UTC+09:00", label: "UTC+09:00 - Japan Standard Time" },
  { value: "UTC+09:30", label: "UTC+09:30 - Australian Central Time" },
  { value: "UTC+10:00", label: "UTC+10:00 - Australian Eastern Time" },
  { value: "UTC+11:00", label: "UTC+11:00 - Solomon Islands Time" },
  { value: "UTC+12:00", label: "UTC+12:00 - New Zealand Time" },
];
const PersonalizePreferencesModal = ({
  personalizeModalOpen,
  ClosePersonalizeModal,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { mode } = useTheme();
  return (
    <Dialog
      open={personalizeModalOpen}
      onClose={ClosePersonalizeModal}
      fullWidth
      maxWidth="lg"
      PaperProps={{
        sx: {
          borderRadius: "20px", // Border radius added here
        },
      }}
    >
      <div>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            bgcolor: mode === "dark" ? "#141414" : "",
            color:  mode === "dark" ? "white" : "",
            fontWeight: "600",
            fontSize:{ xs: "14px", md: "20px" },
            position: "relative",
          }}
        >
          Personalize Preferences
          <IconButton onClick={ClosePersonalizeModal} sx={{ color: mode === "dark" ? "white" : "" }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <Box
          className="flex gap-2 md:hidden w-full"
          sx={{
            backgroundColor: mode === "dark" ? "#141414" : "", // ✅ Use "backgroundColor"
            paddingLeft: 3,
            paddingRight: 3,
          }}
        >
          <img
            src="/images/profileImage.png"
            style={{ width: "35px", height: "36px" }}
          />
          <img
          className="w-[87%] sm:w-full md:flex"
            src="/images/personalizeImg.png"
            alt=""
            srcset=""
            style={{ height: "36px" }}
          />
        </Box>
        <DialogContent
          sx={{
            bgcolor: mode === "dark" ? "#141414" : "",
            color: mode === "dark" ? "white" : "",
            p: { xs: 1, md: 3 },
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            position: "relative",
          }}
        >
          {/* Left Side: Profile Image */}
          <Box
            sx={{
              width: "12%",
              textAlign: "center",
              display: { xs: "none", md: "block" },
            }}
            className='w-full'
          >
            <img
              src="/images/profileImage.png"
              style={{ width: 180, height: 130, margin: "auto" }}
            />
            <p className="text-[#3767B1] text-xs mt-1 ">Change Profile Photo</p>
            <Typography variant="body1" mt={1}>
              Jonathan Snow
            </Typography>
            <Typography variant="body2" color="#A5A5A5">
              HR Manager
            </Typography>
          </Box>

          {/* Right Side: Tabs and Content */}
          <Box sx={{ width: "100%", ml: 2 }}>
            <img
              className="hidden md:flex"
              src="/images/personalizeImg.png"
              alt=""
              srcset=""
            />

            <Tabs
              value={activeTab}
              onChange={(e, newValue) => setActiveTab(newValue)}
              textColor="inherit"
              indicatorColor="primary"
            >
              <Tab label="General" />
              <Tab label="Delegation" />
            </Tabs>

            {activeTab === 0 && (
              <Box mt={3}>
                <div className="text-[18px]">Time Zone Settings</div>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-5">
                  <div>
                    <p className="font-medium mb-1">Language</p>
                    <TextField
                      variant="outlined"
                      fullWidth
                      select
                      placeholder="Select Language"
                      sx={{
                        height: 40,
                        "& .MuiOutlinedInput-root": {
                          height: 40,
                          "& fieldset": {
                            borderColor: "#1F1F1F",
                          },
                          "&:hover fieldset": {
                            borderColor: "#1F1F1F !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#1F1F1F !important",
                          },
                        },
                      }}
                    >
                      <MenuItem value="John Doe">English</MenuItem>
                      <MenuItem value="Jane Smith">French</MenuItem>
                    </TextField>
                  </div>
                  <div>
                    <p className="font-medium mb-1">Country</p>
                    <TextField
                      variant="outlined"
                      fullWidth
                      select
                      placeholder="Select Country"
                      sx={{
                        height: 40,
                        "& .MuiOutlinedInput-root": {
                          height: 40,
                          "& fieldset": {
                            borderColor: "#1F1F1F", // Default border color
                          },
                          "&:hover fieldset": {
                            borderColor: "#1F1F1F !important", // Remove hover effect
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#1F1F1F !important", // Remove focus effect
                          },
                        },
                      }}
                    >
                      <MenuItem value="John Doe">England</MenuItem>
                      <MenuItem value="Jane Smith">Sydney</MenuItem>
                    </TextField>
                  </div>
                  <div>
                    <p className="font-medium mb-1" >Time Zone</p>
                    <TextField
                      variant="outlined"
                      fullWidth
                      select
                      placeholder="Select Time Zone"
                      sx={{
                        height: 40,
                        "& .MuiOutlinedInput-root": {
                          height: 40,
                          "& fieldset": {
                            borderColor: "#1F1F1F", // Default border color
                          },
                          "&:hover fieldset": {
                            borderColor: "#1F1F1F !important", // Remove hover effect
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#1F1F1F !important", // Remove focus effect
                          },
                        },
                      }}
                    >
                      {timeZones.map((tz) => (
                        <MenuItem key={tz.value} value={tz.value}>
                          {tz.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>

                <p className="font-[600] text-[16px] my-[20px]">
                  Feeds Notification
                </p>

                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  mt={1}
                  border={"1px solid #1F1F1F"}
                  p={1}
                  borderRadius={"8px"}
                >
                  <Typography variant="p" sx={{ fontSize: {xs:"10px",sm:"14px"} }}>
                    Notify me when someone mentions me in a post or comment
                  </Typography>
                  <Switch defaultChecked />
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  mt={1}
                  border={"1px solid #1F1F1F"}
                  p={1}
                  borderRadius={"8px"}
                >
                  <Typography variant="p" sx={{ fontSize: {xs:"10px",sm:"14px"} }}>
                    Notify me when someone posts a message in the department
                  </Typography>
                  <Switch defaultChecked />
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  mt={1}
                  border={"1px solid #1F1F1F"}
                  p={1}
                  borderRadius={"8px"}
                >
                  <Typography variant="p" sx={{ fontSize: {xs:"10px",sm:"14px"} }}>
                    Notify me when someone posts in the group
                  </Typography>
                  <Switch defaultChecked />
                </Box>

                <p className="font-[600] text-[16px] my-[20px]">Permissions</p>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  mt={1}
                  border={"1px solid #1F1F1F"}
                  p={1}
                  borderRadius={"8px"}
                >
                  <Typography variant="p" sx={{ fontSize: {xs:"10px",sm:"14px"} }}>
                    Hide my birthday from everyone
                  </Typography>
                  <Switch  />
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  mt={1}
                  border={"1px solid #1F1F1F"}
                  p={1}
                  borderRadius={"8px"}
                >
                  <Typography variant="p" sx={{ fontSize: {xs:"10px",sm:"14px"} }}>
                    Hide my work anniversary from everyone
                  </Typography>
                  <Switch  />
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  mt={1}
                  border={"1px solid #1F1F1F"}
                  p={1}
                  borderRadius={"8px"}
                >
                  <Typography variant="p" sx={{ fontSize: {xs:"10px",sm:"14px"} }}>
                    Hide my mobile number from everyone
                  </Typography>
                  <Switch  />
                </Box>
              </Box>
            )}

            {activeTab === 1 && (
              <Box mt={2}>
                <div className="text-[18px] my-[20px]">Delegations</div>

                <div className="border border-[#1F1F1F] flex flex-col justify-center items-center h-[400px] mb-[10px]">
                  <img src="/images/delegationImg.png" alt="" />
                  <p className="mt-[10px] text-[12px] sm:text-[14px] text-center">
                    {" "}
                    Delegation allows you to transfer approval responsibilities
                    <span className="block">
                      from one employee to another for a designated period.
                    </span>{" "}
                  </p>
                  <button
                    onClick={handleOpen}
                    className={`bg-[#3767B1] rounded-md flex justify-center items-center 
                    w-[130px] sm:w-[214px] h-[47px] text-white  text-[14px] mt-[20px]`}
                  >
                    Add Delegation
                  </button>
                </div>

                <div className="flex gap-3 absolute bottom-[-100px] left-[20px] pb-[20px]">
                  <button className={`bg-transparent rounded-md flex justify-center items-center
                   w-[95px] sm:w-[156px] border border-[#A5A5A5] h-[47px] ${mode === "dark" ? "text-white" : ""}
                   text-[14px] mt-[20px]`}
                   onClick={ClosePersonalizeModal}>
                    Cancel
                  </button>
                  <button className={`bg-[#3767B1] rounded-md flex justify-center items-center
                   w-[95px] sm:w-[156px] h-[47px] text-white text-[14px] mt-[20px]`}>
                    Submit
                  </button>
                </div>
              </Box>
            )}
          </Box>
        </DialogContent>
      </div>

      {open && (
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth
          maxWidth="md"
          PaperProps={{
            sx: {
              borderRadius: "25px", // Border radius added here
              overflow: "hidden", // Ensure it applies properly
            },
          }}
        >
          <div style={{ borderRadius: "25px" }}>
            <DialogTitle sx={{ bgcolor: mode === "dark" ? "#141414" : "" }}>
              Add Delegation
            </DialogTitle>
            <DialogContent sx={{ bgcolor: mode === "dark" ? "#141414" : "" }}>
              <div>
                <p className="font-medium mb-1">Delegator</p>
                <TextField
                  variant="outlined"
                  fullWidth
                  select
                  placeholder="Select Delegator"
                  sx={{
                    height: 40,
                    "& .MuiOutlinedInput-root": {
                      height: 40,
                      "& fieldset": {
                        borderColor: "#1F1F1F",
                      },
                      "&:hover fieldset": {
                        borderColor: "#1F1F1F !important",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#1F1F1F !important",
                      },
                    },
                  }}
                >
                  <MenuItem value="Select Delegator">Select Delegator</MenuItem>
                </TextField>
              </div>

              <div>
                <p className="font-medium mb-1">Delegatee</p>
                <TextField
                  variant="outlined"
                  fullWidth
                  select
                  placeholder="Select Delegatee"
                  sx={{
                    height: 40,
                    "& .MuiOutlinedInput-root": {
                      height: 40,
                      "& fieldset": {
                        borderColor: "#1F1F1F",
                      },
                      "&:hover fieldset": {
                        borderColor: "#1F1F1F !important",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#1F1F1F !important",
                      },
                    },
                  }}
                >
                  <MenuItem value="Select Delegatee">Select Delegatee</MenuItem>
                </TextField>
              </div>

              <p className="font-medium mb-1">Description of Delegation</p>

              <TextField
                fullWidth
                multiline
                rows={3}
                placeholder="Enter description"
                className="mt-3"
              />

              <div>
                <p className="font-medium mb-1">Notification</p>
                <TextField
                  variant="outlined"
                  fullWidth
                  select
                  placeholder="Select Notification"
                  sx={{
                    height: 40,
                    "& .MuiOutlinedInput-root": {
                      height: 40,
                      "& fieldset": {
                        borderColor: "#1F1F1F",
                      },
                      "&:hover fieldset": {
                        borderColor: "#1F1F1F !important",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#1F1F1F !important",
                      },
                    },
                  }}
                >
                  <MenuItem value="Select Delegatee">
                    Select Notification
                  </MenuItem>
                </TextField>
              </div>

              <p className="font-medium mb-1">Type</p>
              <div className="flex flex-col sm-flex-row justify-between gap-4 ">
                <TextField
                  variant="outlined"
                  fullWidth
                  select
                  placeholder="Select Type"
                  sx={{
                    height: 40,
                    "& .MuiOutlinedInput-root": {
                      height: 40,
                      "& fieldset": {
                        borderColor: "#1F1F1F",
                      },
                      "&:hover fieldset": {
                        borderColor: "#1F1F1F !important",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#1F1F1F !important",
                      },
                    },
                  }}
                >
                  <MenuItem value="Select Delegatee">Select Type</MenuItem>
                </TextField>

                <TextField
                  fullWidth
                  sx={{
                    height: 40,
                    "& .MuiOutlinedInput-root": {
                      height: 40,
                      "& fieldset": {
                        borderColor: "#1F1F1F",
                      },
                      "&:hover fieldset": {
                        borderColor: "#1F1F1F !important",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#1F1F1F !important",
                      },
                    },
                  }}
                  type="date"
                />
              </div>
            </DialogContent>

            <DialogActions sx={{ bgcolor: mode === "dark" ? "#141414" : "" }}>
              <div className="flex gap-3 pb-[20px] pr-[20px]">
                <button
                  onClick={handleClose}
                  className={`bg-transparent rounded-md flex justify-center items-center w-[95px] sm:w-[156px]
                   border border-[#A5A5A5] h-[47px] ${mode === "dark" ? "text-white" : ""} text-[14px] mt-[20px]`}
                >
                  Cancel
                </button>
                <button className={`bg-[#3767B1] rounded-md flex justify-center items-center w-[95px]
                 sm:w-[156px] h-[47px] ${mode === "dark" ? "text-white" : ""} text-[14px] mt-[20px]`}>
                  Save
                </button>
              </div>
            </DialogActions>
          </div>
        </Dialog>
      )}
    </Dialog>
  );
};

export default PersonalizePreferencesModal;
