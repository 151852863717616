import { Avatar, Divider, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomTable from "../../../components/CustomTable";
import axios from "axios";
import Grid from "@mui/material/Grid2";
import LockOpenIcon from "@mui/icons-material/LockOpen";

const profileInfo = [
  {
    title: "Department",
    more: "-",
  },
  {
    title: "Designation",
    more: "-",
  },
  {
    title: "Location",
    more: "-",
  },
  {
    title: "Email Address",
    more: "-",
  },
  {
    title: "Work Phone Number",
    more: "Admin",
  },
  {
    title: "Seating Location",
    more: "Admin",
  },
  {
    title: "Clikkle HR Role",
    more: "Admin",
  },
  {
    title: "Extension",
    more: "Active",
  },
];

export default function EmployeeProfileInformation() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });
  const [totalRows, setTotalRows] = useState(0);
  const [sorting, setSorting] = useState([{ id: "id", desc: false }]);
  const [rowSelection, setRowSelection] = useState({});

  const fetchData = async (pageIndex, pageSize, sorting) => {
    const sortField = sorting[0]?.id || "id";
    const sortDirection = sorting[0]?.desc ? "desc" : "asc";

    const response = await axios.get(`https://reqres.in/api/users`, {
      params: {
        page: pageIndex + 1,
        pageSize,
      },
    });
    setData(response.data.data);
    setTotalRows(response.data.total);
  };
  useEffect(() => {
    fetchData(pagination.pageIndex, pagination.pageSize, sorting);
  }, [pagination.pageIndex, pagination.pageSize, sorting]);

  // Work Experience Columns
  const workExperienceColumns = [
    { accessorKey: "companyName", header: "Company Name", enableSorting: true },
    { accessorKey: "jobTitle", header: "Job Title", enableSorting: true },
    { accessorKey: "fromDate", header: "From Date", enableSorting: true },
    { accessorKey: "toDate", header: "To Date", enableSorting: true },
    {
      accessorKey: "jobDescription",
      header: "Job Description",
      enableSorting: true,
    },
    { accessorKey: "relevant", header: "Relevant", enableSorting: true },
  ];

  // Education Details Columns
  const educationDetailsColumns = [
    {
      accessorKey: "instituteName",
      header: "Institute Name",
      enableSorting: true,
    },
    { accessorKey: "degree", header: "Degree/Diploma", enableSorting: true },
    {
      accessorKey: "specialization",
      header: "Specialization",
      enableSorting: true,
    },
    {
      accessorKey: "dateOfCompletion",
      header: "Date of Completion",
      enableSorting: true,
    },
  ];

  // Dependent Details Columns
  const dependentDetailsColumns = [
    { accessorKey: "name", header: "Name", enableSorting: true },
    {
      accessorKey: "relationship",
      header: "Relationship",
      enableSorting: true,
    },
    {
      accessorKey: "dateOfBirth",
      header: "Date of Birth",
      enableSorting: true,
    },
  ];

  // Card Component
  const Card = ({ image, title, more }) => {
    return (
      <>
        {/* <div className="flex flex-row px-6 py-2 gap-3 items-center rounded-lg border border-neutral-700 text-nowrap">
        <div>
          <Avatar />
        </div>
        <div>
          <div>
            <h1>{title}</h1>
          </div>
          <div>
            <h1>{more}</h1>
          </div>
        </div>
      </div> */}
        {/*  */}
        <Grid container spacing={2}>
          <Grid size={{ xs: 6, md: 4 }} sx={{ bgcolor: "red", m: 2 }}>
            <div className="flex gap-3 items-center">
              <img src="/images/departmentIcons/departicon.png" alt="" />
              <div>
                <h1 className="text-[16px]">{title}</h1>
                <h2 className="text-sm text-gray-500">{more}</h2>
              </div>
            </div>
          </Grid>
        </Grid>
        {/* <div className="md:grid md:grid-cols-4 gap-3 flex flex-col">
          <div className="flex gap-3 items-center">
            <img src="/images/departmentIcons/departicon.png" alt="" />
            <div>
              <h1 className="text-[16px]">{title}</h1>
              <h2 className="text-sm text-gray-500">{more}</h2>
            </div>
          </div>
        </div> */}
      </>
    );
  };

  return (
    <div className="w-full flex gap-3 flex-col justify-center items-center ">
      {/* Work Information Section */}

      <Grid
        container
        spacing={2}
        sx={{ border: "1px solid", borderRadius: "8px", p: 2, width: "100%" }}
      >
        <Grid size={{ xs: 6, md: 4 }}>
          <Grid container spacing={2}>
            <Grid size={{ xs: 12 }}>
              <div className="flex gap-3 items-center">
                <img
                  src="/images/departmentIcons/departicon.png"
                  className="h-10 w-10"
                  alt=""
                />
                <div>
                  <h1 className="text-[16px] text-gray-500">{"Department"}</h1>
                  <h2 className="mt-1 ">{"Management"}</h2>
                </div>
              </div>
            </Grid>
            <Grid size={{ xs: 12 }}>
              <div className="flex gap-3 items-center">
                <img
                  src="/images/departmentIcons/departicon.png"
                  className="h-10 w-10"
                  alt=""
                />
                <div>
                  <h1 className="text-[16px] text-gray-500">{"Designation"}</h1>
                  <h2 className="mt-1 ">{"CEO"}</h2>
                </div>
              </div>
            </Grid>
            <Grid size={{ xs: 12 }}>
              <div className="flex gap-3 items-center">
                <img
                  src="/images/departmentIcons/departicon.png"
                  className="h-10 w-10"
                  alt=""
                />
                <div>
                  <h1 className="text-[16px] text-gray-500">{"Location"}</h1>
                  <h2 className="mt-1 ">{"-"}</h2>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid size={{ xs: 6, md: 4 }}>
          <Grid container spacing={2}>
            <Grid size={{ xs: 12 }}>
              <div className="flex gap-3 items-center">
                <img
                  src="/images/departmentIcons/departicon.png"
                  className="h-10 w-10"
                  alt=""
                />
                <div>
                  <h1 className="text-[16px] text-gray-500">
                    {"Email Address"}
                  </h1>
                  <h2 className="mt-1 ">{"saad@gmail.com"}</h2>
                </div>
              </div>
            </Grid>
            <Grid size={{ xs: 12 }}>
              <div className="flex gap-3 items-center">
                <img
                  src="/images/departmentIcons/departicon.png"
                  className="h-10 w-10"
                  alt=""
                />
                <div>
                  <h1 className="text-[16px] text-gray-500">
                    {"Work Phone Number"}
                  </h1>
                  <h2 className="mt-1 ">{"305-555-1212"}</h2>
                </div>
              </div>
            </Grid>
            <Grid size={{ xs: 12 }}>
              <div className="flex gap-3 items-center">
                <img
                  src="/images/departmentIcons/departicon.png"
                  className="h-10 w-10"
                  alt=""
                />
                <div>
                  <h1 className="text-[16px] text-gray-500">
                    {"Seating Location"}
                  </h1>
                  <h2 className="mt-1 ">{"FL_EXEC_1"}</h2>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid size={{ xs: 6, md: 4 }}>
          <Grid container spacing={2}>
            <Grid size={{ xs: 12 }}>
              <div className="flex gap-3 items-center">
                <img
                  src="/images/departmentIcons/departicon.png"
                  className="h-10 w-10"
                  alt=""
                />
                <div>
                  <h1 className="text-[16px] text-gray-500">
                    {"Clikkle Hr Role"}
                  </h1>
                  <h2 className="mt-1 ">{"Admin"}</h2>
                </div>
              </div>
            </Grid>
            <Grid size={{ xs: 12 }}>
              <div className="flex gap-3 items-center">
                <img
                  src="/images/departmentIcons/departicon.png"
                  className="h-10 w-10"
                  alt=""
                />
                <div>
                  <h1 className="text-[16px] text-gray-500">{"Extension"}</h1>
                  <h2 className="mt-1 ">{"1"}</h2>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Basic Information Section */}
      <div className="w-full flex flex-col gap-3 p-4 mt-2 rounded-lg border border-neutral-700">
        <div className="flex mb-5 ">
          <p className="text-lg">Basic Information</p>
        </div>

        <Grid container spacing={3} xs={{ p: 2 }}>
          <Grid size={{ xs: 6, md: 2 }}>
            <p className="text-gray-500">Employee ID</p>
          </Grid>
          <Grid size={{ xs: 6, md: 4 }}>
            <p>1</p>
          </Grid>
          <Grid size={{ xs: 6, md: 2 }}>
            <p className="text-gray-500">Nickname</p>
          </Grid>
          <Grid size={{ xs: 6, md: 4 }}>
            <p>-</p>
          </Grid>
          <Grid size={{ xs: 6, md: 2 }}>
            <p className="text-gray-500">First Name</p>
          </Grid>
          <Grid size={{ xs: 6, md: 4 }}>
            <p>Tanya Gwilsbry</p>
          </Grid>
          <Grid size={{ xs: 6, md: 2 }}>
            <p className="text-gray-500">Email address</p>
          </Grid>
          <Grid size={{ xs: 6, md: 4 }}>
            <p>TanyaGwilsbry@gmail.com</p>
          </Grid>
          <Grid size={{ xs: 6, md: 2 }}>
            <p className="text-gray-500">Last Name</p>
          </Grid>
          <Grid size={{ xs: 6, md: 4 }}>
            <p>-</p>
          </Grid>
        </Grid>
      </div>

      {/* Work Information Section */}
      <div className="w-full flex flex-col gap-3 p-4 mt-2 rounded-lg border border-neutral-700">
        <div className="flex mb-5 ">
          <p className="text-lg">Work Information</p>
        </div>
        <Grid container spacing={3}>
          <Grid size={{ xs: 12, md: 6 }}>
            <Grid container spacing={3} xs={{ p: 2 }}>
              <Grid
                size={{ xs: 4 }}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <p className="text-gray-500 mr-1">Department</p>
                <span>
                  <LockOpenIcon fontSize="14px" />
                </span>
              </Grid>
              <Grid size={{ xs: 8 }}>
                <p>Management</p>
              </Grid>
              <Grid size={{ xs: 4 }}>
                <p className="text-gray-500">Location</p>
              </Grid>
              <Grid size={{ xs: 8 }}>
                <p>-</p>
              </Grid>
              <Grid size={{ xs: 4 }}>
                <p className="text-gray-500">Designation</p>
              </Grid>
              <Grid size={{ xs: 8 }}>
                <p>CEO</p>
              </Grid>
            </Grid>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Grid container spacing={3} xs={{ p: 2 }}>
              <Grid size={{ xs: 4 }}>
                <p className="text-gray-500">Clikkle Hr Role</p>
              </Grid>
              <Grid size={{ xs: 8 }}>
                <p>Admin</p>
              </Grid>
              <Grid size={{ xs: 4 }}>
                <p className="text-gray-500">Employment Type</p>
              </Grid>
              <Grid size={{ xs: 8 }}>
                <p>Permanent</p>
              </Grid>
              <Grid size={{ xs: 4 }}>
                <p className="text-gray-500">Employee Status</p>
              </Grid>
              <Grid size={{ xs: 8 }}>
                <p>Active</p>
              </Grid>
              <Grid size={{ xs: 4 }}>
                <p className="text-gray-500">Source of Hire</p>
              </Grid>
              <Grid size={{ xs: 8 }}>
                <p>-</p>
              </Grid>
              <Grid size={{ xs: 4 }}>
                <p className="text-gray-500">Date of Joining</p>
              </Grid>
              <Grid size={{ xs: 8 }}>
                <p>02-Feb-2024</p>
              </Grid>
              <Grid size={{ xs: 4 }}>
                <p className="text-gray-500">Current Experience</p>
              </Grid>
              <Grid size={{ xs: 8 }}>
                <p>21 years 1 month</p>
              </Grid>
              <Grid size={{ xs: 4 }}>
                <p className="text-gray-500">Total Experience</p>
              </Grid>
              <Grid size={{ xs: 8 }}>
                <p>20 years 9 months</p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      {/* Hierarchy Information Section */}
      <div className="w-full flex flex-col gap-3 p-3 mt-2 rounded-lg border border-neutral-700">
        <h1>Hierarchy Information</h1>
        <Grid container spacing={3} xs={{ p: 2 }}>
          <Grid size={{ xs: 6, md: 2 }}>
            <p className="text-gray-500">Reporting Manager</p>
          </Grid>
          <Grid size={{ xs: 6, md: 4 }}>
            <p>-</p>
          </Grid>
        </Grid>
      </div>

      {/* Personal Details Section */}
      <div className="w-full flex flex-col gap-3 p-3 mt-2 rounded-lg border border-neutral-700">
        <h1>Personal Details</h1>

        <Grid container spacing={3}>
          <Grid size={{ xs: 12, md: 6 }}>
            <Grid container spacing={3} xs={{ p: 2 }}>
              <Grid size={{ xs: 4 }}>
                <p className="text-gray-500">Date of Birth</p>
              </Grid>
              <Grid size={{ xs: 8 }}>
                <p>-</p>
              </Grid>
              <Grid size={{ xs: 4 }}>
                <p className="text-gray-500">Age</p>
              </Grid>
              <Grid size={{ xs: 8 }}>
                <p>-</p>
              </Grid>
              <Grid size={{ xs: 4 }}>
                <p className="text-gray-500">Gender</p>
              </Grid>
              <Grid size={{ xs: 8 }}>
                <p>Male</p>
              </Grid>
              <Grid size={{ xs: 4 }}>
                <p className="text-gray-500">Martial Status</p>
              </Grid>
              <Grid size={{ xs: 8 }}>
                <p>-</p>
              </Grid>
              <Grid size={{ xs: 4 }}>
                <p className="text-gray-500">About Me</p>
              </Grid>
              <Grid size={{ xs: 8 }}>
                <p>-</p>
              </Grid>
            </Grid>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Grid container spacing={3} xs={{ p: 2 }}>
              <Grid size={{ xs: 4 }}>
                <p className="text-gray-500">Ask me about/Expertise</p>
              </Grid>
              <Grid size={{ xs: 8 }}>
                <p>-</p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      {/* Contact Details Section */}
      <div className="w-full flex flex-col gap-3 p-3 mt-2 rounded-lg border border-neutral-700">
        <h1>Contact Details</h1>
        <Grid container spacing={3}>
          <Grid size={{ xs: 12, md: 6 }}>
            <Grid container spacing={3} xs={{ p: 2 }}>
              <Grid size={{ xs: 4 }}>
                <p className="text-gray-500">Work Phone Number</p>
              </Grid>
              <Grid size={{ xs: 8 }}>
                <p>-</p>
              </Grid>
              <Grid size={{ xs: 4 }}>
                <p className="text-gray-500">Extension</p>
              </Grid>
              <Grid size={{ xs: 8 }}>
                <p>FL_EXEC_1</p>
              </Grid>
              <Grid size={{ xs: 4 }}>
                <p className="text-gray-500">Tags</p>
              </Grid>
              <Grid size={{ xs: 8 }}>
                <p>-</p>
              </Grid>
              <Grid size={{ xs: 4 }}>
                <p className="text-gray-500">Present Address</p>
              </Grid>
              <Grid size={{ xs: 8 }}>
                <p>
                  6422 Collins Ave, #APT 302, Miami Beach, Florida, UNITED
                  STATES, 33141.
                </p>
              </Grid>
              <Grid size={{ xs: 4 }}>
                <p className="text-gray-500">Permanent Address</p>
              </Grid>
              <Grid size={{ xs: 8 }}>
                <p>-</p>
              </Grid>
            </Grid>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Grid container spacing={3} xs={{ p: 2 }}>
              <Grid size={{ xs: 4 }}>
                <p className="text-gray-500">Personal Mobile Number</p>
              </Grid>
              <Grid size={{ xs: 8 }}>
                <p>-</p>
              </Grid>
              <Grid size={{ xs: 4 }}>
                <p className="text-gray-500">Personal Email Address</p>
              </Grid>
              <Grid size={{ xs: 8 }}>
                <p>-</p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      {/* Separation Information Section */}
      <div className="w-full flex flex-col gap-3 p-3 mt-2 rounded-lg border border-neutral-700">
        <h1>Separation Information</h1>
        <Grid container spacing={3}>
          <Grid size={{ xs: 12, md: 6 }}>
            <Grid container spacing={3} xs={{ p: 2 }}>
              <Grid size={{ xs: 4 }}>
                <p className="text-gray-500">Date of Exit</p>
              </Grid>
              <Grid size={{ xs: 8 }}>
                <p>-</p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      {/* System Fields Section */}
      <div className="w-full flex flex-col gap-3 p-3 mt-2 rounded-lg border border-neutral-700">
        <h1>System Fields</h1>
        <Grid container spacing={3}>
          <Grid size={{ xs: 12, md: 6 }}>
            <Grid container spacing={3} xs={{ p: 2 }}>
              <Grid size={{ xs: 4 }}>
                <p className="text-gray-500">Added By</p>
              </Grid>
              <Grid size={{ xs: 8 }}>
                <p>1 - tanya gwilsbry -</p>
              </Grid>
              <Grid size={{ xs: 4 }}>
                <p className="text-gray-500">Added Time</p>
              </Grid>
              <Grid size={{ xs: 8 }}>
                <p>09-Feb-2025 10:42 PM</p>
              </Grid>
            </Grid>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Grid container spacing={3} xs={{ p: 2 }}>
              <Grid size={{ xs: 4 }}>
                <p className="text-gray-500">Modified By</p>
              </Grid>
              <Grid size={{ xs: 8 }}>
                <p>I - tanya gwilsbry </p>
              </Grid>
              <Grid size={{ xs: 4 }}>
                <p className="text-gray-500">Modified Time</p>
              </Grid>
              <Grid size={{ xs: 8 }}>
                <p> 09-Feb-2025 10:42 PM </p>
              </Grid>
              <Grid size={{ xs: 4 }}>
                <p className="text-gray-500">Onboarding Status</p>
              </Grid>
              <Grid size={{ xs: 8 }}>
                <p> -</p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      {/* Work Experience Section */}
      <div className="w-full flex flex-col gap-3 p-3 rounded-lg border border-neutral-700">
        <h1>Work Experience</h1>
        <div className="w-full">
          <CustomTable
            columns={workExperienceColumns}
            data={data}
            loading={loading}
            error={error}
            sorting={sorting}
            setSorting={setSorting}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            pagination={pagination}
            setPagination={setPagination}
            totalRows={totalRows}
            setTotalRows={setTotalRows}
            isPagination={false}
            enableColumnActions={false}
            enableFullScreenToggle={false}
            enableHiding={false}
          />
        </div>
      </div>

      {/* Education Details Section */}
      <div className="w-full flex flex-col gap-3 p-3 rounded-lg border border-neutral-700">
        <h1>Education Details</h1>
        <div className="w-full">
          <CustomTable
            columns={educationDetailsColumns}
            data={data}
            loading={loading}
            error={error}
            sorting={sorting}
            setSorting={setSorting}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            pagination={pagination}
            setPagination={setPagination}
            totalRows={totalRows}
            setTotalRows={setTotalRows}
            isPagination={false}
            enableColumnActions={false}
            enableFullScreenToggle={false}
            enableHiding={false}
          />
        </div>
      </div>

      {/* Dependent Details Section */}
      <div className="w-full flex flex-col gap-3 p-3 rounded-lg border border-neutral-700">
        <h1>Dependent Details</h1>
        <div className="w-full">
          <CustomTable
            columns={dependentDetailsColumns}
            data={data}
            loading={loading}
            error={error}
            sorting={sorting}
            setSorting={setSorting}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            pagination={pagination}
            setPagination={setPagination}
            totalRows={totalRows}
            setTotalRows={setTotalRows}
            isPagination={false}
            enableColumnActions={false}
            enableFullScreenToggle={false}
            enableHiding={false}
          />
        </div>
      </div>
    </div>
  );
}
