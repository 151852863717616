import React from "react";
import { Button, Grid } from "@mui/material";
import { IoMdTime } from "react-icons/io";

export default function LogTime() {
  return (
    <Grid
      className="w-full flex gap-2 px-2 py-4 justify-between items-center rounded-lg shadow-md border border-[#1e2734]"
      sx={{ backgroundColor: "background.default" }}
    >
      <div className="bg-blue-400 bg-opacity-10 rounded-lg p-2 me-1">
        <IoMdTime className="h-6 w-6 text-blue-400" />
      </div>
      <div className="w-full flex justify-between items-center">
        <div className="pe-3 sm:pe-0">
          <h1 className="text-[14px] w-full sm:text-[1rem]">You have not submitted your time log today!</h1>
          <h1 className="text-[10px] sm:text-xs text-zinc-400">Click the button now to log in your time.</h1>
        </div>
        <div>
          <Button variant="contained" sx={{ px: { sm: "1.6rem", xs: "5px" }, py: { sm: "0.2rem", xs: "0.4rem" }, width: { sm: "110px", xs: "60px" }, fontSize: { xs: "11px", sm: "13px" } }}>
            Log Time
          </Button>
        </div>
      </div>
    </Grid>
  );
}
