import React, { useState } from "react";
import {
  Container,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Typography,
  Paper,
  Box,
  Tooltip,
  Modal,
  Alert,
  Snackbar,
  Card,
  TableContainer,
} from "@mui/material";
import { ChevronLeft, ChevronRight, EditIcon } from "lucide-react";
import {
  LocalizationProvider,
  DatePicker,
  DateCalendar,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import dayjs from "dayjs";
import { useTheme } from "../../style/theme";
import { CiExport, CiImport } from "react-icons/ci";
import { IoIosArrowBack, IoIosArrowForward, IoIosMore } from "react-icons/io";
import { TfiDownload } from "react-icons/tfi";
import { BsPrinter } from "react-icons/bs";
import { IoCalendarOutline } from "react-icons/io5";

const EditShiftModal = ({ open, handleClose, mode }) => {
  const [shiftName, setShiftName] = useState("");
  const [fromDate, setFromDate] = useState("2025-02-02");
  const [toDate, setToDate] = useState("2025-02-02");
  const [reason, setReason] = useState("");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSave = () => {
    if (!shiftName) {
      setError(true);
      return;
    }
    setSuccess(true);

    // Close modal after success message
    setTimeout(() => {
      setSuccess(false);
      handleClose();
    }, 1000);
  };

  return (
    <>
      <Snackbar
        open={success}
        autoHideDuration={3000}
        onClose={() => setSuccess(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="success" variant="filled">
          {mode === "edit"
            ? "Shift edited successfully!"
            : "Shift assigned successfully!"}
        </Alert>
      </Snackbar>

      <Modal open={open} onClose={handleClose} aria-labelledby="modal-title">
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "80%", md: 600 },
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 3,
            borderRadius: 2,
          }}
        >
          <Typography id="modal-title" variant="h6" fontWeight="bold">
            {mode === "edit" ? "Change Shift" : "Assign Shift"}
          </Typography>

          <FormControl fullWidth sx={{ mt: 2 }} error={error}>
            <InputLabel>Shift Name *</InputLabel>
            <Select
              value={shiftName}
              onChange={(e) => {
                setShiftName(e.target.value);
                setError(false);
              }}
            >
              <MenuItem value="General">General 09:00 AM - 06:00 PM</MenuItem>
              <MenuItem value="Morning">Morning 06:00 AM - 02:00 PM</MenuItem>
              <MenuItem value="Evening">Evening 02:00 PM - 10:00 PM</MenuItem>
            </Select>
            {error && (
              <Typography color="error">Shift time is required</Typography>
            )}
          </FormControl>

          <TextField
            label="From *"
            type="date"
            fullWidth
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
            sx={{ mt: 2 }}
            InputLabelProps={{ shrink: true }}
          />

          <TextField
            label="To"
            type="date"
            fullWidth
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
            sx={{ mt: 2 }}
            InputLabelProps={{ shrink: true }}
          />

          <TextField
            label="Reason"
            fullWidth
            multiline
            rows={3}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            sx={{ mt: 2 }}
          />

          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
            <Button variant="contained" color="primary" onClick={handleSave}>
              {mode === "edit" ? "Save Changes" : "Assign Shift"}
            </Button>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

const MonthTab = () => {
  const [currentDate, setCurrentDate] = useState(dayjs());

  const { mode: themeMode } = useTheme();
  const startOfMonth = currentDate.startOf("month");
  const endOfMonth = currentDate.endOf("month");
  const daysInMonth = endOfMonth.date();

  const prevMonth = () => setCurrentDate(currentDate.subtract(1, "month"));
  const nextMonth = () => setCurrentDate(currentDate.add(1, "month"));

  const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  // ✅ Pehle din ka weekday nikalna
  const firstDayIndex = startOfMonth.day();

  // ✅ Shifts ko dynamically generate karna
  const shifts = Array.from({ length: daysInMonth }, (_, index) => ({
    type: "General",
    time: "9:00 AM - 6:00 PM",
  }));

  return (
    <div style={{ backgroundColor: "background.paper" }} className="rounded-lg">
      <div
        className={`${themeMode === "light" ? "bg-[#fff]" : ""
          } pt-5 px-2 sm:px-4`}
      >
        {/* Header Navigation */}
        <div className="flex justify-between items-center my-4">
          <button onClick={prevMonth} className="p-2  rounded-md ">
            <ChevronLeft />
          </button>
          <span className="font-semibold text-lg sm:text-xl">
            {currentDate.format("MMMM YYYY")}
          </span>
          <button onClick={nextMonth} className="p-2 rounded-md ">
            <ChevronRight />
          </button>
        </div>

        {/* Weekday Headers */}
        <div className="overflow-x-auto">
          <div className="grid grid-cols-7 text-center font-semibold p-2 rounded-md min-w-[700px] sm:min-w-full">
            {weekdays.map((day) => (
              <div key={day} className="text-sm sm:text-base text-gray-600">
                {day}
              </div>
            ))}
          </div>

          <div className="grid grid-cols-7 border-t min-w-[700px] sm:min-w-full">
            {/* Empty slots for days before the first day */}
            {Array.from({ length: firstDayIndex }).map((_, index) => (
              <div
                key={`empty-${index}`}
                className="h-24 sm:h-20 md:h-24 p-2 border"
              ></div>
            ))}

            {/* Days of the month */}
            {[...Array(daysInMonth)].map((_, dayIndex) => {
              const day = dayIndex + 1;
              const shift = shifts[dayIndex];

              return (
                <div
                  key={day}
                  className="relative h-24 sm:h-20 md:h-24 p-2 border flex flex-col"
                >
                  <div className="absolute top-1 left-1 text-gray-500 text-xs sm:text-sm">
                    {day}
                  </div>

                  {shift && (
                    <div
                      style={{
                        borderLeft: "3px solid #999898",
                        backgroundColor:
                          themeMode === "dark" ? "#171717" : "#f6f6f6",
                      }}
                      className="rounded-lg relative flex flex-col mt-[30px] w-full px-2 group inline-block"
                    >
                      <span className="text-[8px] sm:text-[10px] md:text-sm py-1">
                        {shift.type}
                      </span>
                      <span className="text-[8px] sm:text-[10px] md:text-xs text-gray-500">
                        {shift.time}
                      </span>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const WeekTab = () => {
  const [mode, setMode] = useState("edit");
  const [open, setOpen] = useState(false);
  const { mode: themeMode } = useTheme();
  const handleOpen = (selectedMode) => {
    setMode(selectedMode);
    setOpen(true);
  };

  // Sample Shift Data
  const [shifts, setShifts] = useState([
    { day: "Sun", date: "2", shift: "General", time: "9:00 AM - 6:00 PM" },
    { day: "Mon", date: "3", shift: "General", time: "9:00 AM - 6:00 PM" },
    { day: "Tue", date: "4", shift: "General", time: "9:00 AM - 6:00 PM" },
    { day: "Wed", date: "5", shift: "General", time: "9:00 AM - 6:00 PM" },
    { day: "Thu", date: "6", shift: "General", time: "9:00 AM - 6:00 PM" },
    { day: "Fri", date: "7", shift: "General", time: "9:00 AM - 6:00 PM" },
    { day: "Sat", date: "8", shift: "General", time: "9:00 AM - 6:00 PM" },
  ]);

  return (
    <div>
      <TableContainer sx={{ width: "100%", overflowX: "auto" }}>
        <Table sx={{ border: "1px solid #ddd", minWidth: 600 }}>
          <TableHead sx={{ backgroundColor: "background.paper" }}>
            <TableRow>
              <TableCell
                sx={{
                  textAlign: "center",
                  fontWeight: "bold",
                  border: "1px solid #e0e0e0",
                }}
              ></TableCell>
              <TableCell
                sx={{
                  textAlign: "center",
                  fontWeight: "bold",
                  border: "1px solid #e0e0e0",
                }}
              >
                08 AM
              </TableCell>
              {[
                "09 AM",
                "10 AM",
                "11 AM",
                "12 PM",
                "01 PM",
                "02 PM",
                "03 PM",
                "04 PM",
                "05 PM",
                "06 PM",
              ].map((time) => (
                <TableCell
                  key={time}
                  sx={{
                    textAlign: "center",
                    fontWeight: "bold",
                    border: "1px solid #e0e0e0",
                    whiteSpace: "nowrap", // Prevent text wrap
                    fontSize: { xs: "10px", sm: "12px", md: "14px" },
                  }}
                >
                  {time}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {shifts.map((shift, index) => (
              <TableRow
                key={index}
                sx={{
                  backgroundColor:
                    index % 2 === 0 ? "background.paper" : "background.paper",
                }}
              >
                <TableCell
                  sx={{
                    textAlign: "center",
                    border: "1px solid #e0e0e0",
                    backgroundColor:
                      themeMode === "light" && shift.day === "Sun"
                        ? "#fff7e5"
                        : "",
                    fontSize: { xs: "10px", sm: "12px", md: "14px" },
                  }}
                >
                  {shift.day} <br />
                  {shift.date}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    border: "1px solid #e0e0e0",
                    backgroundColor:
                      themeMode === "light" && shift.day === "Sun"
                        ? "#fff7e5"
                        : "",
                  }}
                ></TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    border: "1px solid #e0e0e0",
                    position: "relative",
                    backgroundColor:
                      themeMode === "light" && shift.day === "Sun"
                        ? "#fff7e5"
                        : "",
                  }}
                  colSpan={9}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      backgroundColor: themeMode === "light" ? "#f6f6f6" : "",
                      borderRadius: 1,
                      p: 1,
                      textAlign: "start",
                      borderLeft: "3px solid #999898",
                      fontSize: { xs: "10px", sm: "12px", md: "14px" },
                      position: "relative",
                      "&:hover .edit-icon": { opacity: 1 },
                    }}
                  >
                    {shift.shift} <br /> ({shift.time})
                    <Tooltip title="Edit Shift">
                      <IconButton
                        className="edit-icon"
                        size="small"
                        sx={{
                          position: "absolute",
                          top: 2,
                          left: "-15px",
                          transform: "translateY(-50%)",
                          backgroundColor: "background.paper",
                          opacity: 0,
                          transition: "opacity 0.3s ease-in-out",
                          "&:hover": { backgroundColor: "background.paper" },
                        }}
                        onClick={() => handleOpen("edit")}
                      >
                        <EditIcon
                          sx={{ fontSize: { xs: "12px", sm: "14px" } }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    border: "1px solid #e0e0e0",
                    backgroundColor:
                      themeMode === "light" && shift.day === "Sun"
                        ? "#fff7e5"
                        : "",
                  }}
                ></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <EditShiftModal
        open={open}
        handleClose={() => setOpen(false)}
        mode={mode}
      />
    </div>
  );
};

const Shift = () => {
  const [view, setView] = useState("Weekly");
  const [dateRange, setDateRange] = useState(null);
  const [mode, setMode] = useState("edit");
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { mode: themeMode } = useTheme();
  const [openCalendar, setOpenCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const startOfWeek = selectedDate.startOf("isoWeek"); // Monday
  const endOfWeek = startOfWeek.add(6, "day"); // Sunday
  const formattedStart = startOfWeek.format("DD/MM/YYYY");
  const formattedEnd = endOfWeek.format("DD/MM/YYYY");
  let currentOrg = JSON.parse(localStorage.getItem("org"));

  const handlePrevWeek = () => {
    setSelectedDate(selectedDate.subtract(7, "day"));
  };

  const handleNextWeek = () => {
    setSelectedDate(selectedDate.add(7, "day"));
  };

  const handleOpen = (selectedMode) => {
    setMode(selectedMode);
  };

  return (
    <>
      <Box
        sx={{
          background: "background.paper",
          borderRadius: 2,
          marginBottom: { md: "0px", xs: "60px" },
        }}
      >
        {/* HEADER */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: { md: "center", xs: "start" },
            flexDirection: { xs: "column", md: "row" },
            marginBottom: "20px",
          }}
          className="p-2 mx-[20px]"
        >
          <div></div>

          <div className="flex relative md:ms-[170px] items-center gap-2">
            {/* Previous Week Button */}
            <div
              style={{
                backgroundColor: themeMode === "dark" ? "#171717" : "white",
              }}
              className="flex items-center gap-2 p-2 rounded-md"
            >
              <IoIosArrowBack
                fontSize="small"
                className="cursor-pointer hover:text-gray-600"
                onClick={handlePrevWeek}
                color="#398aff"
              />

              {/* Calendar Icon and Date Range Text */}
              <Box
                className={`relative flex items-center rounded-md cursor-pointer ${themeMode === "dark"
                  ? "hover:bg-[#191919]"
                  : "hover:bg-gray-200"
                  }`}
                onClick={() => setOpenCalendar(!openCalendar)}
              >
                <IoCalendarOutline size="18px" />
              </Box>

              {/* Next Week Button */}
              <IoIosArrowForward
                fontSize="small"
                className="cursor-pointer hover:text-gray-600"
                onClick={handleNextWeek}
                color="#398aff"
              />
            </div>

            <span className="text-sm font-medium">
              {formattedStart} - {formattedEnd}
            </span>

            {/* Calendar Popup */}
            {openCalendar && (
              <Box
                sx={{
                  backgroundColor: themeMode === "dark" ? "#171717" : "white",
                }}
                className="absolute top-8 z-10 shadow-md border rounded-md"
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateCalendar
                    value={selectedDate}
                    onChange={(date) => {
                      setSelectedDate(date);
                      setOpen(false);
                    }}
                  />
                </LocalizationProvider>
              </Box>
            )}
          </div>

          <div
            className="flex gap-1 md:gap-3 md:items-center"
            style={{ display: "flex" }}
          >
            <ToggleButtonGroup
              value={view}
              exclusive
              onChange={(e, newView) => newView && setView(newView)}
              sx={{
                height: "32px",
              }}
            >
              <ToggleButton
                value="Weekly"
                sx={{
                  fontSize: { md: "14px", xs: "10px" },
                  height: "32px",
                  width: "80px",
                  backgroundColor:
                    view === "Weekly" ? `${themeMode === "light" ? "#ffffff !important" : "transparent"} ` : "transparent",
                  border:
                    view === "Weekly"
                      ? "2px solid #0088ff"
                      : "2px solid transparent",
                  color: view === "Weekly" ? "black" : "grey",
                }}
              >
                Weekly
              </ToggleButton>
              <ToggleButton
                value="Monthly"
                disableRipple
                sx={{
                  fontSize: { md: "14px", xs: "10px" },
                  height: "32px",
                  width: "80px",
                  backgroundColor:
                    view === "Monthly" ? `${themeMode === "light" ? "#ffffff !important" : "transparent"} ` : "transparent",
                  border:
                    view === "Monthly"
                      ? "2px solid #0088ff"
                      : "2px solid transparent",
                  color: view === "Monthly" ? "black" : "grey",

                }}
              >
                Monthly
              </ToggleButton>
            </ToggleButtonGroup>

            {currentOrg.type === "Owner" &&
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  // onClick={() =>
                  //   handleOpen("assign")
                  // }
                  onClick={() => setOpen((prev) => !prev)}
                  sx={{
                    fontSize: { md: "14px", xs: "10px" },
                    height: "32px",
                    width: "130px",
                  }}
                >
                  Assign Shift
                </Button>
              </div>
            }

            <div
              className={`relative h-fit group p-2 rounded-md ${themeMode === "light" ? "bg-[#fff]" : ""
                }`}
              onClick={() => setIsOpen(!isOpen)}
            >
              <IoIosMore size={17} className="cursor-pointer" />
              {isOpen && (
                <Box
                  sx={{
                    backgroundColor: themeMode === "light" ? "#fff" : "#363636",
                  }}
                  className="absolute w-[180px] z-40 right-2 p-5 flex flex-col gap-4 rounded-lg top-10"
                >
                  <div className="flex gap-2 items-center cursor-pointer">
                    <CiImport size={18} />
                    <p className="text-[14px]">Import</p>
                  </div>
                  <div className="flex gap-2 items-center cursor-pointer">
                    <CiExport size={18} />
                    <p className="text-[14px]">Export</p>
                  </div>
                  <div className="flex gap-2 items-center cursor-pointer">
                    <TfiDownload size={18} />
                    <p className="text-[14px]">Download as PDF</p>
                  </div>
                  <div className="flex gap-2 items-center cursor-pointer">
                    <BsPrinter size={18} />
                    <p className="text-[14px]">Print</p>
                  </div>
                </Box>
              )}
              <span className="absolute left-1/2 -translate-x-1/2 w-[max-content] text-xs bg-gray-800 text-white px-2 py-1 rounded opacity-0 group-hover:opacity-100 transition">
                More
              </span>
            </div>
          </div>
        </Box>

        <div className='mx-[30px]'>
          {view === "Weekly" ? <WeekTab /> : <MonthTab />}
          <EditShiftModal
            open={open}
            handleClose={() => setOpen(false)}
            mode={mode}
          />
        </div>
      </Box>
    </>
  );
};

export default Shift;
