import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Grid,
  Modal,
  Autocomplete,
  TextField,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  tableCellClasses,
  TableBody,
  IconButton,
  Menu,
  MenuItem,
  Select,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Box,
  Drawer,
  Radio,
  Typography,
  Tooltip,
} from "@mui/material";
import DesktopWindowsOutlinedIcon from "@mui/icons-material/DesktopWindowsOutlined";
import FolderCopyOutlinedIcon from "@mui/icons-material/FolderCopyOutlined";
import { FiInfo } from "react-icons/fi";
import { IoMdAdd } from "react-icons/io";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  ArrowDropDownIcon,
  DateCalendar,
  DatePicker,
  TimePicker,
} from "@mui/x-date-pickers";
import { MdOutlineDescription, MdOutlineModeEdit } from "react-icons/md";
import CloudOutlinedIcon from "@mui/icons-material/CloudOutlined";
import { MdDeleteOutline } from "react-icons/md";
import {
  IoArrowBackOutline,
  IoChevronBack,
  IoFilter,
  IoSearch,
} from "react-icons/io5";
import { IoCalendarOutline } from "react-icons/io5";
import { IoList } from "react-icons/io5";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { HiDotsHorizontal } from "react-icons/hi";
import { CiImport } from "react-icons/ci";
import { CiExport } from "react-icons/ci";
import CustomModal from "../../../components/CustomModal";
import HomeTimeLogs from "../../DashComponents/TimeLogs";
import dayjs from "dayjs";
import {
  getToday,
  getYesterday,
  getThisWeek,
  getThisMonth,
  getLastWeek,
  getLastMonth,
} from "../../../utilities/date";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useTheme } from "../../../style/theme";
import Textarea from "../../DashComponents/TextArea";
import { InfoIcon, X } from "lucide-react";
import { CgClose } from "react-icons/cg";
import { RiKeyLine } from "react-icons/ri";
import { HiOutlineComputerDesktop } from "react-icons/hi2";
import SelectProject from "../../DashComponents/SelectProject";
import SelectJob from "../../DashComponents/SelectJob";

const FilterDrawer = ({ filterModal, setFilterModal }) => {
  const [filter, setFilter] = useState("Today");
  const [billable, setBillable] = useState("All");
  const [approval, setApproval] = useState("All");
  const { mode } = useTheme();
  return (
    <Drawer
      anchor="right"
      open={filterModal}
      onClose={() => setFilterModal(false)}
    >
      <div
        style={{
          backgroundColor: mode === "light" ? "#fff" : "background.view",
        }}
        className="w-72 p-4"
      >
        <div className="flex justify-between items-center">
          <h2 className="text-lg font-semibold mb-4">Filter</h2>
          <button
            className="border-none outline-none"
            onClick={() => setFilterModal(false)}
          >
            <X size={20} />
          </button>
        </div>

        <div className="mt-2">
          <label className="block text-sm font-medium mb-2 text-[#666666] ">
            Period
          </label>
          <Select
            value={filter}
            defaultValue="This Year"
            onChange={(e) => setFilter(e.target.value)}
            fullWidth
            sx={{
              backgroundColor: mode === "light" ? "#fff" : "",
            }}
            // className="bg-white"
            size="small"
          >
            <MenuItem value="Today">Today</MenuItem>
            <MenuItem value="Last Month">This Week</MenuItem>
            <MenuItem value="This Month">This Month</MenuItem>
            <MenuItem value="This Month">Yesterday</MenuItem>
            <MenuItem value="Last Year">Last Week</MenuItem>
            <MenuItem value="This Year">Last Month</MenuItem>
            <MenuItem value="Custom">Custom</MenuItem>
          </Select>
        </div>

        <div className="mt-2">
          <label className="block text-sm font-medium mb-2 text-[#666666] ">
            Clients
          </label>
          <Textarea minRows={1} placeholder="All Clients" />
        </div>

        <div className="mt-2">
          <label className="block text-sm font-medium mb-2 text-[#666666] ">
            Projects
          </label>
          <Textarea minRows={1} placeholder="All Projects" />
        </div>

        <div className="mt-2">
          <label className="block text-sm font-medium mb-2 text-[#666666] ">
            Jobs
          </label>
          <Textarea minRows={1} placeholder="All Jobs" />
        </div>

        <div className="mt-2">
          <label className="block text-sm font-medium mb-2 text-[#666666] ">
            Billable Status
          </label>
          <Select
            value={billable}
            defaultValue="This Year"
            onChange={(e) => setBillable(e.target.value)}
            fullWidth
            sx={{
              backgroundColor: mode === "light" ? "#fff" : "",
            }}
            // className="bg-white"
            size="small"
          >
            <MenuItem value="Today">All</MenuItem>
            <MenuItem value="Last Month">Billable</MenuItem>
            <MenuItem value="This Month">Non-Billable</MenuItem>
          </Select>
        </div>

        <div className="mt-2">
          <label className="block text-sm font-medium mb-2 text-[#666666] ">
            Billable Status
          </label>
          <Select
            value={approval}
            defaultValue="This Year"
            onChange={(e) => setApproval(e.target.value)}
            fullWidth
            sx={{
              backgroundColor: mode === "light" ? "#fff" : "",
            }}
            // className="bg-white"
            size="small"
          >
            <MenuItem value="Today">All</MenuItem>
            <MenuItem value="Last Month">Approved</MenuItem>
            <MenuItem value="This Month">unapproved</MenuItem>
          </Select>
        </div>
      </div>

      <div className="flex items-end h-full mb-5 justify-start px-5 space-x-2">
        <Button variant="contained" sx={{ bgColor: "#0088ff" }}>
          Apply
        </Button>
        <Button variant="outlined" onClick={() => setFilter("")}>
          Reset
        </Button>
      </div>
    </Drawer>
  );
};

const LogTimeModal = ({ logTimeModal, setLogTimeModal }) => {
  const [selectedOption, setSelectedOption] = useState("total");
  const [totalHours, setTotalHours] = useState("00:00");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [timer, setTimer] = useState("");

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [isProjectOpen, setIsProjectOpen] = useState(false);
  const [searchProjects, setSearchProjects] = useState("");
  const [selectedProject, setSelectedProject] = useState("Select Project");
  const [projectModalOpen, SetProjectModalOpen] = useState(false);
  const dropdownProjectRef = useRef(null);

  const [isJobOpen, setIsJobOpen] = useState(false);
  const [searchJob, setSearchJob] = useState("");
  const [selectedJob, setSelectedJob] = useState("Select Job");
  const [jobModalOpen, SetJobModalOpen] = useState(false);
  const dropdownJobRef = useRef(null);
  // const [projectModalOpen, SetProjectModalOpen] = useState(false);
  // const [jobModalOpen, SetJobModalOpen] = useState(false);

  const { mode } = useTheme();
  const projects = ["Test project F"];
  const job = ["Find"];

  const toggleprojectsDropdown = () => {
    setIsProjectOpen(!isProjectOpen);
  };

  const toggleJobDropdown = () => {
    setIsJobOpen(!isJobOpen);
  };

  const closeProjectsDropdown = (e) => {
    if (
      dropdownProjectRef.current &&
      !dropdownProjectRef.current.contains(e.target)
    ) {
      setIsProjectOpen(false);
    }
  };

  const closeJobDropdown = (e) => {
    if (dropdownJobRef.current && !dropdownJobRef.current.contains(e.target)) {
      setIsJobOpen(false);
    }
  };

  const handleProjectSelect = (project) => {
    setSelectedProject(project);
    setIsProjectOpen(false);
  };

  const handleJobSelect = (job) => {
    setSelectedJob(job);
    setIsProjectOpen(false);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    document.addEventListener("mousedown", closeProjectsDropdown);
    document.addEventListener("mousedown", closeJobDropdown);
    return () =>
      document.removeEventListener(
        "mousedown",
        closeProjectsDropdown,
        closeJobDropdown
      );
  }, []);

  const ConfirmModal = ({ confirmModalOpen, setConfirmModalOpen }) => {
    return (
      <Dialog
        open={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <div className="flex flex-col mx-auto justify-center items-center">
            <div className="rounded-full  p-3 bg-[#ffb446]">
              <InfoIcon style={{ color: "#fff" }} />
            </div>
            <p>Confirm</p>
          </div>

          <div
            className={`${
              mode === "light" ? "bg-[#dcdcdc]" : ""
            } p-2 rounded-md cursor-pointer`}
            onClick={() => setConfirmModalOpen(false)}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <CgClose size={"16px"} />
          </div>
        </DialogTitle>
        <DialogContent>
          <Typography>
            Changes made will not be saved. Do you want to proceed?
          </Typography>
        </DialogContent>
        <div className="flex justify-center items-center gap-3 pb-3">
          <Button
            variant="contained"
            style={{ backgroundColor: "#ffb446" }}
            onClick={() => setConfirmModalOpen(false)}
          >
            Yes
          </Button>
          <Button
            sx={{
              color: mode === "light" ? "#000000" : "#ffffff",
              border: mode === "light" ? "1px solid #000" : "",
            }}
            variant="outlined"
            onClick={() => setConfirmModalOpen(false)}
          >
            No
          </Button>
        </div>
      </Dialog>
    );
  };

  const ProjectModal = ({ projectModalOpen, SetProjectModalOpen }) => {
    const [projectName, setProjectName] = useState("");
    const { mode } = useTheme();
    return (
      <Dialog
        open={projectModalOpen}
        onClose={() => SetProjectModalOpen(false)}
        fullScreen
        sx={{ margin: "10px", borderRadius: "10px" }}
      >
        {/* Sticky Header */}
        <div
          className={`sticky flex justify-between items-center shadow-md top-0 ${
            mode === "light" ? "bg-white" : "bg-[#141414]"
          }  z-10 py-2 px-4 `}
        >
          <DialogTitle sx={{ padding: "0px" }}>Add Project</DialogTitle>
          <div
            className={`${
              mode === "light" ? "bg-[#dcdcdc]" : ""
            } p-2 rounded-md cursor-pointer`}
            onClick={() => SetProjectModalOpen(false)}
          >
            <CgClose />
          </div>
        </div>

        {/* Grey Background Content */}
        <DialogContent
          sx={{
            bgcolor: mode === "light" ? "#edf0f4" : "#141414",
            p: 2,
          }}
        >
          <Box
            sx={{
              bgcolor: mode === "light" ? "#ffffff" : "",
              borderRadius: "12px",
              marginTop: "10px",
            }}
          >
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              sx={{ fontWeight: "700px", fontSize: "16px", padding: "20px" }}
            >
              Project Configuration Details
            </Typography>

            <hr />

            <div className="flex gap-4 items-center p-5">
              <label
                className={`block text-[15px] font-[400] w-[170px] ${
                  mode === "light" ? "text-[#666666]" : ""
                } `}
              >
                Project Name
                <span className="text-red-500">*</span>
              </label>
              <TextField
                fullWidth
                // label="Project Name"
                required
                variant="outlined"
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    height: "34px",
                  },
                  mt: "15px",
                }}
              />
            </div>
          </Box>
        </DialogContent>

        {/* Sticky Bottom Section */}
        <div
          className={`sticky bottom-0 flex gap-2 items-center shadow-md ${
            mode === "light" ? "bg-[#ffffff]" : "bg-[#141414]"
          } z-10 p-4 flex justify-start`}
        >
          <button className="text-[14px] px-4 py-1 bg-[#0088ff] text-white rounded">
            Submit
          </button>
          <button
            className="px-4 py-1 border border-gray-300 text-[14px] rounded"
            onClick={() => SetProjectModalOpen(false)}
          >
            Cancel
          </button>
        </div>
      </Dialog>
    );
  };

  const JobModal = ({ jobModalOpen, SetJobModalOpen }) => {
    const [jobName, setJobName] = useState("");
    const { mode } = useTheme();
    return (
      <Dialog
        open={jobModalOpen}
        onClose={() => SetJobModalOpen(false)}
        fullScreen
        sx={{ margin: "10px", borderRadius: "10px" }}
      >
        {/* Sticky Header */}
        <div
          className={`sticky flex justify-between items-center shadow-md top-0 ${
            mode === "light" ? "bg-white" : "bg-[#141414]"
          }  z-10 py-2 px-4 `}
        >
          <DialogTitle sx={{ padding: "0px" }}>Add Job</DialogTitle>
          <div
            className={`${
              mode === "light" ? "bg-[#dcdcdc]" : ""
            } p-2 rounded-md cursor-pointer`}
            onClick={() => SetJobModalOpen(false)}
          >
            <CgClose />
          </div>
        </div>

        {/* Grey Background Content */}
        <DialogContent
          sx={{
            bgcolor: mode === "light" ? "#edf0f4" : "#141414",
            p: 2,
          }}
        >
          <Box
            sx={{
              bgcolor: mode === "light" ? "#ffffff" : "",
              borderRadius: "12px",
              marginTop: "10px",
            }}
          >
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              sx={{ fontWeight: "700px", fontSize: "16px", padding: "20px" }}
            >
              Job Configuration Details
            </Typography>

            <hr />

            <div className="flex gap-4 items-center p-5">
              <label
                className={`block text-[15px] font-[400] w-[170px] ${
                  mode === "light" ? "text-[#666666]" : ""
                } `}
              >
                Job Name
                <span className="text-red-500">*</span>
              </label>
              <TextField
                fullWidth
                // label="Project Name"
                required
                variant="outlined"
                value={jobName}
                onChange={(e) => setJobName(e.target.value)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    height: "34px",
                  },
                  mt: "15px",
                }}
              />
            </div>
          </Box>
        </DialogContent>

        {/* Sticky Bottom Section */}
        <div
          className={`sticky bottom-0 flex gap-2 items-center shadow-md ${
            mode === "light" ? "bg-[#ffffff]" : "bg-[#141414]"
          } z-10 p-4 flex justify-start`}
        >
          <button className="text-[14px] px-4 py-1 bg-[#0088ff] text-white rounded">
            Submit
          </button>
          <button
            className="px-4 py-1 border border-gray-300 text-[14px] rounded"
            onClick={() => SetJobModalOpen(false)}
          >
            Cancel
          </button>
        </div>
      </Dialog>
    );
  };

  return (
    <Dialog
      open={logTimeModal}
      onClose={() => setLogTimeModal(false)}
      fullScreen
    >
      {/* Sticky Top Section */}
      <div
        className={`sticky flex justify-between items-center top-0 ${
          mode === "light" ? "bg-[#ffffff]" : "bg-[#141414]"
        }  z-10 py-4 px-4 shadow-md`}
      >
        <DialogTitle sx={{ padding: "0px" }}>Log Time</DialogTitle>
        <div
          className={`${
            mode === "light" ? "bg-[#dcdcdc]" : ""
          } p-2 rounded-md cursor-pointer`}
          onClick={() => setConfirmModalOpen(true)}
        >
          <CgClose />
        </div>

        {confirmModalOpen && (
          <ConfirmModal
            confirmModalOpen={confirmModalOpen}
            setConfirmModalOpen={setConfirmModalOpen}
          />
        )}
      </div>

      {/* Main Content */}
      <DialogContent
        sx={{ background: mode === "light" ? "#edf0f4" : "#141414", p: 3 }}
      >
        <Box
          sx={{
            background: mode === "light" ? "white" : "#141414",
            p: 3,
            borderRadius: 2,
          }}
        >
          <div className="flex w-full flex-col sm:flex-row sm:items-center ">
            <div className="flex items-center">
              <label
                className={`block text-[15px] font-[400] w-[170px] ${
                  mode === "light" ? "text-[#666666]" : ""
                } `}
              >
                Project Name
              </label>
            </div>

            <div className="flex w-full items-center gap-3">
              <div
                className="relative w-full "
                onClick={toggleprojectsDropdown}
                ref={dropdownProjectRef}
              >
                <select
                  className={`w-full h-[34px] px-2 text-[14px] py-2 pointer-events-none text-left ${
                    mode === "light" ? "bg-white" : "bg-transparent"
                  } border rounded outline-none`}
                >
                  <option value={selectedProject}>{selectedProject} </option>
                </select>
                {isProjectOpen && (
                  <div
                    className={`w-full z-40  absolute left-0 mt-2 ${
                      mode === "light" ? "bg-white" : "bg-[#141414]"
                    } border  rounded-md shadow-lg`}
                  >
                    <div
                      className={`${
                        mode == "light" ? "bg-[#e1e4e7]" : ""
                      }  p-2`}
                    >
                      <div
                        className={`flex  items-center border border-[#0088ff] rounded-sm h-[30px] px-2 ${
                          mode === "light" ? "bg-[#fff] " : ""
                        }`}
                      >
                        <IoSearch color="#c1c1c1" />
                        <input
                          type="text"
                          // placeholder="Search"
                          value={searchProjects}
                          onChange={(e) => setSearchProjects(e.target.value)}
                          className="w-full px-3 py-2 border-none rounded-md outline-none bg-transparent "
                        />
                      </div>
                    </div>
                    <ul className="max-h-48 overflow-y-auto">
                      <li className="px-4 py-2 bg-gray-100 text-gray-600">
                        Select Project
                      </li>
                      {projects
                        .filter((p) =>
                          p.toLowerCase().includes(searchProjects.toLowerCase())
                        )
                        .map((project, index) => (
                          <li
                            key={index}
                            className={`px-4 py-2 hover:bg-gray-100  cursor-pointer`}
                            onClick={() => handleProjectSelect(project)}
                          >
                            {project}
                          </li>
                        ))}
                    </ul>
                  </div>
                )}
              </div>

              <div
                className={`${
                  mode === "light" ? "bg-[#eeeeee]" : ""
                } p-2 rounded-md cursor-pointer`}
                onClick={() => SetProjectModalOpen(true)}
              >
                <IoMdAdd />
              </div>

              {projectModalOpen && (
                <ProjectModal
                  projectModalOpen={projectModalOpen}
                  SetProjectModalOpen={SetProjectModalOpen}
                />
              )}
            </div>
          </div>

          <div className="flex w-full flex-col sm:flex-row  mt-4 sm:items-center ">
            <div className="flex  items-center">
              <label
                className={`block text-[15px] font-[400] w-[170px] ${
                  mode === "light" ? "text-[#666666]" : ""
                } `}
              >
                Job Name
                <span className="text-red-500">*</span>
              </label>
            </div>

            <div className="flex items-center gap-3 w-full">
              <div
                className="relative w-full"
                ref={dropdownJobRef}
                onClick={toggleJobDropdown}
              >
                <select
                  className={`w-full px-2 h-[34px] text-[14px] py-2 text-left pointer-events-none ${
                    mode === "light" ? "bg-white" : "bg-transparent"
                  } border rounded outline-none`}
                >
                  <option>{selectedJob} </option>
                </select>
                {isJobOpen && (
                  <div
                    className={`w-full absolute left-0 mt-2  ${
                      mode === "light" ? "bg-white" : "bg-[#141414]"
                    } border  rounded-md shadow-lg`}
                  >
                    <div
                      className={`${
                        mode == "light" ? "bg-[#e1e4e7]" : ""
                      }  p-2`}
                    >
                      <div
                        className={`flex  items-center border border-[#0088ff] rounded-sm h-[30px] px-2 ${
                          mode === "light" ? "bg-[#fff] " : ""
                        }`}
                      >
                        <IoSearch color="#c1c1c1" />
                        <input
                          type="text"
                          // placeholder="Search"
                          value={searchJob}
                          onChange={(e) => setSearchJob(e.target.value)}
                          className="w-full px-3 py-2 border-none rounded-md outline-none bg-transparent "
                        />
                      </div>
                    </div>
                    <ul className="max-h-48 overflow-y-auto">
                      <li className="px-4 py-2 bg-gray-100 text-gray-600">
                        Select Job
                      </li>
                      {job
                        .filter((p) =>
                          p.toLowerCase().includes(searchJob.toLowerCase())
                        )
                        .map((job, index) => (
                          <li
                            key={index}
                            className="px-4 py-2  hover:bg-gray-100 cursor-pointer"
                            onClick={() => handleJobSelect(job)}
                          >
                            {job}
                          </li>
                        ))}
                    </ul>
                  </div>
                )}
              </div>
              <div
                className={`${
                  mode === "light" ? "bg-[#eeeeee]" : ""
                } p-2 rounded-md cursor-pointer`}
                onClick={() => SetJobModalOpen(true)}
              >
                <IoMdAdd />
              </div>

              {jobModalOpen && (
                <JobModal
                  jobModalOpen={jobModalOpen}
                  SetJobModalOpen={SetJobModalOpen}
                />
              )}
            </div>
          </div>

          <div className="flex  justify-between mt-4 items-center">
            <label
              className={`block text-[15px] font-[400] w-[190px] ${
                mode === "light" ? "text-[#666666]" : ""
              } `}
            >
              Work Item
            </label>
            <Textarea minRows={1} className="h-[34px]" />
          </div>

          <div className="flex justify-between flex-1  mt-4 flex-col xsx:flex-row xsx:items-center">
            <label
              className={`block text-[15px] font-[400] w-[190px] ${
                mode === "light" ? "text-[#666666]" : ""
              } `}
            >
              Date <span className="text-red-500">*</span>
            </label>
            <input
              type="date"
              className={`w-full p-2 h-[34px]  border border-[#fff]  rounded ${
                mode === "light" ? "bg-white" : "bg-transparent"
              }`}
            />
          </div>

          <div className="flex justify-between  mt-4 items-center">
            <label
              className={`block text-[15px] font-[400] w-[190px] ${
                mode === "light" ? "text-[#666666]" : ""
              } `}
            >
              Description
            </label>
            <Textarea minRows={3} />
          </div>

          <div className="flex  mt-4 items-center">
            <label
              className={`block text-[15px] font-[400] w-[170px]  ${
                mode === "light" ? "text-[#666666]" : ""
              } `}
            >
              Hours <span className="text-red-500">*</span>
            </label>
            <div>
              <RadioGroup
                row
                value={selectedOption}
                onChange={(e) => setSelectedOption(e.target.value)}
              >
                <FormControlLabel
                  value="total"
                  control={<Radio />}
                  label="Total hours"
                />
                <FormControlLabel
                  value="start-end"
                  control={<Radio />}
                  label="Start and end time"
                />
                <FormControlLabel
                  value="timer"
                  control={<Radio />}
                  label="Timer"
                />
              </RadioGroup>

              {/* Input fields based on selected option */}
              {selectedOption === "total" && (
                <div className="flex gap-2 ">
                  <TextField
                    type="time"
                    value={totalHours}
                    onChange={(e) => setTotalHours(e.target.value)}
                    size="small"
                  />
                    <Tooltip title="Total working hours">
                      <FiInfo color="#f6bb2f" size={"20px"} />
                    </Tooltip>
                </div>
              )}

              {selectedOption === "start-end" && (
                <Box display="flex" alignItems="center" gap={1} mt={1}>
                <TextField
                    value={startTime || "12:04 PM"}
                    size="small"
                    InputProps={{ readOnly: true }}
                  />
                  -
                  <TextField
                    value={endTime || "12:04 PM"}
                    size="small"
                    InputProps={{ readOnly: true }}
                  />
                </Box>
              )}

              {selectedOption === "timer" && (
                <TextField
                  type="text"
                  label="Timer"
                  value={timer}
                  onChange={(e) => setTimer(e.target.value)}
                  size="small"
                  sx={{ mt: 1 }}
                />
              )}
            </div>
          </div>

          <div className="flex justify-between  mt-4 items-center">
            <label
              className={`block text-[15px] font-[400] w-[190px] ${
                mode === "light" ? "text-[#666666]" : ""
              } `}
            >
              Billable Status
              <span className="text-red-500">*</span>
            </label>
            <Select className="w-full h-[34px]">
              <MenuItem value="billable">Billable</MenuItem>
              <MenuItem value="not-billable">Not Billable</MenuItem>
            </Select>
          </div>

          <div className="flex w-full  mt-4 items-center">
            <label
              className={`block text-[15px] font-[400] w-[190px] ${
                mode === "light" ? "text-[#666666]" : ""
              } `}
            >
              Attachment
            </label>

            <div className="border w-full p-5 border-[#dcdcdc] flex justify-center items-center gap-2 rounded-md">
              <p>Upload from </p>
              <div className="flex gap-1 items-center">
                <DesktopWindowsOutlinedIcon
                  style={{ color: "#0088ff", fontSize: "12px" }}
                />
                <p className="text-[#0088ff] text-[14px]">Desktop /</p>
              </div>

              <div className="flex gap-1 items-center">
                <FolderCopyOutlinedIcon
                  style={{ color: "#0088ff", fontSize: "12px" }}
                />
                <p className="text-[#0088ff] text-[14px]">
                  Clikkle Workdrive /
                </p>
              </div>

              <div className="flex gap-1 items-center">
                <CloudOutlinedIcon
                  style={{ color: "#0088ff", fontSize: "12px" }}
                />
                <p className="text-[#0088ff] text-[14px]">Others</p>
              </div>
            </div>
          </div>

            <div className="text-sm text-[#8f918f]">Max. size is 5 MB</div>
          
        </Box>
      </DialogContent>
      {/* Sticky Footer */}
      <div
        className={`sticky flex gap-3 items-center top-0 ${
          mode === "light" ? "bg-[#ffffff]" : "bg-[#141414]"
        }  z-10 py-4 px-4`}
      >
        <Button variant="contained" sx={{ backgroundColor: "#0088ff" }}>
          Save
        </Button>
        <Button
          variant="outlined"
          sx={{
            backgroundColor: mode === "light" ? "#fff" : "",
            color: mode === "light" ? "#000000" : "#ffffff",
          }}
          onClick={() => setLogTimeModal(false)}
        >
          Cancel
        </Button>
      </div>
    </Dialog>
  );
};

const DailyLog = ({
  dailyLogModal,
  setMonthlyLogModal,
  setDailyLogModal,
  setWeeklyLogModal,
  setSemiMonthlyLogModal,
}) => {
  const { mode } = useTheme();
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("all");
  const [rows, setRows] = useState([1, 2, 3, 4, 5]);
  const [billable, setBillable] = useState("Billable");

  const addRow = () => {
    setRows([...rows, rows.length + 1]);
  };

  const handlePrevDay = () => {
    setSelectedDate(selectedDate.subtract(1, "day"));
  };

  const handleNextDay = () => {
    setSelectedDate(selectedDate.add(1, "day"));
  };
  const handleModalOpen = (value) => {
    if (value === "all") setDailyLogModal(true);
    else if (value === "draft") setWeeklyLogModal(true);
    else if (value === "pending") setSemiMonthlyLogModal(true);
    else if (value === "approved") setMonthlyLogModal(true);
  };
  return (
    <Dialog
      open={dailyLogModal}
      onClose={() => setDailyLogModal(false)}
      fullScreen
    >
      {/* Top Sticky Header */}
      <div
        className={`sticky flex justify-between items-center top-0 ${
          mode === "light" ? "bg-[#ffffff]" : "bg-[#141414]"
        }  z-10 py-4 px-4 shadow-md`}
      >
        <div className="flex items-center gap-2 ">
          <div
            className={`${
              mode === "light" ? "bg-[#dcdcdc]" : ""
            } p-2 rounded-md cursor-pointer`}
            onClick={() => setDailyLogModal(false)}
          >
            <IoArrowBackOutline />
          </div>
          <img
            src="/images/no-user.png"
            alt=""
            width={"38px"}
            height={"38px"}
            className="rounded"
          />
          <span className="font-[500] text-[14px]">1 - tanya Gwilsbry</span>
        </div>
        <div
          className={`${
            mode === "light" ? "bg-[#dcdcdc]" : ""
          } p-2 rounded-md cursor-pointer`}
          onClick={() => setDailyLogModal(false)}
        >
          <CgClose size={"16px"} />
        </div>
      </div>

      {/* Main Content */}
      <DialogContent
        className={`p-4 ${mode === "light" ? "bg-[#edf0f4]" : "bg-[#141414]"} `}
      >
        <div className="flex w-full justify-between items-center">
          <div className="flex-1"></div>

          <div className="flex flex-1 relative justify-center items-center gap-2">
            {/* Navigation Controls */}
            <div className={`flex items-center gap-2 ${mode === "light" ? "bg-white" :""} p-2 rounded-md`}>
              <IoIosArrowBack
                fontSize="small"
                className="cursor-pointer hover:text-gray-600"
                onClick={handlePrevDay} // Change Month → Change Day
              />

              <Box
                onClick={() => setOpen((prev) => !prev)}
                className="relative flex items-center rounded-md cursor-pointer"
              >
                <IoCalendarOutline size="18px" className="text-gray-600" />
              </Box>

              <IoIosArrowForward
                fontSize="small"
                className="cursor-pointer hover:text-gray-600"
                onClick={handleNextDay} // Change Month → Change Day
              />
            </div>

            {/* Display Current Day, Month and Year */}
            <span className="text-sm flex font-medium">
              {selectedDate.format("DD MMMM YYYY")}{" "}
              {/* Show Day Instead of Only Month */}
            </span>

            {/* Calendar Popup */}
            {open && (
              <Box className={`absolute top-8 z-10 shadow-md border rounded-md ${mode === "light" ? "bg-white":"bg-[#141414]"}`}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateCalendar
                    value={selectedDate}
                    onChange={(date) => {
                      setSelectedDate(date);
                      setOpen(false);
                    }}
                  />
                </LocalizationProvider>
              </Box>
            )}
          </div>

          <div className="flex flex-1 gap-3 justify-end items-center mb-[30px]">
            <FormControl sx={{ width: "200px" }}>
              <Select
                value={selectedValue}
                onChange={(e) => {
                  setSelectedValue(e.target.value); // Value update hogi
                  handleModalOpen(e.target.value); // Modal bhi open hoga
                }}
                displayEmpty
                sx={{
                  height: "40px",
                  backgroundColor: mode === "light" ? "white" :"transparent",
                  outline: "none",
                }}
              >
                <MenuItem value="all">Daily Log</MenuItem>
                <MenuItem value="draft">Weekly Log</MenuItem>
                <MenuItem value="pending">Semi Monthly Log</MenuItem>
                <MenuItem value="approved">Monthly Log</MenuItem>
              </Select>
            </FormControl>
            <Button
              variant="outlined"
              sx={{
                fontSize: { xs: "10px", md: "14px" },
                backgroundColor: mode === "light"  ? "#ffffff" :"",
              }}
            >
              Clone
            </Button>
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="w-full border-collapse">
            <thead className={`${mode === "light" ? "bg-[#e5e5f0]" :"bg-[#141414]"} text-[#666666]`}>
              <tr>
                <th className="p-2 font-[500] text-left">S.No</th>
                <th className="p-2 font-[500] text-left">Project Name</th>
                <th className="p-2 font-[500] text-left">Job Name</th>
                <th className="p-2 font-[500] text-left">Work Item</th>
                <th className="p-2 font-[500] text-left">Billable Status</th>
                <th className="p-2 font-[500] text-left">Description</th>
                <th className="p-2 font-[500] text-left">Hour(s)</th>
              </tr>
            </thead>
            <tbody>
              {rows.map((num) => (
                <tr key={num} className={`${mode === "light" ? "bg-[#ffffff]" :"bg-[#141414]"} border-b`}>
                  <td className="p-2">{num}</td>
                  <td className="p-2 w-[332px]">
                  <div className="w-[180px]">
                  <SelectProject />
                    </div>
  
                  </td>
                  <td className="p-2 w-[332px]">
                    <div className="w-[180px]">
                      <SelectJob />
                    </div>
                  </td>
                  <td className="p-2 w-[332px]">
                    <div className="w-[180px]">
                      <input
                        type="text"
                        className={`border rounded p-1 w-full ${mode === "light" ? "bg-[#fff]" : "bg-transparent"}`}
                      />
                    </div>
                  </td>
                  <td className="p-2 w-[332px]">
                    <div className="w-[180px]">
                      <Select
                        value={billable}
                        onChange={(e) => setBillable(e.target.value)}
                        fullWidth
                        sx={{ backgroundColor: mode === "light" ? "#fff" : "transparent" }}
                        size="small"
                      >
                        <MenuItem value="All">All</MenuItem>
                        <MenuItem value="Billable">Billable</MenuItem>
                        <MenuItem value="Non-Billable">Non-Billable</MenuItem>
                      </Select>
                    </div>
                  </td>
                  <td className="p-2 w-[332px]">
                    <div className="w-[180px]">
                      <input
                        type="text"
                        className={`border outline-none border-[#dcdcdc] text-[14px] ${mode === "light" ? "bg-[#Fff] text-[#dcdcdc]":" bg-transparent "} rounded-md p-1 text-[12px]`}
                        placeholder="Enter Description"
                      />
                    </div>
                  </td>
                  <td className="p-2 w-[220px]">
                    <div className={`w-[80px] text-center text-[14px] border border-[#dcdcdc] ${mode === "light"?"text-[#bbb6be]":""} rounded-md`}>
                      00:00
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* Total Row */}
            <tr className={`${mode === "light" ? "bg-[#f8f8f8]" :""} font-[500] p-4`}>
              <td colSpan={2} className="p-2">
                <button
                  onClick={addRow}
                  className="text-[#0088ff] bg-[#e5f3ff] p-2 rounded-md"
                >
                  Add Row
                </button>
              </td>
              <td colSpan={4} className="p-2 text-right">
                Total
              </td>
              <td className= {`p-2 text-center ${mode === "light"  ?"text-black":""}`}>00:00</td>
            </tr>
          </table>
        </div>
      </DialogContent>

      {/* Bottom Sticky Footer */}
      <div
        className={`sticky bottom-0 ${
          mode === "light" ? "bg-[#ffffff]" : "bg-[#141414]"
        }  p-4 border-t flex justify-between items-center`}
      >
        <div className="flex gap-2">
          <Button variant="contained" sx={{ backgroundColor: "#0088ff" }}>
            Save
          </Button>
          <Button
            variant="outlined"
            sx={{
              backgroundColor: mode === "light" ? "#fff" : "",
              color: mode === "light" ? "#000000" : "#ffffff",
              border: mode === "light" ? "1px solid #DCDCDC" : "",
            }}
            onClick={() => setDailyLogModal(false)}
          >
            Reset
          </Button>
          <Button
            variant="outlined"
            sx={{
              backgroundColor: mode === "light" ? "#fff" : "",
              color: mode === "light" ? "#000000" : "#ffffff",
              border: mode === "light" ? "1px solid #DCDCDC" : "",
            }}
            onClick={() => setDailyLogModal(false)}
          >
            Cancel
          </Button>
        </div>

        <div className="text-sm text-gray-500 flex gap-2">
          <span className="border-l-4 pl-3 border-[#e05654]">Unpaid Leave</span>
          <span className="border-l-4 pl-3 border-[#cb3888]">Absent</span>
          <span className="border-l-4 pl-3 border-[#ee9307]">Paid Leave</span>
          <span className="border-l-4 pl-3 border-[#d2bb14]">Weekend</span>
          <span className="border-l-4 pl-3 border-[#ab4afc]">On Duty</span>
          <span className="border-l-4 pl-1 border-[#65d4fb]">Holiday</span>
        </div>
      </div>
    </Dialog>
  );
};

const WeeklyLog = ({
  weeklyLogModal,
  setWeeklyLogModal,
  setSemiMonthlyLogModal,
  setMonthlyLogModal,
  setDailyLogModal,
}) => {
  const { mode } = useTheme();
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("all");
  const [filterModal, setFilterModal] = useState(false);
  const [createTimesheetModal, setCreateTimesheetModal] = useState(false);
  const [rows, setRows] = useState([1, 2, 3, 4, 5]);
  const [billable, setBillable] = useState("Billable");
  const [openCalendar, setOpenCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const startOfWeek = selectedDate.startOf("isoWeek"); // Monday
  const endOfWeek = startOfWeek.add(6, "day"); // Sunday
  const formattedStart = startOfWeek.format("DD/MM/YYYY");
  const formattedEnd = endOfWeek.format("DD/MM/YYYY");
  const [selectedModalDate, setSelectedModalDate] = useState("Mar 09");

  const addRow = () => {
    setRows([...rows, rows.length + 1]);
  };

  const handlePrevWeek = () => {
    setSelectedDate(selectedDate.subtract(7, "day"));
  };

  const handleNextWeek = () => {
    setSelectedDate(selectedDate.add(7, "day"));
  };

  const [modalVisible, setModalVisible] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });

  const handleIconClick = (event) => {
    const rect = event.currentTarget.getBoundingClientRect();
    setModalPosition({
      top: rect.top + window.scrollY + 20,
      left: rect.left + window.scrollX,
    });
    setModalVisible(true);
  };

  const dates = [
    "Mar 09",
    "Mar 10",
    "Mar 11",
    "Mar 12",
    "Mar 13",
    "Mar 14",
    "Mar 15",
  ];
  const handleModalOpen = (value) => {
    if (value === "all") setDailyLogModal(true);
    else if (value === "draft") setWeeklyLogModal(true);
    else if (value === "pending") setSemiMonthlyLogModal(true);
    else if (value === "approved") setMonthlyLogModal(true);
  };

  return (
    <Dialog
      open={weeklyLogModal}
      onClose={() => setWeeklyLogModal(false)}
      fullScreen
    >
      {/* Top Sticky Header */}
      <div
        className={`sticky flex justify-between items-center top-0 ${
          mode === "light" ? "bg-[#ffffff]" : "bg-[#141414]"
        }  z-10 py-4 px-4 shadow-md`}
      >
        <div className="flex items-center gap-2 ">
          <div
            className={`${
              mode === "light" ? "bg-[#dcdcdc]" : ""
            } p-2 rounded-md cursor-pointer`}
            onClick={() => setWeeklyLogModal(false)}
          >
            <IoArrowBackOutline />
          </div>
          <img
            src="/images/no-user.png"
            alt=""
            width={"38px"}
            height={"38px"}
            className="rounded"
          />
          <span className="font-[500] text-[14px]">1 - tanya Gwilsbry</span>
        </div>
        <div
          className={`${
            mode === "light" ? "bg-[#dcdcdc]" : ""
          } p-2 rounded-md cursor-pointer`}
          onClick={() => setWeeklyLogModal(false)}
        >
          <CgClose size={"16px"} />
        </div>
      </div>

      {/* Main Content */}
      <DialogContent className={`p-4 ${mode === "light"  ? "bg-[#edf0f4]" : "bg-[#141414]"}`}>
        <div className="flex w-full justify-between items-center">
          <div className="flex-1"></div>

          <div className="flex flex-1 relative justify-center items-center gap-2">
            {/* Previous Week Button */}
            <div
              style={{
                backgroundColor: mode === "dark" ? "#171717" : "white",
              }}
              className="flex items-center gap-2 p-2 rounded-md"
            >
              <IoIosArrowBack
                fontSize="small"
                className="cursor-pointer hover:text-gray-600"
                onClick={handlePrevWeek}
                color="#398aff"
              />

              {/* Calendar Icon and Date Range Text */}
              <Box
                className={`relative flex items-center rounded-md cursor-pointer ${
                  mode === "dark" ? "hover:bg-[#191919]" : "hover:bg-gray-200"
                }`}
                onClick={() => setOpenCalendar(!openCalendar)}
              >
                <IoCalendarOutline size="18px" />
              </Box>

              {/* Next Week Button */}
              <IoIosArrowForward
                fontSize="small"
                className="cursor-pointer hover:text-gray-600"
                onClick={handleNextWeek}
                color="#398aff"
              />
            </div>

            <span className="text-sm font-medium">
              {formattedStart} - {formattedEnd}
            </span>

            {/* Calendar Popup */}
            {openCalendar && (
              <Box className={`absolute top-8 z-10 shadow-md border rounded-md ${mode === "light" ? "bg-white" :"bg-[#141414]"}`}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateCalendar
                    value={selectedDate}
                    onChange={(date) => {
                      setSelectedDate(date);
                      setOpen(false);
                    }}
                  />
                </LocalizationProvider>
              </Box>
            )}
          </div>

          <div className="flex flex-1 gap-3 justify-end items-center mb-[30px]">
            <FormControl sx={{ width: "200px" }}>
              <Select
                value={selectedValue}
                onChange={(e) => {
                  setSelectedValue(e.target.value);
                  handleModalOpen(e.target.value); // Modal bhi open hoga
                }}
                displayEmpty
                sx={{
                  height: "40px",
                  ackgroundColor: mode === "light" ? "#ffffff" : "",
                  outline: "none",
                }}
              >
                <MenuItem value="all">Daily Log</MenuItem>
                <MenuItem value="draft">Weekly Log</MenuItem>
                <MenuItem value="pending">Semi Monthly Log</MenuItem>
                <MenuItem value="approved">Monthly Log</MenuItem>
              </Select>
            </FormControl>
            <Button
              variant="outlined"
              sx={{
                fontSize: { xs: "10px", md: "14px" },
                backgroundColor: mode === "light" ? "#ffffff" : "",
              }}
            >
              Clone
            </Button>
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="w-full border-collapse text-[#666] text-sm">
            <thead className= {`text-left ${mode === "light" ? "bg-[#e5e5f0]" : "bg-[#141414]"}`}>
              <tr>
                <td className="p-2 font-[500] text-[14px] border-r border-[#fff]">
                  S.No
                </td>
                <td className="p-2 font-[500] text-[14px] border-r border-[#fff]">
                  Project Name
                </td>
                <td className="p-2 font-[500] text-[14px] border-r border-[#fff]">
                  Job Name
                </td>
                <td className="p-2 font-[500] text-[14px] border-r border-[#fff]">
                  Work Item
                </td>
                <td className="p-2 border-r border-[#fff]"></td>
                <td className="p-2 font-[500] text-[14px] border-r border-[#fff]">
                  Billable Status
                </td>
                {[
                  "Mar 09 Sun",
                  "Mar 10 Mon",
                  "Mar 11 Tue",
                  "Mar 12 Wed",
                  "Mar 13 Thu",
                  "Mar 14 Fri",
                  "Mar 15 Sat",
                ].map((day) => (
                  <td
                    key={day}
                    className="px-4 py-4 font-[500] text-[14px] border-r border-[#fff]"
                  >
                    {day}
                  </td>
                ))}
                <td className="p-2 text-center font-[500] text-[14px]">
                  Total
                </td>
              </tr>
            </thead>
            <tbody>
              {rows.map((num) => (
                <tr key={num} className={`${mode === "light"?"bg-[#ffffff]":"bg-[#141414]"} border-b border-[#fff]`}>
                  <td className="p-2 border-r border-[#fff]">{num}</td>
                  <td className="w-[205px] p-2 border-r border-[#fff]">
                    <div className='w-[150px]'> 
                    <SelectProject />
                    </div>
                  </td>
                  <td className="w-[205px] p-2 border-r border-[#fff]">
                    <div className="w-[150px]">
                      <Tooltip title="select job">
                        <SelectJob />
                      </Tooltip>
                    </div>
                  </td>
                  <td className="p-2 w-[205px] border-r border-[#fff]">
                    <div className="w-[150px]">
                      <input
                        type="text"
                        className = {`border rounded p-1 w-full outline-none ${mode === "light" ? "bg-[#fff]" : "bg-transparent"}`}
                      />
                    </div>
                  </td>

                  <td className="p-2 w-[70px] text-center border-r border-[#fff]">
                    <div
                      className="w-[50px] flex justify-center cursor-pointer items-center"
                      onClick={handleIconClick}
                    >
                      <MdOutlineDescription size={"16px"} />
                    </div>
                  </td>

                  <td className="p-2 w-[205px] border-r border-[#fff]">
                    <div className="w-[150px]">
                      <Select
                        value={billable}
                        onChange={(e) => setBillable(e.target.value)}
                        fullWidth
                        sx={{ backgroundColor: mode === "light" ? "#fff" :  ""}}
                        size="small"
                      >
                        <MenuItem value="Billable">Billable</MenuItem>
                        <MenuItem value="Non-Billable">Non-Billable</MenuItem>
                      </Select>
                    </div>
                  </td>
                  {Array(7)
                    .fill(0)
                    .map((_, index) => (
                      <td
                        key={index}
                        className="text-center border-r border-[#fff]"
                      >
                        <div
                          className={`text-[#bbb6be] flex justify-center items-center h-[53px] text-[14px] 
                            ${index === 0 ? `w-full ${mode === "light" ? "bg-[#e8e5c7]" : "bg-transparent"}` : "w-[80px]"}
                          `}
                        >
                          <div className={`${mode === "light" ? "bg-[#fff]" :""} py-[7px] px-[10px] w-[80px] ms-2 me-2 rounded-[4px] text-[14px] border border-[#DCDCDC] pe-4`}>
                            00:00
                          </div>
                        </div>
                      </td>
                    ))}
                  <td className="p-2 text-center font-bold">00:00</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr className={`${mode === "light" ? "bg-[#ffffff]" : ""} font-bold border-t border-[#fff]`}>
                {/* Empty cells for first five columns */}
                <td colSpan={5} className="p-2 border-r border-[#fff]">
                  <button
                    onClick={addRow}
                    className="text-[#0088ff] bg-[#e5f3ff] p-2 rounded-md"
                  >
                    Add Row
                  </button>
                </td>

                {/* Total label cell */}
                <td className="p-2 text-left font-semibold border-r border-[#fff]">
                  Total
                </td>

                {/* Total values aligned with day columns */}
                {Array(7)
                  .fill(0)
                  .map((_, index) => (
                    <td key={index} className="border-r border-[#fff]">
                      <div className="p-2 text-left text-[14px] ms-2 font-[500]">
                        00:00
                      </div>
                    </td>
                  ))}
                {/* Grand Total column */}
                <td className="font-[500] p-2 text-center">00:00</td>
              </tr>
            </tfoot>
          </table>

                    {/* Modal */}
                    {modalVisible && (
            <div
              className={`absolute ${mode === "light" ? "bg-white" :"bg-[#141414]"} z-50 shadow-lg rounded-md border border-gray-200 w-[500px] flex`}
              style={{ top: modalPosition.top, left: modalPosition.left }}
            >
              {/* Sidebar for Dates */}
              <div className={`w-[180px] ${mode === "light" ? "bg-[#f5f5f5]":"bg-[]#141414"} border-r border-gray-300`}>
                {dates.map((date) => (
                  <div
                    key={date}
                    className={`p-2 text-sm cursor-pointer ${
                      selectedModalDate === date
                        ? "bg-[#007bff] text-white font-bold"
                        : `${mode === "light"?"hover:bg-gray-200":""}`
                    }`}
                    onClick={() => setSelectedModalDate(date)}
                  >
                    {date}
                  </div>
                ))}
              </div>

              {/* Description & Button */}
              <div className="w-[300px] p-2">
                <Textarea
                  className="w-full p-2  border rounded text-sm"
                  placeholder="Enter Description"
                  minRows="8"
                ></Textarea>
                <button
                  className="bg-[#0088FF] text-[12px] text-white px-2 py-1 mt-2 rounded "
                  onClick={() => setModalVisible(false)}
                >
                  Confirm
                </button>
              </div>
            </div>
          )}
        </div>
      </DialogContent>

      {/* Bottom Sticky Footer */}
      <div
        className={`sticky bottom-0 ${
          mode === "light" ? "bg-[#ffffff]" : "bg-[#141414]"
        }  p-4 border-t flex justify-between items-center`}
      >
        <div className="flex gap-2">
          <Button variant="contained" sx={{ backgroundColor: "#0088ff" }}>
            Save
          </Button>
          <Button
            variant="outlined"
            sx={{
              backgroundColor: mode === "light" ? "#fff" : "",
              color: mode === "light" ? "#000000" : "#ffffff",
              border: mode === "light" ? "1px solid #DCDCDC" : "",
            }}
            onClick={() => setWeeklyLogModal(false)}
          >
            Reset
          </Button>
          <Button
            variant="outlined"
            sx={{
              backgroundColor: mode === "light" ? "#fff" : "",
              color: mode === "light" ? "#000000" : "#ffffff",
              border: mode === "light" ? "1px solid #DCDCDC" : "",
            }}
            onClick={() => setWeeklyLogModal(false)}
          >
            Cancel
          </Button>
        </div>

        <div className="text-sm text-gray-500 flex gap-2">
          <span className="border-l-4 pl-3 border-[#e05654]">Unpaid Leave</span>
          <span className="border-l-4 pl-3 border-[#cb3888]">Absent</span>
          <span className="border-l-4 pl-3 border-[#ee9307]">Paid Leave</span>
          <span className="border-l-4 pl-3 border-[#d2bb14]">Weekend</span>
          <span className="border-l-4 pl-3 border-[#ab4afc]">On Duty</span>
          <span className="border-l-4 pl-1 border-[#65d4fb]">Holiday</span>
        </div>
      </div>
    </Dialog>
  );
};

const SemiMonthlyLog = ({
  semiMonthlyLogModal,
  setSemiMonthlyLogModal,
  setWeeklyLogModal,
  setMonthlyLogModal,
  setDailyLogModal,
}) => {
  const { mode } = useTheme();
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("all");
  const [filterModal, setFilterModal] = useState(false);
  const [createTimesheetModal, setCreateTimesheetModal] = useState(false);
  const [rows, setRows] = useState([1, 2, 3, 4, 5]);
  const [billable, setBillable] = useState("Billable");
  const [openCalendar, setOpenCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const startOfWeek = selectedDate.startOf("isoWeek"); // Monday
  const endOfWeek = startOfWeek.add(6, "day"); // Sunday
  const formattedStart = startOfWeek.format("DD/MM/YYYY");
  const formattedEnd = endOfWeek.format("DD/MM/YYYY");
  const [selectedModalDate, setSelectedModalDate] = useState("Mar 09");

  const addRow = () => {
    setRows([...rows, rows.length + 1]);
  };

  const handlePrevWeek = () => {
    setSelectedDate(selectedDate.subtract(7, "day"));
  };

  const handleNextWeek = () => {
    setSelectedDate(selectedDate.add(7, "day"));
  };

  const [modalVisible, setModalVisible] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });

  const handleIconClick = (event) => {
    const rect = event.currentTarget.getBoundingClientRect();
    setModalPosition({
      top: rect.top + window.scrollY + 20,
      left: rect.left + window.scrollX,
    });
    setModalVisible(true);
  };

  const dates = [
    "Mar 09",
    "Mar 10",
    "Mar 11",
    "Mar 12",
    "Mar 13",
    "Mar 14",
    "Mar 15",
  ];

  const handleModalOpen = (value) => {
    if (value === "all") setDailyLogModal(true);
    else if (value === "draft") setWeeklyLogModal(true);
    else if (value === "pending") setSemiMonthlyLogModal(true);
    else if (value === "approved") setMonthlyLogModal(true);
  };

  return (
    <Dialog
      open={semiMonthlyLogModal}
      onClose={() => setSemiMonthlyLogModal(false)}
      fullScreen
    >
      {/* Top Sticky Header */}
      <div
        className={`sticky flex justify-between items-center top-0 ${
          mode === "light" ? "bg-[#ffffff]" : "bg-[#141414]"
        }  z-10 py-4 px-4 shadow-md`}
      >
        <div className="flex items-center gap-2 ">
          <div
            className={`${
              mode === "light" ? "bg-[#dcdcdc]" : ""
            } p-2 rounded-md cursor-pointer`}
            onClick={() => setSemiMonthlyLogModal(false)}
          >
            <IoArrowBackOutline />
          </div>
          <img
            src="/images/no-user.png"
            alt=""
            width={"38px"}
            height={"38px"}
            className="rounded"
          />
          <span className="font-[500] text-[14px]">1 - tanya Gwilsbry</span>
        </div>
        <div
          className={`${
            mode === "light" ? "bg-[#dcdcdc]" : ""
          } p-2 rounded-md cursor-pointer`}
          onClick={() => setSemiMonthlyLogModal(false)}
        >
          <CgClose size={"16px"} />
        </div>
      </div>

      {/* Main Content */}
      <DialogContent className={`p-4 ${mode === "light" ? "bg-[#edf0f4]":"bg-[#141414]"}`}>
        <div className="flex w-full justify-between items-center">
          <div className="flex-1"></div>

          <div className="flex flex-1 relative justify-center items-center gap-2">
            {/* Previous Week Button */}
            <div
              style={{
                backgroundColor: mode === "dark" ? "#171717" : "white",
              }}
              className="flex items-center gap-2 p-2 rounded-md"
            >
              <IoIosArrowBack
                fontSize="small"
                className="cursor-pointer hover:text-gray-600"
                onClick={handlePrevWeek}
                color="#398aff"
              />

              {/* Calendar Icon and Date Range Text */}
              <Box
                className={`relative flex items-center rounded-md cursor-pointer ${
                  mode === "dark" ? "hover:bg-[#191919]" : "hover:bg-gray-200"
                }`}
                onClick={() => setOpenCalendar(!openCalendar)}
              >
                <IoCalendarOutline size="18px" />
              </Box>

              {/* Next Week Button */}
              <IoIosArrowForward
                fontSize="small"
                className="cursor-pointer hover:text-gray-600"
                onClick={handleNextWeek}
                color="#398aff"
              />
            </div>

            <span className="text-sm font-medium">
              {formattedStart} - {formattedEnd}
            </span>

            {/* Calendar Popup */}
            {openCalendar && (
              <Box className={`absolute top-8 z-10 shadow-md border rounded-md ${mode === "light" ? "bg-white" :"bg-[#141414]"}`}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateCalendar
                    value={selectedDate}
                    onChange={(date) => {
                      setSelectedDate(date);
                      setOpen(false);
                    }}
                  />
                </LocalizationProvider>
              </Box>
            )}
          </div>

          <div className="flex flex-1 gap-3 justify-end items-center mb-[30px]">
            <FormControl sx={{ width: "200px" }}>
              <Select
                value={selectedValue}
                onChange={(e) => {
                  setSelectedValue(e.target.value);
                  handleModalOpen(e.target.value); // Modal open karne ke liye function call
                }}
                displayEmpty
                sx={{
                  height: "40px",
                  backgroundColor: mode === "light" ? "white":"",
                  outline: "none",
                }}
              >
                <MenuItem value="all">Daily Log</MenuItem>
                <MenuItem value="draft">Weekly Log</MenuItem>
                <MenuItem value="pending">Semi Monthly Log</MenuItem>
                <MenuItem value="approved">Monthly Log</MenuItem>
              </Select>
            </FormControl>
            <Button
              variant="outlined"
              sx={{
                fontSize: { xs: "10px", md: "14px" },
                backgroundColor: mode === "light" ?"#ffffff" : "", 
              }}
            >
              Clone
            </Button>
          </div>
        </div>

        <div className="w-full overflow-x-auto">
          <table className="w-full border-collapse text-[#666] text-sm">
            <thead className= {`text-left ${mode === "light" ? "bg-[#e5e5f0]" : "bg-[#141414]"}`}>
              <tr>
                <td className="p-2 font-[500] text-[14px] border-r border-[#fff]">
                  S.No
                </td>
                <td className="p-2 font-[500] text-[14px] border-r border-[#fff]">
                  Project Name
                </td>
                <td className="p-2 font-[500] text-[14px] border-r border-[#fff]">
                  Job Name
                </td>
                <td className="p-2 font-[500] text-[14px] border-r border-[#fff]">
                  Work Item
                </td>
                <td className="p-2 border-r border-[#fff]"></td>
                <td className="p-2 font-[500] text-[14px] border-r border-[#fff]">
                  Billable Status
                </td>
                {[
                  "Mar 01 Sat",
                  "Mar 02 Sun",
                  "Mar 03 Mon",
                  "Mar 04 Tue",
                  "Mar 05 Wed",
                  "Mar 06 Thu",
                  "Mar 07 Fri",
                  "Mar 08 Sat",
                  "Mar 09 Sun",
                  "Mar 10 Mon",
                  "Mar 11 Tue",
                  "Mar 12 Wed",
                  "Mar 13 Thu",
                  "Mar 14 Fri",
                  "Mar 15 Sat",
                ].map((day) => (
                  <td
                    key={day}
                    className="px-4 py-4 font-[500] text-[14px] border-r border-[#fff]"
                  >
                    {day}
                  </td>
                ))}
                <td className="p-2 text-center font-[500] text-[14px]">
                  Total
                </td>
              </tr>
            </thead>
            <tbody>
              {rows.map((num) => (
                <tr key={num} 
                className={`${mode === "light"?"bg-[#ffffff]":"bg-[#141414]"} border-b border-[#fff]`}>
                  <td className="p-2 border-r border-[#fff]">{num}</td>
                  <td className="w-[250px] p-2 border-r border-[#fff]">
                    <div className="w-[150px]">
                      <SelectProject />
                    </div>
                  </td>
                  <td className="w-[205px] p-2 border-r border-[#fff]">
                    <div className="w-[150px]">
                      <Tooltip title="select job">
                        <SelectJob />
                      </Tooltip>
                    </div>
                  </td>
                  <td className="p-2 w-[205px] border-r border-[#fff]">
                    <div className="w-[150px]">
                      <input
                        type="text"
                        className = {`border rounded p-1 w-full outline-none ${mode === "light" ? "bg-[#fff]" : "bg-transparent"}`}

                      />
                    </div>
                  </td>

                  <td className="p-2 w-[70px] text-center border-r border-[#fff]">
                    <div
                      className="w-[50px] flex justify-center cursor-pointer items-center"
                      onClick={handleIconClick}
                    >
                      <MdOutlineDescription size={"16px"} />
                    </div>
                  </td>

                  <td className="p-2 w-[205px] border-r border-[#fff]">
                    <div className="w-[150px]">
                      <Select
                        value={billable}
                        onChange={(e) => setBillable(e.target.value)}
                        fullWidth
                        sx={{ backgroundColor: mode === "light" ? "#fff" :  ""}}
                        size="small"
                      >
                        <MenuItem value="Billable">Billable</MenuItem>
                        <MenuItem value="Non-Billable">Non-Billable</MenuItem>
                      </Select>
                    </div>
                  </td>
                  {Array(15)
                    .fill(0)
                    .map((_, index) => {
                      const date = new Date(2025, 2, index + 1); // March 1 to March 15 (month index starts from 0)
                      const isSunday = date.getDay() === 0; // Sunday check

                      return (
                        <td
                          key={index}
                          className="text-center border-r border-[#fff]"
                        >
                          <div
                           className={`text-[#bbb6be] flex justify-center items-center h-[53px] text-[14px] ${
                            isSunday ? `${mode === "light"?"bg-[#e8e5c7] ":""} w-[120px]` : "w-[100px]"
                          }`}
                          >
                            <div
           className={`${mode === "light"?"bg-[#fff]":""} py-[7px] px-[10px] w-[80px] ms-2 rounded-[4px] 
           text-[14px] border border-[#DCDCDC] pe-4`}>
                              00:00
                            </div>
                          </div>
                        </td>
                      );
                    })}
                  <td className="p-2 text-center font-bold">00:00</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr className={`${mode === "light" ? "bg-[#ffffff]":""} font-bold border-t border-[#fff]`}>
                {/* Empty cells for first five columns */}
                <td colSpan={5} className="p-2 border-r border-[#fff]">
                  <button
                    onClick={addRow}
                    className="text-[#0088ff] bg-[#e5f3ff] p-2 rounded-md"
                  >
                    Add Row
                  </button>
                </td>

                {/* Total label cell */}
                <td className="p-2 text-left font-semibold border-r border-[#fff]">
                  Total
                </td>

                {/* Total values aligned with day columns */}
                {Array(15)
                  .fill(0)
                  .map((_, index) => (
                    <td key={index} className="border-r border-[#fff]">
                      <div className="p-2 text-left text-[14px] ms-2 font-[500]">
                        00:00
                      </div>
                    </td>
                  ))}
                {/* Grand Total column */}
                <td className="font-[500] p-2 text-center">00:00</td>
              </tr>
            </tfoot>
          </table>

          {/* Modal */}
          {modalVisible && (
            <div
              className={`absolute ${mode === "light" ? "bg-white" :"bg-[#141414]"} z-50 shadow-lg rounded-md border border-gray-200 w-[500px] flex`}
              style={{ top: modalPosition.top, left: modalPosition.left }}
            >
              {/* Sidebar for Dates */}
              <div className={`w-[180px] ${mode === "light" ? "bg-[#f5f5f5]":"bg-[]#141414"} border-r border-gray-300`}>
                {dates.map((date) => (
                  <div
                    key={date}
                    className={`p-2 text-sm cursor-pointer ${
                      selectedModalDate === date
                        ? "bg-[#007bff] text-white font-bold"
                        : `${mode === "light"?"hover:bg-gray-200":""}`
                    }`}
                    onClick={() => setSelectedModalDate(date)}
                  >
                    {date}
                  </div>
                ))}
              </div>

              {/* Description & Button */}
              <div className="w-[300px] p-2">
                <Textarea
                  className="w-full p-2  border rounded text-sm"
                  placeholder="Enter Description"
                  minRows="8"
                ></Textarea>
                <button
                  className="bg-[#0088FF] text-[12px] text-white px-2 py-1 mt-2 rounded "
                  onClick={() => setModalVisible(false)}
                >
                  Confirm
                </button>
              </div>
            </div>
          )}
        </div>
      </DialogContent>

      {/* Bottom Sticky Footer */}
      <div
        className={`sticky bottom-0 ${
          mode === "light" ? "bg-[#ffffff]" : "bg-[#141414]"
        }  p-4 border-t flex justify-between items-center`}
      >
        <div className="flex gap-2">
          <Button variant="contained" sx={{ backgroundColor: "#0088ff" }}>
            Save
          </Button>
          <Button
            variant="outlined"
            sx={{
              backgroundColor: mode === "light" ? "#fff" : "",
              color: mode === "light" ? "#000000" : "#ffffff",
              border: mode === "light" ? "1px solid #DCDCDC" : "",
            }}
            onClick={() => setSemiMonthlyLogModal(false)}
          >
            Reset
          </Button>
          <Button
            variant="outlined"
            sx={{
              backgroundColor: mode === "light" ? "#fff" : "",
              color: mode === "light" ? "#000000" : "#ffffff",
              border: mode === "light" ? "1px solid #DCDCDC" : "",
            }}
            onClick={() => setSemiMonthlyLogModal(false)}
          >
            Cancel
          </Button>
        </div>

        <div className="text-sm text-gray-500 flex gap-2">
          <span className="border-l-4 pl-3 border-[#e05654]">Unpaid Leave</span>
          <span className="border-l-4 pl-3 border-[#cb3888]">Absent</span>
          <span className="border-l-4 pl-3 border-[#ee9307]">Paid Leave</span>
          <span className="border-l-4 pl-3 border-[#d2bb14]">Weekend</span>
          <span className="border-l-4 pl-3 border-[#ab4afc]">On Duty</span>
          <span className="border-l-4 pl-1 border-[#65d4fb]">Holiday</span>
        </div>
      </div>
    </Dialog>
  );
};

const MonthlyLog = ({
  monthlyLogModal,
  setMonthlyLogModal,
  setDailyLogModal,
  setWeeklyLogModal,
  setSemiMonthlyLogModal,
}) => {
  const { mode } = useTheme();
  const [selectedValue, setSelectedValue] = useState("all");
  const [rows, setRows] = useState([1, 2, 3, 4, 5]);
  const [billable, setBillable] = useState("Billable");
  const [openCalendar, setOpenCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const startOfWeek = selectedDate.startOf("isoWeek"); // Monday
  const endOfWeek = startOfWeek.add(6, "day"); // Sunday
  const formattedStart = startOfWeek.format("DD/MM/YYYY");
  const formattedEnd = endOfWeek.format("DD/MM/YYYY");
  const [selectedModalDate, setSelectedModalDate] = useState("Mar 09");

  const addRow = () => {
    setRows([...rows, rows.length + 1]);
  };

  const handlePrevWeek = () => {
    setSelectedDate(selectedDate.subtract(7, "day"));
  };

  const handleNextWeek = () => {
    setSelectedDate(selectedDate.add(7, "day"));
  };

  const [modalVisible, setModalVisible] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });

  const handleIconClick = (event) => {
    const rect = event.currentTarget.getBoundingClientRect();
    setModalPosition({
      top: rect.top + window.scrollY + 20,
      left: rect.left + window.scrollX,
    });
    setModalVisible(true);
  };

  const dates = [
    "Mar 09",
    "Mar 10",
    "Mar 11",
    "Mar 12",
    "Mar 13",
    "Mar 14",
    "Mar 15",
  ];

  const days = Array.from({ length: 30 }, (_, i) => {
    const date = new Date(2025, 2, i + 1); // March 1 to March 30
    const dayName = date.toLocaleDateString("en-US", { weekday: "short" }); // Short weekday name
    const monthDay = date.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "short",
    }); // e.g., Mar 01
    return `${monthDay} ${dayName}`;
  });

  const handleModalOpen = (value) => {
    if (value === "all") setDailyLogModal(true);
    else if (value === "draft") setWeeklyLogModal(true);
    else if (value === "pending") setSemiMonthlyLogModal(true);
    else if (value === "approved") setMonthlyLogModal(true);
  };

  return (
    <Dialog
      open={monthlyLogModal}
      onClose={() => setMonthlyLogModal(false)}
      fullScreen
    >
      {/* Top Sticky Header */}
      <div
        className={`sticky flex justify-between items-center top-0 ${
          mode === "light" ? "bg-[#ffffff]" : "bg-[#141414]"
        }  z-10 py-4 px-4 shadow-md`}
      >
        <div className="flex items-center gap-2 ">
          <div
            className={`${
              mode === "light" ? "bg-[#dcdcdc]" : ""
            } p-2 rounded-md cursor-pointer`}
            onClick={() => setMonthlyLogModal(false)}
          >
            <IoArrowBackOutline />
          </div>
          <img
            src="/images/no-user.png"
            alt=""
            width={"38px"}
            height={"38px"}
            className="rounded"
          />
          <span className="font-[500] text-[14px]">1 - tanya Gwilsbry</span>
        </div>
        <div
          className={`${
            mode === "light" ? "bg-[#dcdcdc]" : ""
          } p-2 rounded-md cursor-pointer`}
          onClick={() => setMonthlyLogModal(false)}
        >
          <CgClose size={"16px"} />
        </div>
      </div>

      {/* Main Content */}
      <DialogContent className={`p-4 ${mode === "light" ? "bg-[#edf0f4]":"bg-[#141414]"}`}>
        <div className="flex w-full justify-between items-center">
          <div className="flex-1"></div>

          <div className="flex flex-1 relative justify-center items-center gap-2">
            {/* Previous Week Button */}
            <div
              style={{
                backgroundColor: mode === "dark" ? "#171717" : "white",
              }}
              className="flex items-center gap-2 p-2 rounded-md"
            >
              <IoIosArrowBack
                fontSize="small"
                className="cursor-pointer hover:text-gray-600"
                onClick={handlePrevWeek}
                color="#398aff"
              />

              {/* Calendar Icon and Date Range Text */}
              <Box
                className={`relative flex items-center rounded-md cursor-pointer ${
                  mode === "dark" ? "hover:bg-[#191919]" : "hover:bg-gray-200"
                }`}
                onClick={() => setOpenCalendar(!openCalendar)}
              >
                <IoCalendarOutline size="18px" />
              </Box>

              {/* Next Week Button */}
              <IoIosArrowForward
                fontSize="small"
                className="cursor-pointer hover:text-gray-600"
                onClick={handleNextWeek}
                color="#398aff"
              />
            </div>

            <span className="text-sm font-medium">
              {formattedStart} - {formattedEnd}
            </span>

            {/* Calendar Popup */}
            {openCalendar && (
              <Box className={`absolute top-8 z-10 shadow-md border rounded-md ${mode === "light" ? "bg-white" :"bg-[#141414]"}`}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateCalendar
                    value={selectedDate}
                    onChange={(date) => {
                      setSelectedDate(date);
                      setOpenCalendar(false);
                    }}
                  />
                </LocalizationProvider>
              </Box>
            )}
          </div>

          <div className="flex flex-1 gap-3 justify-end items-center mb-[30px]">
            <FormControl sx={{ width: "200px" }}>
              <Select
                value={selectedValue}
                onChange={(e) => {
                  setSelectedValue(e.target.value);
                  handleModalOpen(e.target.value); // Modal open karne ke liye function call
                }}
                displayEmpty
                sx={{
                  height: "40px",
                  backgroundColor: mode === "light" ? "white" :"",
                  outline: "none",
                }}
              >
                <MenuItem value="all">Daily Log</MenuItem>
                <MenuItem value="draft">Weekly Log</MenuItem>
                <MenuItem value="pending">Semi Monthly Log</MenuItem>
                <MenuItem value="approved">Monthly Log</MenuItem>
              </Select>
            </FormControl>
            <Button
              variant="outlined"
              sx={{
                fontSize: { xs: "10px", md: "14px" },
                backgroundColor: mode === "light" ?"#ffffff":"",
              }}
            >
              Clone
            </Button>
          </div>
        </div>

        <div className="w-full overflow-x-auto">
          <table className="w-full border-collapse text-[#666] text-sm">
            <thead className= {`text-left ${mode === "light" ? "bg-[#e5e5f0]" : "bg-[#141414]"}`}>
              <tr>
                <td className="p-2 font-[500] text-[14px] border-r border-[#fff]">
                  S.No
                </td>
                <td className="p-2 font-[500] text-[14px] border-r border-[#fff]">
                  Project Name
                </td>
                <td className="p-2 font-[500] text-[14px] border-r border-[#fff]">
                  Job Name
                </td>
                <td className="p-2 font-[500] text-[14px] border-r border-[#fff]">
                  Work Item
                </td>
                <td className="p-2 border-r border-[#fff]"></td>
                <td className="p-2 font-[500] text-[14px] border-r border-[#fff]">
                  Billable Status
                </td>
                {days.map((day) => (
                  <td
                    key={day}
                    className="px-4 py-4 font-[500] text-[14px] border-r border-[#fff]"
                  >
                    {day}
                  </td>
                ))}
                <td className="p-2 text-center font-[500] text-[14px]">
                  Total
                </td>
              </tr>
            </thead>
            <tbody>
              {rows.map((num) => (
                <tr key={num} className={`${mode === "light" ?"bg-[#ffffff]" :""} border-b border-[#fff]`}>
                  <td className="p-2 border-r border-[#fff]">{num}</td>
                  <td className="w-[250px] p-2 border-r border-[#fff]">
                    <div className="w-[150px]">
                      <SelectProject />
                    </div>
                  </td>
                  <td className="w-[205px] p-2 border-r border-[#fff]">
                    <div className="w-[150px]">
                      <Tooltip title="select job">
                        <SelectJob />
                      </Tooltip>
                    </div>
                  </td>
                  <td className="p-2 w-[205px] border-r border-[#fff]">
                    <div className="w-[150px]">
                      <input
                        type="text"
                        className = {`border rounded p-1 w-full outline-none ${mode === "light" ? "bg-[#fff]" : "bg-transparent"}`}
                      />
                    </div>
                  </td>

                  <td className="p-2 w-[70px] text-center border-r border-[#fff]">
                    <div
                      className="w-[50px] flex justify-center cursor-pointer items-center"
                      onClick={handleIconClick}
                    >
                      <MdOutlineDescription size={"16px"} />
                    </div>
                  </td>

                  <td className="p-2 w-[205px] border-r border-[#fff]">
                    <div className="w-[150px]">
                      <Select
                        value={billable}
                        onChange={(e) => setBillable(e.target.value)}
                        fullWidth
                        sx={{ backgroundColor: mode === "light" ? "#fff" :  ""}}
                        size="small"
                      >
                        <MenuItem value="Billable">Billable</MenuItem>
                        <MenuItem value="Non-Billable">Non-Billable</MenuItem>
                      </Select>
                    </div>
                  </td>
                  {Array(30)
                    .fill(0)
                    .map((_, index) => {
                      const date = new Date(2025, 2, index + 1); // March 1 to March 15 (month index starts from 0)
                      const isSunday = date.getDay() === 0; // Sunday check

                      return (
                        <td
                          key={index}
                          className="text-center border-r border-[#fff]"
                        >
                          <div
                            className={`text-[#bbb6be] flex justify-center items-center h-[53px] text-[14px] ${
                              isSunday ? `${mode === "light"?"bg-[#e8e5c7] ":""} w-[120px]` : "w-[100px]"
                            }`}
                          >
                            <div className={`${mode === "light" ? "bg-[#fff]" :""} py-[7px] px-[10px] w-[80px] ms-2 rounded-[4px] text-[14px] border border-[#DCDCDC] pe-4`}>
                              00:00
                            </div>
                          </div>
                        </td>
                      );
                    })}
                  <td className="p-2 text-center font-bold">00:00</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr className={`${mode === "light" ? "bg-[#ffffff]" : ""} font-bold border-t border-[#fff]`}>
                {/* Empty cells for first five columns */}
                <td colSpan={5} className="p-2 border-r border-[#fff]">
                  <button
                    onClick={addRow}
                    className="text-[#0088ff] bg-[#e5f3ff] p-2 rounded-md"
                  >
                    Add Row
                  </button>
                </td>

                {/* Total label cell */}
                <td className="p-2 text-left font-semibold border-r border-[#fff]">
                  Total
                </td>

                {/* Total values aligned with day columns */}
                {Array(30)
                  .fill(0)
                  .map((_, index) => (
                    <td key={index} className="border-r border-[#fff]">
                      <div className="p-2 text-left text-[14px] ms-2 font-[500]">
                        00:00
                      </div>
                    </td>
                  ))}
                {/* Grand Total column */}
                <td className="font-[500] p-2 text-center">00:00</td>
              </tr>
            </tfoot>
          </table>

          {/* Modal */}
          {modalVisible && (
            <div
              className={`absolute ${mode === "light" ? "bg-white" :"bg-[#141414]"} z-50 shadow-lg rounded-md border border-gray-200 w-[500px] flex`}
              style={{ top: modalPosition.top, left: modalPosition.left }}
            >
              {/* Sidebar for Dates */}
              <div className={`w-[180px] ${mode === "light" ? "bg-[#f5f5f5]" :""} border-r border-gray-300`}>
                {dates.map((date) => (
                  <div
                    key={date}
                    className={`p-2 text-sm cursor-pointer ${
                      selectedModalDate === date
                        ? "bg-[#007bff] text-white font-bold"
                        : "hover:bg-gray-200"
                    }`}
                    onClick={() => setSelectedModalDate(date)}
                  >
                    {date}
                  </div>
                ))}
              </div>

              {/* Description & Button */}
              <div className="w-[300px] p-2">
                <Textarea
                  className="w-full p-2  border rounded text-sm"
                  placeholder="Enter Description"
                  minRows="8"
                ></Textarea>
                <button
                  className="bg-[#0088FF] text-[12px] text-white px-2 py-1 mt-2 rounded "
                  onClick={() => setModalVisible(false)}
                >
                  Confirm
                </button>
              </div>
            </div>
          )}
        </div>
      </DialogContent>

      {/* Bottom Sticky Footer */}
      <div
        className={`sticky bottom-0 ${
          mode === "light" ? "bg-[#ffffff]" : "bg-[#141414]"
        }  p-4 border-t flex justify-between items-center`}
      >
        <div className="flex gap-2">
          <Button variant="contained" sx={{ backgroundColor: "#0088ff" }}>
            Save
          </Button>
          <Button
            variant="outlined"
            sx={{
              backgroundColor: mode === "light" ? "#fff" : "",
              color: mode === "light" ? "#000000" : "#ffffff",
              border: mode === "light" ? "1px solid #DCDCDC" : "",
            }}
            onClick={() => setMonthlyLogModal(false)}
          >
            Reset
          </Button>
          <Button
            variant="outlined"
            sx={{
              backgroundColor: mode === "light" ? "#fff" : "",
              color: mode === "light" ? "#000000" : "#ffffff",
              border: mode === "light" ? "1px solid #DCDCDC" : "",
            }}
            onClick={() => setMonthlyLogModal(false)}
          >
            Cancel
          </Button>
        </div>

        <div className="text-sm text-gray-500 flex gap-2">
          <span className="border-l-4 pl-3 border-[#e05654]">Unpaid Leave</span>
          <span className="border-l-4 pl-3 border-[#cb3888]">Absent</span>
          <span className="border-l-4 pl-3 border-[#ee9307]">Paid Leave</span>
          <span className="border-l-4 pl-3 border-[#d2bb14]">Weekend</span>
          <span className="border-l-4 pl-3 border-[#ab4afc]">On Duty</span>
          <span className="border-l-4 pl-1 border-[#65d4fb]">Holiday</span>
        </div>
      </div>
    </Dialog>
  );
};

export default function TimeLogs() {
  const [logTimeModal, setLogTimeModal] = useState(false);
  const [dailyLogModal, setDailyLogModal] = useState(false);
  const [weeklyLogModal, setWeeklyLogModal] = useState(false);
  const [semiMonthlyLogModal, setSemiMonthlyLogModal] = useState(false);
  const [monthlyLogModal, setMonthlyLogModal] = useState(false);

  const { mode } = useTheme();
  const [switchScreen, setSwitchScreen] = useState({
    primary: "tableView",
  });
  const [filterModal, setFilterModal] = useState(false);

  const [menuAnchor, setMenuAnchor] = useState(null);
  const isMenuopen = menuAnchor;

  const [openCalendar, setOpenCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [selectedMonthDate, setSelectedMonthDate] = useState(dayjs());
  const startOfWeek = selectedDate.startOf("isoWeek"); // Monday
  const endOfWeek = startOfWeek.add(6, "day"); // Sunday
  const formattedStart = startOfWeek.format("DD/MM/YYYY");
  const formattedEnd = endOfWeek.format("DD/MM/YYYY");

  const handlePrevWeek = () => {
    setSelectedDate(selectedDate.subtract(7, "day"));
  };

  const handleNextWeek = () => {
    setSelectedDate(selectedDate.add(7, "day"));
  };

  const handlePrevMonth = () => {
    setSelectedMonthDate(selectedMonthDate.subtract(1, "month"));
  };

  const handleNextMonth = () => {
    setSelectedMonthDate(selectedMonthDate.add(1, "month"));
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState("Log Time");

  const open = anchorEl;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (option) => {
    setSelectedOption(option);
    setAnchorEl(null);
  };

  return (
    <div className="w-full relative min-h-80 flex flex-col">
      <div className="flex w-full justify-between items-center">
        <div className="flex flex-1"></div>

        {switchScreen.primary === "tableView" ? (
          <div className="flex flex-1 relative items-center justify-center gap-2">
            {/* Previous Week Button */}
            <div
              className={`flex items-center gap-2 ${
                mode === "light" ? "bg-white" : "bg-[#141414]"
              } p-2 rounded-md`}
            >
              <IoIosArrowBack
                fontSize="small"
                className="cursor-pointer hover:text-gray-600"
                onClick={handlePrevWeek}
                color="#398aff"
              />

              {/* Calendar Icon and Date Range Text */}
              <Box
                className="relative flex items-center rounded-md cursor-pointer hover:bg-gray-200"
                onClick={() => setOpenCalendar((prev) => !prev)}
              >
                <IoCalendarOutline size="18px" className="text-gray-600" />
              </Box>

              {/* Next Week Button */}
              <IoIosArrowForward
                fontSize="small"
                className="cursor-pointer hover:text-gray-600"
                onClick={handleNextWeek}
                color="#398aff"
              />
            </div>

            <span className="text-sm font-medium">
              {formattedStart} - {formattedEnd}
            </span>

            {/* Calendar Popup */}
            {openCalendar && (
              <Box className={`absolute top-8 z-10 shadow-md border rounded-md ${mode === "light" ? "bg-white" :"bg-[#141414]"}`}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateCalendar
                    value={selectedDate}
                    onChange={(date) => {
                      setSelectedDate(date);
                      setOpenCalendar(false);
                    }}
                  />
                </LocalizationProvider>
              </Box>
            )}
          </div>
        ) : (
          <div className="flex flex-1 relative justify-center items-center gap-2">
            {/* Navigation Controls */}
            <div className={`flex items-center gap-2 ${mode === "light" ? "bg-white" : ""} p-2 rounded-md`}>
              <IoIosArrowBack
                fontSize="small"
                className="cursor-pointer hover:text-gray-600"
                onClick={handlePrevMonth}
              />

              <Box
                onClick={() => setOpenCalendar((prev) => !prev)}
                className="relative flex items-center rounded-md cursor-pointer hover:bg-gray-200"
              >
                <IoCalendarOutline size="18px" className="text-gray-600" />
              </Box>

              <IoIosArrowForward
                fontSize="small"
                className="cursor-pointer hover:text-gray-600"
                onClick={handleNextMonth}
              />
            </div>

            {/* Display Current Month and Year */}
            <span className="text-sm flex font-medium">
              {selectedMonthDate.format("MMMM YYYY")}
            </span>

            {/* Calendar Popup */}
            {openCalendar && (
              <Box className="absolute top-8 z-10 shadow-md border rounded-md "
              sx={{ backgroundColor: mode === "dark" ? "#171717" : "white" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateCalendar
                    value={selectedMonthDate}
                    onChange={(date) => {
                      setSelectedMonthDate(date);
                      setOpenCalendar(false);
                    }}
                  />
                </LocalizationProvider>
              </Box>
            )}
          </div>
        )}

        <div className="w-full flex-1 flex gap-3 sm:justify-end items-center">
          {switchScreen.primary === "tableView" ? (
            <>
              <div>
                <Button
                  variant="contained"
                  sx={{
                    fontSize: { xs: "12px", sm: "15px" },
                    height: "32px",
                    backgroundColor: "#0088ff",
                  }}
                >
                  {selectedOption}
                </Button>
                <Button
                  variant="contained"
                  onClick={handleClick}
                  sx={{
                    height: "32px",
                    minWidth: "30px",
                    padding: "0px",
                    borderRadius: "4px",
                    ml: "2px",
                    backgroundColor: "#0088ff",
                  }}
                >
                  <ArrowDropDownIcon />
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={() => setAnchorEl(null)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  PaperProps={{
                    style: { marginTop: "5px" },
                  }}
                >
                  <MenuItem onClick={() => setLogTimeModal(true)}>
                    Log Time
                  </MenuItem>
                  <MenuItem onClick={() => setDailyLogModal(true)}>
                    Daily Log
                  </MenuItem>
                  <MenuItem onClick={() => setWeeklyLogModal(true)}>
                    Weekly Log
                  </MenuItem>
                  <MenuItem onClick={() => setSemiMonthlyLogModal(true)}>
                    Semi Monthly Log
                  </MenuItem>
                  <MenuItem onClick={() => setMonthlyLogModal(true)}>
                    Monthly Log
                  </MenuItem>
                </Menu>
              </div>
            </>
          ) : (
            <></>
          )}

          <div className="flex items-center ">
            <Button
              variant={
                switchScreen.primary === "tableView" ? "contained" : "outlined"
              }
              onClick={() => {
                setSwitchScreen({ primary: "tableView" });
              }}
              title="Table View"
              sx={{
                height: "32px",
                minWidth: "38px",
                padding: "0px",
                borderRadius: "2px",
              }}
            >
              <IoList className="text-[20px] sm:text-2xl" />
            </Button>

            <Button
              variant={
                switchScreen.primary === "calendarView"
                  ? "contained"
                  : "outlined"
              }
              onClick={() => {
                setSwitchScreen({ primary: "calendarView" });
              }}
              title="Calendar View"
              sx={{
                height: "32px",
                minWidth: "38px",
                padding: "0px",
                borderRadius: "2px",
              }}
            >
              <IoCalendarOutline className="text-[21px] sm:text-2xl" />
            </Button>
          </div>

          <IoFilter
            size={"16px"}
            className="cursor-pointer"
            onClick={() => {
              setFilterModal(true);
            }}
          />

          <div>
            <HiDotsHorizontal
              size={"16px"}
              onClick={(event) => {
                setMenuAnchor(event.currentTarget);
              }}
              className="text-2xl cursor-pointer "
            />

            <Menu
              id="basic-menu"
              anchorEl={menuAnchor}
              open={isMenuopen}
              onClose={() => {
                setMenuAnchor(null);
              }}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem>
                <div className="flex flex-row gap-3 justify-between items-center">
                  <CiImport className="text-2xl" />
                  <h1>Import</h1>
                </div>
              </MenuItem>
              <MenuItem>
                <div className="flex flex-row gap-3 justify-between items-center">
                  <CiExport className="text-2xl" />
                  <h1>Export</h1>
                </div>
              </MenuItem>
            </Menu>
          </div>
        </div>
      </div>

      <div className="w-full mb-[80px]">
        {switchScreen.primary === "tableView" ? (
          <>
            <div className="pt-[30px]">
              <HomeTimeLogs />
            </div>
          </>
        ) : (
          <></>
        )}
        {switchScreen.primary === "calendarView" ? (
          <>
            <div className="w-full h-[60dvh] pt-[30px] overflow-scroll">
              <FullCalendar
                plugins={[dayGridPlugin]}
                initialView="dayGridMonth"
              />
            </div>
          </>
        ) : (
          <></>
        )}
      </div>

      <div
        className={`fixed bottom-0 left-0 right-[100px] w-full ${
          mode === "light" ? "bg-white" : " bg-[#141414]"
        } shadow-md h-[70px] py-2 flex justify-end items-center gap-6 border-t`}
      >
        {/* Total Hours */}
        <div className="flex flex-col items-center border-l-4 border-[#0088FF] gap-1 text-sm font-medium">
          <span className="text-[#0088FF]  pr-2 pl-2">00:00 Hrs</span>
          <span className="text-[14px]">Total</span>
        </div>

        {/* Submitted Hours */}
        <div className="flex flex-col border-l-4 border-[#0EBC6B] items-center gap-1 text-sm font-medium">
          <span className="text-[#0EBC6B]  pr-2 text-[14px] pl-2">
            00:00 Hrs
          </span>
          <span className="text-[14px]">Submitted</span>
        </div>

        {/* Not Submitted Button */}
        <button className="flex gap-1 me-[50px] text-sm font-medium px-4 py-1 rounded-md border">
          <div className="flex flex-col ">
            <span className="text-[#F1A82F] text-[14px]">00:00 Hrs</span>
            <span className=" text-[14px]">Not Submitted</span>
          </div>
          <span className="text-[#F1A82F]">{">"}</span>
        </button>
      </div>

      <FilterDrawer setFilterModal={setFilterModal} filterModal={filterModal} />

      {logTimeModal && (
        <LogTimeModal
          logTimeModal={logTimeModal}
          setLogTimeModal={setLogTimeModal}
        />
      )}

      {dailyLogModal && (
        <DailyLog
          dailyLogModal={dailyLogModal}
          setDailyLogModal={setDailyLogModal}
          setWeeklyLogModal={setWeeklyLogModal}
          setSemiMonthlyLogModal={setSemiMonthlyLogModal}
          setMonthlyLogModal={setMonthlyLogModal}
        />
      )}

      {weeklyLogModal && (
        <WeeklyLog
          weeklyLogModal={weeklyLogModal}
          setWeeklyLogModal={setWeeklyLogModal}
          setSemiMonthlyLogModal={setSemiMonthlyLogModal}
          setMonthlyLogModal={setMonthlyLogModal}
          setDailyLogModal={setDailyLogModal}
          // setDailyLogModal={setDailyLogModal}
        />
      )}

      {semiMonthlyLogModal && (
        <SemiMonthlyLog
          semiMonthlyLogModal={semiMonthlyLogModal}
          setSemiMonthlyLogModal={setSemiMonthlyLogModal}
          setMonthlyLogModal={setMonthlyLogModal}
          setDailyLogModal={setDailyLogModal}
          setWeeklyLogModal={setWeeklyLogModal}
        />
      )}

      {monthlyLogModal && (
        <MonthlyLog
          monthlyLogModal={monthlyLogModal}
          setMonthlyLogModal={setMonthlyLogModal}
          setDailyLogModal={setDailyLogModal}
          setWeeklyLogModal={setWeeklyLogModal}
          setSemiMonthlyLogModal={setSemiMonthlyLogModal}
        />
      )}
    </div>
  );
}