import React from "react";
import { Box, Card, Typography, Button, Divider } from "@mui/material";
import { blue, grey } from "@mui/material/colors";
import Norecentjob from "../../assets/offerLatter/rafiki.png";
import SearchIcon from "@mui/icons-material/Search"; // Replace with your custom icon if needed
import calander from "../../assets/Interductionimages/cuate.png";
import { useTheme } from "../../style/theme";

const Noleaveapplication = () => {
  let currentOrg = JSON.parse(localStorage.getItem("org"));
  const { mode } = useTheme()
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          padding: "10px",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          justifyContent: "center",

          borderRadius: "12px",
          color: "white",
          marginBottom: "30px",
          marginTop: "30px"
        }}
      >
        {/* Icon/Image Section */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",

          }}
        >
          <Box
            component="img"
            src={"/images/cuate.png"} // Replace with your actual image path
            alt="No job applications"
            sx={{ width: '278px', height: '150' }}
          />
        </Box>

        {/* Main Text */}
        <p
          style={{ marginBottom: 1, fontFamily: "sans-serif", fontSize: "22px", fontWeight: "600" }}
          className={`${mode === "light" ? "text-[#000]" : "text-[#fff]"} mt-2`}
        >
          No Recent Leave Application!
        </p>
        <p
          style={{ marginBottom: 4, fontSize: "16px" }}
          className={`${mode === "light" ? "text-[#000]" : "text-[#fff]"} mt-2 mb-2`}

        >
          You do not have any recent leave applications. {currentOrg?.type == "Owner" && <span> When you add employees
            and they apply for leaves, it will show here. </span>}
        </p>

        {/* <button className='bg-[#3767B1] rounded-md h-[36px] w-[139px] mx-auto mt-2'>Add Employee</button> */}
      </Box>
    </Box >
  );
};

export default Noleaveapplication;
