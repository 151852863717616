import { useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  useTheme,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import CustomDrawer from "../../../components/CustomDrawer";
import Grid from "@mui/material/Grid2";

export default function Delegation() {
  const mode = useTheme();
  const [selectedFormat, setSelectedFormat] = useState(
    "Delegator and Delegatee"
  );
  const [addDelegation, setAddDelegation] = useState(false);

  const addDelegationModalFields = [
    {
      type: "autocomplete",
      name: "delegator",
      label: "Delegator",
      options: ["Delegatee 1", "Delegatee 2"],
      defaultValue: "",
    },
    {
      type: "autocomplete",
      name: "delegatee",
      label: "Delegatee",
      options: ["Delegatee 1", "Delegatee 2"],
      defaultValue: "",
    },
    {
      type: "select",
      name: "type",
      label: "Type",
      options: [
        { label: "Temporary", value: "temporary" },
        { label: "Permanent", value: "permanent" },
      ],
      defaultValue: "temporary",
      custom: (formData, setFormData, field, index, handleChange) => {
        if (formData.type === "temporary") {
          return (
            <div className="w-full flex flex-row gap-3">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="From"
                  sx={{ width: "100%" }}
                  value={formData.from ? dayjs(formData.from) : null}
                  onChange={(value) => handleChange("from")(null, value)}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth margin="normal" />
                  )}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="To"
                  sx={{ width: "100%" }}
                  value={formData.to ? dayjs(formData.to) : null}
                  onChange={(value) => handleChange("to")(null, value)}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth margin="normal" />
                  )}
                />
              </LocalizationProvider>
            </div>
          );
        }
        return null;
      },
    },
    {
      type: "radio",
      name: "notification",
      label: "Notification",
      options: [
        { label: "Delegator And Delegatee", value: "delegatorAndDelegatee" },
        { label: "Delegatee", value: "delegatee" },
      ],
      defaultValue: "delegatorAndDelegatee",
    },
    {
      type: "textarea",
      label: "Description",
      name: "description",
      defaultValue: "",
    },
  ];
  const handleAddDelegationFormSubmit = (data) => {
    console.log("Add Delegation Form :- ", data);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid
          size={{ xs: 12 }}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid",
            borderRadius: "8px",
            p: 3,
            textAlign: "center",
            height: "100%",
            minHeight: "200px",
          }}
        >
          <img src="/images/no_timeLogs.png" />
          <p className="text-lg font-semibold mt-4 ">
            No delegations added currently.
          </p>
          <h1 className="mt-4">
            Delegation lets you reassign approvals from one employee to another
            for a specific time frame.
          </h1>
          <button
            className="px-3 py-1.5 mt-4 bg-[#0088FF] text-sm rounded-md text-white "
            onClick={() => setAddDelegation(true)}
          >
            Add Delegation
          </button>
        </Grid>
      </Grid>

      <CustomDrawer
        isScrollable={true}
        open={addDelegation}
        onClose={() => setAddDelegation(false)}
        paperProps="w-[80%] m-1 md:w-[500px] lg:w-[800px] rounded-lg"
        mode={mode}
        headerTitle={"Setup Delegation"}
      >
        <Box sx={{ background: "#fff", padding: 2, borderRadius: 2 }}>
          <Grid container spacing={2}>
            <Grid  size={{ xs: 12 }}>
              <p style={{ marginBottom: "8px" }}>Delegator</p>
              <Autocomplete
                sx={{ width: "100%" }}
                size="small"
                options={["saad", "bisham"]}
                renderInput={(params) => (
                  <TextField {...params} label={"Select"} />
                )}
              />
            </Grid>
            <Grid  size={{ xs: 12 }}>
              <p style={{ marginBottom: "8px" }}>Delegatee</p>
              <Autocomplete
                sx={{ width: "100%" }}
                size="small"
                options={["saad", "bisham"]}
                renderInput={(params) => (
                  <TextField {...params} label={"Select"}  />
                )}
              />
            </Grid>
            <Grid  size={{ xs: 12 }}>
              <p style={{ marginBottom: "8px" }}>Type</p>
              <Autocomplete
                sx={{ width: "100%" }}
                size="small"
                options={["Temporary", "Permanent"]}
                renderInput={(params) => (
                  <TextField {...params} label={"Select"} />
                )}
              />
            </Grid>
            <Grid  size={{ xs: 12 }}>
              <p style={{ marginBottom: "8px" }}>Date Range</p>
              <div className="w-full flex flex-row gap-3">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="From"
                  sx={{ width: "100%" }}
                  value={null}
                  // onChange={(value) => handleChange("from")(null, value)}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth margin="normal" />
                  )}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="To"
                  sx={{ width: "100%" }}
                  value={null}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth margin="normal" />
                  )}
                />
              </LocalizationProvider>
            </div>
            </Grid>
            <Grid  size={{ xs: 12 }}>
              <p >Notification</p>
              <FormControl sx={{ mt: 2 }}>
                <RadioGroup
                  row
                  value={selectedFormat}
                  onChange={(e) => setSelectedFormat(e.target.value)}
                >
                  <FormControlLabel
                    value="Delegator and Delegatee"
                    control={<Radio />}
                    label="Delegator and Delegatee"
                  />
                  <FormControlLabel
                    value="Delegatee"
                    control={<Radio />}
                    label="Delegatee"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid  size={{ xs: 12 }}>
              <p style={{ marginBottom: "8px" }}>Description</p>
              <TextField
                sx={{ width: "100%" }}
                name="description"
                size="small"
                minRows={3}
                multiline
                // variant="standard"
                value={""}
              />
            </Grid>
          </Grid>
        </Box>

        {/* buttons */}
        <Box
          sx={{ backgroundColor: mode === "dark" ? "#141414" : "#edf0f4" }}
          className="w-full min-h-14 items-center z-50 sticky mt-2 bottom-0 left-0 py-2"
        >
          <div className="flex flex-row gap-3 items-center">
            <Button variant="contained">Save</Button>
            <Button variant="outlined" onClick={() => setAddDelegation(false)}>
              Cancel
            </Button>
          </div>
        </Box>
      </CustomDrawer>
      {/* <div className="flex flex-col gap-3 pb-3">
      <div className="w-full h-[41.25rem] flex flex-col gap-3 justify-center items-center text-center">
        <div className="w-[30%]">
          <h1>No delegations added currently.</h1>
        </div>
        <div className="w-[30%]">
          <p>
            Delegation lets you reassign approvals from one employee to another
            for a specific time frame.
          </p>
        </div>
        <div>
          <Button
            onClick={() => {
              setAddDelegationModal(true);
            }}
            variant="contained"
          >
            Add Delegation
          </Button>
        </div>
      </div>
      <CustomModal
        title="Add Delegation"
        fields={addDelegationModalFields}
        open={addDelegationModal}
        onClose={() => {
          setAddDelegationModal(false);
        }}
        onSubmit={handleAddDelegationFormSubmit}
        // isScrollable={true}
      />
    </div> */}
    </>
  );
}
