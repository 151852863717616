import React, { useState, useRef, useEffect } from "react";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { IoCalendarOutline } from "react-icons/io5";
import { CiViewTable } from "react-icons/ci";
import { MdDeleteOutline, MdOutlineModeEditOutline } from "react-icons/md";
import HolidayTableView from "./HolidayTableView";
import DateButton from "../../../components/DateButton";
import dayjs from "dayjs";
import CustomModal from "../../../components/CustomModal";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useTheme } from "../../../style/theme";
import { TfiViewList } from "react-icons/tfi";
import { FiGrid } from "react-icons/fi";

export default function Holidays() {
  const [switchScreen, setSwitchScreen] = useState({ primary: "tableView" });
  const [holiday, setHoliday] = useState("myHoliday");
  const [addHolidayModal, setAddHolidayModel] = useState(false);
  const [viewHolidayModal, setViewHolidayModal] = useState(false);
  const [editHolidayModal, setEditHolidayModal] = useState(false);
  const [dateRange, setDateRange] = useState({ start: "", end: "" });
  const [openCalendar, setOpenCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const startOfWeek = selectedDate.startOf("isoWeek"); // Monday
  const endOfWeek = startOfWeek.add(6, "day"); // Sunday
  const formattedStart = startOfWeek.format("DD/MM/YYYY");
  const formattedEnd = endOfWeek.format("DD/MM/YYYY");
  const { mode } = useTheme();

  const calendarRef = useRef(null);
  const handleDateChange = ({ start, end }) => {
    setDateRange({ start, end });
  };

  useEffect(() => {
    if (calendarRef.current && dateRange.start) {
      const calendarApi = calendarRef.current.getApi();
      const startDate = new Date(dateRange.start);
      calendarApi.gotoDate(startDate);
    }
  }, [dateRange]);

  const getDateRange = (startDate, endDate) => {
    const dates = [];
    let currentDate = dayjs(startDate);
    const endDateObj = dayjs(endDate);

    while (
      currentDate.isBefore(endDateObj) ||
      currentDate.isSame(endDateObj, "day")
    ) {
      dates.push(currentDate.format("ddd DD-MMM-YYYY"));
      currentDate = currentDate.add(1, "day");
    }

    return dates;
  };

  const renderDateRange = (startDate, endDate) => {
    const dates = getDateRange(startDate, endDate);

    return dates.map((date, index) => (
      <div
        key={index}
        className="flex flex-row gap-3 justify-center items-center"
      >
        <div className="text-nowrap w-40">
          <h1>{date}</h1>
        </div>
        <div className="w-full">
          <FormControl sx={{ width: "100%" }}>
            <InputLabel>Holiday Type</InputLabel>
            <Select
              sx={{ width: "100%" }}
              name="holidayType"
              label="Holiday Type"
              defaultValue="fullDay"
            >
              <MenuItem value={"fullDay"}>Full Day</MenuItem>
              <MenuItem value={"1thHalfDay"}>1th Half Day</MenuItem>
              <MenuItem value={"2ndHalfDay"}>2nd Half Day</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
    ));
  };

  const addHolidayModalFields = [
    {
      type: "text",
      name: "name",
      label: "Name",
      defaultValue: "",
    },
    {
      type: "datePicker",
      label: "Start Date",
      name: "startDate",
      defaultValue: dayjs("2023-10-01"),
    },
    {
      type: "datePicker",
      label: "End Date",
      name: "endDate",
      defaultValue: dayjs("2023-10-01"),
    },
    {
      type: "none",
      custom: (formData, setFormData, field, index, handleChange) => {
        const startDate = formData.startDate || dayjs("2023-10-01");
        const endDate = formData.endDate || dayjs("2023-10-01");

        return (
          <div className="flex flex-col gap-3">
            <h1>Holiday Type</h1>
            {renderDateRange(startDate, endDate)}
          </div>
        );
      },
    },
    {
      type: "none",
      custom: (formData, setFormData, field, index, handleChange) => {
        // Initialize the applicableFor array if it doesn't exist
        if (!formData.applicableFor) {
          formData.applicableFor = [];
        }

        const handleAddField = () => {
          // Add a new field to the applicableFor array
          const newField = { shifts: "", select: "" };
          handleChange("applicableFor")(null, [
            ...formData.applicableFor,
            newField,
          ]);
        };

        const handleDeleteField = (index) => {
          // Remove the field at the specified index
          const updatedFields = formData.applicableFor.filter(
            (_, i) => i !== index
          );
          handleChange("applicableFor")(null, updatedFields);
        };

        const handleFieldChange = (index, key, value) => {
          // Update the specific field in the applicableFor array
          const updatedFields = formData.applicableFor.map((item, i) =>
            i === index ? { ...item, [key]: value } : item
          );
          handleChange("applicableFor")(null, updatedFields);
        };

        return (
          <div className="flex flex-col gap-3">
            <h1>Applicable For</h1>
            {formData.applicableFor.map((dynamicField, idx) => (
              <div
                key={idx}
                className="flex flex-row gap-3 justify-center items-center"
              >
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel>Shifts</InputLabel>
                  <Select
                    name="Shifts"
                    label="Shifts"
                    value={dynamicField.shifts}
                    onChange={(event) =>
                      handleFieldChange(idx, "shifts", event.target.value)
                    }
                  >
                    <MenuItem value={"shifts"}>Shifts</MenuItem>
                  </Select>
                </FormControl>
                <div>
                  <h1>Is</h1>
                </div>
                <Autocomplete
                  sx={{ width: "100%", marginBottom: "-15px" }}
                  options={["General"]}
                  value={dynamicField.select}
                  onChange={(event, value) =>
                    handleFieldChange(idx, "select", value)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Select"
                      placeholder="Select"
                    />
                  )}
                />
                <div>
                  <IconButton onClick={() => handleDeleteField(idx)}>
                    <MdDeleteOutline />
                  </IconButton>
                </div>
              </div>
            ))}
            <div>
              <Button variant="outlined" onClick={handleAddField}>
                Add
              </Button>
            </div>
          </div>
        );
      },
    },
    {
      type: "textarea",
      name: "description",
      label: "Description",
      defaultValue: "",
    },
    {
      type: "select",
      name: "noOfDays",
      label: "No of day(s) before when the reminder email is to be sent",
      options: [
        { label: "Select", value: "select" },
        { label: "1", value: 1 },
        { label: "2", value: 2 },
        { label: "3", value: 3 },
        { label: "4", value: 4 },
        { label: "5", value: 5 },
        { label: "6", value: 6 },
        { label: "7", value: 7 },
        { label: "8", value: 8 },
        { label: "9", value: 9 },
        { label: "10", value: 10 },
        { label: "11", value: 11 },
        { label: "12", value: 12 },
        { label: "13", value: 13 },
        { label: "14", value: 14 },
        { label: "15", value: 15 },
        { label: "16", value: 16 },
        { label: "17", value: 17 },
        { label: "18", value: 18 },
        { label: "19", value: 19 },
        { label: "20", value: 20 },
        { label: "21", value: 21 },
        { label: "22", value: 22 },
        { label: "23", value: 23 },
        { label: "24", value: 24 },
        { label: "25", value: 25 },
        { label: "26", value: 26 },
        { label: "27", value: 27 },
        { label: "28", value: 28 },
        { label: "29", value: 29 },
        { label: "30", value: 30 },
      ],
      defaultValue: "select",
    },
    {
      type: "checkbox",
      name: "notifyApplicable",
      title: "Notify applicable employees via feeds",
      label:
        "They will receive a feed notification instantly once this holiday is saved",
      defaultValue: "",
    },
    {
      type: "checkbox",
      name: "reprocessLeave",
      title: "Reprocess leave applications based on this added holiday",
      label:
        "Leaves that are already applied for this holiday will be reprocessed and the balance will be adjusted accordingly",
      defaultValue: "",
    },
  ];

  const handleAddHolidayFormSubmit = (data) => {
    const startDate = data.startDate || dayjs("2023-10-01");
    const endDate = data.endDate || dayjs("2023-10-01");

    const dateRange = getDateRange(startDate, endDate);

    const holidayTypes = dateRange.map((date) => ({
      date: date,
      holidayType: data[`holidayType-${date}`] || "fullDay",
    }));

    const newForm = {
      ...data,
      holidayType: holidayTypes,
    };

    console.log("Add Form Data :- ", newForm);
  };

  const viewHolidayModalFields = [
    {
      type: "text",
      name: "name",
      label: "Name",
      defaultValue: "Demo Holiday",
    },
    {
      type: "datePicker",
      label: "Start Date",
      name: "startDate",
      defaultValue: dayjs("2023-10-01"),
    },
    {
      type: "datePicker",
      label: "End Date",
      name: "endDate",
      defaultValue: dayjs("2023-10-01"),
    },
    {
      type: "none",
      custom: (formData, setFormData, field, index, handleChange) => {
        const startDate = formData.startDate || dayjs("2023-10-01");
        const endDate = formData.endDate || dayjs("2023-10-01");

        return (
          <div className="flex flex-col gap-3">
            <h1>Holiday Type</h1>
            {renderDateRange(startDate, endDate)}
          </div>
        );
      },
    },
    {
      type: "none",
      custom: (formData, setFormData, field, index, handleChange) => {
        return (
          <div className="flex flex-col gap-3">
            <h1>Applicable For</h1>
            {[
              { shifts: "Shift 1", select: "General" },
              { shifts: "Shift 2", select: "General" },
            ].map((dynamicField, idx) => (
              <div
                key={idx}
                className="flex flex-row gap-3 justify-center items-center"
              >
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel>Shifts</InputLabel>
                  <Select
                    name="Shifts"
                    label="Shifts"
                    value={dynamicField.shifts}
                    disabled
                  >
                    <MenuItem value={"shifts"}>Shifts</MenuItem>
                  </Select>
                </FormControl>
                <div>
                  <h1>Is</h1>
                </div>
                <Autocomplete
                  sx={{ width: "100%", marginBottom: "-15px" }}
                  options={["General"]}
                  value={dynamicField.select}
                  disabled
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Select"
                      placeholder="Select"
                    />
                  )}
                />
              </div>
            ))}
          </div>
        );
      },
    },
    {
      type: "textarea",
      name: "description",
      label: "Description",
      defaultValue: "This is a demo holiday description.",
    },
    {
      type: "select",
      name: "noOfDays",
      label: "No of day(s) before when the reminder email is to be sent",
      options: [
        { label: "Select", value: "select" },
        { label: "1", value: 1 },
        { label: "2", value: 2 },
        { label: "3", value: 3 },
        { label: "4", value: 4 },
        { label: "5", value: 5 },
        { label: "6", value: 6 },
        { label: "7", value: 7 },
        { label: "8", value: 8 },
        { label: "9", value: 9 },
        { label: "10", value: 10 },
        { label: "11", value: 11 },
        { label: "12", value: 12 },
        { label: "13", value: 13 },
        { label: "14", value: 14 },
        { label: "15", value: 15 },
        { label: "16", value: 16 },
        { label: "17", value: 17 },
        { label: "18", value: 18 },
        { label: "19", value: 19 },
        { label: "20", value: 20 },
        { label: "21", value: 21 },
        { label: "22", value: 22 },
        { label: "23", value: 23 },
        { label: "24", value: 24 },
        { label: "25", value: 25 },
        { label: "26", value: 26 },
        { label: "27", value: 27 },
        { label: "28", value: 28 },
        { label: "29", value: 29 },
        { label: "30", value: 30 },
      ],
      defaultValue: "select",
    },
    {
      type: "checkbox",
      name: "notifyApplicable",
      title: "Notify applicable employees via feeds",
      label:
        "They will receive a feed notification instantly once this holiday is saved",
      defaultValue: true,
    },
    {
      type: "checkbox",
      name: "reprocessLeave",
      title: "Reprocess leave applications based on this added holiday",
      label:
        "Leaves that are already applied for this holiday will be reprocessed and the balance will be adjusted accordingly",
      defaultValue: false,
    },
  ];

  const editHolidayModalFields = [
    {
      type: "text",
      name: "name",
      label: "Name",
      defaultValue: "Demo Holiday",
    },
    {
      type: "datePicker",
      label: "Start Date",
      name: "startDate",
      defaultValue: dayjs("2023-10-01"),
    },
    {
      type: "datePicker",
      label: "End Date",
      name: "endDate",
      defaultValue: dayjs("2023-10-05"),
    },
    {
      type: "none",
      custom: (formData, setFormData, field, index, handleChange) => {
        const startDate = formData.startDate || dayjs("2023-10-01");
        const endDate = formData.endDate || dayjs("2023-10-05");

        // Demo data for holidayType
        const demoHolidayTypes = [
          { date: "Mon 02-Oct-2023", holidayType: "fullDay" },
          { date: "Tue 03-Oct-2023", holidayType: "1thHalfDay" },
          { date: "Wed 04-Oct-2023", holidayType: "2ndHalfDay" },
        ];

        return (
          <div className="flex flex-col gap-3">
            <h1>Holiday Type</h1>
            {renderDateRange(startDate, endDate)}
          </div>
        );
      },
    },
    {
      type: "none",
      custom: (formData, setFormData, field, index, handleChange) => {
        // Initialize the applicableFor array if it doesn't exist
        if (!formData.applicableFor) {
          formData.applicableFor = [
            { shifts: "Shift 1", select: "General" },
            { shifts: "Shift 2", select: "General" },
          ];
        }

        const handleAddField = () => {
          // Add a new field to the applicableFor array
          const newField = { shifts: "", select: "" };
          handleChange("applicableFor")(null, [
            ...formData.applicableFor,
            newField,
          ]);
        };

        const handleDeleteField = (index) => {
          // Remove the field at the specified index
          const updatedFields = formData.applicableFor.filter(
            (_, i) => i !== index
          );
          handleChange("applicableFor")(null, updatedFields);
        };

        const handleFieldChange = (index, key, value) => {
          // Update the specific field in the applicableFor array
          const updatedFields = formData.applicableFor.map((item, i) =>
            i === index ? { ...item, [key]: value } : item
          );
          handleChange("applicableFor")(null, updatedFields);
        };

        return (
          <div className="flex flex-col gap-3">
            <h1>Applicable For</h1>
            {formData.applicableFor.map((dynamicField, idx) => (
              <div
                key={idx}
                className="flex flex-row gap-3 justify-center items-center"
              >
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel>Shifts</InputLabel>
                  <Select
                    name="Shifts"
                    label="Shifts"
                    value={dynamicField.shifts}
                    onChange={(event) =>
                      handleFieldChange(idx, "shifts", event.target.value)
                    }
                  >
                    <MenuItem value={"shifts"}>Shifts</MenuItem>
                  </Select>
                </FormControl>
                <div>
                  <h1>Is</h1>
                </div>
                <Autocomplete
                  sx={{ width: "100%", marginBottom: "-15px" }}
                  options={["General"]}
                  value={dynamicField.select}
                  onChange={(event, value) =>
                    handleFieldChange(idx, "select", value)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Select"
                      placeholder="Select"
                    />
                  )}
                />
                <div>
                  <IconButton onClick={() => handleDeleteField(idx)}>
                    <MdDeleteOutline />
                  </IconButton>
                </div>
              </div>
            ))}
            <div>
              <Button variant="outlined" onClick={handleAddField}>
                Add
              </Button>
            </div>
          </div>
        );
      },
    },
    {
      type: "textarea",
      name: "description",
      label: "Description",
      defaultValue: "This is a demo holiday description.",
    },
    {
      type: "select",
      name: "noOfDays",
      label: "No of day(s) before when the reminder email is to be sent",
      options: [
        { label: "Select", value: "select" },
        { label: "1", value: 1 },
        { label: "2", value: 2 },
        { label: "3", value: 3 },
        { label: "4", value: 4 },
        { label: "5", value: 5 },
        { label: "6", value: 6 },
        { label: "7", value: 7 },
        { label: "8", value: 8 },
        { label: "9", value: 9 },
        { label: "10", value: 10 },
        { label: "11", value: 11 },
        { label: "12", value: 12 },
        { label: "13", value: 13 },
        { label: "14", value: 14 },
        { label: "15", value: 15 },
        { label: "16", value: 16 },
        { label: "17", value: 17 },
        { label: "18", value: 18 },
        { label: "19", value: 19 },
        { label: "20", value: 20 },
        { label: "21", value: 21 },
        { label: "22", value: 22 },
        { label: "23", value: 23 },
        { label: "24", value: 24 },
        { label: "25", value: 25 },
        { label: "26", value: 26 },
        { label: "27", value: 27 },
        { label: "28", value: 28 },
        { label: "29", value: 29 },
        { label: "30", value: 30 },
      ],
      defaultValue: "select",
    },
    {
      type: "checkbox",
      name: "notifyApplicable",
      title: "Notify applicable employees via feeds",
      label:
        "They will receive a feed notification instantly once this holiday is saved",
      defaultValue: true,
    },
    {
      type: "checkbox",
      name: "reprocessLeave",
      title: "Reprocess leave applications based on this added holiday",
      label:
        "Leaves that are already applied for this holiday will be reprocessed and the balance will be adjusted accordingly",
      defaultValue: false,
    },
  ];

  const handleEditHolidayFormSubmit = (data) => {
    const startDate = data.startDate || dayjs("2023-10-01");
    const endDate = data.endDate || dayjs("2023-10-01");

    const dateRange = getDateRange(startDate, endDate);

    const holidayTypes = dateRange.map((date) => ({
      date: date,
      holidayType: data[`holidayType-${date}`] || "fullDay",
    }));

    const newForm = {
      ...data,
      holidayType: holidayTypes,
    };

    console.log("Edit Form Data :- ", newForm);
  };

  const handlePrevWeek = () => {
    setSelectedDate(selectedDate.subtract(7, "day"));
  };

  const handleNextWeek = () => {
    setSelectedDate(selectedDate.add(7, "day"));
  };

  const [currentMonth, setCurrentMonth] = useState(dayjs());

  const handlePrevMonth = () => {
    setCurrentMonth(currentMonth.subtract(1, "month"));
  };

  const handleNextMonth = () => {
    setCurrentMonth(currentMonth.add(1, "month"));
  };

  const daysInMonth = currentMonth.daysInMonth();
  const firstDay = currentMonth.startOf("month").day();

  const calendarDays = [];
  for (let i = 0; i < firstDay; i++) {
    calendarDays.push(null);
  }
  for (let i = 1; i <= daysInMonth; i++) {
    calendarDays.push(i);
  }

  return (
    <div className="w-full h-full flex flex-col p-4 gap-3">
      <div className="w-full flex gap-3 flex-col md:flex-row justify-between md:items-center">
        {/* <div className="w-full flex gap-3 justify-center items-center">
          <DateButton mode="week" onDateChange={handleDateChange} />
        </div> */}
        <div />
        <div />

        <div className="flex relative items-center gap-2 ">
          {/* Previous Week Button */}
          <div
            className={`flex items-center gap-2 ${
              mode === "light" ? "bg-white" : "bg-transparent"
            } p-2 rounded-md`}
          >
            <IoIosArrowBack
              fontSize="small"
              className="cursor-pointer hover:text-gray-600"
              onClick={() => {
                switchScreen.primary === "calendarView"
                  ? handlePrevMonth()
                  : handlePrevWeek();
              }}
              color="#398aff"
            />

            {/* Calendar Icon and Date Range Text */}
            <Box className="relative flex items-center rounded-md cursor-pointer hover:bg-gray-200">
              <IoCalendarOutline
                onClick={() => setOpenCalendar(!openCalendar)}
                size="18px"
                className="text-gray-600"
              />
            </Box>

            {/* Next Week Button */}
            <IoIosArrowForward
              fontSize="small"
              className="cursor-pointer hover:text-gray-600"
              onClick={() => {
                switchScreen.primary === "calendarView"
                  ? handleNextMonth()
                  : handleNextWeek();
              }}
              color="#398aff"
            />
          </div>

          {switchScreen.primary === "calendarView" ? (
            <span className="text-sm font-semibold">
              {currentMonth.format("MMMM YYYY")}
            </span>
          ) : (
            <span className="text-sm font-medium">
              {formattedStart} - {formattedEnd}
            </span>
          )}

          {/* Calendar Popup */}
          {openCalendar && (
            <Box className="absolute top-8 z-10 shadow-md border rounded-md " 
            sx={{ backgroundColor: mode === "dark" ? "#171717" : "white" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar
                  value={selectedDate}
                  onChange={(date) => {
                    setSelectedDate(date);
                    setOpenCalendar(false);
                  }}
                />
              </LocalizationProvider>
            </Box>
          )}
        </div>

        <div className="flex gap-2 items-center">
          <div
            className={`rounded-md flex items-center gap-1  ${
              mode === "light" ? "bg-[#fff]" : ""
            }`}
          >
            <div
              className="relative group p-1 rounded-md"
              style={{
                border:
                  switchScreen.primary === "tableView"
                    ? "1px solid #0088ff"
                    : "",
              }}
            >
              <FiGrid
                size={20}
                className="cursor-pointer"
                onClick={() => setSwitchScreen({ primary: "tableView" })}
                color={switchScreen.primary === "tableView" ? "#0088ff" : ""}
              />
              <span className="absolute top-10 left-1/2 w-[max-content] -translate-x-1/2 text-xs bg-gray-800 text-white px-2 py-1 rounded opacity-0 group-hover:opacity-100 transition">
                Table view
              </span>
            </div>
            <div
              className="relative group p-1 rounded-md"
              style={{
                border:
                  switchScreen.primary === "calendarView"
                    ? "1px solid #0088ff"
                    : "",
              }}
            >
              <IoCalendarOutline
                size={20}
                className="cursor-pointer"
                onClick={() => setSwitchScreen({ primary: "calendarView" })}
                color={switchScreen.primary === "calendarView" ? "#0088ff" : ""}
              />
              <span className="absolute top-10 left-1/2 -translate-x-1/2 w-[max-content] text-xs bg-gray-800 text-white px-2 py-1 rounded opacity-0 group-hover:opacity-100 transition">
                Calendar view
              </span>
            </div>
          </div>
          <div>
            <FormControl sx={{ minWidth: 180 }}>
              <InputLabel>Holiday</InputLabel>
              <Select
                size="small"
                value={holiday}
                onChange={(event) => setHoliday(event.target.value)}
                label="holiday"
              >
                <MenuItem value="myHoliday">My Holiday</MenuItem>
                <MenuItem value="allHoliday">All Holiday</MenuItem>
                <MenuItem value="allShifts">All Shifts</MenuItem>
                <MenuItem value="general">General</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div>
            <Button
              onClick={() => setAddHolidayModel(true)}
              variant="contained"
            >
              Add Holiday
            </Button>
          </div>
        </div>
      </div>
      {switchScreen.primary === "calendarView" ? (
        <div>
          {/* Calendar Grid */}
          <div
            style={{
              backgroundColor: mode === "dark" ? "#141414" : "white",
              border: mode === "dark" ? "1px solid" : "1px solid #d7e2ed",
            }}
            className="grid grid-cols-7 gap-0 sm:p-2 text-center text-gray-700 font-medium"
          >
            {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
              <div key={day} className="sm:p-2 py-2">
                {day}
              </div>
            ))}
          </div>
          <div className="grid grid-cols-7 gap-0">
            {calendarDays.map((day, index) => (
              <div
                style={{
                  border:
                    mode === "dark" ? "1px solid #374151" : "1px solid #d7e2ed",
                }}
                key={index}
                className={`sm:h-32 p-3 ${
                  mode === "dark" ? "bg-[#141414]" : "bg-white"
                }`}
                onClick={() => setSelectedDate(currentMonth.date(day))}
              >
                {day && (
                  <span
                    className={`text-[14px] sm:text-lg ${
                      day === selectedDate.date() &&
                      currentMonth.isSame(selectedDate, "month")
                        ? "bg-blue-500 p-1 px-2 rounded-lg text-white font-bold"
                        : ""
                    }`}
                  >
                    {day}
                  </span>
                )}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <></>
      )}
      {switchScreen.primary === "tableView" ? (
        <HolidayTableView
          handleRowClick={(row) => {
            setViewHolidayModal(true);
          }}
        />
      ) : (
        <></>
      )}
      <CustomModal
        title="Add Holiday"
        fields={addHolidayModalFields}
        open={addHolidayModal}
        onClose={() => setAddHolidayModel(false)}
        onSubmit={handleAddHolidayFormSubmit}
        isScrollable={true}
      />
      <CustomModal
        title="View Holiday"
        fields={viewHolidayModalFields}
        open={viewHolidayModal}
        onClose={() => setViewHolidayModal(false)}
        isView={true}
        isEditButton={true}
        onEditButtonClick={() => {
          setViewHolidayModal(false);
          setEditHolidayModal(true);
        }}
        isScrollable={true}
      />
      <CustomModal
        title="Edit Holiday"
        fields={editHolidayModalFields}
        open={editHolidayModal}
        onClose={() => setEditHolidayModal(false)}
        onSubmit={handleEditHolidayFormSubmit}
        isScrollable={true}
      />
    </div>
  );
}
