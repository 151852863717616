import React, { useState } from "react";
import CustomEmptyModal from "./CustomEmptyModal";
import Grid from "@mui/material/Grid2";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import { ArrowBack, Close } from "@mui/icons-material";

const CustomImport = ({ open, onClose }) => {
  const { mode } = useTheme();
  const [selectedFile, setSelectedFile] = useState(null);
  const [mapDetails, setMapDetails] = useState(false);
  const [activeTab, setActiveTab] = useState("all");
  const [mainTab, setMainTab] = useState("1");

  const tabs = [
    { id: "all", label: "All" },
    { id: "mapped", label: "Mapped" },
    { id: "unmapped", label: "Unmapped" },
  ];

  const stepperTabs = [
    { id: "1", label: "1", title: "Map Details" },
    { id: "2", label: "2", title: "Verify" },
    { id: "3", label: "3", title: "Import Summary" },
  ];

  const handleClose = () => {
    onClose();
    setSelectedFile(null);
    setMapDetails(false);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (
      file &&
      [
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "text/csv",
      ].includes(file.type)
    ) {
      setSelectedFile(file);
    } else {
      alert(
        "Invalid file type! Only xls, xlsx, and csv formats are supported."
      );
    }
  };

  const handleMapDetails = () => {
    if (selectedFile) {
      setMapDetails(true);
    } else {
      alert("Please upload a file to proceed.");
    }
  };

  return (
    <div>
      <CustomEmptyModal isScrollable={true} open={open} onClose={handleClose}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: 2,
            borderBottom: "1px solid #ddd",
            position: "sticky",
            backgroundColor: "background.default",
            top: 0,
            zIndex: 10, // Keeps it above scrollable content
          }}
        >
          <div className="flex items-center">
            <IconButton
              sx={{
                backgroundColor: mode == "light" ? "#edf0f4" : "#3333",
                mr: 2,
                fontSize: 14,
                borderRadius: "6px",
              }}
              onClick={handleClose}
            >
              <ArrowBack  fontSize="16px" />
            </IconButton>
            <Typography variant="h6">Import</Typography>
          </div>
          <IconButton
            sx={{
              backgroundColor: mode == "light" ? "#edf0f4" : "#3333",
              fontSize: 16,
              borderRadius: "6px",
            }}
            onClick={handleClose}
          >
            <Close  fontSize="16px" />
          </IconButton>
        </Box>

        {!mapDetails ? (
          <Box sx={{ padding: 2 }}>
            <div className="grid grid-cols-4 gap-2">
              <div>
                <p>Import data for:</p>
                <FormControl
                  // key={index}
                  sx={{
                    minWidth: "200px",
                    width: "100%",
                    padding: 0,
                    margin: 0,
                    mt: 1,
                  }}
                >
                  {/* <InputLabel>{column.label}</InputLabel> */}
                  <Select
                    size="small"
                    // label={column.label}
                    name={"importFor"}
                    value={""}
                    //   onChange={}
                    //   disabled={isView}
                  >
                    <MenuItem value={"Candidate"}>{"Candidate"}</MenuItem>
                    <MenuItem value={"Education"}>{"Education"}</MenuItem>
                    <MenuItem value={"Experience"}>{"Experience"}</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div>
                <p>Import based on:</p>
                <FormControl
                  // key={index}
                  sx={{
                    minWidth: "200px",
                    width: "100%",
                    padding: 0,
                    margin: 0,
                    mt: 1,
                  }}
                >
                  {/* <InputLabel>{column.label}</InputLabel> */}
                  <Select
                    size="small"
                    // label={column.label}
                    name={"importBasedOn"}
                    value={""}
                    //   onChange={}
                    //   disabled={isView}
                  >
                    <MenuItem value={"Candidate ID"}>{"Candidate ID"}</MenuItem>
                    <MenuItem value={"Email ID"}>{"Email ID"}</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>

            <div className="border p-2 rounded-md my-5 flex justify-center items-center flex-col ">
              <img
                src="/images/importImg.png"
                style={{ width: "30%", height: "30%" }}
              />
              <div className="text-center mt-2">
                {selectedFile ? (
                  <>
                    <p className="text-[18px]">{selectedFile.name}</p>
                    <Button
                      variant="contained"
                      component="label"
                      sx={{ my: 2 }}
                    >
                      Remove and Upload New
                      <input type="file" hidden onChange={handleFileChange} />
                    </Button>
                  </>
                ) : (
                  <>
                    <p className="text-[18px]">Drag and drop attachment here</p>
                    <p className="text-[14px] my-1">
                      [only xls, xlsx, and csv formats are supported]
                    </p>
                    <Button
                      variant="contained"
                      component="label"
                      sx={{ my: 1 }}
                    >
                      Upload File
                      <input type="file" hidden onChange={handleFileChange} />
                    </Button>
                    <p>Maximum upload file size is 5 MB.</p>
                    <Button
                      sx={{ my: 1 }}
                      onClick={() =>
                        window.open(
                          "../assets/files/Candidate Template.xlsx",
                          "_blank"
                        )
                      }
                      variant="outlined"
                    >
                      Download sample template
                    </Button>
                  </>
                )}
              </div>
            </div>
          </Box>
        ) : (
          <div className="p-2 ">
            <div className="flex justify-center p-1 space-x-14 rounded-xl">
              {stepperTabs.map((tab) => (
                <div
                  key={tab.id}
                  className="flex flex-col items-center space-y-1"
                >
                  <button
                    onClick={() => setMainTab(tab.id)}
                    className={`h-12 w-12 rounded-full text-md font-medium transition-all duration-200 flex items-center justify-center
          ${
            mainTab === tab.id
              ? "bg-[#0088ff] text-white shadow-md"
              : "bg-white text-gray-500  shadow-md"
          }
        `}
                  >
                    {tab.label}
                  </button>
                  <p
                    className={`text-sm   ${
                      mainTab === tab.id ? "font-semibold" : "font-normal"
                    } text-gray-700`}
                  >
                    {tab.title}
                  </p>
                </div>
              ))}
            </div>

            {mainTab === "1" ? (
              <>
                {/* Pill-style Tabs */}
                <div className="flex w-[30%] p-1 space-x-1 bg-gray-100 rounded-xl">
                  {tabs.map((tab) => (
                    <button
                      key={tab.id}
                      onClick={() => setActiveTab(tab.id)}
                      className={`px-4 py-2 text-sm font-medium rounded-lg transition-all duration-200 flex-1
              ${
                activeTab === tab.id
                  ? "bg-white text-gray-800 shadow-sm"
                  : "text-gray-500 hover:text-gray-700"
              }
            `}
                    >
                      {tab.label}
                    </button>
                  ))}
                </div>

                {/* Tabs Content */}
                <div className="mt-4 p-4 bg-white rounded-lg">
                  {activeTab === "all" && (
                    <div>
                      <Accordion defaultExpanded>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="candidateDetails"
                          id="candidateDetails"
                          sx={{ borderBottom: "1px solid #ddd" }}
                        >
                          <p className="text-[20px] ">Candidate Details</p>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 4 }}>
                          <Grid container spacing={2}>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <p>Candidate ID</p>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  name="candidateId"
                                  variant="outlined"
                                  value={""}
                                >
                                  <MenuItem value={"Bisham"}>
                                    {"Bisham"}
                                  </MenuItem>
                                  <MenuItem value={"Saad"}>{"Saad"}</MenuItem>
                                  <MenuItem value={"Fahad"}>{"Fahad"}</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <p>First Name</p>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  name="firstName"
                                  variant="outlined"
                                  value={""}
                                >
                                  <MenuItem value={"Bisham"}>
                                    {"Bisham"}
                                  </MenuItem>
                                  <MenuItem value={"Saad"}>{"Saad"}</MenuItem>
                                  <MenuItem value={"Fahad"}>{"Fahad"}</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <p>Email ID</p>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  name="emailId"
                                  variant="outlined"
                                  value={""}
                                >
                                  <MenuItem value={"Bisham"}>
                                    {"Bisham"}
                                  </MenuItem>
                                  <MenuItem value={"Saad"}>{"Saad"}</MenuItem>
                                  <MenuItem value={"Fahad"}>{"Fahad"}</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <p>Last Name</p>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  name="lastName"
                                  variant="outlined"
                                  value={""}
                                >
                                  <MenuItem value={"Bisham"}>
                                    {"Bisham"}
                                  </MenuItem>
                                  <MenuItem value={"Saad"}>{"Saad"}</MenuItem>
                                  <MenuItem value={"Fahad"}>{"Fahad"}</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <p>Phone</p>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  name="phone"
                                  variant="outlined"
                                  value={""}
                                >
                                  <MenuItem value={"Bisham"}>
                                    {"Bisham"}
                                  </MenuItem>
                                  <MenuItem value={"Saad"}>{"Saad"}</MenuItem>
                                  <MenuItem value={"Fahad"}>{"Fahad"}</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <p>Official Email</p>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  name="officialEmail"
                                  variant="outlined"
                                  value={""}
                                >
                                  <MenuItem value={"Bisham"}>
                                    {"Bisham"}
                                  </MenuItem>
                                  <MenuItem value={"Saad"}>{"Saad"}</MenuItem>
                                  <MenuItem value={"Fahad"}>{"Fahad"}</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="candidateDetails"
                          id="candidateDetails"
                          sx={{ borderBottom: "1px solid #ddd" }}
                        >
                          <p className="text-[20px] ">Address Details</p>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 4 }}>
                          <Grid container spacing={2}>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <Grid
                                container
                                spacing={2}
                                sx={{
                                  background: "#edf0f4",
                                  borderRadius: "10px",
                                  padding: 2,
                                }}
                              >
                                <Grid size={{ xs: 12, md: 6 }}>
                                  <p className="text-[16px] font-semibold ">
                                    Present Address
                                  </p>
                                </Grid>

                                <Grid size={{ xs: 12 }}>
                                  <p>Address line 1</p>
                                  <FormControl fullWidth>
                                    <Select
                                      size="small"
                                      name="addressLine1"
                                      variant="outlined"
                                      value={""}
                                    >
                                      <MenuItem value={"Bisham"}>
                                        {"Bisham"}
                                      </MenuItem>
                                      <MenuItem value={"Saad"}>
                                        {"Saad"}
                                      </MenuItem>
                                      <MenuItem value={"Fahad"}>
                                        {"Fahad"}
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                  <p>Address line 2</p>
                                  <FormControl fullWidth>
                                    <Select
                                      size="small"
                                      name="addressLine2"
                                      variant="outlined"
                                      value={""}
                                    >
                                      <MenuItem value={"Bisham"}>
                                        {"Bisham"}
                                      </MenuItem>
                                      <MenuItem value={"Saad"}>
                                        {"Saad"}
                                      </MenuItem>
                                      <MenuItem value={"Fahad"}>
                                        {"Fahad"}
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                  <p>City</p>
                                  <FormControl fullWidth>
                                    <Select
                                      size="small"
                                      name="city"
                                      variant="outlined"
                                      value={""}
                                    >
                                      <MenuItem value={"Bisham"}>
                                        {"Bisham"}
                                      </MenuItem>
                                      <MenuItem value={"Saad"}>
                                        {"Saad"}
                                      </MenuItem>
                                      <MenuItem value={"Fahad"}>
                                        {"Fahad"}
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid size={{ xs: 12, md: 6 }}>
                                  <p>Country</p>
                                  <FormControl fullWidth>
                                    <Select
                                      size="small"
                                      name="country"
                                      variant="outlined"
                                      value={""}
                                    >
                                      <MenuItem value={"Bisham"}>
                                        {"Bisham"}
                                      </MenuItem>
                                      <MenuItem value={"Saad"}>
                                        {"Saad"}
                                      </MenuItem>
                                      <MenuItem value={"Fahad"}>
                                        {"Fahad"}
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid size={{ xs: 12, md: 6 }}>
                                  <p>State</p>
                                  <FormControl fullWidth>
                                    <Select
                                      size="small"
                                      name="state"
                                      variant="outlined"
                                      value={""}
                                    >
                                      <MenuItem value={"Bisham"}>
                                        {"Bisham"}
                                      </MenuItem>
                                      <MenuItem value={"Saad"}>
                                        {"Saad"}
                                      </MenuItem>
                                      <MenuItem value={"Fahad"}>
                                        {"Fahad"}
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                  <p>Postal Code</p>
                                  <FormControl fullWidth>
                                    <Select
                                      size="small"
                                      name="postalCode"
                                      variant="outlined"
                                      value={""}
                                    >
                                      <MenuItem value={"Bisham"}>
                                        {"Bisham"}
                                      </MenuItem>
                                      <MenuItem value={"Saad"}>
                                        {"Saad"}
                                      </MenuItem>
                                      <MenuItem value={"Fahad"}>
                                        {"Fahad"}
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                spacing={2}
                                sx={{
                                  background: "#edf0f4",
                                  borderRadius: "10px",
                                  padding: 2,
                                  mt: 3,
                                }}
                              >
                                <Grid size={{ xs: 12, md: 6 }}>
                                  <p className="text-[16px] font-semibold ">
                                    Permanent address
                                  </p>
                                </Grid>

                                <Grid size={{ xs: 12 }}>
                                  <p>Address line 1</p>
                                  <FormControl fullWidth>
                                    <Select
                                      size="small"
                                      name="addressLine1"
                                      variant="outlined"
                                      value={""}
                                    >
                                      <MenuItem value={"Bisham"}>
                                        {"Bisham"}
                                      </MenuItem>
                                      <MenuItem value={"Saad"}>
                                        {"Saad"}
                                      </MenuItem>
                                      <MenuItem value={"Fahad"}>
                                        {"Fahad"}
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                  <p>Address line 2</p>
                                  <FormControl fullWidth>
                                    <Select
                                      size="small"
                                      name="addressLine2"
                                      variant="outlined"
                                      value={""}
                                    >
                                      <MenuItem value={"Bisham"}>
                                        {"Bisham"}
                                      </MenuItem>
                                      <MenuItem value={"Saad"}>
                                        {"Saad"}
                                      </MenuItem>
                                      <MenuItem value={"Fahad"}>
                                        {"Fahad"}
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                  <p>City</p>
                                  <FormControl fullWidth>
                                    <Select
                                      size="small"
                                      name="city"
                                      variant="outlined"
                                      value={""}
                                    >
                                      <MenuItem value={"Bisham"}>
                                        {"Bisham"}
                                      </MenuItem>
                                      <MenuItem value={"Saad"}>
                                        {"Saad"}
                                      </MenuItem>
                                      <MenuItem value={"Fahad"}>
                                        {"Fahad"}
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid size={{ xs: 12, md: 6 }}>
                                  <p>Country</p>
                                  <FormControl fullWidth>
                                    <Select
                                      size="small"
                                      name="country"
                                      variant="outlined"
                                      value={""}
                                    >
                                      <MenuItem value={"Bisham"}>
                                        {"Bisham"}
                                      </MenuItem>
                                      <MenuItem value={"Saad"}>
                                        {"Saad"}
                                      </MenuItem>
                                      <MenuItem value={"Fahad"}>
                                        {"Fahad"}
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid size={{ xs: 12, md: 6 }}>
                                  <p>State</p>
                                  <FormControl fullWidth>
                                    <Select
                                      size="small"
                                      name="state"
                                      variant="outlined"
                                      value={""}
                                    >
                                      <MenuItem value={"Bisham"}>
                                        {"Bisham"}
                                      </MenuItem>
                                      <MenuItem value={"Saad"}>
                                        {"Saad"}
                                      </MenuItem>
                                      <MenuItem value={"Fahad"}>
                                        {"Fahad"}
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                  <p>Postal Code</p>
                                  <FormControl fullWidth>
                                    <Select
                                      size="small"
                                      name="postalCode"
                                      variant="outlined"
                                      value={""}
                                    >
                                      <MenuItem value={"Bisham"}>
                                        {"Bisham"}
                                      </MenuItem>
                                      <MenuItem value={"Saad"}>
                                        {"Saad"}
                                      </MenuItem>
                                      <MenuItem value={"Fahad"}>
                                        {"Fahad"}
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}></Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="candidateDetails"
                          id="candidateDetails"
                          sx={{ borderBottom: "1px solid #ddd" }}
                        >
                          <p className="text-[20px] ">Professional Details</p>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 4 }}>
                          <Grid container spacing={2}>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <p>Experience</p>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  name="experience"
                                  variant="outlined"
                                  value={""}
                                >
                                  <MenuItem value={"Bisham"}>
                                    {"Bisham"}
                                  </MenuItem>
                                  <MenuItem value={"Saad"}>{"Saad"}</MenuItem>
                                  <MenuItem value={"Fahad"}>{"Fahad"}</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <p>Location</p>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  name="location"
                                  variant="outlined"
                                  value={""}
                                >
                                  <MenuItem value={"Bisham"}>
                                    {"Bisham"}
                                  </MenuItem>
                                  <MenuItem value={"Saad"}>{"Saad"}</MenuItem>
                                  <MenuItem value={"Fahad"}>{"Fahad"}</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <p>Source of Hire</p>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  name="sourceOfHire"
                                  variant="outlined"
                                  value={""}
                                >
                                  <MenuItem value={"Bisham"}>
                                    {"Bisham"}
                                  </MenuItem>
                                  <MenuItem value={"Saad"}>{"Saad"}</MenuItem>
                                  <MenuItem value={"Fahad"}>{"Fahad"}</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <p>Title</p>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  name="title"
                                  variant="outlined"
                                  value={""}
                                >
                                  <MenuItem value={"Bisham"}>
                                    {"Bisham"}
                                  </MenuItem>
                                  <MenuItem value={"Saad"}>{"Saad"}</MenuItem>
                                  <MenuItem value={"Fahad"}>{"Fahad"}</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <p>Skill Set</p>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  name="skillSet"
                                  variant="outlined"
                                  value={""}
                                >
                                  <MenuItem value={"Bisham"}>
                                    {"Bisham"}
                                  </MenuItem>
                                  <MenuItem value={"Saad"}>{"Saad"}</MenuItem>
                                  <MenuItem value={"Fahad"}>{"Fahad"}</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <p>Current Salary</p>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  name="currentSalary"
                                  variant="outlined"
                                  value={""}
                                >
                                  <MenuItem value={"Bisham"}>
                                    {"Bisham"}
                                  </MenuItem>
                                  <MenuItem value={"Saad"}>{"Saad"}</MenuItem>
                                  <MenuItem value={"Fahad"}>{"Fahad"}</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <p>Highest Qualification</p>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  name="highestQualification"
                                  variant="outlined"
                                  value={""}
                                >
                                  <MenuItem value={"Bisham"}>
                                    {"Bisham"}
                                  </MenuItem>
                                  <MenuItem value={"Saad"}>{"Saad"}</MenuItem>
                                  <MenuItem value={"Fahad"}>{"Fahad"}</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <p>Department</p>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  name="department"
                                  variant="outlined"
                                  value={""}
                                >
                                  <MenuItem value={"Bisham"}>
                                    {"Bisham"}
                                  </MenuItem>
                                  <MenuItem value={"Saad"}>{"Saad"}</MenuItem>
                                  <MenuItem value={"Fahad"}>{"Fahad"}</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <p>Additional information</p>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  name="additionalInformation"
                                  variant="outlined"
                                  value={""}
                                >
                                  <MenuItem value={"Bisham"}>
                                    {"Bisham"}
                                  </MenuItem>
                                  <MenuItem value={"Saad"}>{"Saad"}</MenuItem>
                                  <MenuItem value={"Fahad"}>{"Fahad"}</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <p>Tentative Joining Date</p>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  name="tentativeJoiningDate"
                                  variant="outlined"
                                  value={""}
                                >
                                  <MenuItem value={"Bisham"}>
                                    {"Bisham"}
                                  </MenuItem>
                                  <MenuItem value={"Saad"}>{"Saad"}</MenuItem>
                                  <MenuItem value={"Fahad"}>{"Fahad"}</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  )}

                  {activeTab === "mapped" && (
                    <div>
                      <Accordion defaultExpanded>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="candidateDetails"
                          id="candidateDetails"
                          sx={{ borderBottom: "1px solid #ddd" }}
                        >
                          <p className="text-[20px] ">Candidate Details</p>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 4 }}>
                          <Grid container spacing={2}>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <p>Candidate ID</p>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  name="candidateId"
                                  variant="outlined"
                                  value={""}
                                >
                                  <MenuItem value={"Bisham"}>
                                    {"Bisham"}
                                  </MenuItem>
                                  <MenuItem value={"Saad"}>{"Saad"}</MenuItem>
                                  <MenuItem value={"Fahad"}>{"Fahad"}</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <p>First Name</p>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  name="firstName"
                                  variant="outlined"
                                  value={""}
                                >
                                  <MenuItem value={"Bisham"}>
                                    {"Bisham"}
                                  </MenuItem>
                                  <MenuItem value={"Saad"}>{"Saad"}</MenuItem>
                                  <MenuItem value={"Fahad"}>{"Fahad"}</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <p>Email ID</p>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  name="emailId"
                                  variant="outlined"
                                  value={""}
                                >
                                  <MenuItem value={"Bisham"}>
                                    {"Bisham"}
                                  </MenuItem>
                                  <MenuItem value={"Saad"}>{"Saad"}</MenuItem>
                                  <MenuItem value={"Fahad"}>{"Fahad"}</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <p>Last Name</p>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  name="lastName"
                                  variant="outlined"
                                  value={""}
                                >
                                  <MenuItem value={"Bisham"}>
                                    {"Bisham"}
                                  </MenuItem>
                                  <MenuItem value={"Saad"}>{"Saad"}</MenuItem>
                                  <MenuItem value={"Fahad"}>{"Fahad"}</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <p>Phone</p>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  name="phone"
                                  variant="outlined"
                                  value={""}
                                >
                                  <MenuItem value={"Bisham"}>
                                    {"Bisham"}
                                  </MenuItem>
                                  <MenuItem value={"Saad"}>{"Saad"}</MenuItem>
                                  <MenuItem value={"Fahad"}>{"Fahad"}</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <p>Official Email</p>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  name="officialEmail"
                                  variant="outlined"
                                  value={""}
                                >
                                  <MenuItem value={"Bisham"}>
                                    {"Bisham"}
                                  </MenuItem>
                                  <MenuItem value={"Saad"}>{"Saad"}</MenuItem>
                                  <MenuItem value={"Fahad"}>{"Fahad"}</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="candidateDetails"
                          id="candidateDetails"
                          sx={{ borderBottom: "1px solid #ddd" }}
                        >
                          <p className="text-[20px] ">Address Details</p>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 4 }}>
                          <Grid container spacing={2}>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <Grid
                                container
                                spacing={2}
                                sx={{
                                  background: "#edf0f4",
                                  borderRadius: "10px",
                                  padding: 2,
                                }}
                              >
                                <Grid size={{ xs: 12, md: 6 }}>
                                  <p className="text-[16px] font-semibold ">
                                    Present Address
                                  </p>
                                </Grid>

                                <Grid size={{ xs: 12 }}>
                                  <p>Address line 1</p>
                                  <FormControl fullWidth>
                                    <Select
                                      size="small"
                                      name="addressLine1"
                                      variant="outlined"
                                      value={""}
                                    >
                                      <MenuItem value={"Bisham"}>
                                        {"Bisham"}
                                      </MenuItem>
                                      <MenuItem value={"Saad"}>
                                        {"Saad"}
                                      </MenuItem>
                                      <MenuItem value={"Fahad"}>
                                        {"Fahad"}
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                  <p>Address line 2</p>
                                  <FormControl fullWidth>
                                    <Select
                                      size="small"
                                      name="addressLine2"
                                      variant="outlined"
                                      value={""}
                                    >
                                      <MenuItem value={"Bisham"}>
                                        {"Bisham"}
                                      </MenuItem>
                                      <MenuItem value={"Saad"}>
                                        {"Saad"}
                                      </MenuItem>
                                      <MenuItem value={"Fahad"}>
                                        {"Fahad"}
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                  <p>City</p>
                                  <FormControl fullWidth>
                                    <Select
                                      size="small"
                                      name="city"
                                      variant="outlined"
                                      value={""}
                                    >
                                      <MenuItem value={"Bisham"}>
                                        {"Bisham"}
                                      </MenuItem>
                                      <MenuItem value={"Saad"}>
                                        {"Saad"}
                                      </MenuItem>
                                      <MenuItem value={"Fahad"}>
                                        {"Fahad"}
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid size={{ xs: 12, md: 6 }}>
                                  <p>Country</p>
                                  <FormControl fullWidth>
                                    <Select
                                      size="small"
                                      name="country"
                                      variant="outlined"
                                      value={""}
                                    >
                                      <MenuItem value={"Bisham"}>
                                        {"Bisham"}
                                      </MenuItem>
                                      <MenuItem value={"Saad"}>
                                        {"Saad"}
                                      </MenuItem>
                                      <MenuItem value={"Fahad"}>
                                        {"Fahad"}
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid size={{ xs: 12, md: 6 }}>
                                  <p>State</p>
                                  <FormControl fullWidth>
                                    <Select
                                      size="small"
                                      name="state"
                                      variant="outlined"
                                      value={""}
                                    >
                                      <MenuItem value={"Bisham"}>
                                        {"Bisham"}
                                      </MenuItem>
                                      <MenuItem value={"Saad"}>
                                        {"Saad"}
                                      </MenuItem>
                                      <MenuItem value={"Fahad"}>
                                        {"Fahad"}
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                  <p>Postal Code</p>
                                  <FormControl fullWidth>
                                    <Select
                                      size="small"
                                      name="postalCode"
                                      variant="outlined"
                                      value={""}
                                    >
                                      <MenuItem value={"Bisham"}>
                                        {"Bisham"}
                                      </MenuItem>
                                      <MenuItem value={"Saad"}>
                                        {"Saad"}
                                      </MenuItem>
                                      <MenuItem value={"Fahad"}>
                                        {"Fahad"}
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                spacing={2}
                                sx={{
                                  background: "#edf0f4",
                                  borderRadius: "10px",
                                  padding: 2,
                                  mt: 3,
                                }}
                              >
                                <Grid size={{ xs: 12, md: 6 }}>
                                  <p className="text-[16px] font-semibold ">
                                    Permanent address
                                  </p>
                                </Grid>

                                <Grid size={{ xs: 12 }}>
                                  <p>Address line 1</p>
                                  <FormControl fullWidth>
                                    <Select
                                      size="small"
                                      name="addressLine1"
                                      variant="outlined"
                                      value={""}
                                    >
                                      <MenuItem value={"Bisham"}>
                                        {"Bisham"}
                                      </MenuItem>
                                      <MenuItem value={"Saad"}>
                                        {"Saad"}
                                      </MenuItem>
                                      <MenuItem value={"Fahad"}>
                                        {"Fahad"}
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                  <p>Address line 2</p>
                                  <FormControl fullWidth>
                                    <Select
                                      size="small"
                                      name="addressLine2"
                                      variant="outlined"
                                      value={""}
                                    >
                                      <MenuItem value={"Bisham"}>
                                        {"Bisham"}
                                      </MenuItem>
                                      <MenuItem value={"Saad"}>
                                        {"Saad"}
                                      </MenuItem>
                                      <MenuItem value={"Fahad"}>
                                        {"Fahad"}
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                  <p>City</p>
                                  <FormControl fullWidth>
                                    <Select
                                      size="small"
                                      name="city"
                                      variant="outlined"
                                      value={""}
                                    >
                                      <MenuItem value={"Bisham"}>
                                        {"Bisham"}
                                      </MenuItem>
                                      <MenuItem value={"Saad"}>
                                        {"Saad"}
                                      </MenuItem>
                                      <MenuItem value={"Fahad"}>
                                        {"Fahad"}
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid size={{ xs: 12, md: 6 }}>
                                  <p>Country</p>
                                  <FormControl fullWidth>
                                    <Select
                                      size="small"
                                      name="country"
                                      variant="outlined"
                                      value={""}
                                    >
                                      <MenuItem value={"Bisham"}>
                                        {"Bisham"}
                                      </MenuItem>
                                      <MenuItem value={"Saad"}>
                                        {"Saad"}
                                      </MenuItem>
                                      <MenuItem value={"Fahad"}>
                                        {"Fahad"}
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid size={{ xs: 12, md: 6 }}>
                                  <p>State</p>
                                  <FormControl fullWidth>
                                    <Select
                                      size="small"
                                      name="state"
                                      variant="outlined"
                                      value={""}
                                    >
                                      <MenuItem value={"Bisham"}>
                                        {"Bisham"}
                                      </MenuItem>
                                      <MenuItem value={"Saad"}>
                                        {"Saad"}
                                      </MenuItem>
                                      <MenuItem value={"Fahad"}>
                                        {"Fahad"}
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                  <p>Postal Code</p>
                                  <FormControl fullWidth>
                                    <Select
                                      size="small"
                                      name="postalCode"
                                      variant="outlined"
                                      value={""}
                                    >
                                      <MenuItem value={"Bisham"}>
                                        {"Bisham"}
                                      </MenuItem>
                                      <MenuItem value={"Saad"}>
                                        {"Saad"}
                                      </MenuItem>
                                      <MenuItem value={"Fahad"}>
                                        {"Fahad"}
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}></Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion defaultExpanded>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="candidateDetails"
                          id="candidateDetails"
                          sx={{ borderBottom: "1px solid #ddd" }}
                        >
                          <p className="text-[20px] ">Professional Details</p>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 4 }}>
                          <Grid container spacing={2}>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <p>Experience</p>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  name="experience"
                                  variant="outlined"
                                  value={""}
                                >
                                  <MenuItem value={"Bisham"}>
                                    {"Bisham"}
                                  </MenuItem>
                                  <MenuItem value={"Saad"}>{"Saad"}</MenuItem>
                                  <MenuItem value={"Fahad"}>{"Fahad"}</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <p>Location</p>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  name="location"
                                  variant="outlined"
                                  value={""}
                                >
                                  <MenuItem value={"Bisham"}>
                                    {"Bisham"}
                                  </MenuItem>
                                  <MenuItem value={"Saad"}>{"Saad"}</MenuItem>
                                  <MenuItem value={"Fahad"}>{"Fahad"}</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <p>Source of Hire</p>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  name="sourceOfHire"
                                  variant="outlined"
                                  value={""}
                                >
                                  <MenuItem value={"Bisham"}>
                                    {"Bisham"}
                                  </MenuItem>
                                  <MenuItem value={"Saad"}>{"Saad"}</MenuItem>
                                  <MenuItem value={"Fahad"}>{"Fahad"}</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <p>Title</p>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  name="title"
                                  variant="outlined"
                                  value={""}
                                >
                                  <MenuItem value={"Bisham"}>
                                    {"Bisham"}
                                  </MenuItem>
                                  <MenuItem value={"Saad"}>{"Saad"}</MenuItem>
                                  <MenuItem value={"Fahad"}>{"Fahad"}</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <p>Skill Set</p>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  name="skillSet"
                                  variant="outlined"
                                  value={""}
                                >
                                  <MenuItem value={"Bisham"}>
                                    {"Bisham"}
                                  </MenuItem>
                                  <MenuItem value={"Saad"}>{"Saad"}</MenuItem>
                                  <MenuItem value={"Fahad"}>{"Fahad"}</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <p>Current Salary</p>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  name="currentSalary"
                                  variant="outlined"
                                  value={""}
                                >
                                  <MenuItem value={"Bisham"}>
                                    {"Bisham"}
                                  </MenuItem>
                                  <MenuItem value={"Saad"}>{"Saad"}</MenuItem>
                                  <MenuItem value={"Fahad"}>{"Fahad"}</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <p>Highest Qualification</p>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  name="highestQualification"
                                  variant="outlined"
                                  value={""}
                                >
                                  <MenuItem value={"Bisham"}>
                                    {"Bisham"}
                                  </MenuItem>
                                  <MenuItem value={"Saad"}>{"Saad"}</MenuItem>
                                  <MenuItem value={"Fahad"}>{"Fahad"}</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <p>Department</p>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  name="department"
                                  variant="outlined"
                                  value={""}
                                >
                                  <MenuItem value={"Bisham"}>
                                    {"Bisham"}
                                  </MenuItem>
                                  <MenuItem value={"Saad"}>{"Saad"}</MenuItem>
                                  <MenuItem value={"Fahad"}>{"Fahad"}</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <p>Additional information</p>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  name="additionalInformation"
                                  variant="outlined"
                                  value={""}
                                >
                                  <MenuItem value={"Bisham"}>
                                    {"Bisham"}
                                  </MenuItem>
                                  <MenuItem value={"Saad"}>{"Saad"}</MenuItem>
                                  <MenuItem value={"Fahad"}>{"Fahad"}</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <p>Tentative Joining Date</p>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  name="tentativeJoiningDate"
                                  variant="outlined"
                                  value={""}
                                >
                                  <MenuItem value={"Bisham"}>
                                    {"Bisham"}
                                  </MenuItem>
                                  <MenuItem value={"Saad"}>{"Saad"}</MenuItem>
                                  <MenuItem value={"Fahad"}>{"Fahad"}</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  )}

                  {activeTab === "unmapped" && (
                    <div>
                      <Accordion defaultExpanded>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="candidateDetails"
                          id="candidateDetails"
                          sx={{ borderBottom: "1px solid #ddd" }}
                        >
                          <p className="text-[20px] ">Candidate Details</p>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 4 }}>
                          <Grid container spacing={2}>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <p>Candidate ID</p>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  name="candidateId"
                                  variant="outlined"
                                  value={""}
                                >
                                  <MenuItem value={"Bisham"}>
                                    {"Bisham"}
                                  </MenuItem>
                                  <MenuItem value={"Saad"}>{"Saad"}</MenuItem>
                                  <MenuItem value={"Fahad"}>{"Fahad"}</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <p>First Name</p>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  name="firstName"
                                  variant="outlined"
                                  value={""}
                                >
                                  <MenuItem value={"Bisham"}>
                                    {"Bisham"}
                                  </MenuItem>
                                  <MenuItem value={"Saad"}>{"Saad"}</MenuItem>
                                  <MenuItem value={"Fahad"}>{"Fahad"}</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <p>Email ID</p>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  name="emailId"
                                  variant="outlined"
                                  value={""}
                                >
                                  <MenuItem value={"Bisham"}>
                                    {"Bisham"}
                                  </MenuItem>
                                  <MenuItem value={"Saad"}>{"Saad"}</MenuItem>
                                  <MenuItem value={"Fahad"}>{"Fahad"}</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <p>Last Name</p>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  name="lastName"
                                  variant="outlined"
                                  value={""}
                                >
                                  <MenuItem value={"Bisham"}>
                                    {"Bisham"}
                                  </MenuItem>
                                  <MenuItem value={"Saad"}>{"Saad"}</MenuItem>
                                  <MenuItem value={"Fahad"}>{"Fahad"}</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <p>Phone</p>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  name="phone"
                                  variant="outlined"
                                  value={""}
                                >
                                  <MenuItem value={"Bisham"}>
                                    {"Bisham"}
                                  </MenuItem>
                                  <MenuItem value={"Saad"}>{"Saad"}</MenuItem>
                                  <MenuItem value={"Fahad"}>{"Fahad"}</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <p>Official Email</p>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  name="officialEmail"
                                  variant="outlined"
                                  value={""}
                                >
                                  <MenuItem value={"Bisham"}>
                                    {"Bisham"}
                                  </MenuItem>
                                  <MenuItem value={"Saad"}>{"Saad"}</MenuItem>
                                  <MenuItem value={"Fahad"}>{"Fahad"}</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="candidateDetails"
                          id="candidateDetails"
                          sx={{ borderBottom: "1px solid #ddd" }}
                        >
                          <p className="text-[20px] ">Address Details</p>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 4 }}>
                          <Grid container spacing={2}>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <Grid
                                container
                                spacing={2}
                                sx={{
                                  background: "#edf0f4",
                                  borderRadius: "10px",
                                  padding: 2,
                                }}
                              >
                                <Grid size={{ xs: 12, md: 6 }}>
                                  <p className="text-[16px] font-semibold ">
                                    Present Address
                                  </p>
                                </Grid>

                                <Grid size={{ xs: 12 }}>
                                  <p>Address line 1</p>
                                  <FormControl fullWidth>
                                    <Select
                                      size="small"
                                      name="addressLine1"
                                      variant="outlined"
                                      value={""}
                                    >
                                      <MenuItem value={"Bisham"}>
                                        {"Bisham"}
                                      </MenuItem>
                                      <MenuItem value={"Saad"}>
                                        {"Saad"}
                                      </MenuItem>
                                      <MenuItem value={"Fahad"}>
                                        {"Fahad"}
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                  <p>Address line 2</p>
                                  <FormControl fullWidth>
                                    <Select
                                      size="small"
                                      name="addressLine2"
                                      variant="outlined"
                                      value={""}
                                    >
                                      <MenuItem value={"Bisham"}>
                                        {"Bisham"}
                                      </MenuItem>
                                      <MenuItem value={"Saad"}>
                                        {"Saad"}
                                      </MenuItem>
                                      <MenuItem value={"Fahad"}>
                                        {"Fahad"}
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                  <p>City</p>
                                  <FormControl fullWidth>
                                    <Select
                                      size="small"
                                      name="city"
                                      variant="outlined"
                                      value={""}
                                    >
                                      <MenuItem value={"Bisham"}>
                                        {"Bisham"}
                                      </MenuItem>
                                      <MenuItem value={"Saad"}>
                                        {"Saad"}
                                      </MenuItem>
                                      <MenuItem value={"Fahad"}>
                                        {"Fahad"}
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid size={{ xs: 12, md: 6 }}>
                                  <p>Country</p>
                                  <FormControl fullWidth>
                                    <Select
                                      size="small"
                                      name="country"
                                      variant="outlined"
                                      value={""}
                                    >
                                      <MenuItem value={"Bisham"}>
                                        {"Bisham"}
                                      </MenuItem>
                                      <MenuItem value={"Saad"}>
                                        {"Saad"}
                                      </MenuItem>
                                      <MenuItem value={"Fahad"}>
                                        {"Fahad"}
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid size={{ xs: 12, md: 6 }}>
                                  <p>State</p>
                                  <FormControl fullWidth>
                                    <Select
                                      size="small"
                                      name="state"
                                      variant="outlined"
                                      value={""}
                                    >
                                      <MenuItem value={"Bisham"}>
                                        {"Bisham"}
                                      </MenuItem>
                                      <MenuItem value={"Saad"}>
                                        {"Saad"}
                                      </MenuItem>
                                      <MenuItem value={"Fahad"}>
                                        {"Fahad"}
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                  <p>Postal Code</p>
                                  <FormControl fullWidth>
                                    <Select
                                      size="small"
                                      name="postalCode"
                                      variant="outlined"
                                      value={""}
                                    >
                                      <MenuItem value={"Bisham"}>
                                        {"Bisham"}
                                      </MenuItem>
                                      <MenuItem value={"Saad"}>
                                        {"Saad"}
                                      </MenuItem>
                                      <MenuItem value={"Fahad"}>
                                        {"Fahad"}
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                spacing={2}
                                sx={{
                                  background: "#edf0f4",
                                  borderRadius: "10px",
                                  padding: 2,
                                  mt: 3,
                                }}
                              >
                                <Grid size={{ xs: 12, md: 6 }}>
                                  <p className="text-[16px] font-semibold ">
                                    Permanent address
                                  </p>
                                </Grid>

                                <Grid size={{ xs: 12 }}>
                                  <p>Address line 1</p>
                                  <FormControl fullWidth>
                                    <Select
                                      size="small"
                                      name="addressLine1"
                                      variant="outlined"
                                      value={""}
                                    >
                                      <MenuItem value={"Bisham"}>
                                        {"Bisham"}
                                      </MenuItem>
                                      <MenuItem value={"Saad"}>
                                        {"Saad"}
                                      </MenuItem>
                                      <MenuItem value={"Fahad"}>
                                        {"Fahad"}
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                  <p>Address line 2</p>
                                  <FormControl fullWidth>
                                    <Select
                                      size="small"
                                      name="addressLine2"
                                      variant="outlined"
                                      value={""}
                                    >
                                      <MenuItem value={"Bisham"}>
                                        {"Bisham"}
                                      </MenuItem>
                                      <MenuItem value={"Saad"}>
                                        {"Saad"}
                                      </MenuItem>
                                      <MenuItem value={"Fahad"}>
                                        {"Fahad"}
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                  <p>City</p>
                                  <FormControl fullWidth>
                                    <Select
                                      size="small"
                                      name="city"
                                      variant="outlined"
                                      value={""}
                                    >
                                      <MenuItem value={"Bisham"}>
                                        {"Bisham"}
                                      </MenuItem>
                                      <MenuItem value={"Saad"}>
                                        {"Saad"}
                                      </MenuItem>
                                      <MenuItem value={"Fahad"}>
                                        {"Fahad"}
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid size={{ xs: 12, md: 6 }}>
                                  <p>Country</p>
                                  <FormControl fullWidth>
                                    <Select
                                      size="small"
                                      name="country"
                                      variant="outlined"
                                      value={""}
                                    >
                                      <MenuItem value={"Bisham"}>
                                        {"Bisham"}
                                      </MenuItem>
                                      <MenuItem value={"Saad"}>
                                        {"Saad"}
                                      </MenuItem>
                                      <MenuItem value={"Fahad"}>
                                        {"Fahad"}
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid size={{ xs: 12, md: 6 }}>
                                  <p>State</p>
                                  <FormControl fullWidth>
                                    <Select
                                      size="small"
                                      name="state"
                                      variant="outlined"
                                      value={""}
                                    >
                                      <MenuItem value={"Bisham"}>
                                        {"Bisham"}
                                      </MenuItem>
                                      <MenuItem value={"Saad"}>
                                        {"Saad"}
                                      </MenuItem>
                                      <MenuItem value={"Fahad"}>
                                        {"Fahad"}
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                  <p>Postal Code</p>
                                  <FormControl fullWidth>
                                    <Select
                                      size="small"
                                      name="postalCode"
                                      variant="outlined"
                                      value={""}
                                    >
                                      <MenuItem value={"Bisham"}>
                                        {"Bisham"}
                                      </MenuItem>
                                      <MenuItem value={"Saad"}>
                                        {"Saad"}
                                      </MenuItem>
                                      <MenuItem value={"Fahad"}>
                                        {"Fahad"}
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}></Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion defaultExpanded>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="candidateDetails"
                          id="candidateDetails"
                          sx={{ borderBottom: "1px solid #ddd" }}
                        >
                          <p className="text-[20px] ">Professional Details</p>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 4 }}>
                          <Grid container spacing={2}>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <p>Experience</p>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  name="experience"
                                  variant="outlined"
                                  value={""}
                                >
                                  <MenuItem value={"Bisham"}>
                                    {"Bisham"}
                                  </MenuItem>
                                  <MenuItem value={"Saad"}>{"Saad"}</MenuItem>
                                  <MenuItem value={"Fahad"}>{"Fahad"}</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <p>Location</p>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  name="location"
                                  variant="outlined"
                                  value={""}
                                >
                                  <MenuItem value={"Bisham"}>
                                    {"Bisham"}
                                  </MenuItem>
                                  <MenuItem value={"Saad"}>{"Saad"}</MenuItem>
                                  <MenuItem value={"Fahad"}>{"Fahad"}</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <p>Source of Hire</p>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  name="sourceOfHire"
                                  variant="outlined"
                                  value={""}
                                >
                                  <MenuItem value={"Bisham"}>
                                    {"Bisham"}
                                  </MenuItem>
                                  <MenuItem value={"Saad"}>{"Saad"}</MenuItem>
                                  <MenuItem value={"Fahad"}>{"Fahad"}</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <p>Title</p>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  name="title"
                                  variant="outlined"
                                  value={""}
                                >
                                  <MenuItem value={"Bisham"}>
                                    {"Bisham"}
                                  </MenuItem>
                                  <MenuItem value={"Saad"}>{"Saad"}</MenuItem>
                                  <MenuItem value={"Fahad"}>{"Fahad"}</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <p>Skill Set</p>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  name="skillSet"
                                  variant="outlined"
                                  value={""}
                                >
                                  <MenuItem value={"Bisham"}>
                                    {"Bisham"}
                                  </MenuItem>
                                  <MenuItem value={"Saad"}>{"Saad"}</MenuItem>
                                  <MenuItem value={"Fahad"}>{"Fahad"}</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <p>Current Salary</p>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  name="currentSalary"
                                  variant="outlined"
                                  value={""}
                                >
                                  <MenuItem value={"Bisham"}>
                                    {"Bisham"}
                                  </MenuItem>
                                  <MenuItem value={"Saad"}>{"Saad"}</MenuItem>
                                  <MenuItem value={"Fahad"}>{"Fahad"}</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <p>Highest Qualification</p>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  name="highestQualification"
                                  variant="outlined"
                                  value={""}
                                >
                                  <MenuItem value={"Bisham"}>
                                    {"Bisham"}
                                  </MenuItem>
                                  <MenuItem value={"Saad"}>{"Saad"}</MenuItem>
                                  <MenuItem value={"Fahad"}>{"Fahad"}</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <p>Department</p>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  name="department"
                                  variant="outlined"
                                  value={""}
                                >
                                  <MenuItem value={"Bisham"}>
                                    {"Bisham"}
                                  </MenuItem>
                                  <MenuItem value={"Saad"}>{"Saad"}</MenuItem>
                                  <MenuItem value={"Fahad"}>{"Fahad"}</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <p>Additional information</p>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  name="additionalInformation"
                                  variant="outlined"
                                  value={""}
                                >
                                  <MenuItem value={"Bisham"}>
                                    {"Bisham"}
                                  </MenuItem>
                                  <MenuItem value={"Saad"}>{"Saad"}</MenuItem>
                                  <MenuItem value={"Fahad"}>{"Fahad"}</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <p>Tentative Joining Date</p>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  name="tentativeJoiningDate"
                                  variant="outlined"
                                  value={""}
                                >
                                  <MenuItem value={"Bisham"}>
                                    {"Bisham"}
                                  </MenuItem>
                                  <MenuItem value={"Saad"}>{"Saad"}</MenuItem>
                                  <MenuItem value={"Fahad"}>{"Fahad"}</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        )}

        <Box
          sx={{ backgroundColor: "background.default" }}
          className="w-full min-h-14 items-center z-50 sticky bottom-0 left-0 py-2"
        >
          <div className="flex flex-row gap-3 justify-between items-center">
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleMapDetails}>
              Next
            </Button>
          </div>
        </Box>
      </CustomEmptyModal>
    </div>
  );
};

export default CustomImport;
