import React, { useState } from "react";
import CustomEmptyModal from "./CustomEmptyModal";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import { ArrowBack, Close } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const CustomExport = ({ open, onClose, historyExport,setHistoryExport, checkBoxes }) => {
  const { mode } = useTheme();
  const [selectedFormat, setSelectedFormat] = useState("XLS");
  const [includeTabularData, setIncludeTabularData] = useState(false);
  const [includeFilesImages, setIncludeFilesImages] = useState(false);
  const [fileOption, setFileOption] = useState("single");

  const handleFormatChange = (event) => {
    setSelectedFormat(event.target.value);
  };

  return (
    <div>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "background.default",
          }}
          className="w-[50%] h-[80%] overflow-scroll px-3 rounded-lg border border-neutral-700"
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: 2,
              borderBottom: "1px solid #ddd",
              position: "sticky",
              backgroundColor: "background.default",
              top: 0,
              zIndex: 10,
            }}
          >
            <Typography variant="h6">Export</Typography>
            <IconButton
              sx={{
                backgroundColor: mode === "light" ? "#edf0f4" : "#3333",
                fontSize: 16,
                borderRadius: "6px",
              }}
              onClick={onClose}
            >
              <Close fontSize="16px" />
            </IconButton>
          </Box>

          <Box sx={{ padding: 4, border: "1px solid", mt: 3, borderRadius: 4 }}>
            <div className=" my-5 flex flex-col items-center text-center">
              {/* Upload Image */}
              <img
                src="/images/uploadImg.png"
                style={{ width: "20%", height: "20%" }}
              />

              {/* Title */}
              <p className="mt-2 text-lg font-medium">Choose File Format</p>

              {/* Centered File Format Selection */}
              <FormControl sx={{ mt: 2 }}>
                <RadioGroup
                  row
                  value={selectedFormat}
                  onChange={(e) => setSelectedFormat(e.target.value)}
                >
                  <FormControlLabel
                    value="XLS"
                    control={<Radio />}
                    label="XLS"
                  />
                  <FormControlLabel
                    value="XLSX"
                    control={<Radio />}
                    label="XLSX"
                  />
                  <FormControlLabel
                    value="CSV"
                    control={<Radio />}
                    label="CSV"
                  />
                  <FormControlLabel
                    value="TSV"
                    control={<Radio />}
                    label="TSV"
                  />
                </RadioGroup>
              </FormControl>
            </div>

            {/* Checkboxes & Radio Buttons */}
            {
              checkBoxes && (
                <Box sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={includeTabularData}
                        onChange={(e) => setIncludeTabularData(e.target.checked)}
                      />
                    }
                    label="Include tabular section data"
                  />
                  {includeTabularData && (
                    <RadioGroup
                      row
                      value={fileOption}
                      onChange={(e) => setFileOption(e.target.value)}
                      sx={{ pl: 3 }}
                    >
                      <FormControlLabel
                        value="single"
                        control={<Radio />}
                        label="Single File"
                      />
                      <FormControlLabel
                        value="multiple"
                        control={<Radio />}
                        label="Multiple Files"
                      />
                    </RadioGroup>
                  )}
    
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={includeFilesImages}
                        onChange={(e) => setIncludeFilesImages(e.target.checked)}
                      />
                    }
                    label="Include files & images"
                  />
                </Box>
              )
            }

            {historyExport && (
              <Box sx={{ mt: 2 }}>
                <p className="mb-1 text-md ">Date Range</p>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    //   label="Select Date"
                    // value={selectedDate}
                    // onChange={(newValue) => setSelectedDate(newValue)}
                    format="DD-MMM-YYYY"
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        sx: {
                          height: 32,
                          "& .MuiInputBase-root": { height: 32 },
                        },
                      },
                    }}
                  />
                </LocalizationProvider>
              </Box>
            )}
          </Box>

          {/* Sticky Footer Buttons */}
          <Box
            sx={{ backgroundColor: "background.default" }}
            className="w-full min-h-14 items-center sticky bottom-0 py-2"
          >
            <div className="flex flex-row gap-3 items-center">
              <Button variant="contained" type="submit">
                Export
              </Button>
              <Button variant="outlined" onClick={onClose}>
                Cancel
              </Button>
            </div>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default CustomExport;
